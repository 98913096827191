import { Box, Button, Flex, FormControl, FormLabel, HStack } from "@chakra-ui/react";
import { faBan } from "@fortawesome/free-solid-svg-icons";

import { LinkLocationTypeSelector } from "../../common/link-location-type-selector";
import { LinkLocationStatusSelector } from "../../common/link-location-status-selector";
import AppIcon from "../../components/app-icon";
import { Container } from "../../components/layout/container";
import { PageHeading } from "../../components/page-heading";
import { SearchInput } from "../../components/search-input/search-input";
import { PrimaryButton } from "../../components/buttons";
import { LayoutBase, MainNav } from "../../components/layout";
import { PanelBottom, PanelGroup, PanelTop } from "../../components/panels";

import { useAdminLinkLocations } from "./use-admin-link-locations-hook";
import { AddLinkLocationModal } from "../../common/add-link-location-modal";
import { AdminLinkLocationsTable } from "./admin-link-locations-table";
import { CategoryMultiSelector } from "../../common/category-selector";

export function LinkLocationsRoute() {
  const {
    linkLocationsQueryResult,
    statusIds,
    typeIds,
    hasFilters,
    isCreatingLinkLocation,
    setIsCreatingLinkLocation,
    onAdd,
    onAddClose,
    onSearchChange,
    onStatusIdsChange,
    onTypeIdsChange,
    onNextPage,
    onPreviousPage,
    clearFilters,
    updateLinkLocationCategories,
    categoryIds,
    onCategoryIdsChange,
    onDownloadLinkLocations,
    onLinkLocationAutoAssignChange,
  } = useAdminLinkLocations();

  return (
    <LayoutBase pageTitle="Link Locations">
      <MainNav />
      <AddLinkLocationModal isOpen={isCreatingLinkLocation} onAdded={onAdd} onClose={onAddClose} />
      <Container centerHorizontally>
        <Flex alignItems="center">
          <PageHeading>Link Locations</PageHeading>
        </Flex>
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Flex justifyContent="space-between" alignItems="center">
              <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end">
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Search</FormLabel>
                  <SearchInput onChange={onSearchChange} />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Types</FormLabel>
                  <LinkLocationTypeSelector
                    selectedTypeIds={typeIds as string[]}
                    onTypeSelected={onTypeIdsChange}
                    emptyValueLabel="All Types"
                    isMulti
                  />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Statuses</FormLabel>
                  <LinkLocationStatusSelector
                    selectedStatusIds={statusIds as string[]}
                    onStatusSelected={onStatusIdsChange}
                    emptyValueLabel="All Statuses"
                    isMulti
                  />
                </FormControl>
                <FormControl width="inherit">
                  <FormLabel fontSize="xs">Category</FormLabel>
                  <CategoryMultiSelector
                    selectedCategoryIds={categoryIds as number[]}
                    onCategorySelected={onCategoryIdsChange}
                    emptyValueLabel="All Categories"
                  />
                </FormControl>
                <Box>
                  {hasFilters && (
                    <Button onClick={clearFilters}>
                      <AppIcon icon={faBan} standardRightMargin />
                      Clear Filters
                    </Button>
                  )}
                </Box>
              </HStack>
              <HStack spacing="8px" justify="left" justifyContent="flex-start" alignItems="flex-end">
                <Box>
                  <PrimaryButton onClick={onDownloadLinkLocations}>Download link locations(.csv)</PrimaryButton>
                </Box>
                <Box>
                  <PrimaryButton onClick={() => setIsCreatingLinkLocation(true)}>Add Link Location</PrimaryButton>
                </Box>
              </HStack>
            </Flex>
          </PanelTop>
          <PanelBottom containsTable>
            <AdminLinkLocationsTable
              adminLinkLocationsQueryResult={linkLocationsQueryResult}
              onNextPage={onNextPage}
              onPreviousPage={onPreviousPage}
              updateLinkLocationCategories={updateLinkLocationCategories}
              updateLinkLocation={onLinkLocationAutoAssignChange}
            />
          </PanelBottom>
        </PanelGroup>
      </Container>
    </LayoutBase>
  );
}
