import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: Date;
  Time: string;
};

export type AdminReportPublicationManagement = {
  __typename?: "AdminReportPublicationManagement";
  ordersNeedingResultUrl: OrderConnection;
};

export type AdminReportPublicationManagementOrdersNeedingResultUrlArgs = {
  customerIds?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
  treatReportingSmartsResultUrlAsNull?: InputMaybe<Scalars["Boolean"]>;
};

export type AdminReports = {
  __typename?: "AdminReports";
  publicationManagement: AdminReportPublicationManagement;
};

export type AmTask = {
  __typename?: "AmTask";
  completedAt: Scalars["DateTime"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  deletedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  info?: Maybe<Scalars["String"]>;
  taskType: Scalars["String"];
};

export type AmTaskConnection = {
  __typename?: "AmTaskConnection";
  nodes: Array<AmTask>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type ApprovePaymentBatchFundingInput = {
  paymentBatchId: Scalars["Int"];
};

export type ApprovePaymentBatchFundingPaypalInput = {
  paymentBatchId: Scalars["Int"];
};

export type ApprovePaymentBatchFundingPaypalResponse = {
  __typename?: "ApprovePaymentBatchFundingPaypalResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type ApprovePaymentBatchFundingResponse = {
  __typename?: "ApprovePaymentBatchFundingResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type ApproveUserWritingSampleInput = {
  userWritingSampleId: Scalars["Int"];
};

export type ApproveUserWritingSampleResponse = {
  __typename?: "ApproveUserWritingSampleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type BlockDomainConnection = {
  __typename?: "BlockDomainConnection";
  nodes: Array<BlockDomainCustomer>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type BlockDomainCustomer = {
  __typename?: "BlockDomainCustomer";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  domain: Scalars["String"];
  id: Scalars["Int"];
};

export enum CampaignCategoriesSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type CampaignCategory = {
  __typename?: "CampaignCategory";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CampaignCategoryConnection = {
  __typename?: "CampaignCategoryConnection";
  nodes: Array<CampaignCategory>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export enum CategoriesSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type Category = {
  __typename?: "Category";
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  name: Scalars["String"];
};

export type CategoryConnection = {
  __typename?: "CategoryConnection";
  nodes: Array<Category>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type ChangePasswordInput = {
  currentPassword: Scalars["String"];
  newPassword: Scalars["String"];
};

export type ChangePasswordResponse = {
  __typename?: "ChangePasswordResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type Checklist = {
  __typename?: "Checklist";
  checklistItems: Array<ChecklistItem>;
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type ChecklistDefinition = {
  __typename?: "ChecklistDefinition";
  checklistDefinitionItems: Array<ChecklistDefinitionItem>;
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["String"];
};

export type ChecklistDefinitionConnection = {
  __typename?: "ChecklistDefinitionConnection";
  nodes: Array<ChecklistDefinition>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type ChecklistDefinitionItem = {
  __typename?: "ChecklistDefinitionItem";
  checklistDefinitionId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type ChecklistItem = {
  __typename?: "ChecklistItem";
  completedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type CheckoutInput = {
  customerId?: InputMaybe<Scalars["Int"]>;
  orderIds?: InputMaybe<Array<Scalars["Int"]>>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  partnerPaymentMethodId?: InputMaybe<Scalars["Int"]>;
  payNow?: InputMaybe<Scalars["Boolean"]>;
};

export type CheckoutResponse = {
  __typename?: "CheckoutResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type ClaimTaskInput = {
  taskId: Scalars["Int"];
};

export type ClaimTaskResponse = {
  __typename?: "ClaimTaskResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  task?: Maybe<Task>;
};

export type ClearProposedCustomerKeywordsRejectionInput = {
  customerKeywordProposalId: Scalars["Int"];
};

export type ClearProposedCustomerKeywordsRejectionResponse = {
  __typename?: "ClearProposedCustomerKeywordsRejectionResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type ConfirmEmailInput = {
  confirmationToken: Scalars["String"];
};

export type ConfirmEmailResponse = {
  __typename?: "ConfirmEmailResponse";
  ok: Scalars["Boolean"];
};

export type ContentIdeation = {
  __typename?: "ContentIdeation";
  id: Scalars["Int"];
  idea: Scalars["String"];
};

export type ContentItem = {
  __typename?: "ContentItem";
  classification?: Maybe<Scalars["Int"]>;
  content: Scalars["String"];
  feedbackContentItems: Array<FeedbackContentItem>;
  id: Scalars["Int"];
  metaDescription: Scalars["String"];
  metaTitle: Scalars["String"];
  probability?: Maybe<Scalars["Float"]>;
  thumbnailFile?: Maybe<File>;
  title: Scalars["String"];
};

export type ContentItemDelivery = {
  __typename?: "ContentItemDelivery";
  content: Scalars["String"];
  keyword: Scalars["String"];
  metaDescription: Scalars["String"];
  metaTitle: Scalars["String"];
  partnerLogoUrl?: Maybe<Scalars["String"]>;
  partnerName: Scalars["String"];
  publicUrl: Scalars["String"];
  title: Scalars["String"];
};

export type Country = {
  __typename?: "Country";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CreateAmTaskInput = {
  completedAt: Scalars["Date"];
  customerId: Scalars["Int"];
  info?: InputMaybe<Scalars["String"]>;
  taskType: Scalars["String"];
};

export type CreateAmTaskResponse = {
  __typename?: "CreateAmTaskResponse";
  amTask?: Maybe<AmTask>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateBlockDomainCustomersInput = {
  customerId: Scalars["Int"];
  domains: Array<Scalars["String"]>;
};

export type CreateBlockDomainCustomersResponse = {
  __typename?: "CreateBlockDomainCustomersResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateBulkProposedCustomerKeywordsInput = {
  customerKeywordProposalId: Scalars["Int"];
  keywords: Array<Scalars["String"]>;
};

export type CreateBulkProposedCustomerKeywordsResponse = {
  __typename?: "CreateBulkProposedCustomerKeywordsResponse";
  proposedCustomerKeywords: Array<ProposedCustomerKeyword>;
};

export type CreateBusinessSubscriptionOrdersInput = {
  customerId: Scalars["Int"];
};

export type CreateBusinessSubscriptionOrdersResponse = {
  __typename?: "CreateBusinessSubscriptionOrdersResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orders?: Maybe<Array<Order>>;
};

export type CreateCategoryInput = {
  name: Scalars["String"];
};

export type CreateCategoryResponse = {
  __typename?: "CreateCategoryResponse";
  category?: Maybe<Category>;
};

export type CreateChecklistDefinitionInput = {
  items: Array<CreateChecklistDefinitionItemInput>;
  name: Scalars["String"];
  partnerId: Scalars["Int"];
};

export type CreateChecklistDefinitionItemInput = {
  name: Scalars["String"];
  order: Scalars["Int"];
};

export type CreateChecklistDefinitionResponse = {
  __typename?: "CreateChecklistDefinitionResponse";
  checklistDefinition?: Maybe<ChecklistDefinition>;
};

export type CreateCustomerBusinessListingSpecInput = {
  address?: InputMaybe<Scalars["String"]>;
  addressDisplay?: InputMaybe<Scalars["Boolean"]>;
  city?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  email?: InputMaybe<Scalars["String"]>;
  hasPhysicalStorefront?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stateOrProvince?: InputMaybe<Scalars["String"]>;
  uberallCategoryId?: InputMaybe<Scalars["Int"]>;
  zipPostalCode?: InputMaybe<Scalars["String"]>;
};

export type CreateCustomerBusinessListingSpecResponse = {
  __typename?: "CreateCustomerBusinessListingSpecResponse";
  customerBusinessListingSpec?: Maybe<CustomerBusinessListingSpec>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerCampaignInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  campaignCategoryId: Scalars["String"];
  customerId: Scalars["Int"];
  endsAt?: InputMaybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  partnerPackageId?: InputMaybe<Scalars["Int"]>;
  startsAt: Scalars["DateTime"];
};

export type CreateCustomerCampaignResponse = {
  __typename?: "CreateCustomerCampaignResponse";
  customerCampaign: CustomerCampaign;
};

export type CreateCustomerConversationInput = {
  content: Scalars["String"];
  customerId: Scalars["Int"];
  subject: Scalars["String"];
};

export type CreateCustomerConversationResponse = {
  __typename?: "CreateCustomerConversationResponse";
  customerConversation: CustomerConversation;
};

export type CreateCustomerHoursOfOperationItemInput = {
  closeAt: Scalars["String"];
  customerId: Scalars["Int"];
  dayIndex: Scalars["Int"];
  isOpen: Scalars["Boolean"];
  openAt: Scalars["String"];
};

export type CreateCustomerInput = {
  accountManagerUserId?: InputMaybe<Scalars["Int"]>;
  categoryId: Scalars["Int"];
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  gbpUrl?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  partnerId: Scalars["Int"];
  partnerPortal?: InputMaybe<Scalars["String"]>;
  portal?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type CreateCustomerInvoiceInput = {
  customerId: Scalars["Int"];
  orderIds: Array<Scalars["Int"]>;
};

export type CreateCustomerInvoiceResponse = {
  __typename?: "CreateCustomerInvoiceResponse";
  customerInvoice?: Maybe<CustomerInvoice>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerKeywordInput = {
  country?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  instructions?: InputMaybe<Scalars["String"]>;
  isPrimary: Scalars["Boolean"];
  isTrackingRank: Scalars["Boolean"];
  landingPageUrl?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type CreateCustomerKeywordResponse = {
  __typename?: "CreateCustomerKeywordResponse";
  customerKeyword: CustomerKeyword;
};

export type CreateCustomerLinkBuildInput = {
  customerId: Scalars["Int"];
  orderId: Scalars["Int"];
  summary: Scalars["String"];
};

export type CreateCustomerLinkBuildResponse = {
  __typename?: "CreateCustomerLinkBuildResponse";
  customerLinkBuild?: Maybe<CustomerLinkBuild>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerMessageInput = {
  content: Scalars["String"];
  conversationId: Scalars["String"];
};

export type CreateCustomerMessageResponse = {
  __typename?: "CreateCustomerMessageResponse";
  customerMessage: CustomerMessage;
};

export type CreateCustomerNoteInput = {
  content: Scalars["String"];
  customerId: Scalars["Int"];
};

export type CreateCustomerPremiumBusinessListingSpecImageInput = {
  customerId: Scalars["Int"];
  fileId: Scalars["String"];
  imageTypeId: Scalars["String"];
};

export type CreateCustomerPremiumBusinessListingSpecImageResponse = {
  __typename?: "CreateCustomerPremiumBusinessListingSpecImageResponse";
  customerPremiumBusinessListingSpecImage: CustomerPremiumBusinessListingSpecImage;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerResponse = {
  __typename?: "CreateCustomerResponse";
  customer?: Maybe<Customer>;
};

export type CreateCustomerSiteAuditInput = {
  customerId: Scalars["Int"];
  fileId?: InputMaybe<Scalars["String"]>;
  orderId: Scalars["Int"];
  summary?: InputMaybe<Scalars["String"]>;
};

export type CreateCustomerSiteAuditResponse = {
  __typename?: "CreateCustomerSiteAuditResponse";
  customerSiteAudit?: Maybe<CustomerSiteAudit>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateCustomerStatusChangeInput = {
  customerId: Scalars["Int"];
  effectiveAt: Scalars["Date"];
  reason?: InputMaybe<Scalars["String"]>;
  statusId: Scalars["String"];
};

export type CreateCustomerStatusChangeResponse = {
  __typename?: "CreateCustomerStatusChangeResponse";
  customerStatusChange: CustomerStatusChange;
};

export type CreateCustomerUserInput = {
  customerId: Scalars["Int"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type CreateCustomerUserResponse = {
  __typename?: "CreateCustomerUserResponse";
  customerUser?: Maybe<CustomerUser>;
};

export type CreateCustomerUserSsoCodeInput = {
  customerUserId: Scalars["Int"];
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CreateCustomerUserSsoCodeResponse = {
  __typename?: "CreateCustomerUserSsoCodeResponse";
  customerUserSsoCode: CustomerUserSsoCode;
};

export type CreateDomainCategoryInput = {
  categoryId: Scalars["Int"];
  domainId: Scalars["String"];
};

export type CreateDomainCategoryResponse = {
  __typename?: "CreateDomainCategoryResponse";
  domainCategory?: Maybe<DomainCategory>;
};

export type CreateDomainInput = {
  id: Scalars["String"];
};

export type CreateDomainResponse = {
  __typename?: "CreateDomainResponse";
  domain?: Maybe<Domain>;
};

export type CreateDomainsInput = {
  ids: Array<Scalars["String"]>;
};

export type CreateDomainsResponse = {
  __typename?: "CreateDomainsResponse";
  domains: Array<Domain>;
};

export type CreateFeedbackContentItemInput = {
  contentItemId: Scalars["Int"];
  feedback?: InputMaybe<Scalars["String"]>;
  rating: Scalars["Int"];
};

export type CreateFeedbackContentItemResponse = {
  __typename?: "CreateFeedbackContentItemResponse";
  error?: Maybe<ResponseError>;
  feedbackContentItem?: Maybe<FeedbackContentItem>;
  ok: Scalars["Boolean"];
};

export type CreateFulfillmentRuleInput = {
  partnerId?: InputMaybe<Scalars["Int"]>;
  productId?: InputMaybe<Scalars["String"]>;
  taskTypeId?: InputMaybe<Scalars["String"]>;
  vendorId: Scalars["Int"];
};

export type CreateFulfillmentRuleResponse = {
  __typename?: "CreateFulfillmentRuleResponse";
  error?: Maybe<ResponseError>;
  fulfillmentRule?: Maybe<FulfillmentRule>;
  ok: Scalars["Boolean"];
};

export type CreateLinkLocationInput = {
  allowsAIContent?: InputMaybe<Scalars["Boolean"]>;
  allowsCbdContent?: InputMaybe<Scalars["Boolean"]>;
  allowsOnlyOneLinkInContent?: InputMaybe<Scalars["Boolean"]>;
  allowsWeapons?: InputMaybe<Scalars["Boolean"]>;
  authorityScore?: InputMaybe<Scalars["Int"]>;
  autoAssign?: InputMaybe<Scalars["Boolean"]>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  contactEmail?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  contactPhone?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Float"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  domain: Scalars["String"];
  domainAuthority: Scalars["Int"];
  internalQuality?: InputMaybe<Scalars["Int"]>;
  minimumWordCount?: InputMaybe<Scalars["Int"]>;
  monthlyTraffic?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  spam?: InputMaybe<Scalars["Int"]>;
  statusId: Scalars["String"];
  typeId: Scalars["String"];
};

export type CreateLinkLocationResponse = {
  __typename?: "CreateLinkLocationResponse";
  error?: Maybe<ResponseError>;
  linkLocation?: Maybe<LinkLocation>;
  ok: Scalars["Boolean"];
};

export type CreateNoteResponse = {
  __typename?: "CreateNoteResponse";
  error?: Maybe<ResponseError>;
  note?: Maybe<Note>;
  ok: Scalars["Boolean"];
};

export type CreateOrderExternalContentSpecInput = {
  cost: Scalars["Float"];
  domainAuthority?: InputMaybe<Scalars["Int"]>;
  externalOrderId: Scalars["String"];
  orderId: Scalars["Int"];
  submittedAt: Scalars["DateTime"];
  trustFlow?: InputMaybe<Scalars["Int"]>;
  vendorId: Scalars["Int"];
};

export type CreateOrderExternalContentSpecResponse = {
  __typename?: "CreateOrderExternalContentSpecResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderExternalContentSpec?: Maybe<OrderExternalContentSpec>;
};

export type CreateOrderHaloKeywordInput = {
  customerKeywordId: Scalars["Int"];
  customerProposalId?: InputMaybe<Scalars["Int"]>;
  isProposed: Scalars["Boolean"];
  orderId: Scalars["Int"];
};

export type CreateOrderHaloKeywordResponse = {
  __typename?: "CreateOrderHaloKeywordResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderHaloKeyword?: Maybe<OrderHaloKeyword>;
};

export type CreateOrderInput = {
  customerCampaignId: Scalars["Int"];
  customerId: Scalars["Int"];
  customerKeywordId?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  partnerProductId: Scalars["Int"];
  quantity?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  startImmediately?: InputMaybe<Scalars["Boolean"]>;
  topic?: InputMaybe<Scalars["String"]>;
  trackingId?: InputMaybe<Scalars["String"]>;
};

export type CreateOrderResponse = {
  __typename?: "CreateOrderResponse";
  order?: Maybe<Order>;
};

export type CreateOrdersInput = {
  orders?: InputMaybe<Array<OrderInput>>;
};

export type CreateOrdersResponse = {
  __typename?: "CreateOrdersResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orders?: Maybe<Array<Order>>;
};

export type CreatePartnerAvailableProductInput = {
  partnerId: Scalars["Int"];
  productId: Scalars["String"];
  wholesalePrice: Scalars["Float"];
};

export type CreatePartnerAvailableProductResponse = {
  __typename?: "CreatePartnerAvailableProductResponse";
  partnerAvailableProduct: PartnerAvailableProduct;
};

export type CreatePartnerBillingContactInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  partnerId: Scalars["Int"];
};

export type CreatePartnerBillingContactResponse = {
  __typename?: "CreatePartnerBillingContactResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerBillingContact?: Maybe<PartnerBillingContact>;
};

export type CreatePartnerInput = {
  billingEmail?: InputMaybe<Scalars["String"]>;
  isBillingEnforced?: InputMaybe<Scalars["Boolean"]>;
  isUpfrontPaymentRequired: Scalars["Boolean"];
  name: Scalars["String"];
  portal?: InputMaybe<Scalars["String"]>;
};

export type CreatePartnerInvoiceInput = {
  orderIds: Array<Scalars["Int"]>;
  partnerId: Scalars["Int"];
};

export type CreatePartnerInvoiceResponse = {
  __typename?: "CreatePartnerInvoiceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerInvoice?: Maybe<PartnerInvoice>;
};

export type CreatePartnerPackageInput = {
  monthlySpend: Scalars["Float"];
  months: Scalars["Int"];
  name: Scalars["String"];
  partnerId: Scalars["Int"];
};

export type CreatePartnerPackageItemInput = {
  isRecurring: Scalars["Boolean"];
  monthIndex: Scalars["Int"];
  partnerPackageId: Scalars["Int"];
  partnerProductId: Scalars["Int"];
  quantity: Scalars["Int"];
};

export type CreatePartnerPackageItemResponse = {
  __typename?: "CreatePartnerPackageItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerPackageItem?: Maybe<PartnerPackageItem>;
};

export type CreatePartnerPackageResponse = {
  __typename?: "CreatePartnerPackageResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerPackage?: Maybe<PartnerPackage>;
};

export type CreatePartnerPaymentMethodSetupIntentInput = {
  partnerId: Scalars["Int"];
};

export type CreatePartnerPaymentMethodSetupIntentResponse = {
  __typename?: "CreatePartnerPaymentMethodSetupIntentResponse";
  clientSecret?: Maybe<Scalars["String"]>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreatePartnerProductInput = {
  activeAt?: InputMaybe<Scalars["DateTime"]>;
  checklistDefinitionId?: InputMaybe<Scalars["Int"]>;
  inactiveAt?: InputMaybe<Scalars["DateTime"]>;
  initialPrice: Scalars["Float"];
  name: Scalars["String"];
  partnerId: Scalars["Int"];
  productId: Scalars["String"];
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type CreatePartnerProductPriceInput = {
  partnerProductId: Scalars["Int"];
  price: Scalars["Float"];
  startsAt: Scalars["DateTime"];
};

export type CreatePartnerProductPriceResponse = {
  __typename?: "CreatePartnerProductPriceResponse";
  partnerProductPrice: PartnerProductPrice;
};

export type CreatePartnerProductResponse = {
  __typename?: "CreatePartnerProductResponse";
  partnerProduct?: Maybe<PartnerProduct>;
};

export type CreatePartnerProductWholesalePriceInput = {
  effectiveAt: Scalars["DateTime"];
  partnerId: Scalars["Int"];
  productId: Scalars["String"];
  wholesaleAmount: Scalars["Float"];
};

export type CreatePartnerProductWholesalePriceResponse = {
  __typename?: "CreatePartnerProductWholesalePriceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerProductWholesalePrice?: Maybe<PartnerProductWholesalePrice>;
};

export type CreatePartnerResponse = {
  __typename?: "CreatePartnerResponse";
  partner?: Maybe<Partner>;
};

export type CreatePartnerTaskTypeVendorInput = {
  partnerId: Scalars["Int"];
  taskTypeId: Scalars["String"];
  vendorId: Scalars["Int"];
};

export type CreatePartnerTaskTypeVendorResponse = {
  __typename?: "CreatePartnerTaskTypeVendorResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerTaskTypeVendor?: Maybe<PartnerTaskTypeVendor>;
};

export type CreatePartnerUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  isAdmin: Scalars["Boolean"];
  lastName: Scalars["String"];
  partnerId: Scalars["Int"];
  password?: InputMaybe<Scalars["String"]>;
  portal?: InputMaybe<Scalars["String"]>;
};

export type CreatePartnerUserResponse = {
  __typename?: "CreatePartnerUserResponse";
  partnerUser?: Maybe<PartnerUser>;
};

export type CreateProposedCustomerKeywordInput = {
  country?: InputMaybe<Scalars["String"]>;
  customerKeywordProposalId: Scalars["Int"];
  isPrimary: Scalars["Boolean"];
  landingPageUrl?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type CreateProposedCustomerKeywordResponse = {
  __typename?: "CreateProposedCustomerKeywordResponse";
  proposedCustomerKeyword: ProposedCustomerKeyword;
};

export type CreatePublisherInput = {
  contactEmail?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  isAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
  isInvoiceRequired?: InputMaybe<Scalars["Boolean"]>;
  isSlowPartner?: InputMaybe<Scalars["Boolean"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  paymentMethod?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  portal?: InputMaybe<Scalars["String"]>;
};

export type CreatePublisherLinkLocationInput = {
  isRCMPublishing: Scalars["Boolean"];
  linkLocationId: Scalars["Int"];
  publisherId: Scalars["Int"];
};

export type CreatePublisherLinkLocationResponse = {
  __typename?: "CreatePublisherLinkLocationResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherLinkLocation?: Maybe<PublisherLinkLocation>;
};

export type CreatePublisherPaymentInput = {
  amount: Scalars["Float"];
  orderId: Scalars["Int"];
  processor?: InputMaybe<Scalars["String"]>;
  processorReferenceId?: InputMaybe<Scalars["String"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
  statusId?: InputMaybe<Scalars["String"]>;
};

export type CreatePublisherPaymentResponse = {
  __typename?: "CreatePublisherPaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherPayment: PublisherPayment;
};

export type CreatePublisherResponse = {
  __typename?: "CreatePublisherResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisher?: Maybe<Publisher>;
};

export type CreatePublisherUserInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
};

export type CreatePublisherUserResponse = {
  __typename?: "CreatePublisherUserResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherUser: PublisherUser;
};

export type CreateSiteInput = {
  id: Scalars["String"];
};

export type CreateSitePostInput = {
  categoryId?: InputMaybe<Scalars["Int"]>;
  content: Scalars["String"];
  siteId?: InputMaybe<Scalars["String"]>;
  statusId?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type CreateSitePostResponse = {
  __typename?: "CreateSitePostResponse";
  sitePost: SitePost;
};

export type CreateSiteResponse = {
  __typename?: "CreateSiteResponse";
  site: Site;
};

export type CreateSystemTaskInprogressInput = {
  taskId: Scalars["Int"];
  userId: Scalars["Int"];
};

export type CreateSystemTaskInprogressResponse = {
  __typename?: "CreateSystemTaskInprogressResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type CreateUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  isSuperAdmin?: InputMaybe<Scalars["Boolean"]>;
  lastName: Scalars["String"];
};

export type CreateUserInteractionEventInput = {
  entityId?: InputMaybe<Scalars["Int"]>;
  entityName: Scalars["String"];
  eventData?: InputMaybe<Scalars["String"]>;
  eventType: Scalars["String"];
  userId: Scalars["Int"];
};

export type CreateUserInteractionEventResponse = {
  __typename?: "CreateUserInteractionEventResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type CreateUserProfessionalInput = {
  linkedInProfileUrl?: InputMaybe<Scalars["String"]>;
  resumeS3FileId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type CreateUserProfessionalResponse = {
  __typename?: "CreateUserProfessionalResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userProfessional: UserProfessional;
};

export type CreateUserResponse = {
  __typename?: "CreateUserResponse";
  user?: Maybe<User>;
};

export type CreateUserTaskPermissionInput = {
  productId?: InputMaybe<Scalars["String"]>;
  taskTypeId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
  vendorId: Scalars["Int"];
};

export type CreateUserTaskPermissionResponse = {
  __typename?: "CreateUserTaskPermissionResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userTaskPermission?: Maybe<UserTaskPermission>;
};

export type CreateVendorInput = {
  isExternal?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  partnerId?: InputMaybe<Scalars["Int"]>;
};

export type CreateVendorResponse = {
  __typename?: "CreateVendorResponse";
  vendor?: Maybe<Vendor>;
};

export type CreateVendorUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  vendorId: Scalars["Int"];
};

export type CreateVendorUserResponse = {
  __typename?: "CreateVendorUserResponse";
  vendorUser?: Maybe<VendorUser>;
};

export type CreateWorkflowNoteInput = {
  content: Scalars["String"];
  workflowId: Scalars["Int"];
};

export type CreateWritingSampleTopicInput = {
  additionalInformation?: InputMaybe<Scalars["String"]>;
  categoryId: Scalars["Int"];
  isActive: Scalars["Boolean"];
  primaryKeyword: Scalars["String"];
  topic: Scalars["String"];
  voice: Scalars["String"];
  website: Scalars["String"];
};

export type CreateWritingSampleTopicResponse = {
  __typename?: "CreateWritingSampleTopicResponse";
  writingSampleTopic: WritingSampleTopic;
};

export type CreativesScaleConfiguration = {
  __typename?: "CreativesScaleConfiguration";
  workInterests?: Maybe<Array<WorkInterest>>;
};

export type CreativesScaleTaskStatus = {
  __typename?: "CreativesScaleTaskStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CreativesScaleUser = {
  __typename?: "CreativesScaleUser";
  activeUserGrammarTest?: Maybe<UserGrammarTest>;
  activeWritingSample?: Maybe<UserWritingSample>;
  allTimeEarnedAmount: Scalars["Float"];
  avgStarRating: Scalars["Float"];
  countryOfResidence?: Maybe<Scalars["String"]>;
  countryOfResidenceCountries: Array<Country>;
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isAdmin: Scalars["Boolean"];
  isEligibleForPayments: Scalars["Boolean"];
  isRCMWriter: Scalars["Boolean"];
  isStripeOnboardingReminderEnabled: Scalars["Boolean"];
  latestUserGrammarTest?: Maybe<UserGrammarTest>;
  latestWritingSample?: Maybe<UserWritingSample>;
  onboardingStatus: OnboardingStatus;
  onboardingStatusId: Scalars["String"];
  onboardingStatusLastUpdatedAt?: Maybe<Scalars["DateTime"]>;
  optedInForUpdatesAt?: Maybe<Scalars["DateTime"]>;
  payments: CreativesScaleUserPaymentConnection;
  retryGrammarTestAt?: Maybe<Scalars["DateTime"]>;
  retryWritingSampleAt?: Maybe<Scalars["DateTime"]>;
  stripeAccountId?: Maybe<Scalars["String"]>;
  stripeAccountStatus: StripeOnboardingStatus;
  unpaidBalance: Scalars["Float"];
  user: User;
  userId: Scalars["Int"];
  workInterests: Array<WorkInterest>;
  writerClassification?: Maybe<Scalars["String"]>;
  writingSampleTopics: Array<WritingSampleTopic>;
};

export type CreativesScaleUserPaymentsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PaymentsSort>;
};

export type CreativesScaleUserConnection = {
  __typename?: "CreativesScaleUserConnection";
  nodes: Array<CreativesScaleUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CreativesScaleUserPayment = {
  __typename?: "CreativesScaleUserPayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  creativesScaleUser: CreativesScaleUser;
  creativesScaleUserId: Scalars["Int"];
  externalPaymentId?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  paymentBatchId?: Maybe<Scalars["Int"]>;
  paymentTypeId: Scalars["String"];
  status: CreativesScaleUserPaymentStatus;
  statusId: Scalars["String"];
  statusIdUpdatedAt: Scalars["DateTime"];
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars["Int"]>;
};

export type CreativesScaleUserPaymentConnection = {
  __typename?: "CreativesScaleUserPaymentConnection";
  nodes: Array<CreativesScaleUserPayment>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CreativesScaleUserPaymentStatus = {
  __typename?: "CreativesScaleUserPaymentStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type Customer = {
  __typename?: "Customer";
  accountManagerUser?: Maybe<User>;
  accountManagerUserId?: Maybe<Scalars["Int"]>;
  address?: Maybe<Scalars["String"]>;
  analyticsReportingInlineFrameUrl?: Maybe<Scalars["String"]>;
  averageRanking?: Maybe<Scalars["Float"]>;
  billableOrders: OrderConnection;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  currentCustomerCampaign?: Maybe<CustomerCampaign>;
  customerBillingConfig?: Maybe<CustomerBillingConfig>;
  customerBusinessListingSpec?: Maybe<CustomerBusinessListingSpec>;
  customerBusinessListingSpecs: CustomerBusinessListingSpecConnection;
  customerCampaigns: CustomerCampaignConnection;
  customerHoursOfOperationItems?: Maybe<Array<CustomerHoursOfOperationItem>>;
  customerInvoices?: Maybe<CustomerInvoiceConnection>;
  customerKeywords: CustomerKeywordConnection;
  customerPremiumBusinessListingSpec?: Maybe<CustomerPremiumBusinessListingSpec>;
  customerPremiumBusinessListingSpecImages: CustomerPremiumBusinessListingSpecImageConnection;
  customerQuestionnaire?: Maybe<CustomerQuestionnaire>;
  customerSiteCredential?: Maybe<CustomerSiteCredential>;
  customerStatusChanges: CustomerStatusChangeConnection;
  customerUsers: CustomerUserConnection;
  emailMessages?: Maybe<EmailMessageConnection>;
  gbpUrl?: Maybe<Scalars["String"]>;
  googleAccountId?: Maybe<Scalars["String"]>;
  hasMapRanking: Scalars["Boolean"];
  hasPremiumListing: Scalars["Boolean"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  isBillingVisible: Scalars["Boolean"];
  isBlogFulfilledByRCMWriter: Scalars["Boolean"];
  isGuestArticleFulfilledByRCMWriter: Scalars["Boolean"];
  keywordsImproved?: Maybe<Scalars["Int"]>;
  keywordsOnFirstPage?: Maybe<Scalars["Int"]>;
  linkLocationsPending: LinkLocationConnection;
  linkLocationsRejected: LinkLocationConnection;
  linkLocationsUsed: LinkLocationConnection;
  name: Scalars["String"];
  notes: NoteConnection;
  partner: Partner;
  partnerId: Scalars["Int"];
  phoneNumber?: Maybe<Scalars["String"]>;
  portal: Scalars["String"];
  rcmAccountManagerUser?: Maybe<User>;
  rcmAccountManagerUserId?: Maybe<Scalars["Int"]>;
  status: CustomerStatus;
  uberallBusinessId?: Maybe<Scalars["Int"]>;
  website: Scalars["String"];
};

export type CustomerAverageRankingArgs = {
  searchEngine?: InputMaybe<Scalars["String"]>;
};

export type CustomerBillableOrdersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<DateQuery>;
};

export type CustomerCurrentCustomerCampaignArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
};

export type CustomerCustomerBusinessListingSpecsArgs = {
  isListed?: InputMaybe<Scalars["Boolean"]>;
};

export type CustomerCustomerCampaignsArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
};

export type CustomerCustomerInvoicesArgs = {
  invoiceDate?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerInvoiceSort>;
  statusIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type CustomerCustomerKeywordsArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  isTrackingMapRank?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CustomerCustomerPremiumBusinessListingSpecImagesArgs = {
  imageTypeId?: InputMaybe<Scalars["String"]>;
};

export type CustomerCustomerStatusChangesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  processedAt?: InputMaybe<DateQuery>;
  sort?: InputMaybe<CustomerStatusChangeSort>;
};

export type CustomerCustomerUsersArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerUsersSort>;
};

export type CustomerEmailMessagesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CustomerKeywordsImprovedArgs = {
  searchEngine?: InputMaybe<Scalars["String"]>;
};

export type CustomerKeywordsOnFirstPageArgs = {
  searchEngine?: InputMaybe<Scalars["String"]>;
};

export type CustomerLinkLocationsPendingArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<LinkLocationsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  typeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type CustomerLinkLocationsRejectedArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<LinkLocationsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  typeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type CustomerLinkLocationsUsedArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<LinkLocationsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  typeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type CustomerNotesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CustomerBillingConfig = {
  __typename?: "CustomerBillingConfig";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  isAutoPayEnabled: Scalars["Boolean"];
  isBillingEnforced: Scalars["Boolean"];
  isUpfrontPaymentRequired: Scalars["Boolean"];
};

export type CustomerBillingContact = {
  __typename?: "CustomerBillingContact";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  deletedAt: Scalars["DateTime"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["Int"];
  lastName: Scalars["String"];
};

export type CustomerBusinessListingSpec = {
  __typename?: "CustomerBusinessListingSpec";
  address: Scalars["String"];
  addressDisplay: Scalars["Boolean"];
  city: Scalars["String"];
  contactName?: Maybe<Scalars["String"]>;
  country: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  email?: Maybe<Scalars["String"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  googleLocationId?: Maybe<Scalars["String"]>;
  hasPhysicalStorefront: Scalars["Boolean"];
  id: Scalars["Int"];
  isListed: Scalars["Boolean"];
  isReviewEnabled: Scalars["Boolean"];
  name?: Maybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  stateOrProvince: Scalars["String"];
  uberallCategoryId?: Maybe<Scalars["Int"]>;
  uberallListingDetails?: Maybe<UberallListingDetailsResponse>;
  uberallLocationId?: Maybe<Scalars["Int"]>;
  uberallSyncNeeded?: Maybe<Scalars["Boolean"]>;
  zipPostalCode: Scalars["String"];
};

export type CustomerBusinessListingSpecConnection = {
  __typename?: "CustomerBusinessListingSpecConnection";
  nodes: Array<CustomerBusinessListingSpec>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerCampaign = {
  __typename?: "CustomerCampaign";
  addedByUserId: Scalars["Int"];
  amount: Scalars["Float"];
  campaignCategory: CampaignCategory;
  campaignCategoryId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerCampaignInvoiceCharges?: Maybe<Array<CustomerCampaignInvoiceCharge>>;
  customerId: Scalars["Int"];
  endsAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isCurrent: Scalars["Boolean"];
  name: Scalars["String"];
  pausedAt?: Maybe<Scalars["DateTime"]>;
  startsAt: Scalars["DateTime"];
};

export type CustomerCampaignConnection = {
  __typename?: "CustomerCampaignConnection";
  nodes: Array<CustomerCampaign>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerCampaignInvoice = {
  __typename?: "CustomerCampaignInvoice";
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  invoiceData: CustomerCampaignInvoiceData;
  invoiceDate: Scalars["DateTime"];
  status: CustomerCampaignInvoiceStatus;
  statusId: Scalars["String"];
};

export type CustomerCampaignInvoiceCharge = {
  __typename?: "CustomerCampaignInvoiceCharge";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerCampaign: CustomerCampaign;
  customerCampaignId: Scalars["Int"];
  customerCampaignInvoiceId: Scalars["Int"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  monthIndex: Scalars["Int"];
  status: CustomerCampaignInvoiceChargeStatus;
  statusId: Scalars["String"];
};

export type CustomerCampaignInvoiceChargeStatus = {
  __typename?: "CustomerCampaignInvoiceChargeStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerCampaignInvoiceConnection = {
  __typename?: "CustomerCampaignInvoiceConnection";
  nodes: Array<CustomerCampaignInvoice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerCampaignInvoiceData = {
  __typename?: "CustomerCampaignInvoiceData";
  invoiceCharges: Array<CustomerCampaignInvoiceCharge>;
  invoicePayments: Array<CustomerCampaignInvoicePayment>;
  totalChargesAmount: Scalars["Float"];
  totalDue: Scalars["Float"];
  totalPaymentsAmount: Scalars["Float"];
};

export type CustomerCampaignInvoicePayment = {
  __typename?: "CustomerCampaignInvoicePayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerCampaignInvoiceId: Scalars["Int"];
  customerPaymentMethodId?: Maybe<Scalars["Int"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  notes?: Maybe<Scalars["String"]>;
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  status: CustomerCampaignInvoicePaymentStatus;
  statusId: Scalars["String"];
};

export type CustomerCampaignInvoicePaymentStatus = {
  __typename?: "CustomerCampaignInvoicePaymentStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum CustomerCampaignInvoiceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type CustomerCampaignInvoiceStatus = {
  __typename?: "CustomerCampaignInvoiceStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerConnection = {
  __typename?: "CustomerConnection";
  nodes: Array<Customer>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerConversation = {
  __typename?: "CustomerConversation";
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerMessages: CustomerMessageConnection;
  helpRequestedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["String"];
  subject: Scalars["String"];
};

export type CustomerConversationCustomerMessagesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CustomerMessagesSort>;
};

export type CustomerConversationConnection = {
  __typename?: "CustomerConversationConnection";
  nodes: Array<CustomerConversation>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerConversationsSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
}

export type CustomerHoursOfOperationItem = {
  __typename?: "CustomerHoursOfOperationItem";
  closeAt: Scalars["String"];
  customerId: Scalars["Int"];
  dayIndex: Scalars["Int"];
  id: Scalars["Int"];
  isOpen: Scalars["Boolean"];
  openAt: Scalars["String"];
};

export type CustomerInvoice = {
  __typename?: "CustomerInvoice";
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  invoiceData: InvoiceData;
  invoiceDate: Scalars["DateTime"];
  status: CustomerInvoiceStatus;
  statusId: Scalars["String"];
};

export type CustomerInvoiceCharge = {
  __typename?: "CustomerInvoiceCharge";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerInvoiceId: Scalars["Int"];
  id: Scalars["Int"];
  order: Order;
  orderId: Scalars["Int"];
  status: CustomerInvoiceChargeStatus;
  statusId: Scalars["String"];
  typeId: Scalars["String"];
};

export type CustomerInvoiceChargeStatus = {
  __typename?: "CustomerInvoiceChargeStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerInvoiceConnection = {
  __typename?: "CustomerInvoiceConnection";
  nodes: Array<CustomerInvoice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerInvoicePayment = {
  __typename?: "CustomerInvoicePayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerInvoiceId: Scalars["Int"];
  customerPaymentMethodId?: Maybe<Scalars["Int"]>;
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  notes?: Maybe<Scalars["String"]>;
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  status: CustomerInvoicePaymentStatus;
  statusId: Scalars["String"];
};

export type CustomerInvoicePaymentStatus = {
  __typename?: "CustomerInvoicePaymentStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerInvoiceRefund = {
  __typename?: "CustomerInvoiceRefund";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerInvoiceId: Scalars["Int"];
  customerInvoicePaymentId: Scalars["Int"];
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  reason: Scalars["String"];
  statusId: Scalars["String"];
};

export enum CustomerInvoiceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type CustomerInvoiceStatus = {
  __typename?: "CustomerInvoiceStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerKeyword = {
  __typename?: "CustomerKeyword";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  displayName: Scalars["String"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  isActive: Scalars["Boolean"];
  isPrimary: Scalars["Boolean"];
  isTrackingMapRank: Scalars["Boolean"];
  isTrackingRank: Scalars["Boolean"];
  landingPageUrl?: Maybe<Scalars["String"]>;
  latestRank?: Maybe<CustomerKeywordRanking>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  startRank?: Maybe<CustomerKeywordRanking>;
};

export type CustomerKeywordLatestRankArgs = {
  searchEngine: Scalars["String"];
};

export type CustomerKeywordStartRankArgs = {
  searchEngine: Scalars["String"];
};

export type CustomerKeywordConnection = {
  __typename?: "CustomerKeywordConnection";
  nodes: Array<CustomerKeyword>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerKeywordProposal = {
  __typename?: "CustomerKeywordProposal";
  createdAt: Scalars["DateTime"];
  customerConversationId?: Maybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  orderId: Scalars["Int"];
  proposedCustomerKeywords: Array<ProposedCustomerKeyword>;
  summary?: Maybe<Scalars["String"]>;
};

export type CustomerKeywordProposalProposedCustomerKeywordsArgs = {
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  isRejected?: InputMaybe<Scalars["Boolean"]>;
  sort?: InputMaybe<ProposedCustomerKeywordSort>;
};

export type CustomerKeywordRanking = {
  __typename?: "CustomerKeywordRanking";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  rank: Scalars["Int"];
  searchEngine: Scalars["String"];
};

export type CustomerKeywordRankingConnection = {
  __typename?: "CustomerKeywordRankingConnection";
  nodes: Array<CustomerKeywordRanking>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerKeywordRankingSort {
  DateAsc = "DATE_ASC",
  DateDesc = "DATE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type CustomerLinkBuild = {
  __typename?: "CustomerLinkBuild";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  order: Order;
  orderId: Scalars["Int"];
  summary?: Maybe<Scalars["String"]>;
};

export type CustomerMessage = {
  __typename?: "CustomerMessage";
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customerConversation: CustomerConversation;
  id: Scalars["Int"];
  isFromCustomer: Scalars["Boolean"];
  user: User;
};

export type CustomerMessageConnection = {
  __typename?: "CustomerMessageConnection";
  nodes: Array<CustomerMessage>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerMessagesSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
}

export type CustomerPaymentMethod = {
  __typename?: "CustomerPaymentMethod";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  externalId: Scalars["String"];
  externalProvider: Scalars["String"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  type: Scalars["String"];
};

export type CustomerPremiumBusinessListingSpec = {
  __typename?: "CustomerPremiumBusinessListingSpec";
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  hotelAmenities: Array<Scalars["String"]>;
  hotelBathroom?: Maybe<Scalars["String"]>;
  hotelFrontDesk?: Maybe<Scalars["String"]>;
  hotelHousekeeping?: Maybe<Scalars["String"]>;
  hotelHousekeepingFrequency?: Maybe<Scalars["String"]>;
  hotelMinimumStay?: Maybe<Scalars["String"]>;
  hotelOnSiteStaff?: Maybe<Scalars["Boolean"]>;
  hotelSecurity?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isAlwaysOpen?: Maybe<Scalars["Boolean"]>;
  isBlackOwned?: Maybe<Scalars["Boolean"]>;
  isWomenOwned?: Maybe<Scalars["Boolean"]>;
  priceRangeLevel?: Maybe<Scalars["Int"]>;
  shouldReceiveLeadMessages?: Maybe<Scalars["Boolean"]>;
  specialHoursOfOperation?: Maybe<Scalars["String"]>;
  yearsInBusiness?: Maybe<Scalars["Int"]>;
};

export type CustomerPremiumBusinessListingSpecImage = {
  __typename?: "CustomerPremiumBusinessListingSpecImage";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  file: File;
  fileId: Scalars["String"];
  id: Scalars["Int"];
  imageTypeId: Scalars["String"];
};

export type CustomerPremiumBusinessListingSpecImageConnection = {
  __typename?: "CustomerPremiumBusinessListingSpecImageConnection";
  nodes: Array<CustomerPremiumBusinessListingSpecImage>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerProposal = {
  __typename?: "CustomerProposal";
  id: Scalars["Int"];
  orderContentStrategies: Array<OrderContentStrategy>;
  orderHaloKeywords: Array<OrderHaloKeyword>;
  proposedOrderTopics: Array<ProposedOrderTopic>;
  summary?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type CustomerProposalOrderContentStrategiesArgs = {
  sort?: InputMaybe<OrderContentStrategiesSort>;
};

export type CustomerQuestionnaire = {
  __typename?: "CustomerQuestionnaire";
  businessDescription?: Maybe<Scalars["String"]>;
  callToActionUrls?: Maybe<Scalars["String"]>;
  competitorWebsites?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  contentPreference?: Maybe<Scalars["String"]>;
  contentPublishingPermissions?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerMarketSize?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isAddImage?: Maybe<Scalars["Boolean"]>;
  isRCMPublishing?: Maybe<Scalars["Boolean"]>;
  preferredVoice?: Maybe<Scalars["String"]>;
  productsOffered?: Maybe<Scalars["String"]>;
  purposeOfContent?: Maybe<Scalars["String"]>;
  serviceArea?: Maybe<Scalars["String"]>;
  targetArea?: Maybe<Scalars["String"]>;
  targetAudience?: Maybe<Scalars["String"]>;
  targetInternalLinks?: Maybe<Scalars["String"]>;
  targetKeywords?: Maybe<Scalars["String"]>;
  websiteCms?: Maybe<Scalars["String"]>;
  websiteHosting?: Maybe<Scalars["String"]>;
  whoCanChangeWebsite?: Maybe<Scalars["String"]>;
};

export type CustomerSiteAudit = {
  __typename?: "CustomerSiteAudit";
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  file?: Maybe<File>;
  fileId?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  order: Order;
  orderId: Scalars["Int"];
  summary?: Maybe<Scalars["String"]>;
};

export type CustomerSiteCredential = {
  __typename?: "CustomerSiteCredential";
  adminUrl: Scalars["String"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  user: Scalars["String"];
};

export type CustomerStatus = {
  __typename?: "CustomerStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerStatusChange = {
  __typename?: "CustomerStatusChange";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  customer: Customer;
  customerId: Scalars["Int"];
  effectiveAt: Scalars["Date"];
  id: Scalars["Int"];
  reason?: Maybe<Scalars["String"]>;
  status: CustomerStatus;
  statusId: Scalars["String"];
};

export type CustomerStatusChangeConnection = {
  __typename?: "CustomerStatusChangeConnection";
  nodes: Array<CustomerStatusChange>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerStatusChangeSort {
  EffectiveAtAsc = "EFFECTIVE_AT_ASC",
  EffectiveAtDesc = "EFFECTIVE_AT_DESC",
}

export type CustomerUser = {
  __typename?: "CustomerUser";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerId: Scalars["Int"];
  customerUserSsoCodes: CustomerUserSsoCodeConnection;
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  user: User;
  userId: Scalars["Int"];
};

export type CustomerUserCustomerUserSsoCodesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type CustomerUserConnection = {
  __typename?: "CustomerUserConnection";
  nodes: Array<CustomerUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CustomerUserSsoCode = {
  __typename?: "CustomerUserSsoCode";
  code: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customerUserId: Scalars["Int"];
  expiresAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  url: Scalars["String"];
};

export type CustomerUserSsoCodeConnection = {
  __typename?: "CustomerUserSsoCodeConnection";
  nodes: Array<CustomerUserSsoCode>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum CustomerUsersSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export enum CustomersKeywordSort {
  CurrentRankAsc = "CURRENT_RANK_ASC",
  CurrentRankDesc = "CURRENT_RANK_DESC",
  IsPrimaryAsc = "IS_PRIMARY_ASC",
  IsPrimaryDesc = "IS_PRIMARY_DESC",
  LatestRankAsc = "LATEST_RANK_ASC",
  LatestRankDesc = "LATEST_RANK_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export enum CustomersSort {
  EndDateAsc = "END_DATE_ASC",
  EndDateDesc = "END_DATE_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type DateQuery = {
  eq?: InputMaybe<Scalars["DateTime"]>;
  gt?: InputMaybe<Scalars["DateTime"]>;
  gte?: InputMaybe<Scalars["DateTime"]>;
  lt?: InputMaybe<Scalars["DateTime"]>;
  lte?: InputMaybe<Scalars["DateTime"]>;
  orIsNull?: InputMaybe<Scalars["Boolean"]>;
};

export type DeleteAmTaskInput = {
  amTaskId: Scalars["Int"];
};

export type DeleteAmTaskResponse = {
  __typename?: "DeleteAmTaskResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteCustomerCampaignInput = {
  customerCampaignId: Scalars["Int"];
};

export type DeleteCustomerCampaignInvoiceInput = {
  customerCampaignInvoiceId: Scalars["Int"];
};

export type DeleteCustomerCampaignInvoiceResponse = {
  __typename?: "DeleteCustomerCampaignInvoiceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteCustomerCampaignResponse = {
  __typename?: "DeleteCustomerCampaignResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteCustomerPremiumBusinessListingSpecImageInput = {
  customerPremiumBusinessListingSpecImageId: Scalars["Int"];
};

export type DeleteCustomerPremiumBusinessListingSpecImageResponse = {
  __typename?: "DeleteCustomerPremiumBusinessListingSpecImageResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteCustomerStatusChangeInput = {
  customerStatusChangeId: Scalars["Int"];
};

export type DeleteCustomerStatusChangeResponse = {
  __typename?: "DeleteCustomerStatusChangeResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteDomainCategoryInput = {
  id: Scalars["Int"];
};

export type DeleteDomainCategoryResponse = {
  __typename?: "DeleteDomainCategoryResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteFulfillmentRuleInput = {
  fulfillmentRuleId: Scalars["Int"];
};

export type DeleteFulfillmentRuleResponse = {
  __typename?: "DeleteFulfillmentRuleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteLinkLocationInput = {
  linkLocationId: Scalars["Int"];
};

export type DeleteLinkLocationResponse = {
  __typename?: "DeleteLinkLocationResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteOrderContentStrategyInput = {
  orderContentStrategyId: Scalars["Int"];
};

export type DeleteOrderContentStrategyResponse = {
  __typename?: "DeleteOrderContentStrategyResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteOrderInput = {
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  orderId: Scalars["Int"];
};

export type DeleteOrderResponse = {
  __typename?: "DeleteOrderResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeletePartnerBillingContactInput = {
  partnerBillingContactId: Scalars["Int"];
};

export type DeletePartnerBillingContactResponse = {
  __typename?: "DeletePartnerBillingContactResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeletePartnerPaymentMethodInput = {
  partnerPaymentMethodId: Scalars["Int"];
};

export type DeletePartnerPaymentMethodResponse = {
  __typename?: "DeletePartnerPaymentMethodResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeletePartnerProductInput = {
  partnerProductId: Scalars["Int"];
};

export type DeletePartnerProductPriceInput = {
  partnerProductPriceId: Scalars["Int"];
};

export type DeletePartnerProductPriceResponse = {
  __typename?: "DeletePartnerProductPriceResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeletePartnerProductResponse = {
  __typename?: "DeletePartnerProductResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeletePartnerProductWholesalePriceInput = {
  partnerProductWholesalePriceId: Scalars["Int"];
};

export type DeletePartnerProductWholesalePriceResponse = {
  __typename?: "DeletePartnerProductWholesalePriceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeletePartnerTaskTypeVendorInput = {
  partnerTaskTypeVendorId: Scalars["Int"];
};

export type DeletePartnerTaskTypeVendorResponse = {
  __typename?: "DeletePartnerTaskTypeVendorResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteProposedCustomerKeywordInput = {
  proposedCustomerKeywordId: Scalars["Int"];
};

export type DeleteProposedCustomerKeywordResponse = {
  __typename?: "DeleteProposedCustomerKeywordResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteSitePostInput = {
  sitePostId: Scalars["Int"];
};

export type DeleteSitePostResponse = {
  __typename?: "DeleteSitePostResponse";
  isDeleted: Scalars["Boolean"];
};

export type DeleteSystemTaskInprogressInput = {
  taskId: Scalars["Int"];
};

export type DeleteSystemTaskInprogressResponse = {
  __typename?: "DeleteSystemTaskInprogressResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type DeleteUserTaskPermissionInput = {
  userTaskPermissionId: Scalars["Int"];
};

export type DeleteUserTaskPermissionResponse = {
  __typename?: "DeleteUserTaskPermissionResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type Domain = {
  __typename?: "Domain";
  availabilityLastCheckedAt?: Maybe<Scalars["DateTime"]>;
  canRefreshAvailability: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  domainCategories: DomainCategoryConnection;
  expiresAt?: Maybe<Scalars["Date"]>;
  id: Scalars["String"];
  purchaseError?: Maybe<Scalars["String"]>;
  purchasePrice?: Maybe<Scalars["Float"]>;
  purchaseRequestedByUser?: Maybe<User>;
  purchaseRequestedByUserId?: Maybe<Scalars["Int"]>;
  purchasedAt?: Maybe<Scalars["DateTime"]>;
  registrar?: Maybe<Scalars["String"]>;
  registrarId?: Maybe<Scalars["String"]>;
  site?: Maybe<Site>;
  status: DomainStatus;
};

export type DomainDomainCategoriesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type DomainCategory = {
  __typename?: "DomainCategory";
  category?: Maybe<Category>;
  categoryId: Scalars["Int"];
  domain?: Maybe<Domain>;
  domainId: Scalars["String"];
  id: Scalars["Int"];
};

export type DomainCategoryConnection = {
  __typename?: "DomainCategoryConnection";
  nodes: Array<DomainCategory>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type DomainConnection = {
  __typename?: "DomainConnection";
  nodes: Array<Domain>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type DomainMetrics = {
  __typename?: "DomainMetrics";
  authorityScore: Scalars["Int"];
  domainAuthority: Scalars["Int"];
  organitcTraffic: Scalars["Int"];
  spamScore: Scalars["Int"];
};

export type DomainMetricsRespose = {
  __typename?: "DomainMetricsRespose";
  domainMetrics?: Maybe<DomainMetrics>;
};

export type DomainStatus = {
  __typename?: "DomainStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum DomainsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type EmailMessage = {
  __typename?: "EmailMessage";
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  customerId?: Maybe<Scalars["Int"]>;
  fromAddress: Scalars["String"];
  fromName: Scalars["String"];
  id: Scalars["Int"];
  partnerId?: Maybe<Scalars["Int"]>;
  sentAt?: Maybe<Scalars["DateTime"]>;
  subject: Scalars["String"];
  toAddress: Scalars["String"];
  toName: Scalars["String"];
  userId?: Maybe<Scalars["Int"]>;
};

export type EmailMessageConnection = {
  __typename?: "EmailMessageConnection";
  nodes: Array<EmailMessage>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type EnableAutopayInformation = {
  __typename?: "EnableAutopayInformation";
  dueImmediatelyAmount?: Maybe<Scalars["Float"]>;
  nextBillingAmount?: Maybe<Scalars["Float"]>;
  nextBillingDate?: Maybe<Scalars["DateTime"]>;
};

export type FeedbackContentItem = {
  __typename?: "FeedbackContentItem";
  contentItem: ContentItem;
  contentItemId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  feedback: Scalars["String"];
  id: Scalars["Int"];
  rating: Scalars["Int"];
  reviewUser: User;
  reviewerUserId: Scalars["Int"];
};

export type File = {
  __typename?: "File";
  contentType: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  name: Scalars["String"];
  sizeInBytes: Scalars["Int"];
  url: Scalars["String"];
};

export type FulfillmentRule = {
  __typename?: "FulfillmentRule";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  partnerId?: Maybe<Scalars["Int"]>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["String"]>;
  taskType?: Maybe<TaskType>;
  taskTypeId?: Maybe<Scalars["String"]>;
  vendor?: Maybe<Vendor>;
  vendorId: Scalars["Int"];
};

export type FulfillmentRuleConnection = {
  __typename?: "FulfillmentRuleConnection";
  nodes: Array<FulfillmentRule>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type GbpItem = {
  __typename?: "GbpItem";
  content: Scalars["String"];
  id: Scalars["Int"];
  readMoreUrl?: Maybe<Scalars["String"]>;
  thumbnailFile?: Maybe<File>;
};

export type GenerateUrlForFileUploadInput = {
  contentType: Scalars["String"];
  name: Scalars["String"];
  sizeInBytes: Scalars["Int"];
};

export type GenerateUrlForFileUploadResponse = {
  __typename?: "GenerateUrlForFileUploadResponse";
  expiresAt: Scalars["DateTime"];
  fileId: Scalars["String"];
  url: Scalars["String"];
};

export type GenerateUrlForFileUploadSignupInput = {
  contentType: Scalars["String"];
  name: Scalars["String"];
  sizeInBytes: Scalars["Int"];
};

export type GenerateUrlForFileUploadSignupResponse = {
  __typename?: "GenerateUrlForFileUploadSignupResponse";
  expiresAt: Scalars["DateTime"];
  fileId: Scalars["String"];
  url: Scalars["String"];
};

export type GenerateUrlForStripeDashboardResponse = {
  __typename?: "GenerateUrlForStripeDashboardResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  url?: Maybe<Scalars["String"]>;
};

export type GenerateUrlForStripeOnboardingResponse = {
  __typename?: "GenerateUrlForStripeOnboardingResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  url?: Maybe<Scalars["String"]>;
};

export type GoogleAccount = {
  __typename?: "GoogleAccount";
  accountName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  verificationState?: Maybe<Scalars["String"]>;
  vettedState?: Maybe<Scalars["String"]>;
};

export type GoogleAccountsResponse = {
  __typename?: "GoogleAccountsResponse";
  accounts?: Maybe<Array<GoogleAccount>>;
};

export type GoogleLocation = {
  __typename?: "GoogleLocation";
  name?: Maybe<Scalars["String"]>;
  storeCode?: Maybe<Scalars["String"]>;
  storefrontAddress?: Maybe<PostalAddress>;
  title?: Maybe<Scalars["String"]>;
  websiteUri?: Maybe<Scalars["String"]>;
};

export type GoogleLocationsResponse = {
  __typename?: "GoogleLocationsResponse";
  locations?: Maybe<Array<GoogleLocation>>;
};

export type GoogleReview = {
  __typename?: "GoogleReview";
  comment?: Maybe<Scalars["String"]>;
  createTime?: Maybe<Scalars["DateTime"]>;
  name?: Maybe<Scalars["String"]>;
  reviewId?: Maybe<Scalars["String"]>;
  reviewReply?: Maybe<ReviewReply>;
  reviewer?: Maybe<Reviewer>;
  starRating?: Maybe<Scalars["String"]>;
  updateTime?: Maybe<Scalars["DateTime"]>;
};

export type GoogleReviewReplyInput = {
  account?: InputMaybe<Scalars["String"]>;
  comment?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  reviewId?: InputMaybe<Scalars["String"]>;
};

export type GoogleReviewReplyResponse = {
  __typename?: "GoogleReviewReplyResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type GoogleReviewRespose = {
  __typename?: "GoogleReviewRespose";
  averageRating?: Maybe<Scalars["Float"]>;
  nextPageToken?: Maybe<Scalars["String"]>;
  reviews?: Maybe<Array<GoogleReview>>;
  totalReviewCount?: Maybe<Scalars["Int"]>;
};

export type GrammarTestQuestion = {
  __typename?: "GrammarTestQuestion";
  answers: Array<GrammarTestQuestionAnswer>;
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  prompt: Scalars["String"];
  totalAttempts: Scalars["Int"];
  totalCorrectAttempts: Scalars["Int"];
};

export type GrammarTestQuestionAnswer = {
  __typename?: "GrammarTestQuestionAnswer";
  content: Scalars["String"];
  grammarTestQuestionId: Scalars["Int"];
  id: Scalars["Int"];
  isCorrect?: Maybe<Scalars["Boolean"]>;
};

export type GrammarTestQuestionAnswerInput = {
  content: Scalars["String"];
  id?: InputMaybe<Scalars["Int"]>;
  isCorrect: Scalars["Boolean"];
};

export type GrammarTestQuestionConnection = {
  __typename?: "GrammarTestQuestionConnection";
  nodes: Array<GrammarTestQuestion>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type ImportInvoicesInput = {
  content: Scalars["String"];
};

export type ImportInvoicesResponse = {
  __typename?: "ImportInvoicesResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  results: Array<ImportInvoicesResult>;
};

export type ImportInvoicesResult = {
  __typename?: "ImportInvoicesResult";
  message: Scalars["String"];
  ok: Scalars["Boolean"];
  orderId: Scalars["Int"];
};

export type InputFieldError = {
  __typename?: "InputFieldError";
  fieldName: Scalars["String"];
  message: Scalars["String"];
};

export type InvoiceData = {
  __typename?: "InvoiceData";
  invoiceCharges: Array<CustomerInvoiceCharge>;
  invoicePayments: Array<CustomerInvoicePayment>;
  totalChargesAmount: Scalars["Float"];
  totalDue: Scalars["Float"];
  totalPaymentsAmount: Scalars["Float"];
  totalRefundsAmount: Scalars["Float"];
};

export type KeywordLocation = {
  __typename?: "KeywordLocation";
  country_iso_code: Scalars["String"];
  location_code: Scalars["Int"];
  location_code_parent?: Maybe<Scalars["Int"]>;
  location_name: Scalars["String"];
  location_type: Scalars["String"];
};

export type KeywordSuggestion = {
  __typename?: "KeywordSuggestion";
  avgMonthlySearches?: Maybe<Scalars["Int"]>;
  competitionIndex?: Maybe<Scalars["Int"]>;
  keyword: Scalars["String"];
};

export type KeywordsSuggestionRespose = {
  __typename?: "KeywordsSuggestionRespose";
  keywordSuggestions?: Maybe<Array<KeywordSuggestion>>;
};

export type LinkLocation = {
  __typename?: "LinkLocation";
  allowsAIContent?: Maybe<Scalars["Boolean"]>;
  allowsCbdContent?: Maybe<Scalars["Boolean"]>;
  allowsOnlyOneLinkInContent?: Maybe<Scalars["Boolean"]>;
  allowsWeapons?: Maybe<Scalars["Boolean"]>;
  authorityScore?: Maybe<Scalars["Int"]>;
  autoAssign?: Maybe<Scalars["Boolean"]>;
  contactEmail?: Maybe<Scalars["String"]>;
  contactName?: Maybe<Scalars["String"]>;
  contactPhone?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Float"]>;
  createdAt: Scalars["DateTime"];
  domain: Scalars["String"];
  domainAuthority: Scalars["Int"];
  id: Scalars["Int"];
  internalQuality?: Maybe<Scalars["Int"]>;
  lastMetricsUpdatedAt?: Maybe<Scalars["DateTime"]>;
  linkLocationCategories: Array<LinkLocationCategory>;
  linkLocationCredential?: Maybe<LinkLocationCredential>;
  minimumWordCount?: Maybe<Scalars["Int"]>;
  monthlyTraffic?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  publishedOrders: OrderConnection;
  publisherLinkLocation?: Maybe<PublisherLinkLocation>;
  spam?: Maybe<Scalars["Int"]>;
  status: LinkLocationStatus;
  statusId: Scalars["String"];
  type: LinkLocationType;
  typeId: Scalars["String"];
};

export type LinkLocationCategory = {
  __typename?: "LinkLocationCategory";
  category: Category;
  categoryId: Scalars["Int"];
  id: Scalars["Int"];
  linkLocation: LinkLocation;
  linkLocationId: Scalars["Int"];
};

export type LinkLocationConnection = {
  __typename?: "LinkLocationConnection";
  nodes: Array<LinkLocation>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type LinkLocationCredential = {
  __typename?: "LinkLocationCredential";
  adminUrl: Scalars["String"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  userName: Scalars["String"];
};

export type LinkLocationStatus = {
  __typename?: "LinkLocationStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type LinkLocationType = {
  __typename?: "LinkLocationType";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum LinkLocationsSort {
  CostAsc = "cost_asc",
  CostDesc = "cost_desc",
  DaAsc = "da_asc",
  DaDesc = "da_desc",
  IdAsc = "id_asc",
  IdDesc = "id_desc",
  TrafficAsc = "traffic_asc",
  TrafficDesc = "traffic_desc",
}

export type LocationResponse = {
  __typename?: "LocationResponse";
  nodes: Array<KeywordLocation>;
};

export type LoginGoogleCallbackInput = {
  code: Scalars["String"];
};

export type LoginGoogleCallbackResponse = {
  __typename?: "LoginGoogleCallbackResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type LoginGoogleResponse = {
  __typename?: "LoginGoogleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  url?: Maybe<Scalars["String"]>;
};

export type LoginUserInput = {
  email?: InputMaybe<Scalars["String"]>;
  googleAccessToken?: InputMaybe<Scalars["String"]>;
  magicLink?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  ssoCode?: InputMaybe<Scalars["String"]>;
  temporaryLoginCode?: InputMaybe<Scalars["String"]>;
};

export type LoginUserResponse = {
  __typename?: "LoginUserResponse";
  isLoggedIn?: Maybe<Scalars["Boolean"]>;
};

export type LogoutUserResponse = {
  __typename?: "LogoutUserResponse";
  isLoggedOut: Scalars["Boolean"];
};

export type Mutation = {
  __typename?: "Mutation";
  approvePaymentBatchFunding: ApprovePaymentBatchFundingResponse;
  approvePaymentBatchFundingPaypal: ApprovePaymentBatchFundingPaypalResponse;
  approveUserWritingSample: ApproveUserWritingSampleResponse;
  changePassword: ChangePasswordResponse;
  checkout: CheckoutResponse;
  claimTask: ClaimTaskResponse;
  clearProposedCustomerKeywordsRejection: ClearProposedCustomerKeywordsRejectionResponse;
  confirmEmail: ConfirmEmailResponse;
  createAmTask: CreateAmTaskResponse;
  createBlockDomainCustomers: CreateBlockDomainCustomersResponse;
  createBulkProposedCustomerKeywords: CreateBulkProposedCustomerKeywordsResponse;
  createBusinessSubscriptionOrders: CreateBusinessSubscriptionOrdersResponse;
  createCategory: CreateCategoryResponse;
  createChecklistDefinition: CreateChecklistDefinitionResponse;
  createCustomer: CreateCustomerResponse;
  createCustomerBusinessListingSpec: CreateCustomerBusinessListingSpecResponse;
  createCustomerCampaign: CreateCustomerCampaignResponse;
  createCustomerConversation: CreateCustomerConversationResponse;
  createCustomerInvoice: CreateCustomerInvoiceResponse;
  createCustomerKeyword: CreateCustomerKeywordResponse;
  createCustomerLinkBuild: CreateCustomerLinkBuildResponse;
  createCustomerMessage: CreateCustomerMessageResponse;
  createCustomerNote: CreateNoteResponse;
  createCustomerPremiumBusinessListingSpecImage: CreateCustomerPremiumBusinessListingSpecImageResponse;
  createCustomerSiteAudit: CreateCustomerSiteAuditResponse;
  createCustomerStatusChange: CreateCustomerStatusChangeResponse;
  createCustomerUser: CreateCustomerUserResponse;
  createCustomerUserSsoCode: CreateCustomerUserSsoCodeResponse;
  createDomain: CreateDomainResponse;
  createDomainCategory: CreateDomainCategoryResponse;
  createDomains: CreateDomainsResponse;
  createFeedbackContentItem: CreateFeedbackContentItemResponse;
  createFulfillmentRule: CreateFulfillmentRuleResponse;
  createLinkLocation: CreateLinkLocationResponse;
  createOrder: CreateOrderResponse;
  createOrderExternalContentSpec: CreateOrderExternalContentSpecResponse;
  createOrderHaloKeyword: CreateOrderHaloKeywordResponse;
  createOrders: CreateOrdersResponse;
  createPartner: CreatePartnerResponse;
  createPartnerAvailableProduct: CreatePartnerAvailableProductResponse;
  createPartnerBillingContact: CreatePartnerBillingContactResponse;
  createPartnerInvoice: CreatePartnerInvoiceResponse;
  createPartnerPackage: CreatePartnerPackageResponse;
  createPartnerPackageItem: CreatePartnerPackageItemResponse;
  createPartnerPaymentMethodSetupIntent: CreatePartnerPaymentMethodSetupIntentResponse;
  createPartnerProduct: CreatePartnerProductResponse;
  createPartnerProductPrice: CreatePartnerProductPriceResponse;
  createPartnerProductWholesalePrice: CreatePartnerProductWholesalePriceResponse;
  createPartnerTaskTypeVendor: CreatePartnerTaskTypeVendorResponse;
  createPartnerUser: CreatePartnerUserResponse;
  createProposedCustomerKeyword: CreateProposedCustomerKeywordResponse;
  createPublisher: CreatePublisherResponse;
  createPublisherLinkLocation: CreatePublisherLinkLocationResponse;
  createPublisherPayment: CreatePublisherPaymentResponse;
  createPublisherUser: CreatePublisherUserResponse;
  createSite: CreateSiteResponse;
  createSitePost: CreateSitePostResponse;
  createSystemTaskInprogress: CreateSystemTaskInprogressResponse;
  createUser: CreateUserResponse;
  createUserInteractionEvent: CreateUserInteractionEventResponse;
  createUserProfessional: CreateUserProfessionalResponse;
  createUserTaskPermission: CreateUserTaskPermissionResponse;
  createVendor: CreateVendorResponse;
  createVendorUser: CreateVendorUserResponse;
  createWorkflowNote: CreateNoteResponse;
  createWritingSampleTopic: CreateWritingSampleTopicResponse;
  deleteAmTask: DeleteAmTaskResponse;
  deleteCustomerCampaign: DeleteCustomerCampaignResponse;
  deleteCustomerCampaignInvoice: DeleteCustomerCampaignInvoiceResponse;
  deleteCustomerPremiumBusinessListingSpecImage: DeleteCustomerPremiumBusinessListingSpecImageResponse;
  deleteCustomerStatusChange: DeleteCustomerStatusChangeResponse;
  deleteDomainCategory: DeleteDomainCategoryResponse;
  deleteFulfillmentRule: DeleteFulfillmentRuleResponse;
  deleteLinkLocation: DeleteLinkLocationResponse;
  deleteOrder: DeleteOrderResponse;
  deleteOrderContentStrategy: DeleteOrderContentStrategyResponse;
  deletePartnerBillingContact: DeletePartnerBillingContactResponse;
  deletePartnerPaymentMethod: DeletePartnerPaymentMethodResponse;
  deletePartnerProduct: DeletePartnerProductResponse;
  deletePartnerProductPrice: DeletePartnerProductPriceResponse;
  deletePartnerProductWholesalePrice: DeletePartnerProductWholesalePriceResponse;
  deletePartnerTaskTypeVendor: DeletePartnerTaskTypeVendorResponse;
  deleteProposedCustomerKeyword: DeleteProposedCustomerKeywordResponse;
  deleteSitePost: DeleteSitePostResponse;
  deleteSystemTaskInprogresss: DeleteSystemTaskInprogressResponse;
  deleteUserTaskPermission: DeleteUserTaskPermissionResponse;
  generateUrlForFileUpload: GenerateUrlForFileUploadResponse;
  generateUrlForFileUploadSignup: GenerateUrlForFileUploadSignupResponse;
  generateUrlForStripeDashboard: GenerateUrlForStripeDashboardResponse;
  generateUrlForStripeOnboarding: GenerateUrlForStripeOnboardingResponse;
  googleReviewReply: GoogleReviewReplyResponse;
  importInvoices: ImportInvoicesResponse;
  loginGoogleCallback: LoginGoogleCallbackResponse;
  loginGoogleService: LoginGoogleResponse;
  loginUser: LoginUserResponse;
  logoutUser: LogoutUserResponse;
  payPartnerInvoice: PayPartnerInvoiceResponse;
  purchaseDomain: PurchaseDomainResponse;
  reCreateGuestOutreachTask: ReCreateGuestOutreachTaskResponse;
  recordCustomerCampaignInvoicePayment: RecordCustomerCampaignInvoicePaymentResponse;
  recordCustomerInvoicePayment: RecordCustomerInvoicePaymentResponse;
  recordPartnerInvoicePayment: RecordPartnerInvoicePaymentResponse;
  refreshDomainAvailability: RefreshDomainAvailabilityResponse;
  rejectUserWritingSample: RejectUserWritingSampleResponse;
  requestPasswordReset: RequestPasswordResetResponse;
  resendConfirmationEmail: ResendConfirmationEmailResponse;
  resetPassword: ResetPasswordResponse;
  resolveOrderContentStrategyReview: ResolveOrderContentStrategyReviewResponse;
  saveCustomerBillingConfig: SaveCustomerBillingConfigResponse;
  saveCustomerBusinessListingSpec: SaveCustomerBusinessListingSpecResponse;
  saveCustomerHoursOfOperationItems: SaveCustomerHoursOfOperationItemsResponse;
  saveCustomerPremiumBusinessListingSpec: SaveCustomerPremiumBusinessListingSpecResponse;
  saveCustomerQuestionnaire: SaveCustomerQuestionnaireResponse;
  saveCustomerSiteCredential: SaveCustomerSiteCredentialResponse;
  saveGrammarTestQuestion: SaveGrammarTestQuestionResponse;
  saveLinkLocationCredential: SaveLinkLocationCredentialResponse;
  saveOrderContentItem: SaveOrderContentItemResponse;
  savePartnerAnalyticCredential: SavePartnerAnalyticCredentialResponse;
  saveProposedOrderTopic: SaveProposedOrderTopicResponse;
  saveTaskContentIdeation: SaveTaskContentIdeationResponse;
  saveTaskContentItem: SaveTaskContentItemResponse;
  saveTaskGbpItem: SaveTaskGbpItemResponse;
  saveTaskTitleMetaDescriptions: SaveTaskTitleMetaDescriptionResponse;
  saveUserGrammarTestResponse: SaveUserGrammarTestResponseResponse;
  saveUserProfessional: SaveUserProfessionalResponse;
  saveUserWorkInterests: SaveUserWorkInterestsResponse;
  saveWritingSample: SaveWritingSampleResponse;
  selectWritingSampleTopic: SelectWritingSampleTopicResponse;
  sendEmailManagedSeo: SendEmailManagedSeoResponse;
  signupCSUser: SignupCsUserResponse;
  startGrammarTest: StartGrammarTestResponse;
  startWritingSample: StartWritingSampleResponse;
  submitProposalReview: SubmitProposalReviewResponse;
  syncUberallLocation: SyncUberallLocationResponse;
  updateAmTask: UpdateAmTaskResponse;
  updateCategory: UpdateCategoryResponse;
  updateChecklistItem: UpdateChecklistItemResponse;
  updateCreativesScaleUser: UpdateCreativeScaleUserResponse;
  updateCustomer: UpdateCustomerResponse;
  updateCustomerBusinessListingSpec: UpdateCustomerBusinessListingSpecResponse;
  updateCustomerCampaign: UpdateCustomerCampaignResponse;
  updateCustomerConversation: UpdateCustomerConversationResponse;
  updateCustomerKeyword: UpdateCustomerKeywordResponse;
  updateCustomerKeywordProposal: UpdateCustomerKeywordProposalResponse;
  updateCustomerNote: UpdateCustomerNoteResponse;
  updateCustomerProposal: UpdateCustomerProposalResponse;
  updateCustomerStatusChange: UpdateCustomerStatusChangeResponse;
  updateCustomerUser: UpdateCustomerUserResponse;
  updateFeedbackContentItem: UpdateFeedbackContentItemResponse;
  updateFulfillmentRule: UpdateFulfillmentRuleResponse;
  updateLinkLocation: UpdateLinkLocationResponse;
  updateLinkLocationCategories: UpdateLinkLocationCategoriesResponse;
  updateLinkLocationMetrics: UpdateLinkLocationMetricsResponse;
  updateOrder: UpdateOrderResponse;
  updateOrderContentItem: UpdateOrderContentItemResponse;
  updateOrderContentStrategy: UpdateOrderContentStrategyResponse;
  updateOrderExternalContentSpec: UpdateOrderExternalContentSpecResponse;
  updateOrderGbpItem: UpdateOrderGbpItemResponse;
  updateOrderTitleMetaDescriptionItem: UpdateOrderTitleMetaDescriptionItemResponse;
  updatePartner: UpdatePartnerResponse;
  updatePartnerBillingContact: UpdatePartnerBillingContactResponse;
  updatePartnerPackage: UpdatePartnerPackageResponse;
  updatePartnerPackageItem: UpdatePartnerPackageItemResponse;
  updatePartnerProduct?: Maybe<UpdatePartnerProductResponse>;
  updatePartnerProductPrice: UpdatePartnerProductPriceResponse;
  updatePartnerProductWholesalePrice: UpdatePartnerProductWholesalePriceResponse;
  updatePartnerUser: UpdatePartnerUserResponse;
  updateProposedCustomerKeyword: UpdateProposedCustomerKeywordResponse;
  updatePublisher: UpdatePublisherResponse;
  updatePublisherLinkLocation: UpdatePublisherLinkLocationResponse;
  updatePublisherPayment: UpdatePublisherPaymentResponse;
  updateRcmWriterTasks: UpdateRcmWriterTasksResponse;
  updateSitePost: UpdateSitePostResponse;
  updateSitePostForTask: UpdateSitePostForTaskResponse;
  updateTask: UpdateTaskResponse;
  updateUser: UpdateUserResponse;
  updateUserProfessional: UpdateUserProfessionalResponse;
  updateVendor: UpdateVendorResponse;
  updateVendorUser: UpdateVendorUserResponse;
  updateWritingSampleTopic: UpdateWritingSampleTopicResponse;
  verifyGuestPost: VerifyGuestPostResponse;
  version?: Maybe<Scalars["String"]>;
};

export type MutationApprovePaymentBatchFundingArgs = {
  input: ApprovePaymentBatchFundingInput;
};

export type MutationApprovePaymentBatchFundingPaypalArgs = {
  input: ApprovePaymentBatchFundingPaypalInput;
};

export type MutationApproveUserWritingSampleArgs = {
  input: ApproveUserWritingSampleInput;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationCheckoutArgs = {
  input: CheckoutInput;
};

export type MutationClaimTaskArgs = {
  input: ClaimTaskInput;
};

export type MutationClearProposedCustomerKeywordsRejectionArgs = {
  input: ClearProposedCustomerKeywordsRejectionInput;
};

export type MutationConfirmEmailArgs = {
  input: ConfirmEmailInput;
};

export type MutationCreateAmTaskArgs = {
  input: CreateAmTaskInput;
};

export type MutationCreateBlockDomainCustomersArgs = {
  input: CreateBlockDomainCustomersInput;
};

export type MutationCreateBulkProposedCustomerKeywordsArgs = {
  input: CreateBulkProposedCustomerKeywordsInput;
};

export type MutationCreateBusinessSubscriptionOrdersArgs = {
  input: CreateBusinessSubscriptionOrdersInput;
};

export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};

export type MutationCreateChecklistDefinitionArgs = {
  input: CreateChecklistDefinitionInput;
};

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

export type MutationCreateCustomerBusinessListingSpecArgs = {
  input: CreateCustomerBusinessListingSpecInput;
};

export type MutationCreateCustomerCampaignArgs = {
  input: CreateCustomerCampaignInput;
};

export type MutationCreateCustomerConversationArgs = {
  input: CreateCustomerConversationInput;
};

export type MutationCreateCustomerInvoiceArgs = {
  input: CreateCustomerInvoiceInput;
};

export type MutationCreateCustomerKeywordArgs = {
  input: CreateCustomerKeywordInput;
};

export type MutationCreateCustomerLinkBuildArgs = {
  input: CreateCustomerLinkBuildInput;
};

export type MutationCreateCustomerMessageArgs = {
  input: CreateCustomerMessageInput;
};

export type MutationCreateCustomerNoteArgs = {
  input: CreateCustomerNoteInput;
};

export type MutationCreateCustomerPremiumBusinessListingSpecImageArgs = {
  input: CreateCustomerPremiumBusinessListingSpecImageInput;
};

export type MutationCreateCustomerSiteAuditArgs = {
  input: CreateCustomerSiteAuditInput;
};

export type MutationCreateCustomerStatusChangeArgs = {
  input: CreateCustomerStatusChangeInput;
};

export type MutationCreateCustomerUserArgs = {
  input: CreateCustomerUserInput;
};

export type MutationCreateCustomerUserSsoCodeArgs = {
  input: CreateCustomerUserSsoCodeInput;
};

export type MutationCreateDomainArgs = {
  input: CreateDomainInput;
};

export type MutationCreateDomainCategoryArgs = {
  input: CreateDomainCategoryInput;
};

export type MutationCreateDomainsArgs = {
  input: CreateDomainsInput;
};

export type MutationCreateFeedbackContentItemArgs = {
  input: CreateFeedbackContentItemInput;
};

export type MutationCreateFulfillmentRuleArgs = {
  input: CreateFulfillmentRuleInput;
};

export type MutationCreateLinkLocationArgs = {
  input: CreateLinkLocationInput;
};

export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};

export type MutationCreateOrderExternalContentSpecArgs = {
  input: CreateOrderExternalContentSpecInput;
};

export type MutationCreateOrderHaloKeywordArgs = {
  input: CreateOrderHaloKeywordInput;
};

export type MutationCreateOrdersArgs = {
  input: CreateOrdersInput;
};

export type MutationCreatePartnerArgs = {
  input: CreatePartnerInput;
};

export type MutationCreatePartnerAvailableProductArgs = {
  input: CreatePartnerAvailableProductInput;
};

export type MutationCreatePartnerBillingContactArgs = {
  input: CreatePartnerBillingContactInput;
};

export type MutationCreatePartnerInvoiceArgs = {
  input: CreatePartnerInvoiceInput;
};

export type MutationCreatePartnerPackageArgs = {
  input: CreatePartnerPackageInput;
};

export type MutationCreatePartnerPackageItemArgs = {
  input: CreatePartnerPackageItemInput;
};

export type MutationCreatePartnerPaymentMethodSetupIntentArgs = {
  input: CreatePartnerPaymentMethodSetupIntentInput;
};

export type MutationCreatePartnerProductArgs = {
  input: CreatePartnerProductInput;
};

export type MutationCreatePartnerProductPriceArgs = {
  input: CreatePartnerProductPriceInput;
};

export type MutationCreatePartnerProductWholesalePriceArgs = {
  input: CreatePartnerProductWholesalePriceInput;
};

export type MutationCreatePartnerTaskTypeVendorArgs = {
  input: CreatePartnerTaskTypeVendorInput;
};

export type MutationCreatePartnerUserArgs = {
  input: CreatePartnerUserInput;
};

export type MutationCreateProposedCustomerKeywordArgs = {
  input: CreateProposedCustomerKeywordInput;
};

export type MutationCreatePublisherArgs = {
  input: CreatePublisherInput;
};

export type MutationCreatePublisherLinkLocationArgs = {
  input: CreatePublisherLinkLocationInput;
};

export type MutationCreatePublisherPaymentArgs = {
  input: CreatePublisherPaymentInput;
};

export type MutationCreatePublisherUserArgs = {
  input: CreatePublisherUserInput;
};

export type MutationCreateSiteArgs = {
  input: CreateSiteInput;
};

export type MutationCreateSitePostArgs = {
  input: CreateSitePostInput;
};

export type MutationCreateSystemTaskInprogressArgs = {
  input: CreateSystemTaskInprogressInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateUserInteractionEventArgs = {
  input: CreateUserInteractionEventInput;
};

export type MutationCreateUserProfessionalArgs = {
  input: CreateUserProfessionalInput;
};

export type MutationCreateUserTaskPermissionArgs = {
  input: CreateUserTaskPermissionInput;
};

export type MutationCreateVendorArgs = {
  input: CreateVendorInput;
};

export type MutationCreateVendorUserArgs = {
  input: CreateVendorUserInput;
};

export type MutationCreateWorkflowNoteArgs = {
  input: CreateWorkflowNoteInput;
};

export type MutationCreateWritingSampleTopicArgs = {
  input: CreateWritingSampleTopicInput;
};

export type MutationDeleteAmTaskArgs = {
  input: DeleteAmTaskInput;
};

export type MutationDeleteCustomerCampaignArgs = {
  input: DeleteCustomerCampaignInput;
};

export type MutationDeleteCustomerCampaignInvoiceArgs = {
  input: DeleteCustomerCampaignInvoiceInput;
};

export type MutationDeleteCustomerPremiumBusinessListingSpecImageArgs = {
  input: DeleteCustomerPremiumBusinessListingSpecImageInput;
};

export type MutationDeleteCustomerStatusChangeArgs = {
  input: DeleteCustomerStatusChangeInput;
};

export type MutationDeleteDomainCategoryArgs = {
  input: DeleteDomainCategoryInput;
};

export type MutationDeleteFulfillmentRuleArgs = {
  input: DeleteFulfillmentRuleInput;
};

export type MutationDeleteLinkLocationArgs = {
  input: DeleteLinkLocationInput;
};

export type MutationDeleteOrderArgs = {
  input: DeleteOrderInput;
};

export type MutationDeleteOrderContentStrategyArgs = {
  input: DeleteOrderContentStrategyInput;
};

export type MutationDeletePartnerBillingContactArgs = {
  input: DeletePartnerBillingContactInput;
};

export type MutationDeletePartnerPaymentMethodArgs = {
  input: DeletePartnerPaymentMethodInput;
};

export type MutationDeletePartnerProductArgs = {
  input: DeletePartnerProductInput;
};

export type MutationDeletePartnerProductPriceArgs = {
  input: DeletePartnerProductPriceInput;
};

export type MutationDeletePartnerProductWholesalePriceArgs = {
  input: DeletePartnerProductWholesalePriceInput;
};

export type MutationDeletePartnerTaskTypeVendorArgs = {
  input: DeletePartnerTaskTypeVendorInput;
};

export type MutationDeleteProposedCustomerKeywordArgs = {
  input: DeleteProposedCustomerKeywordInput;
};

export type MutationDeleteSitePostArgs = {
  input: DeleteSitePostInput;
};

export type MutationDeleteSystemTaskInprogresssArgs = {
  input: DeleteSystemTaskInprogressInput;
};

export type MutationDeleteUserTaskPermissionArgs = {
  input: DeleteUserTaskPermissionInput;
};

export type MutationGenerateUrlForFileUploadArgs = {
  input?: InputMaybe<GenerateUrlForFileUploadInput>;
};

export type MutationGenerateUrlForFileUploadSignupArgs = {
  input?: InputMaybe<GenerateUrlForFileUploadSignupInput>;
};

export type MutationGoogleReviewReplyArgs = {
  input: GoogleReviewReplyInput;
};

export type MutationImportInvoicesArgs = {
  input: ImportInvoicesInput;
};

export type MutationLoginGoogleCallbackArgs = {
  input?: InputMaybe<LoginGoogleCallbackInput>;
};

export type MutationLoginUserArgs = {
  input: LoginUserInput;
};

export type MutationPayPartnerInvoiceArgs = {
  input: PayPartnerInvoiceInput;
};

export type MutationPurchaseDomainArgs = {
  input: PurchaseDomainInput;
};

export type MutationReCreateGuestOutreachTaskArgs = {
  input: ReCreateGuestOutreachTaskInput;
};

export type MutationRecordCustomerCampaignInvoicePaymentArgs = {
  input: RecordCustomerCampaignInvoicePaymentInput;
};

export type MutationRecordCustomerInvoicePaymentArgs = {
  input: RecordCustomerInvoicePaymentInput;
};

export type MutationRecordPartnerInvoicePaymentArgs = {
  input: RecordPartnerInvoicePaymentInput;
};

export type MutationRefreshDomainAvailabilityArgs = {
  input?: InputMaybe<RefreshDomainAvailabilityInput>;
};

export type MutationRejectUserWritingSampleArgs = {
  input: RejectUserWritingSampleInput;
};

export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};

export type MutationResendConfirmationEmailArgs = {
  input: ResendConfirmationEmailInput;
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationResolveOrderContentStrategyReviewArgs = {
  input: ResolveOrderContentStrategyReviewInput;
};

export type MutationSaveCustomerBillingConfigArgs = {
  input: SaveCustomerBillingConfigInput;
};

export type MutationSaveCustomerBusinessListingSpecArgs = {
  input: SaveCustomerBusinessListingSpecInput;
};

export type MutationSaveCustomerHoursOfOperationItemsArgs = {
  input: SaveCustomerHoursOfOperationItemsInput;
};

export type MutationSaveCustomerPremiumBusinessListingSpecArgs = {
  input: SaveCustomerPremiumBusinessListingSpecInput;
};

export type MutationSaveCustomerQuestionnaireArgs = {
  input: SaveCustomerQuestionnaireInput;
};

export type MutationSaveCustomerSiteCredentialArgs = {
  input: SaveCustomerSiteCredentialInput;
};

export type MutationSaveGrammarTestQuestionArgs = {
  input: SaveGrammarTestQuestionInput;
};

export type MutationSaveLinkLocationCredentialArgs = {
  input: SaveLinkLocationCredentialInput;
};

export type MutationSaveOrderContentItemArgs = {
  input: SaveOrderContentItemInput;
};

export type MutationSavePartnerAnalyticCredentialArgs = {
  input: SavePartnerAnalyticCredentialInput;
};

export type MutationSaveProposedOrderTopicArgs = {
  input: SaveProposedOrderTopicInput;
};

export type MutationSaveTaskContentIdeationArgs = {
  input: SaveTaskContentIdeationInput;
};

export type MutationSaveTaskContentItemArgs = {
  input: SaveTaskContentItemInput;
};

export type MutationSaveTaskGbpItemArgs = {
  input: SaveTaskGbpItemInput;
};

export type MutationSaveTaskTitleMetaDescriptionsArgs = {
  input: SaveTaskTitleMetaDescriptionInput;
};

export type MutationSaveUserGrammarTestResponseArgs = {
  input: SaveUserGrammarTestResponseInput;
};

export type MutationSaveUserProfessionalArgs = {
  input: SaveUserProfessionalInput;
};

export type MutationSaveUserWorkInterestsArgs = {
  input: SaveUserWorkInterestsInput;
};

export type MutationSaveWritingSampleArgs = {
  input: SaveWritingSampleInput;
};

export type MutationSelectWritingSampleTopicArgs = {
  input: SelectWritingSampleTopicInput;
};

export type MutationSendEmailManagedSeoArgs = {
  input: SendEmailManagedSeoInput;
};

export type MutationSignupCsUserArgs = {
  input?: InputMaybe<SignupCsUserInput>;
};

export type MutationSubmitProposalReviewArgs = {
  input: SubmitProposalReviewInput;
};

export type MutationSyncUberallLocationArgs = {
  input: SyncUberallLocationInput;
};

export type MutationUpdateAmTaskArgs = {
  input: UpdateAmTaskInput;
};

export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};

export type MutationUpdateChecklistItemArgs = {
  input: UpdateChecklistItemInput;
};

export type MutationUpdateCreativesScaleUserArgs = {
  input?: InputMaybe<UpdateCreativeScaleUserInput>;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateCustomerBusinessListingSpecArgs = {
  input: UpdateCustomerBusinessListingSpecInput;
};

export type MutationUpdateCustomerCampaignArgs = {
  input: UpdateCustomerCampaignInput;
};

export type MutationUpdateCustomerConversationArgs = {
  input: UpdateCustomerConversationInput;
};

export type MutationUpdateCustomerKeywordArgs = {
  input: UpdateCustomerKeywordInput;
};

export type MutationUpdateCustomerKeywordProposalArgs = {
  input: UpdateCustomerKeywordProposalInput;
};

export type MutationUpdateCustomerNoteArgs = {
  input: UpdateCustomerNoteInput;
};

export type MutationUpdateCustomerProposalArgs = {
  input: UpdateCustomerProposalInput;
};

export type MutationUpdateCustomerStatusChangeArgs = {
  input: UpdateCustomerStatusChangeInput;
};

export type MutationUpdateCustomerUserArgs = {
  input: UpdateCustomerUserInput;
};

export type MutationUpdateFeedbackContentItemArgs = {
  input: UpdateFeedbackContentItemInput;
};

export type MutationUpdateFulfillmentRuleArgs = {
  input: UpdateFulfillmentRuleInput;
};

export type MutationUpdateLinkLocationArgs = {
  input: UpdateLinkLocationInput;
};

export type MutationUpdateLinkLocationCategoriesArgs = {
  input: UpdateLinkLocationCategoriesInput;
};

export type MutationUpdateLinkLocationMetricsArgs = {
  input: UpdateLinkLocationMetricsInput;
};

export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};

export type MutationUpdateOrderContentItemArgs = {
  input: UpdateOrderContentItemInput;
};

export type MutationUpdateOrderContentStrategyArgs = {
  input: UpdateOrderContentStrategyInput;
};

export type MutationUpdateOrderExternalContentSpecArgs = {
  input: UpdateOrderExternalContentSpecInput;
};

export type MutationUpdateOrderGbpItemArgs = {
  input: UpdateOrderGbpItemInput;
};

export type MutationUpdateOrderTitleMetaDescriptionItemArgs = {
  input: UpdateOrderTitleMetaDescriptionItemInput;
};

export type MutationUpdatePartnerArgs = {
  input: UpdatePartnerInput;
};

export type MutationUpdatePartnerBillingContactArgs = {
  input: UpdatePartnerBillingContactInput;
};

export type MutationUpdatePartnerPackageArgs = {
  input: UpdatePartnerPackageInput;
};

export type MutationUpdatePartnerPackageItemArgs = {
  input: UpdatePartnerPackageItemInput;
};

export type MutationUpdatePartnerProductArgs = {
  input: UpdatePartnerProductInput;
};

export type MutationUpdatePartnerProductPriceArgs = {
  input: UpdatePartnerProductPriceInput;
};

export type MutationUpdatePartnerProductWholesalePriceArgs = {
  input: UpdatePartnerProductWholesalePriceInput;
};

export type MutationUpdatePartnerUserArgs = {
  input: UpdatePartnerUserInput;
};

export type MutationUpdateProposedCustomerKeywordArgs = {
  input: UpdateProposedCustomerKeywordInput;
};

export type MutationUpdatePublisherArgs = {
  input: UpdatePublisherInput;
};

export type MutationUpdatePublisherLinkLocationArgs = {
  input: UpdatePublisherLinkLocationInput;
};

export type MutationUpdatePublisherPaymentArgs = {
  input: UpdatePublisherPaymentInput;
};

export type MutationUpdateRcmWriterTasksArgs = {
  input: UpdateRcmWriterTasksInput;
};

export type MutationUpdateSitePostArgs = {
  input: UpdateSitePostInput;
};

export type MutationUpdateSitePostForTaskArgs = {
  input: UpdateSitePostForTaskInput;
};

export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserProfessionalArgs = {
  input: UpdateUserProfessionalInput;
};

export type MutationUpdateVendorArgs = {
  input: UpdateVendorInput;
};

export type MutationUpdateVendorUserArgs = {
  input: UpdateVendorUserInput;
};

export type MutationUpdateWritingSampleTopicArgs = {
  input: UpdateWritingSampleTopicInput;
};

export type MutationVerifyGuestPostArgs = {
  input: VerifyGuestPostInput;
};

export type Note = {
  __typename?: "Note";
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  lastUpdatedAt?: Maybe<Scalars["DateTime"]>;
  user: User;
  userId: Scalars["Int"];
};

export type NoteConnection = {
  __typename?: "NoteConnection";
  nodes: Array<Note>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type OnboardingStatus = {
  __typename?: "OnboardingStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type Order = {
  __typename?: "Order";
  activeOrderContentStrategy?: Maybe<OrderContentStrategy>;
  amount: Scalars["Float"];
  billingStatus: OrderBillingStatus;
  canDelete: Scalars["Boolean"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  customer: Customer;
  customerBillingStatus: OrderBillingStatus;
  customerCampaign: CustomerCampaign;
  customerCampaignId: Scalars["Int"];
  customerId: Scalars["Int"];
  customerKeyword?: Maybe<CustomerKeyword>;
  customerKeywordId?: Maybe<Scalars["Int"]>;
  customerProposal?: Maybe<CustomerProposal>;
  customerReviewTask?: Maybe<Task>;
  customerSiteAudit?: Maybe<CustomerSiteAudit>;
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  linkLocation?: Maybe<LinkLocation>;
  linkLocationId?: Maybe<Scalars["Int"]>;
  orderContentItems: Array<OrderContentItem>;
  orderContentStrategies: Array<OrderContentStrategy>;
  orderExternalContentSpec?: Maybe<OrderExternalContentSpec>;
  orderGbpItem?: Maybe<OrderGbpItem>;
  orderIssues: Array<OrderIssue>;
  orderTitleMetaDescriptionItem?: Maybe<OrderTitleMetaDescriptionItem>;
  partnerInvoiceCharges: Array<PartnerInvoiceCharge>;
  partnerProduct: PartnerProduct;
  proposalTask?: Maybe<Task>;
  publisherPayment?: Maybe<PublisherPayment>;
  publisherPaymentStatus: OrderBillingStatus;
  quantity: Scalars["Int"];
  resultUrl?: Maybe<Scalars["String"]>;
  resultUrlDomainAuthority?: Maybe<Scalars["Int"]>;
  resultUrlTraffic?: Maybe<Scalars["Int"]>;
  startDate: Scalars["DateTime"];
  status: OrderStatus;
  targetLinkLocation?: Maybe<LinkLocation>;
  targetLinkLocationId?: Maybe<Scalars["Int"]>;
  targetResultUrl?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  totalRetailAmount: Scalars["Float"];
  totalWholesaleAmount: Scalars["Float"];
  trackingId?: Maybe<Scalars["String"]>;
  user: User;
  userId: Scalars["Int"];
  workflow?: Maybe<Workflow>;
  workflowInternalStatus?: Maybe<Scalars["String"]>;
};

export type OrderOrderContentItemsArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]>;
  isPostPublished?: InputMaybe<Scalars["Boolean"]>;
};

export type OrderPublisherPaymentArgs = {
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type OrderBillingStatus = {
  __typename?: "OrderBillingStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type OrderConnection = {
  __typename?: "OrderConnection";
  nodes: Array<Order>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type OrderContentItem = {
  __typename?: "OrderContentItem";
  contentItem: ContentItem;
  contentItemDelivery: ContentItemDelivery;
  contentItemId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isPostPublished: Scalars["Boolean"];
  lastVerifiedPostAt?: Maybe<Scalars["DateTime"]>;
  order: Order;
  orderId: Scalars["Int"];
  publicId?: Maybe<Scalars["String"]>;
};

export type OrderContentItemConnection = {
  __typename?: "OrderContentItemConnection";
  nodes: Array<OrderContentItem>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum OrderContentStrategiesSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  OrderStartDateAsc = "ORDER_START_DATE_ASC",
  OrderStartDateDesc = "ORDER_START_DATE_DESC",
}

export type OrderContentStrategy = {
  __typename?: "OrderContentStrategy";
  clientProvidedInstructions?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerKeyword?: Maybe<CustomerKeyword>;
  customerKeywordId?: Maybe<Scalars["Int"]>;
  customerProposal?: Maybe<CustomerProposal>;
  customerProposalId?: Maybe<Scalars["Int"]>;
  focusPoints?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isComplete: Scalars["Boolean"];
  isProposed: Scalars["Boolean"];
  linkTo?: Maybe<Scalars["String"]>;
  order?: Maybe<Order>;
  orderContentStrategyReviews: Array<OrderContentStrategyReview>;
  orderHaloKeywords: Array<OrderHaloKeyword>;
  orderId: Scalars["Int"];
  pageType?: Maybe<Scalars["String"]>;
  purposeOfContent?: Maybe<Scalars["String"]>;
  sampleContent?: Maybe<Scalars["String"]>;
  topic?: Maybe<Scalars["String"]>;
  voice?: Maybe<Scalars["String"]>;
  webPage?: Maybe<Scalars["String"]>;
};

export type OrderContentStrategyReview = {
  __typename?: "OrderContentStrategyReview";
  comments?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdByUser: User;
  createdByUserId: Scalars["Int"];
  customerId: Scalars["Int"];
  id: Scalars["Int"];
  orderContentStrategyId: Scalars["Int"];
  orderId: Scalars["Int"];
  resolvedAt?: Maybe<Scalars["DateTime"]>;
};

export type OrderExternalContentSpec = {
  __typename?: "OrderExternalContentSpec";
  addedByUserId: Scalars["Int"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  cost: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  domainAuthority?: Maybe<Scalars["Int"]>;
  externalOrderId: Scalars["String"];
  id: Scalars["Int"];
  orderId: Scalars["Int"];
  submittedAt: Scalars["DateTime"];
  trustFlow?: Maybe<Scalars["Int"]>;
  vendor: Vendor;
  vendorId: Scalars["Int"];
};

export type OrderGbpItem = {
  __typename?: "OrderGbpItem";
  createdAt: Scalars["DateTime"];
  gbpItem: GbpItem;
  gbpItemId: Scalars["Int"];
  id: Scalars["Int"];
  isProfilePublished: Scalars["Boolean"];
  order: Order;
  orderId: Scalars["Int"];
};

export type OrderHaloKeyword = {
  __typename?: "OrderHaloKeyword";
  createdAt: Scalars["DateTime"];
  customerKeyword: CustomerKeyword;
  customerKeywordId: Scalars["Int"];
  customerProposal?: Maybe<CustomerProposal>;
  customerProposalId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  isProposed: Scalars["Boolean"];
  order: Order;
  orderId: Scalars["Int"];
};

export type OrderInput = {
  customerId: Scalars["Int"];
  instructions?: InputMaybe<Scalars["String"]>;
  keywordId?: InputMaybe<Scalars["Int"]>;
  partnerProductId: Scalars["Int"];
  startDate?: InputMaybe<Scalars["Date"]>;
  targetResultUrl?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
  trackingId?: InputMaybe<Scalars["String"]>;
};

export type OrderIssue = {
  __typename?: "OrderIssue";
  message: Scalars["String"];
  title: Scalars["String"];
};

export type OrderStatus = {
  __typename?: "OrderStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type OrderTitleMetaDescriptionItem = {
  __typename?: "OrderTitleMetaDescriptionItem";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isPublished: Scalars["Boolean"];
  orderId: Scalars["Int"];
  titleMetaDescriptionItem: TitleMetaDescriptionItem;
  titleMetaDescriptionItemId: Scalars["Int"];
};

export enum OrdersSort {
  CompletedAtAsc = "COMPLETED_AT_ASC",
  CompletedAtDesc = "COMPLETED_AT_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  StartedAtAsc = "STARTED_AT_ASC",
  StartedAtDesc = "STARTED_AT_DESC",
}

export type PageInfo = {
  __typename?: "PageInfo";
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
};

export type Partner = {
  __typename?: "Partner";
  autopayPartnerPaymentMethod?: Maybe<PartnerPaymentMethod>;
  autopayPartnerPaymentMethodId?: Maybe<Scalars["Int"]>;
  billableOrders: OrderConnection;
  billingEmail?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  emailLogoFile?: Maybe<File>;
  enableAutopayInformation?: Maybe<EnableAutopayInformation>;
  id: Scalars["Int"];
  isAutopayEnabled: Scalars["Boolean"];
  isBillingEnforced: Scalars["Boolean"];
  isUpfrontPaymentRequired: Scalars["Boolean"];
  name: Scalars["String"];
  partnerAnalyticCredential?: Maybe<PartnerAnalyticCredential>;
  partnerAvailableProducts: Array<PartnerAvailableProduct>;
  partnerBillingContacts: PartnerBillingContactConnection;
  partnerInvoices: PartnerInvoiceConnection;
  partnerPackages: PartnerPackageConnection;
  partnerPaymentMethods: PartnerPaymentMethodConnection;
  partnerProducts: PartnerProductConnection;
  partnerTaskTypeVendors: Array<PartnerTaskTypeVendor>;
  partnerUsers: PartnerUserConnection;
  portal?: Maybe<Scalars["String"]>;
  reportingLogoFile?: Maybe<File>;
  stripeAccountId?: Maybe<Scalars["String"]>;
};

export type PartnerBillableOrdersArgs = {
  customerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<DateQuery>;
};

export type PartnerPartnerBillingContactsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PartnerPartnerInvoicesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PartnerPartnerPaymentMethodsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PartnerPartnerProductsArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  productIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type PartnerPartnerUsersArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type PartnerAnalyticCredential = {
  __typename?: "PartnerAnalyticCredential";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  partnerId: Scalars["Int"];
  password?: Maybe<Scalars["String"]>;
  passwordHash: Scalars["String"];
  userName: Scalars["String"];
};

export type PartnerAvailableProduct = {
  __typename?: "PartnerAvailableProduct";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerProductWholesalePrices: PartnerProductWholesalePriceConnection;
  product: Product;
  productId: Scalars["String"];
  wholesalePrice: Scalars["Float"];
};

export type PartnerAvailableProductPartnerProductWholesalePricesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PartnerProductWholesalePriceSort>;
};

export type PartnerBillingContact = {
  __typename?: "PartnerBillingContact";
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["Int"];
  lastName: Scalars["String"];
  partnerId: Scalars["Int"];
};

export type PartnerBillingContactConnection = {
  __typename?: "PartnerBillingContactConnection";
  nodes: Array<PartnerBillingContact>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerConnection = {
  __typename?: "PartnerConnection";
  nodes: Array<Partner>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoice = {
  __typename?: "PartnerInvoice";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  invoiceDate: Scalars["DateTime"];
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerInvoiceCharges: PartnerInvoiceChargeConnection;
  partnerInvoicePayments: PartnerInvoicePaymentConnection;
  partnerInvoiceRefunds: PartnerInvoiceRefundConnection;
  status?: Maybe<PartnerInvoiceStatus>;
  statusId: Scalars["String"];
  totalChargesAmount: Scalars["Float"];
  totalDueAmount: Scalars["Float"];
  totalPaymentsAmount: Scalars["Float"];
  totalRefundsAmount: Scalars["Float"];
};

export type PartnerInvoiceCharge = {
  __typename?: "PartnerInvoiceCharge";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars["Int"]>;
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerInvoiceId: Scalars["Int"];
  status?: Maybe<PartnerInvoiceChargeStatus>;
  statusId: Scalars["String"];
  typeId: Scalars["String"];
};

export type PartnerInvoiceChargeConnection = {
  __typename?: "PartnerInvoiceChargeConnection";
  nodes: Array<PartnerInvoiceCharge>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoiceChargeStatus = {
  __typename?: "PartnerInvoiceChargeStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PartnerInvoiceConnection = {
  __typename?: "PartnerInvoiceConnection";
  nodes: Array<PartnerInvoice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoicePayment = {
  __typename?: "PartnerInvoicePayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  partnerId: Scalars["Int"];
  partnerInvoiceId: Scalars["Int"];
  partnerPaymentMethod: PartnerPaymentMethod;
  partnerPaymentMethodId: Scalars["Int"];
  paymentMethodDescription: Scalars["String"];
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  status?: Maybe<PartnerInvoicePaymentStatus>;
  statusId: Scalars["String"];
};

export type PartnerInvoicePaymentConnection = {
  __typename?: "PartnerInvoicePaymentConnection";
  nodes: Array<PartnerInvoicePayment>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoicePaymentStatus = {
  __typename?: "PartnerInvoicePaymentStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PartnerInvoiceRefund = {
  __typename?: "PartnerInvoiceRefund";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  errorMessage?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  partnerId: Scalars["Int"];
  partnerInvoice: PartnerInvoice;
  partnerInvoiceId: Scalars["Int"];
  partnerInvoicePayment: PartnerInvoicePayment;
  partnerInvoicePaymentId: Scalars["Int"];
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  reason: Scalars["String"];
  status?: Maybe<PartnerInvoicePaymentStatus>;
  statusId: Scalars["String"];
};

export type PartnerInvoiceRefundConnection = {
  __typename?: "PartnerInvoiceRefundConnection";
  nodes: Array<PartnerInvoiceRefund>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerInvoiceRefundStatus = {
  __typename?: "PartnerInvoiceRefundStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum PartnerInvoiceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type PartnerInvoiceStatus = {
  __typename?: "PartnerInvoiceStatus";
  description: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PartnerPackage = {
  __typename?: "PartnerPackage";
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  monthlySpend: Scalars["Float"];
  months: Scalars["Int"];
  name: Scalars["String"];
  partnerId: Scalars["Int"];
  partnerPackageItemCount: Scalars["Int"];
  partnerPackageItems: Array<PartnerPackageItem>;
};

export type PartnerPackageConnection = {
  __typename?: "PartnerPackageConnection";
  nodes: Array<PartnerPackage>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerPackageItem = {
  __typename?: "PartnerPackageItem";
  id: Scalars["Int"];
  isRecurring: Scalars["Boolean"];
  monthIndex: Scalars["Int"];
  partnerId: Scalars["Int"];
  partnerPackage: PartnerPackage;
  partnerPackageId: Scalars["Int"];
  partnerProduct: PartnerProduct;
  partnerProductId: Scalars["Int"];
  quantity: Scalars["Int"];
  totalPrice: Scalars["Float"];
  unitPrice: Scalars["Float"];
};

export enum PartnerPackageSort {
  MonthlySpendAsc = "MONTHLY_SPEND_ASC",
  MonthlySpendDesc = "MONTHLY_SPEND_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type PartnerPaymentMethod = {
  __typename?: "PartnerPaymentMethod";
  createdAt: Scalars["DateTime"];
  externalId: Scalars["String"];
  externalProvider: Scalars["String"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerPaymentMethodCard?: Maybe<PartnerPaymentMethodCard>;
  type: Scalars["String"];
};

export type PartnerPaymentMethodCard = {
  __typename?: "PartnerPaymentMethodCard";
  brand: Scalars["String"];
  brandDisplayName: Scalars["String"];
  expirationMonth: Scalars["Int"];
  expirationYear: Scalars["Int"];
  last4Digits: Scalars["String"];
};

export type PartnerPaymentMethodConnection = {
  __typename?: "PartnerPaymentMethodConnection";
  nodes: Array<PartnerPaymentMethod>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerProduct = {
  __typename?: "PartnerProduct";
  activeAt: Scalars["DateTime"];
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  canDelete: Scalars["Boolean"];
  checklistDefinition?: Maybe<ChecklistDefinition>;
  checklistDefinitionId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  currentPartnerProductPrice?: Maybe<PartnerProductPrice>;
  currentPartnerProductWholesalePrice?: Maybe<PartnerProductWholesalePrice>;
  id: Scalars["Int"];
  inactiveAt?: Maybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  partner: Partner;
  partnerId: Scalars["Int"];
  partnerProductPrices: PartnerProductPriceConnection;
  partnerProductWholesalePrices: PartnerProductWholesalePriceConnection;
  product: Product;
  productId: Scalars["String"];
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars["Int"]>;
  wholesalePrice?: Maybe<Scalars["Float"]>;
};

export type PartnerProductPartnerProductPricesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PartnerProductPriceSort>;
  startsAt?: InputMaybe<DateQuery>;
};

export type PartnerProductPartnerProductWholesalePricesArgs = {
  effectiveAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PartnerProductConnection = {
  __typename?: "PartnerProductConnection";
  nodes: Array<PartnerProduct>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PartnerProductPrice = {
  __typename?: "PartnerProductPrice";
  addedByUserId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  endsAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isCurrent: Scalars["Boolean"];
  partnerProduct: PartnerProduct;
  partnerProductId: Scalars["Int"];
  price: Scalars["Float"];
  startsAt: Scalars["DateTime"];
};

export type PartnerProductPriceConnection = {
  __typename?: "PartnerProductPriceConnection";
  nodes: Array<PartnerProductPrice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PartnerProductPriceSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  StartsAtAsc = "STARTS_AT_ASC",
  StartsAtDesc = "STARTS_AT_DESC",
}

export type PartnerProductWholesalePrice = {
  __typename?: "PartnerProductWholesalePrice";
  canDelete: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  effectiveAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isCurrent: Scalars["Boolean"];
  partnerId: Scalars["Int"];
  productId: Scalars["String"];
  wholesaleAmount: Scalars["Float"];
};

export type PartnerProductWholesalePriceConnection = {
  __typename?: "PartnerProductWholesalePriceConnection";
  nodes: Array<PartnerProductWholesalePrice>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PartnerProductWholesalePriceSort {
  EffectiveAtAsc = "EFFECTIVE_AT_ASC",
  EffectiveAtDesc = "EFFECTIVE_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type PartnerTaskTypeVendor = {
  __typename?: "PartnerTaskTypeVendor";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  partnerId: Scalars["Int"];
  taskType: TaskType;
  taskTypeId: Scalars["String"];
  vendor: Vendor;
  vendorId: Scalars["Int"];
};

export type PartnerUser = {
  __typename?: "PartnerUser";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  isAdmin: Scalars["Boolean"];
  partner: Partner;
  partnerId: Scalars["Int"];
  user: User;
  userId: Scalars["Int"];
};

export type PartnerUserConnection = {
  __typename?: "PartnerUserConnection";
  nodes: Array<PartnerUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PartnersSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type PayPartnerInvoiceInput = {
  partnerInvoiceId: Scalars["Int"];
  partnerPaymentMethodId: Scalars["Int"];
};

export type PayPartnerInvoiceResponse = {
  __typename?: "PayPartnerInvoiceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  wasPaymentSuccessful?: Maybe<Scalars["Boolean"]>;
};

export type PaymentBatch = {
  __typename?: "PaymentBatch";
  createdAt: Scalars["DateTime"];
  creativesScaleUserPayments?: Maybe<Array<CreativesScaleUserPayment>>;
  externalFundingTransferId?: Maybe<Scalars["String"]>;
  feesTotalAmount: Scalars["Float"];
  fundingApprovedAt?: Maybe<Scalars["DateTime"]>;
  fundingApprovedByUserId?: Maybe<Scalars["Int"]>;
  fundingStatus: PaymentBatchFundingStatus;
  fundingStatusId: Scalars["String"];
  id: Scalars["Int"];
  paymentTotalAmount: Scalars["Float"];
  publisherPayments?: Maybe<Array<PublisherPayment>>;
  status: PaymentBatchStatus;
  statusId: Scalars["String"];
  totalAmount: Scalars["Float"];
};

export type PaymentBatchFundingStatus = {
  __typename?: "PaymentBatchFundingStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PaymentBatchStatus = {
  __typename?: "PaymentBatchStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum PaymentsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type PostalAddress = {
  __typename?: "PostalAddress";
  addressLines?: Maybe<Array<Scalars["String"]>>;
  administrativeArea?: Maybe<Scalars["String"]>;
  languageCode?: Maybe<Scalars["String"]>;
  locality?: Maybe<Scalars["String"]>;
  organization?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  regionCode?: Maybe<Scalars["String"]>;
  revision?: Maybe<Scalars["Int"]>;
  sortingCode?: Maybe<Scalars["String"]>;
  sublocality?: Maybe<Scalars["String"]>;
};

export type Product = {
  __typename?: "Product";
  campaignCategory: CampaignCategory;
  campaignCategoryId: Scalars["String"];
  defaultWholesalePrice: Scalars["Int"];
  deprecatedAt?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  isChecklistRequired: Scalars["Boolean"];
  isKeywordRequired: Scalars["Boolean"];
  isSystemManaged: Scalars["Boolean"];
  isSystemOrdered: Scalars["Boolean"];
  isTopicRequired: Scalars["Boolean"];
  isVendorRequired: Scalars["Boolean"];
  isWebsiteRequired: Scalars["Boolean"];
  maxOrderQuantity: Scalars["Int"];
  maxWordCount?: Maybe<Scalars["Int"]>;
  minDA?: Maybe<Scalars["Int"]>;
  minSV?: Maybe<Scalars["Int"]>;
  minWordCount?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
};

export type ProductConnection = {
  __typename?: "ProductConnection";
  nodes: Array<Product>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type ProductTaskType = {
  __typename?: "ProductTaskType";
  id: Scalars["String"];
  product: Product;
  productId: Scalars["String"];
  taskType: TaskType;
  taskTypeId: Scalars["String"];
};

export type ProductTaskTypeConnection = {
  __typename?: "ProductTaskTypeConnection";
  nodes: Array<ProductTaskType>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type ProductTaskTypePair = {
  productId: Scalars["String"];
  taskTypeId: Scalars["String"];
};

export enum ProductsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type ProposedCustomerKeyword = {
  __typename?: "ProposedCustomerKeyword";
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["Int"];
  customerKeywordProposalId: Scalars["Int"];
  id: Scalars["Int"];
  isPrimary: Scalars["Boolean"];
  landingPageUrl?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  rejectedAt?: Maybe<Scalars["DateTime"]>;
  rejectionReason?: Maybe<Scalars["String"]>;
};

export enum ProposedCustomerKeywordSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type ProposedOrderTopic = {
  __typename?: "ProposedOrderTopic";
  customerKeyword: CustomerKeyword;
  id: Scalars["Int"];
  orderId: Scalars["Int"];
  rejectionComment?: Maybe<Scalars["String"]>;
  topic: Scalars["String"];
};

export type Publisher = {
  __typename?: "Publisher";
  contactEmail?: Maybe<Scalars["String"]>;
  contactName?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  createdByUser?: Maybe<User>;
  createdByUserId?: Maybe<Scalars["Int"]>;
  emailMessages?: Maybe<EmailMessageConnection>;
  id: Scalars["Int"];
  instructions?: Maybe<Scalars["String"]>;
  isAutoPayEnabled?: Maybe<Scalars["Boolean"]>;
  isInvoiceRequired?: Maybe<Scalars["Boolean"]>;
  isSlowPartner?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  paidAmount: Scalars["Float"];
  paymentMethod?: Maybe<Scalars["String"]>;
  paymentMethodId?: Maybe<Scalars["String"]>;
  publishedOrders: OrderConnection;
  publisherLinkLocations: PublisherLinkLocationConnection;
  publisherUsers: PublisherUserConnection;
  requestedOrders: OrderConnection;
};

export type PublisherEmailMessagesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type PublisherPaidAmountArgs = {
  daysAgo?: InputMaybe<Scalars["Int"]>;
};

export type PublisherPublishedOrdersArgs = {
  daysAgo?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
};

export type PublisherPublisherLinkLocationsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PublisherLinkLocationSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type PublisherPublisherUsersArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PublisherUsersSort>;
};

export type PublisherRequestedOrdersArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
};

export type PublisherConnection = {
  __typename?: "PublisherConnection";
  nodes: Array<Publisher>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PublisherLinkLocation = {
  __typename?: "PublisherLinkLocation";
  createdAt: Scalars["Date"];
  id: Scalars["Int"];
  isRCMPublishing?: Maybe<Scalars["Boolean"]>;
  linkLocation?: Maybe<LinkLocation>;
  linkLocationId: Scalars["Int"];
  publisher?: Maybe<Publisher>;
  publisherId: Scalars["Int"];
};

export type PublisherLinkLocationConnection = {
  __typename?: "PublisherLinkLocationConnection";
  nodes: Array<PublisherLinkLocation>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PublisherLinkLocationSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type PublisherPayment = {
  __typename?: "PublisherPayment";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  orderId?: Maybe<Scalars["Int"]>;
  paymentBatchId?: Maybe<Scalars["Int"]>;
  processor?: Maybe<Scalars["String"]>;
  processorReferenceId?: Maybe<Scalars["String"]>;
  publisher?: Maybe<Publisher>;
  publisherId?: Maybe<Scalars["Int"]>;
  status?: Maybe<PublisherPaymentStatus>;
};

export type PublisherPaymentConnection = {
  __typename?: "PublisherPaymentConnection";
  nodes: Array<PublisherPayment>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type PublisherPaymentStatus = {
  __typename?: "PublisherPaymentStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type PublisherUser = {
  __typename?: "PublisherUser";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isAdmin: Scalars["Boolean"];
  publisher: Publisher;
  publisherId: Scalars["Int"];
  user: User;
  userId: Scalars["Int"];
};

export type PublisherUserConnection = {
  __typename?: "PublisherUserConnection";
  nodes: Array<PublisherUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum PublisherUsersSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export enum PublishersSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
}

export type PurchaseDomainInput = {
  domainId: Scalars["String"];
};

export type PurchaseDomainResponse = {
  __typename?: "PurchaseDomainResponse";
  domain: Domain;
};

export type Query = {
  __typename?: "Query";
  adminReports: AdminReports;
  amTask: AmTask;
  amTasks: AmTaskConnection;
  blockDomains: BlockDomainConnection;
  campaignCategories: CampaignCategoryConnection;
  categories: CategoryConnection;
  category?: Maybe<Category>;
  checklistDefinition?: Maybe<ChecklistDefinition>;
  checklistDefinitions: ChecklistDefinitionConnection;
  creativesScaleConfiguration: CreativesScaleConfiguration;
  creativesScaleUser?: Maybe<CreativesScaleUser>;
  creativesScaleUsers: CreativesScaleUserConnection;
  customer?: Maybe<Customer>;
  customerBusinessListingSpec?: Maybe<CustomerBusinessListingSpec>;
  customerCampaignInvoice?: Maybe<CustomerCampaignInvoice>;
  customerCampaignInvoices: CustomerCampaignInvoiceConnection;
  customerConversation?: Maybe<CustomerConversation>;
  customerConversations: CustomerConversationConnection;
  customerInvoice?: Maybe<CustomerInvoice>;
  customerKeyword?: Maybe<CustomerKeyword>;
  customerKeywordRankings: CustomerKeywordRankingConnection;
  customerKeywords: CustomerKeywordConnection;
  customerUser?: Maybe<CustomerUser>;
  customers: CustomerConnection;
  domain?: Maybe<Domain>;
  domainStatuses: Array<DomainStatus>;
  domains: DomainConnection;
  emailMessage?: Maybe<EmailMessage>;
  fulfillmentRules: FulfillmentRuleConnection;
  geminiReplyComment: ReplyComment;
  getDomainMetrics: DomainMetricsRespose;
  getGoogleAccounts: GoogleAccountsResponse;
  getGoogleLocations: GoogleLocationsResponse;
  getGoogleReviews: GoogleReviewRespose;
  getKeywordsKeywords: KeywordsSuggestionRespose;
  getKeywordsSite: KeywordsSuggestionRespose;
  getTopicKeyword: TopicKeywordResponse;
  grammarTestQuestion?: Maybe<GrammarTestQuestion>;
  grammarTestQuestions: GrammarTestQuestionConnection;
  keywordLocations: LocationResponse;
  linkLocation?: Maybe<LinkLocation>;
  linkLocationStatuses: Array<LinkLocationStatus>;
  linkLocationTypes: Array<LinkLocationType>;
  linkLocations: LinkLocationConnection;
  openaiReplyComment: ReplyComment;
  order?: Maybe<Order>;
  orderContentItems: OrderContentItemConnection;
  orderStatuses: Array<OrderStatus>;
  orders: OrderConnection;
  partner?: Maybe<Partner>;
  partnerAvailableProduct?: Maybe<PartnerAvailableProduct>;
  partnerInvoice?: Maybe<PartnerInvoice>;
  partnerInvoices: PartnerInvoiceConnection;
  partnerPackage?: Maybe<PartnerPackage>;
  partnerPackages: PartnerPackageConnection;
  partnerProduct?: Maybe<PartnerProduct>;
  partnerProducts: PartnerProductConnection;
  partners: PartnerConnection;
  paymentBatch?: Maybe<PaymentBatch>;
  productTaskTypes: ProductTaskTypeConnection;
  products: ProductConnection;
  publisher?: Maybe<Publisher>;
  publisherLinkLocations: PublisherLinkLocationConnection;
  publisherPayments: PublisherPaymentConnection;
  publishers: PublisherConnection;
  site?: Maybe<Site>;
  siteDeployStatuses: Array<SiteDeployStatus>;
  sitePost?: Maybe<SitePost>;
  sitePostStatuses: Array<SitePostStatus>;
  sitePosts: SitePostConnection;
  sites: SiteConnection;
  task?: Maybe<Task>;
  taskStatuses: Array<TaskStatus>;
  taskTypes: TaskTypeConnection;
  tasks: TaskConnection;
  uberallCategories?: Maybe<Array<UberallCategory>>;
  uberallListingDetails: UberallListingDetailsResponse;
  user?: Maybe<User>;
  userTaskReport?: Maybe<UserTaskReport>;
  userWritingSample?: Maybe<UserWritingSample>;
  userWritingSamples: UserWritingSampleConnection;
  users: UserConnection;
  vendor?: Maybe<Vendor>;
  vendors: VendorConnection;
  version?: Maybe<Scalars["String"]>;
  viewer: Viewer;
  workflow?: Maybe<Workflow>;
  workflows: WorkflowConnection;
  writingSampleTopics: WritingSampleTopicConnection;
};

export type QueryAmTaskArgs = {
  id: Scalars["Int"];
};

export type QueryAmTasksArgs = {
  completedAt?: InputMaybe<DateQuery>;
  customerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryBlockDomainsArgs = {
  customerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryCampaignCategoriesArgs = {
  campaignCategoryIds?: InputMaybe<Array<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CampaignCategoriesSort>;
};

export type QueryCategoriesArgs = {
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CategoriesSort>;
};

export type QueryCategoryArgs = {
  id: Scalars["Int"];
};

export type QueryChecklistDefinitionArgs = {
  id: Scalars["Int"];
};

export type QueryChecklistDefinitionsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryCreativesScaleUserArgs = {
  creativesScaleUserId: Scalars["Int"];
};

export type QueryCreativesScaleUsersArgs = {
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  onboardingStatusIds?: InputMaybe<Array<Scalars["String"]>>;
  permissionTaskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryCustomerArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerBusinessListingSpecArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerCampaignInvoiceArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerCampaignInvoicesArgs = {
  customerIds?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerCampaignInvoiceSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryCustomerConversationArgs = {
  conversationId: Scalars["String"];
};

export type QueryCustomerConversationsArgs = {
  customerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerConversationsSort>;
  wasStartedByCustomer?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryCustomerInvoiceArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerKeywordArgs = {
  id: Scalars["Int"];
};

export type QueryCustomerKeywordRankingsArgs = {
  createdAt?: InputMaybe<DateQuery>;
  customerKeywordId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  searchEngine?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomerKeywordRankingSort>;
};

export type QueryCustomerKeywordsArgs = {
  customerId?: InputMaybe<Scalars["Int"]>;
  isTrackingMapRank?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  locationNotNull?: InputMaybe<Scalars["Boolean"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomersKeywordSort>;
};

export type QueryCustomerUserArgs = {
  id: Scalars["Int"];
};

export type QueryCustomersArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerIds?: InputMaybe<Array<Scalars["Int"]>>;
  partnerPortal?: InputMaybe<Scalars["String"]>;
  rcmAccountManagerUserId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomersSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryDomainArgs = {
  id: Scalars["String"];
};

export type QueryDomainsArgs = {
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<DomainsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryEmailMessageArgs = {
  id: Scalars["Int"];
};

export type QueryFulfillmentRulesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  productId?: InputMaybe<Scalars["String"]>;
  taskTypeId?: InputMaybe<Scalars["String"]>;
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type QueryGeminiReplyCommentArgs = {
  comment: Scalars["String"];
  keywords: Scalars["String"];
};

export type QueryGetDomainMetricsArgs = {
  domain: Scalars["String"];
};

export type QueryGetGoogleAccountsArgs = {
  code?: InputMaybe<Scalars["String"]>;
};

export type QueryGetGoogleLocationsArgs = {
  account: Scalars["String"];
  title?: InputMaybe<Scalars["String"]>;
};

export type QueryGetGoogleReviewsArgs = {
  account: Scalars["String"];
  code?: InputMaybe<Scalars["String"]>;
  location: Scalars["String"];
  pageToken?: InputMaybe<Scalars["String"]>;
};

export type QueryGetKeywordsKeywordsArgs = {
  keywords: Array<Scalars["String"]>;
};

export type QueryGetKeywordsSiteArgs = {
  domain: Scalars["String"];
};

export type QueryGetTopicKeywordArgs = {
  keyword: Scalars["String"];
};

export type QueryGrammarTestQuestionArgs = {
  id: Scalars["Int"];
};

export type QueryGrammarTestQuestionsArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryKeywordLocationsArgs = {
  country: Scalars["String"];
  search: Scalars["String"];
};

export type QueryLinkLocationArgs = {
  id: Scalars["Int"];
};

export type QueryLinkLocationsArgs = {
  associatedToPublisher?: InputMaybe<Scalars["Boolean"]>;
  categoryId?: InputMaybe<Scalars["Int"]>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  excludeCustomerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  minDa?: InputMaybe<Scalars["Int"]>;
  minTraffic?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  secondSort?: InputMaybe<LinkLocationsSort>;
  sort?: InputMaybe<LinkLocationsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  typeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryOpenaiReplyCommentArgs = {
  comment: Scalars["String"];
};

export type QueryOrderArgs = {
  id: Scalars["Int"];
};

export type QueryOrderContentItemsArgs = {
  isPostPublished?: InputMaybe<Scalars["Boolean"]>;
  lastVerifiedPostAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
};

export type QueryOrdersArgs = {
  completedAt?: InputMaybe<DateQuery>;
  customerIds?: InputMaybe<Array<Scalars["Int"]>>;
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  isPostPublished?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  productIds?: InputMaybe<Array<Scalars["String"]>>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<OrdersSort>;
  startDate?: InputMaybe<DateQuery>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  treatReportingSmartsResultUrlAsNull?: InputMaybe<Scalars["Boolean"]>;
  workflowInternalStatusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryPartnerArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerAvailableProductArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerInvoiceArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerInvoicesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerIds?: InputMaybe<Array<Scalars["Int"]>>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PartnerInvoiceSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryPartnerPackageArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerPackagesArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PartnerPackageSort>;
};

export type QueryPartnerProductArgs = {
  id: Scalars["Int"];
};

export type QueryPartnerProductsArgs = {
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryPartnersArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  isBillingEnforced?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  portal?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PartnersSort>;
};

export type QueryPaymentBatchArgs = {
  id: Scalars["Int"];
};

export type QueryProductTaskTypesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  productIds?: InputMaybe<Array<Scalars["String"]>>;
  search?: InputMaybe<Scalars["String"]>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryProductsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<ProductsSort>;
};

export type QueryPublisherArgs = {
  id: Scalars["Int"];
};

export type QueryPublisherLinkLocationsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  publisherId: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PublisherLinkLocationSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryPublisherPaymentsArgs = {
  checkPublisherAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
  createdAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderIds?: InputMaybe<Array<Scalars["Int"]>>;
  paymentBatchId?: InputMaybe<Scalars["Int"]>;
  processor?: InputMaybe<Scalars["String"]>;
  processorReferenceId?: InputMaybe<Scalars["String"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  statusLastUpdatedAt?: InputMaybe<DateQuery>;
};

export type QueryPublishersArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PublishersSort>;
};

export type QuerySiteArgs = {
  siteId: Scalars["String"];
};

export type QuerySitePostArgs = {
  id: Scalars["Int"];
};

export type QuerySitePostsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  siteIds?: InputMaybe<Array<Scalars["String"]>>;
  sort?: InputMaybe<SitePostsSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QuerySitesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<SitesSort>;
};

export type QueryTaskArgs = {
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  taskId: Scalars["Int"];
};

export type QueryTaskTypesArgs = {
  ids?: InputMaybe<Array<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryTasksArgs = {
  assignedAt?: InputMaybe<DateQuery>;
  assignedUserIds?: InputMaybe<Array<Scalars["Int"]>>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]>>;
  completedAt?: InputMaybe<DateQuery>;
  customerIds?: InputMaybe<Array<Scalars["Int"]>>;
  excludedVendorIds?: InputMaybe<Array<Scalars["Int"]>>;
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  isFulfilledByRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  notTaskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
  offset?: InputMaybe<Scalars["Int"]>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  productTaskTypes?: InputMaybe<Array<InputMaybe<ProductTaskTypePair>>>;
  publisherId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<TasksSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
  vendorIds?: InputMaybe<Array<Scalars["Int"]>>;
  workflowStatuses?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryUberallCategoriesArgs = {
  categories?: InputMaybe<Scalars["String"]>;
  language: Scalars["String"];
  max?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  q?: InputMaybe<Scalars["String"]>;
  roots?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryUberallListingDetailsArgs = {
  locationId: Scalars["Int"];
};

export type QueryUserArgs = {
  id: Scalars["Int"];
};

export type QueryUserTaskReportArgs = {
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
  userId: Scalars["Int"];
};

export type QueryUserWritingSampleArgs = {
  userWritingSampleId: Scalars["Int"];
};

export type QueryUserWritingSamplesArgs = {
  createdAt?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  reviewedAt?: InputMaybe<DateQuery>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<UserWritingSamplesSort>;
  userId?: InputMaybe<Scalars["Int"]>;
};

export type QueryUsersArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  isAdminOrSuperAdmin?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<UsersSort>;
  vendorIds?: InputMaybe<Array<Scalars["Int"]>>;
};

export type QueryVendorArgs = {
  id: Scalars["Int"];
};

export type QueryVendorsArgs = {
  ids?: InputMaybe<Array<Scalars["Int"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  showExternalVendors?: InputMaybe<Scalars["Boolean"]>;
  showSystemVendors?: InputMaybe<Scalars["Boolean"]>;
  sort?: InputMaybe<VendorsSort>;
};

export type QueryWorkflowArgs = {
  workflowId: Scalars["Int"];
};

export type QueryWorkflowsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type QueryWritingSampleTopicsArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type ReCreateGuestOutreachTaskInput = {
  orderId: Scalars["Int"];
};

export type ReCreateGuestOutreachTaskResponse = {
  __typename?: "ReCreateGuestOutreachTaskResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type RecordCustomerCampaignInvoicePaymentInput = {
  amount: Scalars["Float"];
  customerCampaignInvoiceId: Scalars["Int"];
  processor: Scalars["String"];
  processorReferenceId: Scalars["String"];
};

export type RecordCustomerCampaignInvoicePaymentResponse = {
  __typename?: "RecordCustomerCampaignInvoicePaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  wasPaymentSuccessful?: Maybe<Scalars["Boolean"]>;
};

export type RecordCustomerInvoicePaymentInput = {
  amount: Scalars["Float"];
  customerInvoiceId: Scalars["Int"];
  processor: Scalars["String"];
  processorReferenceId: Scalars["String"];
};

export type RecordCustomerInvoicePaymentResponse = {
  __typename?: "RecordCustomerInvoicePaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  wasPaymentSuccessful?: Maybe<Scalars["Boolean"]>;
};

export type RecordPartnerInvoicePaymentInput = {
  amount: Scalars["Float"];
  partnerInvoiceId: Scalars["Int"];
  processor: Scalars["String"];
  processorReferenceId: Scalars["String"];
};

export type RecordPartnerInvoicePaymentResponse = {
  __typename?: "RecordPartnerInvoicePaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  wasPaymentSuccessful?: Maybe<Scalars["Boolean"]>;
};

export type RefreshDomainAvailabilityInput = {
  domainId: Scalars["String"];
};

export type RefreshDomainAvailabilityResponse = {
  __typename?: "RefreshDomainAvailabilityResponse";
  ok: Scalars["Boolean"];
};

export type RejectUserWritingSampleInput = {
  comments: Scalars["String"];
  userWritingSampleId: Scalars["Int"];
};

export type RejectUserWritingSampleResponse = {
  __typename?: "RejectUserWritingSampleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type ReplyComment = {
  __typename?: "ReplyComment";
  reply: Scalars["String"];
};

export type RequestPasswordResetInput = {
  email: Scalars["String"];
  origin?: InputMaybe<Scalars["String"]>;
};

export type RequestPasswordResetResponse = {
  __typename?: "RequestPasswordResetResponse";
  wasRequested: Scalars["Boolean"];
};

export type ResendConfirmationEmailInput = {
  creativesScaleUserId?: InputMaybe<Scalars["Int"]>;
  email?: InputMaybe<Scalars["String"]>;
};

export type ResendConfirmationEmailResponse = {
  __typename?: "ResendConfirmationEmailResponse";
  ok: Scalars["Boolean"];
};

export type ResetPasswordInput = {
  id: Scalars["String"];
  password: Scalars["String"];
};

export type ResetPasswordResponse = {
  __typename?: "ResetPasswordResponse";
  wasReset: Scalars["Boolean"];
};

export type ResolveOrderContentStrategyReviewInput = {
  orderContentStrategyReviewId: Scalars["Int"];
};

export type ResolveOrderContentStrategyReviewResponse = {
  __typename?: "ResolveOrderContentStrategyReviewResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderContentStrategyReview?: Maybe<OrderContentStrategyReview>;
};

export type ResponseError = {
  __typename?: "ResponseError";
  inputFieldErrors: Array<InputFieldError>;
  message: Scalars["String"];
};

export type ReviewReply = {
  __typename?: "ReviewReply";
  comment?: Maybe<Scalars["String"]>;
  updateTime?: Maybe<Scalars["DateTime"]>;
};

export type Reviewer = {
  __typename?: "Reviewer";
  displayName?: Maybe<Scalars["String"]>;
  isAnonymous?: Maybe<Scalars["Boolean"]>;
  profilePhotoUrl?: Maybe<Scalars["String"]>;
};

export type SaveCustomerBillingConfigInput = {
  customerId: Scalars["Int"];
  isAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
  isBillingEnforced?: InputMaybe<Scalars["Boolean"]>;
  isUpfrontPaymentRequired?: InputMaybe<Scalars["Boolean"]>;
};

export type SaveCustomerBillingConfigResponse = {
  __typename?: "SaveCustomerBillingConfigResponse";
  customerBillingConfig: CustomerBillingConfig;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveCustomerBusinessListingSpecInput = {
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  email?: InputMaybe<Scalars["String"]>;
  hasPhysicalStorefront?: InputMaybe<Scalars["Boolean"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stateOrProvince?: InputMaybe<Scalars["String"]>;
  zipPostalCode?: InputMaybe<Scalars["String"]>;
};

export type SaveCustomerBusinessListingSpecResponse = {
  __typename?: "SaveCustomerBusinessListingSpecResponse";
  customerBusinessListingSpec: CustomerBusinessListingSpec;
};

export type SaveCustomerHoursOfOperationItemsInput = {
  itemsToCreate: Array<CreateCustomerHoursOfOperationItemInput>;
  itemsToDelete: Array<Scalars["Int"]>;
  itemsToUpdate: Array<UpdateCustomerHoursOfOperationItemInput>;
};

export type SaveCustomerHoursOfOperationItemsResponse = {
  __typename?: "SaveCustomerHoursOfOperationItemsResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type SaveCustomerPremiumBusinessListingSpecInput = {
  customerId: Scalars["Int"];
  hotelAmenities?: InputMaybe<Array<Scalars["String"]>>;
  hotelBathroom?: InputMaybe<Scalars["String"]>;
  hotelFrontDesk?: InputMaybe<Scalars["String"]>;
  hotelHousekeeping?: InputMaybe<Scalars["String"]>;
  hotelHousekeepingFrequency?: InputMaybe<Scalars["String"]>;
  hotelMinimumStay?: InputMaybe<Scalars["String"]>;
  hotelOnSiteStaff?: InputMaybe<Scalars["Boolean"]>;
  hotelSecurity?: InputMaybe<Scalars["String"]>;
  isAlwaysOpen?: InputMaybe<Scalars["Boolean"]>;
  isBlackOwned?: InputMaybe<Scalars["Boolean"]>;
  isWomenOwned?: InputMaybe<Scalars["Boolean"]>;
  priceRangeLevel?: InputMaybe<Scalars["Int"]>;
  shouldReceiveLeadMessages?: InputMaybe<Scalars["Boolean"]>;
  specialHoursOfOperation?: InputMaybe<Scalars["String"]>;
  yearsInBusiness?: InputMaybe<Scalars["Int"]>;
};

export type SaveCustomerPremiumBusinessListingSpecResponse = {
  __typename?: "SaveCustomerPremiumBusinessListingSpecResponse";
  customerPremiumBusinessListingSpec: CustomerPremiumBusinessListingSpec;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveCustomerQuestionnaireInput = {
  businessDescription?: InputMaybe<Scalars["String"]>;
  callToActionUrls?: InputMaybe<Scalars["String"]>;
  competitorWebsites?: InputMaybe<Scalars["String"]>;
  contentPreference?: InputMaybe<Scalars["String"]>;
  contentPublishingPermissions?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  customerMarketSize?: InputMaybe<Scalars["String"]>;
  isAddImage?: InputMaybe<Scalars["Boolean"]>;
  isRCMPublishing?: InputMaybe<Scalars["Boolean"]>;
  preferredVoice?: InputMaybe<Scalars["String"]>;
  productsOffered?: InputMaybe<Scalars["String"]>;
  purposeOfContent?: InputMaybe<Scalars["String"]>;
  serviceArea?: InputMaybe<Scalars["String"]>;
  targetArea?: InputMaybe<Scalars["String"]>;
  targetAudience?: InputMaybe<Scalars["String"]>;
  targetInternalLinks?: InputMaybe<Scalars["String"]>;
  targetKeywords?: InputMaybe<Scalars["String"]>;
  websiteCms?: InputMaybe<Scalars["String"]>;
  websiteHosting?: InputMaybe<Scalars["String"]>;
  whoCanChangeWebsite?: InputMaybe<Scalars["String"]>;
};

export type SaveCustomerQuestionnaireResponse = {
  __typename?: "SaveCustomerQuestionnaireResponse";
  customerQuestionnaire: CustomerQuestionnaire;
};

export type SaveCustomerSiteCredentialInput = {
  adminUrl?: InputMaybe<Scalars["String"]>;
  customerId: Scalars["Int"];
  instructions?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<Scalars["String"]>;
};

export type SaveCustomerSiteCredentialResponse = {
  __typename?: "SaveCustomerSiteCredentialResponse";
  customerSiteCredential: CustomerSiteCredential;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveGrammarTestQuestionInput = {
  answers?: InputMaybe<Array<GrammarTestQuestionAnswerInput>>;
  grammarTestQuestionId?: InputMaybe<Scalars["Int"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  prompt?: InputMaybe<Scalars["String"]>;
};

export type SaveGrammarTestQuestionResponse = {
  __typename?: "SaveGrammarTestQuestionResponse";
  error?: Maybe<ResponseError>;
  grammarTestQuestion?: Maybe<GrammarTestQuestion>;
  ok: Scalars["Boolean"];
};

export type SaveLinkLocationCredentialInput = {
  adminUrl: Scalars["String"];
  instructions?: InputMaybe<Scalars["String"]>;
  linkLocationId: Scalars["Int"];
  password: Scalars["String"];
  userName: Scalars["String"];
};

export type SaveLinkLocationCredentialResponse = {
  __typename?: "SaveLinkLocationCredentialResponse";
  error?: Maybe<ResponseError>;
  linkLocationCredential?: Maybe<LinkLocationCredential>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type SaveOrderContentItemInput = {
  content: Scalars["String"];
  orderId: Scalars["Int"];
  title: Scalars["String"];
};

export type SaveOrderContentItemResponse = {
  __typename?: "SaveOrderContentItemResponse";
  contentItem: ContentItem;
};

export type SavePartnerAnalyticCredentialInput = {
  instructions?: InputMaybe<Scalars["String"]>;
  partnerId: Scalars["Int"];
  password?: InputMaybe<Scalars["String"]>;
  userName?: InputMaybe<Scalars["String"]>;
};

export type SavePartnerAnalyticCredentialResponse = {
  __typename?: "SavePartnerAnalyticCredentialResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
  partnerAnalyticCredential?: Maybe<PartnerAnalyticCredential>;
};

export type SaveProposedOrderTopicInput = {
  customerKeywordId: Scalars["Int"];
  customerProposalId: Scalars["Int"];
  orderId: Scalars["Int"];
  taskId: Scalars["Int"];
  topic: Scalars["String"];
};

export type SaveProposedOrderTopicResponse = {
  __typename?: "SaveProposedOrderTopicResponse";
  proposedOrderTopic: ProposedOrderTopic;
};

export type SaveTaskContentIdeationInput = {
  idea: Scalars["String"];
  taskId: Scalars["Int"];
};

export type SaveTaskContentIdeationResponse = {
  __typename?: "SaveTaskContentIdeationResponse";
  contentIdeation: ContentIdeation;
  task: Task;
};

export type SaveTaskContentItemInput = {
  content: Scalars["String"];
  ignoreContentTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  taskId: Scalars["Int"];
  thumbnailS3FileId?: InputMaybe<Scalars["String"]>;
  title: Scalars["String"];
};

export type SaveTaskContentItemResponse = {
  __typename?: "SaveTaskContentItemResponse";
  contentItem: ContentItem;
  task?: Maybe<Task>;
};

export type SaveTaskGbpItemInput = {
  content: Scalars["String"];
  readMoreUrl?: InputMaybe<Scalars["String"]>;
  taskId: Scalars["Int"];
  thumbnailS3FileId?: InputMaybe<Scalars["String"]>;
};

export type SaveTaskGbpItemResponse = {
  __typename?: "SaveTaskGbpItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveTaskTitleMetaDescriptionInput = {
  isFromReview?: InputMaybe<Scalars["Boolean"]>;
  taskId: Scalars["Int"];
  titleMetaDescriptions: Array<TitleMetaDescriptionInput>;
};

export type SaveTaskTitleMetaDescriptionResponse = {
  __typename?: "SaveTaskTitleMetaDescriptionResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SaveUserGrammarTestResponseInput = {
  answerId: Scalars["Int"];
};

export type SaveUserGrammarTestResponseResponse = {
  __typename?: "SaveUserGrammarTestResponseResponse";
  ok: Scalars["Boolean"];
};

export type SaveUserProfessionalInput = {
  linkedInProfileUrl?: InputMaybe<Scalars["String"]>;
  resumeS3FileId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type SaveUserProfessionalResponse = {
  __typename?: "SaveUserProfessionalResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userProfessional: UserProfessional;
};

export type SaveUserWorkInterestsInput = {
  interests: Array<Scalars["String"]>;
};

export type SaveUserWorkInterestsResponse = {
  __typename?: "SaveUserWorkInterestsResponse";
  interests: Array<Scalars["String"]>;
};

export type SaveWritingSampleInput = {
  content: Scalars["String"];
  isFinished: Scalars["Boolean"];
  title: Scalars["String"];
};

export type SaveWritingSampleResponse = {
  __typename?: "SaveWritingSampleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userWritingSample?: Maybe<UserWritingSample>;
};

export type SelectWritingSampleTopicInput = {
  writingSampleTopicId?: InputMaybe<Scalars["Int"]>;
};

export type SelectWritingSampleTopicResponse = {
  __typename?: "SelectWritingSampleTopicResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SendEmailManagedSeoInput = {
  customerId: Scalars["Int"];
  userId: Scalars["Int"];
};

export type SendEmailManagedSeoResponse = {
  __typename?: "SendEmailManagedSeoResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type SignupCsUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  isAuthorizedToWorkInUS: Scalars["Boolean"];
  lastName: Scalars["String"];
  password: Scalars["String"];
};

export type SignupCsUserResponse = {
  __typename?: "SignupCSUserResponse";
  creativesScaleUser?: Maybe<CreativesScaleUser>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type Site = {
  __typename?: "Site";
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars["Int"]>;
  deployStatus: SiteDeployStatus;
  domainId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  lastPublishedAt?: Maybe<Scalars["DateTime"]>;
  siteTemplate?: Maybe<SiteTemplate>;
  siteTemplateId?: Maybe<Scalars["Int"]>;
};

export type SiteConnection = {
  __typename?: "SiteConnection";
  nodes: Array<Site>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type SiteDeployStatus = {
  __typename?: "SiteDeployStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type SitePost = {
  __typename?: "SitePost";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  category?: Maybe<Category>;
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  lastUpdatedAt?: Maybe<Scalars["DateTime"]>;
  site?: Maybe<Site>;
  siteId?: Maybe<Scalars["String"]>;
  status: SitePostStatus;
  statusId: Scalars["String"];
  taskId?: Maybe<Scalars["Int"]>;
  title: Scalars["String"];
};

export type SitePostConnection = {
  __typename?: "SitePostConnection";
  nodes: Array<SitePost>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type SitePostStatus = {
  __typename?: "SitePostStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum SitePostsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type SiteTemplate = {
  __typename?: "SiteTemplate";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  name: Scalars["String"];
};

export enum SitesSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type StartGrammarTestResponse = {
  __typename?: "StartGrammarTestResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type StartWritingSampleResponse = {
  __typename?: "StartWritingSampleResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type StripeOnboardingStatus = {
  __typename?: "StripeOnboardingStatus";
  helpText: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type SubmitProposalReviewInput = {
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  reviewComment?: InputMaybe<Scalars["String"]>;
  reviewStatusId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  taskId: Scalars["Int"];
};

export type SubmitProposalReviewResponse = {
  __typename?: "SubmitProposalReviewResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type SyncUberallLocationInput = {
  uberallLocationId: Scalars["Int"];
};

export type SyncUberallLocationResponse = {
  __typename?: "SyncUberallLocationResponse";
  error?: Maybe<ResponseError>;
  ok?: Maybe<Scalars["Boolean"]>;
};

export type Task = {
  __typename?: "Task";
  assignedAt?: Maybe<Scalars["DateTime"]>;
  assignedUser?: Maybe<User>;
  assignedUserId?: Maybe<Scalars["Int"]>;
  availableAt: Scalars["DateTime"];
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars["Int"]>;
  checklist?: Maybe<Checklist>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  contentCalendarOrders: Array<Order>;
  contentIdeation?: Maybe<ContentIdeation>;
  contentItem?: Maybe<ContentItem>;
  createdAt: Scalars["DateTime"];
  creativesScaleTaskStatus?: Maybe<CreativesScaleTaskStatus>;
  currentSubTask?: Maybe<Task>;
  customer: Customer;
  customerId: Scalars["Int"];
  customerKeywordProposal?: Maybe<CustomerKeywordProposal>;
  customerProposal?: Maybe<CustomerProposal>;
  expiresAt?: Maybe<Scalars["DateTime"]>;
  gbpItem?: Maybe<GbpItem>;
  id: Scalars["Int"];
  isAssignedToViewer: Scalars["Boolean"];
  isSystemTaskInProgress: Scalars["Boolean"];
  keyword?: Maybe<Scalars["String"]>;
  landingPageUrl?: Maybe<Scalars["String"]>;
  order: Order;
  orderId: Scalars["Int"];
  paymentAmount?: Maybe<Scalars["Float"]>;
  paymentStatus?: Maybe<TaskPaymentStatus>;
  product: Product;
  productId: Scalars["String"];
  publishPostOrders: Array<Order>;
  reviewComment?: Maybe<Scalars["String"]>;
  reviewStatus?: Maybe<TaskReviewStatus>;
  reviewTargetTask?: Maybe<Task>;
  reviewTargetTaskId?: Maybe<Scalars["Int"]>;
  reviewTasks: TaskConnection;
  sitePost?: Maybe<SitePost>;
  status: TaskStatus;
  subTasks: TaskConnection;
  taskType: TaskType;
  taskTypeId: Scalars["String"];
  titleMetaDescriptionItem?: Maybe<TitleMetaDescriptionItem>;
  topic?: Maybe<Scalars["String"]>;
  validationCheckSummary?: Maybe<ValidationCheckSummary>;
  vendor?: Maybe<Vendor>;
  vendorId?: Maybe<Scalars["Int"]>;
  workflow?: Maybe<Workflow>;
  workflowId: Scalars["Int"];
};

export type TaskReviewTasksArgs = {
  isFulfilledByCustomer?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  reviewStatusId?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<TasksSort>;
};

export type TaskSubTasksArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TasksSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]>>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type TaskConnection = {
  __typename?: "TaskConnection";
  nodes: Array<Task>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type TaskCount = {
  __typename?: "TaskCount";
  count: Scalars["Int"];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["String"]>;
  rejectedCount: Scalars["Int"];
  taskType?: Maybe<TaskType>;
  taskTypeId?: Maybe<Scalars["String"]>;
};

export type TaskPaymentStatus = {
  __typename?: "TaskPaymentStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type TaskReport = {
  __typename?: "TaskReport";
  completedAt: Scalars["DateTime"];
  dayNumber: Scalars["Int"];
  taskCount?: Maybe<Array<TaskCount>>;
};

export type TaskReviewStatus = {
  __typename?: "TaskReviewStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type TaskStatus = {
  __typename?: "TaskStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type TaskType = {
  __typename?: "TaskType";
  defaultVendorId: Scalars["Int"];
  id: Scalars["String"];
  isFulfilledByCustomer: Scalars["Boolean"];
  name: Scalars["String"];
};

export type TaskTypeConnection = {
  __typename?: "TaskTypeConnection";
  nodes: Array<TaskType>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum TasksSort {
  CompletedAtAsc = "completed_at_asc",
  CompletedAtDesc = "completed_at_desc",
  IdAsc = "id_asc",
  IdDesc = "id_desc",
  OrderStartAsc = "order_start_asc",
  OrderStartDesc = "order_start_desc",
  Priority = "priority",
  WriterAsc = "writer_asc",
  WriterDesc = "writer_desc",
}

export type TitleMetaDescription = {
  __typename?: "TitleMetaDescription";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  metaDescription: Scalars["String"];
  titleTag: Scalars["String"];
  url: Scalars["String"];
};

export type TitleMetaDescriptionInput = {
  id?: InputMaybe<Scalars["Int"]>;
  metaDescription: Scalars["String"];
  titleTag: Scalars["String"];
  url: Scalars["String"];
};

export type TitleMetaDescriptionItem = {
  __typename?: "TitleMetaDescriptionItem";
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  titleMetaDescriptions: Array<TitleMetaDescription>;
};

export type TopicKeywordResponse = {
  __typename?: "TopicKeywordResponse";
  topicSuggestions: Scalars["String"];
};

export type UberallCategory = {
  __typename?: "UberallCategory";
  fullName: Scalars["String"];
  id: Scalars["Int"];
  name: Scalars["String"];
  parent: Scalars["Int"];
  selectable: Scalars["Boolean"];
};

export type UberallListingDetails = {
  __typename?: "UberallListingDetails";
  action?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  listingUrl?: Maybe<Scalars["String"]>;
  logoUrl?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  typeName?: Maybe<Scalars["String"]>;
};

export type UberallListingDetailsResponse = {
  __typename?: "UberallListingDetailsResponse";
  countListingsActionRequired?: Maybe<Scalars["Int"]>;
  countListingsBeingUpdated?: Maybe<Scalars["Int"]>;
  countListingsInSync?: Maybe<Scalars["Int"]>;
  countListingsLinked?: Maybe<Scalars["Int"]>;
  countListingsSubmitted?: Maybe<Scalars["Int"]>;
  listings?: Maybe<Array<UberallListingDetails>>;
  totalListings?: Maybe<Scalars["Int"]>;
};

export type UpdateAmTaskInput = {
  amTaskId: Scalars["Int"];
  completedAt?: InputMaybe<Scalars["Date"]>;
  info?: InputMaybe<Scalars["String"]>;
  taskType?: InputMaybe<Scalars["String"]>;
};

export type UpdateAmTaskResponse = {
  __typename?: "UpdateAmTaskResponse";
  amTask?: Maybe<AmTask>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateCategoryInput = {
  categoryId: Scalars["Int"];
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateCategoryResponse = {
  __typename?: "UpdateCategoryResponse";
  category: Category;
};

export type UpdateChecklistItemInput = {
  checklistItemId: Scalars["Int"];
  completedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateChecklistItemResponse = {
  __typename?: "UpdateChecklistItemResponse";
  checklistItem: ChecklistItem;
};

export type UpdateCreativeScaleUserInput = {
  countryOfResidence?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  isEligibleForPayments?: InputMaybe<Scalars["Boolean"]>;
  isOptingInForUpdates?: InputMaybe<Scalars["Boolean"]>;
  isRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  isStripeOnboardingReminderEnabled?: InputMaybe<Scalars["Boolean"]>;
  linkedInProfileUrl?: InputMaybe<Scalars["String"]>;
  resumeS3FileId?: InputMaybe<Scalars["String"]>;
  writerClassification?: InputMaybe<Scalars["String"]>;
};

export type UpdateCreativeScaleUserResponse = {
  __typename?: "UpdateCreativeScaleUserResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateCustomerBusinessListingSpecInput = {
  address?: InputMaybe<Scalars["String"]>;
  addressDisplay?: InputMaybe<Scalars["Boolean"]>;
  city?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  googleLocationId?: InputMaybe<Scalars["String"]>;
  hasPhysicalStorefront?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["Int"];
  isListed?: InputMaybe<Scalars["Boolean"]>;
  isReviewEnabled?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stateOrProvince?: InputMaybe<Scalars["String"]>;
  uberallCategoryId?: InputMaybe<Scalars["Int"]>;
  zipPostalCode?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerBusinessListingSpecResponse = {
  __typename?: "UpdateCustomerBusinessListingSpecResponse";
  customerBusinessListingSpec?: Maybe<CustomerBusinessListingSpec>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateCustomerCampaignInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  customerCampaignId: Scalars["Int"];
  endsAt?: InputMaybe<Scalars["DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  startsAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateCustomerCampaignResponse = {
  __typename?: "UpdateCustomerCampaignResponse";
  customerCampaign: CustomerCampaign;
};

export type UpdateCustomerConversationInput = {
  customerConversationId: Scalars["String"];
  helpRequestedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateCustomerConversationResponse = {
  __typename?: "UpdateCustomerConversationResponse";
  customerConversation: CustomerConversation;
};

export type UpdateCustomerHoursOfOperationItemInput = {
  closeAt?: InputMaybe<Scalars["String"]>;
  dayIndex?: InputMaybe<Scalars["Int"]>;
  id: Scalars["Int"];
  isOpen?: InputMaybe<Scalars["Boolean"]>;
  openAt?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerInput = {
  accountManagerUserId?: InputMaybe<Scalars["Int"]>;
  address?: InputMaybe<Scalars["String"]>;
  analyticsReportingInlineFrameUrl?: InputMaybe<Scalars["String"]>;
  categoryId?: InputMaybe<Scalars["Int"]>;
  customerId: Scalars["Int"];
  gbpUrl?: InputMaybe<Scalars["String"]>;
  googleAccountId?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  isBlogFulfilledByRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  isGuestArticleFulfilledByRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  rcmAccountManagerUserId?: InputMaybe<Scalars["Int"]>;
  website?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerKeywordInput = {
  country?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  instructions?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  isTrackingMapRank?: InputMaybe<Scalars["Boolean"]>;
  isTrackingRank?: InputMaybe<Scalars["Boolean"]>;
  landingPageUrl?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerKeywordProposalInput = {
  customerConversationId?: InputMaybe<Scalars["String"]>;
  id: Scalars["Int"];
  summary?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerKeywordProposalResponse = {
  __typename?: "UpdateCustomerKeywordProposalResponse";
  customerKeywordProposal?: Maybe<CustomerKeywordProposal>;
};

export type UpdateCustomerKeywordResponse = {
  __typename?: "UpdateCustomerKeywordResponse";
  customerKeyword?: Maybe<CustomerKeyword>;
};

export type UpdateCustomerNoteInput = {
  content: Scalars["String"];
  noteId: Scalars["Int"];
};

export type UpdateCustomerNoteResponse = {
  __typename?: "UpdateCustomerNoteResponse";
  note?: Maybe<Note>;
};

export type UpdateCustomerProposalInput = {
  customerProposalId: Scalars["Int"];
  summary?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerProposalResponse = {
  __typename?: "UpdateCustomerProposalResponse";
  customerProposal?: Maybe<CustomerProposal>;
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateCustomerResponse = {
  __typename?: "UpdateCustomerResponse";
  customer?: Maybe<Customer>;
};

export type UpdateCustomerStatusChangeInput = {
  customerStatusChangeId: Scalars["Int"];
  effectiveAt: Scalars["Date"];
  reason?: InputMaybe<Scalars["String"]>;
};

export type UpdateCustomerStatusChangeResponse = {
  __typename?: "UpdateCustomerStatusChangeResponse";
  customerStatusChange: CustomerStatusChange;
};

export type UpdateCustomerUserInput = {
  customerUserId: Scalars["Int"];
  isActive: Scalars["Boolean"];
};

export type UpdateCustomerUserResponse = {
  __typename?: "UpdateCustomerUserResponse";
  customerUser: CustomerUser;
};

export type UpdateFeedbackContentItemInput = {
  feedback?: InputMaybe<Scalars["String"]>;
  feedbackContentItemId: Scalars["Int"];
  rating?: InputMaybe<Scalars["Int"]>;
};

export type UpdateFeedbackContentItemResponse = {
  __typename?: "UpdateFeedbackContentItemResponse";
  error?: Maybe<ResponseError>;
  feedbackContentItem?: Maybe<FeedbackContentItem>;
  ok: Scalars["Boolean"];
};

export type UpdateFulfillmentRuleInput = {
  fulfillmentRuleId: Scalars["Int"];
  vendorId: Scalars["Int"];
};

export type UpdateFulfillmentRuleResponse = {
  __typename?: "UpdateFulfillmentRuleResponse";
  error?: Maybe<ResponseError>;
  fulfillmentRule?: Maybe<FulfillmentRule>;
  ok: Scalars["Boolean"];
};

export type UpdateLinkLocationCategoriesInput = {
  categoryIds: Array<Scalars["Int"]>;
  linkLocationId: Scalars["Int"];
};

export type UpdateLinkLocationCategoriesResponse = {
  __typename?: "UpdateLinkLocationCategoriesResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateLinkLocationInput = {
  allowsAIContent?: InputMaybe<Scalars["Boolean"]>;
  allowsCbdContent?: InputMaybe<Scalars["Boolean"]>;
  allowsOnlyOneLinkInContent?: InputMaybe<Scalars["Boolean"]>;
  allowsWeapons?: InputMaybe<Scalars["Boolean"]>;
  authorityScore?: InputMaybe<Scalars["Int"]>;
  autoAssign?: InputMaybe<Scalars["Boolean"]>;
  contactEmail?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  cost?: InputMaybe<Scalars["Float"]>;
  domainAuthority?: InputMaybe<Scalars["Int"]>;
  internalQuality?: InputMaybe<Scalars["Int"]>;
  linkLocationId: Scalars["Int"];
  minimumWordCount?: InputMaybe<Scalars["Int"]>;
  monthlyTraffic?: InputMaybe<Scalars["Int"]>;
  notes?: InputMaybe<Scalars["String"]>;
  spam?: InputMaybe<Scalars["Int"]>;
  statusId?: InputMaybe<Scalars["String"]>;
  typeId?: InputMaybe<Scalars["String"]>;
};

export type UpdateLinkLocationMetricsInput = {
  linkLocationId: Scalars["Int"];
};

export type UpdateLinkLocationMetricsResponse = {
  __typename?: "UpdateLinkLocationMetricsResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateLinkLocationResponse = {
  __typename?: "UpdateLinkLocationResponse";
  error?: Maybe<ResponseError>;
  linkLocation?: Maybe<LinkLocation>;
  ok: Scalars["Boolean"];
};

export type UpdateOrderContentItemInput = {
  isPostPublished: Scalars["Boolean"];
  orderContentItemId: Scalars["Int"];
};

export type UpdateOrderContentItemResponse = {
  __typename?: "UpdateOrderContentItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderContentItem?: Maybe<OrderContentItem>;
};

export type UpdateOrderContentStrategyInput = {
  clientProvidedInstructions?: InputMaybe<Scalars["String"]>;
  customerKeywordId?: InputMaybe<Scalars["Int"]>;
  focusPoints?: InputMaybe<Scalars["String"]>;
  isProposed?: InputMaybe<Scalars["Boolean"]>;
  linkTo?: InputMaybe<Scalars["String"]>;
  orderContentStrategyId: Scalars["Int"];
  orderHaloKeywords?: InputMaybe<Array<Scalars["Int"]>>;
  pageType?: InputMaybe<Scalars["String"]>;
  purposeOfContent?: InputMaybe<Scalars["String"]>;
  sampleContent?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
  voice?: InputMaybe<Scalars["String"]>;
  webPage?: InputMaybe<Scalars["String"]>;
};

export type UpdateOrderContentStrategyResponse = {
  __typename?: "UpdateOrderContentStrategyResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderContentStrategy?: Maybe<OrderContentStrategy>;
};

export type UpdateOrderExternalContentSpecInput = {
  completedAt?: InputMaybe<Scalars["DateTime"]>;
  cost?: InputMaybe<Scalars["Float"]>;
  domainAuthority?: InputMaybe<Scalars["Int"]>;
  orderExternalContentSpecId: Scalars["Int"];
  trustFlow?: InputMaybe<Scalars["Int"]>;
};

export type UpdateOrderExternalContentSpecResponse = {
  __typename?: "UpdateOrderExternalContentSpecResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderExternalContentSpec?: Maybe<OrderExternalContentSpec>;
};

export type UpdateOrderGbpItemInput = {
  content: Scalars["String"];
  isProfilePublished: Scalars["Boolean"];
  orderGbpItemId: Scalars["Int"];
  readMoreUrl?: InputMaybe<Scalars["String"]>;
  thumbnailS3FileId?: InputMaybe<Scalars["String"]>;
};

export type UpdateOrderGbpItemResponse = {
  __typename?: "UpdateOrderGbpItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderGbpItem?: Maybe<OrderGbpItem>;
};

export type UpdateOrderInput = {
  customerCampaignId?: InputMaybe<Scalars["Int"]>;
  customerKeywordId?: InputMaybe<Scalars["Int"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  linkLocationId?: InputMaybe<Scalars["Int"]>;
  orderId: Scalars["Int"];
  publisherId?: InputMaybe<Scalars["Int"]>;
  quantity?: InputMaybe<Scalars["Int"]>;
  resultUrl?: InputMaybe<Scalars["String"]>;
  resultUrlDomainAuthority?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  targetLinkLocationId?: InputMaybe<Scalars["Int"]>;
  targetResultUrl?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
  validateResultUrl?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateOrderResponse = {
  __typename?: "UpdateOrderResponse";
  order: Order;
};

export type UpdateOrderTitleMetaDescriptionItemInput = {
  isPublished: Scalars["Boolean"];
  orderTitleMetaDescriptionItemId: Scalars["Int"];
};

export type UpdateOrderTitleMetaDescriptionItemResponse = {
  __typename?: "UpdateOrderTitleMetaDescriptionItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orderTitleMetaDescriptionItem?: Maybe<OrderTitleMetaDescriptionItem>;
};

export type UpdatePartnerBillingContactInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  partnerBillingContactId: Scalars["Int"];
};

export type UpdatePartnerBillingContactResponse = {
  __typename?: "UpdatePartnerBillingContactResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerBillingContact?: Maybe<PartnerBillingContact>;
};

export type UpdatePartnerInput = {
  autopayPartnerPaymentMethodId?: InputMaybe<Scalars["Int"]>;
  billingEmail?: InputMaybe<Scalars["String"]>;
  emailLogoS3FileId?: InputMaybe<Scalars["String"]>;
  isAutopayEnabled?: InputMaybe<Scalars["Boolean"]>;
  isBillingEnforced?: InputMaybe<Scalars["Boolean"]>;
  isUpfrontPaymentRequired?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  partnerId: Scalars["Int"];
  portal?: InputMaybe<Scalars["String"]>;
  reportingLogoS3FileId?: InputMaybe<Scalars["String"]>;
  stripeAccountId?: InputMaybe<Scalars["String"]>;
};

export type UpdatePartnerPackageInput = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  partnerPackageId: Scalars["Int"];
};

export type UpdatePartnerPackageItemInput = {
  isRecurring?: InputMaybe<Scalars["Boolean"]>;
  partnerPackageItemId: Scalars["Int"];
  quantity?: InputMaybe<Scalars["Int"]>;
};

export type UpdatePartnerPackageItemResponse = {
  __typename?: "UpdatePartnerPackageItemResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerPackageItem?: Maybe<PartnerPackageItem>;
};

export type UpdatePartnerPackageResponse = {
  __typename?: "UpdatePartnerPackageResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerPackage?: Maybe<PartnerPackage>;
};

export type UpdatePartnerProductInput = {
  activeAt?: InputMaybe<Scalars["DateTime"]>;
  checklistDefinitionId?: InputMaybe<Scalars["Int"]>;
  inactiveAt?: InputMaybe<Scalars["DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  partnerProductId: Scalars["Int"];
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type UpdatePartnerProductPriceInput = {
  partnerProductPriceId: Scalars["Int"];
  price?: InputMaybe<Scalars["Float"]>;
  startsAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdatePartnerProductPriceResponse = {
  __typename?: "UpdatePartnerProductPriceResponse";
  partnerProductPrice: PartnerProductPrice;
};

export type UpdatePartnerProductResponse = {
  __typename?: "UpdatePartnerProductResponse";
  partnerProduct?: Maybe<PartnerProduct>;
};

export type UpdatePartnerProductWholesalePriceInput = {
  effectiveAt?: InputMaybe<Scalars["DateTime"]>;
  partnerProductWholesalePriceId: Scalars["Int"];
  wholesaleAmount?: InputMaybe<Scalars["Float"]>;
};

export type UpdatePartnerProductWholesalePriceResponse = {
  __typename?: "UpdatePartnerProductWholesalePriceResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  partnerProductWholesalePrice?: Maybe<PartnerProductWholesalePrice>;
};

export type UpdatePartnerResponse = {
  __typename?: "UpdatePartnerResponse";
  partner?: Maybe<Partner>;
};

export type UpdatePartnerUserInput = {
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  partnerUserId: Scalars["Int"];
};

export type UpdatePartnerUserResponse = {
  __typename?: "UpdatePartnerUserResponse";
  partnerUser: PartnerUser;
};

export type UpdateProposedCustomerKeywordInput = {
  country?: InputMaybe<Scalars["String"]>;
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  landingPageUrl?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  proposedCustomerKeywordId: Scalars["Int"];
};

export type UpdateProposedCustomerKeywordResponse = {
  __typename?: "UpdateProposedCustomerKeywordResponse";
  proposedCustomerKeyword: ProposedCustomerKeyword;
};

export type UpdatePublisherInput = {
  contactEmail?: InputMaybe<Scalars["String"]>;
  instructions?: InputMaybe<Scalars["String"]>;
  isAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
  isInvoiceRequired?: InputMaybe<Scalars["Boolean"]>;
  isSlowPartner?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  paymentMethod?: InputMaybe<Scalars["String"]>;
  paymentMethodId?: InputMaybe<Scalars["String"]>;
  publisherId: Scalars["Int"];
};

export type UpdatePublisherLinkLocationInput = {
  isRCMPublishing?: InputMaybe<Scalars["Boolean"]>;
  publisherLinkLocationId: Scalars["Int"];
};

export type UpdatePublisherLinkLocationResponse = {
  __typename?: "UpdatePublisherLinkLocationResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherLinkLocation?: Maybe<PublisherLinkLocation>;
};

export type UpdatePublisherPaymentInput = {
  amount?: InputMaybe<Scalars["Float"]>;
  processor?: InputMaybe<Scalars["String"]>;
  processorReferenceId?: InputMaybe<Scalars["String"]>;
  publisherPaymentId: Scalars["Int"];
  statusId?: InputMaybe<Scalars["String"]>;
};

export type UpdatePublisherPaymentResponse = {
  __typename?: "UpdatePublisherPaymentResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisherPayment: PublisherPayment;
};

export type UpdatePublisherResponse = {
  __typename?: "UpdatePublisherResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  publisher?: Maybe<Publisher>;
};

export type UpdateRcmWriterTasksInput = {
  isFulfilledByRCMWriter?: InputMaybe<Scalars["Boolean"]>;
  taskIds?: InputMaybe<Array<Scalars["Int"]>>;
};

export type UpdateRcmWriterTasksResponse = {
  __typename?: "UpdateRcmWriterTasksResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
};

export type UpdateSitePostForTaskInput = {
  content: Scalars["String"];
  taskId: Scalars["Int"];
  title: Scalars["String"];
};

export type UpdateSitePostForTaskResponse = {
  __typename?: "UpdateSitePostForTaskResponse";
  sitePost: SitePost;
};

export type UpdateSitePostInput = {
  categoryId?: InputMaybe<Scalars["Int"]>;
  content?: InputMaybe<Scalars["String"]>;
  siteId?: InputMaybe<Scalars["String"]>;
  sitePostId: Scalars["Int"];
  statusId?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type UpdateSitePostResponse = {
  __typename?: "UpdateSitePostResponse";
  sitePost: SitePost;
};

export type UpdateTaskInput = {
  assignedUserId?: InputMaybe<Scalars["Int"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]>;
  ignoreUserTaskPermission?: InputMaybe<Scalars["Boolean"]>;
  isApproved?: InputMaybe<Scalars["Boolean"]>;
  reviewComment?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  taskId: Scalars["Int"];
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type UpdateTaskResponse = {
  __typename?: "UpdateTaskResponse";
  task: Task;
};

export type UpdateUserInput = {
  calendarUrl?: InputMaybe<Scalars["String"]>;
  emailPreference?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isAdmin?: InputMaybe<Scalars["Boolean"]>;
  profilePictureS3FileId?: InputMaybe<Scalars["String"]>;
  timezone?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type UpdateUserProfessionalInput = {
  linkedInProfileUrl?: InputMaybe<Scalars["String"]>;
  resumeS3FileId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["Int"];
};

export type UpdateUserProfessionalResponse = {
  __typename?: "UpdateUserProfessionalResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  userPorfessional?: Maybe<UserProfessional>;
};

export type UpdateUserResponse = {
  __typename?: "UpdateUserResponse";
  user?: Maybe<User>;
};

export type UpdateVendorInput = {
  name?: InputMaybe<Scalars["String"]>;
  vendorId: Scalars["Int"];
};

export type UpdateVendorResponse = {
  __typename?: "UpdateVendorResponse";
  vendor?: Maybe<Vendor>;
};

export type UpdateVendorUserInput = {
  isActive: Scalars["Boolean"];
  vendorUserId: Scalars["Int"];
};

export type UpdateVendorUserResponse = {
  __typename?: "UpdateVendorUserResponse";
  vendorUser: VendorUser;
};

export type UpdateWritingSampleTopicInput = {
  additionalInformation?: InputMaybe<Scalars["String"]>;
  categoryId?: InputMaybe<Scalars["Int"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  primaryKeyword?: InputMaybe<Scalars["String"]>;
  topic?: InputMaybe<Scalars["String"]>;
  voice?: InputMaybe<Scalars["String"]>;
  website?: InputMaybe<Scalars["String"]>;
  writingSampleTopicId: Scalars["Int"];
};

export type UpdateWritingSampleTopicResponse = {
  __typename?: "UpdateWritingSampleTopicResponse";
  writingSampleTopic: WritingSampleTopic;
};

export type User = {
  __typename?: "User";
  calendarUrl?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  emailPreference?: Maybe<Scalars["String"]>;
  firstName: Scalars["String"];
  fullName: Scalars["String"];
  googleRefreshToken?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  isAdmin: Scalars["Boolean"];
  isEmailConfirmed: Scalars["Boolean"];
  isPasswordSet: Scalars["Boolean"];
  isSuperAdmin: Scalars["Boolean"];
  isTracking: Scalars["Boolean"];
  lastName: Scalars["String"];
  partnerUsers: Array<PartnerUser>;
  partners: Array<Partner>;
  profilePictureFile?: Maybe<File>;
  timezone: Scalars["String"];
  title?: Maybe<Scalars["String"]>;
  userProfessional?: Maybe<UserProfessional>;
  userTaskPermissions: Array<UserTaskPermission>;
  vendorUsers: Array<VendorUser>;
};

export type UserUserTaskPermissionsArgs = {
  vendorId?: InputMaybe<Scalars["Int"]>;
};

export type UserConnection = {
  __typename?: "UserConnection";
  nodes: Array<User>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type UserGrammarTest = {
  __typename?: "UserGrammarTest";
  activeQuestion?: Maybe<UserGrammarTestQuestion>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  expiresAt: Scalars["DateTime"];
  id: Scalars["Int"];
  result?: Maybe<Scalars["String"]>;
  startedAt: Scalars["DateTime"];
  userId: Scalars["Int"];
};

export type UserGrammarTestQuestion = {
  __typename?: "UserGrammarTestQuestion";
  createdAt: Scalars["DateTime"];
  grammarTestQuestion: GrammarTestQuestion;
  grammarTestQuestionId: Scalars["Int"];
  id: Scalars["Int"];
  isCorrect?: Maybe<Scalars["Boolean"]>;
  option1Answer?: Maybe<GrammarTestQuestionAnswer>;
  option1AnswerId: Scalars["Int"];
  option2Answer?: Maybe<GrammarTestQuestionAnswer>;
  option2AnswerId: Scalars["Int"];
  option3Answer?: Maybe<GrammarTestQuestionAnswer>;
  option3AnswerId?: Maybe<Scalars["Int"]>;
  option4Answer?: Maybe<GrammarTestQuestionAnswer>;
  option4AnswerId?: Maybe<Scalars["Int"]>;
  questionIndex: Scalars["Int"];
  selectedAnswerId?: Maybe<Scalars["Int"]>;
  userId: Scalars["Int"];
};

export type UserProfessional = {
  __typename?: "UserProfessional";
  createdAt: Scalars["DateTime"];
  linkedInProfileUrl?: Maybe<Scalars["String"]>;
  resumeFile?: Maybe<File>;
  user: User;
  userId: Scalars["Int"];
};

export type UserTaskPermission = {
  __typename?: "UserTaskPermission";
  createdAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["Int"];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars["String"]>;
  taskType?: Maybe<TaskType>;
  taskTypeId?: Maybe<Scalars["String"]>;
  userId: Scalars["Int"];
  vendorId: Scalars["Int"];
};

export type UserTaskReport = {
  __typename?: "UserTaskReport";
  taskReports?: Maybe<Array<TaskReport>>;
  user: User;
  userId: Scalars["Int"];
};

export type UserWritingSample = {
  __typename?: "UserWritingSample";
  contentItem?: Maybe<ContentItem>;
  contentItemId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["DateTime"];
  endsAt: Scalars["DateTime"];
  id: Scalars["Int"];
  reviewStatus?: Maybe<UserWritingSampleReviewStatus>;
  reviewerUser?: Maybe<User>;
  user: User;
  validationCheckSummary?: Maybe<ValidationCheckSummary>;
  writingSampleTopic?: Maybe<WritingSampleTopic>;
  writingSampleTopicId?: Maybe<Scalars["Int"]>;
};

export type UserWritingSampleConnection = {
  __typename?: "UserWritingSampleConnection";
  nodes: Array<UserWritingSample>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type UserWritingSampleReviewStatus = {
  __typename?: "UserWritingSampleReviewStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export enum UserWritingSamplesSort {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
}

export enum UsersSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type ValidationCheck = {
  __typename?: "ValidationCheck";
  actualValue: Scalars["String"];
  failureCriteria: Scalars["String"];
  helpText: Scalars["String"];
  id: Scalars["String"];
  isRequiredToPass: Scalars["Boolean"];
  isValid: Scalars["Boolean"];
  name: Scalars["String"];
  successCriteria: Scalars["String"];
};

export type ValidationCheckSummary = {
  __typename?: "ValidationCheckSummary";
  checks: Array<ValidationCheck>;
  isValid: Scalars["Boolean"];
};

export type Vendor = {
  __typename?: "Vendor";
  associatedPartnerProducts: PartnerProductConnection;
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isExternal: Scalars["Boolean"];
  name: Scalars["String"];
  partner?: Maybe<Partner>;
  partnerId?: Maybe<Scalars["Int"]>;
  vendorUsers: VendorUserConnection;
};

export type VendorAssociatedPartnerProductsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type VendorVendorUsersArgs = {
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type VendorConnection = {
  __typename?: "VendorConnection";
  nodes: Array<Vendor>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type VendorUser = {
  __typename?: "VendorUser";
  addedByUser: User;
  addedByUserId: Scalars["Int"];
  createdAt: Scalars["DateTime"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  user: User;
  userId: Scalars["Int"];
  userTaskPermissions: Array<UserTaskPermission>;
  vendor: Vendor;
  vendorId: Scalars["Int"];
};

export type VendorUserConnection = {
  __typename?: "VendorUserConnection";
  nodes: Array<VendorUser>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export enum VendorsSort {
  IdAsc = "ID_ASC",
  IdDesc = "ID_DESC",
}

export type VerifyGuestPostInput = {
  orderIds: Array<Scalars["Int"]>;
};

export type VerifyGuestPostResponse = {
  __typename?: "VerifyGuestPostResponse";
  error?: Maybe<ResponseError>;
  ok: Scalars["Boolean"];
  orders?: Maybe<Array<Order>>;
};

export type Viewer = {
  __typename?: "Viewer";
  accessibleCustomerIds: Array<Scalars["Int"]>;
  accessiblePublisherIds: Array<Scalars["Int"]>;
  creativesScaleUser?: Maybe<CreativesScaleUser>;
  hasSLPCustomer?: Maybe<Scalars["Boolean"]>;
  user?: Maybe<User>;
};

export type WorkInterest = {
  __typename?: "WorkInterest";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type Workflow = {
  __typename?: "Workflow";
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  customer: Customer;
  id: Scalars["Int"];
  internalStatus: Scalars["String"];
  lastUpdatedAt: Scalars["DateTime"];
  notes: NoteConnection;
  order: Order;
  status: WorkflowStatus;
  tasks: TaskConnection;
  type: Scalars["String"];
};

export type WorkflowNotesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type WorkflowTasksArgs = {
  includeDeleted?: InputMaybe<Scalars["Boolean"]>;
  sort?: InputMaybe<TasksSort>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type WorkflowConnection = {
  __typename?: "WorkflowConnection";
  nodes: Array<Workflow>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type WorkflowStatus = {
  __typename?: "WorkflowStatus";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type WritingSampleTopic = {
  __typename?: "WritingSampleTopic";
  additionalInformation?: Maybe<Scalars["String"]>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["Date"];
  id: Scalars["Int"];
  isActive: Scalars["Boolean"];
  primaryKeyword?: Maybe<Scalars["String"]>;
  topic: Scalars["String"];
  voice?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type WritingSampleTopicConnection = {
  __typename?: "WritingSampleTopicConnection";
  nodes: Array<WritingSampleTopic>;
  pageInfo?: Maybe<PageInfo>;
  totalCount: Scalars["Int"];
};

export type CreateAdminUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type CreateAdminUserMutation = {
  __typename?: "Mutation";
  createUser: { __typename?: "CreateUserResponse"; user?: { __typename?: "User"; id: number } | null };
};

export type CreateAmTaskMutationVariables = Exact<{
  input: CreateAmTaskInput;
}>;

export type CreateAmTaskMutation = {
  __typename?: "Mutation";
  createAmTask: {
    __typename?: "CreateAmTaskResponse";
    ok: boolean;
    amTask?: { __typename?: "AmTask"; id: number } | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type AddCategoryMutationVariables = Exact<{
  input: CreateCategoryInput;
}>;

export type AddCategoryMutation = {
  __typename?: "Mutation";
  createCategory: { __typename?: "CreateCategoryResponse"; category?: { __typename?: "Category"; id: number } | null };
};

export type AddClientCampaignDrawerQueryVariables = Exact<{
  customerId: Scalars["Int"];
}>;

export type AddClientCampaignDrawerQuery = {
  __typename?: "Query";
  customer?: { __typename?: "Customer"; id: number; partnerId: number } | null;
};

export type CreateClientCampaignMutationVariables = Exact<{
  input: CreateCustomerCampaignInput;
}>;

export type CreateClientCampaignMutation = {
  __typename?: "Mutation";
  createCustomerCampaign: {
    __typename?: "CreateCustomerCampaignResponse";
    customerCampaign: { __typename?: "CustomerCampaign"; id: number };
  };
};

export type CreateCustomerMutationVariables = Exact<{
  input: CreateCustomerInput;
}>;

export type CreateCustomerMutation = {
  __typename?: "Mutation";
  createCustomer: { __typename?: "CreateCustomerResponse"; customer?: { __typename?: "Customer"; id: number } | null };
};

export type CreateClientKeywordMutationVariables = Exact<{
  input: CreateCustomerKeywordInput;
}>;

export type CreateClientKeywordMutation = {
  __typename?: "Mutation";
  createCustomerKeyword: {
    __typename?: "CreateCustomerKeywordResponse";
    customerKeyword: { __typename?: "CustomerKeyword"; id: number };
  };
};

export type CreateClientUserMutationVariables = Exact<{
  input: CreateCustomerUserInput;
}>;

export type CreateClientUserMutation = {
  __typename?: "Mutation";
  createCustomerUser: {
    __typename?: "CreateCustomerUserResponse";
    customerUser?: { __typename?: "CustomerUser"; id: number } | null;
  };
};

export type AddDomainsMutationVariables = Exact<{
  input: CreateDomainsInput;
}>;

export type AddDomainsMutation = {
  __typename?: "Mutation";
  createDomains: { __typename?: "CreateDomainsResponse"; domains: Array<{ __typename?: "Domain"; id: string }> };
};

export type CreateLinkLocationMutationVariables = Exact<{
  input: CreateLinkLocationInput;
}>;

export type CreateLinkLocationMutation = {
  __typename?: "Mutation";
  createLinkLocation: {
    __typename?: "CreateLinkLocationResponse";
    ok: boolean;
    linkLocation?: { __typename?: "LinkLocation"; id: number } | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type CreatePublisherLinkLocationMutationVariables = Exact<{
  input: CreatePublisherLinkLocationInput;
}>;

export type CreatePublisherLinkLocationMutation = {
  __typename?: "Mutation";
  createPublisherLinkLocation: {
    __typename?: "CreatePublisherLinkLocationResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type SaveLinkLocationCredentialMutationVariables = Exact<{
  input: SaveLinkLocationCredentialInput;
}>;

export type SaveLinkLocationCredentialMutation = {
  __typename?: "Mutation";
  saveLinkLocationCredential: {
    __typename?: "SaveLinkLocationCredentialResponse";
    ok?: boolean | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type AddOrderDrawer_PartnerProductFragment = {
  __typename?: "PartnerProduct";
  id: number;
  name: string;
  createdAt: Date;
  activeAt: Date;
  inactiveAt?: Date | null;
  vendorId?: number | null;
  productId: string;
  checklistDefinitionId?: number | null;
  addedByUser: { __typename?: "User"; id: number; firstName: string; lastName: string };
  product: {
    __typename?: "Product";
    id: string;
    name: string;
    isKeywordRequired: boolean;
    isChecklistRequired: boolean;
    isVendorRequired: boolean;
    isTopicRequired: boolean;
    maxOrderQuantity: number;
    isSystemOrdered: boolean;
  };
  checklistDefinition?: { __typename?: "ChecklistDefinition"; id: number; name: string } | null;
  vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
  partnerProductPrices: {
    __typename?: "PartnerProductPriceConnection";
    nodes: Array<{ __typename?: "PartnerProductPrice"; id: number; price: number; startsAt: Date }>;
  };
};

export type AddOrderDrawer_CustomerKeywordFragment = {
  __typename?: "CustomerKeyword";
  id: number;
  name: string;
  location?: string | null;
};

export type AddOrderDrawerQueryVariables = Exact<{
  customerId: Scalars["Int"];
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
}>;

export type AddOrderDrawerQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    partner: {
      __typename?: "Partner";
      id: number;
      partnerProducts: {
        __typename?: "PartnerProductConnection";
        nodes: Array<{
          __typename?: "PartnerProduct";
          id: number;
          name: string;
          createdAt: Date;
          activeAt: Date;
          inactiveAt?: Date | null;
          vendorId?: number | null;
          productId: string;
          checklistDefinitionId?: number | null;
          addedByUser: { __typename?: "User"; id: number; firstName: string; lastName: string };
          product: {
            __typename?: "Product";
            id: string;
            name: string;
            isKeywordRequired: boolean;
            isChecklistRequired: boolean;
            isVendorRequired: boolean;
            isTopicRequired: boolean;
            maxOrderQuantity: number;
            isSystemOrdered: boolean;
          };
          checklistDefinition?: { __typename?: "ChecklistDefinition"; id: number; name: string } | null;
          vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
          partnerProductPrices: {
            __typename?: "PartnerProductPriceConnection";
            nodes: Array<{ __typename?: "PartnerProductPrice"; id: number; price: number; startsAt: Date }>;
          };
        }>;
      };
    };
    customerKeywords: {
      __typename?: "CustomerKeywordConnection";
      nodes: Array<{ __typename?: "CustomerKeyword"; id: number; name: string; location?: string | null }>;
    };
  } | null;
};

export type AddOrderDrawer_CreateOrderMutationVariables = Exact<{
  input: CreateOrderInput;
}>;

export type AddOrderDrawer_CreateOrderMutation = {
  __typename?: "Mutation";
  createOrder: { __typename?: "CreateOrderResponse"; order?: { __typename?: "Order"; id: number } | null };
};

export type AddPartnerAvailableProductMutationVariables = Exact<{
  input: CreatePartnerAvailableProductInput;
}>;

export type AddPartnerAvailableProductMutation = {
  __typename?: "Mutation";
  createPartnerAvailableProduct: {
    __typename?: "CreatePartnerAvailableProductResponse";
    partnerAvailableProduct: { __typename?: "PartnerAvailableProduct"; id: number };
  };
};

export type CreatePartnerBillingContactMutationVariables = Exact<{
  input: CreatePartnerBillingContactInput;
}>;

export type CreatePartnerBillingContactMutation = {
  __typename?: "Mutation";
  createPartnerBillingContact: {
    __typename?: "CreatePartnerBillingContactResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
    partnerBillingContact?: { __typename?: "PartnerBillingContact"; id: number } | null;
  };
};

export type AddPartnerMutationVariables = Exact<{
  input: CreatePartnerInput;
}>;

export type AddPartnerMutation = {
  __typename?: "Mutation";
  createPartner: { __typename?: "CreatePartnerResponse"; partner?: { __typename?: "Partner"; id: number } | null };
};

export type AddPartnerPackageDrawer_CreatePartnerPackageMutationVariables = Exact<{
  input: CreatePartnerPackageInput;
}>;

export type AddPartnerPackageDrawer_CreatePartnerPackageMutation = {
  __typename?: "Mutation";
  createPartnerPackage: {
    __typename?: "CreatePartnerPackageResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
    partnerPackage?: { __typename?: "PartnerPackage"; id: number } | null;
  };
};

export type CreateChecklistDefinitionMutationVariables = Exact<{
  input: CreateChecklistDefinitionInput;
}>;

export type CreateChecklistDefinitionMutation = {
  __typename?: "Mutation";
  createChecklistDefinition: {
    __typename?: "CreateChecklistDefinitionResponse";
    checklistDefinition?: { __typename?: "ChecklistDefinition"; id: number } | null;
  };
};

export type AddPartnerProductDrawerPartnerProductsQueryVariables = Exact<{
  partnerId: Scalars["Int"];
}>;

export type AddPartnerProductDrawerPartnerProductsQuery = {
  __typename?: "Query";
  partnerProducts: {
    __typename?: "PartnerProductConnection";
    nodes: Array<{ __typename?: "PartnerProduct"; id: number; name: string }>;
  };
};

export type AddPartnerProductMutationVariables = Exact<{
  input: CreatePartnerProductInput;
}>;

export type AddPartnerProductMutation = {
  __typename?: "Mutation";
  createPartnerProduct: {
    __typename?: "CreatePartnerProductResponse";
    partnerProduct?: { __typename?: "PartnerProduct"; id: number } | null;
  };
};

export type AddPartnerUserMutationVariables = Exact<{
  input: CreatePartnerUserInput;
}>;

export type AddPartnerUserMutation = {
  __typename?: "Mutation";
  createPartnerUser: {
    __typename?: "CreatePartnerUserResponse";
    partnerUser?: { __typename?: "PartnerUser"; id: number } | null;
  };
};

export type CreatePartnerPaymentMethodSetupIntentMutationVariables = Exact<{
  input: CreatePartnerPaymentMethodSetupIntentInput;
}>;

export type CreatePartnerPaymentMethodSetupIntentMutation = {
  __typename?: "Mutation";
  createPartnerPaymentMethodSetupIntent: {
    __typename?: "CreatePartnerPaymentMethodSetupIntentResponse";
    ok: boolean;
    clientSecret?: string | null;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type AddPublisherMutationVariables = Exact<{
  input: CreatePublisherInput;
}>;

export type AddPublisherMutation = {
  __typename?: "Mutation";
  createPublisher: {
    __typename?: "CreatePublisherResponse";
    ok: boolean;
    publisher?: { __typename?: "Publisher"; id: number } | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type CreatePublisherUserMutationVariables = Exact<{
  input: CreatePublisherUserInput;
}>;

export type CreatePublisherUserMutation = {
  __typename?: "Mutation";
  createPublisherUser: {
    __typename?: "CreatePublisherUserResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type AddVendorMutationVariables = Exact<{
  input: CreateVendorInput;
}>;

export type AddVendorMutation = {
  __typename?: "Mutation";
  createVendor: { __typename?: "CreateVendorResponse"; vendor?: { __typename?: "Vendor"; id: number } | null };
};

export type AddVendorUserMutationVariables = Exact<{
  input: CreateVendorUserInput;
}>;

export type AddVendorUserMutation = {
  __typename?: "Mutation";
  createVendorUser: {
    __typename?: "CreateVendorUserResponse";
    vendorUser?: { __typename?: "VendorUser"; id: number } | null;
  };
};

export type ProductForm_ProductFragment = {
  __typename?: "Product";
  id: string;
  name: string;
  isChecklistRequired: boolean;
  isVendorRequired: boolean;
  isSystemManaged: boolean;
};

export type ProductForm_PartnerAvailableProductFragment = {
  __typename?: "PartnerAvailableProduct";
  id: number;
  wholesalePrice: number;
  product: {
    __typename?: "Product";
    id: string;
    name: string;
    isChecklistRequired: boolean;
    isVendorRequired: boolean;
    isSystemManaged: boolean;
  };
};

export type ProductsQueryVariables = Exact<{
  partnerId: Scalars["Int"];
}>;

export type ProductsQuery = {
  __typename?: "Query";
  partner?: {
    __typename?: "Partner";
    id: number;
    partnerAvailableProducts: Array<{
      __typename?: "PartnerAvailableProduct";
      id: number;
      wholesalePrice: number;
      product: {
        __typename?: "Product";
        id: string;
        name: string;
        isChecklistRequired: boolean;
        isVendorRequired: boolean;
        isSystemManaged: boolean;
      };
    }>;
  } | null;
};

export type ProductTaskTypesForProductSelectorQueryVariables = Exact<{
  taskTypeIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type ProductTaskTypesForProductSelectorQuery = {
  __typename?: "Query";
  productTaskTypes: {
    __typename?: "ProductTaskTypeConnection";
    nodes: Array<{ __typename?: "ProductTaskType"; id: string; product: { __typename?: "Product"; id: string } }>;
  };
};

export type CampaignCategoriesForSelectorV2QueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  campaignCategoryIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type CampaignCategoriesForSelectorV2Query = {
  __typename?: "Query";
  campaignCategories: {
    __typename?: "CampaignCategoryConnection";
    nodes: Array<{ __typename?: "CampaignCategory"; id: string; name: string }>;
  };
};

export type CampaignCategoriesForSelectorQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  campaignCategoryIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type CampaignCategoriesForSelectorQuery = {
  __typename?: "Query";
  campaignCategories: {
    __typename?: "CampaignCategoryConnection";
    nodes: Array<{ __typename?: "CampaignCategory"; id: string; name: string }>;
  };
};

export type CustomerCampaignInvoiceDetailFragment = {
  __typename?: "CustomerCampaignInvoice";
  id: number;
  customerId: number;
  invoiceDate: Date;
  status: { __typename?: "CustomerCampaignInvoiceStatus"; id: string; name: string; description: string };
  invoiceData: { __typename?: "CustomerCampaignInvoiceData"; totalChargesAmount: number; totalDue: number };
  customer: { __typename?: "Customer"; id: number; name: string };
};

export type CampaignInvoicesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CustomerCampaignInvoiceSort>;
  search?: InputMaybe<Scalars["String"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  customerIds: Array<Scalars["Int"]> | Scalars["Int"];
}>;

export type CampaignInvoicesQuery = {
  __typename?: "Query";
  customerCampaignInvoices: {
    __typename?: "CustomerCampaignInvoiceConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "CustomerCampaignInvoice";
      id: number;
      customerId: number;
      invoiceDate: Date;
      status: { __typename?: "CustomerCampaignInvoiceStatus"; id: string; name: string; description: string };
      invoiceData: { __typename?: "CustomerCampaignInvoiceData"; totalChargesAmount: number; totalDue: number };
      customer: { __typename?: "Customer"; id: number; name: string };
    }>;
  };
};

export type RecordCustomerCampaignInvoiceManualPaymentMutationVariables = Exact<{
  input: RecordCustomerCampaignInvoicePaymentInput;
}>;

export type RecordCustomerCampaignInvoiceManualPaymentMutation = {
  __typename?: "Mutation";
  recordCustomerCampaignInvoicePayment: {
    __typename?: "RecordCustomerCampaignInvoicePaymentResponse";
    wasPaymentSuccessful?: boolean | null;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type CategoriesForSelectorQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
}>;

export type CategoriesForSelectorQuery = {
  __typename?: "Query";
  categories: {
    __typename?: "CategoryConnection";
    nodes: Array<{ __typename?: "Category"; id: number; name: string }>;
  };
};

export type ChecklistDefinitionsListFragment = {
  __typename?: "ChecklistDefinitionConnection";
  totalCount: number;
  nodes: Array<{ __typename?: "ChecklistDefinition"; id: number; name: string }>;
};

export type ChecklistDefinitionsForProductQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type ChecklistDefinitionsForProductQuery = {
  __typename?: "Query";
  checklistDefinitions: {
    __typename?: "ChecklistDefinitionConnection";
    totalCount: number;
    nodes: Array<{ __typename?: "ChecklistDefinition"; id: number; name: string }>;
  };
};

export type CreateClientStatusChangeMutationVariables = Exact<{
  input: CreateCustomerStatusChangeInput;
}>;

export type CreateClientStatusChangeMutation = {
  __typename?: "Mutation";
  createCustomerStatusChange: {
    __typename?: "CreateCustomerStatusChangeResponse";
    customerStatusChange: { __typename?: "CustomerStatusChange"; id: number };
  };
};

export type UpdateClientStatusChangeMutationVariables = Exact<{
  input: UpdateCustomerStatusChangeInput;
}>;

export type UpdateClientStatusChangeMutation = {
  __typename?: "Mutation";
  updateCustomerStatusChange: {
    __typename?: "UpdateCustomerStatusChangeResponse";
    customerStatusChange: { __typename?: "CustomerStatusChange"; id: number };
  };
};

export type DeleteClientStatusChangeMutationVariables = Exact<{
  input: DeleteCustomerStatusChangeInput;
}>;

export type DeleteClientStatusChangeMutation = {
  __typename?: "Mutation";
  deleteCustomerStatusChange: { __typename?: "DeleteCustomerStatusChangeResponse"; isDeleted: boolean };
};

export type ClientStatusQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type ClientStatusQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    status: { __typename?: "CustomerStatus"; id: string; name: string };
    partner: { __typename?: "Partner"; id: number; isBillingEnforced: boolean; isUpfrontPaymentRequired: boolean };
    customerStatusChanges: {
      __typename?: "CustomerStatusChangeConnection";
      nodes: Array<{
        __typename?: "CustomerStatusChange";
        id: number;
        reason?: string | null;
        effectiveAt: string;
        status: { __typename?: "CustomerStatus"; id: string; name: string };
      }>;
    };
  } | null;
};

export type ConversationDetailsQueryVariables = Exact<{
  conversationId: Scalars["String"];
}>;

export type ConversationDetailsQuery = {
  __typename?: "Query";
  customerConversation?: {
    __typename?: "CustomerConversation";
    id: string;
    subject: string;
    createdAt: Date;
    helpRequestedAt?: Date | null;
    customer: { __typename?: "Customer"; id: number; name: string };
    customerMessages: {
      __typename?: "CustomerMessageConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "CustomerMessage";
        id: number;
        createdAt: Date;
        content: string;
        isFromCustomer: boolean;
        user: { __typename?: "User"; id: number; fullName: string };
      }>;
    };
  } | null;
};

export type ConversationDetails_CreateMessageMutationVariables = Exact<{
  input: CreateCustomerMessageInput;
}>;

export type ConversationDetails_CreateMessageMutation = {
  __typename?: "Mutation";
  createCustomerMessage: {
    __typename?: "CreateCustomerMessageResponse";
    customerMessage: { __typename?: "CustomerMessage"; id: number };
  };
};

export type ConversationDetails_UpdateConversationMutationVariables = Exact<{
  input: UpdateCustomerConversationInput;
}>;

export type ConversationDetails_UpdateConversationMutation = {
  __typename?: "Mutation";
  updateCustomerConversation: {
    __typename?: "UpdateCustomerConversationResponse";
    customerConversation: { __typename?: "CustomerConversation"; id: string; helpRequestedAt?: Date | null };
  };
};

export type CustomerCampaignSelector_CustomerCampaignFragment = {
  __typename?: "CustomerCampaign";
  id: number;
  name: string;
  startsAt: Date;
  endsAt: Date;
  amount: number;
  isCurrent: boolean;
};

export type CustomerCampaignSelectorQueryVariables = Exact<{
  customerId: Scalars["Int"];
}>;

export type CustomerCampaignSelectorQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    customerCampaigns: {
      __typename?: "CustomerCampaignConnection";
      nodes: Array<{
        __typename?: "CustomerCampaign";
        id: number;
        name: string;
        startsAt: Date;
        endsAt: Date;
        amount: number;
        isCurrent: boolean;
      }>;
    };
  } | null;
};

export type CustomerKeywordSelector_KeywordFragment = {
  __typename?: "CustomerKeyword";
  id: number;
  displayName: string;
  isActive: boolean;
  isPrimary: boolean;
};

export type CustomerKeywordSelectorQueryVariables = Exact<{
  customerId: Scalars["Int"];
  isPrimary?: InputMaybe<Scalars["Boolean"]>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type CustomerKeywordSelectorQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    customerKeywords: {
      __typename?: "CustomerKeywordConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "CustomerKeyword";
        id: number;
        displayName: string;
        isActive: boolean;
        isPrimary: boolean;
      }>;
    };
  } | null;
};

export type CreateCustomerNoteMutationVariables = Exact<{
  input: CreateCustomerNoteInput;
}>;

export type CreateCustomerNoteMutation = {
  __typename?: "Mutation";
  createCustomerNote: { __typename?: "CreateNoteResponse"; note?: { __typename?: "Note"; id: number } | null };
};

export type UpdateCustomerNoteMutationVariables = Exact<{
  input: UpdateCustomerNoteInput;
}>;

export type UpdateCustomerNoteMutation = {
  __typename?: "Mutation";
  updateCustomerNote: { __typename?: "UpdateCustomerNoteResponse"; note?: { __typename?: "Note"; id: number } | null };
};

export type CustomerNotes_NoteFragment = {
  __typename?: "Note";
  id: number;
  content: string;
  createdAt: Date;
  user: { __typename?: "User"; id: number; fullName: string };
};

export type CustomerNotesQueryVariables = Exact<{
  customerId: Scalars["Int"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type CustomerNotesQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    notes: {
      __typename?: "NoteConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "Note";
        id: number;
        content: string;
        createdAt: Date;
        user: { __typename?: "User"; id: number; fullName: string };
      }>;
    };
  } | null;
};

export type CustomersForSelectorQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  partnerIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type CustomersForSelectorQuery = {
  __typename?: "Query";
  customers: { __typename?: "CustomerConnection"; nodes: Array<{ __typename?: "Customer"; id: number; name: string }> };
};

export type DomainStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type DomainStatusesQuery = {
  __typename?: "Query";
  domainStatuses: Array<{ __typename?: "DomainStatus"; id: string; name: string }>;
};

export type AmTaskQueryVariables = Exact<{
  amTaskId: Scalars["Int"];
}>;

export type AmTaskQuery = {
  __typename?: "Query";
  amTask: { __typename?: "AmTask"; id: number; taskType: string; info?: string | null; completedAt: Date };
};

export type UpdateAmTaskMutationVariables = Exact<{
  input: UpdateAmTaskInput;
}>;

export type UpdateAmTaskMutation = {
  __typename?: "Mutation";
  updateAmTask: {
    __typename?: "UpdateAmTaskResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type UpdateCategoryMutationVariables = Exact<{
  input: UpdateCategoryInput;
}>;

export type UpdateCategoryMutation = {
  __typename?: "Mutation";
  updateCategory: { __typename?: "UpdateCategoryResponse"; category: { __typename?: "Category"; id: number } };
};

export type EditCategoryDrawerQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type EditCategoryDrawerQuery = {
  __typename?: "Query";
  category?: { __typename?: "Category"; id: number; name: string } | null;
};

export type UpdateClientCampaignMutationVariables = Exact<{
  input: UpdateCustomerCampaignInput;
}>;

export type UpdateClientCampaignMutation = {
  __typename?: "Mutation";
  updateCustomerCampaign: {
    __typename?: "UpdateCustomerCampaignResponse";
    customerCampaign: { __typename?: "CustomerCampaign"; id: number };
  };
};

export type EditOrderDrawerQueryVariables = Exact<{
  orderId: Scalars["Int"];
}>;

export type EditOrderDrawerQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    id: number;
    createdAt: Date;
    quantity: number;
    topic?: string | null;
    resultUrl?: string | null;
    resultUrlDomainAuthority?: number | null;
    startDate: Date;
    amount: number;
    instructions?: string | null;
    customerCampaignId: number;
    status: { __typename?: "OrderStatus"; id: string; name: string };
    orderIssues: Array<{ __typename?: "OrderIssue"; title: string; message: string }>;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      name: string;
      product: {
        __typename?: "Product";
        id: string;
        name: string;
        isKeywordRequired: boolean;
        isTopicRequired: boolean;
        maxOrderQuantity: number;
      };
    };
    customer: { __typename?: "Customer"; id: number; name: string };
  } | null;
};

export type EditOrderDrawer_UpdateOrderMutationVariables = Exact<{
  input: UpdateOrderInput;
}>;

export type EditOrderDrawer_UpdateOrderMutation = {
  __typename?: "Mutation";
  updateOrder: { __typename?: "UpdateOrderResponse"; order: { __typename?: "Order"; id: number } };
};

export type CreatePartnerProductWholesalePriceMutationVariables = Exact<{
  input: CreatePartnerProductWholesalePriceInput;
}>;

export type CreatePartnerProductWholesalePriceMutation = {
  __typename?: "Mutation";
  createPartnerProductWholesalePrice: {
    __typename?: "CreatePartnerProductWholesalePriceResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
    partnerProductWholesalePrice?: { __typename?: "PartnerProductWholesalePrice"; id: number } | null;
  };
};

export type DeletePartnerProductWholesalePriceMutationVariables = Exact<{
  input: DeletePartnerProductWholesalePriceInput;
}>;

export type DeletePartnerProductWholesalePriceMutation = {
  __typename?: "Mutation";
  deletePartnerProductWholesalePrice: {
    __typename?: "DeletePartnerProductWholesalePriceResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type EditPartnerAvailableProductQueryVariables = Exact<{
  partnerAvailableProductId: Scalars["Int"];
}>;

export type EditPartnerAvailableProductQuery = {
  __typename?: "Query";
  partnerAvailableProduct?: {
    __typename?: "PartnerAvailableProduct";
    id: number;
    partner: { __typename?: "Partner"; id: number; name: string };
    product: { __typename?: "Product"; id: string; name: string };
    partnerProductWholesalePrices: {
      __typename?: "PartnerProductWholesalePriceConnection";
      nodes: Array<{
        __typename?: "PartnerProductWholesalePrice";
        id: number;
        wholesaleAmount: number;
        effectiveAt: Date;
        isCurrent: boolean;
        canDelete: boolean;
      }>;
    };
  } | null;
};

export type UpdatePartnerProductWholesalePriceMutationVariables = Exact<{
  input: UpdatePartnerProductWholesalePriceInput;
}>;

export type UpdatePartnerProductWholesalePriceMutation = {
  __typename?: "Mutation";
  updatePartnerProductWholesalePrice: {
    __typename?: "UpdatePartnerProductWholesalePriceResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
    partnerProductWholesalePrice?: { __typename?: "PartnerProductWholesalePrice"; id: number } | null;
  };
};

export type UpdatePartnerMutationVariables = Exact<{
  input: UpdatePartnerInput;
}>;

export type UpdatePartnerMutation = {
  __typename?: "Mutation";
  updatePartner: { __typename?: "UpdatePartnerResponse"; partner?: { __typename?: "Partner"; id: number } | null };
};

export type EditPartnerDrawerQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type EditPartnerDrawerQuery = {
  __typename?: "Query";
  partner?: { __typename?: "Partner"; id: number; name: string } | null;
};

export type UpdatePartnerProductMutationVariables = Exact<{
  input: UpdatePartnerProductInput;
}>;

export type UpdatePartnerProductMutation = {
  __typename?: "Mutation";
  updatePartnerProduct?: {
    __typename?: "UpdatePartnerProductResponse";
    partnerProduct?: { __typename?: "PartnerProduct"; id: number } | null;
  } | null;
};

export type EditPartnerProductQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type EditPartnerProductQuery = {
  __typename?: "Query";
  partnerProduct?: {
    __typename?: "PartnerProduct";
    id: number;
    name: string;
    activeAt: Date;
    inactiveAt?: Date | null;
    partnerId: number;
    vendorId?: number | null;
    productId: string;
    checklistDefinitionId?: number | null;
    wholesalePrice?: number | null;
    currentPartnerProductPrice?: { __typename?: "PartnerProductPrice"; id: number; price: number } | null;
    product: {
      __typename?: "Product";
      id: string;
      name: string;
      isChecklistRequired: boolean;
      isVendorRequired: boolean;
    };
  } | null;
};

export type UpdateVendorMutationVariables = Exact<{
  input: UpdateVendorInput;
}>;

export type UpdateVendorMutation = {
  __typename?: "Mutation";
  updateVendor: { __typename?: "UpdateVendorResponse"; vendor?: { __typename?: "Vendor"; id: number } | null };
};

export type EditVendorDrawerQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type EditVendorDrawerQuery = {
  __typename?: "Query";
  vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
};

export type EmailMessageModalQueryVariables = Exact<{
  emailMessageId: Scalars["Int"];
}>;

export type EmailMessageModalQuery = {
  __typename?: "Query";
  emailMessage?: {
    __typename?: "EmailMessage";
    id: number;
    createdAt: Date;
    sentAt?: Date | null;
    toName: string;
    toAddress: string;
    fromName: string;
    fromAddress: string;
    subject: string;
    content: string;
  } | null;
};

export type ForceDeleteOrderModal_DeleteOrderMutationVariables = Exact<{
  input: DeleteOrderInput;
}>;

export type ForceDeleteOrderModal_DeleteOrderMutation = {
  __typename?: "Mutation";
  deleteOrder: { __typename?: "DeleteOrderResponse"; isDeleted: boolean };
};

export type LinkLocationStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type LinkLocationStatusesQuery = {
  __typename?: "Query";
  linkLocationStatuses: Array<{ __typename?: "LinkLocationStatus"; id: string; name: string }>;
};

export type LinkLocationTypesQueryVariables = Exact<{ [key: string]: never }>;

export type LinkLocationTypesQuery = {
  __typename?: "Query";
  linkLocationTypes: Array<{ __typename?: "LinkLocationType"; id: string; name: string }>;
};

export type LinkLocationsSelectorQueryVariables = Exact<{
  typeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type LinkLocationsSelectorQuery = {
  __typename?: "Query";
  linkLocations: {
    __typename?: "LinkLocationConnection";
    nodes: Array<{ __typename?: "LinkLocation"; id: number; domain: string }>;
  };
};

export type LinkLocationsUsedQueryVariables = Exact<{
  customerId: Scalars["Int"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  typeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  sort?: InputMaybe<LinkLocationsSort>;
  pendingLimit?: InputMaybe<Scalars["Int"]>;
  pendingOffset?: InputMaybe<Scalars["Int"]>;
  pendingSearch?: InputMaybe<Scalars["String"]>;
  pendingTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  pendingSort?: InputMaybe<LinkLocationsSort>;
  rejectedLimit?: InputMaybe<Scalars["Int"]>;
  rejectedOffset?: InputMaybe<Scalars["Int"]>;
  rejectedSearch?: InputMaybe<Scalars["String"]>;
  rejectedTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  rejectedSort?: InputMaybe<LinkLocationsSort>;
}>;

export type LinkLocationsUsedQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    linkLocationsUsed: {
      __typename?: "LinkLocationConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "LinkLocation";
        id: number;
        domain: string;
        domainAuthority: number;
        spam?: number | null;
        authorityScore?: number | null;
        internalQuality?: number | null;
        monthlyTraffic?: number | null;
        cost?: number | null;
        lastMetricsUpdatedAt?: Date | null;
        publisherLinkLocation?: {
          __typename?: "PublisherLinkLocation";
          id: number;
          publisher?: { __typename?: "Publisher"; id: number; name: string } | null;
        } | null;
        status: { __typename?: "LinkLocationStatus"; id: string; name: string };
        type: { __typename?: "LinkLocationType"; id: string; name: string };
        linkLocationCategories: Array<{
          __typename?: "LinkLocationCategory";
          id: number;
          category: { __typename?: "Category"; id: number; name: string };
        }>;
      }>;
    };
    linkLocationsPending: {
      __typename?: "LinkLocationConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "LinkLocation";
        id: number;
        domain: string;
        domainAuthority: number;
        spam?: number | null;
        authorityScore?: number | null;
        internalQuality?: number | null;
        monthlyTraffic?: number | null;
        cost?: number | null;
        lastMetricsUpdatedAt?: Date | null;
        publisherLinkLocation?: {
          __typename?: "PublisherLinkLocation";
          id: number;
          publisher?: { __typename?: "Publisher"; id: number; name: string } | null;
        } | null;
        status: { __typename?: "LinkLocationStatus"; id: string; name: string };
        type: { __typename?: "LinkLocationType"; id: string; name: string };
        linkLocationCategories: Array<{
          __typename?: "LinkLocationCategory";
          id: number;
          category: { __typename?: "Category"; id: number; name: string };
        }>;
      }>;
    };
    linkLocationsRejected: {
      __typename?: "LinkLocationConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "LinkLocation";
        id: number;
        domain: string;
        domainAuthority: number;
        spam?: number | null;
        authorityScore?: number | null;
        internalQuality?: number | null;
        monthlyTraffic?: number | null;
        cost?: number | null;
        lastMetricsUpdatedAt?: Date | null;
        publisherLinkLocation?: {
          __typename?: "PublisherLinkLocation";
          id: number;
          publisher?: { __typename?: "Publisher"; id: number; name: string } | null;
        } | null;
        status: { __typename?: "LinkLocationStatus"; id: string; name: string };
        type: { __typename?: "LinkLocationType"; id: string; name: string };
        linkLocationCategories: Array<{
          __typename?: "LinkLocationCategory";
          id: number;
          category: { __typename?: "Category"; id: number; name: string };
        }>;
      }>;
    };
  } | null;
};

export type LinkLocation_LinkLocationConnectionFragment = {
  __typename?: "LinkLocationConnection";
  totalCount: number;
  nodes: Array<{
    __typename?: "LinkLocation";
    id: number;
    domain: string;
    domainAuthority: number;
    spam?: number | null;
    authorityScore?: number | null;
    internalQuality?: number | null;
    monthlyTraffic?: number | null;
    cost?: number | null;
    lastMetricsUpdatedAt?: Date | null;
    publisherLinkLocation?: {
      __typename?: "PublisherLinkLocation";
      id: number;
      publisher?: { __typename?: "Publisher"; id: number; name: string } | null;
    } | null;
    status: { __typename?: "LinkLocationStatus"; id: string; name: string };
    type: { __typename?: "LinkLocationType"; id: string; name: string };
    linkLocationCategories: Array<{
      __typename?: "LinkLocationCategory";
      id: number;
      category: { __typename?: "Category"; id: number; name: string };
    }>;
  }>;
};

export type LinkLocationsAvailableQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  typeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  minDa?: InputMaybe<Scalars["Int"]>;
  minTraffic?: InputMaybe<Scalars["Int"]>;
  excludeCustomerId?: InputMaybe<Scalars["Int"]>;
  categoryId?: InputMaybe<Scalars["Int"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<LinkLocationsSort>;
  secondSort?: InputMaybe<LinkLocationsSort>;
}>;

export type LinkLocationsAvailableQuery = {
  __typename?: "Query";
  linkLocations: {
    __typename?: "LinkLocationConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "LinkLocation";
      id: number;
      domain: string;
      domainAuthority: number;
      spam?: number | null;
      authorityScore?: number | null;
      internalQuality?: number | null;
      monthlyTraffic?: number | null;
      cost?: number | null;
      lastMetricsUpdatedAt?: Date | null;
      publisherLinkLocation?: {
        __typename?: "PublisherLinkLocation";
        id: number;
        publisher?: { __typename?: "Publisher"; id: number; name: string } | null;
      } | null;
      status: { __typename?: "LinkLocationStatus"; id: string; name: string };
      type: { __typename?: "LinkLocationType"; id: string; name: string };
      linkLocationCategories: Array<{
        __typename?: "LinkLocationCategory";
        id: number;
        category: { __typename?: "Category"; id: number; name: string };
      }>;
    }>;
  };
};

export type UpdateLinkLocationMetricsMutationVariables = Exact<{
  input: UpdateLinkLocationMetricsInput;
}>;

export type UpdateLinkLocationMetricsMutation = {
  __typename?: "Mutation";
  updateLinkLocationMetrics: {
    __typename?: "UpdateLinkLocationMetricsResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type CreatePartnerProductPriceMutationVariables = Exact<{
  input: CreatePartnerProductPriceInput;
}>;

export type CreatePartnerProductPriceMutation = {
  __typename?: "Mutation";
  createPartnerProductPrice: {
    __typename?: "CreatePartnerProductPriceResponse";
    partnerProductPrice: { __typename?: "PartnerProductPrice"; id: number };
  };
};

export type DeletePartnerProductPriceMutationVariables = Exact<{
  input: DeletePartnerProductPriceInput;
}>;

export type DeletePartnerProductPriceMutation = {
  __typename?: "Mutation";
  deletePartnerProductPrice: { __typename?: "DeletePartnerProductPriceResponse"; isDeleted: boolean };
};

export type PartnerProductDetailPriceFragment = {
  __typename?: "PartnerProductPrice";
  id: number;
  startsAt: Date;
  price: number;
  isCurrent: boolean;
};

export type PartnerProductDetailPricesQueryVariables = Exact<{
  partnerProductId: Scalars["Int"];
}>;

export type PartnerProductDetailPricesQuery = {
  __typename?: "Query";
  partnerProduct?: {
    __typename?: "PartnerProduct";
    id: number;
    name: string;
    wholesalePrice?: number | null;
    partnerProductPrices: {
      __typename?: "PartnerProductPriceConnection";
      nodes: Array<{
        __typename?: "PartnerProductPrice";
        id: number;
        startsAt: Date;
        price: number;
        isCurrent: boolean;
      }>;
    };
  } | null;
};

export type UpdatePartnerProductPriceMutationVariables = Exact<{
  input: UpdatePartnerProductPriceInput;
}>;

export type UpdatePartnerProductPriceMutation = {
  __typename?: "Mutation";
  updatePartnerProductPrice: {
    __typename?: "UpdatePartnerProductPriceResponse";
    partnerProductPrice: { __typename?: "PartnerProductPrice"; id: number };
  };
};

export type MapPublisherLinkLocationMutationVariables = Exact<{
  input: CreatePublisherLinkLocationInput;
}>;

export type MapPublisherLinkLocationMutation = {
  __typename?: "Mutation";
  createPublisherLinkLocation: {
    __typename?: "CreatePublisherLinkLocationResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type OrderStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type OrderStatusesQuery = {
  __typename?: "Query";
  orderStatuses: Array<{ __typename?: "OrderStatus"; id: string; name: string }>;
};

export type PartnerPackageOptionFragment = {
  __typename?: "PartnerPackage";
  id: number;
  name: string;
  monthlySpend: number;
  months: number;
};

export type PartnerPackagesForSelectorQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId: Scalars["Int"];
}>;

export type PartnerPackagesForSelectorQuery = {
  __typename?: "Query";
  partnerPackages: {
    __typename?: "PartnerPackageConnection";
    nodes: Array<{ __typename?: "PartnerPackage"; id: number; name: string; monthlySpend: number; months: number }>;
  };
};

export type PartnerPaymentMethodSelectorQueryVariables = Exact<{
  partnerId: Scalars["Int"];
}>;

export type PartnerPaymentMethodSelectorQuery = {
  __typename?: "Query";
  partner?: {
    __typename?: "Partner";
    id: number;
    partnerPaymentMethods: {
      __typename?: "PartnerPaymentMethodConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "PartnerPaymentMethod";
        id: number;
        partnerPaymentMethodCard?: {
          __typename?: "PartnerPaymentMethodCard";
          brand: string;
          brandDisplayName: string;
          expirationMonth: number;
          expirationYear: number;
          last4Digits: string;
        } | null;
      }>;
    };
  } | null;
};

export type PartnerProductSelectorQueryVariables = Exact<{
  partnerId: Scalars["Int"];
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
}>;

export type PartnerProductSelectorQuery = {
  __typename?: "Query";
  partnerProducts: {
    __typename?: "PartnerProductConnection";
    nodes: Array<{
      __typename?: "PartnerProduct";
      id: number;
      name: string;
      product: { __typename?: "Product"; id: string; name: string; isSystemOrdered: boolean };
    }>;
  };
};

export type PartnerSelectorV2QueryVariables = Exact<{ [key: string]: never }>;

export type PartnerSelectorV2Query = {
  __typename?: "Query";
  partners: { __typename?: "PartnerConnection"; nodes: Array<{ __typename?: "Partner"; id: number; name: string }> };
};

export type PartnerSelectorQueryVariables = Exact<{ [key: string]: never }>;

export type PartnerSelectorQuery = {
  __typename?: "Query";
  partners: { __typename?: "PartnerConnection"; nodes: Array<{ __typename?: "Partner"; id: number; name: string }> };
};

export type PartnerUserSelectorQueryVariables = Exact<{
  partnerId: Scalars["Int"];
}>;

export type PartnerUserSelectorQuery = {
  __typename?: "Query";
  partner?: {
    __typename?: "Partner";
    id: number;
    partnerUsers: {
      __typename?: "PartnerUserConnection";
      nodes: Array<{
        __typename?: "PartnerUser";
        id: number;
        isAdmin: boolean;
        user: { __typename?: "User"; id: number; firstName: string; lastName: string };
      }>;
    };
  } | null;
};

export type ProductSelectorQueryVariables = Exact<{ [key: string]: never }>;

export type ProductSelectorQuery = {
  __typename?: "Query";
  products: { __typename?: "ProductConnection"; nodes: Array<{ __typename?: "Product"; id: string; name: string }> };
};

export type PublisherSelectorV2QueryVariables = Exact<{ [key: string]: never }>;

export type PublisherSelectorV2Query = {
  __typename?: "Query";
  publishers: {
    __typename?: "PublisherConnection";
    nodes: Array<{ __typename?: "Publisher"; id: number; name: string }>;
  };
};

export type PublisherSelectorQueryVariables = Exact<{ [key: string]: never }>;

export type PublisherSelectorQuery = {
  __typename?: "Query";
  publishers: {
    __typename?: "PublisherConnection";
    nodes: Array<{ __typename?: "Publisher"; id: number; name: string }>;
  };
};

export type Comment_ReviewTaskFragment = {
  __typename?: "Task";
  id: number;
  createdAt: Date;
  completedAt?: Date | null;
  taskTypeId: string;
  reviewComment?: string | null;
  reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
};

export type SitePostStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type SitePostStatusesQuery = {
  __typename?: "Query";
  sitePostStatuses: Array<{ __typename?: "SitePostStatus"; id: string; name: string }>;
};

export type SitesForSelectorQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
}>;

export type SitesForSelectorQuery = {
  __typename?: "Query";
  sites: { __typename?: "SiteConnection"; nodes: Array<{ __typename?: "Site"; id: string }> };
};

export type CustomerDetails_CreateCustomerUserSsoCodeMutationVariables = Exact<{
  input: CreateCustomerUserSsoCodeInput;
}>;

export type CustomerDetails_CreateCustomerUserSsoCodeMutation = {
  __typename?: "Mutation";
  createCustomerUserSsoCode: {
    __typename?: "CreateCustomerUserSsoCodeResponse";
    customerUserSsoCode: { __typename?: "CustomerUserSsoCode"; id: number };
  };
};

export type CustomerDetails_CustomerUserSsoCodesQueryVariables = Exact<{
  customerUserId: Scalars["Int"];
}>;

export type CustomerDetails_CustomerUserSsoCodesQuery = {
  __typename?: "Query";
  customerUser?: {
    __typename?: "CustomerUser";
    id: number;
    customerUserSsoCodes: {
      __typename?: "CustomerUserSsoCodeConnection";
      nodes: Array<{
        __typename?: "CustomerUserSsoCode";
        id: number;
        code: string;
        expiresAt?: Date | null;
        createdAt: Date;
        url: string;
      }>;
    };
  } | null;
};

export type TaskTypeSelectorQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type TaskTypeSelectorQuery = {
  __typename?: "Query";
  taskTypes: {
    __typename?: "TaskTypeConnection";
    nodes: Array<{ __typename?: "TaskType"; id: string; name: string; isFulfilledByCustomer: boolean }>;
  };
};

export type ProductTaskTypesForTaskTypeSelectorQueryVariables = Exact<{
  productIds: Array<Scalars["String"]> | Scalars["String"];
}>;

export type ProductTaskTypesForTaskTypeSelectorQuery = {
  __typename?: "Query";
  productTaskTypes: {
    __typename?: "ProductTaskTypeConnection";
    nodes: Array<{
      __typename?: "ProductTaskType";
      id: string;
      taskType: { __typename?: "TaskType"; id: string; name: string; isFulfilledByCustomer: boolean };
    }>;
  };
};

export type TaskTypesForSelectorQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  ids?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type TaskTypesForSelectorQuery = {
  __typename?: "Query";
  taskTypes: {
    __typename?: "TaskTypeConnection";
    nodes: Array<{ __typename?: "TaskType"; id: string; name: string; isFulfilledByCustomer: boolean }>;
  };
};

export type TaskWorkspaceBusinessListing_OrderFragment = {
  __typename?: "Order";
  id: number;
  startDate: Date;
  resultUrl?: string | null;
  resultUrlDomainAuthority?: number | null;
  partnerProduct: {
    __typename?: "PartnerProduct";
    id: number;
    name: string;
    product: { __typename?: "Product"; id: string; name: string };
  };
};

export type TaskWorkspaceBusinessListing_TaskFragment = {
  __typename?: "Task";
  id: number;
  keyword?: string | null;
  customer: {
    __typename?: "Customer";
    id: number;
    name: string;
    website: string;
    instructions?: string | null;
    customerBusinessListingSpec?: {
      __typename?: "CustomerBusinessListingSpec";
      id: number;
      hasPhysicalStorefront: boolean;
      email?: string | null;
      address: string;
      city: string;
      stateOrProvince: string;
      zipPostalCode: string;
      country: string;
      phoneNumber: string;
      contactName?: string | null;
    } | null;
    customerQuestionnaire?: {
      __typename?: "CustomerQuestionnaire";
      id: number;
      businessDescription?: string | null;
    } | null;
    category?: { __typename?: "Category"; id: number; name: string } | null;
  };
  order: {
    __typename?: "Order";
    id: number;
    startDate: Date;
    resultUrl?: string | null;
    resultUrlDomainAuthority?: number | null;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      name: string;
      product: { __typename?: "Product"; id: string; name: string };
    };
  };
  vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
};

export type TaskWorkspaceBusinessListingQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type TaskWorkspaceBusinessListingQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    keyword?: string | null;
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      instructions?: string | null;
      customerBusinessListingSpec?: {
        __typename?: "CustomerBusinessListingSpec";
        id: number;
        hasPhysicalStorefront: boolean;
        email?: string | null;
        address: string;
        city: string;
        stateOrProvince: string;
        zipPostalCode: string;
        country: string;
        phoneNumber: string;
        contactName?: string | null;
      } | null;
      customerQuestionnaire?: {
        __typename?: "CustomerQuestionnaire";
        id: number;
        businessDescription?: string | null;
      } | null;
      category?: { __typename?: "Category"; id: number; name: string } | null;
    };
    order: {
      __typename?: "Order";
      id: number;
      startDate: Date;
      resultUrl?: string | null;
      resultUrlDomainAuthority?: number | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        name: string;
        product: { __typename?: "Product"; id: string; name: string };
      };
    };
    vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
  } | null;
};

export type TaskWorkspaceBusinessListing_UpdateOrderMutationVariables = Exact<{
  input: UpdateOrderInput;
}>;

export type TaskWorkspaceBusinessListing_UpdateOrderMutation = {
  __typename?: "Mutation";
  updateOrder: {
    __typename?: "UpdateOrderResponse";
    order: { __typename?: "Order"; id: number; resultUrl?: string | null; resultUrlDomainAuthority?: number | null };
  };
};

export type ChecklistTaskWorkspace_ChecklistItemFragment = {
  __typename?: "ChecklistItem";
  id: number;
  name: string;
  completedAt?: Date | null;
  order: number;
};

export type ChecklistTaskWorkspace_ChecklistFragment = {
  __typename?: "Checklist";
  id: number;
  name: string;
  checklistItems: Array<{
    __typename?: "ChecklistItem";
    id: number;
    name: string;
    completedAt?: Date | null;
    order: number;
  }>;
};

export type ChecklistTaskWorkspace_OrderFragment = {
  __typename?: "Order";
  id: number;
  startDate: Date;
  resultUrl?: string | null;
  resultUrlDomainAuthority?: number | null;
  instructions?: string | null;
  partnerProduct: { __typename?: "PartnerProduct"; id: number; name: string };
};

export type ChecklistTaskWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  keyword?: string | null;
  customer: { __typename?: "Customer"; id: number; name: string; instructions?: string | null };
  checklist?: {
    __typename?: "Checklist";
    id: number;
    name: string;
    checklistItems: Array<{
      __typename?: "ChecklistItem";
      id: number;
      name: string;
      completedAt?: Date | null;
      order: number;
    }>;
  } | null;
  order: {
    __typename?: "Order";
    id: number;
    startDate: Date;
    resultUrl?: string | null;
    resultUrlDomainAuthority?: number | null;
    instructions?: string | null;
    partnerProduct: { __typename?: "PartnerProduct"; id: number; name: string };
  };
  vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
};

export type ChecklistTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ChecklistTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    keyword?: string | null;
    customer: { __typename?: "Customer"; id: number; name: string; instructions?: string | null };
    checklist?: {
      __typename?: "Checklist";
      id: number;
      name: string;
      checklistItems: Array<{
        __typename?: "ChecklistItem";
        id: number;
        name: string;
        completedAt?: Date | null;
        order: number;
      }>;
    } | null;
    order: {
      __typename?: "Order";
      id: number;
      startDate: Date;
      resultUrl?: string | null;
      resultUrlDomainAuthority?: number | null;
      instructions?: string | null;
      partnerProduct: { __typename?: "PartnerProduct"; id: number; name: string };
    };
    vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
  } | null;
};

export type UpdateChecklistItemMutationVariables = Exact<{
  input: UpdateChecklistItemInput;
}>;

export type UpdateChecklistItemMutation = {
  __typename?: "Mutation";
  updateChecklistItem: {
    __typename?: "UpdateChecklistItemResponse";
    checklistItem: { __typename?: "ChecklistItem"; id: number; name: string; completedAt?: Date | null; order: number };
  };
};

export type ChecklistTaskWorkspace_UpdateOrderMutationVariables = Exact<{
  input: UpdateOrderInput;
}>;

export type ChecklistTaskWorkspace_UpdateOrderMutation = {
  __typename?: "Mutation";
  updateOrder: {
    __typename?: "UpdateOrderResponse";
    order: { __typename?: "Order"; id: number; resultUrl?: string | null; resultUrlDomainAuthority?: number | null };
  };
};

export type CreateOrderExternalContentSpecMutationVariables = Exact<{
  input: CreateOrderExternalContentSpecInput;
}>;

export type CreateOrderExternalContentSpecMutation = {
  __typename?: "Mutation";
  createOrderExternalContentSpec: {
    __typename?: "CreateOrderExternalContentSpecResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type ContentFulfillment_UpdateOrderMutationVariables = Exact<{
  input: UpdateOrderInput;
}>;

export type ContentFulfillment_UpdateOrderMutation = {
  __typename?: "Mutation";
  updateOrder: { __typename?: "UpdateOrderResponse"; order: { __typename?: "Order"; id: number } };
};

export type ContentFulfillmentDecisionTaskWorkspace_CustomerFragment = {
  __typename?: "Customer";
  id: number;
  name: string;
  website: string;
  instructions?: string | null;
  category?: { __typename?: "Category"; id: number; name: string } | null;
  partner: { __typename?: "Partner"; id: number; name: string };
  customerQuestionnaire?: {
    __typename?: "CustomerQuestionnaire";
    id: number;
    businessDescription?: string | null;
    callToActionUrls?: string | null;
  } | null;
};

export type ContentFulfillmentDecisionTaskWorkspace_OrderFragment = {
  __typename?: "Order";
  id: number;
  startDate: Date;
  topic?: string | null;
  totalWholesaleAmount: number;
  partnerProduct: {
    __typename?: "PartnerProduct";
    id: number;
    product: { __typename?: "Product"; id: string; name: string };
  };
  activeOrderContentStrategy?: { __typename?: "OrderContentStrategy"; id: number; linkTo?: string | null } | null;
};

export type ContentFulfillmentDecisionTaskWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  keyword?: string | null;
  customer: {
    __typename?: "Customer";
    id: number;
    name: string;
    website: string;
    instructions?: string | null;
    category?: { __typename?: "Category"; id: number; name: string } | null;
    partner: { __typename?: "Partner"; id: number; name: string };
    customerQuestionnaire?: {
      __typename?: "CustomerQuestionnaire";
      id: number;
      businessDescription?: string | null;
      callToActionUrls?: string | null;
    } | null;
  };
  order: {
    __typename?: "Order";
    id: number;
    startDate: Date;
    topic?: string | null;
    totalWholesaleAmount: number;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      product: { __typename?: "Product"; id: string; name: string };
    };
    activeOrderContentStrategy?: { __typename?: "OrderContentStrategy"; id: number; linkTo?: string | null } | null;
  };
};

export type ContentFulfillmentDecisionTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ContentFulfillmentDecisionTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    keyword?: string | null;
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      instructions?: string | null;
      category?: { __typename?: "Category"; id: number; name: string } | null;
      partner: { __typename?: "Partner"; id: number; name: string };
      customerQuestionnaire?: {
        __typename?: "CustomerQuestionnaire";
        id: number;
        businessDescription?: string | null;
        callToActionUrls?: string | null;
      } | null;
    };
    order: {
      __typename?: "Order";
      id: number;
      startDate: Date;
      topic?: string | null;
      totalWholesaleAmount: number;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: { __typename?: "Product"; id: string; name: string };
      };
      activeOrderContentStrategy?: { __typename?: "OrderContentStrategy"; id: number; linkTo?: string | null } | null;
    };
  } | null;
};

export type ContentIdeationTaskWorkspace_ContentIdeationFragment = {
  __typename?: "ContentIdeation";
  id: number;
  idea: string;
};

export type ContentIdeationTaskWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  keyword?: string | null;
  customer: { __typename?: "Customer"; id: number; name: string; instructions?: string | null };
  contentIdeation?: { __typename?: "ContentIdeation"; id: number; idea: string } | null;
  order: {
    __typename?: "Order";
    id: number;
    topic?: string | null;
    instructions?: string | null;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      product: { __typename?: "Product"; id: string; name: string };
    };
  };
};

export type ContentIdeationTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ContentIdeationTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    keyword?: string | null;
    customer: { __typename?: "Customer"; id: number; name: string; instructions?: string | null };
    contentIdeation?: { __typename?: "ContentIdeation"; id: number; idea: string } | null;
    order: {
      __typename?: "Order";
      id: number;
      topic?: string | null;
      instructions?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: { __typename?: "Product"; id: string; name: string };
      };
    };
  } | null;
};

export type SaveTaskContentIdeationMutationVariables = Exact<{
  input: SaveTaskContentIdeationInput;
}>;

export type SaveTaskContentIdeationMutation = {
  __typename?: "Mutation";
  saveTaskContentIdeation: {
    __typename?: "SaveTaskContentIdeationResponse";
    contentIdeation: { __typename?: "ContentIdeation"; id: number };
  };
};

export type ContentReviewTaskWorkspace_ContentItemFragment = {
  __typename?: "ContentItem";
  id: number;
  title: string;
  content: string;
  metaTitle: string;
  metaDescription: string;
  thumbnailFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
  feedbackContentItems: Array<{ __typename?: "FeedbackContentItem"; id: number; rating: number; feedback: string }>;
};

export type ContentReviewTaskWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  keyword?: string | null;
  landingPageUrl?: string | null;
  customer: {
    __typename?: "Customer";
    id: number;
    name: string;
    website: string;
    instructions?: string | null;
    customerQuestionnaire?: { __typename?: "CustomerQuestionnaire"; id: number; isAddImage?: boolean | null } | null;
  };
  reviewTargetTask?: {
    __typename?: "Task";
    id: number;
    contentItem?: {
      __typename?: "ContentItem";
      id: number;
      title: string;
      content: string;
      metaTitle: string;
      metaDescription: string;
      thumbnailFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
      feedbackContentItems: Array<{ __typename?: "FeedbackContentItem"; id: number; rating: number; feedback: string }>;
    } | null;
    reviewTasks: {
      __typename?: "TaskConnection";
      nodes: Array<{
        __typename?: "Task";
        id: number;
        createdAt: Date;
        completedAt?: Date | null;
        taskTypeId: string;
        reviewComment?: string | null;
        reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
      }>;
    };
    assignedUser?: { __typename?: "User"; id: number; fullName: string; email: string } | null;
  } | null;
  reviewStatus?: { __typename?: "TaskReviewStatus"; id: string } | null;
  order: {
    __typename?: "Order";
    id: number;
    topic?: string | null;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      product: { __typename?: "Product"; id: string; name: string };
    };
  };
};

export type ContentReviewTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ContentReviewTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    keyword?: string | null;
    landingPageUrl?: string | null;
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      instructions?: string | null;
      customerQuestionnaire?: { __typename?: "CustomerQuestionnaire"; id: number; isAddImage?: boolean | null } | null;
    };
    reviewTargetTask?: {
      __typename?: "Task";
      id: number;
      contentItem?: {
        __typename?: "ContentItem";
        id: number;
        title: string;
        content: string;
        metaTitle: string;
        metaDescription: string;
        thumbnailFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
        feedbackContentItems: Array<{
          __typename?: "FeedbackContentItem";
          id: number;
          rating: number;
          feedback: string;
        }>;
      } | null;
      reviewTasks: {
        __typename?: "TaskConnection";
        nodes: Array<{
          __typename?: "Task";
          id: number;
          createdAt: Date;
          completedAt?: Date | null;
          taskTypeId: string;
          reviewComment?: string | null;
          reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
        }>;
      };
      assignedUser?: { __typename?: "User"; id: number; fullName: string; email: string } | null;
    } | null;
    reviewStatus?: { __typename?: "TaskReviewStatus"; id: string } | null;
    order: {
      __typename?: "Order";
      id: number;
      topic?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: { __typename?: "Product"; id: string; name: string };
      };
    };
  } | null;
};

export type CreateFeedbackContentItemMutationVariables = Exact<{
  input: CreateFeedbackContentItemInput;
}>;

export type CreateFeedbackContentItemMutation = {
  __typename?: "Mutation";
  createFeedbackContentItem: {
    __typename?: "CreateFeedbackContentItemResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type SaveTaskContentItemMutationVariables = Exact<{
  input: SaveTaskContentItemInput;
}>;

export type SaveTaskContentItemMutation = {
  __typename?: "Mutation";
  saveTaskContentItem: {
    __typename?: "SaveTaskContentItemResponse";
    contentItem: { __typename?: "ContentItem"; id: number };
  };
};

export type UpdateFeedbackContentItemMutationVariables = Exact<{
  input: UpdateFeedbackContentItemInput;
}>;

export type UpdateFeedbackContentItemMutation = {
  __typename?: "Mutation";
  updateFeedbackContentItem: {
    __typename?: "UpdateFeedbackContentItemResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type ContentStrategyV2_OrderStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type ContentStrategyV2_OrderStatusesQuery = {
  __typename?: "Query";
  orderStatuses: Array<{ __typename?: "OrderStatus"; id: string; name: string }>;
};

export type ContentStrategyV2_OrdersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
  search?: InputMaybe<Scalars["String"]>;
  customerIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  productIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type ContentStrategyV2_OrdersQuery = {
  __typename?: "Query";
  orders: {
    __typename?: "OrderConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Order";
      id: number;
      topic?: string | null;
      startDate: Date;
      instructions?: string | null;
      billingStatus: { __typename?: "OrderBillingStatus"; id: string; name: string; description: string };
      status: { __typename?: "OrderStatus"; id: string; name: string };
      customer: {
        __typename?: "Customer";
        id: number;
        name: string;
        partner: { __typename?: "Partner"; id: number; name: string };
      };
      user: { __typename?: "User"; id: number; firstName: string; lastName: string };
      partnerProduct: { __typename?: "PartnerProduct"; id: number; name: string };
      customerKeyword?: { __typename?: "CustomerKeyword"; id: number; name: string } | null;
      orderIssues: Array<{ __typename?: "OrderIssue"; title: string; message: string }>;
    }>;
  };
};

export type ContentStrategyV2TaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ContentStrategyV2TaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    reviewTargetTask?: {
      __typename?: "Task";
      id: number;
      reviewTasks: {
        __typename?: "TaskConnection";
        nodes: Array<{
          __typename?: "Task";
          id: number;
          createdAt: Date;
          completedAt?: Date | null;
          taskTypeId: string;
          reviewComment?: string | null;
          reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
        }>;
      };
    } | null;
    taskType: { __typename?: "TaskType"; id: string; name: string };
    order: { __typename?: "Order"; id: number };
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      instructions?: string | null;
      category?: { __typename?: "Category"; id: number; name: string } | null;
      customerQuestionnaire?: {
        __typename?: "CustomerQuestionnaire";
        id: number;
        businessDescription?: string | null;
        customerMarketSize?: string | null;
        targetAudience?: string | null;
        competitorWebsites?: string | null;
      } | null;
    };
    customerProposal?: {
      __typename?: "CustomerProposal";
      id: number;
      summary?: string | null;
      orderContentStrategies: Array<{
        __typename?: "OrderContentStrategy";
        id: number;
        topic?: string | null;
        webPage?: string | null;
        pageType?: string | null;
        purposeOfContent?: string | null;
        linkTo?: string | null;
        voice?: string | null;
        focusPoints?: string | null;
        sampleContent?: string | null;
        clientProvidedInstructions?: string | null;
        customerKeywordId?: number | null;
        isComplete: boolean;
        orderContentStrategyReviews: Array<{
          __typename?: "OrderContentStrategyReview";
          id: number;
          createdAt: Date;
          resolvedAt?: Date | null;
          comments?: string | null;
          createdByUser: { __typename?: "User"; id: number; fullName: string };
        }>;
        orderHaloKeywords: Array<{
          __typename?: "OrderHaloKeyword";
          id: number;
          isProposed: boolean;
          customerKeyword: { __typename?: "CustomerKeyword"; id: number; name: string };
        }>;
        order?: {
          __typename?: "Order";
          id: number;
          startDate: Date;
          customerId: number;
          instructions?: string | null;
          partnerProduct: {
            __typename?: "PartnerProduct";
            id: number;
            product: {
              __typename?: "Product";
              id: string;
              name: string;
              minWordCount?: number | null;
              maxWordCount?: number | null;
            };
          };
          customer: {
            __typename?: "Customer";
            customerQuestionnaire?: { __typename?: "CustomerQuestionnaire"; contentPreference?: string | null } | null;
          };
        } | null;
      }>;
    } | null;
  } | null;
};

export type ContentStrategyV2_OrderContentStrategyFragment = {
  __typename?: "OrderContentStrategy";
  id: number;
  topic?: string | null;
  webPage?: string | null;
  pageType?: string | null;
  purposeOfContent?: string | null;
  linkTo?: string | null;
  voice?: string | null;
  focusPoints?: string | null;
  sampleContent?: string | null;
  clientProvidedInstructions?: string | null;
  customerKeywordId?: number | null;
  isComplete: boolean;
  orderContentStrategyReviews: Array<{
    __typename?: "OrderContentStrategyReview";
    id: number;
    createdAt: Date;
    resolvedAt?: Date | null;
    comments?: string | null;
    createdByUser: { __typename?: "User"; id: number; fullName: string };
  }>;
  orderHaloKeywords: Array<{
    __typename?: "OrderHaloKeyword";
    id: number;
    isProposed: boolean;
    customerKeyword: { __typename?: "CustomerKeyword"; id: number; name: string };
  }>;
  order?: {
    __typename?: "Order";
    id: number;
    startDate: Date;
    customerId: number;
    instructions?: string | null;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      product: {
        __typename?: "Product";
        id: string;
        name: string;
        minWordCount?: number | null;
        maxWordCount?: number | null;
      };
    };
    customer: {
      __typename?: "Customer";
      customerQuestionnaire?: { __typename?: "CustomerQuestionnaire"; contentPreference?: string | null } | null;
    };
  } | null;
};

export type ResolveOrderContentStrategyReviewMutationVariables = Exact<{
  input: ResolveOrderContentStrategyReviewInput;
}>;

export type ResolveOrderContentStrategyReviewMutation = {
  __typename?: "Mutation";
  resolveOrderContentStrategyReview: {
    __typename?: "ResolveOrderContentStrategyReviewResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
    orderContentStrategyReview?: {
      __typename?: "OrderContentStrategyReview";
      id: number;
      resolvedAt?: Date | null;
    } | null;
  };
};

export type ContentStrategyV2_UpdateCustomerProposalMutationVariables = Exact<{
  input: UpdateCustomerProposalInput;
}>;

export type ContentStrategyV2_UpdateCustomerProposalMutation = {
  __typename?: "Mutation";
  updateCustomerProposal: {
    __typename?: "UpdateCustomerProposalResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
    customerProposal?: { __typename?: "CustomerProposal"; id: number; summary?: string | null } | null;
  };
};

export type ContentStrategyV2_UpdateOrderContentStrategyMutationVariables = Exact<{
  input: UpdateOrderContentStrategyInput;
}>;

export type ContentStrategyV2_UpdateOrderContentStrategyMutation = {
  __typename?: "Mutation";
  updateOrderContentStrategy: {
    __typename?: "UpdateOrderContentStrategyResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
    orderContentStrategy?: {
      __typename?: "OrderContentStrategy";
      id: number;
      topic?: string | null;
      webPage?: string | null;
      pageType?: string | null;
      purposeOfContent?: string | null;
      linkTo?: string | null;
      voice?: string | null;
      focusPoints?: string | null;
      sampleContent?: string | null;
      clientProvidedInstructions?: string | null;
      customerKeywordId?: number | null;
      isComplete: boolean;
      orderContentStrategyReviews: Array<{
        __typename?: "OrderContentStrategyReview";
        id: number;
        createdAt: Date;
        resolvedAt?: Date | null;
        comments?: string | null;
        createdByUser: { __typename?: "User"; id: number; fullName: string };
      }>;
      orderHaloKeywords: Array<{
        __typename?: "OrderHaloKeyword";
        id: number;
        isProposed: boolean;
        customerKeyword: { __typename?: "CustomerKeyword"; id: number; name: string };
      }>;
      order?: {
        __typename?: "Order";
        id: number;
        startDate: Date;
        customerId: number;
        instructions?: string | null;
        partnerProduct: {
          __typename?: "PartnerProduct";
          id: number;
          product: {
            __typename?: "Product";
            id: string;
            name: string;
            minWordCount?: number | null;
            maxWordCount?: number | null;
          };
        };
        customer: {
          __typename?: "Customer";
          customerQuestionnaire?: { __typename?: "CustomerQuestionnaire"; contentPreference?: string | null } | null;
        };
      } | null;
    } | null;
  };
};

export type ContentStrategy_SaveProposedOrderTopicMutationVariables = Exact<{
  input: SaveProposedOrderTopicInput;
}>;

export type ContentStrategy_SaveProposedOrderTopicMutation = {
  __typename?: "Mutation";
  saveProposedOrderTopic: {
    __typename?: "SaveProposedOrderTopicResponse";
    proposedOrderTopic: { __typename?: "ProposedOrderTopic"; id: number };
  };
};

export type ContentStrategyTaskWorkspace_OrdersFragment = {
  __typename?: "Order";
  id: number;
  startDate: Date;
  topic?: string | null;
  partnerProduct: {
    __typename?: "PartnerProduct";
    id: number;
    name: string;
    product: { __typename?: "Product"; id: string; name: string };
  };
  customerKeyword?: { __typename?: "CustomerKeyword"; id: number; displayName: string } | null;
};

export type ContentStrategyTaskWorkspace_ProposedOrderTopicFragment = {
  __typename?: "ProposedOrderTopic";
  id: number;
  orderId: number;
  topic: string;
  rejectionComment?: string | null;
  customerKeyword: { __typename?: "CustomerKeyword"; id: number; displayName: string };
};

export type ContentStrategyTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ContentStrategyTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    customerId: number;
    customerProposal?: {
      __typename?: "CustomerProposal";
      id: number;
      proposedOrderTopics: Array<{
        __typename?: "ProposedOrderTopic";
        id: number;
        orderId: number;
        topic: string;
        rejectionComment?: string | null;
        customerKeyword: { __typename?: "CustomerKeyword"; id: number; displayName: string };
      }>;
    } | null;
    contentCalendarOrders: Array<{
      __typename?: "Order";
      id: number;
      startDate: Date;
      topic?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        name: string;
        product: { __typename?: "Product"; id: string; name: string };
      };
      customerKeyword?: { __typename?: "CustomerKeyword"; id: number; displayName: string } | null;
    }>;
  } | null;
};

export type ContentTaskWorkspace_ContentItemFragment = {
  __typename?: "ContentItem";
  id: number;
  title: string;
  content: string;
};

export type ContentTaskWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  keyword?: string | null;
  landingPageUrl?: string | null;
  customer: { __typename?: "Customer"; id: number; name: string; website: string; instructions?: string | null };
  contentItem?: { __typename?: "ContentItem"; id: number; title: string; content: string } | null;
  order: {
    __typename?: "Order";
    id: number;
    topic?: string | null;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      product: { __typename?: "Product"; id: string; name: string };
    };
  };
  reviewTasks: {
    __typename?: "TaskConnection";
    nodes: Array<{
      __typename?: "Task";
      id: number;
      createdAt: Date;
      completedAt?: Date | null;
      taskTypeId: string;
      reviewComment?: string | null;
      reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
    }>;
  };
};

export type ContentTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ContentTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    keyword?: string | null;
    landingPageUrl?: string | null;
    customer: { __typename?: "Customer"; id: number; name: string; website: string; instructions?: string | null };
    contentItem?: { __typename?: "ContentItem"; id: number; title: string; content: string } | null;
    order: {
      __typename?: "Order";
      id: number;
      topic?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: { __typename?: "Product"; id: string; name: string };
      };
    };
    reviewTasks: {
      __typename?: "TaskConnection";
      nodes: Array<{
        __typename?: "Task";
        id: number;
        createdAt: Date;
        completedAt?: Date | null;
        taskTypeId: string;
        reviewComment?: string | null;
        reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
      }>;
    };
  } | null;
};

export type TaskWorkspaceExternalContentQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type TaskWorkspaceExternalContentQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      instructions?: string | null;
      partner: { __typename?: "Partner"; id: number; name: string };
    };
    order: {
      __typename?: "Order";
      id: number;
      resultUrl?: string | null;
      resultUrlDomainAuthority?: number | null;
      startDate: Date;
      topic?: string | null;
      customerKeyword?: { __typename?: "CustomerKeyword"; id: number; name: string } | null;
      orderExternalContentSpec?: {
        __typename?: "OrderExternalContentSpec";
        id: number;
        externalOrderId: string;
        vendor: { __typename?: "Vendor"; id: number; name: string };
      } | null;
    };
    product: { __typename?: "Product"; id: string; name: string };
  } | null;
};

export type TaskWorkspaceExternalContent_UpdateOrderDetailsMutationVariables = Exact<{
  orderInput: UpdateOrderInput;
  orderExternalContentSpecInput: UpdateOrderExternalContentSpecInput;
}>;

export type TaskWorkspaceExternalContent_UpdateOrderDetailsMutation = {
  __typename?: "Mutation";
  updateOrder: { __typename?: "UpdateOrderResponse"; order: { __typename?: "Order"; id: number } };
  updateOrderExternalContentSpec: {
    __typename?: "UpdateOrderExternalContentSpecResponse";
    orderExternalContentSpec?: { __typename?: "OrderExternalContentSpec"; id: number } | null;
  };
};

export type GuestArticleWorkspace_TaskQueryVariables = Exact<{
  taskId: Scalars["Int"];
  subTasksSort?: InputMaybe<TasksSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type GuestArticleWorkspace_TaskQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    taskTypeId: string;
    keyword?: string | null;
    landingPageUrl?: string | null;
    isSystemTaskInProgress: boolean;
    status: { __typename?: "TaskStatus"; id: string };
    currentSubTask?: { __typename?: "Task"; id: number; taskTypeId: string } | null;
    subTasks: {
      __typename?: "TaskConnection";
      nodes: Array<{ __typename?: "Task"; id: number; taskTypeId: string; completedAt?: Date | null }>;
    };
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      instructions?: string | null;
      category?: { __typename?: "Category"; id: number; name: string } | null;
    };
    order: {
      __typename?: "Order";
      id: number;
      resultUrl?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        name: string;
        product: { __typename?: "Product"; id: string; name: string };
      };
      targetLinkLocation?: {
        __typename?: "LinkLocation";
        id: number;
        domain: string;
        domainAuthority: number;
        contactName?: string | null;
        contactEmail?: string | null;
        cost?: number | null;
        publisherLinkLocation?: {
          __typename?: "PublisherLinkLocation";
          id: number;
          publisher?: {
            __typename?: "Publisher";
            id: number;
            name: string;
            paymentMethod?: string | null;
            paymentMethodId?: string | null;
            isAutoPayEnabled?: boolean | null;
            isInvoiceRequired?: boolean | null;
            contactEmail?: string | null;
            contactName?: string | null;
            instructions?: string | null;
          } | null;
        } | null;
      } | null;
      orderContentItems: Array<{
        __typename?: "OrderContentItem";
        contentItem: { __typename?: "ContentItem"; id: number; title: string; content: string };
      }>;
      publisherPayment?: {
        __typename?: "PublisherPayment";
        id: number;
        processor?: string | null;
        processorReferenceId?: string | null;
        paymentBatchId?: number | null;
        amount: number;
        status?: { __typename?: "PublisherPaymentStatus"; id: string } | null;
      } | null;
    };
  } | null;
};

export type SubmitProposalReviewMutationVariables = Exact<{
  input: SubmitProposalReviewInput;
}>;

export type SubmitProposalReviewMutation = {
  __typename?: "Mutation";
  submitProposalReview: {
    __typename?: "SubmitProposalReviewResponse";
    ok?: boolean | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type UpdateOrderProposalReviewMutationVariables = Exact<{
  input: UpdateOrderInput;
}>;

export type UpdateOrderProposalReviewMutation = {
  __typename?: "Mutation";
  updateOrder: { __typename?: "UpdateOrderResponse"; order: { __typename?: "Order"; id: number } };
};

export type ProposalReviewTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ProposalReviewTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    status: { __typename?: "TaskStatus"; id: string; name: string };
    order: {
      __typename?: "Order";
      id: number;
      targetResultUrl?: string | null;
      targetLinkLocation?: {
        __typename?: "LinkLocation";
        id: number;
        cost?: number | null;
        publisherLinkLocation?: {
          __typename?: "PublisherLinkLocation";
          id: number;
          publisher?: { __typename?: "Publisher"; id: number; name: string } | null;
        } | null;
      } | null;
    };
    reviewTargetTask?: {
      __typename?: "Task";
      id: number;
      contentItem?: { __typename?: "ContentItem"; id: number; title: string; content: string } | null;
    } | null;
  } | null;
};

export type QaContentSendTaskWorkspace_ContentItemFragment = {
  __typename?: "ContentItem";
  id: number;
  title: string;
  content: string;
  metaTitle: string;
  metaDescription: string;
  thumbnailFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
};

export type QaContentSendTaskWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  keyword?: string | null;
  customer: {
    __typename?: "Customer";
    id: number;
    name: string;
    instructions?: string | null;
    customerQuestionnaire?: { __typename?: "CustomerQuestionnaire"; id: number; isAddImage?: boolean | null } | null;
  };
  reviewTargetTask?: {
    __typename?: "Task";
    id: number;
    contentItem?: {
      __typename?: "ContentItem";
      id: number;
      title: string;
      content: string;
      metaTitle: string;
      metaDescription: string;
      thumbnailFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
    } | null;
    reviewTasks: {
      __typename?: "TaskConnection";
      nodes: Array<{
        __typename?: "Task";
        id: number;
        createdAt: Date;
        completedAt?: Date | null;
        taskTypeId: string;
        reviewComment?: string | null;
        reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
      }>;
    };
    assignedUser?: { __typename?: "User"; id: number; fullName: string; email: string } | null;
  } | null;
  reviewStatus?: { __typename?: "TaskReviewStatus"; id: string } | null;
  order: {
    __typename?: "Order";
    id: number;
    topic?: string | null;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      product: { __typename?: "Product"; id: string; name: string };
    };
  };
};

export type QaContentSendTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type QaContentSendTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    keyword?: string | null;
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      instructions?: string | null;
      customerQuestionnaire?: { __typename?: "CustomerQuestionnaire"; id: number; isAddImage?: boolean | null } | null;
    };
    reviewTargetTask?: {
      __typename?: "Task";
      id: number;
      contentItem?: {
        __typename?: "ContentItem";
        id: number;
        title: string;
        content: string;
        metaTitle: string;
        metaDescription: string;
        thumbnailFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
      } | null;
      reviewTasks: {
        __typename?: "TaskConnection";
        nodes: Array<{
          __typename?: "Task";
          id: number;
          createdAt: Date;
          completedAt?: Date | null;
          taskTypeId: string;
          reviewComment?: string | null;
          reviewStatus?: { __typename?: "TaskReviewStatus"; id: string; name: string } | null;
        }>;
      };
      assignedUser?: { __typename?: "User"; id: number; fullName: string; email: string } | null;
    } | null;
    reviewStatus?: { __typename?: "TaskReviewStatus"; id: string } | null;
    order: {
      __typename?: "Order";
      id: number;
      topic?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: { __typename?: "Product"; id: string; name: string };
      };
    };
  } | null;
};

export type QaContentSendTaskWorkspace_SaveTaskContentItemMutationVariables = Exact<{
  input: SaveTaskContentItemInput;
}>;

export type QaContentSendTaskWorkspace_SaveTaskContentItemMutation = {
  __typename?: "Mutation";
  saveTaskContentItem: {
    __typename?: "SaveTaskContentItemResponse";
    contentItem: { __typename?: "ContentItem"; id: number };
  };
};

export type GuestArticleTaskWorkspace_SaveOrderContentItemMutationVariables = Exact<{
  input: SaveOrderContentItemInput;
}>;

export type GuestArticleTaskWorkspace_SaveOrderContentItemMutation = {
  __typename?: "Mutation";
  saveOrderContentItem: {
    __typename?: "SaveOrderContentItemResponse";
    contentItem: { __typename?: "ContentItem"; id: number };
  };
};

export type UpdateSubTaskMutationVariables = Exact<{
  input: UpdateTaskInput;
}>;

export type UpdateSubTaskMutation = {
  __typename?: "Mutation";
  updateTask: { __typename?: "UpdateTaskResponse"; task: { __typename?: "Task"; id: number } };
};

export type GuestArticle_UpdateOrderMutationVariables = Exact<{
  input: UpdateOrderInput;
}>;

export type GuestArticle_UpdateOrderMutation = {
  __typename?: "Mutation";
  updateOrder: { __typename?: "UpdateOrderResponse"; order: { __typename?: "Order"; id: number } };
};

export type GuestArticle_CreatePublisherPaymentMutationVariables = Exact<{
  input: CreatePublisherPaymentInput;
}>;

export type GuestArticle_CreatePublisherPaymentMutation = {
  __typename?: "Mutation";
  createPublisherPayment: {
    __typename?: "CreatePublisherPaymentResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type GuestArticle_UpdatePublisherPaymentMutationVariables = Exact<{
  input: UpdatePublisherPaymentInput;
}>;

export type GuestArticle_UpdatePublisherPaymentMutation = {
  __typename?: "Mutation";
  updatePublisherPayment: {
    __typename?: "UpdatePublisherPaymentResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type GuestArticle_CreateSystemTaskInprogressMutationVariables = Exact<{
  input: CreateSystemTaskInprogressInput;
}>;

export type GuestArticle_CreateSystemTaskInprogressMutation = {
  __typename?: "Mutation";
  createSystemTaskInprogress: {
    __typename?: "CreateSystemTaskInprogressResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type GuestArticle_DeleteSystemTaskInprogressMutationVariables = Exact<{
  input: DeleteSystemTaskInprogressInput;
}>;

export type GuestArticle_DeleteSystemTaskInprogressMutation = {
  __typename?: "Mutation";
  deleteSystemTaskInprogresss: { __typename?: "DeleteSystemTaskInprogressResponse"; ok: boolean };
};

export type WebsiteSelectionTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type WebsiteSelectionTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    order: {
      __typename?: "Order";
      id: number;
      targetResultUrl?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: { __typename?: "Product"; id: string; minDA?: number | null; minSV?: number | null };
      };
    };
  } | null;
};

export type CreateProposedCustomerKeywordMutationVariables = Exact<{
  input: CreateProposedCustomerKeywordInput;
}>;

export type CreateProposedCustomerKeywordMutation = {
  __typename?: "Mutation";
  createProposedCustomerKeyword: {
    __typename?: "CreateProposedCustomerKeywordResponse";
    proposedCustomerKeyword: { __typename?: "ProposedCustomerKeyword"; id: number };
  };
};

export type CreateBulkProposedCustomerKeywordsMutationVariables = Exact<{
  input: CreateBulkProposedCustomerKeywordsInput;
}>;

export type CreateBulkProposedCustomerKeywordsMutation = {
  __typename?: "Mutation";
  createBulkProposedCustomerKeywords: {
    __typename?: "CreateBulkProposedCustomerKeywordsResponse";
    proposedCustomerKeywords: Array<{ __typename?: "ProposedCustomerKeyword"; id: number; name: string }>;
  };
};

export type DeleteProposedCustomerKeywordMutationVariables = Exact<{
  input: DeleteProposedCustomerKeywordInput;
}>;

export type DeleteProposedCustomerKeywordMutation = {
  __typename?: "Mutation";
  deleteProposedCustomerKeyword: { __typename?: "DeleteProposedCustomerKeywordResponse"; isDeleted: boolean };
};

export type UpdateCustomerKeywordProposalMutationVariables = Exact<{
  input: UpdateCustomerKeywordProposalInput;
}>;

export type UpdateCustomerKeywordProposalMutation = {
  __typename?: "Mutation";
  updateCustomerKeywordProposal: {
    __typename?: "UpdateCustomerKeywordProposalResponse";
    customerKeywordProposal?: { __typename?: "CustomerKeywordProposal"; id: number; summary?: string | null } | null;
  };
};

export type UpdateProposedCustomerKeywordMutationVariables = Exact<{
  input: UpdateProposedCustomerKeywordInput;
}>;

export type UpdateProposedCustomerKeywordMutation = {
  __typename?: "Mutation";
  updateProposedCustomerKeyword: {
    __typename?: "UpdateProposedCustomerKeywordResponse";
    proposedCustomerKeyword: { __typename?: "ProposedCustomerKeyword"; id: number; name: string };
  };
};

export type ClearProposedCustomerKeywordsRejectionMutationVariables = Exact<{
  input: ClearProposedCustomerKeywordsRejectionInput;
}>;

export type ClearProposedCustomerKeywordsRejectionMutation = {
  __typename?: "Mutation";
  clearProposedCustomerKeywordsRejection: {
    __typename?: "ClearProposedCustomerKeywordsRejectionResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type KeywordResearchTaskWorkspace_CustomerFragment = {
  __typename?: "Customer";
  id: number;
  name: string;
  website: string;
  instructions?: string | null;
  category?: { __typename?: "Category"; id: number; name: string } | null;
  customerQuestionnaire?: {
    __typename?: "CustomerQuestionnaire";
    id: number;
    customerMarketSize?: string | null;
    businessDescription?: string | null;
    productsOffered?: string | null;
    targetKeywords?: string | null;
    targetAudience?: string | null;
    competitorWebsites?: string | null;
    serviceArea?: string | null;
  } | null;
};

export type KeywordResearchTaskWorkspace_ProposedKeywordFragment = {
  __typename?: "ProposedCustomerKeyword";
  id: number;
  name: string;
  location?: string | null;
  country?: string | null;
  landingPageUrl?: string | null;
  rejectionReason?: string | null;
  isPrimary: boolean;
};

export type KeywordResearchTaskWorkspace_OrderFragment = {
  __typename?: "Order";
  id: number;
  startDate: Date;
  partnerProduct: {
    __typename?: "PartnerProduct";
    id: number;
    product: { __typename?: "Product"; id: string; name: string };
  };
};

export type KeywordResearchTaskWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  customer: {
    __typename?: "Customer";
    id: number;
    name: string;
    website: string;
    instructions?: string | null;
    category?: { __typename?: "Category"; id: number; name: string } | null;
    customerQuestionnaire?: {
      __typename?: "CustomerQuestionnaire";
      id: number;
      customerMarketSize?: string | null;
      businessDescription?: string | null;
      productsOffered?: string | null;
      targetKeywords?: string | null;
      targetAudience?: string | null;
      competitorWebsites?: string | null;
      serviceArea?: string | null;
    } | null;
  };
  order: {
    __typename?: "Order";
    id: number;
    startDate: Date;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      product: { __typename?: "Product"; id: string; name: string };
    };
  };
  customerKeywordProposal?: {
    __typename?: "CustomerKeywordProposal";
    id: number;
    summary?: string | null;
    rejectedProposedKeywords: Array<{
      __typename?: "ProposedCustomerKeyword";
      id: number;
      name: string;
      location?: string | null;
      country?: string | null;
      landingPageUrl?: string | null;
      rejectionReason?: string | null;
      isPrimary: boolean;
    }>;
    keywords: Array<{
      __typename?: "ProposedCustomerKeyword";
      id: number;
      name: string;
      location?: string | null;
      country?: string | null;
      landingPageUrl?: string | null;
      rejectionReason?: string | null;
      isPrimary: boolean;
    }>;
  } | null;
};

export type KeywordResearchTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type KeywordResearchTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      instructions?: string | null;
      category?: { __typename?: "Category"; id: number; name: string } | null;
      customerQuestionnaire?: {
        __typename?: "CustomerQuestionnaire";
        id: number;
        customerMarketSize?: string | null;
        businessDescription?: string | null;
        productsOffered?: string | null;
        targetKeywords?: string | null;
        targetAudience?: string | null;
        competitorWebsites?: string | null;
        serviceArea?: string | null;
      } | null;
    };
    order: {
      __typename?: "Order";
      id: number;
      startDate: Date;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: { __typename?: "Product"; id: string; name: string };
      };
    };
    customerKeywordProposal?: {
      __typename?: "CustomerKeywordProposal";
      id: number;
      summary?: string | null;
      rejectedProposedKeywords: Array<{
        __typename?: "ProposedCustomerKeyword";
        id: number;
        name: string;
        location?: string | null;
        country?: string | null;
        landingPageUrl?: string | null;
        rejectionReason?: string | null;
        isPrimary: boolean;
      }>;
      keywords: Array<{
        __typename?: "ProposedCustomerKeyword";
        id: number;
        name: string;
        location?: string | null;
        country?: string | null;
        landingPageUrl?: string | null;
        rejectionReason?: string | null;
        isPrimary: boolean;
      }>;
    } | null;
  } | null;
};

export type CreateCustomerLinkBuildMutationVariables = Exact<{
  input: CreateCustomerLinkBuildInput;
}>;

export type CreateCustomerLinkBuildMutation = {
  __typename?: "Mutation";
  createCustomerLinkBuild: {
    __typename?: "CreateCustomerLinkBuildResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
    customerLinkBuild?: { __typename?: "CustomerLinkBuild"; id: number } | null;
  };
};

export type LinkBuildTaskWorkspace_OrderFragment = {
  __typename?: "Order";
  id: number;
  startDate: Date;
  instructions?: string | null;
  partnerProduct: {
    __typename?: "PartnerProduct";
    id: number;
    name: string;
    product: { __typename?: "Product"; id: string; name: string };
  };
  customerSiteAudit?: { __typename?: "CustomerSiteAudit"; id: number; createdAt: Date } | null;
};

export type LinkBuildTaskWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  customer: {
    __typename?: "Customer";
    id: number;
    name: string;
    website: string;
    customerBusinessListingSpec?: {
      __typename?: "CustomerBusinessListingSpec";
      id: number;
      address: string;
      city: string;
      stateOrProvince: string;
      zipPostalCode: string;
      phoneNumber: string;
      email?: string | null;
    } | null;
    customerQuestionnaire?: {
      __typename?: "CustomerQuestionnaire";
      id: number;
      businessDescription?: string | null;
    } | null;
    category?: { __typename?: "Category"; id: number; name: string } | null;
    customerKeywords: {
      __typename?: "CustomerKeywordConnection";
      nodes: Array<{ __typename?: "CustomerKeyword"; id: number; displayName: string }>;
    };
  };
  order: {
    __typename?: "Order";
    id: number;
    startDate: Date;
    instructions?: string | null;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      name: string;
      product: { __typename?: "Product"; id: string; name: string };
    };
    customerSiteAudit?: { __typename?: "CustomerSiteAudit"; id: number; createdAt: Date } | null;
  };
};

export type LinkBuildTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type LinkBuildTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      customerBusinessListingSpec?: {
        __typename?: "CustomerBusinessListingSpec";
        id: number;
        address: string;
        city: string;
        stateOrProvince: string;
        zipPostalCode: string;
        phoneNumber: string;
        email?: string | null;
      } | null;
      customerQuestionnaire?: {
        __typename?: "CustomerQuestionnaire";
        id: number;
        businessDescription?: string | null;
      } | null;
      category?: { __typename?: "Category"; id: number; name: string } | null;
      customerKeywords: {
        __typename?: "CustomerKeywordConnection";
        nodes: Array<{ __typename?: "CustomerKeyword"; id: number; displayName: string }>;
      };
    };
    order: {
      __typename?: "Order";
      id: number;
      startDate: Date;
      instructions?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        name: string;
        product: { __typename?: "Product"; id: string; name: string };
      };
      customerSiteAudit?: { __typename?: "CustomerSiteAudit"; id: number; createdAt: Date } | null;
    };
  } | null;
};

export type UpdatePublishGbpMutationVariables = Exact<{
  input: UpdateOrderGbpItemInput;
}>;

export type UpdatePublishGbpMutation = {
  __typename?: "Mutation";
  updateOrderGbpItem: {
    __typename?: "UpdateOrderGbpItemResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type PublishGbpTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type PublishGbpTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      gbpUrl?: string | null;
      partner: { __typename?: "Partner"; id: number; name: string };
      customerKeywords: {
        __typename?: "CustomerKeywordConnection";
        nodes: Array<{ __typename?: "CustomerKeyword"; id: number; name: string }>;
      };
      customerQuestionnaire?: { __typename?: "CustomerQuestionnaire"; id: number; serviceArea?: string | null } | null;
    };
    order: {
      __typename?: "Order";
      id: number;
      instructions?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: { __typename?: "Product"; id: string; name: string };
      };
      orderGbpItem?: {
        __typename?: "OrderGbpItem";
        id: number;
        isProfilePublished: boolean;
        gbpItem: {
          __typename?: "GbpItem";
          id: number;
          content: string;
          readMoreUrl?: string | null;
          thumbnailFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
        };
      } | null;
    };
  } | null;
};

export type UpdatePublishPostMutationVariables = Exact<{
  input: UpdateOrderContentItemInput;
}>;

export type UpdatePublishPostMutation = {
  __typename?: "Mutation";
  updateOrderContentItem: {
    __typename?: "UpdateOrderContentItemResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type PublishPostTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type PublishPostTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      customerSiteCredential?: {
        __typename?: "CustomerSiteCredential";
        id: number;
        adminUrl: string;
        user: string;
        password: string;
        instructions?: string | null;
      } | null;
    };
    order: {
      __typename?: "Order";
      id: number;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: { __typename?: "Product"; id: string; name: string };
      };
    };
    publishPostOrders: Array<{
      __typename?: "Order";
      id: number;
      orderContentItems: Array<{
        __typename?: "OrderContentItem";
        id: number;
        isPostPublished: boolean;
        contentItemDelivery: { __typename?: "ContentItemDelivery"; publicUrl: string };
      }>;
    }>;
  } | null;
};

export type UpdatePublishTitleMetaDescriptionMutationVariables = Exact<{
  input: UpdateOrderTitleMetaDescriptionItemInput;
}>;

export type UpdatePublishTitleMetaDescriptionMutation = {
  __typename?: "Mutation";
  updateOrderTitleMetaDescriptionItem: {
    __typename?: "UpdateOrderTitleMetaDescriptionItemResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type PublishTitleMetaDescriptionTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type PublishTitleMetaDescriptionTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    customer: { __typename?: "Customer"; id: number; name: string; website: string };
    order: {
      __typename?: "Order";
      id: number;
      instructions?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: { __typename?: "Product"; id: string; name: string };
      };
      orderTitleMetaDescriptionItem?: {
        __typename?: "OrderTitleMetaDescriptionItem";
        id: number;
        titleMetaDescriptionItem: {
          __typename?: "TitleMetaDescriptionItem";
          id: number;
          titleMetaDescriptions: Array<{
            __typename?: "TitleMetaDescription";
            id: number;
            titleTag: string;
            metaDescription: string;
            url: string;
          }>;
        };
      } | null;
    };
  } | null;
};

export type UpdatePublishWebCopyPostMutationVariables = Exact<{
  input: UpdateOrderContentItemInput;
}>;

export type UpdatePublishWebCopyPostMutation = {
  __typename?: "Mutation";
  updateOrderContentItem: {
    __typename?: "UpdateOrderContentItemResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type PublishWebPostTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type PublishWebPostTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    customer: { __typename?: "Customer"; id: number; name: string };
    order: {
      __typename?: "Order";
      id: number;
      orderContentItems: Array<{
        __typename?: "OrderContentItem";
        id: number;
        isPostPublished: boolean;
        contentItemDelivery: { __typename?: "ContentItemDelivery"; publicUrl: string };
      }>;
    };
  } | null;
};

export type ReviewTitleMetaDescriptionTaskWorkspace_TitleMetaDescriptionItemFragment = {
  __typename?: "TitleMetaDescriptionItem";
  id: number;
  titleMetaDescriptions: Array<{
    __typename?: "TitleMetaDescription";
    id: number;
    titleTag: string;
    metaDescription: string;
    url: string;
  }>;
};

export type ReviewTitleMetaDescriptionTaskWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  expiresAt?: Date | null;
  customer: { __typename?: "Customer"; id: number; name: string; website: string };
  order: { __typename?: "Order"; id: number; instructions?: string | null };
  reviewTargetTask?: {
    __typename?: "Task";
    id: number;
    titleMetaDescriptionItem?: {
      __typename?: "TitleMetaDescriptionItem";
      id: number;
      titleMetaDescriptions: Array<{
        __typename?: "TitleMetaDescription";
        id: number;
        titleTag: string;
        metaDescription: string;
        url: string;
      }>;
    } | null;
  } | null;
};

export type ReviewTitleMetaDescriptionTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type ReviewTitleMetaDescriptionTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    expiresAt?: Date | null;
    customer: { __typename?: "Customer"; id: number; name: string; website: string };
    order: { __typename?: "Order"; id: number; instructions?: string | null };
    reviewTargetTask?: {
      __typename?: "Task";
      id: number;
      titleMetaDescriptionItem?: {
        __typename?: "TitleMetaDescriptionItem";
        id: number;
        titleMetaDescriptions: Array<{
          __typename?: "TitleMetaDescription";
          id: number;
          titleTag: string;
          metaDescription: string;
          url: string;
        }>;
      } | null;
    } | null;
  } | null;
};

export type SaveTaskTitleMetaDescriptionMutationVariables = Exact<{
  input: SaveTaskTitleMetaDescriptionInput;
}>;

export type SaveTaskTitleMetaDescriptionMutation = {
  __typename?: "Mutation";
  saveTaskTitleMetaDescriptions: {
    __typename?: "SaveTaskTitleMetaDescriptionResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; message: string; fieldName: string }>;
    } | null;
  };
};

export type SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutationVariables = Exact<{
  input: CreateCustomerSiteAuditInput;
}>;

export type SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutation = {
  __typename?: "Mutation";
  createCustomerSiteAudit: {
    __typename?: "CreateCustomerSiteAuditResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
    customerSiteAudit?: { __typename?: "CustomerSiteAudit"; id: number } | null;
  };
};

export type SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutationVariables = Exact<{
  input: GenerateUrlForFileUploadInput;
}>;

export type SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutation = {
  __typename?: "Mutation";
  generateUrlForFileUpload: { __typename?: "GenerateUrlForFileUploadResponse"; fileId: string; url: string };
};

export type SiteAuditTaskWorkspace_OrderFragment = {
  __typename?: "Order";
  id: number;
  startDate: Date;
  instructions?: string | null;
  partnerProduct: {
    __typename?: "PartnerProduct";
    id: number;
    name: string;
    product: { __typename?: "Product"; id: string; name: string };
  };
  customerSiteAudit?: { __typename?: "CustomerSiteAudit"; id: number; createdAt: Date } | null;
};

export type SiteAuditTaskWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  customer: {
    __typename?: "Customer";
    id: number;
    name: string;
    website: string;
    instructions?: string | null;
    category?: { __typename?: "Category"; id: number; name: string } | null;
    customerQuestionnaire?: {
      __typename?: "CustomerQuestionnaire";
      id: number;
      customerMarketSize?: string | null;
    } | null;
  };
  order: {
    __typename?: "Order";
    id: number;
    startDate: Date;
    instructions?: string | null;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      name: string;
      product: { __typename?: "Product"; id: string; name: string };
    };
    customerSiteAudit?: { __typename?: "CustomerSiteAudit"; id: number; createdAt: Date } | null;
  };
};

export type SiteAuditTaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type SiteAuditTaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      instructions?: string | null;
      category?: { __typename?: "Category"; id: number; name: string } | null;
      customerQuestionnaire?: {
        __typename?: "CustomerQuestionnaire";
        id: number;
        customerMarketSize?: string | null;
      } | null;
    };
    order: {
      __typename?: "Order";
      id: number;
      startDate: Date;
      instructions?: string | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        name: string;
        product: { __typename?: "Product"; id: string; name: string };
      };
      customerSiteAudit?: { __typename?: "CustomerSiteAudit"; id: number; createdAt: Date } | null;
    };
  } | null;
};

export type UberallCategorySelectorQueryVariables = Exact<{
  categories?: InputMaybe<Scalars["String"]>;
  language: Scalars["String"];
  max?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  q?: InputMaybe<Scalars["String"]>;
  roots?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UberallCategorySelectorQuery = {
  __typename?: "Query";
  uberallCategories?: Array<{
    __typename?: "UberallCategory";
    id: number;
    fullName: string;
    selectable: boolean;
  }> | null;
};

export type UploadFileDialog_GenerateUrlForFileUploadMutationVariables = Exact<{
  input: GenerateUrlForFileUploadInput;
}>;

export type UploadFileDialog_GenerateUrlForFileUploadMutation = {
  __typename?: "Mutation";
  generateUrlForFileUpload: { __typename?: "GenerateUrlForFileUploadResponse"; url: string; fileId: string };
};

export type AccessiblePartnerCountQueryVariables = Exact<{ [key: string]: never }>;

export type AccessiblePartnerCountQuery = {
  __typename?: "Query";
  partners: { __typename?: "PartnerConnection"; totalCount: number };
};

export type TrackShowEventMutationVariables = Exact<{
  input: CreateUserInteractionEventInput;
}>;

export type TrackShowEventMutation = {
  __typename?: "Mutation";
  createUserInteractionEvent: {
    __typename?: "CreateUserInteractionEventResponse";
    ok?: boolean | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type TrackUserEventMutationVariables = Exact<{
  input: CreateUserInteractionEventInput;
}>;

export type TrackUserEventMutation = {
  __typename?: "Mutation";
  createUserInteractionEvent: {
    __typename?: "CreateUserInteractionEventResponse";
    ok?: boolean | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type UserAutoCompleteQueryVariables = Exact<{
  offset: Scalars["Int"];
  limit: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  isAdminOrSuperAdmin?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UserAutoCompleteQuery = {
  __typename?: "Query";
  users: {
    __typename?: "UserConnection";
    nodes: Array<{ __typename?: "User"; id: number; email: string; fullName: string }>;
  };
};

export type UserSelectorV2QueryVariables = Exact<{
  isAdminOrSuperAdmin?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UserSelectorV2Query = {
  __typename?: "Query";
  users: { __typename?: "UserConnection"; nodes: Array<{ __typename?: "User"; id: number; fullName: string }> };
};

export type UserSelectorQueryVariables = Exact<{
  isAdminOrSuperAdmin?: InputMaybe<Scalars["Boolean"]>;
}>;

export type UserSelectorQuery = {
  __typename?: "Query";
  users: { __typename?: "UserConnection"; nodes: Array<{ __typename?: "User"; id: number; fullName: string }> };
};

export type UserTaskPermissionsDrawer_CreatePermissionMutationVariables = Exact<{
  input: CreateUserTaskPermissionInput;
}>;

export type UserTaskPermissionsDrawer_CreatePermissionMutation = {
  __typename?: "Mutation";
  createUserTaskPermission: {
    __typename?: "CreateUserTaskPermissionResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type UserTaskPermissionsDrawer_DeletePermissionMutationVariables = Exact<{
  input: DeleteUserTaskPermissionInput;
}>;

export type UserTaskPermissionsDrawer_DeletePermissionMutation = {
  __typename?: "Mutation";
  deleteUserTaskPermission: {
    __typename?: "DeleteUserTaskPermissionResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type UserTaskPermissionDrawer_ProductTaskTypeFragment = {
  __typename?: "ProductTaskType";
  id: string;
  product: { __typename?: "Product"; id: string; name: string };
  taskType: { __typename?: "TaskType"; id: string; name: string };
};

export type UserTaskPermissionsDrawer_ProductTaskTypesQueryVariables = Exact<{ [key: string]: never }>;

export type UserTaskPermissionsDrawer_ProductTaskTypesQuery = {
  __typename?: "Query";
  productTaskTypes: {
    __typename?: "ProductTaskTypeConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "ProductTaskType";
      id: string;
      product: { __typename?: "Product"; id: string; name: string };
      taskType: { __typename?: "TaskType"; id: string; name: string };
    }>;
  };
};

export type UserTaskPermissionsDrawerQueryVariables = Exact<{
  userId: Scalars["Int"];
}>;

export type UserTaskPermissionsDrawerQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: number;
    fullName: string;
    email: string;
    userTaskPermissions: Array<{
      __typename?: "UserTaskPermission";
      id: number;
      vendorId: number;
      product?: { __typename?: "Product"; id: string; name: string } | null;
      taskType?: { __typename?: "TaskType"; id: string; name: string } | null;
    }>;
  } | null;
};

export type VendorsForSelectorQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  ids?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  showExternalVendors?: InputMaybe<Scalars["Boolean"]>;
}>;

export type VendorsForSelectorQuery = {
  __typename?: "Query";
  vendors: {
    __typename?: "VendorConnection";
    nodes: Array<{
      __typename?: "Vendor";
      id: number;
      name: string;
      isExternal: boolean;
      partner?: { __typename?: "Partner"; id: number; name: string } | null;
    }>;
  };
};

export type CreateWorkflowNoteMutationVariables = Exact<{
  input: CreateWorkflowNoteInput;
}>;

export type CreateWorkflowNoteMutation = {
  __typename?: "Mutation";
  createWorkflowNote: {
    __typename?: "CreateNoteResponse";
    ok: boolean;
    note?: { __typename?: "Note"; id: number } | null;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type WorkflowNotesQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type WorkflowNotesQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    workflow?: {
      __typename?: "Workflow";
      id: number;
      notes: {
        __typename?: "NoteConnection";
        totalCount: number;
        nodes: Array<{
          __typename?: "Note";
          id: number;
          content: string;
          createdAt: Date;
          user: { __typename?: "User"; id: number; fullName: string };
        }>;
      };
    } | null;
  } | null;
};

export type AuthManager_ViewerUserFragment = {
  __typename?: "User";
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  timezone: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  isTracking: boolean;
  googleRefreshToken?: string | null;
  partnerUsers: Array<{ __typename?: "PartnerUser"; id: number; partnerId: number; isAdmin: boolean }>;
  vendorUsers: Array<{ __typename?: "VendorUser"; id: number; vendorId: number }>;
};

export type AuthManager_ViewerFragment = {
  __typename?: "Viewer";
  hasSLPCustomer?: boolean | null;
  user?: {
    __typename?: "User";
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    timezone: string;
    isAdmin: boolean;
    isSuperAdmin: boolean;
    isTracking: boolean;
    googleRefreshToken?: string | null;
    partnerUsers: Array<{ __typename?: "PartnerUser"; id: number; partnerId: number; isAdmin: boolean }>;
    vendorUsers: Array<{ __typename?: "VendorUser"; id: number; vendorId: number }>;
  } | null;
};

export type AuthManagerQueryVariables = Exact<{ [key: string]: never }>;

export type AuthManagerQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Viewer";
    hasSLPCustomer?: boolean | null;
    user?: {
      __typename?: "User";
      id: number;
      firstName: string;
      lastName: string;
      fullName: string;
      email: string;
      timezone: string;
      isAdmin: boolean;
      isSuperAdmin: boolean;
      isTracking: boolean;
      googleRefreshToken?: string | null;
      partnerUsers: Array<{ __typename?: "PartnerUser"; id: number; partnerId: number; isAdmin: boolean }>;
      vendorUsers: Array<{ __typename?: "VendorUser"; id: number; vendorId: number }>;
    } | null;
  };
};

export type LogoutUserMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutUserMutation = {
  __typename?: "Mutation";
  logoutUser: { __typename?: "LogoutUserResponse"; isLoggedOut: boolean };
};

export type AdminBillingPartnerQueryVariables = Exact<{
  partnerId: Scalars["Int"];
  startDate?: InputMaybe<DateQuery>;
}>;

export type AdminBillingPartnerQuery = {
  __typename?: "Query";
  partner?: {
    __typename?: "Partner";
    id: number;
    name: string;
    billableOrders: {
      __typename?: "OrderConnection";
      nodes: Array<{
        __typename?: "Order";
        id: number;
        startDate: Date;
        totalWholesaleAmount: number;
        customer: { __typename?: "Customer"; id: number; name: string };
        partnerProduct: {
          __typename?: "PartnerProduct";
          id: number;
          product: { __typename?: "Product"; id: string; name: string };
        };
      }>;
    };
  } | null;
};

export type CreatePartnerInvoiceMutationVariables = Exact<{
  input: CreatePartnerInvoiceInput;
}>;

export type CreatePartnerInvoiceMutation = {
  __typename?: "Mutation";
  createPartnerInvoice: {
    __typename?: "CreatePartnerInvoiceResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
    partnerInvoice?: { __typename?: "PartnerInvoice"; id: number } | null;
  };
};

export type AdminBillingCreateInvoicePartnersQueryVariables = Exact<{ [key: string]: never }>;

export type AdminBillingCreateInvoicePartnersQuery = {
  __typename?: "Query";
  partners: { __typename?: "PartnerConnection"; nodes: Array<{ __typename?: "Partner"; id: number; name: string }> };
};

export type BillingOrdersReview_OrderFragment = {
  __typename?: "Order";
  id: number;
  startDate: Date;
  totalRetailAmount: number;
  totalWholesaleAmount: number;
  partnerProduct: {
    __typename?: "PartnerProduct";
    id: number;
    name: string;
    product: { __typename?: "Product"; id: string; name: string };
  };
  customer: {
    __typename?: "Customer";
    id: number;
    name: string;
    status: { __typename?: "CustomerStatus"; id: string; name: string };
    partner: { __typename?: "Partner"; id: number; name: string };
  };
  status: { __typename?: "OrderStatus"; id: string; name: string };
  orderIssues: Array<{ __typename?: "OrderIssue"; title: string; message: string }>;
};

export type BillingOrdersReviewQueryVariables = Exact<{
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  partnerId?: InputMaybe<Scalars["Int"]>;
  startDate?: InputMaybe<DateQuery>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type BillingOrdersReviewQuery = {
  __typename?: "Query";
  orders: {
    __typename?: "OrderConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Order";
      id: number;
      startDate: Date;
      totalRetailAmount: number;
      totalWholesaleAmount: number;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        name: string;
        product: { __typename?: "Product"; id: string; name: string };
      };
      customer: {
        __typename?: "Customer";
        id: number;
        name: string;
        status: { __typename?: "CustomerStatus"; id: string; name: string };
        partner: { __typename?: "Partner"; id: number; name: string };
      };
      status: { __typename?: "OrderStatus"; id: string; name: string };
      orderIssues: Array<{ __typename?: "OrderIssue"; title: string; message: string }>;
    }>;
  };
};

export type AdminBillingQueryVariables = Exact<{
  startDate?: InputMaybe<DateQuery>;
}>;

export type AdminBillingQuery = {
  __typename?: "Query";
  partners: {
    __typename?: "PartnerConnection";
    nodes: Array<{
      __typename?: "Partner";
      id: number;
      name: string;
      billableOrders: {
        __typename?: "OrderConnection";
        nodes: Array<{
          __typename?: "Order";
          id: number;
          startDate: Date;
          totalWholesaleAmount: number;
          partnerProduct: {
            __typename?: "PartnerProduct";
            id: number;
            product: { __typename?: "Product"; id: string; name: string };
          };
        }>;
      };
    }>;
  };
};

export type BlockDomainsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  customerId: Scalars["Int"];
}>;

export type BlockDomainsQuery = {
  __typename?: "Query";
  blockDomains: {
    __typename?: "BlockDomainConnection";
    totalCount: number;
    nodes: Array<{ __typename?: "BlockDomainCustomer"; id: number; domain: string }>;
  };
};

export type CreateBlockDomainCustomersMutationVariables = Exact<{
  input: CreateBlockDomainCustomersInput;
}>;

export type CreateBlockDomainCustomersMutation = {
  __typename?: "Mutation";
  createBlockDomainCustomers: {
    __typename?: "CreateBlockDomainCustomersResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type CategoriesQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CategoriesQuery = {
  __typename?: "Query";
  categories: {
    __typename?: "CategoryConnection";
    totalCount: number;
    nodes: Array<{ __typename?: "Category"; id: number; name: string; isActive: boolean }>;
  };
};

export type AdminKeywordsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
}>;

export type AdminKeywordsQuery = {
  __typename?: "Query";
  customerKeywords: {
    __typename?: "CustomerKeywordConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "CustomerKeyword";
      id: number;
      name: string;
      location?: string | null;
      country?: string | null;
      instructions?: string | null;
      customerId: number;
    }>;
  };
};

export type AdminUpdateCustomerKeywordMutationVariables = Exact<{
  input: UpdateCustomerKeywordInput;
}>;

export type AdminUpdateCustomerKeywordMutation = {
  __typename?: "Mutation";
  updateCustomerKeyword: {
    __typename?: "UpdateCustomerKeywordResponse";
    customerKeyword?: { __typename?: "CustomerKeyword"; id: number } | null;
  };
};

export type CreateDomainCategoryMutationVariables = Exact<{
  input: CreateDomainCategoryInput;
}>;

export type CreateDomainCategoryMutation = {
  __typename?: "Mutation";
  createDomainCategory: {
    __typename?: "CreateDomainCategoryResponse";
    domainCategory?: { __typename?: "DomainCategory"; id: number } | null;
  };
};

export type DeleteDomainCategoryMutationVariables = Exact<{
  input: DeleteDomainCategoryInput;
}>;

export type DeleteDomainCategoryMutation = {
  __typename?: "Mutation";
  deleteDomainCategory: { __typename?: "DeleteDomainCategoryResponse"; isDeleted: boolean };
};

export type DomainDetailsQueryVariables = Exact<{
  id: Scalars["String"];
}>;

export type DomainDetailsQuery = {
  __typename?: "Query";
  domain?: {
    __typename?: "Domain";
    id: string;
    createdAt: Date;
    availabilityLastCheckedAt?: Date | null;
    expiresAt?: string | null;
    purchasedAt?: Date | null;
    registrar?: string | null;
    registrarId?: string | null;
    status: { __typename?: "DomainStatus"; id: string; name: string };
    purchaseRequestedByUser?: { __typename?: "User"; id: number; fullName: string } | null;
    domainCategories: {
      __typename?: "DomainCategoryConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "DomainCategory";
        id: number;
        category?: { __typename?: "Category"; id: number; name: string } | null;
      }>;
    };
  } | null;
};

export type CreateSiteMutationVariables = Exact<{
  input: CreateSiteInput;
}>;

export type CreateSiteMutation = {
  __typename?: "Mutation";
  createSite: {
    __typename?: "CreateSiteResponse";
    site: { __typename?: "Site"; id: string; domainId?: string | null };
  };
};

export type DomainTableFragment = {
  __typename?: "Domain";
  id: string;
  createdAt: Date;
  availabilityLastCheckedAt?: Date | null;
  expiresAt?: string | null;
  purchasedAt?: Date | null;
  registrar?: string | null;
  registrarId?: string | null;
  purchasePrice?: number | null;
  purchaseError?: string | null;
  canRefreshAvailability: boolean;
  status: { __typename?: "DomainStatus"; id: string; name: string };
  domainCategories: {
    __typename?: "DomainCategoryConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "DomainCategory";
      id: number;
      domainId: string;
      categoryId: number;
      category?: { __typename?: "Category"; id: number; name: string } | null;
    }>;
  };
  site?: { __typename?: "Site"; id: string; domainId?: string | null } | null;
};

export type DomainsQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
}>;

export type DomainsQuery = {
  __typename?: "Query";
  domains: {
    __typename?: "DomainConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Domain";
      id: string;
      createdAt: Date;
      availabilityLastCheckedAt?: Date | null;
      expiresAt?: string | null;
      purchasedAt?: Date | null;
      registrar?: string | null;
      registrarId?: string | null;
      purchasePrice?: number | null;
      purchaseError?: string | null;
      canRefreshAvailability: boolean;
      status: { __typename?: "DomainStatus"; id: string; name: string };
      domainCategories: {
        __typename?: "DomainCategoryConnection";
        totalCount: number;
        nodes: Array<{
          __typename?: "DomainCategory";
          id: number;
          domainId: string;
          categoryId: number;
          category?: { __typename?: "Category"; id: number; name: string } | null;
        }>;
      };
      site?: { __typename?: "Site"; id: string; domainId?: string | null } | null;
    }>;
  };
};

export type PurchaseDomainMutationVariables = Exact<{
  input: PurchaseDomainInput;
}>;

export type PurchaseDomainMutation = {
  __typename?: "Mutation";
  purchaseDomain: { __typename?: "PurchaseDomainResponse"; domain: { __typename?: "Domain"; id: string } };
};

export type RefreshDomainAvailabilityMutationVariables = Exact<{
  input: RefreshDomainAvailabilityInput;
}>;

export type RefreshDomainAvailabilityMutation = {
  __typename?: "Mutation";
  refreshDomainAvailability: { __typename?: "RefreshDomainAvailabilityResponse"; ok: boolean };
};

export type GetGoogleAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGoogleAccountsQuery = {
  __typename?: "Query";
  getGoogleAccounts: {
    __typename?: "GoogleAccountsResponse";
    accounts?: Array<{ __typename?: "GoogleAccount"; name?: string | null; accountName?: string | null }> | null;
  };
};

export type GetGoogleLocationsQueryVariables = Exact<{
  account: Scalars["String"];
}>;

export type GetGoogleLocationsQuery = {
  __typename?: "Query";
  getGoogleLocations: {
    __typename?: "GoogleLocationsResponse";
    locations?: Array<{
      __typename?: "GoogleLocation";
      name?: string | null;
      title?: string | null;
      storefrontAddress?: {
        __typename?: "PostalAddress";
        addressLines?: Array<string> | null;
        locality?: string | null;
      } | null;
    }> | null;
  };
};

export type AdminLocationReviewFragment = {
  __typename?: "GoogleReview";
  reviewId?: string | null;
  starRating?: string | null;
  comment?: string | null;
  createTime?: Date | null;
  reviewer?: { __typename?: "Reviewer"; displayName?: string | null; profilePhotoUrl?: string | null } | null;
  reviewReply?: { __typename?: "ReviewReply"; comment?: string | null } | null;
};

export type GetGoogleReviewsQueryVariables = Exact<{
  account: Scalars["String"];
  location: Scalars["String"];
  pageToken?: InputMaybe<Scalars["String"]>;
}>;

export type GetGoogleReviewsQuery = {
  __typename?: "Query";
  getGoogleReviews: {
    __typename?: "GoogleReviewRespose";
    nextPageToken?: string | null;
    totalReviewCount?: number | null;
    averageRating?: number | null;
    reviews?: Array<{
      __typename?: "GoogleReview";
      reviewId?: string | null;
      starRating?: string | null;
      comment?: string | null;
      createTime?: Date | null;
      reviewer?: { __typename?: "Reviewer"; displayName?: string | null; profilePhotoUrl?: string | null } | null;
      reviewReply?: { __typename?: "ReviewReply"; comment?: string | null } | null;
    }> | null;
  };
};

export type GeminiReplyCommentQueryVariables = Exact<{
  comment: Scalars["String"];
  keywords: Scalars["String"];
}>;

export type GeminiReplyCommentQuery = {
  __typename?: "Query";
  geminiReplyComment: { __typename?: "ReplyComment"; reply: string };
};

export type LoginGoogleMutationVariables = Exact<{ [key: string]: never }>;

export type LoginGoogleMutation = {
  __typename?: "Mutation";
  loginGoogleService: { __typename?: "LoginGoogleResponse"; ok: boolean; url?: string | null };
};

export type LoginGoogleCallbackMutationVariables = Exact<{
  input?: InputMaybe<LoginGoogleCallbackInput>;
}>;

export type LoginGoogleCallbackMutation = {
  __typename?: "Mutation";
  loginGoogleCallback: {
    __typename?: "LoginGoogleCallbackResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type GoogleReviewReplyMutationVariables = Exact<{
  input: GoogleReviewReplyInput;
}>;

export type GoogleReviewReplyMutation = {
  __typename?: "Mutation";
  googleReviewReply: {
    __typename?: "GoogleReviewReplyResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type GuestArticleOrdersQueryVariables = Exact<{
  productIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  startDate?: InputMaybe<DateQuery>;
  sort?: InputMaybe<OrdersSort>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  tasksSort?: InputMaybe<TasksSort>;
}>;

export type GuestArticleOrdersQuery = {
  __typename?: "Query";
  orders: {
    __typename?: "OrderConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Order";
      id: number;
      resultUrl?: string | null;
      startDate: Date;
      completedAt?: Date | null;
      partnerProduct: {
        __typename?: "PartnerProduct";
        id: number;
        product: { __typename?: "Product"; id: string; name: string };
      };
      customer: { __typename?: "Customer"; id: number; name: string };
      workflow?: {
        __typename?: "Workflow";
        id: number;
        tasks: {
          __typename?: "TaskConnection";
          nodes: Array<{
            __typename?: "Task";
            id: number;
            assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
          }>;
        };
      } | null;
      linkLocation?: {
        __typename?: "LinkLocation";
        id: number;
        domain: string;
        domainAuthority: number;
        authorityScore?: number | null;
        spam?: number | null;
        internalQuality?: number | null;
        monthlyTraffic?: number | null;
        cost?: number | null;
        publisherLinkLocation?: {
          __typename?: "PublisherLinkLocation";
          id: number;
          publisher?: { __typename?: "Publisher"; id: number; name: string } | null;
        } | null;
      } | null;
    }>;
  };
};

export type AdminPublishedContentQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  completedAt?: InputMaybe<DateQuery>;
  includeDeleted?: InputMaybe<Scalars["Boolean"]>;
  productIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  isPostPublished?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AdminPublishedContentQuery = {
  __typename?: "Query";
  orders: {
    __typename?: "OrderConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Order";
      id: number;
      resultUrl?: string | null;
      linkLocation?: {
        __typename?: "LinkLocation";
        id: number;
        publisherLinkLocation?: {
          __typename?: "PublisherLinkLocation";
          id: number;
          publisher?: { __typename?: "Publisher"; id: number; name: string } | null;
        } | null;
      } | null;
      orderContentItems: Array<{
        __typename?: "OrderContentItem";
        id: number;
        isPostPublished: boolean;
        lastVerifiedPostAt?: Date | null;
      }>;
    }>;
  };
};

export type VerifyGuestPostMutationVariables = Exact<{
  input: VerifyGuestPostInput;
  includeDeleted: Scalars["Boolean"];
}>;

export type VerifyGuestPostMutation = {
  __typename?: "Mutation";
  verifyGuestPost: {
    __typename?: "VerifyGuestPostResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
    orders?: Array<{
      __typename?: "Order";
      id: number;
      resultUrl?: string | null;
      linkLocation?: {
        __typename?: "LinkLocation";
        id: number;
        publisherLinkLocation?: {
          __typename?: "PublisherLinkLocation";
          id: number;
          publisher?: { __typename?: "Publisher"; id: number; name: string } | null;
        } | null;
      } | null;
      orderContentItems: Array<{
        __typename?: "OrderContentItem";
        id: number;
        isPostPublished: boolean;
        lastVerifiedPostAt?: Date | null;
      }>;
    }> | null;
  };
};

export type AdminGuestArticleOutreachTasks_TaskFragment = {
  __typename?: "Task";
  id: number;
  isAssignedToViewer: boolean;
  availableAt: Date;
  createdAt: Date;
  completedAt?: Date | null;
  assignedAt?: Date | null;
  topic?: string | null;
  status: { __typename?: "TaskStatus"; id: string; name: string };
  assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
  customer: { __typename?: "Customer"; id: number; name: string };
  order: {
    __typename?: "Order";
    id: number;
    startDate: Date;
    targetLinkLocation?: {
      __typename?: "LinkLocation";
      id: number;
      publisherLinkLocation?: {
        __typename?: "PublisherLinkLocation";
        id: number;
        publisher?: { __typename?: "Publisher"; id: number; name: string; isAutoPayEnabled?: boolean | null } | null;
      } | null;
    } | null;
  };
  product: { __typename?: "Product"; id: string; name: string };
  subTasks: {
    __typename?: "TaskConnection";
    nodes: Array<{
      __typename?: "Task";
      id: number;
      completedAt?: Date | null;
      taskType: { __typename?: "TaskType"; id: string; name: string };
      status: { __typename?: "TaskStatus"; id: string; name: string };
    }>;
  };
  proposalReviewTask: {
    __typename?: "TaskConnection";
    nodes: Array<{
      __typename?: "Task";
      id: number;
      createdAt: Date;
      completedAt?: Date | null;
      reviewStatus?: { __typename?: "TaskReviewStatus"; id: string } | null;
    }>;
  };
};

export type AdminGuestArticleOutreachTasksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TasksSort>;
  customerIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  assignedUserIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  productIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  subTasksSort?: InputMaybe<TasksSort>;
  proposalTaskTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  publisherId?: InputMaybe<Scalars["Int"]>;
}>;

export type AdminGuestArticleOutreachTasksQuery = {
  __typename?: "Query";
  tasks: {
    __typename?: "TaskConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Task";
      id: number;
      isAssignedToViewer: boolean;
      availableAt: Date;
      createdAt: Date;
      completedAt?: Date | null;
      assignedAt?: Date | null;
      topic?: string | null;
      status: { __typename?: "TaskStatus"; id: string; name: string };
      assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
      customer: { __typename?: "Customer"; id: number; name: string };
      order: {
        __typename?: "Order";
        id: number;
        startDate: Date;
        targetLinkLocation?: {
          __typename?: "LinkLocation";
          id: number;
          publisherLinkLocation?: {
            __typename?: "PublisherLinkLocation";
            id: number;
            publisher?: {
              __typename?: "Publisher";
              id: number;
              name: string;
              isAutoPayEnabled?: boolean | null;
            } | null;
          } | null;
        } | null;
      };
      product: { __typename?: "Product"; id: string; name: string };
      subTasks: {
        __typename?: "TaskConnection";
        nodes: Array<{
          __typename?: "Task";
          id: number;
          completedAt?: Date | null;
          taskType: { __typename?: "TaskType"; id: string; name: string };
          status: { __typename?: "TaskStatus"; id: string; name: string };
        }>;
      };
      proposalReviewTask: {
        __typename?: "TaskConnection";
        nodes: Array<{
          __typename?: "Task";
          id: number;
          createdAt: Date;
          completedAt?: Date | null;
          reviewStatus?: { __typename?: "TaskReviewStatus"; id: string } | null;
        }>;
      };
    }>;
  };
};

export type GuestArticleTasksTable_UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskInput;
}>;

export type GuestArticleTasksTable_UpdateTaskMutation = {
  __typename?: "Mutation";
  updateTask: {
    __typename?: "UpdateTaskResponse";
    task: {
      __typename?: "Task";
      id: number;
      assignedUserId?: number | null;
      status: { __typename?: "TaskStatus"; id: string };
    };
  };
};

export type Admin_ImportInvoicesMutationVariables = Exact<{
  input: ImportInvoicesInput;
}>;

export type Admin_ImportInvoicesMutation = {
  __typename?: "Mutation";
  importInvoices: {
    __typename?: "ImportInvoicesResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
    results: Array<{ __typename?: "ImportInvoicesResult"; ok: boolean; orderId: number; message: string }>;
  };
};

export type AdminInvoicesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PartnerInvoiceSort>;
  partnerIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
}>;

export type AdminInvoicesQuery = {
  __typename?: "Query";
  partnerInvoices: {
    __typename?: "PartnerInvoiceConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "PartnerInvoice";
      id: number;
      totalDueAmount: number;
      totalChargesAmount: number;
      invoiceDate: Date;
      status?: { __typename?: "PartnerInvoiceStatus"; id: string; name: string; description: string } | null;
      partner: { __typename?: "Partner"; id: number; name: string };
    }>;
  };
};

export type UpdateLinkLocationDetailsMutationVariables = Exact<{
  input: UpdateLinkLocationInput;
  categoryInput: UpdateLinkLocationCategoriesInput;
}>;

export type UpdateLinkLocationDetailsMutation = {
  __typename?: "Mutation";
  updateLinkLocation: {
    __typename?: "UpdateLinkLocationResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
  updateLinkLocationCategories: {
    __typename?: "UpdateLinkLocationCategoriesResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type UpdatePublisherLinkLocationMutationVariables = Exact<{
  input: UpdatePublisherLinkLocationInput;
}>;

export type UpdatePublisherLinkLocationMutation = {
  __typename?: "Mutation";
  updatePublisherLinkLocation: {
    __typename?: "UpdatePublisherLinkLocationResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type AdminUpdateLinkLocationMetricsMutationVariables = Exact<{
  input: UpdateLinkLocationMetricsInput;
}>;

export type AdminUpdateLinkLocationMetricsMutation = {
  __typename?: "Mutation";
  updateLinkLocationMetrics: {
    __typename?: "UpdateLinkLocationMetricsResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type LinkLocationDetailsQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type LinkLocationDetailsQuery = {
  __typename?: "Query";
  linkLocation?: {
    __typename?: "LinkLocation";
    id: number;
    domain: string;
    domainAuthority: number;
    notes?: string | null;
    minimumWordCount?: number | null;
    spam?: number | null;
    authorityScore?: number | null;
    internalQuality?: number | null;
    monthlyTraffic?: number | null;
    cost?: number | null;
    contactName?: string | null;
    contactEmail?: string | null;
    allowsOnlyOneLinkInContent?: boolean | null;
    allowsCbdContent?: boolean | null;
    allowsWeapons?: boolean | null;
    allowsAIContent?: boolean | null;
    autoAssign?: boolean | null;
    statusId: string;
    typeId: string;
    createdAt: Date;
    lastMetricsUpdatedAt?: Date | null;
    linkLocationCategories: Array<{ __typename?: "LinkLocationCategory"; id: number; categoryId: number }>;
    publisherLinkLocation?: {
      __typename?: "PublisherLinkLocation";
      id: number;
      isRCMPublishing?: boolean | null;
      publisher?: { __typename?: "Publisher"; id: number; name: string } | null;
    } | null;
    linkLocationCredential?: {
      __typename?: "LinkLocationCredential";
      id: number;
      adminUrl: string;
      userName: string;
      password: string;
    } | null;
  } | null;
};

export type UpdateLinkLocationCategoriesMutationVariables = Exact<{
  input: UpdateLinkLocationCategoriesInput;
}>;

export type UpdateLinkLocationCategoriesMutation = {
  __typename?: "Mutation";
  updateLinkLocationCategories: {
    __typename?: "UpdateLinkLocationCategoriesResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type Locations_UpdateLinkLocationMutationVariables = Exact<{
  input: UpdateLinkLocationInput;
}>;

export type Locations_UpdateLinkLocationMutation = {
  __typename?: "Mutation";
  updateLinkLocation: {
    __typename?: "UpdateLinkLocationResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type LinkLocationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  typeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  categoryIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
}>;

export type LinkLocationsQuery = {
  __typename?: "Query";
  linkLocations: {
    __typename?: "LinkLocationConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "LinkLocation";
      id: number;
      domain: string;
      domainAuthority: number;
      authorityScore?: number | null;
      internalQuality?: number | null;
      monthlyTraffic?: number | null;
      autoAssign?: boolean | null;
      createdAt: Date;
      linkLocationCategories: Array<{ __typename?: "LinkLocationCategory"; categoryId: number }>;
      status: { __typename?: "LinkLocationStatus"; name: string };
      type: { __typename?: "LinkLocationType"; name: string };
      publisherLinkLocation?: {
        __typename?: "PublisherLinkLocation";
        id: number;
        publisher?: { __typename?: "Publisher"; id: number; name: string } | null;
      } | null;
    }>;
  };
};

export type PartnerDetailsSecondaryNavQueryVariables = Exact<{
  partnerId: Scalars["Int"];
}>;

export type PartnerDetailsSecondaryNavQuery = {
  __typename?: "Query";
  partner?: { __typename?: "Partner"; id: number; name: string } | null;
};

export type AdminPartnerOverviewQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type AdminPartnerOverviewQuery = {
  __typename?: "Query";
  partner?: {
    __typename?: "Partner";
    id: number;
    name: string;
    billingEmail?: string | null;
    isUpfrontPaymentRequired: boolean;
    isBillingEnforced: boolean;
    stripeAccountId?: string | null;
    portal?: string | null;
    reportingLogoFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
    emailLogoFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
    partnerAnalyticCredential?: {
      __typename?: "PartnerAnalyticCredential";
      id: number;
      userName: string;
      password?: string | null;
    } | null;
  } | null;
};

export type SavePartnerAnalyticCredentialMutationVariables = Exact<{
  input: SavePartnerAnalyticCredentialInput;
}>;

export type SavePartnerAnalyticCredentialMutation = {
  __typename?: "Mutation";
  savePartnerAnalyticCredential: {
    __typename?: "SavePartnerAnalyticCredentialResponse";
    ok?: boolean | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type AdminPartnerOverview_UpdatePartnerMutationVariables = Exact<{
  input: UpdatePartnerInput;
}>;

export type AdminPartnerOverview_UpdatePartnerMutation = {
  __typename?: "Mutation";
  updatePartner: {
    __typename?: "UpdatePartnerResponse";
    partner?: {
      __typename?: "Partner";
      id: number;
      name: string;
      billingEmail?: string | null;
      isUpfrontPaymentRequired: boolean;
      isBillingEnforced: boolean;
      stripeAccountId?: string | null;
      portal?: string | null;
      reportingLogoFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
      emailLogoFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
    } | null;
  };
};

export type AdminPartners_PartnerFragment = { __typename?: "Partner"; id: number; name: string; createdAt: Date };

export type PartnersQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  sort?: InputMaybe<PartnersSort>;
  search?: InputMaybe<Scalars["String"]>;
  portal?: InputMaybe<Scalars["String"]>;
}>;

export type PartnersQuery = {
  __typename?: "Query";
  partners: {
    __typename?: "PartnerConnection";
    totalCount: number;
    nodes: Array<{ __typename?: "Partner"; id: number; name: string; createdAt: Date }>;
  };
};

export type PaymentBatchReviewApproveMutationVariables = Exact<{
  input: ApprovePaymentBatchFundingInput;
}>;

export type PaymentBatchReviewApproveMutation = {
  __typename?: "Mutation";
  approvePaymentBatchFunding: {
    __typename?: "ApprovePaymentBatchFundingResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type PaymentBatchReviewQueryVariables = Exact<{
  paymentBatchId: Scalars["Int"];
}>;

export type PaymentBatchReviewQuery = {
  __typename?: "Query";
  paymentBatch?: {
    __typename?: "PaymentBatch";
    id: number;
    createdAt: Date;
    paymentTotalAmount: number;
    feesTotalAmount: number;
    totalAmount: number;
    fundingApprovedAt?: Date | null;
    creativesScaleUserPayments?: Array<{
      __typename?: "CreativesScaleUserPayment";
      id: number;
      createdAt: Date;
      amount: number;
      taskId?: number | null;
      creativesScaleUserId: number;
      creativesScaleUser: {
        __typename?: "CreativesScaleUser";
        id: number;
        user: { __typename?: "User"; id: number; fullName: string; email: string };
      };
    }> | null;
  } | null;
};

export type PublisherDetailsSecondaryNavQueryVariables = Exact<{
  publisherId: Scalars["Int"];
}>;

export type PublisherDetailsSecondaryNavQuery = {
  __typename?: "Query";
  publisher?: { __typename?: "Publisher"; id: number; name: string } | null;
};

export type AdminPublisherEmailsQueryVariables = Exact<{
  publisherId: Scalars["Int"];
}>;

export type AdminPublisherEmailsQuery = {
  __typename?: "Query";
  publisher?: {
    __typename?: "Publisher";
    id: number;
    emailMessages?: {
      __typename?: "EmailMessageConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "EmailMessage";
        id: number;
        createdAt: Date;
        sentAt?: Date | null;
        toName: string;
        toAddress: string;
        fromName: string;
        fromAddress: string;
        subject: string;
      }>;
    } | null;
  } | null;
};

export type UpdatePublisherLinkLocationCategoriesMutationVariables = Exact<{
  input: UpdateLinkLocationCategoriesInput;
}>;

export type UpdatePublisherLinkLocationCategoriesMutation = {
  __typename?: "Mutation";
  updateLinkLocationCategories: {
    __typename?: "UpdateLinkLocationCategoriesResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type AdminPublisherLinkLocationsQueryVariables = Exact<{
  publisherId: Scalars["Int"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<PublisherLinkLocationSort>;
}>;

export type AdminPublisherLinkLocationsQuery = {
  __typename?: "Query";
  publisherLinkLocations: {
    __typename?: "PublisherLinkLocationConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "PublisherLinkLocation";
      id: number;
      linkLocation?: {
        __typename?: "LinkLocation";
        id: number;
        domain: string;
        domainAuthority: number;
        authorityScore?: number | null;
        monthlyTraffic?: number | null;
        internalQuality?: number | null;
        cost?: number | null;
        status: { __typename?: "LinkLocationStatus"; id: string; name: string };
        linkLocationCategories: Array<{ __typename?: "LinkLocationCategory"; id: number; categoryId: number }>;
      } | null;
    }>;
  };
};

export type AdminPublisherOverviewQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type AdminPublisherOverviewQuery = {
  __typename?: "Query";
  publisher?: {
    __typename?: "Publisher";
    id: number;
    name: string;
    contactEmail?: string | null;
    paymentMethod?: string | null;
    paymentMethodId?: string | null;
    isSlowPartner?: boolean | null;
    instructions?: string | null;
    isAutoPayEnabled?: boolean | null;
    isInvoiceRequired?: boolean | null;
    createdByUser?: { __typename?: "User"; id: number; fullName: string } | null;
  } | null;
};

export type AdminPublisherOverview_UpdatePublisherMutationVariables = Exact<{
  input: UpdatePublisherInput;
}>;

export type AdminPublisherOverview_UpdatePublisherMutation = {
  __typename?: "Mutation";
  updatePublisher: {
    __typename?: "UpdatePublisherResponse";
    ok: boolean;
    publisher?: {
      __typename?: "Publisher";
      id: number;
      name: string;
      contactEmail?: string | null;
      paymentMethod?: string | null;
      paymentMethodId?: string | null;
      isSlowPartner?: boolean | null;
      instructions?: string | null;
    } | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type PaymentBatchReviewApprovePayPalMutationVariables = Exact<{
  input: ApprovePaymentBatchFundingPaypalInput;
}>;

export type PaymentBatchReviewApprovePayPalMutation = {
  __typename?: "Mutation";
  approvePaymentBatchFundingPaypal: {
    __typename?: "ApprovePaymentBatchFundingPaypalResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type PublisherPaymentBatchReviewQueryVariables = Exact<{
  paymentBatchId: Scalars["Int"];
}>;

export type PublisherPaymentBatchReviewQuery = {
  __typename?: "Query";
  paymentBatch?: {
    __typename?: "PaymentBatch";
    id: number;
    createdAt: Date;
    paymentTotalAmount: number;
    feesTotalAmount: number;
    totalAmount: number;
    fundingApprovedAt?: Date | null;
    publisherPayments?: Array<{
      __typename?: "PublisherPayment";
      id: number;
      amount: number;
      orderId?: number | null;
      processor?: string | null;
      processorReferenceId?: string | null;
      publisher?: { __typename?: "Publisher"; id: number; name: string } | null;
      status?: { __typename?: "PublisherPaymentStatus"; id: string } | null;
    }> | null;
  } | null;
};

export type AutoPublisherPaymentsQueryVariables = Exact<{
  processorReferenceId?: InputMaybe<Scalars["String"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  checkPublisherAutoPayEnabled?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AutoPublisherPaymentsQuery = {
  __typename?: "Query";
  publisherPayments: {
    __typename?: "PublisherPaymentConnection";
    nodes: Array<{
      __typename?: "PublisherPayment";
      id: number;
      amount: number;
      orderId?: number | null;
      publisherId?: number | null;
      processor?: string | null;
      processorReferenceId?: string | null;
      publisher?: {
        __typename?: "Publisher";
        id: number;
        name: string;
        paymentMethod?: string | null;
        paymentMethodId?: string | null;
      } | null;
      status?: { __typename?: "PublisherPaymentStatus"; id: string } | null;
    }>;
  };
};

export type UpdatePublisherPaymentMutationVariables = Exact<{
  input: UpdatePublisherPaymentInput;
}>;

export type UpdatePublisherPaymentMutation = {
  __typename?: "Mutation";
  updatePublisherPayment: {
    __typename?: "UpdatePublisherPaymentResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type AdminPublisherMetricQueryVariables = Exact<{
  publisherId: Scalars["Int"];
}>;

export type AdminPublisherMetricQuery = {
  __typename?: "Query";
  publisher?: {
    __typename?: "Publisher";
    id: number;
    paidAmount30days: number;
    paidAmountTotal: number;
    publishedArticles7days: { __typename?: "OrderConnection"; totalCount: number };
    publishedArticles30days: { __typename?: "OrderConnection"; totalCount: number };
    publishedArticlesTotal: { __typename?: "OrderConnection"; totalCount: number };
  } | null;
};

export type RequestedOrderFragment = {
  __typename?: "Order";
  id: number;
  targetResultUrl?: string | null;
  topic?: string | null;
  status: { __typename?: "OrderStatus"; id: string; name: string };
  publisherPayment?: {
    __typename?: "PublisherPayment";
    id: number;
    processor?: string | null;
    processorReferenceId?: string | null;
  } | null;
  publisherPaymentStatus: { __typename?: "OrderBillingStatus"; id: string; name: string };
  targetLinkLocation?: { __typename?: "LinkLocation"; id: number; cost?: number | null } | null;
};

export type PublishedOrderFragment = {
  __typename?: "Order";
  id: number;
  completedAt?: Date | null;
  resultUrl?: string | null;
  topic?: string | null;
  status: { __typename?: "OrderStatus"; id: string; name: string };
  publisherPayment?: {
    __typename?: "PublisherPayment";
    id: number;
    processor?: string | null;
    processorReferenceId?: string | null;
  } | null;
  publisherPaymentStatus: { __typename?: "OrderBillingStatus"; id: string; name: string };
  linkLocation?: { __typename?: "LinkLocation"; id: number; cost?: number | null } | null;
};

export type AdminPublishedOrdersQueryVariables = Exact<{
  publisherId: Scalars["Int"];
  requestLimit?: InputMaybe<Scalars["Int"]>;
  requestOffset?: InputMaybe<Scalars["Int"]>;
  requestSort?: InputMaybe<OrdersSort>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
}>;

export type AdminPublishedOrdersQuery = {
  __typename?: "Query";
  publisher?: {
    __typename?: "Publisher";
    requestedOrders: {
      __typename?: "OrderConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "Order";
        id: number;
        targetResultUrl?: string | null;
        topic?: string | null;
        status: { __typename?: "OrderStatus"; id: string; name: string };
        publisherPayment?: {
          __typename?: "PublisherPayment";
          id: number;
          processor?: string | null;
          processorReferenceId?: string | null;
        } | null;
        publisherPaymentStatus: { __typename?: "OrderBillingStatus"; id: string; name: string };
        targetLinkLocation?: { __typename?: "LinkLocation"; id: number; cost?: number | null } | null;
      }>;
    };
    publishedOrders: {
      __typename?: "OrderConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "Order";
        id: number;
        completedAt?: Date | null;
        resultUrl?: string | null;
        topic?: string | null;
        status: { __typename?: "OrderStatus"; id: string; name: string };
        publisherPayment?: {
          __typename?: "PublisherPayment";
          id: number;
          processor?: string | null;
          processorReferenceId?: string | null;
        } | null;
        publisherPaymentStatus: { __typename?: "OrderBillingStatus"; id: string; name: string };
        linkLocation?: { __typename?: "LinkLocation"; id: number; cost?: number | null } | null;
      }>;
    };
  } | null;
};

export type PublisherUsersQueryVariables = Exact<{
  id: Scalars["Int"];
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PublisherUsersQuery = {
  __typename?: "Query";
  publisher?: {
    __typename?: "Publisher";
    id: number;
    publisherUsers: {
      __typename?: "PublisherUserConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "PublisherUser";
        createdAt: Date;
        user: { __typename?: "User"; id: number; fullName: string; email: string };
      }>;
    };
  } | null;
};

export type AdminPublishers_PublisherFragment = {
  __typename?: "Publisher";
  id: number;
  name: string;
  contactEmail?: string | null;
  createdAt: Date;
  publisherLinkLocations: { __typename?: "PublisherLinkLocationConnection"; totalCount: number };
};

export type PublishersQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
}>;

export type PublishersQuery = {
  __typename?: "Query";
  publishers: {
    __typename?: "PublisherConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Publisher";
      id: number;
      name: string;
      contactEmail?: string | null;
      createdAt: Date;
      publisherLinkLocations: { __typename?: "PublisherLinkLocationConnection"; totalCount: number };
    }>;
  };
};

export type AdminReports_PublicationManagementQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  treatReportingSmartsResultUrlAsNull?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AdminReports_PublicationManagementQuery = {
  __typename?: "Query";
  adminReports: {
    __typename?: "AdminReports";
    publicationManagement: {
      __typename?: "AdminReportPublicationManagement";
      ordersNeedingResultUrl: {
        __typename?: "OrderConnection";
        totalCount: number;
        nodes: Array<{
          __typename?: "Order";
          id: number;
          startDate: Date;
          completedAt?: Date | null;
          topic?: string | null;
          partnerProduct: {
            __typename?: "PartnerProduct";
            id: number;
            product: { __typename?: "Product"; id: string; name: string };
          };
          customer: {
            __typename?: "Customer";
            id: number;
            name: string;
            partner: { __typename?: "Partner"; id: number; name: string };
          };
          orderContentItems: Array<{
            __typename?: "OrderContentItem";
            id: number;
            contentItemDelivery: { __typename?: "ContentItemDelivery"; publicUrl: string };
          }>;
        }>;
      };
    };
  };
};

export type SitePostDetailQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type SitePostDetailQuery = {
  __typename?: "Query";
  sitePost?: {
    __typename?: "SitePost";
    id: number;
    title: string;
    content: string;
    createdAt: Date;
    siteId?: string | null;
    category?: { __typename?: "Category"; id: number } | null;
    addedByUser: { __typename?: "User"; id: number; fullName: string };
    status: { __typename?: "SitePostStatus"; id: string };
  } | null;
};

export type UpdateSitePostMutationVariables = Exact<{
  input: UpdateSitePostInput;
}>;

export type UpdateSitePostMutation = {
  __typename?: "Mutation";
  updateSitePost: { __typename?: "UpdateSitePostResponse"; sitePost: { __typename?: "SitePost"; id: number } };
};

export type SitePostsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<SitePostsSort>;
  search?: InputMaybe<Scalars["String"]>;
  siteIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type SitePostsQuery = {
  __typename?: "Query";
  sitePosts: {
    __typename?: "SitePostConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "SitePost";
      id: number;
      siteId?: string | null;
      title: string;
      createdAt: Date;
      taskId?: number | null;
      status: { __typename?: "SitePostStatus"; id: string; name: string };
      site?: { __typename?: "Site"; id: string } | null;
    }>;
  };
};

export type SitesQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
}>;

export type SitesQuery = {
  __typename?: "Query";
  sites: {
    __typename?: "SiteConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Site";
      id: string;
      lastPublishedAt?: Date | null;
      category?: { __typename?: "Category"; id: number; name: string } | null;
      deployStatus: { __typename?: "SiteDeployStatus"; id: string; name: string };
      siteTemplate?: { __typename?: "SiteTemplate"; id: number; name: string } | null;
    }>;
  };
};

export type UpdateTasksEditMutationVariables = Exact<{
  input: UpdateRcmWriterTasksInput;
}>;

export type UpdateTasksEditMutation = {
  __typename?: "Mutation";
  updateRcmWriterTasks: { __typename?: "UpdateRcmWriterTasksResponse"; ok: boolean };
};

export type AdminTasks_TaskFragment = {
  __typename?: "Task";
  id: number;
  availableAt: Date;
  createdAt: Date;
  completedAt?: Date | null;
  assignedAt?: Date | null;
  isAssignedToViewer: boolean;
  keyword?: string | null;
  status: { __typename?: "TaskStatus"; id: string; name: string };
  assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
  taskType: { __typename?: "TaskType"; id: string; name: string };
  customer: { __typename?: "Customer"; id: number; name: string };
  vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
  order: { __typename?: "Order"; id: number };
  product: { __typename?: "Product"; id: string; name: string };
};

export type AdminTasksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  customerIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  vendorIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  assignedUserIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  assignedAt?: InputMaybe<DateQuery>;
  search?: InputMaybe<Scalars["String"]>;
}>;

export type AdminTasksQuery = {
  __typename?: "Query";
  tasks: {
    __typename?: "TaskConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Task";
      id: number;
      availableAt: Date;
      createdAt: Date;
      completedAt?: Date | null;
      assignedAt?: Date | null;
      isAssignedToViewer: boolean;
      keyword?: string | null;
      status: { __typename?: "TaskStatus"; id: string; name: string };
      assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
      taskType: { __typename?: "TaskType"; id: string; name: string };
      customer: { __typename?: "Customer"; id: number; name: string };
      vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
      order: { __typename?: "Order"; id: number };
      product: { __typename?: "Product"; id: string; name: string };
    }>;
  };
};

export type AdminTasks_UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskInput;
}>;

export type AdminTasks_UpdateTaskMutation = {
  __typename?: "Mutation";
  updateTask: { __typename?: "UpdateTaskResponse"; task: { __typename?: "Task"; id: number } };
};

export type AdminUsers_UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type AdminUsers_UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser: { __typename?: "UpdateUserResponse"; user?: { __typename?: "User"; id: number } | null };
};

export type AdminUsers_UserFragment = {
  __typename?: "User";
  id: number;
  fullName: string;
  email: string;
  isActive: boolean;
  isAdmin: boolean;
};

export type AdminUsersQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AdminUsersQuery = {
  __typename?: "Query";
  users: {
    __typename?: "UserConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "User";
      id: number;
      fullName: string;
      email: string;
      isActive: boolean;
      isAdmin: boolean;
    }>;
  };
};

export type AdminVendorDetailsSecondaryNavQueryVariables = Exact<{
  vendorId: Scalars["Int"];
}>;

export type AdminVendorDetailsSecondaryNavQuery = {
  __typename?: "Query";
  vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
};

export type PartnerInvoiceDetailsQueryVariables = Exact<{
  partnerInvoiceId: Scalars["Int"];
}>;

export type PartnerInvoiceDetailsQuery = {
  __typename?: "Query";
  partnerInvoice?: {
    __typename?: "PartnerInvoice";
    id: number;
    createdAt: Date;
    totalChargesAmount: number;
    totalPaymentsAmount: number;
    totalRefundsAmount: number;
    totalDueAmount: number;
    status?: { __typename?: "PartnerInvoiceStatus"; id: string; name: string; description: string } | null;
    partner: { __typename?: "Partner"; id: number; name: string; billingEmail?: string | null };
    partnerInvoicePayments: {
      __typename?: "PartnerInvoicePaymentConnection";
      nodes: Array<{
        __typename?: "PartnerInvoicePayment";
        id: number;
        createdAt: Date;
        amount: number;
        errorMessage?: string | null;
        paymentMethodDescription: string;
        status?: { __typename?: "PartnerInvoicePaymentStatus"; id: string; name: string; description: string } | null;
      }>;
    };
    partnerInvoiceCharges: {
      __typename?: "PartnerInvoiceChargeConnection";
      nodes: Array<{
        __typename?: "PartnerInvoiceCharge";
        id: number;
        createdAt: Date;
        typeId: string;
        amount: number;
        customer?: { __typename?: "Customer"; id: number; name: string } | null;
        status?: { __typename?: "PartnerInvoiceChargeStatus"; id: string; name: string; description: string } | null;
        order?: {
          __typename?: "Order";
          id: number;
          startDate: Date;
          activeOrderContentStrategy?: {
            __typename?: "OrderContentStrategy";
            id: number;
            topic?: string | null;
          } | null;
          partnerProduct: {
            __typename?: "PartnerProduct";
            id: number;
            product: { __typename?: "Product"; id: string; name: string };
          };
        } | null;
      }>;
    };
    partnerInvoiceRefunds: {
      __typename?: "PartnerInvoiceRefundConnection";
      nodes: Array<{
        __typename?: "PartnerInvoiceRefund";
        id: number;
        createdAt: Date;
        amount: number;
        reason: string;
        status?: { __typename?: "PartnerInvoicePaymentStatus"; id: string; name: string; description: string } | null;
      }>;
    };
  } | null;
};

export type PartnerInvoice_PayPartnerInvoiceMutationVariables = Exact<{
  input: PayPartnerInvoiceInput;
}>;

export type PartnerInvoice_PayPartnerInvoiceMutation = {
  __typename?: "Mutation";
  payPartnerInvoice: {
    __typename?: "PayPartnerInvoiceResponse";
    ok: boolean;
    wasPaymentSuccessful?: boolean | null;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type BillingOneTimePaymentReviewQueryVariables = Exact<{
  partnerId: Scalars["Int"];
  orderIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
}>;

export type BillingOneTimePaymentReviewQuery = {
  __typename?: "Query";
  orders: {
    __typename?: "OrderConnection";
    nodes: Array<{
      __typename?: "Order";
      id: number;
      startDate: Date;
      totalWholesaleAmount: number;
      partnerProduct: { __typename?: "PartnerProduct"; id: number; name: string };
      customer: { __typename?: "Customer"; id: number; name: string };
      activeOrderContentStrategy?: { __typename?: "OrderContentStrategy"; id: number; topic?: string | null } | null;
    }>;
  };
};

export type BillingOneTimePaymentQueryVariables = Exact<{
  partnerId: Scalars["Int"];
  startDate?: InputMaybe<DateQuery>;
}>;

export type BillingOneTimePaymentQuery = {
  __typename?: "Query";
  partner?: {
    __typename?: "Partner";
    id: number;
    name: string;
    billableOrders: {
      __typename?: "OrderConnection";
      nodes: Array<{
        __typename?: "Order";
        id: number;
        startDate: Date;
        totalWholesaleAmount: number;
        customer: { __typename?: "Customer"; id: number; name: string };
        partnerProduct: {
          __typename?: "PartnerProduct";
          id: number;
          product: { __typename?: "Product"; id: string; name: string };
        };
      }>;
    };
  } | null;
};

export type BillingOneTimePayment_CreatePartnerInvoiceMutationVariables = Exact<{
  input: CreatePartnerInvoiceInput;
}>;

export type BillingOneTimePayment_CreatePartnerInvoiceMutation = {
  __typename?: "Mutation";
  createPartnerInvoice: {
    __typename?: "CreatePartnerInvoiceResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
    partnerInvoice?: { __typename?: "PartnerInvoice"; id: number } | null;
  };
};

export type BillingOneTimePayment_PayPartnerInvoiceMutationVariables = Exact<{
  input: PayPartnerInvoiceInput;
}>;

export type BillingOneTimePayment_PayPartnerInvoiceMutation = {
  __typename?: "Mutation";
  payPartnerInvoice: {
    __typename?: "PayPartnerInvoiceResponse";
    ok: boolean;
    wasPaymentSuccessful?: boolean | null;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type BillingAutomaticPaymentsQueryVariables = Exact<{
  partnerId: Scalars["Int"];
}>;

export type BillingAutomaticPaymentsQuery = {
  __typename?: "Query";
  partner?: {
    __typename?: "Partner";
    id: number;
    name: string;
    isAutopayEnabled: boolean;
    autopayPartnerPaymentMethodId?: number | null;
    enableAutopayInformation?: {
      __typename?: "EnableAutopayInformation";
      dueImmediatelyAmount?: number | null;
      nextBillingDate?: Date | null;
      nextBillingAmount?: number | null;
    } | null;
  } | null;
};

export type Billing_PartnerBillingContactFragment = {
  __typename?: "PartnerBillingContact";
  id: number;
  firstName: string;
  lastName: string;
  email: string;
};

export type PartnerBillingContactsQueryVariables = Exact<{
  partnerId: Scalars["Int"];
}>;

export type PartnerBillingContactsQuery = {
  __typename?: "Query";
  partner?: {
    __typename?: "Partner";
    id: number;
    partnerBillingContacts: {
      __typename?: "PartnerBillingContactConnection";
      nodes: Array<{
        __typename?: "PartnerBillingContact";
        id: number;
        firstName: string;
        lastName: string;
        email: string;
      }>;
    };
  } | null;
};

export type BillingOverview_PartnerInvoiceFragment = {
  __typename?: "PartnerInvoice";
  id: number;
  createdAt: Date;
  totalPaymentsAmount: number;
  totalChargesAmount: number;
  status?: { __typename?: "PartnerInvoiceStatus"; id: string; name: string; description: string } | null;
};

export type BillingOverviewQueryVariables = Exact<{
  partnerId: Scalars["Int"];
}>;

export type BillingOverviewQuery = {
  __typename?: "Query";
  partner?: {
    __typename?: "Partner";
    id: number;
    name: string;
    isUpfrontPaymentRequired: boolean;
    isAutopayEnabled: boolean;
    isBillingEnforced: boolean;
    partnerInvoices: {
      __typename?: "PartnerInvoiceConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "PartnerInvoice";
        id: number;
        createdAt: Date;
        totalPaymentsAmount: number;
        totalChargesAmount: number;
        status?: { __typename?: "PartnerInvoiceStatus"; id: string; name: string; description: string } | null;
      }>;
    };
  } | null;
};

export type DeletePartnerBillingContactMutationVariables = Exact<{
  input: DeletePartnerBillingContactInput;
}>;

export type DeletePartnerBillingContactMutation = {
  __typename?: "Mutation";
  deletePartnerBillingContact: {
    __typename?: "DeletePartnerBillingContactResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type Billing_DeletePartnerPaymentMethodMutationVariables = Exact<{
  input: DeletePartnerPaymentMethodInput;
}>;

export type Billing_DeletePartnerPaymentMethodMutation = {
  __typename?: "Mutation";
  deletePartnerPaymentMethod: {
    __typename?: "DeletePartnerPaymentMethodResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type BillingPartnersQueryVariables = Exact<{ [key: string]: never }>;

export type BillingPartnersQuery = {
  __typename?: "Query";
  partners: { __typename?: "PartnerConnection"; nodes: Array<{ __typename?: "Partner"; id: number; name: string }> };
};

export type Billing_PartnerPaymentMethodCardFragment = {
  __typename?: "PartnerPaymentMethodCard";
  brand: string;
  brandDisplayName: string;
  expirationMonth: number;
  expirationYear: number;
  last4Digits: string;
};

export type Billing_PartnerPaymentMethodFragment = {
  __typename?: "PartnerPaymentMethod";
  id: number;
  createdAt: Date;
  type: string;
  partnerPaymentMethodCard?: {
    __typename?: "PartnerPaymentMethodCard";
    brand: string;
    brandDisplayName: string;
    expirationMonth: number;
    expirationYear: number;
    last4Digits: string;
  } | null;
};

export type PartnerPaymentMethodsQueryVariables = Exact<{
  partnerId: Scalars["Int"];
}>;

export type PartnerPaymentMethodsQuery = {
  __typename?: "Query";
  partner?: {
    __typename?: "Partner";
    id: number;
    name: string;
    partnerPaymentMethods: {
      __typename?: "PartnerPaymentMethodConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "PartnerPaymentMethod";
        id: number;
        createdAt: Date;
        type: string;
        partnerPaymentMethodCard?: {
          __typename?: "PartnerPaymentMethodCard";
          brand: string;
          brandDisplayName: string;
          expirationMonth: number;
          expirationYear: number;
          last4Digits: string;
        } | null;
      }>;
    };
  } | null;
};

export type RecordManualPaymentMutationVariables = Exact<{
  input: RecordPartnerInvoicePaymentInput;
}>;

export type RecordManualPaymentMutation = {
  __typename?: "Mutation";
  recordPartnerInvoicePayment: {
    __typename?: "RecordPartnerInvoicePaymentResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type Billing_UpdatePartnerMutationVariables = Exact<{
  input: UpdatePartnerInput;
}>;

export type Billing_UpdatePartnerMutation = {
  __typename?: "Mutation";
  updatePartner: { __typename?: "UpdatePartnerResponse"; partner?: { __typename?: "Partner"; id: number } | null };
};

export type SaveClientBillingConfigMutationVariables = Exact<{
  input: SaveCustomerBillingConfigInput;
}>;

export type SaveClientBillingConfigMutation = {
  __typename?: "Mutation";
  saveCustomerBillingConfig: {
    __typename?: "SaveCustomerBillingConfigResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type ClientDetailsBillingConfigQueryVariables = Exact<{
  customerId: Scalars["Int"];
}>;

export type ClientDetailsBillingConfigQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    customerBillingConfig?: {
      __typename?: "CustomerBillingConfig";
      id: number;
      isBillingEnforced: boolean;
      isUpfrontPaymentRequired: boolean;
    } | null;
  } | null;
};

export type CampaignInvoiceQueryVariables = Exact<{
  invoiceId: Scalars["Int"];
  customerId: Scalars["Int"];
}>;

export type CampaignInvoiceQuery = {
  __typename?: "Query";
  customerCampaignInvoice?: {
    __typename?: "CustomerCampaignInvoice";
    id: number;
    invoiceDate: Date;
    invoiceData: {
      __typename?: "CustomerCampaignInvoiceData";
      totalChargesAmount: number;
      totalDue: number;
      totalPaymentsAmount: number;
      invoiceCharges: Array<{
        __typename?: "CustomerCampaignInvoiceCharge";
        id: number;
        amount: number;
        monthIndex: number;
        status: { __typename?: "CustomerCampaignInvoiceChargeStatus"; id: string; name: string; description: string };
        customerCampaign: { __typename?: "CustomerCampaign"; id: number; name: string };
      }>;
      invoicePayments: Array<{
        __typename?: "CustomerCampaignInvoicePayment";
        id: number;
        createdAt: Date;
        amount: number;
        processor?: string | null;
        processorReferenceId?: string | null;
        status: { __typename?: "CustomerCampaignInvoicePaymentStatus"; id: string; name: string; description: string };
      }>;
    };
  } | null;
  customer?: {
    __typename?: "Customer";
    id: number;
    name: string;
    status: { __typename?: "CustomerStatus"; id: string };
    partner: { __typename?: "Partner"; emailLogoFile?: { __typename?: "File"; id: string; url: string } | null };
  } | null;
};

export type DeleteCustomerCampaignInvoiceMutationVariables = Exact<{
  input: DeleteCustomerCampaignInvoiceInput;
}>;

export type DeleteCustomerCampaignInvoiceMutation = {
  __typename?: "Mutation";
  deleteCustomerCampaignInvoice: {
    __typename?: "DeleteCustomerCampaignInvoiceResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type ClientDetailsClientCampaignFragment = {
  __typename?: "CustomerCampaign";
  id: number;
  name: string;
  startsAt: Date;
  endsAt: Date;
  amount: number;
  isCurrent: boolean;
  campaignCategory: { __typename?: "CampaignCategory"; id: string; name: string };
};

export type ClientDetailsClientCampaignsQueryVariables = Exact<{
  clientId: Scalars["Int"];
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
}>;

export type ClientDetailsClientCampaignsQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    status: { __typename?: "CustomerStatus"; id: string; name: string };
    customerCampaigns: {
      __typename?: "CustomerCampaignConnection";
      nodes: Array<{
        __typename?: "CustomerCampaign";
        id: number;
        name: string;
        startsAt: Date;
        endsAt: Date;
        amount: number;
        isCurrent: boolean;
        campaignCategory: { __typename?: "CampaignCategory"; id: string; name: string };
      }>;
    };
  } | null;
};

export type DeleteClientCampaignMutationVariables = Exact<{
  input: DeleteCustomerCampaignInput;
}>;

export type DeleteClientCampaignMutation = {
  __typename?: "Mutation";
  deleteCustomerCampaign: { __typename?: "DeleteCustomerCampaignResponse"; isDeleted: boolean };
};

export type ClientDetailsEmailsQueryVariables = Exact<{
  customerId: Scalars["Int"];
}>;

export type ClientDetailsEmailsQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    emailMessages?: {
      __typename?: "EmailMessageConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "EmailMessage";
        id: number;
        createdAt: Date;
        sentAt?: Date | null;
        toName: string;
        toAddress: string;
        fromName: string;
        fromAddress: string;
        subject: string;
      }>;
    } | null;
  } | null;
};

export type ClientDetailsInvoiceCreateQueryVariables = Exact<{
  customerId: Scalars["Int"];
  startDate?: InputMaybe<DateQuery>;
}>;

export type ClientDetailsInvoiceCreateQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    billableOrders: {
      __typename?: "OrderConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "Order";
        id: number;
        startDate: Date;
        totalRetailAmount: number;
        partnerProduct: { __typename?: "PartnerProduct"; id: number; name: string };
      }>;
    };
  } | null;
};

export type CreateCustomerInvoiceMutationVariables = Exact<{
  input: CreateCustomerInvoiceInput;
}>;

export type CreateCustomerInvoiceMutation = {
  __typename?: "Mutation";
  createCustomerInvoice: {
    __typename?: "CreateCustomerInvoiceResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type ClientDetailsInvoiceQueryVariables = Exact<{
  invoiceId: Scalars["Int"];
}>;

export type ClientDetailsInvoiceQuery = {
  __typename?: "Query";
  customerInvoice?: {
    __typename?: "CustomerInvoice";
    id: number;
    invoiceDate: Date;
    invoiceData: {
      __typename?: "InvoiceData";
      totalChargesAmount: number;
      totalDue: number;
      totalPaymentsAmount: number;
      invoiceCharges: Array<{
        __typename?: "CustomerInvoiceCharge";
        id: number;
        amount: number;
        status: { __typename?: "CustomerInvoiceChargeStatus"; id: string; name: string; description: string };
        order: {
          __typename?: "Order";
          id: number;
          startDate: Date;
          customerBillingStatus: { __typename?: "OrderBillingStatus"; id: string; name: string; description: string };
        };
      }>;
      invoicePayments: Array<{
        __typename?: "CustomerInvoicePayment";
        id: number;
        createdAt: Date;
        amount: number;
        processor?: string | null;
        processorReferenceId?: string | null;
        status: { __typename?: "CustomerInvoicePaymentStatus"; id: string; name: string; description: string };
      }>;
    };
    customer: {
      __typename?: "Customer";
      id: number;
      name: string;
      customerBillingConfig?: { __typename?: "CustomerBillingConfig"; isBillingEnforced: boolean } | null;
      partner: {
        __typename?: "Partner";
        id: number;
        name: string;
        reportingLogoFile?: { __typename?: "File"; id: string; url: string } | null;
      };
    };
  } | null;
};

export type ClientDetailsInvoice_CustomerInvoiceFragment = {
  __typename?: "CustomerInvoice";
  id: number;
  customerId: number;
  invoiceDate: Date;
  status: { __typename?: "CustomerInvoiceStatus"; id: string; name: string; description: string };
  invoiceData: { __typename?: "InvoiceData"; totalChargesAmount: number; totalDue: number };
};

export type ClientDetailsInvoicesQueryVariables = Exact<{
  customerId: Scalars["Int"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  statusIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  sort?: InputMaybe<CustomerInvoiceSort>;
}>;

export type ClientDetailsInvoicesQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    customerInvoices?: {
      __typename?: "CustomerInvoiceConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "CustomerInvoice";
        id: number;
        customerId: number;
        invoiceDate: Date;
        status: { __typename?: "CustomerInvoiceStatus"; id: string; name: string; description: string };
        invoiceData: { __typename?: "InvoiceData"; totalChargesAmount: number; totalDue: number };
      }>;
    } | null;
  } | null;
};

export type RecordCustomerInvoiceManualPaymentMutationVariables = Exact<{
  input: RecordCustomerInvoicePaymentInput;
}>;

export type RecordCustomerInvoiceManualPaymentMutation = {
  __typename?: "Mutation";
  recordCustomerInvoicePayment: {
    __typename?: "RecordCustomerInvoicePaymentResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type UpdateClientKeywordDetailsMutationVariables = Exact<{
  input: UpdateCustomerKeywordInput;
}>;

export type UpdateClientKeywordDetailsMutation = {
  __typename?: "Mutation";
  updateCustomerKeyword: {
    __typename?: "UpdateCustomerKeywordResponse";
    customerKeyword?: { __typename?: "CustomerKeyword"; id: number } | null;
  };
};

export type ClientDetailsKeywordQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type ClientDetailsKeywordQuery = {
  __typename?: "Query";
  customerKeyword?: {
    __typename?: "CustomerKeyword";
    id: number;
    name: string;
    location?: string | null;
    country?: string | null;
    landingPageUrl?: string | null;
    isActive: boolean;
    isPrimary: boolean;
  } | null;
};

export type CustomerKeywordDetailsFragment = {
  __typename?: "CustomerKeyword";
  id: number;
  name: string;
  location?: string | null;
  country?: string | null;
  landingPageUrl?: string | null;
  instructions?: string | null;
  customerId: number;
  isActive: boolean;
  isPrimary: boolean;
  isTrackingRank: boolean;
  isTrackingMapRank: boolean;
  startRank?: { __typename?: "CustomerKeywordRanking"; id: number; rank: number; createdAt: Date } | null;
  latestRank?: { __typename?: "CustomerKeywordRanking"; id: number; rank: number } | null;
};

export type CustomerKeywordsQueryVariables = Exact<{
  customerId: Scalars["Int"];
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  searchEngine: Scalars["String"];
  isTrackingMapRank?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CustomerKeywordsQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    hasMapRanking: boolean;
    customerKeywords: {
      __typename?: "CustomerKeywordConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "CustomerKeyword";
        id: number;
        name: string;
        location?: string | null;
        country?: string | null;
        landingPageUrl?: string | null;
        instructions?: string | null;
        customerId: number;
        isActive: boolean;
        isPrimary: boolean;
        isTrackingRank: boolean;
        isTrackingMapRank: boolean;
        startRank?: { __typename?: "CustomerKeywordRanking"; id: number; rank: number; createdAt: Date } | null;
        latestRank?: { __typename?: "CustomerKeywordRanking"; id: number; rank: number } | null;
      }>;
    };
  } | null;
};

export type KeywordRankingsQueryVariables = Exact<{
  customerKeywordId: Scalars["Int"];
  createdAt?: InputMaybe<DateQuery>;
  searchEngine: Scalars["String"];
}>;

export type KeywordRankingsQuery = {
  __typename?: "Query";
  customerKeywordRankings: {
    __typename?: "CustomerKeywordRankingConnection";
    nodes: Array<{ __typename?: "CustomerKeywordRanking"; id: number; createdAt: Date; rank: number }>;
  };
};

export type UpdateCustomerKeywordMutationVariables = Exact<{
  input: UpdateCustomerKeywordInput;
}>;

export type UpdateCustomerKeywordMutation = {
  __typename?: "Mutation";
  updateCustomerKeyword: {
    __typename?: "UpdateCustomerKeywordResponse";
    customerKeyword?: {
      __typename?: "CustomerKeyword";
      id: number;
      name: string;
      location?: string | null;
      landingPageUrl?: string | null;
      instructions?: string | null;
      customerId: number;
      isActive: boolean;
      isPrimary: boolean;
      isTrackingRank: boolean;
    } | null;
  };
};

export type LocationReviewFragment = {
  __typename?: "GoogleReview";
  reviewId?: string | null;
  starRating?: string | null;
  comment?: string | null;
  createTime?: Date | null;
  reviewer?: { __typename?: "Reviewer"; displayName?: string | null; profilePhotoUrl?: string | null } | null;
  reviewReply?: { __typename?: "ReviewReply"; comment?: string | null } | null;
};

export type GetClientLocationGoogleReviewsQueryVariables = Exact<{
  account: Scalars["String"];
  location: Scalars["String"];
  pageToken?: InputMaybe<Scalars["String"]>;
}>;

export type GetClientLocationGoogleReviewsQuery = {
  __typename?: "Query";
  getGoogleReviews: {
    __typename?: "GoogleReviewRespose";
    nextPageToken?: string | null;
    totalReviewCount?: number | null;
    averageRating?: number | null;
    reviews?: Array<{
      __typename?: "GoogleReview";
      reviewId?: string | null;
      starRating?: string | null;
      comment?: string | null;
      createTime?: Date | null;
      reviewer?: { __typename?: "Reviewer"; displayName?: string | null; profilePhotoUrl?: string | null } | null;
      reviewReply?: { __typename?: "ReviewReply"; comment?: string | null } | null;
    }> | null;
  };
};

export type GetClientGeminiReplyCommentQueryVariables = Exact<{
  comment: Scalars["String"];
  keywords: Scalars["String"];
}>;

export type GetClientGeminiReplyCommentQuery = {
  __typename?: "Query";
  geminiReplyComment: { __typename?: "ReplyComment"; reply: string };
};

export type ClientDetailsLocationsInReviewQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type ClientDetailsLocationsInReviewQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    googleAccountId?: string | null;
    customerBusinessListingSpecs: {
      __typename?: "CustomerBusinessListingSpecConnection";
      nodes: Array<{
        __typename?: "CustomerBusinessListingSpec";
        id: number;
        address: string;
        city: string;
        googleLocationId?: string | null;
      }>;
    };
  } | null;
};

export type LoginGoogleClientReviewMutationVariables = Exact<{ [key: string]: never }>;

export type LoginGoogleClientReviewMutation = {
  __typename?: "Mutation";
  loginGoogleService: { __typename?: "LoginGoogleResponse"; ok: boolean; url?: string | null };
};

export type LoginGoogleCallbackClientReviewMutationVariables = Exact<{
  input?: InputMaybe<LoginGoogleCallbackInput>;
}>;

export type LoginGoogleCallbackClientReviewMutation = {
  __typename?: "Mutation";
  loginGoogleCallback: {
    __typename?: "LoginGoogleCallbackResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type GoogleReviewReplyClientReviewMutationVariables = Exact<{
  input: GoogleReviewReplyInput;
}>;

export type GoogleReviewReplyClientReviewMutation = {
  __typename?: "Mutation";
  googleReviewReply: {
    __typename?: "GoogleReviewReplyResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type UpdateCustomerBusinessListingSpecMutationVariables = Exact<{
  input: UpdateCustomerBusinessListingSpecInput;
}>;

export type UpdateCustomerBusinessListingSpecMutation = {
  __typename?: "Mutation";
  updateCustomerBusinessListingSpec: {
    __typename?: "UpdateCustomerBusinessListingSpecResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
    customerBusinessListingSpec?: { __typename?: "CustomerBusinessListingSpec"; id: number; isListed: boolean } | null;
  };
};

export type CreateCustomerBusinessListingSpecMutationVariables = Exact<{
  input: CreateCustomerBusinessListingSpecInput;
}>;

export type CreateCustomerBusinessListingSpecMutation = {
  __typename?: "Mutation";
  createCustomerBusinessListingSpec: {
    __typename?: "CreateCustomerBusinessListingSpecResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type SyncUberallLocationMutationVariables = Exact<{
  input: SyncUberallLocationInput;
}>;

export type SyncUberallLocationMutation = {
  __typename?: "Mutation";
  syncUberallLocation: {
    __typename?: "SyncUberallLocationResponse";
    ok?: boolean | null;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type ClientDetailsLocationsQueryVariables = Exact<{
  id: Scalars["Int"];
  campaignCategoryId: Scalars["String"];
}>;

export type ClientDetailsLocationsQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    currentCustomerCampaign?: { __typename?: "CustomerCampaign"; id: number } | null;
    customerBusinessListingSpecs: {
      __typename?: "CustomerBusinessListingSpecConnection";
      nodes: Array<{
        __typename?: "CustomerBusinessListingSpec";
        id: number;
        address: string;
        city: string;
        isListed: boolean;
      }>;
    };
  } | null;
};

export type ClientDetailsLocationQueryVariables = Exact<{
  id: Scalars["Int"];
  customerId: Scalars["Int"];
}>;

export type ClientDetailsLocationQuery = {
  __typename?: "Query";
  customer?: { __typename?: "Customer"; id: number; uberallBusinessId?: number | null } | null;
  customerBusinessListingSpec?: {
    __typename?: "CustomerBusinessListingSpec";
    id: number;
    hasPhysicalStorefront: boolean;
    name?: string | null;
    email?: string | null;
    address: string;
    city: string;
    stateOrProvince: string;
    zipPostalCode: string;
    country: string;
    phoneNumber: string;
    contactName?: string | null;
    isListed: boolean;
    isReviewEnabled: boolean;
    addressDisplay: boolean;
    uberallCategoryId?: number | null;
    errorMessage?: string | null;
    uberallLocationId?: number | null;
    uberallSyncNeeded?: boolean | null;
  } | null;
};

export type CreateCustomerConversationMutationVariables = Exact<{
  input: CreateCustomerConversationInput;
}>;

export type CreateCustomerConversationMutation = {
  __typename?: "Mutation";
  createCustomerConversation: {
    __typename?: "CreateCustomerConversationResponse";
    customerConversation: { __typename?: "CustomerConversation"; id: string };
  };
};

export type CustomerDetailConversationsQueryVariables = Exact<{
  customerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  messageLimit?: InputMaybe<Scalars["Int"]>;
  messageOffset?: InputMaybe<Scalars["Int"]>;
}>;

export type CustomerDetailConversationsQuery = {
  __typename?: "Query";
  customerConversations: {
    __typename?: "CustomerConversationConnection";
    totalCount: number;
    pageInfo?: { __typename?: "PageInfo"; hasNextPage: boolean; hasPreviousPage: boolean } | null;
    nodes: Array<{
      __typename?: "CustomerConversation";
      id: string;
      subject: string;
      createdAt: Date;
      helpRequestedAt?: Date | null;
      customerMessages: {
        __typename?: "CustomerMessageConnection";
        nodes: Array<{ __typename?: "CustomerMessage"; id: number; createdAt: Date; isFromCustomer: boolean }>;
      };
    }>;
  };
};

export type CustomerNameQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type CustomerNameQuery = {
  __typename?: "Query";
  customer?: { __typename?: "Customer"; id: number; name: string } | null;
};

export type SaveCustomerBusinessListingSpecMutationVariables = Exact<{
  input: SaveCustomerBusinessListingSpecInput;
}>;

export type SaveCustomerBusinessListingSpecMutation = {
  __typename?: "Mutation";
  saveCustomerBusinessListingSpec: {
    __typename?: "SaveCustomerBusinessListingSpecResponse";
    customerBusinessListingSpec: { __typename?: "CustomerBusinessListingSpec"; id: number };
  };
};

export type CreateCustomerPremiumBusinessListingSpecImageMutationVariables = Exact<{
  input: CreateCustomerPremiumBusinessListingSpecImageInput;
}>;

export type CreateCustomerPremiumBusinessListingSpecImageMutation = {
  __typename?: "Mutation";
  createCustomerPremiumBusinessListingSpecImage: {
    __typename?: "CreateCustomerPremiumBusinessListingSpecImageResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
    customerPremiumBusinessListingSpecImage: {
      __typename?: "CustomerPremiumBusinessListingSpecImage";
      id: number;
      customerId: number;
      imageTypeId: string;
      file: { __typename?: "File"; id: string; name: string; contentType: string; dataUrl: string; size: number };
    };
  };
};

export type DeleteCustomerPremiumBusinessListingSpecImageMutationVariables = Exact<{
  input: DeleteCustomerPremiumBusinessListingSpecImageInput;
}>;

export type DeleteCustomerPremiumBusinessListingSpecImageMutation = {
  __typename?: "Mutation";
  deleteCustomerPremiumBusinessListingSpecImage: {
    __typename?: "DeleteCustomerPremiumBusinessListingSpecImageResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type CustomerDetailsBusinessListingQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type CustomerDetailsBusinessListingQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    customerBusinessListingSpec?: {
      __typename?: "CustomerBusinessListingSpec";
      id: number;
      hasPhysicalStorefront: boolean;
      email?: string | null;
      address: string;
      city: string;
      stateOrProvince: string;
      zipPostalCode: string;
      country: string;
      phoneNumber: string;
      contactName?: string | null;
    } | null;
  } | null;
};

export type CustomerDetailsCompanyImagesQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type CustomerDetailsCompanyImagesQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    hasPremiumListing: boolean;
    logoImage: {
      __typename?: "CustomerPremiumBusinessListingSpecImageConnection";
      nodes: Array<{
        __typename?: "CustomerPremiumBusinessListingSpecImage";
        id: number;
        customerId: number;
        imageTypeId: string;
        file: { __typename?: "File"; id: string; name: string; contentType: string; dataUrl: string; size: number };
      }>;
    };
    storefrontImages: {
      __typename?: "CustomerPremiumBusinessListingSpecImageConnection";
      nodes: Array<{
        __typename?: "CustomerPremiumBusinessListingSpecImage";
        id: number;
        customerId: number;
        imageTypeId: string;
        file: { __typename?: "File"; id: string; name: string; contentType: string; dataUrl: string; size: number };
      }>;
    };
    productImages: {
      __typename?: "CustomerPremiumBusinessListingSpecImageConnection";
      nodes: Array<{
        __typename?: "CustomerPremiumBusinessListingSpecImage";
        id: number;
        customerId: number;
        imageTypeId: string;
        file: { __typename?: "File"; id: string; name: string; contentType: string; dataUrl: string; size: number };
      }>;
    };
  } | null;
};

export type UpdateCustomerMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;

export type UpdateCustomerMutation = {
  __typename?: "Mutation";
  updateCustomer: { __typename?: "UpdateCustomerResponse"; customer?: { __typename?: "Customer"; id: number } | null };
};

export type CustomerDetailsHoursOfOperationItemsQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type CustomerDetailsHoursOfOperationItemsQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    customerHoursOfOperationItems?: Array<{
      __typename?: "CustomerHoursOfOperationItem";
      id: number;
      dayIndex: number;
      isOpen: boolean;
      openAt: string;
      closeAt: string;
    }> | null;
  } | null;
};

export type Overview_CustomerStatusChangeFragment = {
  __typename?: "CustomerStatusChange";
  id: number;
  reason?: string | null;
  effectiveAt: string;
  status: { __typename?: "CustomerStatus"; id: string; name: string };
};

export type CustomerDetailsOverviewQueryVariables = Exact<{
  id: Scalars["Int"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  processedAt?: InputMaybe<DateQuery>;
}>;

export type CustomerDetailsOverviewQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    name: string;
    website: string;
    partnerId: number;
    analyticsReportingInlineFrameUrl?: string | null;
    gbpUrl?: string | null;
    instructions?: string | null;
    partner: { __typename?: "Partner"; id: number; name: string; portal?: string | null };
    accountManagerUser?: { __typename?: "User"; id: number; fullName: string } | null;
    rcmAccountManagerUser?: { __typename?: "User"; id: number; fullName: string } | null;
    status: { __typename?: "CustomerStatus"; id: string; name: string };
    category?: { __typename?: "Category"; id: number; name: string } | null;
    customerStatusChanges: {
      __typename?: "CustomerStatusChangeConnection";
      nodes: Array<{
        __typename?: "CustomerStatusChange";
        id: number;
        reason?: string | null;
        effectiveAt: string;
        status: { __typename?: "CustomerStatus"; id: string; name: string };
      }>;
    };
  } | null;
};

export type SaveCustomerPremiumBusinessListingSpecMutationVariables = Exact<{
  input: SaveCustomerPremiumBusinessListingSpecInput;
}>;

export type SaveCustomerPremiumBusinessListingSpecMutation = {
  __typename?: "Mutation";
  saveCustomerPremiumBusinessListingSpec: {
    __typename?: "SaveCustomerPremiumBusinessListingSpecResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type CustomerDetailsPremiumBusinessListingQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type CustomerDetailsPremiumBusinessListingQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    customerPremiumBusinessListingSpec?: {
      __typename?: "CustomerPremiumBusinessListingSpec";
      id: number;
      yearsInBusiness?: number | null;
      specialHoursOfOperation?: string | null;
      isAlwaysOpen?: boolean | null;
      priceRangeLevel?: number | null;
      shouldReceiveLeadMessages?: boolean | null;
      isWomenOwned?: boolean | null;
      isBlackOwned?: boolean | null;
    } | null;
  } | null;
};

export type SaveCustomerQuestionnaireMutationVariables = Exact<{
  input: SaveCustomerQuestionnaireInput;
}>;

export type SaveCustomerQuestionnaireMutation = {
  __typename?: "Mutation";
  saveCustomerQuestionnaire: {
    __typename?: "SaveCustomerQuestionnaireResponse";
    customerQuestionnaire: { __typename?: "CustomerQuestionnaire"; id: number };
  };
};

export type CustomerDetailsQuestionnaireQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type CustomerDetailsQuestionnaireQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    name: string;
    isBlogFulfilledByRCMWriter: boolean;
    isGuestArticleFulfilledByRCMWriter: boolean;
    customerQuestionnaire?: {
      __typename?: "CustomerQuestionnaire";
      id: number;
      websiteHosting?: string | null;
      websiteCms?: string | null;
      whoCanChangeWebsite?: string | null;
      customerMarketSize?: string | null;
      businessDescription?: string | null;
      productsOffered?: string | null;
      targetKeywords?: string | null;
      preferredVoice?: string | null;
      targetAudience?: string | null;
      competitorWebsites?: string | null;
      contentPreference?: string | null;
      targetInternalLinks?: string | null;
      purposeOfContent?: string | null;
      callToActionUrls?: string | null;
      serviceArea?: string | null;
      contentPublishingPermissions?: string | null;
      isRCMPublishing?: boolean | null;
      isAddImage?: boolean | null;
      completedAt?: Date | null;
    } | null;
  } | null;
};

export type SaveCustomerHoursOfOperationItemsMutationVariables = Exact<{
  input: SaveCustomerHoursOfOperationItemsInput;
}>;

export type SaveCustomerHoursOfOperationItemsMutation = {
  __typename?: "Mutation";
  saveCustomerHoursOfOperationItems: {
    __typename?: "SaveCustomerHoursOfOperationItemsResponse";
    ok?: boolean | null;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type SaveCustomerSiteCredentialMutationVariables = Exact<{
  input: SaveCustomerSiteCredentialInput;
}>;

export type SaveCustomerSiteCredentialMutation = {
  __typename?: "Mutation";
  saveCustomerSiteCredential: {
    __typename?: "SaveCustomerSiteCredentialResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type CustomerSiteCredentialQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type CustomerSiteCredentialQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    customerSiteCredential?: {
      __typename?: "CustomerSiteCredential";
      id: number;
      adminUrl: string;
      user: string;
      password: string;
      instructions?: string | null;
    } | null;
  } | null;
};

export type PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationVariables = Exact<{
  input: GenerateUrlForFileUploadInput;
}>;

export type PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation = {
  __typename?: "Mutation";
  generateUrlForFileUpload: { __typename?: "GenerateUrlForFileUploadResponse"; url: string; fileId: string };
};

export type CustomerCampaignInvoiceChargeFragment = {
  __typename?: "CustomerCampaignInvoiceCharge";
  id: number;
  monthIndex: number;
  statusId: string;
};

export type RoadmapDeleteAmTaskMutationVariables = Exact<{
  input: DeleteAmTaskInput;
}>;

export type RoadmapDeleteAmTaskMutation = {
  __typename?: "Mutation";
  deleteAmTask: {
    __typename?: "DeleteAmTaskResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type RoadmapDeleteOrderMutationVariables = Exact<{
  input: DeleteOrderInput;
}>;

export type RoadmapDeleteOrderMutation = {
  __typename?: "Mutation";
  deleteOrder: { __typename?: "DeleteOrderResponse"; isDeleted: boolean };
};

export type RoadmapAmTaskFragment = {
  __typename?: "AmTask";
  id: number;
  taskType: string;
  completedAt: Date;
  info?: string | null;
};

export type CustomerRoadmapCampaignsQueryVariables = Exact<{
  customerId: Scalars["Int"];
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
}>;

export type CustomerRoadmapCampaignsQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    name: string;
    status: { __typename?: "CustomerStatus"; id: string; name: string };
    partner: { __typename?: "Partner"; id: number; isBillingEnforced: boolean };
    customerCampaigns: {
      __typename?: "CustomerCampaignConnection";
      nodes: Array<{
        __typename?: "CustomerCampaign";
        id: number;
        name: string;
        startsAt: Date;
        endsAt: Date;
        amount: number;
        pausedAt?: Date | null;
        isCurrent: boolean;
        customerId: number;
        customerCampaignInvoiceCharges?: Array<{
          __typename?: "CustomerCampaignInvoiceCharge";
          id: number;
          monthIndex: number;
          statusId: string;
        }> | null;
      }>;
    };
  } | null;
  orders: {
    __typename?: "OrderConnection";
    nodes: Array<{
      __typename?: "Order";
      id: number;
      startDate: Date;
      amount: number;
      canDelete: boolean;
      quantity: number;
      topic?: string | null;
      instructions?: string | null;
      customerCampaignId: number;
      totalRetailAmount: number;
      totalWholesaleAmount: number;
      partnerProduct: { __typename?: "PartnerProduct"; id: number; name: string };
      customerKeyword?: { __typename?: "CustomerKeyword"; id: number; name: string } | null;
      status: { __typename?: "OrderStatus"; id: string; name: string };
      billingStatus: { __typename?: "OrderBillingStatus"; id: string; name: string; description: string };
      orderIssues: Array<{ __typename?: "OrderIssue"; title: string; message: string }>;
    }>;
  };
  amTasks: {
    __typename?: "AmTaskConnection";
    nodes: Array<{ __typename?: "AmTask"; id: number; taskType: string; completedAt: Date; info?: string | null }>;
  };
};

export type RoadmapCustomerCampaignFragment = {
  __typename?: "CustomerCampaign";
  id: number;
  name: string;
  startsAt: Date;
  endsAt: Date;
  amount: number;
  pausedAt?: Date | null;
  isCurrent: boolean;
  customerId: number;
  customerCampaignInvoiceCharges?: Array<{
    __typename?: "CustomerCampaignInvoiceCharge";
    id: number;
    monthIndex: number;
    statusId: string;
  }> | null;
};

export type RoadmapCustomerFragment = {
  __typename?: "Customer";
  id: number;
  name: string;
  status: { __typename?: "CustomerStatus"; id: string; name: string };
  partner: { __typename?: "Partner"; id: number; isBillingEnforced: boolean };
  customerCampaigns: {
    __typename?: "CustomerCampaignConnection";
    nodes: Array<{
      __typename?: "CustomerCampaign";
      id: number;
      name: string;
      startsAt: Date;
      endsAt: Date;
      amount: number;
      pausedAt?: Date | null;
      isCurrent: boolean;
      customerId: number;
      customerCampaignInvoiceCharges?: Array<{
        __typename?: "CustomerCampaignInvoiceCharge";
        id: number;
        monthIndex: number;
        statusId: string;
      }> | null;
    }>;
  };
};

export type RoadmapOrderFragment = {
  __typename?: "Order";
  id: number;
  startDate: Date;
  amount: number;
  canDelete: boolean;
  quantity: number;
  topic?: string | null;
  instructions?: string | null;
  customerCampaignId: number;
  totalRetailAmount: number;
  totalWholesaleAmount: number;
  partnerProduct: { __typename?: "PartnerProduct"; id: number; name: string };
  customerKeyword?: { __typename?: "CustomerKeyword"; id: number; name: string } | null;
  status: { __typename?: "OrderStatus"; id: string; name: string };
  billingStatus: { __typename?: "OrderBillingStatus"; id: string; name: string; description: string };
  orderIssues: Array<{ __typename?: "OrderIssue"; title: string; message: string }>;
};

export type UpdateRoadmapOrderMutationVariables = Exact<{
  input: UpdateOrderInput;
}>;

export type UpdateRoadmapOrderMutation = {
  __typename?: "Mutation";
  updateOrder: {
    __typename?: "UpdateOrderResponse";
    order: {
      __typename?: "Order";
      id: number;
      startDate: Date;
      amount: number;
      canDelete: boolean;
      quantity: number;
      topic?: string | null;
      instructions?: string | null;
      customerCampaignId: number;
      totalRetailAmount: number;
      totalWholesaleAmount: number;
      partnerProduct: { __typename?: "PartnerProduct"; id: number; name: string };
      customerKeyword?: { __typename?: "CustomerKeyword"; id: number; name: string } | null;
      status: { __typename?: "OrderStatus"; id: string; name: string };
      billingStatus: { __typename?: "OrderBillingStatus"; id: string; name: string; description: string };
      orderIssues: Array<{ __typename?: "OrderIssue"; title: string; message: string }>;
    };
  };
};

export type UpdateClientUserMutationVariables = Exact<{
  input: UpdateCustomerUserInput;
}>;

export type UpdateClientUserMutation = {
  __typename?: "Mutation";
  updateCustomerUser: {
    __typename?: "UpdateCustomerUserResponse";
    customerUser: { __typename?: "CustomerUser"; id: number };
  };
};

export type ClientUsersQueryVariables = Exact<{
  id: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CustomerUsersSort>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ClientUsersQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: number;
    customerUsers: {
      __typename?: "CustomerUserConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "CustomerUser";
        id: number;
        customerId: number;
        isActive: boolean;
        createdAt: Date;
        user: { __typename?: "User"; id: number; email: string; fullName: string };
      }>;
    };
  } | null;
};

export type ClientDetailsQueryVariables = Exact<{
  customerId: Scalars["Int"];
}>;

export type ClientDetailsQuery = {
  __typename?: "Query";
  customer?: { __typename?: "Customer"; id: number; name: string; isBillingVisible: boolean } | null;
};

export type CustomersQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  sort?: InputMaybe<CustomersSort>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  partnerIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  campaignCategoryId?: InputMaybe<Scalars["String"]>;
  rcmAccountManagerUserId?: InputMaybe<Scalars["Int"]>;
  partnerPortal?: InputMaybe<Scalars["String"]>;
}>;

export type CustomersQuery = {
  __typename?: "Query";
  customers: {
    __typename?: "CustomerConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Customer";
      id: number;
      name: string;
      website: string;
      createdAt: Date;
      accountManagerUser?: { __typename?: "User"; id: number; fullName: string } | null;
      partner: { __typename?: "Partner"; id: number; name: string };
      status: { __typename?: "CustomerStatus"; id: string; name: string };
      customerQuestionnaire?: {
        __typename?: "CustomerQuestionnaire";
        id: number;
        createdAt: Date;
        completedAt?: Date | null;
      } | null;
      customerCampaigns: {
        __typename?: "CustomerCampaignConnection";
        nodes: Array<{ __typename?: "CustomerCampaign"; endsAt: Date }>;
      };
    }>;
  };
};

export type PartnerAvailableProducts_ProductFragment = {
  __typename?: "Product";
  id: string;
  name: string;
  description?: string | null;
  defaultWholesalePrice: number;
  isSystemManaged: boolean;
  deprecatedAt?: Date | null;
};

export type PartnerAvailableProducts_PartnerAvailableProductFragment = {
  __typename?: "PartnerAvailableProduct";
  id: number;
  productId: string;
  wholesalePrice: number;
  product: { __typename?: "Product"; id: string; name: string };
};

export type PartnerAvailableProducts_PartnerFragment = {
  __typename?: "Partner";
  id: number;
  name: string;
  partnerAvailableProducts: Array<{
    __typename?: "PartnerAvailableProduct";
    id: number;
    productId: string;
    wholesalePrice: number;
    product: { __typename?: "Product"; id: string; name: string };
  }>;
};

export type PartnerAvailableProductsQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type PartnerAvailableProductsQuery = {
  __typename?: "Query";
  products: {
    __typename?: "ProductConnection";
    nodes: Array<{
      __typename?: "Product";
      id: string;
      name: string;
      description?: string | null;
      defaultWholesalePrice: number;
      isSystemManaged: boolean;
      deprecatedAt?: Date | null;
    }>;
  };
  partner?: {
    __typename?: "Partner";
    id: number;
    name: string;
    partnerAvailableProducts: Array<{
      __typename?: "PartnerAvailableProduct";
      id: number;
      productId: string;
      wholesalePrice: number;
      product: { __typename?: "Product"; id: string; name: string };
    }>;
  } | null;
};

export type AddPackageItem_PartnerProductFragment = {
  __typename?: "PartnerProduct";
  id: number;
  name: string;
  currentPartnerProductPrice?: { __typename?: "PartnerProductPrice"; id: number; price: number } | null;
  product: { __typename?: "Product"; id: string; name: string };
};

export type AddPackageItemDataQueryVariables = Exact<{
  partnerId: Scalars["Int"];
}>;

export type AddPackageItemDataQuery = {
  __typename?: "Query";
  partner?: {
    __typename?: "Partner";
    id: number;
    partnerProducts: {
      __typename?: "PartnerProductConnection";
      nodes: Array<{
        __typename?: "PartnerProduct";
        id: number;
        name: string;
        currentPartnerProductPrice?: { __typename?: "PartnerProductPrice"; id: number; price: number } | null;
        product: { __typename?: "Product"; id: string; name: string };
      }>;
    };
  } | null;
};

export type CreatePartnerPackageItemMutationVariables = Exact<{
  input: CreatePartnerPackageItemInput;
}>;

export type CreatePartnerPackageItemMutation = {
  __typename?: "Mutation";
  createPartnerPackageItem: {
    __typename?: "CreatePartnerPackageItemResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
  };
};

export type PartnerPackageDetails_PackageFragment = {
  __typename?: "PartnerPackage";
  id: number;
  partnerId: number;
  name: string;
  isActive: boolean;
  monthlySpend: number;
  months: number;
  partnerPackageItems: Array<{
    __typename?: "PartnerPackageItem";
    id: number;
    unitPrice: number;
    totalPrice: number;
    monthIndex: number;
    quantity: number;
    isRecurring: boolean;
    partnerProduct: { __typename?: "PartnerProduct"; id: number; name: string };
  }>;
};

export type PartnerPackageDetails_PackageItemFragment = {
  __typename?: "PartnerPackageItem";
  id: number;
  unitPrice: number;
  totalPrice: number;
  monthIndex: number;
  quantity: number;
  isRecurring: boolean;
  partnerProduct: { __typename?: "PartnerProduct"; id: number; name: string };
};

export type PartnerPackageDetailsQueryVariables = Exact<{
  partnerPackageId: Scalars["Int"];
  partnerId: Scalars["Int"];
}>;

export type PartnerPackageDetailsQuery = {
  __typename?: "Query";
  partner?: { __typename?: "Partner"; id: number; name: string } | null;
  partnerPackage?: {
    __typename?: "PartnerPackage";
    id: number;
    partnerId: number;
    name: string;
    isActive: boolean;
    monthlySpend: number;
    months: number;
    partnerPackageItems: Array<{
      __typename?: "PartnerPackageItem";
      id: number;
      unitPrice: number;
      totalPrice: number;
      monthIndex: number;
      quantity: number;
      isRecurring: boolean;
      partnerProduct: { __typename?: "PartnerProduct"; id: number; name: string };
    }>;
  } | null;
};

export type UpdatePartnerPackageItemMutationVariables = Exact<{
  input: UpdatePartnerPackageItemInput;
}>;

export type UpdatePartnerPackageItemMutation = {
  __typename?: "Mutation";
  updatePartnerPackageItem: {
    __typename?: "UpdatePartnerPackageItemResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
    partnerPackageItem?: {
      __typename?: "PartnerPackageItem";
      id: number;
      quantity: number;
      unitPrice: number;
      totalPrice: number;
    } | null;
  };
};

export type UpdatePartnerPackageMutationVariables = Exact<{
  input: UpdatePartnerPackageInput;
}>;

export type UpdatePartnerPackageMutation = {
  __typename?: "Mutation";
  updatePartnerPackage: {
    __typename?: "UpdatePartnerPackageResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type PartnerPackages_PackageFragment = {
  __typename?: "PartnerPackage";
  id: number;
  partnerId: number;
  name: string;
  isActive: boolean;
  monthlySpend: number;
  months: number;
  partnerPackageItemCount: number;
};

export type PartnerPackagesQueryVariables = Exact<{
  partnerId: Scalars["Int"];
}>;

export type PartnerPackagesQuery = {
  __typename?: "Query";
  partner?: { __typename?: "Partner"; id: number; name: string } | null;
  partnerPackages: {
    __typename?: "PartnerPackageConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "PartnerPackage";
      id: number;
      partnerId: number;
      name: string;
      isActive: boolean;
      monthlySpend: number;
      months: number;
      partnerPackageItemCount: number;
    }>;
  };
};

export type DeletePartnerProductMutationVariables = Exact<{
  input: DeletePartnerProductInput;
}>;

export type DeletePartnerProductMutation = {
  __typename?: "Mutation";
  deletePartnerProduct: {
    __typename?: "DeletePartnerProductResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type Configuration_PartnerProductsQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  partnerId: Scalars["Int"];
}>;

export type Configuration_PartnerProductsQuery = {
  __typename?: "Query";
  partner?: { __typename?: "Partner"; id: number; name: string } | null;
  partnerProducts: {
    __typename?: "PartnerProductConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "PartnerProduct";
      id: number;
      name: string;
      createdAt: Date;
      activeAt: Date;
      inactiveAt?: Date | null;
      vendorId?: number | null;
      productId: string;
      checklistDefinitionId?: number | null;
      wholesalePrice?: number | null;
      canDelete: boolean;
      currentPartnerProductPrice?: { __typename?: "PartnerProductPrice"; price: number } | null;
      product: {
        __typename?: "Product";
        id: string;
        name: string;
        isKeywordRequired: boolean;
        isChecklistRequired: boolean;
        isVendorRequired: boolean;
        description?: string | null;
        isSystemManaged: boolean;
      };
      checklistDefinition?: { __typename?: "ChecklistDefinition"; id: number; name: string } | null;
      vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
    }>;
  };
};

export type UpdatePartnerUserMutationVariables = Exact<{
  input: UpdatePartnerUserInput;
}>;

export type UpdatePartnerUserMutation = {
  __typename?: "Mutation";
  updatePartnerUser: {
    __typename?: "UpdatePartnerUserResponse";
    partnerUser: { __typename?: "PartnerUser"; id: number };
  };
};

export type PartnerUsers_PartnerUserFragment = {
  __typename?: "PartnerUser";
  id: number;
  isActive: boolean;
  partnerId: number;
  isAdmin: boolean;
  user: { __typename?: "User"; id: number; fullName: string; email: string };
};

export type PartnerUsersQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
  partnerId: Scalars["Int"];
}>;

export type PartnerUsersQuery = {
  __typename?: "Query";
  partner?: {
    __typename?: "Partner";
    id: number;
    name: string;
    partnerUsers: {
      __typename?: "PartnerUserConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "PartnerUser";
        id: number;
        isActive: boolean;
        partnerId: number;
        isAdmin: boolean;
        user: { __typename?: "User"; id: number; fullName: string; email: string };
      }>;
    };
  } | null;
};

export type PartnerConfigurationQueryVariables = Exact<{
  search?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type PartnerConfigurationQuery = {
  __typename?: "Query";
  partners: {
    __typename?: "PartnerConnection";
    nodes: Array<{
      __typename?: "Partner";
      id: number;
      name: string;
      partnerProducts: { __typename?: "PartnerProductConnection"; totalCount: number };
      partnerUsers: { __typename?: "PartnerUserConnection"; totalCount: number };
    }>;
  };
};

export type ConfirmEmailMutationVariables = Exact<{
  input: ConfirmEmailInput;
}>;

export type ConfirmEmailMutation = {
  __typename?: "Mutation";
  confirmEmail: { __typename?: "ConfirmEmailResponse"; ok: boolean };
};

export type KeywordResearchKeywordsQueryVariables = Exact<{
  keywords: Array<Scalars["String"]> | Scalars["String"];
}>;

export type KeywordResearchKeywordsQuery = {
  __typename?: "Query";
  getKeywordsKeywords: {
    __typename?: "KeywordsSuggestionRespose";
    keywordSuggestions?: Array<{
      __typename?: "KeywordSuggestion";
      keyword: string;
      avgMonthlySearches?: number | null;
      competitionIndex?: number | null;
    }> | null;
  };
};

export type KeywordResearchSiteQueryVariables = Exact<{
  domain: Scalars["String"];
}>;

export type KeywordResearchSiteQuery = {
  __typename?: "Query";
  getKeywordsSite: {
    __typename?: "KeywordsSuggestionRespose";
    keywordSuggestions?: Array<{
      __typename?: "KeywordSuggestion";
      keyword: string;
      avgMonthlySearches?: number | null;
      competitionIndex?: number | null;
    }> | null;
  };
};

export type LoginUserMutationVariables = Exact<{
  input: LoginUserInput;
}>;

export type LoginUserMutation = {
  __typename?: "Mutation";
  loginUser: { __typename?: "LoginUserResponse"; isLoggedIn?: boolean | null };
};

export type CustomerConversationsQueryVariables = Exact<{
  customerId?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CustomerConversationsSort>;
  messageLimit?: InputMaybe<Scalars["Int"]>;
  messageOffset?: InputMaybe<Scalars["Int"]>;
  messageSort?: InputMaybe<CustomerMessagesSort>;
  search?: InputMaybe<Scalars["String"]>;
}>;

export type CustomerConversationsQuery = {
  __typename?: "Query";
  customerConversations: {
    __typename?: "CustomerConversationConnection";
    totalCount: number;
    pageInfo?: { __typename?: "PageInfo"; hasNextPage: boolean; hasPreviousPage: boolean } | null;
    nodes: Array<{
      __typename?: "CustomerConversation";
      id: string;
      subject: string;
      createdAt: Date;
      helpRequestedAt?: Date | null;
      customer: {
        __typename?: "Customer";
        id: number;
        name: string;
        partner: { __typename?: "Partner"; id: number; name: string };
      };
      customerMessages: {
        __typename?: "CustomerMessageConnection";
        nodes: Array<{ __typename?: "CustomerMessage"; id: number; createdAt: Date; isFromCustomer: boolean }>;
      };
    }>;
  };
};

export type OrderDetails_OrderContentItemFragment = {
  __typename?: "OrderContentItem";
  id: number;
  createdAt: Date;
  contentItem: { __typename?: "ContentItem"; id: number; title: string; content: string };
};

export type OrderDetailsContentItemQueryVariables = Exact<{
  orderId: Scalars["Int"];
}>;

export type OrderDetailsContentItemQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    id: number;
    orderContentItems: Array<{
      __typename?: "OrderContentItem";
      id: number;
      createdAt: Date;
      contentItem: { __typename?: "ContentItem"; id: number; title: string; content: string };
    }>;
  } | null;
};

export type OrderDetails_ContentStrategyOrdersQueryVariables = Exact<{
  orderId: Scalars["Int"];
}>;

export type OrderDetails_ContentStrategyOrdersQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    id: number;
    partnerProduct: { __typename?: "PartnerProduct"; id: number; product: { __typename?: "Product"; id: string } };
    customerProposal?: {
      __typename?: "CustomerProposal";
      id: number;
      orderContentStrategies: Array<{
        __typename?: "OrderContentStrategy";
        id: number;
        topic?: string | null;
        order?: {
          __typename?: "Order";
          id: number;
          startDate: Date;
          status: { __typename?: "OrderStatus"; id: string; name: string };
          partnerProduct: {
            __typename?: "PartnerProduct";
            id: number;
            name: string;
            product: { __typename?: "Product"; id: string; name: string };
          };
        } | null;
      }>;
    } | null;
  } | null;
};

export type OrderDetails_DeleteOrderContentStrategyMutationVariables = Exact<{
  input: DeleteOrderContentStrategyInput;
}>;

export type OrderDetails_DeleteOrderContentStrategyMutation = {
  __typename?: "Mutation";
  deleteOrderContentStrategy: {
    __typename?: "DeleteOrderContentStrategyResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type OrderDetails_OrderContentStrategyFragment = {
  __typename?: "OrderContentStrategy";
  id: number;
  topic?: string | null;
  webPage?: string | null;
  pageType?: string | null;
  purposeOfContent?: string | null;
  linkTo?: string | null;
  voice?: string | null;
  focusPoints?: string | null;
  sampleContent?: string | null;
  clientProvidedInstructions?: string | null;
  customerKeywordId?: number | null;
  isComplete: boolean;
  orderHaloKeywords: Array<{
    __typename?: "OrderHaloKeyword";
    id: number;
    isProposed: boolean;
    customerKeyword: { __typename?: "CustomerKeyword"; id: number; name: string };
  }>;
};

export type OrderDetailsContentStrategyQueryVariables = Exact<{
  orderId: Scalars["Int"];
}>;

export type OrderDetailsContentStrategyQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    id: number;
    customerId: number;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      product: { __typename?: "Product"; id: string; name: string };
    };
    activeOrderContentStrategy?: {
      __typename?: "OrderContentStrategy";
      id: number;
      topic?: string | null;
      webPage?: string | null;
      pageType?: string | null;
      purposeOfContent?: string | null;
      linkTo?: string | null;
      voice?: string | null;
      focusPoints?: string | null;
      sampleContent?: string | null;
      clientProvidedInstructions?: string | null;
      customerKeywordId?: number | null;
      isComplete: boolean;
      orderHaloKeywords: Array<{
        __typename?: "OrderHaloKeyword";
        id: number;
        isProposed: boolean;
        customerKeyword: { __typename?: "CustomerKeyword"; id: number; name: string };
      }>;
    } | null;
  } | null;
};

export type OrderDetails_UpdateOrderContentStrategyMutationVariables = Exact<{
  input: UpdateOrderContentStrategyInput;
}>;

export type OrderDetails_UpdateOrderContentStrategyMutation = {
  __typename?: "Mutation";
  updateOrderContentStrategy: {
    __typename?: "UpdateOrderContentStrategyResponse";
    ok: boolean;
    error?: {
      __typename?: "ResponseError";
      message: string;
      inputFieldErrors: Array<{ __typename?: "InputFieldError"; fieldName: string; message: string }>;
    } | null;
    orderContentStrategy?: {
      __typename?: "OrderContentStrategy";
      id: number;
      topic?: string | null;
      webPage?: string | null;
      pageType?: string | null;
      purposeOfContent?: string | null;
      linkTo?: string | null;
      voice?: string | null;
      focusPoints?: string | null;
      sampleContent?: string | null;
      clientProvidedInstructions?: string | null;
      customerKeywordId?: number | null;
      isComplete: boolean;
      orderHaloKeywords: Array<{
        __typename?: "OrderHaloKeyword";
        id: number;
        isProposed: boolean;
        customerKeyword: { __typename?: "CustomerKeyword"; id: number; name: string };
      }>;
    } | null;
  };
};

export type OrderDetailsInvoiceQueryVariables = Exact<{
  orderId: Scalars["Int"];
}>;

export type OrderDetailsInvoiceQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    id: number;
    startDate: Date;
    billingStatus: { __typename?: "OrderBillingStatus"; id: string; name: string; description: string };
    customer: {
      __typename?: "Customer";
      id: number;
      partner: { __typename?: "Partner"; id: number; isBillingEnforced: boolean };
    };
    partnerInvoiceCharges: Array<{
      __typename?: "PartnerInvoiceCharge";
      id: number;
      partnerId: number;
      partnerInvoiceId: number;
      amount: number;
      createdAt: Date;
      status?: { __typename?: "PartnerInvoiceChargeStatus"; id: string; name: string; description: string } | null;
    }>;
  } | null;
};

export type OrderDetailsOverviewQueryVariables = Exact<{
  orderId: Scalars["Int"];
}>;

export type OrderDetailsOverviewQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    id: number;
    createdAt: Date;
    customerId: number;
    userId: number;
    startDate: Date;
    topic?: string | null;
    resultUrl?: string | null;
    resultUrlDomainAuthority?: number | null;
    instructions?: string | null;
    targetResultUrl?: string | null;
    status: { __typename?: "OrderStatus"; id: string; name: string };
    customer: { __typename?: "Customer"; id: number; name: string };
    user: { __typename?: "User"; id: number; firstName: string; lastName: string };
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      name: string;
      product: { __typename?: "Product"; id: string; name: string };
    };
    orderIssues: Array<{ __typename?: "OrderIssue"; title: string; message: string }>;
    orderExternalContentSpec?: {
      __typename?: "OrderExternalContentSpec";
      id: number;
      externalOrderId: string;
      vendor: { __typename?: "Vendor"; id: number; name: string };
    } | null;
  } | null;
};

export type ReCreateGuestOutreachMutationVariables = Exact<{
  input: ReCreateGuestOutreachTaskInput;
}>;

export type ReCreateGuestOutreachMutation = {
  __typename?: "Mutation";
  reCreateGuestOutreachTask: {
    __typename?: "ReCreateGuestOutreachTaskResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type OrderDetails_WorkflowFragment = {
  __typename?: "Workflow";
  id: number;
  internalStatus: string;
  type: string;
  status: { __typename?: "WorkflowStatus"; id: string; name: string };
  tasks: {
    __typename?: "TaskConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Task";
      id: number;
      createdAt: Date;
      assignedAt?: Date | null;
      availableAt: Date;
      completedAt?: Date | null;
      taskType: { __typename?: "TaskType"; id: string; name: string; isFulfilledByCustomer: boolean };
      status: { __typename?: "TaskStatus"; id: string; name: string };
      assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
      vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
    }>;
  };
};

export type OrderDetailsWorkflowQueryVariables = Exact<{
  orderId: Scalars["Int"];
  taskIncludeDeleted?: InputMaybe<Scalars["Boolean"]>;
}>;

export type OrderDetailsWorkflowQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    id: number;
    workflow?: {
      __typename?: "Workflow";
      id: number;
      internalStatus: string;
      type: string;
      status: { __typename?: "WorkflowStatus"; id: string; name: string };
      tasks: {
        __typename?: "TaskConnection";
        totalCount: number;
        nodes: Array<{
          __typename?: "Task";
          id: number;
          createdAt: Date;
          assignedAt?: Date | null;
          availableAt: Date;
          completedAt?: Date | null;
          taskType: { __typename?: "TaskType"; id: string; name: string; isFulfilledByCustomer: boolean };
          status: { __typename?: "TaskStatus"; id: string; name: string };
          assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
          vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
        }>;
      };
    } | null;
  } | null;
};

export type OrdersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OrdersSort>;
  search?: InputMaybe<Scalars["String"]>;
  customerIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  productIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type OrdersQuery = {
  __typename?: "Query";
  orders: {
    __typename?: "OrderConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Order";
      id: number;
      topic?: string | null;
      startDate: Date;
      instructions?: string | null;
      billingStatus: { __typename?: "OrderBillingStatus"; id: string; name: string; description: string };
      status: { __typename?: "OrderStatus"; id: string; name: string };
      customer: {
        __typename?: "Customer";
        id: number;
        name: string;
        partner: { __typename?: "Partner"; id: number; name: string; isBillingEnforced: boolean };
      };
      user: { __typename?: "User"; id: number; firstName: string; lastName: string };
      partnerProduct: { __typename?: "PartnerProduct"; id: number; name: string };
      orderIssues: Array<{ __typename?: "OrderIssue"; title: string; message: string }>;
    }>;
  };
};

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;

export type ChangePasswordMutation = {
  __typename?: "Mutation";
  changePassword: {
    __typename?: "ChangePasswordResponse";
    ok: boolean;
    error?: { __typename?: "ResponseError"; message: string } | null;
  };
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser: { __typename?: "UpdateUserResponse"; user?: { __typename?: "User"; id: number } | null };
};

export type UserProfileQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type UserProfileQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    id: number;
    calendarUrl?: string | null;
    title?: string | null;
    emailPreference?: string | null;
    profilePictureFile?: { __typename?: "File"; id: string; name: string; url: string } | null;
  } | null;
};

export type RequestPasswordResetMutationVariables = Exact<{
  input: RequestPasswordResetInput;
}>;

export type RequestPasswordResetMutation = {
  __typename?: "Mutation";
  requestPasswordReset: { __typename?: "RequestPasswordResetResponse"; wasRequested: boolean };
};

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword: { __typename?: "ResetPasswordResponse"; wasReset: boolean };
};

export type LoginUserSsoMutationVariables = Exact<{
  input: LoginUserInput;
}>;

export type LoginUserSsoMutation = {
  __typename?: "Mutation";
  loginUser: { __typename?: "LoginUserResponse"; isLoggedIn?: boolean | null };
};

export type TaskWorkspace_TaskFragment = {
  __typename?: "Task";
  id: number;
  createdAt: Date;
  isAssignedToViewer: boolean;
  isSystemTaskInProgress: boolean;
  completedAt?: Date | null;
  workflowId: number;
  status: { __typename?: "TaskStatus"; id: string; name: string };
  customer: { __typename?: "Customer"; id: number; name: string };
  assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
  taskType: { __typename?: "TaskType"; id: string; name: string };
  vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
  order: {
    __typename?: "Order";
    partnerProduct: { __typename?: "PartnerProduct"; product: { __typename?: "Product"; id: string } };
  };
};

export type TaskWorkspaceQueryVariables = Exact<{
  taskId: Scalars["Int"];
}>;

export type TaskWorkspaceQuery = {
  __typename?: "Query";
  task?: {
    __typename?: "Task";
    id: number;
    createdAt: Date;
    isAssignedToViewer: boolean;
    isSystemTaskInProgress: boolean;
    completedAt?: Date | null;
    workflowId: number;
    status: { __typename?: "TaskStatus"; id: string; name: string };
    customer: { __typename?: "Customer"; id: number; name: string };
    assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
    taskType: { __typename?: "TaskType"; id: string; name: string };
    vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
    order: {
      __typename?: "Order";
      partnerProduct: { __typename?: "PartnerProduct"; product: { __typename?: "Product"; id: string } };
    };
  } | null;
};

export type TaskWorkspace_UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskInput;
}>;

export type TaskWorkspace_UpdateTaskMutation = {
  __typename?: "Mutation";
  updateTask: {
    __typename?: "UpdateTaskResponse";
    task: {
      __typename?: "Task";
      id: number;
      createdAt: Date;
      isAssignedToViewer: boolean;
      isSystemTaskInProgress: boolean;
      completedAt?: Date | null;
      workflowId: number;
      status: { __typename?: "TaskStatus"; id: string; name: string };
      customer: { __typename?: "Customer"; id: number; name: string };
      assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
      taskType: { __typename?: "TaskType"; id: string; name: string };
      vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
      order: {
        __typename?: "Order";
        partnerProduct: { __typename?: "PartnerProduct"; product: { __typename?: "Product"; id: string } };
      };
    };
  };
};

export type TasksTable_TaskFragment = {
  __typename?: "Task";
  id: number;
  availableAt: Date;
  isAssignedToViewer: boolean;
  isSystemTaskInProgress: boolean;
  keyword?: string | null;
  topic?: string | null;
  status: { __typename?: "TaskStatus"; id: string; name: string };
  assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
  taskType: { __typename?: "TaskType"; id: string; name: string };
  customer: {
    __typename?: "Customer";
    id: number;
    name: string;
    partner: { __typename?: "Partner"; id: number; name: string };
  };
  vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
  order: {
    __typename?: "Order";
    id: number;
    partnerProduct: {
      __typename?: "PartnerProduct";
      id: number;
      name: string;
      product: { __typename?: "Product"; id: string };
    };
  };
};

export type TasksQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  customerIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  vendorIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  excludedVendorIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  taskTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  notTaskTypeIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  statusIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
  assignedUserIds?: InputMaybe<Array<Scalars["Int"]> | Scalars["Int"]>;
  productIds?: InputMaybe<Array<Scalars["String"]> | Scalars["String"]>;
}>;

export type TasksQuery = {
  __typename?: "Query";
  tasks: {
    __typename?: "TaskConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Task";
      id: number;
      availableAt: Date;
      isAssignedToViewer: boolean;
      isSystemTaskInProgress: boolean;
      keyword?: string | null;
      topic?: string | null;
      status: { __typename?: "TaskStatus"; id: string; name: string };
      assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
      taskType: { __typename?: "TaskType"; id: string; name: string };
      customer: {
        __typename?: "Customer";
        id: number;
        name: string;
        partner: { __typename?: "Partner"; id: number; name: string };
      };
      vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
      order: {
        __typename?: "Order";
        id: number;
        partnerProduct: {
          __typename?: "PartnerProduct";
          id: number;
          name: string;
          product: { __typename?: "Product"; id: string };
        };
      };
    }>;
  };
};

export type TasksTable_UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskInput;
}>;

export type TasksTable_UpdateTaskMutation = {
  __typename?: "Mutation";
  updateTask: {
    __typename?: "UpdateTaskResponse";
    task: {
      __typename?: "Task";
      id: number;
      availableAt: Date;
      isAssignedToViewer: boolean;
      isSystemTaskInProgress: boolean;
      keyword?: string | null;
      topic?: string | null;
      status: { __typename?: "TaskStatus"; id: string; name: string };
      assignedUser?: { __typename?: "User"; id: number; fullName: string } | null;
      taskType: { __typename?: "TaskType"; id: string; name: string };
      customer: {
        __typename?: "Customer";
        id: number;
        name: string;
        partner: { __typename?: "Partner"; id: number; name: string };
      };
      vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
      order: {
        __typename?: "Order";
        id: number;
        partnerProduct: {
          __typename?: "PartnerProduct";
          id: number;
          name: string;
          product: { __typename?: "Product"; id: string };
        };
      };
    };
  };
};

export type DomainMetricsQueryVariables = Exact<{
  domain: Scalars["String"];
}>;

export type DomainMetricsQuery = {
  __typename?: "Query";
  getDomainMetrics: {
    __typename?: "DomainMetricsRespose";
    domainMetrics?: {
      __typename?: "DomainMetrics";
      authorityScore: number;
      organitcTraffic: number;
      spamScore: number;
      domainAuthority: number;
    } | null;
  };
};

export type TopicGeneratorQueryVariables = Exact<{
  keyword: Scalars["String"];
}>;

export type TopicGeneratorQuery = {
  __typename?: "Query";
  getTopicKeyword: { __typename?: "TopicKeywordResponse"; topicSuggestions: string };
};

export type VendorDetailsOverviewQueryVariables = Exact<{
  id: Scalars["Int"];
}>;

export type VendorDetailsOverviewQuery = {
  __typename?: "Query";
  vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
};

export type UpdateVendorUserMutationVariables = Exact<{
  input: UpdateVendorUserInput;
}>;

export type UpdateVendorUserMutation = {
  __typename?: "Mutation";
  updateVendorUser: {
    __typename?: "UpdateVendorUserResponse";
    vendorUser: { __typename?: "VendorUser"; id: number; userId: number; isActive: boolean };
  };
};

export type VendorUsersQueryVariables = Exact<{
  id: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  includeInactive?: InputMaybe<Scalars["Boolean"]>;
}>;

export type VendorUsersQuery = {
  __typename?: "Query";
  vendor?: {
    __typename?: "Vendor";
    id: number;
    name: string;
    partnerId?: number | null;
    vendorUsers: {
      __typename?: "VendorUserConnection";
      totalCount: number;
      nodes: Array<{
        __typename?: "VendorUser";
        id: number;
        vendorId: number;
        isActive: boolean;
        userId: number;
        user: { __typename?: "User"; id: number; firstName: string; lastName: string; email: string };
        userTaskPermissions: Array<{
          __typename?: "UserTaskPermission";
          id: number;
          productId?: string | null;
          taskTypeId?: string | null;
        }>;
      }>;
    };
  } | null;
};

export type VendorDetailsSecondaryNavQueryVariables = Exact<{
  vendorId: Scalars["Int"];
}>;

export type VendorDetailsSecondaryNavQuery = {
  __typename?: "Query";
  vendor?: { __typename?: "Vendor"; id: number; name: string } | null;
};

export type VendorsQueryVariables = Exact<{
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
}>;

export type VendorsQuery = {
  __typename?: "Query";
  vendors: {
    __typename?: "VendorConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Vendor";
      id: number;
      name: string;
      isExternal: boolean;
      partner?: { __typename?: "Partner"; id: number; name: string } | null;
    }>;
  };
};

export const AddOrderDrawer_PartnerProductFragmentDoc = gql`
  fragment AddOrderDrawer_PartnerProduct on PartnerProduct {
    id
    name
    createdAt
    activeAt
    inactiveAt
    vendorId
    productId
    checklistDefinitionId
    addedByUser {
      id
      firstName
      lastName
    }
    product {
      id
      name
      isKeywordRequired
      isChecklistRequired
      isVendorRequired
      isTopicRequired
      maxOrderQuantity
      isSystemOrdered
    }
    checklistDefinition {
      id
      name
    }
    vendor {
      id
      name
    }
    partnerProductPrices(sort: STARTS_AT_DESC) {
      nodes {
        id
        price
        startsAt
      }
    }
  }
`;
export const AddOrderDrawer_CustomerKeywordFragmentDoc = gql`
  fragment AddOrderDrawer_CustomerKeyword on CustomerKeyword {
    id
    name
    location
  }
`;
export const ProductForm_ProductFragmentDoc = gql`
  fragment ProductForm_Product on Product {
    id
    name
    isChecklistRequired
    isVendorRequired
    isSystemManaged
  }
`;
export const ProductForm_PartnerAvailableProductFragmentDoc = gql`
  fragment ProductForm_PartnerAvailableProduct on PartnerAvailableProduct {
    id
    wholesalePrice
    product {
      ...ProductForm_Product
    }
  }
  ${ProductForm_ProductFragmentDoc}
`;
export const CustomerCampaignInvoiceDetailFragmentDoc = gql`
  fragment CustomerCampaignInvoiceDetail on CustomerCampaignInvoice {
    id
    customerId
    invoiceDate
    status {
      id
      name
      description
    }
    invoiceData {
      totalChargesAmount
      totalDue
    }
    customer {
      id
      name
    }
  }
`;
export const ChecklistDefinitionsListFragmentDoc = gql`
  fragment ChecklistDefinitionsList on ChecklistDefinitionConnection {
    totalCount
    nodes {
      id
      name
    }
  }
`;
export const CustomerCampaignSelector_CustomerCampaignFragmentDoc = gql`
  fragment CustomerCampaignSelector_CustomerCampaign on CustomerCampaign {
    id
    name
    startsAt
    endsAt
    amount
    isCurrent
  }
`;
export const CustomerKeywordSelector_KeywordFragmentDoc = gql`
  fragment CustomerKeywordSelector_Keyword on CustomerKeyword {
    id
    displayName
    isActive
    isPrimary
  }
`;
export const CustomerNotes_NoteFragmentDoc = gql`
  fragment CustomerNotes_Note on Note {
    id
    content
    createdAt
    user {
      id
      fullName
    }
  }
`;
export const LinkLocation_LinkLocationConnectionFragmentDoc = gql`
  fragment LinkLocation_LinkLocationConnection on LinkLocationConnection {
    totalCount
    nodes {
      id
      domain
      domainAuthority
      spam
      authorityScore
      internalQuality
      monthlyTraffic
      cost
      lastMetricsUpdatedAt
      publisherLinkLocation {
        id
        publisher {
          id
          name
        }
      }
      status {
        id
        name
      }
      type {
        id
        name
      }
      linkLocationCategories {
        id
        category {
          id
          name
        }
      }
    }
  }
`;
export const PartnerProductDetailPriceFragmentDoc = gql`
  fragment PartnerProductDetailPrice on PartnerProductPrice {
    id
    startsAt
    price
    isCurrent
  }
`;
export const PartnerPackageOptionFragmentDoc = gql`
  fragment PartnerPackageOption on PartnerPackage {
    id
    name
    monthlySpend
    months
  }
`;
export const TaskWorkspaceBusinessListing_OrderFragmentDoc = gql`
  fragment TaskWorkspaceBusinessListing_Order on Order {
    id
    startDate
    partnerProduct {
      id
      name
      product {
        id
        name
      }
    }
    resultUrl
    resultUrlDomainAuthority
  }
`;
export const TaskWorkspaceBusinessListing_TaskFragmentDoc = gql`
  fragment TaskWorkspaceBusinessListing_Task on Task {
    id
    customer {
      id
      name
      website
      instructions
      customerBusinessListingSpec {
        id
        hasPhysicalStorefront
        email
        address
        city
        stateOrProvince
        zipPostalCode
        country
        phoneNumber
        contactName
      }
      customerQuestionnaire {
        id
        businessDescription
      }
      category {
        id
        name
      }
    }
    keyword
    order {
      ...TaskWorkspaceBusinessListing_Order
    }
    vendor {
      id
      name
    }
  }
  ${TaskWorkspaceBusinessListing_OrderFragmentDoc}
`;
export const ChecklistTaskWorkspace_ChecklistItemFragmentDoc = gql`
  fragment ChecklistTaskWorkspace_ChecklistItem on ChecklistItem {
    id
    name
    completedAt
    order
  }
`;
export const ChecklistTaskWorkspace_ChecklistFragmentDoc = gql`
  fragment ChecklistTaskWorkspace_Checklist on Checklist {
    id
    name
    checklistItems {
      ...ChecklistTaskWorkspace_ChecklistItem
    }
  }
  ${ChecklistTaskWorkspace_ChecklistItemFragmentDoc}
`;
export const ChecklistTaskWorkspace_OrderFragmentDoc = gql`
  fragment ChecklistTaskWorkspace_Order on Order {
    id
    startDate
    partnerProduct {
      id
      name
    }
    resultUrl
    resultUrlDomainAuthority
    instructions
  }
`;
export const ChecklistTaskWorkspace_TaskFragmentDoc = gql`
  fragment ChecklistTaskWorkspace_Task on Task {
    id
    customer {
      id
      name
      instructions
    }
    keyword
    checklist {
      ...ChecklistTaskWorkspace_Checklist
    }
    order {
      ...ChecklistTaskWorkspace_Order
    }
    vendor {
      id
      name
    }
  }
  ${ChecklistTaskWorkspace_ChecklistFragmentDoc}
  ${ChecklistTaskWorkspace_OrderFragmentDoc}
`;
export const ContentFulfillmentDecisionTaskWorkspace_CustomerFragmentDoc = gql`
  fragment ContentFulfillmentDecisionTaskWorkspace_Customer on Customer {
    id
    name
    website
    category {
      id
      name
    }
    partner {
      id
      name
    }
    customerQuestionnaire {
      id
      businessDescription
      callToActionUrls
    }
    instructions
  }
`;
export const ContentFulfillmentDecisionTaskWorkspace_OrderFragmentDoc = gql`
  fragment ContentFulfillmentDecisionTaskWorkspace_Order on Order {
    id
    startDate
    topic
    totalWholesaleAmount
    partnerProduct {
      id
      product {
        id
        name
      }
    }
    activeOrderContentStrategy {
      id
      linkTo
    }
  }
`;
export const ContentFulfillmentDecisionTaskWorkspace_TaskFragmentDoc = gql`
  fragment ContentFulfillmentDecisionTaskWorkspace_Task on Task {
    id
    keyword
    customer {
      ...ContentFulfillmentDecisionTaskWorkspace_Customer
    }
    order {
      ...ContentFulfillmentDecisionTaskWorkspace_Order
    }
  }
  ${ContentFulfillmentDecisionTaskWorkspace_CustomerFragmentDoc}
  ${ContentFulfillmentDecisionTaskWorkspace_OrderFragmentDoc}
`;
export const ContentIdeationTaskWorkspace_ContentIdeationFragmentDoc = gql`
  fragment ContentIdeationTaskWorkspace_ContentIdeation on ContentIdeation {
    id
    idea
  }
`;
export const ContentIdeationTaskWorkspace_TaskFragmentDoc = gql`
  fragment ContentIdeationTaskWorkspace_Task on Task {
    id
    customer {
      id
      name
      instructions
    }
    keyword
    contentIdeation {
      ...ContentIdeationTaskWorkspace_ContentIdeation
    }
    order {
      id
      topic
      instructions
      partnerProduct {
        id
        product {
          id
          name
        }
      }
    }
  }
  ${ContentIdeationTaskWorkspace_ContentIdeationFragmentDoc}
`;
export const ContentReviewTaskWorkspace_ContentItemFragmentDoc = gql`
  fragment ContentReviewTaskWorkspace_ContentItem on ContentItem {
    id
    title
    content
    metaTitle
    metaDescription
    thumbnailFile {
      id
      name
      url
    }
    feedbackContentItems {
      id
      rating
      feedback
    }
  }
`;
export const Comment_ReviewTaskFragmentDoc = gql`
  fragment Comment_ReviewTask on Task {
    id
    createdAt
    completedAt
    reviewStatus {
      id
      name
    }
    taskTypeId
    reviewComment
  }
`;
export const ContentReviewTaskWorkspace_TaskFragmentDoc = gql`
  fragment ContentReviewTaskWorkspace_Task on Task {
    id
    customer {
      id
      name
      website
      instructions
      customerQuestionnaire {
        id
        isAddImage
      }
    }
    keyword
    landingPageUrl
    reviewTargetTask {
      id
      contentItem {
        ...ContentReviewTaskWorkspace_ContentItem
      }
      reviewTasks {
        nodes {
          ...Comment_ReviewTask
        }
      }
      assignedUser {
        id
        fullName
        email
      }
    }
    reviewStatus {
      id
    }
    order {
      id
      topic
      partnerProduct {
        id
        product {
          id
          name
        }
      }
    }
  }
  ${ContentReviewTaskWorkspace_ContentItemFragmentDoc}
  ${Comment_ReviewTaskFragmentDoc}
`;
export const ContentStrategyV2_OrderContentStrategyFragmentDoc = gql`
  fragment ContentStrategyV2_OrderContentStrategy on OrderContentStrategy {
    id
    topic
    webPage
    pageType
    purposeOfContent
    linkTo
    voice
    focusPoints
    sampleContent
    clientProvidedInstructions
    customerKeywordId
    isComplete
    orderContentStrategyReviews {
      id
      createdAt
      resolvedAt
      comments
      createdByUser {
        id
        fullName
      }
    }
    orderHaloKeywords {
      id
      isProposed
      customerKeyword {
        id
        name
      }
    }
    order {
      id
      startDate
      customerId
      instructions
      partnerProduct {
        id
        product {
          id
          name
          minWordCount
          maxWordCount
        }
      }
      customer {
        customerQuestionnaire {
          contentPreference
        }
      }
    }
  }
`;
export const ContentStrategyTaskWorkspace_OrdersFragmentDoc = gql`
  fragment ContentStrategyTaskWorkspace_Orders on Order {
    id
    startDate
    partnerProduct {
      id
      name
      product {
        id
        name
      }
    }
    customerKeyword {
      id
      displayName
    }
    topic
  }
`;
export const ContentStrategyTaskWorkspace_ProposedOrderTopicFragmentDoc = gql`
  fragment ContentStrategyTaskWorkspace_ProposedOrderTopic on ProposedOrderTopic {
    id
    orderId
    topic
    rejectionComment
    customerKeyword {
      id
      displayName
    }
  }
`;
export const ContentTaskWorkspace_ContentItemFragmentDoc = gql`
  fragment ContentTaskWorkspace_ContentItem on ContentItem {
    id
    title
    content
  }
`;
export const ContentTaskWorkspace_TaskFragmentDoc = gql`
  fragment ContentTaskWorkspace_Task on Task {
    id
    customer {
      id
      name
      website
      instructions
    }
    keyword
    landingPageUrl
    contentItem {
      ...ContentTaskWorkspace_ContentItem
    }
    order {
      id
      topic
      partnerProduct {
        id
        product {
          id
          name
        }
      }
    }
    reviewTasks(isFulfilledByCustomer: false) {
      nodes {
        ...Comment_ReviewTask
      }
    }
  }
  ${ContentTaskWorkspace_ContentItemFragmentDoc}
  ${Comment_ReviewTaskFragmentDoc}
`;
export const QaContentSendTaskWorkspace_ContentItemFragmentDoc = gql`
  fragment QaContentSendTaskWorkspace_ContentItem on ContentItem {
    id
    title
    content
    metaTitle
    metaDescription
    thumbnailFile {
      id
      name
      url
    }
  }
`;
export const QaContentSendTaskWorkspace_TaskFragmentDoc = gql`
  fragment QaContentSendTaskWorkspace_Task on Task {
    id
    customer {
      id
      name
      instructions
      customerQuestionnaire {
        id
        isAddImage
      }
    }
    keyword
    reviewTargetTask {
      id
      contentItem {
        ...QaContentSendTaskWorkspace_ContentItem
      }
      reviewTasks {
        nodes {
          ...Comment_ReviewTask
        }
      }
      assignedUser {
        id
        fullName
        email
      }
    }
    reviewStatus {
      id
    }
    order {
      id
      topic
      partnerProduct {
        id
        product {
          id
          name
        }
      }
    }
  }
  ${QaContentSendTaskWorkspace_ContentItemFragmentDoc}
  ${Comment_ReviewTaskFragmentDoc}
`;
export const KeywordResearchTaskWorkspace_CustomerFragmentDoc = gql`
  fragment KeywordResearchTaskWorkspace_Customer on Customer {
    id
    name
    website
    category {
      id
      name
    }
    instructions
    customerQuestionnaire {
      id
      customerMarketSize
      businessDescription
      productsOffered
      targetKeywords
      targetAudience
      competitorWebsites
      serviceArea
    }
  }
`;
export const KeywordResearchTaskWorkspace_OrderFragmentDoc = gql`
  fragment KeywordResearchTaskWorkspace_Order on Order {
    id
    startDate
    partnerProduct {
      id
      product {
        id
        name
      }
    }
  }
`;
export const KeywordResearchTaskWorkspace_ProposedKeywordFragmentDoc = gql`
  fragment KeywordResearchTaskWorkspace_ProposedKeyword on ProposedCustomerKeyword {
    id
    name
    location
    country
    landingPageUrl
    rejectionReason
    isPrimary
  }
`;
export const KeywordResearchTaskWorkspace_TaskFragmentDoc = gql`
  fragment KeywordResearchTaskWorkspace_Task on Task {
    id
    customer {
      ...KeywordResearchTaskWorkspace_Customer
    }
    order {
      ...KeywordResearchTaskWorkspace_Order
    }
    customerKeywordProposal {
      id
      summary
      rejectedProposedKeywords: proposedCustomerKeywords(isRejected: true) {
        ...KeywordResearchTaskWorkspace_ProposedKeyword
      }
      keywords: proposedCustomerKeywords(isRejected: false, sort: ID_DESC) {
        ...KeywordResearchTaskWorkspace_ProposedKeyword
      }
    }
  }
  ${KeywordResearchTaskWorkspace_CustomerFragmentDoc}
  ${KeywordResearchTaskWorkspace_OrderFragmentDoc}
  ${KeywordResearchTaskWorkspace_ProposedKeywordFragmentDoc}
`;
export const LinkBuildTaskWorkspace_OrderFragmentDoc = gql`
  fragment LinkBuildTaskWorkspace_Order on Order {
    id
    startDate
    partnerProduct {
      id
      name
      product {
        id
        name
      }
    }
    instructions
    customerSiteAudit {
      id
      createdAt
    }
  }
`;
export const LinkBuildTaskWorkspace_TaskFragmentDoc = gql`
  fragment LinkBuildTaskWorkspace_Task on Task {
    id
    customer {
      id
      name
      website
      customerBusinessListingSpec {
        id
        address
        city
        stateOrProvince
        zipPostalCode
        phoneNumber
        email
      }
      customerQuestionnaire {
        id
        businessDescription
      }
      category {
        id
        name
      }
      customerKeywords(isPrimary: true) {
        nodes {
          id
          displayName
        }
      }
    }
    order {
      ...LinkBuildTaskWorkspace_Order
    }
  }
  ${LinkBuildTaskWorkspace_OrderFragmentDoc}
`;
export const ReviewTitleMetaDescriptionTaskWorkspace_TitleMetaDescriptionItemFragmentDoc = gql`
  fragment ReviewTitleMetaDescriptionTaskWorkspace_TitleMetaDescriptionItem on TitleMetaDescriptionItem {
    id
    titleMetaDescriptions {
      id
      titleTag
      metaDescription
      url
    }
  }
`;
export const ReviewTitleMetaDescriptionTaskWorkspace_TaskFragmentDoc = gql`
  fragment ReviewTitleMetaDescriptionTaskWorkspace_Task on Task {
    id
    expiresAt
    customer {
      id
      name
      website
    }
    order {
      id
      instructions
    }
    reviewTargetTask {
      id
      titleMetaDescriptionItem {
        ...ReviewTitleMetaDescriptionTaskWorkspace_TitleMetaDescriptionItem
      }
    }
  }
  ${ReviewTitleMetaDescriptionTaskWorkspace_TitleMetaDescriptionItemFragmentDoc}
`;
export const SiteAuditTaskWorkspace_OrderFragmentDoc = gql`
  fragment SiteAuditTaskWorkspace_Order on Order {
    id
    startDate
    partnerProduct {
      id
      name
      product {
        id
        name
      }
    }
    instructions
    customerSiteAudit {
      id
      createdAt
    }
  }
`;
export const SiteAuditTaskWorkspace_TaskFragmentDoc = gql`
  fragment SiteAuditTaskWorkspace_Task on Task {
    id
    customer {
      id
      name
      website
      category {
        id
        name
      }
      customerQuestionnaire {
        id
        customerMarketSize
      }
      instructions
    }
    order {
      ...SiteAuditTaskWorkspace_Order
    }
  }
  ${SiteAuditTaskWorkspace_OrderFragmentDoc}
`;
export const UserTaskPermissionDrawer_ProductTaskTypeFragmentDoc = gql`
  fragment UserTaskPermissionDrawer_ProductTaskType on ProductTaskType {
    id
    product {
      id
      name
    }
    taskType {
      id
      name
    }
  }
`;
export const AuthManager_ViewerUserFragmentDoc = gql`
  fragment AuthManager_ViewerUser on User {
    id
    firstName
    lastName
    fullName
    email
    timezone
    isAdmin
    isSuperAdmin
    isTracking
    googleRefreshToken
    partnerUsers {
      id
      partnerId
      isAdmin
    }
    vendorUsers {
      id
      vendorId
    }
  }
`;
export const AuthManager_ViewerFragmentDoc = gql`
  fragment AuthManager_Viewer on Viewer {
    hasSLPCustomer
    user {
      ...AuthManager_ViewerUser
    }
  }
  ${AuthManager_ViewerUserFragmentDoc}
`;
export const BillingOrdersReview_OrderFragmentDoc = gql`
  fragment BillingOrdersReview_Order on Order {
    id
    partnerProduct {
      id
      name
      product {
        id
        name
      }
    }
    customer {
      id
      name
      status {
        id
        name
      }
      partner {
        id
        name
      }
    }
    startDate
    status {
      id
      name
    }
    orderIssues {
      title
      message
    }
    totalRetailAmount
    totalWholesaleAmount
  }
`;
export const DomainTableFragmentDoc = gql`
  fragment DomainTable on Domain {
    id
    createdAt
    status {
      id
      name
    }
    availabilityLastCheckedAt
    expiresAt
    purchasedAt
    registrar
    registrarId
    purchasePrice
    purchaseError
    canRefreshAvailability
    domainCategories {
      totalCount
      nodes {
        id
        domainId
        categoryId
        category {
          id
          name
        }
      }
    }
    site {
      id
      domainId
    }
  }
`;
export const AdminLocationReviewFragmentDoc = gql`
  fragment AdminLocationReview on GoogleReview {
    reviewId
    reviewer {
      displayName
      profilePhotoUrl
    }
    starRating
    comment
    createTime
    reviewReply {
      comment
    }
  }
`;
export const AdminGuestArticleOutreachTasks_TaskFragmentDoc = gql`
  fragment AdminGuestArticleOutreachTasks_Task on Task {
    id
    status {
      id
      name
    }
    isAssignedToViewer
    availableAt
    createdAt
    completedAt
    assignedAt
    topic
    assignedUser {
      id
      fullName
    }
    customer {
      id
      name
    }
    order {
      id
      startDate
      targetLinkLocation {
        id
        publisherLinkLocation {
          id
          publisher {
            id
            name
            isAutoPayEnabled
          }
        }
      }
    }
    product {
      id
      name
    }
    subTasks(sort: $subTasksSort) {
      nodes {
        id
        completedAt
        taskType {
          id
          name
        }
        status {
          id
          name
        }
      }
    }
    proposalReviewTask: subTasks(taskTypeIds: $proposalTaskTypeIds, sort: $subTasksSort) {
      nodes {
        id
        createdAt
        completedAt
        reviewStatus {
          id
        }
      }
    }
  }
`;
export const AdminPartners_PartnerFragmentDoc = gql`
  fragment AdminPartners_Partner on Partner {
    id
    name
    createdAt
  }
`;
export const RequestedOrderFragmentDoc = gql`
  fragment RequestedOrder on Order {
    id
    targetResultUrl
    topic
    status {
      id
      name
    }
    publisherPayment {
      id
      processor
      processorReferenceId
    }
    publisherPaymentStatus {
      id
      name
    }
    targetLinkLocation {
      id
      cost
    }
  }
`;
export const PublishedOrderFragmentDoc = gql`
  fragment PublishedOrder on Order {
    id
    completedAt
    resultUrl
    topic
    status {
      id
      name
    }
    publisherPayment {
      id
      processor
      processorReferenceId
    }
    publisherPaymentStatus {
      id
      name
    }
    linkLocation {
      id
      cost
    }
  }
`;
export const AdminPublishers_PublisherFragmentDoc = gql`
  fragment AdminPublishers_Publisher on Publisher {
    id
    name
    contactEmail
    createdAt
    publisherLinkLocations {
      totalCount
    }
  }
`;
export const AdminTasks_TaskFragmentDoc = gql`
  fragment AdminTasks_Task on Task {
    id
    status {
      id
      name
    }
    availableAt
    createdAt
    completedAt
    assignedAt
    isAssignedToViewer
    assignedUser {
      id
      fullName
    }
    taskType {
      id
      name
    }
    keyword
    customer {
      id
      name
    }
    vendor {
      id
      name
    }
    order {
      id
    }
    product {
      id
      name
    }
  }
`;
export const AdminUsers_UserFragmentDoc = gql`
  fragment AdminUsers_User on User {
    id
    fullName
    email
    isActive
    isAdmin
  }
`;
export const Billing_PartnerBillingContactFragmentDoc = gql`
  fragment Billing_PartnerBillingContact on PartnerBillingContact {
    id
    firstName
    lastName
    email
  }
`;
export const BillingOverview_PartnerInvoiceFragmentDoc = gql`
  fragment BillingOverview_PartnerInvoice on PartnerInvoice {
    id
    createdAt
    status {
      id
      name
      description
    }
    totalPaymentsAmount
    totalChargesAmount
  }
`;
export const Billing_PartnerPaymentMethodCardFragmentDoc = gql`
  fragment Billing_PartnerPaymentMethodCard on PartnerPaymentMethodCard {
    brand
    brandDisplayName
    expirationMonth
    expirationYear
    last4Digits
  }
`;
export const Billing_PartnerPaymentMethodFragmentDoc = gql`
  fragment Billing_PartnerPaymentMethod on PartnerPaymentMethod {
    id
    createdAt
    type
    partnerPaymentMethodCard {
      ...Billing_PartnerPaymentMethodCard
    }
  }
  ${Billing_PartnerPaymentMethodCardFragmentDoc}
`;
export const ClientDetailsClientCampaignFragmentDoc = gql`
  fragment ClientDetailsClientCampaign on CustomerCampaign {
    id
    name
    startsAt
    endsAt
    amount
    isCurrent
    campaignCategory {
      id
      name
    }
  }
`;
export const ClientDetailsInvoice_CustomerInvoiceFragmentDoc = gql`
  fragment ClientDetailsInvoice_CustomerInvoice on CustomerInvoice {
    id
    customerId
    invoiceDate
    status {
      id
      name
      description
    }
    invoiceData {
      totalChargesAmount
      totalDue
    }
  }
`;
export const CustomerKeywordDetailsFragmentDoc = gql`
  fragment CustomerKeywordDetails on CustomerKeyword {
    id
    name
    location
    country
    landingPageUrl
    instructions
    customerId
    isActive
    isPrimary
    isTrackingRank
    isTrackingMapRank
    startRank(searchEngine: $searchEngine) {
      id
      rank
      createdAt
    }
    latestRank(searchEngine: $searchEngine) {
      id
      rank
    }
  }
`;
export const LocationReviewFragmentDoc = gql`
  fragment LocationReview on GoogleReview {
    reviewId
    reviewer {
      displayName
      profilePhotoUrl
    }
    starRating
    comment
    createTime
    reviewReply {
      comment
    }
  }
`;
export const Overview_CustomerStatusChangeFragmentDoc = gql`
  fragment Overview_CustomerStatusChange on CustomerStatusChange {
    id
    status {
      id
      name
    }
    reason
    effectiveAt
  }
`;
export const CustomerCampaignInvoiceChargeFragmentDoc = gql`
  fragment CustomerCampaignInvoiceCharge on CustomerCampaignInvoiceCharge {
    id
    monthIndex
    statusId
  }
`;
export const RoadmapAmTaskFragmentDoc = gql`
  fragment RoadmapAmTask on AmTask {
    id
    taskType
    completedAt
    info
  }
`;
export const RoadmapCustomerCampaignFragmentDoc = gql`
  fragment RoadmapCustomerCampaign on CustomerCampaign {
    id
    name
    startsAt
    endsAt
    amount
    pausedAt
    isCurrent
    customerId
    customerCampaignInvoiceCharges {
      id
      monthIndex
      statusId
    }
  }
`;
export const RoadmapCustomerFragmentDoc = gql`
  fragment RoadmapCustomer on Customer {
    id
    name
    status {
      id
      name
    }
    partner {
      id
      isBillingEnforced
    }
    customerCampaigns(campaignCategoryId: $campaignCategoryId) {
      nodes {
        ...RoadmapCustomerCampaign
      }
    }
  }
  ${RoadmapCustomerCampaignFragmentDoc}
`;
export const RoadmapOrderFragmentDoc = gql`
  fragment RoadmapOrder on Order {
    id
    startDate
    amount
    canDelete
    quantity
    topic
    instructions
    customerCampaignId
    partnerProduct {
      id
      name
    }
    customerKeyword {
      id
      name
    }
    status {
      id
      name
    }
    billingStatus {
      id
      name
      description
    }
    orderIssues {
      title
      message
    }
    totalRetailAmount
    totalWholesaleAmount
  }
`;
export const PartnerAvailableProducts_ProductFragmentDoc = gql`
  fragment PartnerAvailableProducts_Product on Product {
    id
    name
    description
    defaultWholesalePrice
    isSystemManaged
    deprecatedAt
  }
`;
export const PartnerAvailableProducts_PartnerAvailableProductFragmentDoc = gql`
  fragment PartnerAvailableProducts_PartnerAvailableProduct on PartnerAvailableProduct {
    id
    productId
    wholesalePrice
    product {
      id
      name
    }
  }
`;
export const PartnerAvailableProducts_PartnerFragmentDoc = gql`
  fragment PartnerAvailableProducts_Partner on Partner {
    id
    name
    partnerAvailableProducts {
      ...PartnerAvailableProducts_PartnerAvailableProduct
    }
  }
  ${PartnerAvailableProducts_PartnerAvailableProductFragmentDoc}
`;
export const AddPackageItem_PartnerProductFragmentDoc = gql`
  fragment AddPackageItem_PartnerProduct on PartnerProduct {
    id
    name
    currentPartnerProductPrice {
      id
      price
    }
    product {
      id
      name
    }
  }
`;
export const PartnerPackageDetails_PackageItemFragmentDoc = gql`
  fragment PartnerPackageDetails_PackageItem on PartnerPackageItem {
    id
    partnerProduct {
      id
      name
    }
    unitPrice
    totalPrice
    monthIndex
    quantity
    isRecurring
  }
`;
export const PartnerPackageDetails_PackageFragmentDoc = gql`
  fragment PartnerPackageDetails_Package on PartnerPackage {
    id
    partnerId
    name
    isActive
    monthlySpend
    months
    partnerPackageItems {
      ...PartnerPackageDetails_PackageItem
    }
  }
  ${PartnerPackageDetails_PackageItemFragmentDoc}
`;
export const PartnerPackages_PackageFragmentDoc = gql`
  fragment PartnerPackages_Package on PartnerPackage {
    id
    partnerId
    name
    isActive
    monthlySpend
    months
    partnerPackageItemCount
  }
`;
export const PartnerUsers_PartnerUserFragmentDoc = gql`
  fragment PartnerUsers_PartnerUser on PartnerUser {
    id
    isActive
    partnerId
    isAdmin
    user {
      id
      fullName
      email
    }
  }
`;
export const OrderDetails_OrderContentItemFragmentDoc = gql`
  fragment OrderDetails_OrderContentItem on OrderContentItem {
    id
    createdAt
    contentItem {
      id
      title
      content
    }
  }
`;
export const OrderDetails_OrderContentStrategyFragmentDoc = gql`
  fragment OrderDetails_OrderContentStrategy on OrderContentStrategy {
    id
    topic
    webPage
    pageType
    purposeOfContent
    linkTo
    voice
    focusPoints
    sampleContent
    clientProvidedInstructions
    customerKeywordId
    isComplete
    orderHaloKeywords {
      id
      isProposed
      customerKeyword {
        id
        name
      }
    }
  }
`;
export const OrderDetails_WorkflowFragmentDoc = gql`
  fragment OrderDetails_Workflow on Workflow {
    id
    status {
      id
      name
    }
    internalStatus
    type
    tasks(includeDeleted: $taskIncludeDeleted) {
      totalCount
      nodes {
        id
        createdAt
        assignedAt
        availableAt
        completedAt
        taskType {
          id
          name
          isFulfilledByCustomer
        }
        status {
          id
          name
        }
        assignedUser {
          id
          fullName
        }
        vendor {
          id
          name
        }
      }
    }
  }
`;
export const TaskWorkspace_TaskFragmentDoc = gql`
  fragment TaskWorkspace_Task on Task {
    id
    createdAt
    isAssignedToViewer
    isSystemTaskInProgress
    completedAt
    status {
      id
      name
    }
    customer {
      id
      name
    }
    assignedUser {
      id
      fullName
    }
    taskType {
      id
      name
    }
    vendor {
      id
      name
    }
    workflowId
    order {
      partnerProduct {
        product {
          id
        }
      }
    }
  }
`;
export const TasksTable_TaskFragmentDoc = gql`
  fragment TasksTable_Task on Task {
    id
    status {
      id
      name
    }
    availableAt
    isAssignedToViewer
    isSystemTaskInProgress
    assignedUser {
      id
      fullName
    }
    taskType {
      id
      name
    }
    keyword
    customer {
      id
      name
      partner {
        id
        name
      }
    }
    vendor {
      id
      name
    }
    order {
      id
      partnerProduct {
        id
        name
        product {
          id
        }
      }
    }
    topic
  }
`;
export const CreateAdminUserDocument = gql`
  mutation CreateAdminUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export type CreateAdminUserMutationFn = ApolloReactCommon.MutationFunction<
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables
>;

/**
 * __useCreateAdminUserMutation__
 *
 * To run a mutation, you first call `useCreateAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminUserMutation, { data, loading, error }] = useCreateAdminUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdminUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAdminUserMutation, CreateAdminUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateAdminUserMutation, CreateAdminUserMutationVariables>(
    CreateAdminUserDocument,
    options
  );
}
export type CreateAdminUserMutationHookResult = ReturnType<typeof useCreateAdminUserMutation>;
export type CreateAdminUserMutationResult = ApolloReactCommon.MutationResult<CreateAdminUserMutation>;
export type CreateAdminUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateAdminUserMutation,
  CreateAdminUserMutationVariables
>;
export const CreateAmTaskDocument = gql`
  mutation CreateAmTask($input: CreateAmTaskInput!) {
    createAmTask(input: $input) {
      ok
      amTask {
        id
      }
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type CreateAmTaskMutationFn = ApolloReactCommon.MutationFunction<
  CreateAmTaskMutation,
  CreateAmTaskMutationVariables
>;

/**
 * __useCreateAmTaskMutation__
 *
 * To run a mutation, you first call `useCreateAmTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAmTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAmTaskMutation, { data, loading, error }] = useCreateAmTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAmTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAmTaskMutation, CreateAmTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateAmTaskMutation, CreateAmTaskMutationVariables>(
    CreateAmTaskDocument,
    options
  );
}
export type CreateAmTaskMutationHookResult = ReturnType<typeof useCreateAmTaskMutation>;
export type CreateAmTaskMutationResult = ApolloReactCommon.MutationResult<CreateAmTaskMutation>;
export type CreateAmTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateAmTaskMutation,
  CreateAmTaskMutationVariables
>;
export const AddCategoryDocument = gql`
  mutation AddCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      category {
        id
      }
    }
  }
`;
export type AddCategoryMutationFn = ApolloReactCommon.MutationFunction<
  AddCategoryMutation,
  AddCategoryMutationVariables
>;

/**
 * __useAddCategoryMutation__
 *
 * To run a mutation, you first call `useAddCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCategoryMutation, { data, loading, error }] = useAddCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddCategoryMutation, AddCategoryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddCategoryMutation, AddCategoryMutationVariables>(AddCategoryDocument, options);
}
export type AddCategoryMutationHookResult = ReturnType<typeof useAddCategoryMutation>;
export type AddCategoryMutationResult = ApolloReactCommon.MutationResult<AddCategoryMutation>;
export type AddCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddCategoryMutation,
  AddCategoryMutationVariables
>;
export const AddClientCampaignDrawerDocument = gql`
  query AddClientCampaignDrawer($customerId: Int!) {
    customer(id: $customerId) {
      id
      partnerId
    }
  }
`;

/**
 * __useAddClientCampaignDrawerQuery__
 *
 * To run a query within a React component, call `useAddClientCampaignDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddClientCampaignDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddClientCampaignDrawerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useAddClientCampaignDrawerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AddClientCampaignDrawerQuery, AddClientCampaignDrawerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AddClientCampaignDrawerQuery, AddClientCampaignDrawerQueryVariables>(
    AddClientCampaignDrawerDocument,
    options
  );
}
export function useAddClientCampaignDrawerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AddClientCampaignDrawerQuery,
    AddClientCampaignDrawerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AddClientCampaignDrawerQuery, AddClientCampaignDrawerQueryVariables>(
    AddClientCampaignDrawerDocument,
    options
  );
}
export type AddClientCampaignDrawerQueryHookResult = ReturnType<typeof useAddClientCampaignDrawerQuery>;
export type AddClientCampaignDrawerLazyQueryHookResult = ReturnType<typeof useAddClientCampaignDrawerLazyQuery>;
export type AddClientCampaignDrawerQueryResult = ApolloReactCommon.QueryResult<
  AddClientCampaignDrawerQuery,
  AddClientCampaignDrawerQueryVariables
>;
export const CreateClientCampaignDocument = gql`
  mutation CreateClientCampaign($input: CreateCustomerCampaignInput!) {
    createCustomerCampaign(input: $input) {
      customerCampaign {
        id
      }
    }
  }
`;
export type CreateClientCampaignMutationFn = ApolloReactCommon.MutationFunction<
  CreateClientCampaignMutation,
  CreateClientCampaignMutationVariables
>;

/**
 * __useCreateClientCampaignMutation__
 *
 * To run a mutation, you first call `useCreateClientCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientCampaignMutation, { data, loading, error }] = useCreateClientCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateClientCampaignMutation,
    CreateClientCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateClientCampaignMutation, CreateClientCampaignMutationVariables>(
    CreateClientCampaignDocument,
    options
  );
}
export type CreateClientCampaignMutationHookResult = ReturnType<typeof useCreateClientCampaignMutation>;
export type CreateClientCampaignMutationResult = ApolloReactCommon.MutationResult<CreateClientCampaignMutation>;
export type CreateClientCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateClientCampaignMutation,
  CreateClientCampaignMutationVariables
>;
export const CreateCustomerDocument = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      customer {
        id
      }
    }
  }
`;
export type CreateCustomerMutationFn = ApolloReactCommon.MutationFunction<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(
    CreateCustomerDocument,
    options
  );
}
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = ApolloReactCommon.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;
export const CreateClientKeywordDocument = gql`
  mutation CreateClientKeyword($input: CreateCustomerKeywordInput!) {
    createCustomerKeyword(input: $input) {
      customerKeyword {
        id
      }
    }
  }
`;
export type CreateClientKeywordMutationFn = ApolloReactCommon.MutationFunction<
  CreateClientKeywordMutation,
  CreateClientKeywordMutationVariables
>;

/**
 * __useCreateClientKeywordMutation__
 *
 * To run a mutation, you first call `useCreateClientKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientKeywordMutation, { data, loading, error }] = useCreateClientKeywordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientKeywordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateClientKeywordMutation, CreateClientKeywordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateClientKeywordMutation, CreateClientKeywordMutationVariables>(
    CreateClientKeywordDocument,
    options
  );
}
export type CreateClientKeywordMutationHookResult = ReturnType<typeof useCreateClientKeywordMutation>;
export type CreateClientKeywordMutationResult = ApolloReactCommon.MutationResult<CreateClientKeywordMutation>;
export type CreateClientKeywordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateClientKeywordMutation,
  CreateClientKeywordMutationVariables
>;
export const CreateClientUserDocument = gql`
  mutation CreateClientUser($input: CreateCustomerUserInput!) {
    createCustomerUser(input: $input) {
      customerUser {
        id
      }
    }
  }
`;
export type CreateClientUserMutationFn = ApolloReactCommon.MutationFunction<
  CreateClientUserMutation,
  CreateClientUserMutationVariables
>;

/**
 * __useCreateClientUserMutation__
 *
 * To run a mutation, you first call `useCreateClientUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientUserMutation, { data, loading, error }] = useCreateClientUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateClientUserMutation, CreateClientUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateClientUserMutation, CreateClientUserMutationVariables>(
    CreateClientUserDocument,
    options
  );
}
export type CreateClientUserMutationHookResult = ReturnType<typeof useCreateClientUserMutation>;
export type CreateClientUserMutationResult = ApolloReactCommon.MutationResult<CreateClientUserMutation>;
export type CreateClientUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateClientUserMutation,
  CreateClientUserMutationVariables
>;
export const AddDomainsDocument = gql`
  mutation AddDomains($input: CreateDomainsInput!) {
    createDomains(input: $input) {
      domains {
        id
      }
    }
  }
`;
export type AddDomainsMutationFn = ApolloReactCommon.MutationFunction<AddDomainsMutation, AddDomainsMutationVariables>;

/**
 * __useAddDomainsMutation__
 *
 * To run a mutation, you first call `useAddDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDomainsMutation, { data, loading, error }] = useAddDomainsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddDomainsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddDomainsMutation, AddDomainsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddDomainsMutation, AddDomainsMutationVariables>(AddDomainsDocument, options);
}
export type AddDomainsMutationHookResult = ReturnType<typeof useAddDomainsMutation>;
export type AddDomainsMutationResult = ApolloReactCommon.MutationResult<AddDomainsMutation>;
export type AddDomainsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddDomainsMutation,
  AddDomainsMutationVariables
>;
export const CreateLinkLocationDocument = gql`
  mutation CreateLinkLocation($input: CreateLinkLocationInput!) {
    createLinkLocation(input: $input) {
      ok
      linkLocation {
        id
      }
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type CreateLinkLocationMutationFn = ApolloReactCommon.MutationFunction<
  CreateLinkLocationMutation,
  CreateLinkLocationMutationVariables
>;

/**
 * __useCreateLinkLocationMutation__
 *
 * To run a mutation, you first call `useCreateLinkLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLinkLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLinkLocationMutation, { data, loading, error }] = useCreateLinkLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLinkLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLinkLocationMutation, CreateLinkLocationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateLinkLocationMutation, CreateLinkLocationMutationVariables>(
    CreateLinkLocationDocument,
    options
  );
}
export type CreateLinkLocationMutationHookResult = ReturnType<typeof useCreateLinkLocationMutation>;
export type CreateLinkLocationMutationResult = ApolloReactCommon.MutationResult<CreateLinkLocationMutation>;
export type CreateLinkLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateLinkLocationMutation,
  CreateLinkLocationMutationVariables
>;
export const CreatePublisherLinkLocationDocument = gql`
  mutation CreatePublisherLinkLocation($input: CreatePublisherLinkLocationInput!) {
    createPublisherLinkLocation(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type CreatePublisherLinkLocationMutationFn = ApolloReactCommon.MutationFunction<
  CreatePublisherLinkLocationMutation,
  CreatePublisherLinkLocationMutationVariables
>;

/**
 * __useCreatePublisherLinkLocationMutation__
 *
 * To run a mutation, you first call `useCreatePublisherLinkLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublisherLinkLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublisherLinkLocationMutation, { data, loading, error }] = useCreatePublisherLinkLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePublisherLinkLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePublisherLinkLocationMutation,
    CreatePublisherLinkLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreatePublisherLinkLocationMutation,
    CreatePublisherLinkLocationMutationVariables
  >(CreatePublisherLinkLocationDocument, options);
}
export type CreatePublisherLinkLocationMutationHookResult = ReturnType<typeof useCreatePublisherLinkLocationMutation>;
export type CreatePublisherLinkLocationMutationResult =
  ApolloReactCommon.MutationResult<CreatePublisherLinkLocationMutation>;
export type CreatePublisherLinkLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePublisherLinkLocationMutation,
  CreatePublisherLinkLocationMutationVariables
>;
export const SaveLinkLocationCredentialDocument = gql`
  mutation SaveLinkLocationCredential($input: SaveLinkLocationCredentialInput!) {
    saveLinkLocationCredential(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type SaveLinkLocationCredentialMutationFn = ApolloReactCommon.MutationFunction<
  SaveLinkLocationCredentialMutation,
  SaveLinkLocationCredentialMutationVariables
>;

/**
 * __useSaveLinkLocationCredentialMutation__
 *
 * To run a mutation, you first call `useSaveLinkLocationCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLinkLocationCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLinkLocationCredentialMutation, { data, loading, error }] = useSaveLinkLocationCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveLinkLocationCredentialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveLinkLocationCredentialMutation,
    SaveLinkLocationCredentialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveLinkLocationCredentialMutation, SaveLinkLocationCredentialMutationVariables>(
    SaveLinkLocationCredentialDocument,
    options
  );
}
export type SaveLinkLocationCredentialMutationHookResult = ReturnType<typeof useSaveLinkLocationCredentialMutation>;
export type SaveLinkLocationCredentialMutationResult =
  ApolloReactCommon.MutationResult<SaveLinkLocationCredentialMutation>;
export type SaveLinkLocationCredentialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveLinkLocationCredentialMutation,
  SaveLinkLocationCredentialMutationVariables
>;
export const AddOrderDrawerDocument = gql`
  query AddOrderDrawer($customerId: Int!, $campaignCategoryId: String) {
    customer(id: $customerId) {
      id
      partner {
        id
        partnerProducts(campaignCategoryId: $campaignCategoryId) {
          nodes {
            ...AddOrderDrawer_PartnerProduct
          }
        }
      }
      customerKeywords(isPrimary: true) {
        nodes {
          ...AddOrderDrawer_CustomerKeyword
        }
      }
    }
  }
  ${AddOrderDrawer_PartnerProductFragmentDoc}
  ${AddOrderDrawer_CustomerKeywordFragmentDoc}
`;

/**
 * __useAddOrderDrawerQuery__
 *
 * To run a query within a React component, call `useAddOrderDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddOrderDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddOrderDrawerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      campaignCategoryId: // value for 'campaignCategoryId'
 *   },
 * });
 */
export function useAddOrderDrawerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AddOrderDrawerQuery, AddOrderDrawerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AddOrderDrawerQuery, AddOrderDrawerQueryVariables>(AddOrderDrawerDocument, options);
}
export function useAddOrderDrawerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AddOrderDrawerQuery, AddOrderDrawerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AddOrderDrawerQuery, AddOrderDrawerQueryVariables>(
    AddOrderDrawerDocument,
    options
  );
}
export type AddOrderDrawerQueryHookResult = ReturnType<typeof useAddOrderDrawerQuery>;
export type AddOrderDrawerLazyQueryHookResult = ReturnType<typeof useAddOrderDrawerLazyQuery>;
export type AddOrderDrawerQueryResult = ApolloReactCommon.QueryResult<
  AddOrderDrawerQuery,
  AddOrderDrawerQueryVariables
>;
export const AddOrderDrawer_CreateOrderDocument = gql`
  mutation AddOrderDrawer_CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      order {
        id
      }
    }
  }
`;
export type AddOrderDrawer_CreateOrderMutationFn = ApolloReactCommon.MutationFunction<
  AddOrderDrawer_CreateOrderMutation,
  AddOrderDrawer_CreateOrderMutationVariables
>;

/**
 * __useAddOrderDrawer_CreateOrderMutation__
 *
 * To run a mutation, you first call `useAddOrderDrawer_CreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrderDrawer_CreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrderDrawerCreateOrderMutation, { data, loading, error }] = useAddOrderDrawer_CreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOrderDrawer_CreateOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddOrderDrawer_CreateOrderMutation,
    AddOrderDrawer_CreateOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddOrderDrawer_CreateOrderMutation, AddOrderDrawer_CreateOrderMutationVariables>(
    AddOrderDrawer_CreateOrderDocument,
    options
  );
}
export type AddOrderDrawer_CreateOrderMutationHookResult = ReturnType<typeof useAddOrderDrawer_CreateOrderMutation>;
export type AddOrderDrawer_CreateOrderMutationResult =
  ApolloReactCommon.MutationResult<AddOrderDrawer_CreateOrderMutation>;
export type AddOrderDrawer_CreateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddOrderDrawer_CreateOrderMutation,
  AddOrderDrawer_CreateOrderMutationVariables
>;
export const AddPartnerAvailableProductDocument = gql`
  mutation AddPartnerAvailableProduct($input: CreatePartnerAvailableProductInput!) {
    createPartnerAvailableProduct(input: $input) {
      partnerAvailableProduct {
        id
      }
    }
  }
`;
export type AddPartnerAvailableProductMutationFn = ApolloReactCommon.MutationFunction<
  AddPartnerAvailableProductMutation,
  AddPartnerAvailableProductMutationVariables
>;

/**
 * __useAddPartnerAvailableProductMutation__
 *
 * To run a mutation, you first call `useAddPartnerAvailableProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartnerAvailableProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartnerAvailableProductMutation, { data, loading, error }] = useAddPartnerAvailableProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPartnerAvailableProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddPartnerAvailableProductMutation,
    AddPartnerAvailableProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddPartnerAvailableProductMutation, AddPartnerAvailableProductMutationVariables>(
    AddPartnerAvailableProductDocument,
    options
  );
}
export type AddPartnerAvailableProductMutationHookResult = ReturnType<typeof useAddPartnerAvailableProductMutation>;
export type AddPartnerAvailableProductMutationResult =
  ApolloReactCommon.MutationResult<AddPartnerAvailableProductMutation>;
export type AddPartnerAvailableProductMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPartnerAvailableProductMutation,
  AddPartnerAvailableProductMutationVariables
>;
export const CreatePartnerBillingContactDocument = gql`
  mutation CreatePartnerBillingContact($input: CreatePartnerBillingContactInput!) {
    createPartnerBillingContact(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
      partnerBillingContact {
        id
      }
    }
  }
`;
export type CreatePartnerBillingContactMutationFn = ApolloReactCommon.MutationFunction<
  CreatePartnerBillingContactMutation,
  CreatePartnerBillingContactMutationVariables
>;

/**
 * __useCreatePartnerBillingContactMutation__
 *
 * To run a mutation, you first call `useCreatePartnerBillingContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerBillingContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerBillingContactMutation, { data, loading, error }] = useCreatePartnerBillingContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartnerBillingContactMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePartnerBillingContactMutation,
    CreatePartnerBillingContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreatePartnerBillingContactMutation,
    CreatePartnerBillingContactMutationVariables
  >(CreatePartnerBillingContactDocument, options);
}
export type CreatePartnerBillingContactMutationHookResult = ReturnType<typeof useCreatePartnerBillingContactMutation>;
export type CreatePartnerBillingContactMutationResult =
  ApolloReactCommon.MutationResult<CreatePartnerBillingContactMutation>;
export type CreatePartnerBillingContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePartnerBillingContactMutation,
  CreatePartnerBillingContactMutationVariables
>;
export const AddPartnerDocument = gql`
  mutation AddPartner($input: CreatePartnerInput!) {
    createPartner(input: $input) {
      partner {
        id
      }
    }
  }
`;
export type AddPartnerMutationFn = ApolloReactCommon.MutationFunction<AddPartnerMutation, AddPartnerMutationVariables>;

/**
 * __useAddPartnerMutation__
 *
 * To run a mutation, you first call `useAddPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartnerMutation, { data, loading, error }] = useAddPartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddPartnerMutation, AddPartnerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddPartnerMutation, AddPartnerMutationVariables>(AddPartnerDocument, options);
}
export type AddPartnerMutationHookResult = ReturnType<typeof useAddPartnerMutation>;
export type AddPartnerMutationResult = ApolloReactCommon.MutationResult<AddPartnerMutation>;
export type AddPartnerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPartnerMutation,
  AddPartnerMutationVariables
>;
export const AddPartnerPackageDrawer_CreatePartnerPackageDocument = gql`
  mutation AddPartnerPackageDrawer_CreatePartnerPackage($input: CreatePartnerPackageInput!) {
    createPartnerPackage(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
      partnerPackage {
        id
      }
    }
  }
`;
export type AddPartnerPackageDrawer_CreatePartnerPackageMutationFn = ApolloReactCommon.MutationFunction<
  AddPartnerPackageDrawer_CreatePartnerPackageMutation,
  AddPartnerPackageDrawer_CreatePartnerPackageMutationVariables
>;

/**
 * __useAddPartnerPackageDrawer_CreatePartnerPackageMutation__
 *
 * To run a mutation, you first call `useAddPartnerPackageDrawer_CreatePartnerPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartnerPackageDrawer_CreatePartnerPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartnerPackageDrawerCreatePartnerPackageMutation, { data, loading, error }] = useAddPartnerPackageDrawer_CreatePartnerPackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPartnerPackageDrawer_CreatePartnerPackageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddPartnerPackageDrawer_CreatePartnerPackageMutation,
    AddPartnerPackageDrawer_CreatePartnerPackageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddPartnerPackageDrawer_CreatePartnerPackageMutation,
    AddPartnerPackageDrawer_CreatePartnerPackageMutationVariables
  >(AddPartnerPackageDrawer_CreatePartnerPackageDocument, options);
}
export type AddPartnerPackageDrawer_CreatePartnerPackageMutationHookResult = ReturnType<
  typeof useAddPartnerPackageDrawer_CreatePartnerPackageMutation
>;
export type AddPartnerPackageDrawer_CreatePartnerPackageMutationResult =
  ApolloReactCommon.MutationResult<AddPartnerPackageDrawer_CreatePartnerPackageMutation>;
export type AddPartnerPackageDrawer_CreatePartnerPackageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPartnerPackageDrawer_CreatePartnerPackageMutation,
  AddPartnerPackageDrawer_CreatePartnerPackageMutationVariables
>;
export const CreateChecklistDefinitionDocument = gql`
  mutation createChecklistDefinition($input: CreateChecklistDefinitionInput!) {
    createChecklistDefinition(input: $input) {
      checklistDefinition {
        id
      }
    }
  }
`;
export type CreateChecklistDefinitionMutationFn = ApolloReactCommon.MutationFunction<
  CreateChecklistDefinitionMutation,
  CreateChecklistDefinitionMutationVariables
>;

/**
 * __useCreateChecklistDefinitionMutation__
 *
 * To run a mutation, you first call `useCreateChecklistDefinitionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChecklistDefinitionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChecklistDefinitionMutation, { data, loading, error }] = useCreateChecklistDefinitionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChecklistDefinitionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateChecklistDefinitionMutation,
    CreateChecklistDefinitionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateChecklistDefinitionMutation, CreateChecklistDefinitionMutationVariables>(
    CreateChecklistDefinitionDocument,
    options
  );
}
export type CreateChecklistDefinitionMutationHookResult = ReturnType<typeof useCreateChecklistDefinitionMutation>;
export type CreateChecklistDefinitionMutationResult =
  ApolloReactCommon.MutationResult<CreateChecklistDefinitionMutation>;
export type CreateChecklistDefinitionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateChecklistDefinitionMutation,
  CreateChecklistDefinitionMutationVariables
>;
export const AddPartnerProductDrawerPartnerProductsDocument = gql`
  query AddPartnerProductDrawerPartnerProducts($partnerId: Int!) {
    partnerProducts(partnerId: $partnerId, isActive: true) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useAddPartnerProductDrawerPartnerProductsQuery__
 *
 * To run a query within a React component, call `useAddPartnerProductDrawerPartnerProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddPartnerProductDrawerPartnerProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddPartnerProductDrawerPartnerProductsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useAddPartnerProductDrawerPartnerProductsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AddPartnerProductDrawerPartnerProductsQuery,
    AddPartnerProductDrawerPartnerProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AddPartnerProductDrawerPartnerProductsQuery,
    AddPartnerProductDrawerPartnerProductsQueryVariables
  >(AddPartnerProductDrawerPartnerProductsDocument, options);
}
export function useAddPartnerProductDrawerPartnerProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AddPartnerProductDrawerPartnerProductsQuery,
    AddPartnerProductDrawerPartnerProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AddPartnerProductDrawerPartnerProductsQuery,
    AddPartnerProductDrawerPartnerProductsQueryVariables
  >(AddPartnerProductDrawerPartnerProductsDocument, options);
}
export type AddPartnerProductDrawerPartnerProductsQueryHookResult = ReturnType<
  typeof useAddPartnerProductDrawerPartnerProductsQuery
>;
export type AddPartnerProductDrawerPartnerProductsLazyQueryHookResult = ReturnType<
  typeof useAddPartnerProductDrawerPartnerProductsLazyQuery
>;
export type AddPartnerProductDrawerPartnerProductsQueryResult = ApolloReactCommon.QueryResult<
  AddPartnerProductDrawerPartnerProductsQuery,
  AddPartnerProductDrawerPartnerProductsQueryVariables
>;
export const AddPartnerProductDocument = gql`
  mutation AddPartnerProduct($input: CreatePartnerProductInput!) {
    createPartnerProduct(input: $input) {
      partnerProduct {
        id
      }
    }
  }
`;
export type AddPartnerProductMutationFn = ApolloReactCommon.MutationFunction<
  AddPartnerProductMutation,
  AddPartnerProductMutationVariables
>;

/**
 * __useAddPartnerProductMutation__
 *
 * To run a mutation, you first call `useAddPartnerProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartnerProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartnerProductMutation, { data, loading, error }] = useAddPartnerProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPartnerProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddPartnerProductMutation, AddPartnerProductMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddPartnerProductMutation, AddPartnerProductMutationVariables>(
    AddPartnerProductDocument,
    options
  );
}
export type AddPartnerProductMutationHookResult = ReturnType<typeof useAddPartnerProductMutation>;
export type AddPartnerProductMutationResult = ApolloReactCommon.MutationResult<AddPartnerProductMutation>;
export type AddPartnerProductMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPartnerProductMutation,
  AddPartnerProductMutationVariables
>;
export const AddPartnerUserDocument = gql`
  mutation AddPartnerUser($input: CreatePartnerUserInput!) {
    createPartnerUser(input: $input) {
      partnerUser {
        id
      }
    }
  }
`;
export type AddPartnerUserMutationFn = ApolloReactCommon.MutationFunction<
  AddPartnerUserMutation,
  AddPartnerUserMutationVariables
>;

/**
 * __useAddPartnerUserMutation__
 *
 * To run a mutation, you first call `useAddPartnerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPartnerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPartnerUserMutation, { data, loading, error }] = useAddPartnerUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPartnerUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddPartnerUserMutation, AddPartnerUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddPartnerUserMutation, AddPartnerUserMutationVariables>(
    AddPartnerUserDocument,
    options
  );
}
export type AddPartnerUserMutationHookResult = ReturnType<typeof useAddPartnerUserMutation>;
export type AddPartnerUserMutationResult = ApolloReactCommon.MutationResult<AddPartnerUserMutation>;
export type AddPartnerUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPartnerUserMutation,
  AddPartnerUserMutationVariables
>;
export const CreatePartnerPaymentMethodSetupIntentDocument = gql`
  mutation CreatePartnerPaymentMethodSetupIntent($input: CreatePartnerPaymentMethodSetupIntentInput!) {
    createPartnerPaymentMethodSetupIntent(input: $input) {
      ok
      error {
        message
      }
      clientSecret
    }
  }
`;
export type CreatePartnerPaymentMethodSetupIntentMutationFn = ApolloReactCommon.MutationFunction<
  CreatePartnerPaymentMethodSetupIntentMutation,
  CreatePartnerPaymentMethodSetupIntentMutationVariables
>;

/**
 * __useCreatePartnerPaymentMethodSetupIntentMutation__
 *
 * To run a mutation, you first call `useCreatePartnerPaymentMethodSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerPaymentMethodSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerPaymentMethodSetupIntentMutation, { data, loading, error }] = useCreatePartnerPaymentMethodSetupIntentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartnerPaymentMethodSetupIntentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePartnerPaymentMethodSetupIntentMutation,
    CreatePartnerPaymentMethodSetupIntentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreatePartnerPaymentMethodSetupIntentMutation,
    CreatePartnerPaymentMethodSetupIntentMutationVariables
  >(CreatePartnerPaymentMethodSetupIntentDocument, options);
}
export type CreatePartnerPaymentMethodSetupIntentMutationHookResult = ReturnType<
  typeof useCreatePartnerPaymentMethodSetupIntentMutation
>;
export type CreatePartnerPaymentMethodSetupIntentMutationResult =
  ApolloReactCommon.MutationResult<CreatePartnerPaymentMethodSetupIntentMutation>;
export type CreatePartnerPaymentMethodSetupIntentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePartnerPaymentMethodSetupIntentMutation,
  CreatePartnerPaymentMethodSetupIntentMutationVariables
>;
export const AddPublisherDocument = gql`
  mutation AddPublisher($input: CreatePublisherInput!) {
    createPublisher(input: $input) {
      ok
      publisher {
        id
      }
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type AddPublisherMutationFn = ApolloReactCommon.MutationFunction<
  AddPublisherMutation,
  AddPublisherMutationVariables
>;

/**
 * __useAddPublisherMutation__
 *
 * To run a mutation, you first call `useAddPublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPublisherMutation, { data, loading, error }] = useAddPublisherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPublisherMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddPublisherMutation, AddPublisherMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddPublisherMutation, AddPublisherMutationVariables>(
    AddPublisherDocument,
    options
  );
}
export type AddPublisherMutationHookResult = ReturnType<typeof useAddPublisherMutation>;
export type AddPublisherMutationResult = ApolloReactCommon.MutationResult<AddPublisherMutation>;
export type AddPublisherMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddPublisherMutation,
  AddPublisherMutationVariables
>;
export const CreatePublisherUserDocument = gql`
  mutation CreatePublisherUser($input: CreatePublisherUserInput!) {
    createPublisherUser(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type CreatePublisherUserMutationFn = ApolloReactCommon.MutationFunction<
  CreatePublisherUserMutation,
  CreatePublisherUserMutationVariables
>;

/**
 * __useCreatePublisherUserMutation__
 *
 * To run a mutation, you first call `useCreatePublisherUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublisherUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublisherUserMutation, { data, loading, error }] = useCreatePublisherUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePublisherUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePublisherUserMutation, CreatePublisherUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreatePublisherUserMutation, CreatePublisherUserMutationVariables>(
    CreatePublisherUserDocument,
    options
  );
}
export type CreatePublisherUserMutationHookResult = ReturnType<typeof useCreatePublisherUserMutation>;
export type CreatePublisherUserMutationResult = ApolloReactCommon.MutationResult<CreatePublisherUserMutation>;
export type CreatePublisherUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePublisherUserMutation,
  CreatePublisherUserMutationVariables
>;
export const AddVendorDocument = gql`
  mutation AddVendor($input: CreateVendorInput!) {
    createVendor(input: $input) {
      vendor {
        id
      }
    }
  }
`;
export type AddVendorMutationFn = ApolloReactCommon.MutationFunction<AddVendorMutation, AddVendorMutationVariables>;

/**
 * __useAddVendorMutation__
 *
 * To run a mutation, you first call `useAddVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVendorMutation, { data, loading, error }] = useAddVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVendorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddVendorMutation, AddVendorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddVendorMutation, AddVendorMutationVariables>(AddVendorDocument, options);
}
export type AddVendorMutationHookResult = ReturnType<typeof useAddVendorMutation>;
export type AddVendorMutationResult = ApolloReactCommon.MutationResult<AddVendorMutation>;
export type AddVendorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddVendorMutation,
  AddVendorMutationVariables
>;
export const AddVendorUserDocument = gql`
  mutation AddVendorUser($input: CreateVendorUserInput!) {
    createVendorUser(input: $input) {
      vendorUser {
        id
      }
    }
  }
`;
export type AddVendorUserMutationFn = ApolloReactCommon.MutationFunction<
  AddVendorUserMutation,
  AddVendorUserMutationVariables
>;

/**
 * __useAddVendorUserMutation__
 *
 * To run a mutation, you first call `useAddVendorUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVendorUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVendorUserMutation, { data, loading, error }] = useAddVendorUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVendorUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddVendorUserMutation, AddVendorUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AddVendorUserMutation, AddVendorUserMutationVariables>(
    AddVendorUserDocument,
    options
  );
}
export type AddVendorUserMutationHookResult = ReturnType<typeof useAddVendorUserMutation>;
export type AddVendorUserMutationResult = ApolloReactCommon.MutationResult<AddVendorUserMutation>;
export type AddVendorUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddVendorUserMutation,
  AddVendorUserMutationVariables
>;
export const ProductsDocument = gql`
  query Products($partnerId: Int!) {
    partner(id: $partnerId) {
      id
      partnerAvailableProducts {
        ...ProductForm_PartnerAvailableProduct
      }
    }
  }
  ${ProductForm_PartnerAvailableProductFragmentDoc}
`;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
}
export function useProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = ApolloReactCommon.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ProductTaskTypesForProductSelectorDocument = gql`
  query ProductTaskTypesForProductSelector($taskTypeIds: [String!]!) {
    productTaskTypes(taskTypeIds: $taskTypeIds) {
      nodes {
        id
        product {
          id
        }
      }
    }
  }
`;

/**
 * __useProductTaskTypesForProductSelectorQuery__
 *
 * To run a query within a React component, call `useProductTaskTypesForProductSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTaskTypesForProductSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTaskTypesForProductSelectorQuery({
 *   variables: {
 *      taskTypeIds: // value for 'taskTypeIds'
 *   },
 * });
 */
export function useProductTaskTypesForProductSelectorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProductTaskTypesForProductSelectorQuery,
    ProductTaskTypesForProductSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ProductTaskTypesForProductSelectorQuery,
    ProductTaskTypesForProductSelectorQueryVariables
  >(ProductTaskTypesForProductSelectorDocument, options);
}
export function useProductTaskTypesForProductSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProductTaskTypesForProductSelectorQuery,
    ProductTaskTypesForProductSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ProductTaskTypesForProductSelectorQuery,
    ProductTaskTypesForProductSelectorQueryVariables
  >(ProductTaskTypesForProductSelectorDocument, options);
}
export type ProductTaskTypesForProductSelectorQueryHookResult = ReturnType<
  typeof useProductTaskTypesForProductSelectorQuery
>;
export type ProductTaskTypesForProductSelectorLazyQueryHookResult = ReturnType<
  typeof useProductTaskTypesForProductSelectorLazyQuery
>;
export type ProductTaskTypesForProductSelectorQueryResult = ApolloReactCommon.QueryResult<
  ProductTaskTypesForProductSelectorQuery,
  ProductTaskTypesForProductSelectorQueryVariables
>;
export const CampaignCategoriesForSelectorV2Document = gql`
  query CampaignCategoriesForSelectorV2($search: String, $limit: Int, $offset: Int, $campaignCategoryIds: [String!]) {
    campaignCategories(search: $search, limit: $limit, offset: $offset, campaignCategoryIds: $campaignCategoryIds) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useCampaignCategoriesForSelectorV2Query__
 *
 * To run a query within a React component, call `useCampaignCategoriesForSelectorV2Query` and pass it any options that fit your needs.
 * When your component renders, `useCampaignCategoriesForSelectorV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignCategoriesForSelectorV2Query({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      campaignCategoryIds: // value for 'campaignCategoryIds'
 *   },
 * });
 */
export function useCampaignCategoriesForSelectorV2Query(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CampaignCategoriesForSelectorV2Query,
    CampaignCategoriesForSelectorV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CampaignCategoriesForSelectorV2Query, CampaignCategoriesForSelectorV2QueryVariables>(
    CampaignCategoriesForSelectorV2Document,
    options
  );
}
export function useCampaignCategoriesForSelectorV2LazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CampaignCategoriesForSelectorV2Query,
    CampaignCategoriesForSelectorV2QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CampaignCategoriesForSelectorV2Query,
    CampaignCategoriesForSelectorV2QueryVariables
  >(CampaignCategoriesForSelectorV2Document, options);
}
export type CampaignCategoriesForSelectorV2QueryHookResult = ReturnType<typeof useCampaignCategoriesForSelectorV2Query>;
export type CampaignCategoriesForSelectorV2LazyQueryHookResult = ReturnType<
  typeof useCampaignCategoriesForSelectorV2LazyQuery
>;
export type CampaignCategoriesForSelectorV2QueryResult = ApolloReactCommon.QueryResult<
  CampaignCategoriesForSelectorV2Query,
  CampaignCategoriesForSelectorV2QueryVariables
>;
export const CampaignCategoriesForSelectorDocument = gql`
  query CampaignCategoriesForSelector($search: String, $limit: Int, $offset: Int, $campaignCategoryIds: [String!]) {
    campaignCategories(search: $search, limit: $limit, offset: $offset, campaignCategoryIds: $campaignCategoryIds) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useCampaignCategoriesForSelectorQuery__
 *
 * To run a query within a React component, call `useCampaignCategoriesForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignCategoriesForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignCategoriesForSelectorQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      campaignCategoryIds: // value for 'campaignCategoryIds'
 *   },
 * });
 */
export function useCampaignCategoriesForSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CampaignCategoriesForSelectorQuery,
    CampaignCategoriesForSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CampaignCategoriesForSelectorQuery, CampaignCategoriesForSelectorQueryVariables>(
    CampaignCategoriesForSelectorDocument,
    options
  );
}
export function useCampaignCategoriesForSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CampaignCategoriesForSelectorQuery,
    CampaignCategoriesForSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CampaignCategoriesForSelectorQuery, CampaignCategoriesForSelectorQueryVariables>(
    CampaignCategoriesForSelectorDocument,
    options
  );
}
export type CampaignCategoriesForSelectorQueryHookResult = ReturnType<typeof useCampaignCategoriesForSelectorQuery>;
export type CampaignCategoriesForSelectorLazyQueryHookResult = ReturnType<
  typeof useCampaignCategoriesForSelectorLazyQuery
>;
export type CampaignCategoriesForSelectorQueryResult = ApolloReactCommon.QueryResult<
  CampaignCategoriesForSelectorQuery,
  CampaignCategoriesForSelectorQueryVariables
>;
export const CampaignInvoicesDocument = gql`
  query CampaignInvoices(
    $limit: Int
    $offset: Int
    $sort: CustomerCampaignInvoiceSort
    $search: String
    $statusIds: [String!]
    $customerIds: [Int!]!
  ) {
    customerCampaignInvoices(
      limit: $limit
      offset: $offset
      sort: $sort
      search: $search
      statusIds: $statusIds
      customerIds: $customerIds
    ) {
      totalCount
      nodes {
        ...CustomerCampaignInvoiceDetail
      }
    }
  }
  ${CustomerCampaignInvoiceDetailFragmentDoc}
`;

/**
 * __useCampaignInvoicesQuery__
 *
 * To run a query within a React component, call `useCampaignInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignInvoicesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      statusIds: // value for 'statusIds'
 *      customerIds: // value for 'customerIds'
 *   },
 * });
 */
export function useCampaignInvoicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CampaignInvoicesQuery, CampaignInvoicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CampaignInvoicesQuery, CampaignInvoicesQueryVariables>(
    CampaignInvoicesDocument,
    options
  );
}
export function useCampaignInvoicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CampaignInvoicesQuery, CampaignInvoicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CampaignInvoicesQuery, CampaignInvoicesQueryVariables>(
    CampaignInvoicesDocument,
    options
  );
}
export type CampaignInvoicesQueryHookResult = ReturnType<typeof useCampaignInvoicesQuery>;
export type CampaignInvoicesLazyQueryHookResult = ReturnType<typeof useCampaignInvoicesLazyQuery>;
export type CampaignInvoicesQueryResult = ApolloReactCommon.QueryResult<
  CampaignInvoicesQuery,
  CampaignInvoicesQueryVariables
>;
export const RecordCustomerCampaignInvoiceManualPaymentDocument = gql`
  mutation RecordCustomerCampaignInvoiceManualPayment($input: RecordCustomerCampaignInvoicePaymentInput!) {
    recordCustomerCampaignInvoicePayment(input: $input) {
      wasPaymentSuccessful
      error {
        message
      }
    }
  }
`;
export type RecordCustomerCampaignInvoiceManualPaymentMutationFn = ApolloReactCommon.MutationFunction<
  RecordCustomerCampaignInvoiceManualPaymentMutation,
  RecordCustomerCampaignInvoiceManualPaymentMutationVariables
>;

/**
 * __useRecordCustomerCampaignInvoiceManualPaymentMutation__
 *
 * To run a mutation, you first call `useRecordCustomerCampaignInvoiceManualPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordCustomerCampaignInvoiceManualPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordCustomerCampaignInvoiceManualPaymentMutation, { data, loading, error }] = useRecordCustomerCampaignInvoiceManualPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordCustomerCampaignInvoiceManualPaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RecordCustomerCampaignInvoiceManualPaymentMutation,
    RecordCustomerCampaignInvoiceManualPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RecordCustomerCampaignInvoiceManualPaymentMutation,
    RecordCustomerCampaignInvoiceManualPaymentMutationVariables
  >(RecordCustomerCampaignInvoiceManualPaymentDocument, options);
}
export type RecordCustomerCampaignInvoiceManualPaymentMutationHookResult = ReturnType<
  typeof useRecordCustomerCampaignInvoiceManualPaymentMutation
>;
export type RecordCustomerCampaignInvoiceManualPaymentMutationResult =
  ApolloReactCommon.MutationResult<RecordCustomerCampaignInvoiceManualPaymentMutation>;
export type RecordCustomerCampaignInvoiceManualPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RecordCustomerCampaignInvoiceManualPaymentMutation,
  RecordCustomerCampaignInvoiceManualPaymentMutationVariables
>;
export const CategoriesForSelectorDocument = gql`
  query CategoriesForSelector($search: String, $limit: Int, $offset: Int, $categoryIds: [Int!]) {
    categories(search: $search, limit: $limit, offset: $offset, categoryIds: $categoryIds) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useCategoriesForSelectorQuery__
 *
 * To run a query within a React component, call `useCategoriesForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesForSelectorQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useCategoriesForSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>(
    CategoriesForSelectorDocument,
    options
  );
}
export function useCategoriesForSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CategoriesForSelectorQuery, CategoriesForSelectorQueryVariables>(
    CategoriesForSelectorDocument,
    options
  );
}
export type CategoriesForSelectorQueryHookResult = ReturnType<typeof useCategoriesForSelectorQuery>;
export type CategoriesForSelectorLazyQueryHookResult = ReturnType<typeof useCategoriesForSelectorLazyQuery>;
export type CategoriesForSelectorQueryResult = ApolloReactCommon.QueryResult<
  CategoriesForSelectorQuery,
  CategoriesForSelectorQueryVariables
>;
export const ChecklistDefinitionsForProductDocument = gql`
  query ChecklistDefinitionsForProduct($search: String, $limit: Int, $offset: Int) {
    checklistDefinitions(search: $search, limit: $limit, offset: $offset) {
      ...ChecklistDefinitionsList
    }
  }
  ${ChecklistDefinitionsListFragmentDoc}
`;

/**
 * __useChecklistDefinitionsForProductQuery__
 *
 * To run a query within a React component, call `useChecklistDefinitionsForProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useChecklistDefinitionsForProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChecklistDefinitionsForProductQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useChecklistDefinitionsForProductQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ChecklistDefinitionsForProductQuery,
    ChecklistDefinitionsForProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ChecklistDefinitionsForProductQuery, ChecklistDefinitionsForProductQueryVariables>(
    ChecklistDefinitionsForProductDocument,
    options
  );
}
export function useChecklistDefinitionsForProductLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ChecklistDefinitionsForProductQuery,
    ChecklistDefinitionsForProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ChecklistDefinitionsForProductQuery,
    ChecklistDefinitionsForProductQueryVariables
  >(ChecklistDefinitionsForProductDocument, options);
}
export type ChecklistDefinitionsForProductQueryHookResult = ReturnType<typeof useChecklistDefinitionsForProductQuery>;
export type ChecklistDefinitionsForProductLazyQueryHookResult = ReturnType<
  typeof useChecklistDefinitionsForProductLazyQuery
>;
export type ChecklistDefinitionsForProductQueryResult = ApolloReactCommon.QueryResult<
  ChecklistDefinitionsForProductQuery,
  ChecklistDefinitionsForProductQueryVariables
>;
export const CreateClientStatusChangeDocument = gql`
  mutation CreateClientStatusChange($input: CreateCustomerStatusChangeInput!) {
    createCustomerStatusChange(input: $input) {
      customerStatusChange {
        id
      }
    }
  }
`;
export type CreateClientStatusChangeMutationFn = ApolloReactCommon.MutationFunction<
  CreateClientStatusChangeMutation,
  CreateClientStatusChangeMutationVariables
>;

/**
 * __useCreateClientStatusChangeMutation__
 *
 * To run a mutation, you first call `useCreateClientStatusChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientStatusChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientStatusChangeMutation, { data, loading, error }] = useCreateClientStatusChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientStatusChangeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateClientStatusChangeMutation,
    CreateClientStatusChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateClientStatusChangeMutation, CreateClientStatusChangeMutationVariables>(
    CreateClientStatusChangeDocument,
    options
  );
}
export type CreateClientStatusChangeMutationHookResult = ReturnType<typeof useCreateClientStatusChangeMutation>;
export type CreateClientStatusChangeMutationResult = ApolloReactCommon.MutationResult<CreateClientStatusChangeMutation>;
export type CreateClientStatusChangeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateClientStatusChangeMutation,
  CreateClientStatusChangeMutationVariables
>;
export const UpdateClientStatusChangeDocument = gql`
  mutation UpdateClientStatusChange($input: UpdateCustomerStatusChangeInput!) {
    updateCustomerStatusChange(input: $input) {
      customerStatusChange {
        id
      }
    }
  }
`;
export type UpdateClientStatusChangeMutationFn = ApolloReactCommon.MutationFunction<
  UpdateClientStatusChangeMutation,
  UpdateClientStatusChangeMutationVariables
>;

/**
 * __useUpdateClientStatusChangeMutation__
 *
 * To run a mutation, you first call `useUpdateClientStatusChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientStatusChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientStatusChangeMutation, { data, loading, error }] = useUpdateClientStatusChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientStatusChangeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateClientStatusChangeMutation,
    UpdateClientStatusChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateClientStatusChangeMutation, UpdateClientStatusChangeMutationVariables>(
    UpdateClientStatusChangeDocument,
    options
  );
}
export type UpdateClientStatusChangeMutationHookResult = ReturnType<typeof useUpdateClientStatusChangeMutation>;
export type UpdateClientStatusChangeMutationResult = ApolloReactCommon.MutationResult<UpdateClientStatusChangeMutation>;
export type UpdateClientStatusChangeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateClientStatusChangeMutation,
  UpdateClientStatusChangeMutationVariables
>;
export const DeleteClientStatusChangeDocument = gql`
  mutation DeleteClientStatusChange($input: DeleteCustomerStatusChangeInput!) {
    deleteCustomerStatusChange(input: $input) {
      isDeleted
    }
  }
`;
export type DeleteClientStatusChangeMutationFn = ApolloReactCommon.MutationFunction<
  DeleteClientStatusChangeMutation,
  DeleteClientStatusChangeMutationVariables
>;

/**
 * __useDeleteClientStatusChangeMutation__
 *
 * To run a mutation, you first call `useDeleteClientStatusChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientStatusChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientStatusChangeMutation, { data, loading, error }] = useDeleteClientStatusChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientStatusChangeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteClientStatusChangeMutation,
    DeleteClientStatusChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteClientStatusChangeMutation, DeleteClientStatusChangeMutationVariables>(
    DeleteClientStatusChangeDocument,
    options
  );
}
export type DeleteClientStatusChangeMutationHookResult = ReturnType<typeof useDeleteClientStatusChangeMutation>;
export type DeleteClientStatusChangeMutationResult = ApolloReactCommon.MutationResult<DeleteClientStatusChangeMutation>;
export type DeleteClientStatusChangeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteClientStatusChangeMutation,
  DeleteClientStatusChangeMutationVariables
>;
export const ClientStatusDocument = gql`
  query ClientStatus($id: Int!) {
    customer(id: $id) {
      id
      status {
        id
        name
      }
      partner {
        id
        isBillingEnforced
        isUpfrontPaymentRequired
      }
      customerStatusChanges(limit: 1, offset: 0, sort: EFFECTIVE_AT_DESC, processedAt: { orIsNull: true }) {
        nodes {
          id
          status {
            id
            name
          }
          reason
          effectiveAt
        }
      }
    }
  }
`;

/**
 * __useClientStatusQuery__
 *
 * To run a query within a React component, call `useClientStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientStatusQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ClientStatusQuery, ClientStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientStatusQuery, ClientStatusQueryVariables>(ClientStatusDocument, options);
}
export function useClientStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientStatusQuery, ClientStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClientStatusQuery, ClientStatusQueryVariables>(ClientStatusDocument, options);
}
export type ClientStatusQueryHookResult = ReturnType<typeof useClientStatusQuery>;
export type ClientStatusLazyQueryHookResult = ReturnType<typeof useClientStatusLazyQuery>;
export type ClientStatusQueryResult = ApolloReactCommon.QueryResult<ClientStatusQuery, ClientStatusQueryVariables>;
export const ConversationDetailsDocument = gql`
  query ConversationDetails($conversationId: String!) {
    customerConversation(conversationId: $conversationId) {
      id
      subject
      createdAt
      helpRequestedAt
      customer {
        id
        name
      }
      customerMessages(limit: 100, sort: CREATED_AT_ASC) {
        totalCount
        nodes {
          id
          createdAt
          content
          user {
            id
            fullName
          }
          isFromCustomer
        }
      }
    }
  }
`;

/**
 * __useConversationDetailsQuery__
 *
 * To run a query within a React component, call `useConversationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationDetailsQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useConversationDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ConversationDetailsQuery, ConversationDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ConversationDetailsQuery, ConversationDetailsQueryVariables>(
    ConversationDetailsDocument,
    options
  );
}
export function useConversationDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConversationDetailsQuery, ConversationDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ConversationDetailsQuery, ConversationDetailsQueryVariables>(
    ConversationDetailsDocument,
    options
  );
}
export type ConversationDetailsQueryHookResult = ReturnType<typeof useConversationDetailsQuery>;
export type ConversationDetailsLazyQueryHookResult = ReturnType<typeof useConversationDetailsLazyQuery>;
export type ConversationDetailsQueryResult = ApolloReactCommon.QueryResult<
  ConversationDetailsQuery,
  ConversationDetailsQueryVariables
>;
export const ConversationDetails_CreateMessageDocument = gql`
  mutation ConversationDetails_CreateMessage($input: CreateCustomerMessageInput!) {
    createCustomerMessage(input: $input) {
      customerMessage {
        id
      }
    }
  }
`;
export type ConversationDetails_CreateMessageMutationFn = ApolloReactCommon.MutationFunction<
  ConversationDetails_CreateMessageMutation,
  ConversationDetails_CreateMessageMutationVariables
>;

/**
 * __useConversationDetails_CreateMessageMutation__
 *
 * To run a mutation, you first call `useConversationDetails_CreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConversationDetails_CreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [conversationDetailsCreateMessageMutation, { data, loading, error }] = useConversationDetails_CreateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConversationDetails_CreateMessageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConversationDetails_CreateMessageMutation,
    ConversationDetails_CreateMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ConversationDetails_CreateMessageMutation,
    ConversationDetails_CreateMessageMutationVariables
  >(ConversationDetails_CreateMessageDocument, options);
}
export type ConversationDetails_CreateMessageMutationHookResult = ReturnType<
  typeof useConversationDetails_CreateMessageMutation
>;
export type ConversationDetails_CreateMessageMutationResult =
  ApolloReactCommon.MutationResult<ConversationDetails_CreateMessageMutation>;
export type ConversationDetails_CreateMessageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConversationDetails_CreateMessageMutation,
  ConversationDetails_CreateMessageMutationVariables
>;
export const ConversationDetails_UpdateConversationDocument = gql`
  mutation ConversationDetails_UpdateConversation($input: UpdateCustomerConversationInput!) {
    updateCustomerConversation(input: $input) {
      customerConversation {
        id
        helpRequestedAt
      }
    }
  }
`;
export type ConversationDetails_UpdateConversationMutationFn = ApolloReactCommon.MutationFunction<
  ConversationDetails_UpdateConversationMutation,
  ConversationDetails_UpdateConversationMutationVariables
>;

/**
 * __useConversationDetails_UpdateConversationMutation__
 *
 * To run a mutation, you first call `useConversationDetails_UpdateConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConversationDetails_UpdateConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [conversationDetailsUpdateConversationMutation, { data, loading, error }] = useConversationDetails_UpdateConversationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConversationDetails_UpdateConversationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConversationDetails_UpdateConversationMutation,
    ConversationDetails_UpdateConversationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ConversationDetails_UpdateConversationMutation,
    ConversationDetails_UpdateConversationMutationVariables
  >(ConversationDetails_UpdateConversationDocument, options);
}
export type ConversationDetails_UpdateConversationMutationHookResult = ReturnType<
  typeof useConversationDetails_UpdateConversationMutation
>;
export type ConversationDetails_UpdateConversationMutationResult =
  ApolloReactCommon.MutationResult<ConversationDetails_UpdateConversationMutation>;
export type ConversationDetails_UpdateConversationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConversationDetails_UpdateConversationMutation,
  ConversationDetails_UpdateConversationMutationVariables
>;
export const CustomerCampaignSelectorDocument = gql`
  query CustomerCampaignSelector($customerId: Int!) {
    customer(id: $customerId) {
      id
      customerCampaigns {
        nodes {
          ...CustomerCampaignSelector_CustomerCampaign
        }
      }
    }
  }
  ${CustomerCampaignSelector_CustomerCampaignFragmentDoc}
`;

/**
 * __useCustomerCampaignSelectorQuery__
 *
 * To run a query within a React component, call `useCustomerCampaignSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCampaignSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCampaignSelectorQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCustomerCampaignSelectorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CustomerCampaignSelectorQuery, CustomerCampaignSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerCampaignSelectorQuery, CustomerCampaignSelectorQueryVariables>(
    CustomerCampaignSelectorDocument,
    options
  );
}
export function useCustomerCampaignSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerCampaignSelectorQuery,
    CustomerCampaignSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerCampaignSelectorQuery, CustomerCampaignSelectorQueryVariables>(
    CustomerCampaignSelectorDocument,
    options
  );
}
export type CustomerCampaignSelectorQueryHookResult = ReturnType<typeof useCustomerCampaignSelectorQuery>;
export type CustomerCampaignSelectorLazyQueryHookResult = ReturnType<typeof useCustomerCampaignSelectorLazyQuery>;
export type CustomerCampaignSelectorQueryResult = ApolloReactCommon.QueryResult<
  CustomerCampaignSelectorQuery,
  CustomerCampaignSelectorQueryVariables
>;
export const CustomerKeywordSelectorDocument = gql`
  query CustomerKeywordSelector(
    $customerId: Int!
    $isPrimary: Boolean
    $includeInactive: Boolean
    $limit: Int
    $offset: Int
  ) {
    customer(id: $customerId) {
      id
      customerKeywords(limit: $limit, offset: $offset, isPrimary: $isPrimary, includeInactive: $includeInactive) {
        totalCount
        nodes {
          ...CustomerKeywordSelector_Keyword
        }
      }
    }
  }
  ${CustomerKeywordSelector_KeywordFragmentDoc}
`;

/**
 * __useCustomerKeywordSelectorQuery__
 *
 * To run a query within a React component, call `useCustomerKeywordSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerKeywordSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerKeywordSelectorQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      isPrimary: // value for 'isPrimary'
 *      includeInactive: // value for 'includeInactive'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCustomerKeywordSelectorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CustomerKeywordSelectorQuery, CustomerKeywordSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerKeywordSelectorQuery, CustomerKeywordSelectorQueryVariables>(
    CustomerKeywordSelectorDocument,
    options
  );
}
export function useCustomerKeywordSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerKeywordSelectorQuery,
    CustomerKeywordSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerKeywordSelectorQuery, CustomerKeywordSelectorQueryVariables>(
    CustomerKeywordSelectorDocument,
    options
  );
}
export type CustomerKeywordSelectorQueryHookResult = ReturnType<typeof useCustomerKeywordSelectorQuery>;
export type CustomerKeywordSelectorLazyQueryHookResult = ReturnType<typeof useCustomerKeywordSelectorLazyQuery>;
export type CustomerKeywordSelectorQueryResult = ApolloReactCommon.QueryResult<
  CustomerKeywordSelectorQuery,
  CustomerKeywordSelectorQueryVariables
>;
export const CreateCustomerNoteDocument = gql`
  mutation CreateCustomerNote($input: CreateCustomerNoteInput!) {
    createCustomerNote(input: $input) {
      note {
        id
      }
    }
  }
`;
export type CreateCustomerNoteMutationFn = ApolloReactCommon.MutationFunction<
  CreateCustomerNoteMutation,
  CreateCustomerNoteMutationVariables
>;

/**
 * __useCreateCustomerNoteMutation__
 *
 * To run a mutation, you first call `useCreateCustomerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerNoteMutation, { data, loading, error }] = useCreateCustomerNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerNoteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerNoteMutation, CreateCustomerNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCustomerNoteMutation, CreateCustomerNoteMutationVariables>(
    CreateCustomerNoteDocument,
    options
  );
}
export type CreateCustomerNoteMutationHookResult = ReturnType<typeof useCreateCustomerNoteMutation>;
export type CreateCustomerNoteMutationResult = ApolloReactCommon.MutationResult<CreateCustomerNoteMutation>;
export type CreateCustomerNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCustomerNoteMutation,
  CreateCustomerNoteMutationVariables
>;
export const UpdateCustomerNoteDocument = gql`
  mutation UpdateCustomerNote($input: UpdateCustomerNoteInput!) {
    updateCustomerNote(input: $input) {
      note {
        id
      }
    }
  }
`;
export type UpdateCustomerNoteMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCustomerNoteMutation,
  UpdateCustomerNoteMutationVariables
>;

/**
 * __useUpdateCustomerNoteMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerNoteMutation, { data, loading, error }] = useUpdateCustomerNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerNoteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerNoteMutation, UpdateCustomerNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCustomerNoteMutation, UpdateCustomerNoteMutationVariables>(
    UpdateCustomerNoteDocument,
    options
  );
}
export type UpdateCustomerNoteMutationHookResult = ReturnType<typeof useUpdateCustomerNoteMutation>;
export type UpdateCustomerNoteMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerNoteMutation>;
export type UpdateCustomerNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCustomerNoteMutation,
  UpdateCustomerNoteMutationVariables
>;
export const CustomerNotesDocument = gql`
  query CustomerNotes($customerId: Int!, $limit: Int, $offset: Int) {
    customer(id: $customerId) {
      id
      notes(limit: $limit, offset: $offset) {
        totalCount
        nodes {
          ...CustomerNotes_Note
        }
      }
    }
  }
  ${CustomerNotes_NoteFragmentDoc}
`;

/**
 * __useCustomerNotesQuery__
 *
 * To run a query within a React component, call `useCustomerNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerNotesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useCustomerNotesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CustomerNotesQuery, CustomerNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerNotesQuery, CustomerNotesQueryVariables>(CustomerNotesDocument, options);
}
export function useCustomerNotesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerNotesQuery, CustomerNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerNotesQuery, CustomerNotesQueryVariables>(CustomerNotesDocument, options);
}
export type CustomerNotesQueryHookResult = ReturnType<typeof useCustomerNotesQuery>;
export type CustomerNotesLazyQueryHookResult = ReturnType<typeof useCustomerNotesLazyQuery>;
export type CustomerNotesQueryResult = ApolloReactCommon.QueryResult<CustomerNotesQuery, CustomerNotesQueryVariables>;
export const CustomersForSelectorDocument = gql`
  query CustomersForSelector($search: String, $partnerIds: [Int!], $statusIds: [String!]) {
    customers(search: $search, partnerIds: $partnerIds, sort: NAME_ASC, statusIds: $statusIds) {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useCustomersForSelectorQuery__
 *
 * To run a query within a React component, call `useCustomersForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersForSelectorQuery({
 *   variables: {
 *      search: // value for 'search'
 *      partnerIds: // value for 'partnerIds'
 *      statusIds: // value for 'statusIds'
 *   },
 * });
 */
export function useCustomersForSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CustomersForSelectorQuery, CustomersForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomersForSelectorQuery, CustomersForSelectorQueryVariables>(
    CustomersForSelectorDocument,
    options
  );
}
export function useCustomersForSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomersForSelectorQuery, CustomersForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomersForSelectorQuery, CustomersForSelectorQueryVariables>(
    CustomersForSelectorDocument,
    options
  );
}
export type CustomersForSelectorQueryHookResult = ReturnType<typeof useCustomersForSelectorQuery>;
export type CustomersForSelectorLazyQueryHookResult = ReturnType<typeof useCustomersForSelectorLazyQuery>;
export type CustomersForSelectorQueryResult = ApolloReactCommon.QueryResult<
  CustomersForSelectorQuery,
  CustomersForSelectorQueryVariables
>;
export const DomainStatusesDocument = gql`
  query DomainStatuses {
    domainStatuses {
      id
      name
    }
  }
`;

/**
 * __useDomainStatusesQuery__
 *
 * To run a query within a React component, call `useDomainStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDomainStatusesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DomainStatusesQuery, DomainStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DomainStatusesQuery, DomainStatusesQueryVariables>(DomainStatusesDocument, options);
}
export function useDomainStatusesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DomainStatusesQuery, DomainStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DomainStatusesQuery, DomainStatusesQueryVariables>(
    DomainStatusesDocument,
    options
  );
}
export type DomainStatusesQueryHookResult = ReturnType<typeof useDomainStatusesQuery>;
export type DomainStatusesLazyQueryHookResult = ReturnType<typeof useDomainStatusesLazyQuery>;
export type DomainStatusesQueryResult = ApolloReactCommon.QueryResult<
  DomainStatusesQuery,
  DomainStatusesQueryVariables
>;
export const AmTaskDocument = gql`
  query AmTask($amTaskId: Int!) {
    amTask(id: $amTaskId) {
      id
      taskType
      info
      completedAt
    }
  }
`;

/**
 * __useAmTaskQuery__
 *
 * To run a query within a React component, call `useAmTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAmTaskQuery({
 *   variables: {
 *      amTaskId: // value for 'amTaskId'
 *   },
 * });
 */
export function useAmTaskQuery(baseOptions: ApolloReactHooks.QueryHookOptions<AmTaskQuery, AmTaskQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AmTaskQuery, AmTaskQueryVariables>(AmTaskDocument, options);
}
export function useAmTaskLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AmTaskQuery, AmTaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AmTaskQuery, AmTaskQueryVariables>(AmTaskDocument, options);
}
export type AmTaskQueryHookResult = ReturnType<typeof useAmTaskQuery>;
export type AmTaskLazyQueryHookResult = ReturnType<typeof useAmTaskLazyQuery>;
export type AmTaskQueryResult = ApolloReactCommon.QueryResult<AmTaskQuery, AmTaskQueryVariables>;
export const UpdateAmTaskDocument = gql`
  mutation UpdateAmTask($input: UpdateAmTaskInput!) {
    updateAmTask(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type UpdateAmTaskMutationFn = ApolloReactCommon.MutationFunction<
  UpdateAmTaskMutation,
  UpdateAmTaskMutationVariables
>;

/**
 * __useUpdateAmTaskMutation__
 *
 * To run a mutation, you first call `useUpdateAmTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAmTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAmTaskMutation, { data, loading, error }] = useUpdateAmTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAmTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAmTaskMutation, UpdateAmTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateAmTaskMutation, UpdateAmTaskMutationVariables>(
    UpdateAmTaskDocument,
    options
  );
}
export type UpdateAmTaskMutationHookResult = ReturnType<typeof useUpdateAmTaskMutation>;
export type UpdateAmTaskMutationResult = ApolloReactCommon.MutationResult<UpdateAmTaskMutation>;
export type UpdateAmTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateAmTaskMutation,
  UpdateAmTaskMutationVariables
>;
export const UpdateCategoryDocument = gql`
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      category {
        id
      }
    }
  }
`;
export type UpdateCategoryMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables
>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(
    UpdateCategoryDocument,
    options
  );
}
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables
>;
export const EditCategoryDrawerDocument = gql`
  query EditCategoryDrawer($id: Int!) {
    category(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useEditCategoryDrawerQuery__
 *
 * To run a query within a React component, call `useEditCategoryDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditCategoryDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditCategoryDrawerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditCategoryDrawerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EditCategoryDrawerQuery, EditCategoryDrawerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EditCategoryDrawerQuery, EditCategoryDrawerQueryVariables>(
    EditCategoryDrawerDocument,
    options
  );
}
export function useEditCategoryDrawerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EditCategoryDrawerQuery, EditCategoryDrawerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EditCategoryDrawerQuery, EditCategoryDrawerQueryVariables>(
    EditCategoryDrawerDocument,
    options
  );
}
export type EditCategoryDrawerQueryHookResult = ReturnType<typeof useEditCategoryDrawerQuery>;
export type EditCategoryDrawerLazyQueryHookResult = ReturnType<typeof useEditCategoryDrawerLazyQuery>;
export type EditCategoryDrawerQueryResult = ApolloReactCommon.QueryResult<
  EditCategoryDrawerQuery,
  EditCategoryDrawerQueryVariables
>;
export const UpdateClientCampaignDocument = gql`
  mutation UpdateClientCampaign($input: UpdateCustomerCampaignInput!) {
    updateCustomerCampaign(input: $input) {
      customerCampaign {
        id
      }
    }
  }
`;
export type UpdateClientCampaignMutationFn = ApolloReactCommon.MutationFunction<
  UpdateClientCampaignMutation,
  UpdateClientCampaignMutationVariables
>;

/**
 * __useUpdateClientCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateClientCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientCampaignMutation, { data, loading, error }] = useUpdateClientCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateClientCampaignMutation,
    UpdateClientCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateClientCampaignMutation, UpdateClientCampaignMutationVariables>(
    UpdateClientCampaignDocument,
    options
  );
}
export type UpdateClientCampaignMutationHookResult = ReturnType<typeof useUpdateClientCampaignMutation>;
export type UpdateClientCampaignMutationResult = ApolloReactCommon.MutationResult<UpdateClientCampaignMutation>;
export type UpdateClientCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateClientCampaignMutation,
  UpdateClientCampaignMutationVariables
>;
export const EditOrderDrawerDocument = gql`
  query EditOrderDrawer($orderId: Int!) {
    order(id: $orderId) {
      id
      createdAt
      quantity
      topic
      resultUrl
      resultUrlDomainAuthority
      status {
        id
        name
      }
      startDate
      amount
      instructions
      orderIssues {
        title
        message
      }
      customerCampaignId
      partnerProduct {
        id
        name
        product {
          id
          name
          isKeywordRequired
          isTopicRequired
          maxOrderQuantity
        }
      }
      customer {
        id
        name
      }
    }
  }
`;

/**
 * __useEditOrderDrawerQuery__
 *
 * To run a query within a React component, call `useEditOrderDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOrderDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOrderDrawerQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useEditOrderDrawerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EditOrderDrawerQuery, EditOrderDrawerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EditOrderDrawerQuery, EditOrderDrawerQueryVariables>(
    EditOrderDrawerDocument,
    options
  );
}
export function useEditOrderDrawerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EditOrderDrawerQuery, EditOrderDrawerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EditOrderDrawerQuery, EditOrderDrawerQueryVariables>(
    EditOrderDrawerDocument,
    options
  );
}
export type EditOrderDrawerQueryHookResult = ReturnType<typeof useEditOrderDrawerQuery>;
export type EditOrderDrawerLazyQueryHookResult = ReturnType<typeof useEditOrderDrawerLazyQuery>;
export type EditOrderDrawerQueryResult = ApolloReactCommon.QueryResult<
  EditOrderDrawerQuery,
  EditOrderDrawerQueryVariables
>;
export const EditOrderDrawer_UpdateOrderDocument = gql`
  mutation EditOrderDrawer_UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      order {
        id
      }
    }
  }
`;
export type EditOrderDrawer_UpdateOrderMutationFn = ApolloReactCommon.MutationFunction<
  EditOrderDrawer_UpdateOrderMutation,
  EditOrderDrawer_UpdateOrderMutationVariables
>;

/**
 * __useEditOrderDrawer_UpdateOrderMutation__
 *
 * To run a mutation, you first call `useEditOrderDrawer_UpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrderDrawer_UpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrderDrawerUpdateOrderMutation, { data, loading, error }] = useEditOrderDrawer_UpdateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrderDrawer_UpdateOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditOrderDrawer_UpdateOrderMutation,
    EditOrderDrawer_UpdateOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    EditOrderDrawer_UpdateOrderMutation,
    EditOrderDrawer_UpdateOrderMutationVariables
  >(EditOrderDrawer_UpdateOrderDocument, options);
}
export type EditOrderDrawer_UpdateOrderMutationHookResult = ReturnType<typeof useEditOrderDrawer_UpdateOrderMutation>;
export type EditOrderDrawer_UpdateOrderMutationResult =
  ApolloReactCommon.MutationResult<EditOrderDrawer_UpdateOrderMutation>;
export type EditOrderDrawer_UpdateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EditOrderDrawer_UpdateOrderMutation,
  EditOrderDrawer_UpdateOrderMutationVariables
>;
export const CreatePartnerProductWholesalePriceDocument = gql`
  mutation CreatePartnerProductWholesalePrice($input: CreatePartnerProductWholesalePriceInput!) {
    createPartnerProductWholesalePrice(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
      partnerProductWholesalePrice {
        id
      }
    }
  }
`;
export type CreatePartnerProductWholesalePriceMutationFn = ApolloReactCommon.MutationFunction<
  CreatePartnerProductWholesalePriceMutation,
  CreatePartnerProductWholesalePriceMutationVariables
>;

/**
 * __useCreatePartnerProductWholesalePriceMutation__
 *
 * To run a mutation, you first call `useCreatePartnerProductWholesalePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerProductWholesalePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerProductWholesalePriceMutation, { data, loading, error }] = useCreatePartnerProductWholesalePriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartnerProductWholesalePriceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePartnerProductWholesalePriceMutation,
    CreatePartnerProductWholesalePriceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreatePartnerProductWholesalePriceMutation,
    CreatePartnerProductWholesalePriceMutationVariables
  >(CreatePartnerProductWholesalePriceDocument, options);
}
export type CreatePartnerProductWholesalePriceMutationHookResult = ReturnType<
  typeof useCreatePartnerProductWholesalePriceMutation
>;
export type CreatePartnerProductWholesalePriceMutationResult =
  ApolloReactCommon.MutationResult<CreatePartnerProductWholesalePriceMutation>;
export type CreatePartnerProductWholesalePriceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePartnerProductWholesalePriceMutation,
  CreatePartnerProductWholesalePriceMutationVariables
>;
export const DeletePartnerProductWholesalePriceDocument = gql`
  mutation DeletePartnerProductWholesalePrice($input: DeletePartnerProductWholesalePriceInput!) {
    deletePartnerProductWholesalePrice(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type DeletePartnerProductWholesalePriceMutationFn = ApolloReactCommon.MutationFunction<
  DeletePartnerProductWholesalePriceMutation,
  DeletePartnerProductWholesalePriceMutationVariables
>;

/**
 * __useDeletePartnerProductWholesalePriceMutation__
 *
 * To run a mutation, you first call `useDeletePartnerProductWholesalePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnerProductWholesalePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerProductWholesalePriceMutation, { data, loading, error }] = useDeletePartnerProductWholesalePriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePartnerProductWholesalePriceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePartnerProductWholesalePriceMutation,
    DeletePartnerProductWholesalePriceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeletePartnerProductWholesalePriceMutation,
    DeletePartnerProductWholesalePriceMutationVariables
  >(DeletePartnerProductWholesalePriceDocument, options);
}
export type DeletePartnerProductWholesalePriceMutationHookResult = ReturnType<
  typeof useDeletePartnerProductWholesalePriceMutation
>;
export type DeletePartnerProductWholesalePriceMutationResult =
  ApolloReactCommon.MutationResult<DeletePartnerProductWholesalePriceMutation>;
export type DeletePartnerProductWholesalePriceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePartnerProductWholesalePriceMutation,
  DeletePartnerProductWholesalePriceMutationVariables
>;
export const EditPartnerAvailableProductDocument = gql`
  query EditPartnerAvailableProduct($partnerAvailableProductId: Int!) {
    partnerAvailableProduct(id: $partnerAvailableProductId) {
      id
      partner {
        id
        name
      }
      product {
        id
        name
      }
      partnerProductWholesalePrices(sort: EFFECTIVE_AT_ASC) {
        nodes {
          id
          wholesaleAmount
          effectiveAt
          isCurrent
          canDelete
        }
      }
    }
  }
`;

/**
 * __useEditPartnerAvailableProductQuery__
 *
 * To run a query within a React component, call `useEditPartnerAvailableProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPartnerAvailableProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPartnerAvailableProductQuery({
 *   variables: {
 *      partnerAvailableProductId: // value for 'partnerAvailableProductId'
 *   },
 * });
 */
export function useEditPartnerAvailableProductQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    EditPartnerAvailableProductQuery,
    EditPartnerAvailableProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EditPartnerAvailableProductQuery, EditPartnerAvailableProductQueryVariables>(
    EditPartnerAvailableProductDocument,
    options
  );
}
export function useEditPartnerAvailableProductLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EditPartnerAvailableProductQuery,
    EditPartnerAvailableProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EditPartnerAvailableProductQuery, EditPartnerAvailableProductQueryVariables>(
    EditPartnerAvailableProductDocument,
    options
  );
}
export type EditPartnerAvailableProductQueryHookResult = ReturnType<typeof useEditPartnerAvailableProductQuery>;
export type EditPartnerAvailableProductLazyQueryHookResult = ReturnType<typeof useEditPartnerAvailableProductLazyQuery>;
export type EditPartnerAvailableProductQueryResult = ApolloReactCommon.QueryResult<
  EditPartnerAvailableProductQuery,
  EditPartnerAvailableProductQueryVariables
>;
export const UpdatePartnerProductWholesalePriceDocument = gql`
  mutation UpdatePartnerProductWholesalePrice($input: UpdatePartnerProductWholesalePriceInput!) {
    updatePartnerProductWholesalePrice(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
      partnerProductWholesalePrice {
        id
      }
    }
  }
`;
export type UpdatePartnerProductWholesalePriceMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePartnerProductWholesalePriceMutation,
  UpdatePartnerProductWholesalePriceMutationVariables
>;

/**
 * __useUpdatePartnerProductWholesalePriceMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerProductWholesalePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerProductWholesalePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerProductWholesalePriceMutation, { data, loading, error }] = useUpdatePartnerProductWholesalePriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartnerProductWholesalePriceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePartnerProductWholesalePriceMutation,
    UpdatePartnerProductWholesalePriceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdatePartnerProductWholesalePriceMutation,
    UpdatePartnerProductWholesalePriceMutationVariables
  >(UpdatePartnerProductWholesalePriceDocument, options);
}
export type UpdatePartnerProductWholesalePriceMutationHookResult = ReturnType<
  typeof useUpdatePartnerProductWholesalePriceMutation
>;
export type UpdatePartnerProductWholesalePriceMutationResult =
  ApolloReactCommon.MutationResult<UpdatePartnerProductWholesalePriceMutation>;
export type UpdatePartnerProductWholesalePriceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePartnerProductWholesalePriceMutation,
  UpdatePartnerProductWholesalePriceMutationVariables
>;
export const UpdatePartnerDocument = gql`
  mutation UpdatePartner($input: UpdatePartnerInput!) {
    updatePartner(input: $input) {
      partner {
        id
      }
    }
  }
`;
export type UpdatePartnerMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePartnerMutation,
  UpdatePartnerMutationVariables
>;

/**
 * __useUpdatePartnerMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerMutation, { data, loading, error }] = useUpdatePartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePartnerMutation, UpdatePartnerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePartnerMutation, UpdatePartnerMutationVariables>(
    UpdatePartnerDocument,
    options
  );
}
export type UpdatePartnerMutationHookResult = ReturnType<typeof useUpdatePartnerMutation>;
export type UpdatePartnerMutationResult = ApolloReactCommon.MutationResult<UpdatePartnerMutation>;
export type UpdatePartnerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePartnerMutation,
  UpdatePartnerMutationVariables
>;
export const EditPartnerDrawerDocument = gql`
  query EditPartnerDrawer($id: Int!) {
    partner(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useEditPartnerDrawerQuery__
 *
 * To run a query within a React component, call `useEditPartnerDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPartnerDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPartnerDrawerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditPartnerDrawerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EditPartnerDrawerQuery, EditPartnerDrawerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EditPartnerDrawerQuery, EditPartnerDrawerQueryVariables>(
    EditPartnerDrawerDocument,
    options
  );
}
export function useEditPartnerDrawerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EditPartnerDrawerQuery, EditPartnerDrawerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EditPartnerDrawerQuery, EditPartnerDrawerQueryVariables>(
    EditPartnerDrawerDocument,
    options
  );
}
export type EditPartnerDrawerQueryHookResult = ReturnType<typeof useEditPartnerDrawerQuery>;
export type EditPartnerDrawerLazyQueryHookResult = ReturnType<typeof useEditPartnerDrawerLazyQuery>;
export type EditPartnerDrawerQueryResult = ApolloReactCommon.QueryResult<
  EditPartnerDrawerQuery,
  EditPartnerDrawerQueryVariables
>;
export const UpdatePartnerProductDocument = gql`
  mutation UpdatePartnerProduct($input: UpdatePartnerProductInput!) {
    updatePartnerProduct(input: $input) {
      partnerProduct {
        id
      }
    }
  }
`;
export type UpdatePartnerProductMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePartnerProductMutation,
  UpdatePartnerProductMutationVariables
>;

/**
 * __useUpdatePartnerProductMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerProductMutation, { data, loading, error }] = useUpdatePartnerProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartnerProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePartnerProductMutation,
    UpdatePartnerProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePartnerProductMutation, UpdatePartnerProductMutationVariables>(
    UpdatePartnerProductDocument,
    options
  );
}
export type UpdatePartnerProductMutationHookResult = ReturnType<typeof useUpdatePartnerProductMutation>;
export type UpdatePartnerProductMutationResult = ApolloReactCommon.MutationResult<UpdatePartnerProductMutation>;
export type UpdatePartnerProductMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePartnerProductMutation,
  UpdatePartnerProductMutationVariables
>;
export const EditPartnerProductDocument = gql`
  query EditPartnerProduct($id: Int!) {
    partnerProduct(id: $id) {
      id
      name
      activeAt
      inactiveAt
      partnerId
      vendorId
      productId
      checklistDefinitionId
      wholesalePrice
      currentPartnerProductPrice {
        id
        price
      }
      product {
        id
        name
        isChecklistRequired
        isVendorRequired
      }
    }
  }
`;

/**
 * __useEditPartnerProductQuery__
 *
 * To run a query within a React component, call `useEditPartnerProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditPartnerProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditPartnerProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditPartnerProductQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EditPartnerProductQuery, EditPartnerProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EditPartnerProductQuery, EditPartnerProductQueryVariables>(
    EditPartnerProductDocument,
    options
  );
}
export function useEditPartnerProductLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EditPartnerProductQuery, EditPartnerProductQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EditPartnerProductQuery, EditPartnerProductQueryVariables>(
    EditPartnerProductDocument,
    options
  );
}
export type EditPartnerProductQueryHookResult = ReturnType<typeof useEditPartnerProductQuery>;
export type EditPartnerProductLazyQueryHookResult = ReturnType<typeof useEditPartnerProductLazyQuery>;
export type EditPartnerProductQueryResult = ApolloReactCommon.QueryResult<
  EditPartnerProductQuery,
  EditPartnerProductQueryVariables
>;
export const UpdateVendorDocument = gql`
  mutation UpdateVendor($input: UpdateVendorInput!) {
    updateVendor(input: $input) {
      vendor {
        id
      }
    }
  }
`;
export type UpdateVendorMutationFn = ApolloReactCommon.MutationFunction<
  UpdateVendorMutation,
  UpdateVendorMutationVariables
>;

/**
 * __useUpdateVendorMutation__
 *
 * To run a mutation, you first call `useUpdateVendorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorMutation, { data, loading, error }] = useUpdateVendorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVendorMutation, UpdateVendorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateVendorMutation, UpdateVendorMutationVariables>(
    UpdateVendorDocument,
    options
  );
}
export type UpdateVendorMutationHookResult = ReturnType<typeof useUpdateVendorMutation>;
export type UpdateVendorMutationResult = ApolloReactCommon.MutationResult<UpdateVendorMutation>;
export type UpdateVendorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateVendorMutation,
  UpdateVendorMutationVariables
>;
export const EditVendorDrawerDocument = gql`
  query EditVendorDrawer($id: Int!) {
    vendor(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useEditVendorDrawerQuery__
 *
 * To run a query within a React component, call `useEditVendorDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditVendorDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditVendorDrawerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditVendorDrawerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EditVendorDrawerQuery, EditVendorDrawerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EditVendorDrawerQuery, EditVendorDrawerQueryVariables>(
    EditVendorDrawerDocument,
    options
  );
}
export function useEditVendorDrawerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EditVendorDrawerQuery, EditVendorDrawerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EditVendorDrawerQuery, EditVendorDrawerQueryVariables>(
    EditVendorDrawerDocument,
    options
  );
}
export type EditVendorDrawerQueryHookResult = ReturnType<typeof useEditVendorDrawerQuery>;
export type EditVendorDrawerLazyQueryHookResult = ReturnType<typeof useEditVendorDrawerLazyQuery>;
export type EditVendorDrawerQueryResult = ApolloReactCommon.QueryResult<
  EditVendorDrawerQuery,
  EditVendorDrawerQueryVariables
>;
export const EmailMessageModalDocument = gql`
  query EmailMessageModal($emailMessageId: Int!) {
    emailMessage(id: $emailMessageId) {
      id
      createdAt
      sentAt
      toName
      toAddress
      fromName
      fromAddress
      subject
      content
    }
  }
`;

/**
 * __useEmailMessageModalQuery__
 *
 * To run a query within a React component, call `useEmailMessageModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailMessageModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailMessageModalQuery({
 *   variables: {
 *      emailMessageId: // value for 'emailMessageId'
 *   },
 * });
 */
export function useEmailMessageModalQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<EmailMessageModalQuery, EmailMessageModalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EmailMessageModalQuery, EmailMessageModalQueryVariables>(
    EmailMessageModalDocument,
    options
  );
}
export function useEmailMessageModalLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EmailMessageModalQuery, EmailMessageModalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EmailMessageModalQuery, EmailMessageModalQueryVariables>(
    EmailMessageModalDocument,
    options
  );
}
export type EmailMessageModalQueryHookResult = ReturnType<typeof useEmailMessageModalQuery>;
export type EmailMessageModalLazyQueryHookResult = ReturnType<typeof useEmailMessageModalLazyQuery>;
export type EmailMessageModalQueryResult = ApolloReactCommon.QueryResult<
  EmailMessageModalQuery,
  EmailMessageModalQueryVariables
>;
export const ForceDeleteOrderModal_DeleteOrderDocument = gql`
  mutation ForceDeleteOrderModal_DeleteOrder($input: DeleteOrderInput!) {
    deleteOrder(input: $input) {
      isDeleted
    }
  }
`;
export type ForceDeleteOrderModal_DeleteOrderMutationFn = ApolloReactCommon.MutationFunction<
  ForceDeleteOrderModal_DeleteOrderMutation,
  ForceDeleteOrderModal_DeleteOrderMutationVariables
>;

/**
 * __useForceDeleteOrderModal_DeleteOrderMutation__
 *
 * To run a mutation, you first call `useForceDeleteOrderModal_DeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteOrderModal_DeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteOrderModalDeleteOrderMutation, { data, loading, error }] = useForceDeleteOrderModal_DeleteOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForceDeleteOrderModal_DeleteOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ForceDeleteOrderModal_DeleteOrderMutation,
    ForceDeleteOrderModal_DeleteOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ForceDeleteOrderModal_DeleteOrderMutation,
    ForceDeleteOrderModal_DeleteOrderMutationVariables
  >(ForceDeleteOrderModal_DeleteOrderDocument, options);
}
export type ForceDeleteOrderModal_DeleteOrderMutationHookResult = ReturnType<
  typeof useForceDeleteOrderModal_DeleteOrderMutation
>;
export type ForceDeleteOrderModal_DeleteOrderMutationResult =
  ApolloReactCommon.MutationResult<ForceDeleteOrderModal_DeleteOrderMutation>;
export type ForceDeleteOrderModal_DeleteOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ForceDeleteOrderModal_DeleteOrderMutation,
  ForceDeleteOrderModal_DeleteOrderMutationVariables
>;
export const LinkLocationStatusesDocument = gql`
  query LinkLocationStatuses {
    linkLocationStatuses {
      id
      name
    }
  }
`;

/**
 * __useLinkLocationStatusesQuery__
 *
 * To run a query within a React component, call `useLinkLocationStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkLocationStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkLocationStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinkLocationStatusesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LinkLocationStatusesQuery, LinkLocationStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LinkLocationStatusesQuery, LinkLocationStatusesQueryVariables>(
    LinkLocationStatusesDocument,
    options
  );
}
export function useLinkLocationStatusesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LinkLocationStatusesQuery, LinkLocationStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LinkLocationStatusesQuery, LinkLocationStatusesQueryVariables>(
    LinkLocationStatusesDocument,
    options
  );
}
export type LinkLocationStatusesQueryHookResult = ReturnType<typeof useLinkLocationStatusesQuery>;
export type LinkLocationStatusesLazyQueryHookResult = ReturnType<typeof useLinkLocationStatusesLazyQuery>;
export type LinkLocationStatusesQueryResult = ApolloReactCommon.QueryResult<
  LinkLocationStatusesQuery,
  LinkLocationStatusesQueryVariables
>;
export const LinkLocationTypesDocument = gql`
  query LinkLocationTypes {
    linkLocationTypes {
      id
      name
    }
  }
`;

/**
 * __useLinkLocationTypesQuery__
 *
 * To run a query within a React component, call `useLinkLocationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkLocationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkLocationTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinkLocationTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LinkLocationTypesQuery, LinkLocationTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LinkLocationTypesQuery, LinkLocationTypesQueryVariables>(
    LinkLocationTypesDocument,
    options
  );
}
export function useLinkLocationTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LinkLocationTypesQuery, LinkLocationTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LinkLocationTypesQuery, LinkLocationTypesQueryVariables>(
    LinkLocationTypesDocument,
    options
  );
}
export type LinkLocationTypesQueryHookResult = ReturnType<typeof useLinkLocationTypesQuery>;
export type LinkLocationTypesLazyQueryHookResult = ReturnType<typeof useLinkLocationTypesLazyQuery>;
export type LinkLocationTypesQueryResult = ApolloReactCommon.QueryResult<
  LinkLocationTypesQuery,
  LinkLocationTypesQueryVariables
>;
export const LinkLocationsSelectorDocument = gql`
  query LinkLocationsSelector($typeIds: [String!]) {
    linkLocations(typeIds: $typeIds) {
      nodes {
        id
        domain
      }
    }
  }
`;

/**
 * __useLinkLocationsSelectorQuery__
 *
 * To run a query within a React component, call `useLinkLocationsSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkLocationsSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkLocationsSelectorQuery({
 *   variables: {
 *      typeIds: // value for 'typeIds'
 *   },
 * });
 */
export function useLinkLocationsSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LinkLocationsSelectorQuery, LinkLocationsSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LinkLocationsSelectorQuery, LinkLocationsSelectorQueryVariables>(
    LinkLocationsSelectorDocument,
    options
  );
}
export function useLinkLocationsSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LinkLocationsSelectorQuery, LinkLocationsSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LinkLocationsSelectorQuery, LinkLocationsSelectorQueryVariables>(
    LinkLocationsSelectorDocument,
    options
  );
}
export type LinkLocationsSelectorQueryHookResult = ReturnType<typeof useLinkLocationsSelectorQuery>;
export type LinkLocationsSelectorLazyQueryHookResult = ReturnType<typeof useLinkLocationsSelectorLazyQuery>;
export type LinkLocationsSelectorQueryResult = ApolloReactCommon.QueryResult<
  LinkLocationsSelectorQuery,
  LinkLocationsSelectorQueryVariables
>;
export const LinkLocationsUsedDocument = gql`
  query LinkLocationsUsed(
    $customerId: Int!
    $limit: Int
    $offset: Int
    $search: String
    $statusIds: [String!]
    $typeIds: [String!]
    $sort: LinkLocationsSort
    $pendingLimit: Int
    $pendingOffset: Int
    $pendingSearch: String
    $pendingTypeIds: [String!]
    $pendingSort: LinkLocationsSort
    $rejectedLimit: Int
    $rejectedOffset: Int
    $rejectedSearch: String
    $rejectedTypeIds: [String!]
    $rejectedSort: LinkLocationsSort
  ) {
    customer(id: $customerId) {
      id
      linkLocationsUsed(
        limit: $limit
        offset: $offset
        search: $search
        statusIds: $statusIds
        typeIds: $typeIds
        sort: $sort
      ) {
        ...LinkLocation_LinkLocationConnection
      }
      linkLocationsPending(
        limit: $pendingLimit
        offset: $pendingOffset
        search: $pendingSearch
        typeIds: $pendingTypeIds
        sort: $pendingSort
      ) {
        ...LinkLocation_LinkLocationConnection
      }
      linkLocationsRejected(
        limit: $rejectedLimit
        offset: $rejectedOffset
        search: $rejectedSearch
        typeIds: $rejectedTypeIds
        sort: $rejectedSort
      ) {
        ...LinkLocation_LinkLocationConnection
      }
    }
  }
  ${LinkLocation_LinkLocationConnectionFragmentDoc}
`;

/**
 * __useLinkLocationsUsedQuery__
 *
 * To run a query within a React component, call `useLinkLocationsUsedQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkLocationsUsedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkLocationsUsedQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      statusIds: // value for 'statusIds'
 *      typeIds: // value for 'typeIds'
 *      sort: // value for 'sort'
 *      pendingLimit: // value for 'pendingLimit'
 *      pendingOffset: // value for 'pendingOffset'
 *      pendingSearch: // value for 'pendingSearch'
 *      pendingTypeIds: // value for 'pendingTypeIds'
 *      pendingSort: // value for 'pendingSort'
 *      rejectedLimit: // value for 'rejectedLimit'
 *      rejectedOffset: // value for 'rejectedOffset'
 *      rejectedSearch: // value for 'rejectedSearch'
 *      rejectedTypeIds: // value for 'rejectedTypeIds'
 *      rejectedSort: // value for 'rejectedSort'
 *   },
 * });
 */
export function useLinkLocationsUsedQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LinkLocationsUsedQuery, LinkLocationsUsedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LinkLocationsUsedQuery, LinkLocationsUsedQueryVariables>(
    LinkLocationsUsedDocument,
    options
  );
}
export function useLinkLocationsUsedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LinkLocationsUsedQuery, LinkLocationsUsedQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LinkLocationsUsedQuery, LinkLocationsUsedQueryVariables>(
    LinkLocationsUsedDocument,
    options
  );
}
export type LinkLocationsUsedQueryHookResult = ReturnType<typeof useLinkLocationsUsedQuery>;
export type LinkLocationsUsedLazyQueryHookResult = ReturnType<typeof useLinkLocationsUsedLazyQuery>;
export type LinkLocationsUsedQueryResult = ApolloReactCommon.QueryResult<
  LinkLocationsUsedQuery,
  LinkLocationsUsedQueryVariables
>;
export const LinkLocationsAvailableDocument = gql`
  query LinkLocationsAvailable(
    $limit: Int
    $offset: Int
    $search: String
    $statusIds: [String!]
    $typeIds: [String!]
    $minDa: Int
    $minTraffic: Int
    $excludeCustomerId: Int
    $categoryId: Int
    $publisherId: Int
    $sort: LinkLocationsSort
    $secondSort: LinkLocationsSort
  ) {
    linkLocations(
      limit: $limit
      offset: $offset
      search: $search
      statusIds: $statusIds
      typeIds: $typeIds
      minDa: $minDa
      minTraffic: $minTraffic
      excludeCustomerId: $excludeCustomerId
      categoryId: $categoryId
      publisherId: $publisherId
      sort: $sort
      secondSort: $secondSort
      associatedToPublisher: true
    ) {
      ...LinkLocation_LinkLocationConnection
    }
  }
  ${LinkLocation_LinkLocationConnectionFragmentDoc}
`;

/**
 * __useLinkLocationsAvailableQuery__
 *
 * To run a query within a React component, call `useLinkLocationsAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkLocationsAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkLocationsAvailableQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      statusIds: // value for 'statusIds'
 *      typeIds: // value for 'typeIds'
 *      minDa: // value for 'minDa'
 *      minTraffic: // value for 'minTraffic'
 *      excludeCustomerId: // value for 'excludeCustomerId'
 *      categoryId: // value for 'categoryId'
 *      publisherId: // value for 'publisherId'
 *      sort: // value for 'sort'
 *      secondSort: // value for 'secondSort'
 *   },
 * });
 */
export function useLinkLocationsAvailableQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LinkLocationsAvailableQuery, LinkLocationsAvailableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LinkLocationsAvailableQuery, LinkLocationsAvailableQueryVariables>(
    LinkLocationsAvailableDocument,
    options
  );
}
export function useLinkLocationsAvailableLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LinkLocationsAvailableQuery, LinkLocationsAvailableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LinkLocationsAvailableQuery, LinkLocationsAvailableQueryVariables>(
    LinkLocationsAvailableDocument,
    options
  );
}
export type LinkLocationsAvailableQueryHookResult = ReturnType<typeof useLinkLocationsAvailableQuery>;
export type LinkLocationsAvailableLazyQueryHookResult = ReturnType<typeof useLinkLocationsAvailableLazyQuery>;
export type LinkLocationsAvailableQueryResult = ApolloReactCommon.QueryResult<
  LinkLocationsAvailableQuery,
  LinkLocationsAvailableQueryVariables
>;
export const UpdateLinkLocationMetricsDocument = gql`
  mutation updateLinkLocationMetrics($input: UpdateLinkLocationMetricsInput!) {
    updateLinkLocationMetrics(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type UpdateLinkLocationMetricsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLinkLocationMetricsMutation,
  UpdateLinkLocationMetricsMutationVariables
>;

/**
 * __useUpdateLinkLocationMetricsMutation__
 *
 * To run a mutation, you first call `useUpdateLinkLocationMetricsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkLocationMetricsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkLocationMetricsMutation, { data, loading, error }] = useUpdateLinkLocationMetricsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLinkLocationMetricsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLinkLocationMetricsMutation,
    UpdateLinkLocationMetricsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateLinkLocationMetricsMutation, UpdateLinkLocationMetricsMutationVariables>(
    UpdateLinkLocationMetricsDocument,
    options
  );
}
export type UpdateLinkLocationMetricsMutationHookResult = ReturnType<typeof useUpdateLinkLocationMetricsMutation>;
export type UpdateLinkLocationMetricsMutationResult =
  ApolloReactCommon.MutationResult<UpdateLinkLocationMetricsMutation>;
export type UpdateLinkLocationMetricsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLinkLocationMetricsMutation,
  UpdateLinkLocationMetricsMutationVariables
>;
export const CreatePartnerProductPriceDocument = gql`
  mutation CreatePartnerProductPrice($input: CreatePartnerProductPriceInput!) {
    createPartnerProductPrice(input: $input) {
      partnerProductPrice {
        id
      }
    }
  }
`;
export type CreatePartnerProductPriceMutationFn = ApolloReactCommon.MutationFunction<
  CreatePartnerProductPriceMutation,
  CreatePartnerProductPriceMutationVariables
>;

/**
 * __useCreatePartnerProductPriceMutation__
 *
 * To run a mutation, you first call `useCreatePartnerProductPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerProductPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerProductPriceMutation, { data, loading, error }] = useCreatePartnerProductPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartnerProductPriceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePartnerProductPriceMutation,
    CreatePartnerProductPriceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreatePartnerProductPriceMutation, CreatePartnerProductPriceMutationVariables>(
    CreatePartnerProductPriceDocument,
    options
  );
}
export type CreatePartnerProductPriceMutationHookResult = ReturnType<typeof useCreatePartnerProductPriceMutation>;
export type CreatePartnerProductPriceMutationResult =
  ApolloReactCommon.MutationResult<CreatePartnerProductPriceMutation>;
export type CreatePartnerProductPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePartnerProductPriceMutation,
  CreatePartnerProductPriceMutationVariables
>;
export const DeletePartnerProductPriceDocument = gql`
  mutation DeletePartnerProductPrice($input: DeletePartnerProductPriceInput!) {
    deletePartnerProductPrice(input: $input) {
      isDeleted
    }
  }
`;
export type DeletePartnerProductPriceMutationFn = ApolloReactCommon.MutationFunction<
  DeletePartnerProductPriceMutation,
  DeletePartnerProductPriceMutationVariables
>;

/**
 * __useDeletePartnerProductPriceMutation__
 *
 * To run a mutation, you first call `useDeletePartnerProductPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnerProductPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerProductPriceMutation, { data, loading, error }] = useDeletePartnerProductPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePartnerProductPriceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePartnerProductPriceMutation,
    DeletePartnerProductPriceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeletePartnerProductPriceMutation, DeletePartnerProductPriceMutationVariables>(
    DeletePartnerProductPriceDocument,
    options
  );
}
export type DeletePartnerProductPriceMutationHookResult = ReturnType<typeof useDeletePartnerProductPriceMutation>;
export type DeletePartnerProductPriceMutationResult =
  ApolloReactCommon.MutationResult<DeletePartnerProductPriceMutation>;
export type DeletePartnerProductPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePartnerProductPriceMutation,
  DeletePartnerProductPriceMutationVariables
>;
export const PartnerProductDetailPricesDocument = gql`
  query PartnerProductDetailPrices($partnerProductId: Int!) {
    partnerProduct(id: $partnerProductId) {
      id
      name
      wholesalePrice
      partnerProductPrices(sort: STARTS_AT_ASC) {
        nodes {
          ...PartnerProductDetailPrice
        }
      }
    }
  }
  ${PartnerProductDetailPriceFragmentDoc}
`;

/**
 * __usePartnerProductDetailPricesQuery__
 *
 * To run a query within a React component, call `usePartnerProductDetailPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerProductDetailPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerProductDetailPricesQuery({
 *   variables: {
 *      partnerProductId: // value for 'partnerProductId'
 *   },
 * });
 */
export function usePartnerProductDetailPricesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PartnerProductDetailPricesQuery,
    PartnerProductDetailPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerProductDetailPricesQuery, PartnerProductDetailPricesQueryVariables>(
    PartnerProductDetailPricesDocument,
    options
  );
}
export function usePartnerProductDetailPricesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PartnerProductDetailPricesQuery,
    PartnerProductDetailPricesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerProductDetailPricesQuery, PartnerProductDetailPricesQueryVariables>(
    PartnerProductDetailPricesDocument,
    options
  );
}
export type PartnerProductDetailPricesQueryHookResult = ReturnType<typeof usePartnerProductDetailPricesQuery>;
export type PartnerProductDetailPricesLazyQueryHookResult = ReturnType<typeof usePartnerProductDetailPricesLazyQuery>;
export type PartnerProductDetailPricesQueryResult = ApolloReactCommon.QueryResult<
  PartnerProductDetailPricesQuery,
  PartnerProductDetailPricesQueryVariables
>;
export const UpdatePartnerProductPriceDocument = gql`
  mutation UpdatePartnerProductPrice($input: UpdatePartnerProductPriceInput!) {
    updatePartnerProductPrice(input: $input) {
      partnerProductPrice {
        id
      }
    }
  }
`;
export type UpdatePartnerProductPriceMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePartnerProductPriceMutation,
  UpdatePartnerProductPriceMutationVariables
>;

/**
 * __useUpdatePartnerProductPriceMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerProductPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerProductPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerProductPriceMutation, { data, loading, error }] = useUpdatePartnerProductPriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartnerProductPriceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePartnerProductPriceMutation,
    UpdatePartnerProductPriceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePartnerProductPriceMutation, UpdatePartnerProductPriceMutationVariables>(
    UpdatePartnerProductPriceDocument,
    options
  );
}
export type UpdatePartnerProductPriceMutationHookResult = ReturnType<typeof useUpdatePartnerProductPriceMutation>;
export type UpdatePartnerProductPriceMutationResult =
  ApolloReactCommon.MutationResult<UpdatePartnerProductPriceMutation>;
export type UpdatePartnerProductPriceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePartnerProductPriceMutation,
  UpdatePartnerProductPriceMutationVariables
>;
export const MapPublisherLinkLocationDocument = gql`
  mutation MapPublisherLinkLocation($input: CreatePublisherLinkLocationInput!) {
    createPublisherLinkLocation(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type MapPublisherLinkLocationMutationFn = ApolloReactCommon.MutationFunction<
  MapPublisherLinkLocationMutation,
  MapPublisherLinkLocationMutationVariables
>;

/**
 * __useMapPublisherLinkLocationMutation__
 *
 * To run a mutation, you first call `useMapPublisherLinkLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMapPublisherLinkLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mapPublisherLinkLocationMutation, { data, loading, error }] = useMapPublisherLinkLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMapPublisherLinkLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MapPublisherLinkLocationMutation,
    MapPublisherLinkLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<MapPublisherLinkLocationMutation, MapPublisherLinkLocationMutationVariables>(
    MapPublisherLinkLocationDocument,
    options
  );
}
export type MapPublisherLinkLocationMutationHookResult = ReturnType<typeof useMapPublisherLinkLocationMutation>;
export type MapPublisherLinkLocationMutationResult = ApolloReactCommon.MutationResult<MapPublisherLinkLocationMutation>;
export type MapPublisherLinkLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  MapPublisherLinkLocationMutation,
  MapPublisherLinkLocationMutationVariables
>;
export const OrderStatusesDocument = gql`
  query OrderStatuses {
    orderStatuses {
      id
      name
    }
  }
`;

/**
 * __useOrderStatusesQuery__
 *
 * To run a query within a React component, call `useOrderStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderStatusesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OrderStatusesQuery, OrderStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrderStatusesQuery, OrderStatusesQueryVariables>(OrderStatusesDocument, options);
}
export function useOrderStatusesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderStatusesQuery, OrderStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrderStatusesQuery, OrderStatusesQueryVariables>(OrderStatusesDocument, options);
}
export type OrderStatusesQueryHookResult = ReturnType<typeof useOrderStatusesQuery>;
export type OrderStatusesLazyQueryHookResult = ReturnType<typeof useOrderStatusesLazyQuery>;
export type OrderStatusesQueryResult = ApolloReactCommon.QueryResult<OrderStatusesQuery, OrderStatusesQueryVariables>;
export const PartnerPackagesForSelectorDocument = gql`
  query PartnerPackagesForSelector($limit: Int, $offset: Int, $partnerId: Int!) {
    partnerPackages(limit: $limit, offset: $offset, includeInactive: false, partnerId: $partnerId) {
      nodes {
        ...PartnerPackageOption
      }
    }
  }
  ${PartnerPackageOptionFragmentDoc}
`;

/**
 * __usePartnerPackagesForSelectorQuery__
 *
 * To run a query within a React component, call `usePartnerPackagesForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerPackagesForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerPackagesForSelectorQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function usePartnerPackagesForSelectorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PartnerPackagesForSelectorQuery,
    PartnerPackagesForSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerPackagesForSelectorQuery, PartnerPackagesForSelectorQueryVariables>(
    PartnerPackagesForSelectorDocument,
    options
  );
}
export function usePartnerPackagesForSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PartnerPackagesForSelectorQuery,
    PartnerPackagesForSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerPackagesForSelectorQuery, PartnerPackagesForSelectorQueryVariables>(
    PartnerPackagesForSelectorDocument,
    options
  );
}
export type PartnerPackagesForSelectorQueryHookResult = ReturnType<typeof usePartnerPackagesForSelectorQuery>;
export type PartnerPackagesForSelectorLazyQueryHookResult = ReturnType<typeof usePartnerPackagesForSelectorLazyQuery>;
export type PartnerPackagesForSelectorQueryResult = ApolloReactCommon.QueryResult<
  PartnerPackagesForSelectorQuery,
  PartnerPackagesForSelectorQueryVariables
>;
export const PartnerPaymentMethodSelectorDocument = gql`
  query PartnerPaymentMethodSelector($partnerId: Int!) {
    partner(id: $partnerId) {
      id
      partnerPaymentMethods {
        totalCount
        nodes {
          id
          partnerPaymentMethodCard {
            brand
            brandDisplayName
            expirationMonth
            expirationYear
            last4Digits
          }
        }
      }
    }
  }
`;

/**
 * __usePartnerPaymentMethodSelectorQuery__
 *
 * To run a query within a React component, call `usePartnerPaymentMethodSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerPaymentMethodSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerPaymentMethodSelectorQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function usePartnerPaymentMethodSelectorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PartnerPaymentMethodSelectorQuery,
    PartnerPaymentMethodSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerPaymentMethodSelectorQuery, PartnerPaymentMethodSelectorQueryVariables>(
    PartnerPaymentMethodSelectorDocument,
    options
  );
}
export function usePartnerPaymentMethodSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PartnerPaymentMethodSelectorQuery,
    PartnerPaymentMethodSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerPaymentMethodSelectorQuery, PartnerPaymentMethodSelectorQueryVariables>(
    PartnerPaymentMethodSelectorDocument,
    options
  );
}
export type PartnerPaymentMethodSelectorQueryHookResult = ReturnType<typeof usePartnerPaymentMethodSelectorQuery>;
export type PartnerPaymentMethodSelectorLazyQueryHookResult = ReturnType<
  typeof usePartnerPaymentMethodSelectorLazyQuery
>;
export type PartnerPaymentMethodSelectorQueryResult = ApolloReactCommon.QueryResult<
  PartnerPaymentMethodSelectorQuery,
  PartnerPaymentMethodSelectorQueryVariables
>;
export const PartnerProductSelectorDocument = gql`
  query PartnerProductSelector($partnerId: Int!, $campaignCategoryId: String) {
    partnerProducts(partnerId: $partnerId, campaignCategoryId: $campaignCategoryId) {
      nodes {
        id
        name
        product {
          id
          name
          isSystemOrdered
        }
      }
    }
  }
`;

/**
 * __usePartnerProductSelectorQuery__
 *
 * To run a query within a React component, call `usePartnerProductSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerProductSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerProductSelectorQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      campaignCategoryId: // value for 'campaignCategoryId'
 *   },
 * });
 */
export function usePartnerProductSelectorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PartnerProductSelectorQuery, PartnerProductSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerProductSelectorQuery, PartnerProductSelectorQueryVariables>(
    PartnerProductSelectorDocument,
    options
  );
}
export function usePartnerProductSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerProductSelectorQuery, PartnerProductSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerProductSelectorQuery, PartnerProductSelectorQueryVariables>(
    PartnerProductSelectorDocument,
    options
  );
}
export type PartnerProductSelectorQueryHookResult = ReturnType<typeof usePartnerProductSelectorQuery>;
export type PartnerProductSelectorLazyQueryHookResult = ReturnType<typeof usePartnerProductSelectorLazyQuery>;
export type PartnerProductSelectorQueryResult = ApolloReactCommon.QueryResult<
  PartnerProductSelectorQuery,
  PartnerProductSelectorQueryVariables
>;
export const PartnerSelectorV2Document = gql`
  query PartnerSelectorV2 {
    partners {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __usePartnerSelectorV2Query__
 *
 * To run a query within a React component, call `usePartnerSelectorV2Query` and pass it any options that fit your needs.
 * When your component renders, `usePartnerSelectorV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerSelectorV2Query({
 *   variables: {
 *   },
 * });
 */
export function usePartnerSelectorV2Query(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PartnerSelectorV2Query, PartnerSelectorV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerSelectorV2Query, PartnerSelectorV2QueryVariables>(
    PartnerSelectorV2Document,
    options
  );
}
export function usePartnerSelectorV2LazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerSelectorV2Query, PartnerSelectorV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerSelectorV2Query, PartnerSelectorV2QueryVariables>(
    PartnerSelectorV2Document,
    options
  );
}
export type PartnerSelectorV2QueryHookResult = ReturnType<typeof usePartnerSelectorV2Query>;
export type PartnerSelectorV2LazyQueryHookResult = ReturnType<typeof usePartnerSelectorV2LazyQuery>;
export type PartnerSelectorV2QueryResult = ApolloReactCommon.QueryResult<
  PartnerSelectorV2Query,
  PartnerSelectorV2QueryVariables
>;
export const PartnerSelectorDocument = gql`
  query PartnerSelector {
    partners {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __usePartnerSelectorQuery__
 *
 * To run a query within a React component, call `usePartnerSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerSelectorQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PartnerSelectorQuery, PartnerSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerSelectorQuery, PartnerSelectorQueryVariables>(
    PartnerSelectorDocument,
    options
  );
}
export function usePartnerSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerSelectorQuery, PartnerSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerSelectorQuery, PartnerSelectorQueryVariables>(
    PartnerSelectorDocument,
    options
  );
}
export type PartnerSelectorQueryHookResult = ReturnType<typeof usePartnerSelectorQuery>;
export type PartnerSelectorLazyQueryHookResult = ReturnType<typeof usePartnerSelectorLazyQuery>;
export type PartnerSelectorQueryResult = ApolloReactCommon.QueryResult<
  PartnerSelectorQuery,
  PartnerSelectorQueryVariables
>;
export const PartnerUserSelectorDocument = gql`
  query PartnerUserSelector($partnerId: Int!) {
    partner(id: $partnerId) {
      id
      partnerUsers {
        nodes {
          id
          isAdmin
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

/**
 * __usePartnerUserSelectorQuery__
 *
 * To run a query within a React component, call `usePartnerUserSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerUserSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerUserSelectorQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function usePartnerUserSelectorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PartnerUserSelectorQuery, PartnerUserSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerUserSelectorQuery, PartnerUserSelectorQueryVariables>(
    PartnerUserSelectorDocument,
    options
  );
}
export function usePartnerUserSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerUserSelectorQuery, PartnerUserSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerUserSelectorQuery, PartnerUserSelectorQueryVariables>(
    PartnerUserSelectorDocument,
    options
  );
}
export type PartnerUserSelectorQueryHookResult = ReturnType<typeof usePartnerUserSelectorQuery>;
export type PartnerUserSelectorLazyQueryHookResult = ReturnType<typeof usePartnerUserSelectorLazyQuery>;
export type PartnerUserSelectorQueryResult = ApolloReactCommon.QueryResult<
  PartnerUserSelectorQuery,
  PartnerUserSelectorQueryVariables
>;
export const ProductSelectorDocument = gql`
  query ProductSelector {
    products {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useProductSelectorQuery__
 *
 * To run a query within a React component, call `useProductSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSelectorQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ProductSelectorQuery, ProductSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProductSelectorQuery, ProductSelectorQueryVariables>(
    ProductSelectorDocument,
    options
  );
}
export function useProductSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductSelectorQuery, ProductSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ProductSelectorQuery, ProductSelectorQueryVariables>(
    ProductSelectorDocument,
    options
  );
}
export type ProductSelectorQueryHookResult = ReturnType<typeof useProductSelectorQuery>;
export type ProductSelectorLazyQueryHookResult = ReturnType<typeof useProductSelectorLazyQuery>;
export type ProductSelectorQueryResult = ApolloReactCommon.QueryResult<
  ProductSelectorQuery,
  ProductSelectorQueryVariables
>;
export const PublisherSelectorV2Document = gql`
  query PublisherSelectorV2 {
    publishers {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __usePublisherSelectorV2Query__
 *
 * To run a query within a React component, call `usePublisherSelectorV2Query` and pass it any options that fit your needs.
 * When your component renders, `usePublisherSelectorV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherSelectorV2Query({
 *   variables: {
 *   },
 * });
 */
export function usePublisherSelectorV2Query(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PublisherSelectorV2Query, PublisherSelectorV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PublisherSelectorV2Query, PublisherSelectorV2QueryVariables>(
    PublisherSelectorV2Document,
    options
  );
}
export function usePublisherSelectorV2LazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublisherSelectorV2Query, PublisherSelectorV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PublisherSelectorV2Query, PublisherSelectorV2QueryVariables>(
    PublisherSelectorV2Document,
    options
  );
}
export type PublisherSelectorV2QueryHookResult = ReturnType<typeof usePublisherSelectorV2Query>;
export type PublisherSelectorV2LazyQueryHookResult = ReturnType<typeof usePublisherSelectorV2LazyQuery>;
export type PublisherSelectorV2QueryResult = ApolloReactCommon.QueryResult<
  PublisherSelectorV2Query,
  PublisherSelectorV2QueryVariables
>;
export const PublisherSelectorDocument = gql`
  query PublisherSelector {
    publishers {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __usePublisherSelectorQuery__
 *
 * To run a query within a React component, call `usePublisherSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherSelectorQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublisherSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PublisherSelectorQuery, PublisherSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PublisherSelectorQuery, PublisherSelectorQueryVariables>(
    PublisherSelectorDocument,
    options
  );
}
export function usePublisherSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublisherSelectorQuery, PublisherSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PublisherSelectorQuery, PublisherSelectorQueryVariables>(
    PublisherSelectorDocument,
    options
  );
}
export type PublisherSelectorQueryHookResult = ReturnType<typeof usePublisherSelectorQuery>;
export type PublisherSelectorLazyQueryHookResult = ReturnType<typeof usePublisherSelectorLazyQuery>;
export type PublisherSelectorQueryResult = ApolloReactCommon.QueryResult<
  PublisherSelectorQuery,
  PublisherSelectorQueryVariables
>;
export const SitePostStatusesDocument = gql`
  query SitePostStatuses {
    sitePostStatuses {
      id
      name
    }
  }
`;

/**
 * __useSitePostStatusesQuery__
 *
 * To run a query within a React component, call `useSitePostStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitePostStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitePostStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSitePostStatusesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SitePostStatusesQuery, SitePostStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SitePostStatusesQuery, SitePostStatusesQueryVariables>(
    SitePostStatusesDocument,
    options
  );
}
export function useSitePostStatusesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SitePostStatusesQuery, SitePostStatusesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SitePostStatusesQuery, SitePostStatusesQueryVariables>(
    SitePostStatusesDocument,
    options
  );
}
export type SitePostStatusesQueryHookResult = ReturnType<typeof useSitePostStatusesQuery>;
export type SitePostStatusesLazyQueryHookResult = ReturnType<typeof useSitePostStatusesLazyQuery>;
export type SitePostStatusesQueryResult = ApolloReactCommon.QueryResult<
  SitePostStatusesQuery,
  SitePostStatusesQueryVariables
>;
export const SitesForSelectorDocument = gql`
  query SitesForSelector($search: String) {
    sites(search: $search) {
      nodes {
        id
      }
    }
  }
`;

/**
 * __useSitesForSelectorQuery__
 *
 * To run a query within a React component, call `useSitesForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitesForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitesForSelectorQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSitesForSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SitesForSelectorQuery, SitesForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SitesForSelectorQuery, SitesForSelectorQueryVariables>(
    SitesForSelectorDocument,
    options
  );
}
export function useSitesForSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SitesForSelectorQuery, SitesForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SitesForSelectorQuery, SitesForSelectorQueryVariables>(
    SitesForSelectorDocument,
    options
  );
}
export type SitesForSelectorQueryHookResult = ReturnType<typeof useSitesForSelectorQuery>;
export type SitesForSelectorLazyQueryHookResult = ReturnType<typeof useSitesForSelectorLazyQuery>;
export type SitesForSelectorQueryResult = ApolloReactCommon.QueryResult<
  SitesForSelectorQuery,
  SitesForSelectorQueryVariables
>;
export const CustomerDetails_CreateCustomerUserSsoCodeDocument = gql`
  mutation CustomerDetails_CreateCustomerUserSsoCode($input: CreateCustomerUserSsoCodeInput!) {
    createCustomerUserSsoCode(input: $input) {
      customerUserSsoCode {
        id
      }
    }
  }
`;
export type CustomerDetails_CreateCustomerUserSsoCodeMutationFn = ApolloReactCommon.MutationFunction<
  CustomerDetails_CreateCustomerUserSsoCodeMutation,
  CustomerDetails_CreateCustomerUserSsoCodeMutationVariables
>;

/**
 * __useCustomerDetails_CreateCustomerUserSsoCodeMutation__
 *
 * To run a mutation, you first call `useCustomerDetails_CreateCustomerUserSsoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetails_CreateCustomerUserSsoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerDetailsCreateCustomerUserSsoCodeMutation, { data, loading, error }] = useCustomerDetails_CreateCustomerUserSsoCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerDetails_CreateCustomerUserSsoCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CustomerDetails_CreateCustomerUserSsoCodeMutation,
    CustomerDetails_CreateCustomerUserSsoCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CustomerDetails_CreateCustomerUserSsoCodeMutation,
    CustomerDetails_CreateCustomerUserSsoCodeMutationVariables
  >(CustomerDetails_CreateCustomerUserSsoCodeDocument, options);
}
export type CustomerDetails_CreateCustomerUserSsoCodeMutationHookResult = ReturnType<
  typeof useCustomerDetails_CreateCustomerUserSsoCodeMutation
>;
export type CustomerDetails_CreateCustomerUserSsoCodeMutationResult =
  ApolloReactCommon.MutationResult<CustomerDetails_CreateCustomerUserSsoCodeMutation>;
export type CustomerDetails_CreateCustomerUserSsoCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CustomerDetails_CreateCustomerUserSsoCodeMutation,
  CustomerDetails_CreateCustomerUserSsoCodeMutationVariables
>;
export const CustomerDetails_CustomerUserSsoCodesDocument = gql`
  query CustomerDetails_CustomerUserSsoCodes($customerUserId: Int!) {
    customerUser(id: $customerUserId) {
      id
      customerUserSsoCodes {
        nodes {
          id
          code
          expiresAt
          createdAt
          url
        }
      }
    }
  }
`;

/**
 * __useCustomerDetails_CustomerUserSsoCodesQuery__
 *
 * To run a query within a React component, call `useCustomerDetails_CustomerUserSsoCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetails_CustomerUserSsoCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetails_CustomerUserSsoCodesQuery({
 *   variables: {
 *      customerUserId: // value for 'customerUserId'
 *   },
 * });
 */
export function useCustomerDetails_CustomerUserSsoCodesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerDetails_CustomerUserSsoCodesQuery,
    CustomerDetails_CustomerUserSsoCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomerDetails_CustomerUserSsoCodesQuery,
    CustomerDetails_CustomerUserSsoCodesQueryVariables
  >(CustomerDetails_CustomerUserSsoCodesDocument, options);
}
export function useCustomerDetails_CustomerUserSsoCodesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerDetails_CustomerUserSsoCodesQuery,
    CustomerDetails_CustomerUserSsoCodesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerDetails_CustomerUserSsoCodesQuery,
    CustomerDetails_CustomerUserSsoCodesQueryVariables
  >(CustomerDetails_CustomerUserSsoCodesDocument, options);
}
export type CustomerDetails_CustomerUserSsoCodesQueryHookResult = ReturnType<
  typeof useCustomerDetails_CustomerUserSsoCodesQuery
>;
export type CustomerDetails_CustomerUserSsoCodesLazyQueryHookResult = ReturnType<
  typeof useCustomerDetails_CustomerUserSsoCodesLazyQuery
>;
export type CustomerDetails_CustomerUserSsoCodesQueryResult = ApolloReactCommon.QueryResult<
  CustomerDetails_CustomerUserSsoCodesQuery,
  CustomerDetails_CustomerUserSsoCodesQueryVariables
>;
export const TaskTypeSelectorDocument = gql`
  query TaskTypeSelector($ids: [String!]) {
    taskTypes(ids: $ids) {
      nodes {
        id
        name
        isFulfilledByCustomer
      }
    }
  }
`;

/**
 * __useTaskTypeSelectorQuery__
 *
 * To run a query within a React component, call `useTaskTypeSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTypeSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTypeSelectorQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useTaskTypeSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TaskTypeSelectorQuery, TaskTypeSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TaskTypeSelectorQuery, TaskTypeSelectorQueryVariables>(
    TaskTypeSelectorDocument,
    options
  );
}
export function useTaskTypeSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskTypeSelectorQuery, TaskTypeSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TaskTypeSelectorQuery, TaskTypeSelectorQueryVariables>(
    TaskTypeSelectorDocument,
    options
  );
}
export type TaskTypeSelectorQueryHookResult = ReturnType<typeof useTaskTypeSelectorQuery>;
export type TaskTypeSelectorLazyQueryHookResult = ReturnType<typeof useTaskTypeSelectorLazyQuery>;
export type TaskTypeSelectorQueryResult = ApolloReactCommon.QueryResult<
  TaskTypeSelectorQuery,
  TaskTypeSelectorQueryVariables
>;
export const ProductTaskTypesForTaskTypeSelectorDocument = gql`
  query ProductTaskTypesForTaskTypeSelector($productIds: [String!]!) {
    productTaskTypes(productIds: $productIds) {
      nodes {
        id
        taskType {
          id
          name
          isFulfilledByCustomer
        }
      }
    }
  }
`;

/**
 * __useProductTaskTypesForTaskTypeSelectorQuery__
 *
 * To run a query within a React component, call `useProductTaskTypesForTaskTypeSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTaskTypesForTaskTypeSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTaskTypesForTaskTypeSelectorQuery({
 *   variables: {
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useProductTaskTypesForTaskTypeSelectorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProductTaskTypesForTaskTypeSelectorQuery,
    ProductTaskTypesForTaskTypeSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ProductTaskTypesForTaskTypeSelectorQuery,
    ProductTaskTypesForTaskTypeSelectorQueryVariables
  >(ProductTaskTypesForTaskTypeSelectorDocument, options);
}
export function useProductTaskTypesForTaskTypeSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProductTaskTypesForTaskTypeSelectorQuery,
    ProductTaskTypesForTaskTypeSelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ProductTaskTypesForTaskTypeSelectorQuery,
    ProductTaskTypesForTaskTypeSelectorQueryVariables
  >(ProductTaskTypesForTaskTypeSelectorDocument, options);
}
export type ProductTaskTypesForTaskTypeSelectorQueryHookResult = ReturnType<
  typeof useProductTaskTypesForTaskTypeSelectorQuery
>;
export type ProductTaskTypesForTaskTypeSelectorLazyQueryHookResult = ReturnType<
  typeof useProductTaskTypesForTaskTypeSelectorLazyQuery
>;
export type ProductTaskTypesForTaskTypeSelectorQueryResult = ApolloReactCommon.QueryResult<
  ProductTaskTypesForTaskTypeSelectorQuery,
  ProductTaskTypesForTaskTypeSelectorQueryVariables
>;
export const TaskTypesForSelectorDocument = gql`
  query TaskTypesForSelector($search: String, $ids: [String!]) {
    taskTypes(search: $search, ids: $ids) {
      nodes {
        id
        name
        isFulfilledByCustomer
      }
    }
  }
`;

/**
 * __useTaskTypesForSelectorQuery__
 *
 * To run a query within a React component, call `useTaskTypesForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskTypesForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskTypesForSelectorQuery({
 *   variables: {
 *      search: // value for 'search'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useTaskTypesForSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<TaskTypesForSelectorQuery, TaskTypesForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TaskTypesForSelectorQuery, TaskTypesForSelectorQueryVariables>(
    TaskTypesForSelectorDocument,
    options
  );
}
export function useTaskTypesForSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskTypesForSelectorQuery, TaskTypesForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TaskTypesForSelectorQuery, TaskTypesForSelectorQueryVariables>(
    TaskTypesForSelectorDocument,
    options
  );
}
export type TaskTypesForSelectorQueryHookResult = ReturnType<typeof useTaskTypesForSelectorQuery>;
export type TaskTypesForSelectorLazyQueryHookResult = ReturnType<typeof useTaskTypesForSelectorLazyQuery>;
export type TaskTypesForSelectorQueryResult = ApolloReactCommon.QueryResult<
  TaskTypesForSelectorQuery,
  TaskTypesForSelectorQueryVariables
>;
export const TaskWorkspaceBusinessListingDocument = gql`
  query TaskWorkspaceBusinessListing($taskId: Int!) {
    task(taskId: $taskId) {
      ...TaskWorkspaceBusinessListing_Task
    }
  }
  ${TaskWorkspaceBusinessListing_TaskFragmentDoc}
`;

/**
 * __useTaskWorkspaceBusinessListingQuery__
 *
 * To run a query within a React component, call `useTaskWorkspaceBusinessListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskWorkspaceBusinessListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskWorkspaceBusinessListingQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskWorkspaceBusinessListingQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    TaskWorkspaceBusinessListingQuery,
    TaskWorkspaceBusinessListingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TaskWorkspaceBusinessListingQuery, TaskWorkspaceBusinessListingQueryVariables>(
    TaskWorkspaceBusinessListingDocument,
    options
  );
}
export function useTaskWorkspaceBusinessListingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TaskWorkspaceBusinessListingQuery,
    TaskWorkspaceBusinessListingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TaskWorkspaceBusinessListingQuery, TaskWorkspaceBusinessListingQueryVariables>(
    TaskWorkspaceBusinessListingDocument,
    options
  );
}
export type TaskWorkspaceBusinessListingQueryHookResult = ReturnType<typeof useTaskWorkspaceBusinessListingQuery>;
export type TaskWorkspaceBusinessListingLazyQueryHookResult = ReturnType<
  typeof useTaskWorkspaceBusinessListingLazyQuery
>;
export type TaskWorkspaceBusinessListingQueryResult = ApolloReactCommon.QueryResult<
  TaskWorkspaceBusinessListingQuery,
  TaskWorkspaceBusinessListingQueryVariables
>;
export const TaskWorkspaceBusinessListing_UpdateOrderDocument = gql`
  mutation TaskWorkspaceBusinessListing_UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      order {
        id
        resultUrl
        resultUrlDomainAuthority
      }
    }
  }
`;
export type TaskWorkspaceBusinessListing_UpdateOrderMutationFn = ApolloReactCommon.MutationFunction<
  TaskWorkspaceBusinessListing_UpdateOrderMutation,
  TaskWorkspaceBusinessListing_UpdateOrderMutationVariables
>;

/**
 * __useTaskWorkspaceBusinessListing_UpdateOrderMutation__
 *
 * To run a mutation, you first call `useTaskWorkspaceBusinessListing_UpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskWorkspaceBusinessListing_UpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskWorkspaceBusinessListingUpdateOrderMutation, { data, loading, error }] = useTaskWorkspaceBusinessListing_UpdateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskWorkspaceBusinessListing_UpdateOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TaskWorkspaceBusinessListing_UpdateOrderMutation,
    TaskWorkspaceBusinessListing_UpdateOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    TaskWorkspaceBusinessListing_UpdateOrderMutation,
    TaskWorkspaceBusinessListing_UpdateOrderMutationVariables
  >(TaskWorkspaceBusinessListing_UpdateOrderDocument, options);
}
export type TaskWorkspaceBusinessListing_UpdateOrderMutationHookResult = ReturnType<
  typeof useTaskWorkspaceBusinessListing_UpdateOrderMutation
>;
export type TaskWorkspaceBusinessListing_UpdateOrderMutationResult =
  ApolloReactCommon.MutationResult<TaskWorkspaceBusinessListing_UpdateOrderMutation>;
export type TaskWorkspaceBusinessListing_UpdateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TaskWorkspaceBusinessListing_UpdateOrderMutation,
  TaskWorkspaceBusinessListing_UpdateOrderMutationVariables
>;
export const ChecklistTaskWorkspaceDocument = gql`
  query ChecklistTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      ...ChecklistTaskWorkspace_Task
    }
  }
  ${ChecklistTaskWorkspace_TaskFragmentDoc}
`;

/**
 * __useChecklistTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useChecklistTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useChecklistTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChecklistTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useChecklistTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ChecklistTaskWorkspaceQuery, ChecklistTaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ChecklistTaskWorkspaceQuery, ChecklistTaskWorkspaceQueryVariables>(
    ChecklistTaskWorkspaceDocument,
    options
  );
}
export function useChecklistTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChecklistTaskWorkspaceQuery, ChecklistTaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ChecklistTaskWorkspaceQuery, ChecklistTaskWorkspaceQueryVariables>(
    ChecklistTaskWorkspaceDocument,
    options
  );
}
export type ChecklistTaskWorkspaceQueryHookResult = ReturnType<typeof useChecklistTaskWorkspaceQuery>;
export type ChecklistTaskWorkspaceLazyQueryHookResult = ReturnType<typeof useChecklistTaskWorkspaceLazyQuery>;
export type ChecklistTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ChecklistTaskWorkspaceQuery,
  ChecklistTaskWorkspaceQueryVariables
>;
export const UpdateChecklistItemDocument = gql`
  mutation UpdateChecklistItem($input: UpdateChecklistItemInput!) {
    updateChecklistItem(input: $input) {
      checklistItem {
        ...ChecklistTaskWorkspace_ChecklistItem
      }
    }
  }
  ${ChecklistTaskWorkspace_ChecklistItemFragmentDoc}
`;
export type UpdateChecklistItemMutationFn = ApolloReactCommon.MutationFunction<
  UpdateChecklistItemMutation,
  UpdateChecklistItemMutationVariables
>;

/**
 * __useUpdateChecklistItemMutation__
 *
 * To run a mutation, you first call `useUpdateChecklistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChecklistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChecklistItemMutation, { data, loading, error }] = useUpdateChecklistItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChecklistItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateChecklistItemMutation, UpdateChecklistItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateChecklistItemMutation, UpdateChecklistItemMutationVariables>(
    UpdateChecklistItemDocument,
    options
  );
}
export type UpdateChecklistItemMutationHookResult = ReturnType<typeof useUpdateChecklistItemMutation>;
export type UpdateChecklistItemMutationResult = ApolloReactCommon.MutationResult<UpdateChecklistItemMutation>;
export type UpdateChecklistItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateChecklistItemMutation,
  UpdateChecklistItemMutationVariables
>;
export const ChecklistTaskWorkspace_UpdateOrderDocument = gql`
  mutation ChecklistTaskWorkspace_UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      order {
        id
        resultUrl
        resultUrlDomainAuthority
      }
    }
  }
`;
export type ChecklistTaskWorkspace_UpdateOrderMutationFn = ApolloReactCommon.MutationFunction<
  ChecklistTaskWorkspace_UpdateOrderMutation,
  ChecklistTaskWorkspace_UpdateOrderMutationVariables
>;

/**
 * __useChecklistTaskWorkspace_UpdateOrderMutation__
 *
 * To run a mutation, you first call `useChecklistTaskWorkspace_UpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChecklistTaskWorkspace_UpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checklistTaskWorkspaceUpdateOrderMutation, { data, loading, error }] = useChecklistTaskWorkspace_UpdateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChecklistTaskWorkspace_UpdateOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChecklistTaskWorkspace_UpdateOrderMutation,
    ChecklistTaskWorkspace_UpdateOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ChecklistTaskWorkspace_UpdateOrderMutation,
    ChecklistTaskWorkspace_UpdateOrderMutationVariables
  >(ChecklistTaskWorkspace_UpdateOrderDocument, options);
}
export type ChecklistTaskWorkspace_UpdateOrderMutationHookResult = ReturnType<
  typeof useChecklistTaskWorkspace_UpdateOrderMutation
>;
export type ChecklistTaskWorkspace_UpdateOrderMutationResult =
  ApolloReactCommon.MutationResult<ChecklistTaskWorkspace_UpdateOrderMutation>;
export type ChecklistTaskWorkspace_UpdateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChecklistTaskWorkspace_UpdateOrderMutation,
  ChecklistTaskWorkspace_UpdateOrderMutationVariables
>;
export const CreateOrderExternalContentSpecDocument = gql`
  mutation CreateOrderExternalContentSpec($input: CreateOrderExternalContentSpecInput!) {
    createOrderExternalContentSpec(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type CreateOrderExternalContentSpecMutationFn = ApolloReactCommon.MutationFunction<
  CreateOrderExternalContentSpecMutation,
  CreateOrderExternalContentSpecMutationVariables
>;

/**
 * __useCreateOrderExternalContentSpecMutation__
 *
 * To run a mutation, you first call `useCreateOrderExternalContentSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderExternalContentSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderExternalContentSpecMutation, { data, loading, error }] = useCreateOrderExternalContentSpecMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderExternalContentSpecMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrderExternalContentSpecMutation,
    CreateOrderExternalContentSpecMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateOrderExternalContentSpecMutation,
    CreateOrderExternalContentSpecMutationVariables
  >(CreateOrderExternalContentSpecDocument, options);
}
export type CreateOrderExternalContentSpecMutationHookResult = ReturnType<
  typeof useCreateOrderExternalContentSpecMutation
>;
export type CreateOrderExternalContentSpecMutationResult =
  ApolloReactCommon.MutationResult<CreateOrderExternalContentSpecMutation>;
export type CreateOrderExternalContentSpecMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrderExternalContentSpecMutation,
  CreateOrderExternalContentSpecMutationVariables
>;
export const ContentFulfillment_UpdateOrderDocument = gql`
  mutation ContentFulfillment_UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      order {
        id
      }
    }
  }
`;
export type ContentFulfillment_UpdateOrderMutationFn = ApolloReactCommon.MutationFunction<
  ContentFulfillment_UpdateOrderMutation,
  ContentFulfillment_UpdateOrderMutationVariables
>;

/**
 * __useContentFulfillment_UpdateOrderMutation__
 *
 * To run a mutation, you first call `useContentFulfillment_UpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentFulfillment_UpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentFulfillmentUpdateOrderMutation, { data, loading, error }] = useContentFulfillment_UpdateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentFulfillment_UpdateOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ContentFulfillment_UpdateOrderMutation,
    ContentFulfillment_UpdateOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ContentFulfillment_UpdateOrderMutation,
    ContentFulfillment_UpdateOrderMutationVariables
  >(ContentFulfillment_UpdateOrderDocument, options);
}
export type ContentFulfillment_UpdateOrderMutationHookResult = ReturnType<
  typeof useContentFulfillment_UpdateOrderMutation
>;
export type ContentFulfillment_UpdateOrderMutationResult =
  ApolloReactCommon.MutationResult<ContentFulfillment_UpdateOrderMutation>;
export type ContentFulfillment_UpdateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ContentFulfillment_UpdateOrderMutation,
  ContentFulfillment_UpdateOrderMutationVariables
>;
export const ContentFulfillmentDecisionTaskWorkspaceDocument = gql`
  query ContentFulfillmentDecisionTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      ...ContentFulfillmentDecisionTaskWorkspace_Task
    }
  }
  ${ContentFulfillmentDecisionTaskWorkspace_TaskFragmentDoc}
`;

/**
 * __useContentFulfillmentDecisionTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useContentFulfillmentDecisionTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentFulfillmentDecisionTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentFulfillmentDecisionTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useContentFulfillmentDecisionTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContentFulfillmentDecisionTaskWorkspaceQuery,
    ContentFulfillmentDecisionTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ContentFulfillmentDecisionTaskWorkspaceQuery,
    ContentFulfillmentDecisionTaskWorkspaceQueryVariables
  >(ContentFulfillmentDecisionTaskWorkspaceDocument, options);
}
export function useContentFulfillmentDecisionTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentFulfillmentDecisionTaskWorkspaceQuery,
    ContentFulfillmentDecisionTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ContentFulfillmentDecisionTaskWorkspaceQuery,
    ContentFulfillmentDecisionTaskWorkspaceQueryVariables
  >(ContentFulfillmentDecisionTaskWorkspaceDocument, options);
}
export type ContentFulfillmentDecisionTaskWorkspaceQueryHookResult = ReturnType<
  typeof useContentFulfillmentDecisionTaskWorkspaceQuery
>;
export type ContentFulfillmentDecisionTaskWorkspaceLazyQueryHookResult = ReturnType<
  typeof useContentFulfillmentDecisionTaskWorkspaceLazyQuery
>;
export type ContentFulfillmentDecisionTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ContentFulfillmentDecisionTaskWorkspaceQuery,
  ContentFulfillmentDecisionTaskWorkspaceQueryVariables
>;
export const ContentIdeationTaskWorkspaceDocument = gql`
  query ContentIdeationTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      ...ContentIdeationTaskWorkspace_Task
    }
  }
  ${ContentIdeationTaskWorkspace_TaskFragmentDoc}
`;

/**
 * __useContentIdeationTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useContentIdeationTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentIdeationTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentIdeationTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useContentIdeationTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContentIdeationTaskWorkspaceQuery,
    ContentIdeationTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentIdeationTaskWorkspaceQuery, ContentIdeationTaskWorkspaceQueryVariables>(
    ContentIdeationTaskWorkspaceDocument,
    options
  );
}
export function useContentIdeationTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentIdeationTaskWorkspaceQuery,
    ContentIdeationTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentIdeationTaskWorkspaceQuery, ContentIdeationTaskWorkspaceQueryVariables>(
    ContentIdeationTaskWorkspaceDocument,
    options
  );
}
export type ContentIdeationTaskWorkspaceQueryHookResult = ReturnType<typeof useContentIdeationTaskWorkspaceQuery>;
export type ContentIdeationTaskWorkspaceLazyQueryHookResult = ReturnType<
  typeof useContentIdeationTaskWorkspaceLazyQuery
>;
export type ContentIdeationTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ContentIdeationTaskWorkspaceQuery,
  ContentIdeationTaskWorkspaceQueryVariables
>;
export const SaveTaskContentIdeationDocument = gql`
  mutation SaveTaskContentIdeation($input: SaveTaskContentIdeationInput!) {
    saveTaskContentIdeation(input: $input) {
      contentIdeation {
        id
      }
    }
  }
`;
export type SaveTaskContentIdeationMutationFn = ApolloReactCommon.MutationFunction<
  SaveTaskContentIdeationMutation,
  SaveTaskContentIdeationMutationVariables
>;

/**
 * __useSaveTaskContentIdeationMutation__
 *
 * To run a mutation, you first call `useSaveTaskContentIdeationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTaskContentIdeationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTaskContentIdeationMutation, { data, loading, error }] = useSaveTaskContentIdeationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTaskContentIdeationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveTaskContentIdeationMutation,
    SaveTaskContentIdeationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveTaskContentIdeationMutation, SaveTaskContentIdeationMutationVariables>(
    SaveTaskContentIdeationDocument,
    options
  );
}
export type SaveTaskContentIdeationMutationHookResult = ReturnType<typeof useSaveTaskContentIdeationMutation>;
export type SaveTaskContentIdeationMutationResult = ApolloReactCommon.MutationResult<SaveTaskContentIdeationMutation>;
export type SaveTaskContentIdeationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveTaskContentIdeationMutation,
  SaveTaskContentIdeationMutationVariables
>;
export const ContentReviewTaskWorkspaceDocument = gql`
  query ContentReviewTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      ...ContentReviewTaskWorkspace_Task
    }
  }
  ${ContentReviewTaskWorkspace_TaskFragmentDoc}
`;

/**
 * __useContentReviewTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useContentReviewTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentReviewTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentReviewTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useContentReviewTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContentReviewTaskWorkspaceQuery,
    ContentReviewTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentReviewTaskWorkspaceQuery, ContentReviewTaskWorkspaceQueryVariables>(
    ContentReviewTaskWorkspaceDocument,
    options
  );
}
export function useContentReviewTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentReviewTaskWorkspaceQuery,
    ContentReviewTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentReviewTaskWorkspaceQuery, ContentReviewTaskWorkspaceQueryVariables>(
    ContentReviewTaskWorkspaceDocument,
    options
  );
}
export type ContentReviewTaskWorkspaceQueryHookResult = ReturnType<typeof useContentReviewTaskWorkspaceQuery>;
export type ContentReviewTaskWorkspaceLazyQueryHookResult = ReturnType<typeof useContentReviewTaskWorkspaceLazyQuery>;
export type ContentReviewTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ContentReviewTaskWorkspaceQuery,
  ContentReviewTaskWorkspaceQueryVariables
>;
export const CreateFeedbackContentItemDocument = gql`
  mutation CreateFeedbackContentItem($input: CreateFeedbackContentItemInput!) {
    createFeedbackContentItem(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type CreateFeedbackContentItemMutationFn = ApolloReactCommon.MutationFunction<
  CreateFeedbackContentItemMutation,
  CreateFeedbackContentItemMutationVariables
>;

/**
 * __useCreateFeedbackContentItemMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackContentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackContentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackContentItemMutation, { data, loading, error }] = useCreateFeedbackContentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFeedbackContentItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateFeedbackContentItemMutation,
    CreateFeedbackContentItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateFeedbackContentItemMutation, CreateFeedbackContentItemMutationVariables>(
    CreateFeedbackContentItemDocument,
    options
  );
}
export type CreateFeedbackContentItemMutationHookResult = ReturnType<typeof useCreateFeedbackContentItemMutation>;
export type CreateFeedbackContentItemMutationResult =
  ApolloReactCommon.MutationResult<CreateFeedbackContentItemMutation>;
export type CreateFeedbackContentItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateFeedbackContentItemMutation,
  CreateFeedbackContentItemMutationVariables
>;
export const SaveTaskContentItemDocument = gql`
  mutation SaveTaskContentItem($input: SaveTaskContentItemInput!) {
    saveTaskContentItem(input: $input) {
      contentItem {
        id
      }
    }
  }
`;
export type SaveTaskContentItemMutationFn = ApolloReactCommon.MutationFunction<
  SaveTaskContentItemMutation,
  SaveTaskContentItemMutationVariables
>;

/**
 * __useSaveTaskContentItemMutation__
 *
 * To run a mutation, you first call `useSaveTaskContentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTaskContentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTaskContentItemMutation, { data, loading, error }] = useSaveTaskContentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTaskContentItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SaveTaskContentItemMutation, SaveTaskContentItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveTaskContentItemMutation, SaveTaskContentItemMutationVariables>(
    SaveTaskContentItemDocument,
    options
  );
}
export type SaveTaskContentItemMutationHookResult = ReturnType<typeof useSaveTaskContentItemMutation>;
export type SaveTaskContentItemMutationResult = ApolloReactCommon.MutationResult<SaveTaskContentItemMutation>;
export type SaveTaskContentItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveTaskContentItemMutation,
  SaveTaskContentItemMutationVariables
>;
export const UpdateFeedbackContentItemDocument = gql`
  mutation UpdateFeedbackContentItem($input: UpdateFeedbackContentItemInput!) {
    updateFeedbackContentItem(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type UpdateFeedbackContentItemMutationFn = ApolloReactCommon.MutationFunction<
  UpdateFeedbackContentItemMutation,
  UpdateFeedbackContentItemMutationVariables
>;

/**
 * __useUpdateFeedbackContentItemMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackContentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackContentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackContentItemMutation, { data, loading, error }] = useUpdateFeedbackContentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFeedbackContentItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateFeedbackContentItemMutation,
    UpdateFeedbackContentItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateFeedbackContentItemMutation, UpdateFeedbackContentItemMutationVariables>(
    UpdateFeedbackContentItemDocument,
    options
  );
}
export type UpdateFeedbackContentItemMutationHookResult = ReturnType<typeof useUpdateFeedbackContentItemMutation>;
export type UpdateFeedbackContentItemMutationResult =
  ApolloReactCommon.MutationResult<UpdateFeedbackContentItemMutation>;
export type UpdateFeedbackContentItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateFeedbackContentItemMutation,
  UpdateFeedbackContentItemMutationVariables
>;
export const ContentStrategyV2_OrderStatusesDocument = gql`
  query ContentStrategyV2_OrderStatuses {
    orderStatuses {
      id
      name
    }
  }
`;

/**
 * __useContentStrategyV2_OrderStatusesQuery__
 *
 * To run a query within a React component, call `useContentStrategyV2_OrderStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentStrategyV2_OrderStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentStrategyV2_OrderStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useContentStrategyV2_OrderStatusesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ContentStrategyV2_OrderStatusesQuery,
    ContentStrategyV2_OrderStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentStrategyV2_OrderStatusesQuery, ContentStrategyV2_OrderStatusesQueryVariables>(
    ContentStrategyV2_OrderStatusesDocument,
    options
  );
}
export function useContentStrategyV2_OrderStatusesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentStrategyV2_OrderStatusesQuery,
    ContentStrategyV2_OrderStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ContentStrategyV2_OrderStatusesQuery,
    ContentStrategyV2_OrderStatusesQueryVariables
  >(ContentStrategyV2_OrderStatusesDocument, options);
}
export type ContentStrategyV2_OrderStatusesQueryHookResult = ReturnType<typeof useContentStrategyV2_OrderStatusesQuery>;
export type ContentStrategyV2_OrderStatusesLazyQueryHookResult = ReturnType<
  typeof useContentStrategyV2_OrderStatusesLazyQuery
>;
export type ContentStrategyV2_OrderStatusesQueryResult = ApolloReactCommon.QueryResult<
  ContentStrategyV2_OrderStatusesQuery,
  ContentStrategyV2_OrderStatusesQueryVariables
>;
export const ContentStrategyV2_OrdersDocument = gql`
  query ContentStrategyV2_Orders(
    $limit: Int
    $offset: Int
    $sort: OrdersSort
    $search: String
    $customerIds: [Int!]
    $statusIds: [String!]
    $productIds: [String!]
  ) {
    orders(
      limit: $limit
      offset: $offset
      sort: $sort
      search: $search
      customerIds: $customerIds
      statusIds: $statusIds
      productIds: $productIds
    ) {
      totalCount
      nodes {
        id
        topic
        startDate
        instructions
        billingStatus {
          id
          name
          description
        }
        status {
          id
          name
        }
        customer {
          id
          name
          partner {
            id
            name
          }
        }
        user {
          id
          firstName
          lastName
        }
        partnerProduct {
          id
          name
        }
        customerKeyword {
          id
          name
        }
        orderIssues {
          title
          message
        }
      }
    }
  }
`;

/**
 * __useContentStrategyV2_OrdersQuery__
 *
 * To run a query within a React component, call `useContentStrategyV2_OrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentStrategyV2_OrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentStrategyV2_OrdersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      customerIds: // value for 'customerIds'
 *      statusIds: // value for 'statusIds'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useContentStrategyV2_OrdersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ContentStrategyV2_OrdersQuery, ContentStrategyV2_OrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentStrategyV2_OrdersQuery, ContentStrategyV2_OrdersQueryVariables>(
    ContentStrategyV2_OrdersDocument,
    options
  );
}
export function useContentStrategyV2_OrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentStrategyV2_OrdersQuery,
    ContentStrategyV2_OrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentStrategyV2_OrdersQuery, ContentStrategyV2_OrdersQueryVariables>(
    ContentStrategyV2_OrdersDocument,
    options
  );
}
export type ContentStrategyV2_OrdersQueryHookResult = ReturnType<typeof useContentStrategyV2_OrdersQuery>;
export type ContentStrategyV2_OrdersLazyQueryHookResult = ReturnType<typeof useContentStrategyV2_OrdersLazyQuery>;
export type ContentStrategyV2_OrdersQueryResult = ApolloReactCommon.QueryResult<
  ContentStrategyV2_OrdersQuery,
  ContentStrategyV2_OrdersQueryVariables
>;
export const ContentStrategyV2TaskWorkspaceDocument = gql`
  query ContentStrategyV2TaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      id
      reviewTargetTask {
        id
        reviewTasks {
          nodes {
            ...Comment_ReviewTask
          }
        }
      }
      taskType {
        id
        name
      }
      order {
        id
      }
      customer {
        id
        name
        website
        instructions
        category {
          id
          name
        }
        customerQuestionnaire {
          id
          businessDescription
          customerMarketSize
          targetAudience
          competitorWebsites
        }
      }
      customerProposal {
        id
        summary
        orderContentStrategies(sort: ORDER_START_DATE_ASC) {
          ...ContentStrategyV2_OrderContentStrategy
        }
      }
    }
  }
  ${Comment_ReviewTaskFragmentDoc}
  ${ContentStrategyV2_OrderContentStrategyFragmentDoc}
`;

/**
 * __useContentStrategyV2TaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useContentStrategyV2TaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentStrategyV2TaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentStrategyV2TaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useContentStrategyV2TaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContentStrategyV2TaskWorkspaceQuery,
    ContentStrategyV2TaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentStrategyV2TaskWorkspaceQuery, ContentStrategyV2TaskWorkspaceQueryVariables>(
    ContentStrategyV2TaskWorkspaceDocument,
    options
  );
}
export function useContentStrategyV2TaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentStrategyV2TaskWorkspaceQuery,
    ContentStrategyV2TaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ContentStrategyV2TaskWorkspaceQuery,
    ContentStrategyV2TaskWorkspaceQueryVariables
  >(ContentStrategyV2TaskWorkspaceDocument, options);
}
export type ContentStrategyV2TaskWorkspaceQueryHookResult = ReturnType<typeof useContentStrategyV2TaskWorkspaceQuery>;
export type ContentStrategyV2TaskWorkspaceLazyQueryHookResult = ReturnType<
  typeof useContentStrategyV2TaskWorkspaceLazyQuery
>;
export type ContentStrategyV2TaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ContentStrategyV2TaskWorkspaceQuery,
  ContentStrategyV2TaskWorkspaceQueryVariables
>;
export const ResolveOrderContentStrategyReviewDocument = gql`
  mutation ResolveOrderContentStrategyReview($input: ResolveOrderContentStrategyReviewInput!) {
    resolveOrderContentStrategyReview(input: $input) {
      ok
      error {
        message
      }
      orderContentStrategyReview {
        id
        resolvedAt
      }
    }
  }
`;
export type ResolveOrderContentStrategyReviewMutationFn = ApolloReactCommon.MutationFunction<
  ResolveOrderContentStrategyReviewMutation,
  ResolveOrderContentStrategyReviewMutationVariables
>;

/**
 * __useResolveOrderContentStrategyReviewMutation__
 *
 * To run a mutation, you first call `useResolveOrderContentStrategyReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveOrderContentStrategyReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveOrderContentStrategyReviewMutation, { data, loading, error }] = useResolveOrderContentStrategyReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResolveOrderContentStrategyReviewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResolveOrderContentStrategyReviewMutation,
    ResolveOrderContentStrategyReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ResolveOrderContentStrategyReviewMutation,
    ResolveOrderContentStrategyReviewMutationVariables
  >(ResolveOrderContentStrategyReviewDocument, options);
}
export type ResolveOrderContentStrategyReviewMutationHookResult = ReturnType<
  typeof useResolveOrderContentStrategyReviewMutation
>;
export type ResolveOrderContentStrategyReviewMutationResult =
  ApolloReactCommon.MutationResult<ResolveOrderContentStrategyReviewMutation>;
export type ResolveOrderContentStrategyReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResolveOrderContentStrategyReviewMutation,
  ResolveOrderContentStrategyReviewMutationVariables
>;
export const ContentStrategyV2_UpdateCustomerProposalDocument = gql`
  mutation ContentStrategyV2_UpdateCustomerProposal($input: UpdateCustomerProposalInput!) {
    updateCustomerProposal(input: $input) {
      ok
      error {
        message
      }
      customerProposal {
        id
        summary
      }
    }
  }
`;
export type ContentStrategyV2_UpdateCustomerProposalMutationFn = ApolloReactCommon.MutationFunction<
  ContentStrategyV2_UpdateCustomerProposalMutation,
  ContentStrategyV2_UpdateCustomerProposalMutationVariables
>;

/**
 * __useContentStrategyV2_UpdateCustomerProposalMutation__
 *
 * To run a mutation, you first call `useContentStrategyV2_UpdateCustomerProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentStrategyV2_UpdateCustomerProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentStrategyV2UpdateCustomerProposalMutation, { data, loading, error }] = useContentStrategyV2_UpdateCustomerProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentStrategyV2_UpdateCustomerProposalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ContentStrategyV2_UpdateCustomerProposalMutation,
    ContentStrategyV2_UpdateCustomerProposalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ContentStrategyV2_UpdateCustomerProposalMutation,
    ContentStrategyV2_UpdateCustomerProposalMutationVariables
  >(ContentStrategyV2_UpdateCustomerProposalDocument, options);
}
export type ContentStrategyV2_UpdateCustomerProposalMutationHookResult = ReturnType<
  typeof useContentStrategyV2_UpdateCustomerProposalMutation
>;
export type ContentStrategyV2_UpdateCustomerProposalMutationResult =
  ApolloReactCommon.MutationResult<ContentStrategyV2_UpdateCustomerProposalMutation>;
export type ContentStrategyV2_UpdateCustomerProposalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ContentStrategyV2_UpdateCustomerProposalMutation,
  ContentStrategyV2_UpdateCustomerProposalMutationVariables
>;
export const ContentStrategyV2_UpdateOrderContentStrategyDocument = gql`
  mutation ContentStrategyV2_UpdateOrderContentStrategy($input: UpdateOrderContentStrategyInput!) {
    updateOrderContentStrategy(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
      orderContentStrategy {
        ...ContentStrategyV2_OrderContentStrategy
      }
    }
  }
  ${ContentStrategyV2_OrderContentStrategyFragmentDoc}
`;
export type ContentStrategyV2_UpdateOrderContentStrategyMutationFn = ApolloReactCommon.MutationFunction<
  ContentStrategyV2_UpdateOrderContentStrategyMutation,
  ContentStrategyV2_UpdateOrderContentStrategyMutationVariables
>;

/**
 * __useContentStrategyV2_UpdateOrderContentStrategyMutation__
 *
 * To run a mutation, you first call `useContentStrategyV2_UpdateOrderContentStrategyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentStrategyV2_UpdateOrderContentStrategyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentStrategyV2UpdateOrderContentStrategyMutation, { data, loading, error }] = useContentStrategyV2_UpdateOrderContentStrategyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentStrategyV2_UpdateOrderContentStrategyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ContentStrategyV2_UpdateOrderContentStrategyMutation,
    ContentStrategyV2_UpdateOrderContentStrategyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ContentStrategyV2_UpdateOrderContentStrategyMutation,
    ContentStrategyV2_UpdateOrderContentStrategyMutationVariables
  >(ContentStrategyV2_UpdateOrderContentStrategyDocument, options);
}
export type ContentStrategyV2_UpdateOrderContentStrategyMutationHookResult = ReturnType<
  typeof useContentStrategyV2_UpdateOrderContentStrategyMutation
>;
export type ContentStrategyV2_UpdateOrderContentStrategyMutationResult =
  ApolloReactCommon.MutationResult<ContentStrategyV2_UpdateOrderContentStrategyMutation>;
export type ContentStrategyV2_UpdateOrderContentStrategyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ContentStrategyV2_UpdateOrderContentStrategyMutation,
  ContentStrategyV2_UpdateOrderContentStrategyMutationVariables
>;
export const ContentStrategy_SaveProposedOrderTopicDocument = gql`
  mutation ContentStrategy_SaveProposedOrderTopic($input: SaveProposedOrderTopicInput!) {
    saveProposedOrderTopic(input: $input) {
      proposedOrderTopic {
        id
      }
    }
  }
`;
export type ContentStrategy_SaveProposedOrderTopicMutationFn = ApolloReactCommon.MutationFunction<
  ContentStrategy_SaveProposedOrderTopicMutation,
  ContentStrategy_SaveProposedOrderTopicMutationVariables
>;

/**
 * __useContentStrategy_SaveProposedOrderTopicMutation__
 *
 * To run a mutation, you first call `useContentStrategy_SaveProposedOrderTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContentStrategy_SaveProposedOrderTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contentStrategySaveProposedOrderTopicMutation, { data, loading, error }] = useContentStrategy_SaveProposedOrderTopicMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContentStrategy_SaveProposedOrderTopicMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ContentStrategy_SaveProposedOrderTopicMutation,
    ContentStrategy_SaveProposedOrderTopicMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ContentStrategy_SaveProposedOrderTopicMutation,
    ContentStrategy_SaveProposedOrderTopicMutationVariables
  >(ContentStrategy_SaveProposedOrderTopicDocument, options);
}
export type ContentStrategy_SaveProposedOrderTopicMutationHookResult = ReturnType<
  typeof useContentStrategy_SaveProposedOrderTopicMutation
>;
export type ContentStrategy_SaveProposedOrderTopicMutationResult =
  ApolloReactCommon.MutationResult<ContentStrategy_SaveProposedOrderTopicMutation>;
export type ContentStrategy_SaveProposedOrderTopicMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ContentStrategy_SaveProposedOrderTopicMutation,
  ContentStrategy_SaveProposedOrderTopicMutationVariables
>;
export const ContentStrategyTaskWorkspaceDocument = gql`
  query ContentStrategyTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      id
      customerId
      customerProposal {
        id
        proposedOrderTopics {
          ...ContentStrategyTaskWorkspace_ProposedOrderTopic
        }
      }
      contentCalendarOrders {
        ...ContentStrategyTaskWorkspace_Orders
      }
    }
  }
  ${ContentStrategyTaskWorkspace_ProposedOrderTopicFragmentDoc}
  ${ContentStrategyTaskWorkspace_OrdersFragmentDoc}
`;

/**
 * __useContentStrategyTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useContentStrategyTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentStrategyTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentStrategyTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useContentStrategyTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ContentStrategyTaskWorkspaceQuery,
    ContentStrategyTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentStrategyTaskWorkspaceQuery, ContentStrategyTaskWorkspaceQueryVariables>(
    ContentStrategyTaskWorkspaceDocument,
    options
  );
}
export function useContentStrategyTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ContentStrategyTaskWorkspaceQuery,
    ContentStrategyTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentStrategyTaskWorkspaceQuery, ContentStrategyTaskWorkspaceQueryVariables>(
    ContentStrategyTaskWorkspaceDocument,
    options
  );
}
export type ContentStrategyTaskWorkspaceQueryHookResult = ReturnType<typeof useContentStrategyTaskWorkspaceQuery>;
export type ContentStrategyTaskWorkspaceLazyQueryHookResult = ReturnType<
  typeof useContentStrategyTaskWorkspaceLazyQuery
>;
export type ContentStrategyTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ContentStrategyTaskWorkspaceQuery,
  ContentStrategyTaskWorkspaceQueryVariables
>;
export const ContentTaskWorkspaceDocument = gql`
  query ContentTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      ...ContentTaskWorkspace_Task
    }
  }
  ${ContentTaskWorkspace_TaskFragmentDoc}
`;

/**
 * __useContentTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useContentTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useContentTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ContentTaskWorkspaceQuery, ContentTaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ContentTaskWorkspaceQuery, ContentTaskWorkspaceQueryVariables>(
    ContentTaskWorkspaceDocument,
    options
  );
}
export function useContentTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContentTaskWorkspaceQuery, ContentTaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ContentTaskWorkspaceQuery, ContentTaskWorkspaceQueryVariables>(
    ContentTaskWorkspaceDocument,
    options
  );
}
export type ContentTaskWorkspaceQueryHookResult = ReturnType<typeof useContentTaskWorkspaceQuery>;
export type ContentTaskWorkspaceLazyQueryHookResult = ReturnType<typeof useContentTaskWorkspaceLazyQuery>;
export type ContentTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ContentTaskWorkspaceQuery,
  ContentTaskWorkspaceQueryVariables
>;
export const TaskWorkspaceExternalContentDocument = gql`
  query TaskWorkspaceExternalContent($taskId: Int!) {
    task(taskId: $taskId) {
      id
      customer {
        id
        name
        website
        partner {
          id
          name
        }
        instructions
      }
      order {
        id
        resultUrl
        resultUrlDomainAuthority
        startDate
        topic
        customerKeyword {
          id
          name
        }
        orderExternalContentSpec {
          id
          externalOrderId
          vendor {
            id
            name
          }
        }
      }
      product {
        id
        name
      }
    }
  }
`;

/**
 * __useTaskWorkspaceExternalContentQuery__
 *
 * To run a query within a React component, call `useTaskWorkspaceExternalContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskWorkspaceExternalContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskWorkspaceExternalContentQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskWorkspaceExternalContentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    TaskWorkspaceExternalContentQuery,
    TaskWorkspaceExternalContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TaskWorkspaceExternalContentQuery, TaskWorkspaceExternalContentQueryVariables>(
    TaskWorkspaceExternalContentDocument,
    options
  );
}
export function useTaskWorkspaceExternalContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TaskWorkspaceExternalContentQuery,
    TaskWorkspaceExternalContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TaskWorkspaceExternalContentQuery, TaskWorkspaceExternalContentQueryVariables>(
    TaskWorkspaceExternalContentDocument,
    options
  );
}
export type TaskWorkspaceExternalContentQueryHookResult = ReturnType<typeof useTaskWorkspaceExternalContentQuery>;
export type TaskWorkspaceExternalContentLazyQueryHookResult = ReturnType<
  typeof useTaskWorkspaceExternalContentLazyQuery
>;
export type TaskWorkspaceExternalContentQueryResult = ApolloReactCommon.QueryResult<
  TaskWorkspaceExternalContentQuery,
  TaskWorkspaceExternalContentQueryVariables
>;
export const TaskWorkspaceExternalContent_UpdateOrderDetailsDocument = gql`
  mutation TaskWorkspaceExternalContent_UpdateOrderDetails(
    $orderInput: UpdateOrderInput!
    $orderExternalContentSpecInput: UpdateOrderExternalContentSpecInput!
  ) {
    updateOrder(input: $orderInput) {
      order {
        id
      }
    }
    updateOrderExternalContentSpec(input: $orderExternalContentSpecInput) {
      orderExternalContentSpec {
        id
      }
    }
  }
`;
export type TaskWorkspaceExternalContent_UpdateOrderDetailsMutationFn = ApolloReactCommon.MutationFunction<
  TaskWorkspaceExternalContent_UpdateOrderDetailsMutation,
  TaskWorkspaceExternalContent_UpdateOrderDetailsMutationVariables
>;

/**
 * __useTaskWorkspaceExternalContent_UpdateOrderDetailsMutation__
 *
 * To run a mutation, you first call `useTaskWorkspaceExternalContent_UpdateOrderDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskWorkspaceExternalContent_UpdateOrderDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskWorkspaceExternalContentUpdateOrderDetailsMutation, { data, loading, error }] = useTaskWorkspaceExternalContent_UpdateOrderDetailsMutation({
 *   variables: {
 *      orderInput: // value for 'orderInput'
 *      orderExternalContentSpecInput: // value for 'orderExternalContentSpecInput'
 *   },
 * });
 */
export function useTaskWorkspaceExternalContent_UpdateOrderDetailsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TaskWorkspaceExternalContent_UpdateOrderDetailsMutation,
    TaskWorkspaceExternalContent_UpdateOrderDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    TaskWorkspaceExternalContent_UpdateOrderDetailsMutation,
    TaskWorkspaceExternalContent_UpdateOrderDetailsMutationVariables
  >(TaskWorkspaceExternalContent_UpdateOrderDetailsDocument, options);
}
export type TaskWorkspaceExternalContent_UpdateOrderDetailsMutationHookResult = ReturnType<
  typeof useTaskWorkspaceExternalContent_UpdateOrderDetailsMutation
>;
export type TaskWorkspaceExternalContent_UpdateOrderDetailsMutationResult =
  ApolloReactCommon.MutationResult<TaskWorkspaceExternalContent_UpdateOrderDetailsMutation>;
export type TaskWorkspaceExternalContent_UpdateOrderDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TaskWorkspaceExternalContent_UpdateOrderDetailsMutation,
  TaskWorkspaceExternalContent_UpdateOrderDetailsMutationVariables
>;
export const GuestArticleWorkspace_TaskDocument = gql`
  query GuestArticleWorkspace_Task($taskId: Int!, $subTasksSort: TasksSort, $statusIds: [String!]) {
    task(taskId: $taskId) {
      id
      taskTypeId
      keyword
      landingPageUrl
      isSystemTaskInProgress
      status {
        id
      }
      currentSubTask {
        id
        taskTypeId
      }
      subTasks(statusIds: ["completed"], sort: $subTasksSort) {
        nodes {
          id
          taskTypeId
          completedAt
        }
      }
      customer {
        id
        name
        website
        instructions
        category {
          id
          name
        }
      }
      order {
        id
        resultUrl
        partnerProduct {
          id
          name
          product {
            id
            name
          }
        }
        targetLinkLocation {
          id
          domain
          domainAuthority
          contactName
          contactEmail
          cost
          publisherLinkLocation {
            id
            publisher {
              id
              name
              paymentMethod
              paymentMethodId
              isAutoPayEnabled
              isInvoiceRequired
              contactEmail
              contactName
              instructions
            }
          }
        }
        orderContentItems {
          contentItem {
            id
            title
            content
          }
        }
        publisherPayment(statusIds: $statusIds) {
          id
          processor
          processorReferenceId
          paymentBatchId
          amount
          status {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGuestArticleWorkspace_TaskQuery__
 *
 * To run a query within a React component, call `useGuestArticleWorkspace_TaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestArticleWorkspace_TaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestArticleWorkspace_TaskQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      subTasksSort: // value for 'subTasksSort'
 *      statusIds: // value for 'statusIds'
 *   },
 * });
 */
export function useGuestArticleWorkspace_TaskQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GuestArticleWorkspace_TaskQuery,
    GuestArticleWorkspace_TaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GuestArticleWorkspace_TaskQuery, GuestArticleWorkspace_TaskQueryVariables>(
    GuestArticleWorkspace_TaskDocument,
    options
  );
}
export function useGuestArticleWorkspace_TaskLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GuestArticleWorkspace_TaskQuery,
    GuestArticleWorkspace_TaskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GuestArticleWorkspace_TaskQuery, GuestArticleWorkspace_TaskQueryVariables>(
    GuestArticleWorkspace_TaskDocument,
    options
  );
}
export type GuestArticleWorkspace_TaskQueryHookResult = ReturnType<typeof useGuestArticleWorkspace_TaskQuery>;
export type GuestArticleWorkspace_TaskLazyQueryHookResult = ReturnType<typeof useGuestArticleWorkspace_TaskLazyQuery>;
export type GuestArticleWorkspace_TaskQueryResult = ApolloReactCommon.QueryResult<
  GuestArticleWorkspace_TaskQuery,
  GuestArticleWorkspace_TaskQueryVariables
>;
export const SubmitProposalReviewDocument = gql`
  mutation SubmitProposalReview($input: SubmitProposalReviewInput!) {
    submitProposalReview(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type SubmitProposalReviewMutationFn = ApolloReactCommon.MutationFunction<
  SubmitProposalReviewMutation,
  SubmitProposalReviewMutationVariables
>;

/**
 * __useSubmitProposalReviewMutation__
 *
 * To run a mutation, you first call `useSubmitProposalReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitProposalReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitProposalReviewMutation, { data, loading, error }] = useSubmitProposalReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitProposalReviewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitProposalReviewMutation,
    SubmitProposalReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SubmitProposalReviewMutation, SubmitProposalReviewMutationVariables>(
    SubmitProposalReviewDocument,
    options
  );
}
export type SubmitProposalReviewMutationHookResult = ReturnType<typeof useSubmitProposalReviewMutation>;
export type SubmitProposalReviewMutationResult = ApolloReactCommon.MutationResult<SubmitProposalReviewMutation>;
export type SubmitProposalReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitProposalReviewMutation,
  SubmitProposalReviewMutationVariables
>;
export const UpdateOrderProposalReviewDocument = gql`
  mutation UpdateOrderProposalReview($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      order {
        id
      }
    }
  }
`;
export type UpdateOrderProposalReviewMutationFn = ApolloReactCommon.MutationFunction<
  UpdateOrderProposalReviewMutation,
  UpdateOrderProposalReviewMutationVariables
>;

/**
 * __useUpdateOrderProposalReviewMutation__
 *
 * To run a mutation, you first call `useUpdateOrderProposalReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderProposalReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderProposalReviewMutation, { data, loading, error }] = useUpdateOrderProposalReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderProposalReviewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrderProposalReviewMutation,
    UpdateOrderProposalReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateOrderProposalReviewMutation, UpdateOrderProposalReviewMutationVariables>(
    UpdateOrderProposalReviewDocument,
    options
  );
}
export type UpdateOrderProposalReviewMutationHookResult = ReturnType<typeof useUpdateOrderProposalReviewMutation>;
export type UpdateOrderProposalReviewMutationResult =
  ApolloReactCommon.MutationResult<UpdateOrderProposalReviewMutation>;
export type UpdateOrderProposalReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOrderProposalReviewMutation,
  UpdateOrderProposalReviewMutationVariables
>;
export const ProposalReviewTaskWorkspaceDocument = gql`
  query ProposalReviewTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      id
      status {
        id
        name
      }
      order {
        id
        targetResultUrl
        targetLinkLocation {
          id
          cost
          publisherLinkLocation {
            id
            publisher {
              id
              name
            }
          }
        }
      }
      reviewTargetTask {
        id
        contentItem {
          id
          title
          content
        }
      }
    }
  }
`;

/**
 * __useProposalReviewTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useProposalReviewTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useProposalReviewTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProposalReviewTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useProposalReviewTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ProposalReviewTaskWorkspaceQuery,
    ProposalReviewTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ProposalReviewTaskWorkspaceQuery, ProposalReviewTaskWorkspaceQueryVariables>(
    ProposalReviewTaskWorkspaceDocument,
    options
  );
}
export function useProposalReviewTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProposalReviewTaskWorkspaceQuery,
    ProposalReviewTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ProposalReviewTaskWorkspaceQuery, ProposalReviewTaskWorkspaceQueryVariables>(
    ProposalReviewTaskWorkspaceDocument,
    options
  );
}
export type ProposalReviewTaskWorkspaceQueryHookResult = ReturnType<typeof useProposalReviewTaskWorkspaceQuery>;
export type ProposalReviewTaskWorkspaceLazyQueryHookResult = ReturnType<typeof useProposalReviewTaskWorkspaceLazyQuery>;
export type ProposalReviewTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ProposalReviewTaskWorkspaceQuery,
  ProposalReviewTaskWorkspaceQueryVariables
>;
export const QaContentSendTaskWorkspaceDocument = gql`
  query QaContentSendTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      ...QaContentSendTaskWorkspace_Task
    }
  }
  ${QaContentSendTaskWorkspace_TaskFragmentDoc}
`;

/**
 * __useQaContentSendTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useQaContentSendTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useQaContentSendTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQaContentSendTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useQaContentSendTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    QaContentSendTaskWorkspaceQuery,
    QaContentSendTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<QaContentSendTaskWorkspaceQuery, QaContentSendTaskWorkspaceQueryVariables>(
    QaContentSendTaskWorkspaceDocument,
    options
  );
}
export function useQaContentSendTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    QaContentSendTaskWorkspaceQuery,
    QaContentSendTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<QaContentSendTaskWorkspaceQuery, QaContentSendTaskWorkspaceQueryVariables>(
    QaContentSendTaskWorkspaceDocument,
    options
  );
}
export type QaContentSendTaskWorkspaceQueryHookResult = ReturnType<typeof useQaContentSendTaskWorkspaceQuery>;
export type QaContentSendTaskWorkspaceLazyQueryHookResult = ReturnType<typeof useQaContentSendTaskWorkspaceLazyQuery>;
export type QaContentSendTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  QaContentSendTaskWorkspaceQuery,
  QaContentSendTaskWorkspaceQueryVariables
>;
export const QaContentSendTaskWorkspace_SaveTaskContentItemDocument = gql`
  mutation QaContentSendTaskWorkspace_SaveTaskContentItem($input: SaveTaskContentItemInput!) {
    saveTaskContentItem(input: $input) {
      contentItem {
        id
      }
    }
  }
`;
export type QaContentSendTaskWorkspace_SaveTaskContentItemMutationFn = ApolloReactCommon.MutationFunction<
  QaContentSendTaskWorkspace_SaveTaskContentItemMutation,
  QaContentSendTaskWorkspace_SaveTaskContentItemMutationVariables
>;

/**
 * __useQaContentSendTaskWorkspace_SaveTaskContentItemMutation__
 *
 * To run a mutation, you first call `useQaContentSendTaskWorkspace_SaveTaskContentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQaContentSendTaskWorkspace_SaveTaskContentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [qaContentSendTaskWorkspaceSaveTaskContentItemMutation, { data, loading, error }] = useQaContentSendTaskWorkspace_SaveTaskContentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useQaContentSendTaskWorkspace_SaveTaskContentItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    QaContentSendTaskWorkspace_SaveTaskContentItemMutation,
    QaContentSendTaskWorkspace_SaveTaskContentItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    QaContentSendTaskWorkspace_SaveTaskContentItemMutation,
    QaContentSendTaskWorkspace_SaveTaskContentItemMutationVariables
  >(QaContentSendTaskWorkspace_SaveTaskContentItemDocument, options);
}
export type QaContentSendTaskWorkspace_SaveTaskContentItemMutationHookResult = ReturnType<
  typeof useQaContentSendTaskWorkspace_SaveTaskContentItemMutation
>;
export type QaContentSendTaskWorkspace_SaveTaskContentItemMutationResult =
  ApolloReactCommon.MutationResult<QaContentSendTaskWorkspace_SaveTaskContentItemMutation>;
export type QaContentSendTaskWorkspace_SaveTaskContentItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  QaContentSendTaskWorkspace_SaveTaskContentItemMutation,
  QaContentSendTaskWorkspace_SaveTaskContentItemMutationVariables
>;
export const GuestArticleTaskWorkspace_SaveOrderContentItemDocument = gql`
  mutation GuestArticleTaskWorkspace_SaveOrderContentItem($input: SaveOrderContentItemInput!) {
    saveOrderContentItem(input: $input) {
      contentItem {
        id
      }
    }
  }
`;
export type GuestArticleTaskWorkspace_SaveOrderContentItemMutationFn = ApolloReactCommon.MutationFunction<
  GuestArticleTaskWorkspace_SaveOrderContentItemMutation,
  GuestArticleTaskWorkspace_SaveOrderContentItemMutationVariables
>;

/**
 * __useGuestArticleTaskWorkspace_SaveOrderContentItemMutation__
 *
 * To run a mutation, you first call `useGuestArticleTaskWorkspace_SaveOrderContentItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestArticleTaskWorkspace_SaveOrderContentItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestArticleTaskWorkspaceSaveOrderContentItemMutation, { data, loading, error }] = useGuestArticleTaskWorkspace_SaveOrderContentItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuestArticleTaskWorkspace_SaveOrderContentItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GuestArticleTaskWorkspace_SaveOrderContentItemMutation,
    GuestArticleTaskWorkspace_SaveOrderContentItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GuestArticleTaskWorkspace_SaveOrderContentItemMutation,
    GuestArticleTaskWorkspace_SaveOrderContentItemMutationVariables
  >(GuestArticleTaskWorkspace_SaveOrderContentItemDocument, options);
}
export type GuestArticleTaskWorkspace_SaveOrderContentItemMutationHookResult = ReturnType<
  typeof useGuestArticleTaskWorkspace_SaveOrderContentItemMutation
>;
export type GuestArticleTaskWorkspace_SaveOrderContentItemMutationResult =
  ApolloReactCommon.MutationResult<GuestArticleTaskWorkspace_SaveOrderContentItemMutation>;
export type GuestArticleTaskWorkspace_SaveOrderContentItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GuestArticleTaskWorkspace_SaveOrderContentItemMutation,
  GuestArticleTaskWorkspace_SaveOrderContentItemMutationVariables
>;
export const UpdateSubTaskDocument = gql`
  mutation UpdateSubTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        id
      }
    }
  }
`;
export type UpdateSubTaskMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSubTaskMutation,
  UpdateSubTaskMutationVariables
>;

/**
 * __useUpdateSubTaskMutation__
 *
 * To run a mutation, you first call `useUpdateSubTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubTaskMutation, { data, loading, error }] = useUpdateSubTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubTaskMutation, UpdateSubTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateSubTaskMutation, UpdateSubTaskMutationVariables>(
    UpdateSubTaskDocument,
    options
  );
}
export type UpdateSubTaskMutationHookResult = ReturnType<typeof useUpdateSubTaskMutation>;
export type UpdateSubTaskMutationResult = ApolloReactCommon.MutationResult<UpdateSubTaskMutation>;
export type UpdateSubTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSubTaskMutation,
  UpdateSubTaskMutationVariables
>;
export const GuestArticle_UpdateOrderDocument = gql`
  mutation GuestArticle_UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      order {
        id
      }
    }
  }
`;
export type GuestArticle_UpdateOrderMutationFn = ApolloReactCommon.MutationFunction<
  GuestArticle_UpdateOrderMutation,
  GuestArticle_UpdateOrderMutationVariables
>;

/**
 * __useGuestArticle_UpdateOrderMutation__
 *
 * To run a mutation, you first call `useGuestArticle_UpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestArticle_UpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestArticleUpdateOrderMutation, { data, loading, error }] = useGuestArticle_UpdateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuestArticle_UpdateOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GuestArticle_UpdateOrderMutation,
    GuestArticle_UpdateOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<GuestArticle_UpdateOrderMutation, GuestArticle_UpdateOrderMutationVariables>(
    GuestArticle_UpdateOrderDocument,
    options
  );
}
export type GuestArticle_UpdateOrderMutationHookResult = ReturnType<typeof useGuestArticle_UpdateOrderMutation>;
export type GuestArticle_UpdateOrderMutationResult = ApolloReactCommon.MutationResult<GuestArticle_UpdateOrderMutation>;
export type GuestArticle_UpdateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GuestArticle_UpdateOrderMutation,
  GuestArticle_UpdateOrderMutationVariables
>;
export const GuestArticle_CreatePublisherPaymentDocument = gql`
  mutation GuestArticle_CreatePublisherPayment($input: CreatePublisherPaymentInput!) {
    createPublisherPayment(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type GuestArticle_CreatePublisherPaymentMutationFn = ApolloReactCommon.MutationFunction<
  GuestArticle_CreatePublisherPaymentMutation,
  GuestArticle_CreatePublisherPaymentMutationVariables
>;

/**
 * __useGuestArticle_CreatePublisherPaymentMutation__
 *
 * To run a mutation, you first call `useGuestArticle_CreatePublisherPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestArticle_CreatePublisherPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestArticleCreatePublisherPaymentMutation, { data, loading, error }] = useGuestArticle_CreatePublisherPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuestArticle_CreatePublisherPaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GuestArticle_CreatePublisherPaymentMutation,
    GuestArticle_CreatePublisherPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GuestArticle_CreatePublisherPaymentMutation,
    GuestArticle_CreatePublisherPaymentMutationVariables
  >(GuestArticle_CreatePublisherPaymentDocument, options);
}
export type GuestArticle_CreatePublisherPaymentMutationHookResult = ReturnType<
  typeof useGuestArticle_CreatePublisherPaymentMutation
>;
export type GuestArticle_CreatePublisherPaymentMutationResult =
  ApolloReactCommon.MutationResult<GuestArticle_CreatePublisherPaymentMutation>;
export type GuestArticle_CreatePublisherPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GuestArticle_CreatePublisherPaymentMutation,
  GuestArticle_CreatePublisherPaymentMutationVariables
>;
export const GuestArticle_UpdatePublisherPaymentDocument = gql`
  mutation GuestArticle_UpdatePublisherPayment($input: UpdatePublisherPaymentInput!) {
    updatePublisherPayment(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type GuestArticle_UpdatePublisherPaymentMutationFn = ApolloReactCommon.MutationFunction<
  GuestArticle_UpdatePublisherPaymentMutation,
  GuestArticle_UpdatePublisherPaymentMutationVariables
>;

/**
 * __useGuestArticle_UpdatePublisherPaymentMutation__
 *
 * To run a mutation, you first call `useGuestArticle_UpdatePublisherPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestArticle_UpdatePublisherPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestArticleUpdatePublisherPaymentMutation, { data, loading, error }] = useGuestArticle_UpdatePublisherPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuestArticle_UpdatePublisherPaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GuestArticle_UpdatePublisherPaymentMutation,
    GuestArticle_UpdatePublisherPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GuestArticle_UpdatePublisherPaymentMutation,
    GuestArticle_UpdatePublisherPaymentMutationVariables
  >(GuestArticle_UpdatePublisherPaymentDocument, options);
}
export type GuestArticle_UpdatePublisherPaymentMutationHookResult = ReturnType<
  typeof useGuestArticle_UpdatePublisherPaymentMutation
>;
export type GuestArticle_UpdatePublisherPaymentMutationResult =
  ApolloReactCommon.MutationResult<GuestArticle_UpdatePublisherPaymentMutation>;
export type GuestArticle_UpdatePublisherPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GuestArticle_UpdatePublisherPaymentMutation,
  GuestArticle_UpdatePublisherPaymentMutationVariables
>;
export const GuestArticle_CreateSystemTaskInprogressDocument = gql`
  mutation GuestArticle_CreateSystemTaskInprogress($input: CreateSystemTaskInprogressInput!) {
    createSystemTaskInprogress(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type GuestArticle_CreateSystemTaskInprogressMutationFn = ApolloReactCommon.MutationFunction<
  GuestArticle_CreateSystemTaskInprogressMutation,
  GuestArticle_CreateSystemTaskInprogressMutationVariables
>;

/**
 * __useGuestArticle_CreateSystemTaskInprogressMutation__
 *
 * To run a mutation, you first call `useGuestArticle_CreateSystemTaskInprogressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestArticle_CreateSystemTaskInprogressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestArticleCreateSystemTaskInprogressMutation, { data, loading, error }] = useGuestArticle_CreateSystemTaskInprogressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuestArticle_CreateSystemTaskInprogressMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GuestArticle_CreateSystemTaskInprogressMutation,
    GuestArticle_CreateSystemTaskInprogressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GuestArticle_CreateSystemTaskInprogressMutation,
    GuestArticle_CreateSystemTaskInprogressMutationVariables
  >(GuestArticle_CreateSystemTaskInprogressDocument, options);
}
export type GuestArticle_CreateSystemTaskInprogressMutationHookResult = ReturnType<
  typeof useGuestArticle_CreateSystemTaskInprogressMutation
>;
export type GuestArticle_CreateSystemTaskInprogressMutationResult =
  ApolloReactCommon.MutationResult<GuestArticle_CreateSystemTaskInprogressMutation>;
export type GuestArticle_CreateSystemTaskInprogressMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GuestArticle_CreateSystemTaskInprogressMutation,
  GuestArticle_CreateSystemTaskInprogressMutationVariables
>;
export const GuestArticle_DeleteSystemTaskInprogressDocument = gql`
  mutation GuestArticle_DeleteSystemTaskInprogress($input: DeleteSystemTaskInprogressInput!) {
    deleteSystemTaskInprogresss(input: $input) {
      ok
    }
  }
`;
export type GuestArticle_DeleteSystemTaskInprogressMutationFn = ApolloReactCommon.MutationFunction<
  GuestArticle_DeleteSystemTaskInprogressMutation,
  GuestArticle_DeleteSystemTaskInprogressMutationVariables
>;

/**
 * __useGuestArticle_DeleteSystemTaskInprogressMutation__
 *
 * To run a mutation, you first call `useGuestArticle_DeleteSystemTaskInprogressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestArticle_DeleteSystemTaskInprogressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestArticleDeleteSystemTaskInprogressMutation, { data, loading, error }] = useGuestArticle_DeleteSystemTaskInprogressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuestArticle_DeleteSystemTaskInprogressMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GuestArticle_DeleteSystemTaskInprogressMutation,
    GuestArticle_DeleteSystemTaskInprogressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GuestArticle_DeleteSystemTaskInprogressMutation,
    GuestArticle_DeleteSystemTaskInprogressMutationVariables
  >(GuestArticle_DeleteSystemTaskInprogressDocument, options);
}
export type GuestArticle_DeleteSystemTaskInprogressMutationHookResult = ReturnType<
  typeof useGuestArticle_DeleteSystemTaskInprogressMutation
>;
export type GuestArticle_DeleteSystemTaskInprogressMutationResult =
  ApolloReactCommon.MutationResult<GuestArticle_DeleteSystemTaskInprogressMutation>;
export type GuestArticle_DeleteSystemTaskInprogressMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GuestArticle_DeleteSystemTaskInprogressMutation,
  GuestArticle_DeleteSystemTaskInprogressMutationVariables
>;
export const WebsiteSelectionTaskWorkspaceDocument = gql`
  query WebsiteSelectionTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      id
      order {
        id
        targetResultUrl
        partnerProduct {
          id
          product {
            id
            minDA
            minSV
          }
        }
      }
    }
  }
`;

/**
 * __useWebsiteSelectionTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useWebsiteSelectionTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteSelectionTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteSelectionTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useWebsiteSelectionTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    WebsiteSelectionTaskWorkspaceQuery,
    WebsiteSelectionTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<WebsiteSelectionTaskWorkspaceQuery, WebsiteSelectionTaskWorkspaceQueryVariables>(
    WebsiteSelectionTaskWorkspaceDocument,
    options
  );
}
export function useWebsiteSelectionTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WebsiteSelectionTaskWorkspaceQuery,
    WebsiteSelectionTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<WebsiteSelectionTaskWorkspaceQuery, WebsiteSelectionTaskWorkspaceQueryVariables>(
    WebsiteSelectionTaskWorkspaceDocument,
    options
  );
}
export type WebsiteSelectionTaskWorkspaceQueryHookResult = ReturnType<typeof useWebsiteSelectionTaskWorkspaceQuery>;
export type WebsiteSelectionTaskWorkspaceLazyQueryHookResult = ReturnType<
  typeof useWebsiteSelectionTaskWorkspaceLazyQuery
>;
export type WebsiteSelectionTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  WebsiteSelectionTaskWorkspaceQuery,
  WebsiteSelectionTaskWorkspaceQueryVariables
>;
export const CreateProposedCustomerKeywordDocument = gql`
  mutation CreateProposedCustomerKeyword($input: CreateProposedCustomerKeywordInput!) {
    createProposedCustomerKeyword(input: $input) {
      proposedCustomerKeyword {
        id
      }
    }
  }
`;
export type CreateProposedCustomerKeywordMutationFn = ApolloReactCommon.MutationFunction<
  CreateProposedCustomerKeywordMutation,
  CreateProposedCustomerKeywordMutationVariables
>;

/**
 * __useCreateProposedCustomerKeywordMutation__
 *
 * To run a mutation, you first call `useCreateProposedCustomerKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProposedCustomerKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProposedCustomerKeywordMutation, { data, loading, error }] = useCreateProposedCustomerKeywordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProposedCustomerKeywordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProposedCustomerKeywordMutation,
    CreateProposedCustomerKeywordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateProposedCustomerKeywordMutation,
    CreateProposedCustomerKeywordMutationVariables
  >(CreateProposedCustomerKeywordDocument, options);
}
export type CreateProposedCustomerKeywordMutationHookResult = ReturnType<
  typeof useCreateProposedCustomerKeywordMutation
>;
export type CreateProposedCustomerKeywordMutationResult =
  ApolloReactCommon.MutationResult<CreateProposedCustomerKeywordMutation>;
export type CreateProposedCustomerKeywordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateProposedCustomerKeywordMutation,
  CreateProposedCustomerKeywordMutationVariables
>;
export const CreateBulkProposedCustomerKeywordsDocument = gql`
  mutation CreateBulkProposedCustomerKeywords($input: CreateBulkProposedCustomerKeywordsInput!) {
    createBulkProposedCustomerKeywords(input: $input) {
      proposedCustomerKeywords {
        id
        name
      }
    }
  }
`;
export type CreateBulkProposedCustomerKeywordsMutationFn = ApolloReactCommon.MutationFunction<
  CreateBulkProposedCustomerKeywordsMutation,
  CreateBulkProposedCustomerKeywordsMutationVariables
>;

/**
 * __useCreateBulkProposedCustomerKeywordsMutation__
 *
 * To run a mutation, you first call `useCreateBulkProposedCustomerKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkProposedCustomerKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkProposedCustomerKeywordsMutation, { data, loading, error }] = useCreateBulkProposedCustomerKeywordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBulkProposedCustomerKeywordsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateBulkProposedCustomerKeywordsMutation,
    CreateBulkProposedCustomerKeywordsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateBulkProposedCustomerKeywordsMutation,
    CreateBulkProposedCustomerKeywordsMutationVariables
  >(CreateBulkProposedCustomerKeywordsDocument, options);
}
export type CreateBulkProposedCustomerKeywordsMutationHookResult = ReturnType<
  typeof useCreateBulkProposedCustomerKeywordsMutation
>;
export type CreateBulkProposedCustomerKeywordsMutationResult =
  ApolloReactCommon.MutationResult<CreateBulkProposedCustomerKeywordsMutation>;
export type CreateBulkProposedCustomerKeywordsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateBulkProposedCustomerKeywordsMutation,
  CreateBulkProposedCustomerKeywordsMutationVariables
>;
export const DeleteProposedCustomerKeywordDocument = gql`
  mutation DeleteProposedCustomerKeyword($input: DeleteProposedCustomerKeywordInput!) {
    deleteProposedCustomerKeyword(input: $input) {
      isDeleted
    }
  }
`;
export type DeleteProposedCustomerKeywordMutationFn = ApolloReactCommon.MutationFunction<
  DeleteProposedCustomerKeywordMutation,
  DeleteProposedCustomerKeywordMutationVariables
>;

/**
 * __useDeleteProposedCustomerKeywordMutation__
 *
 * To run a mutation, you first call `useDeleteProposedCustomerKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProposedCustomerKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProposedCustomerKeywordMutation, { data, loading, error }] = useDeleteProposedCustomerKeywordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProposedCustomerKeywordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteProposedCustomerKeywordMutation,
    DeleteProposedCustomerKeywordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteProposedCustomerKeywordMutation,
    DeleteProposedCustomerKeywordMutationVariables
  >(DeleteProposedCustomerKeywordDocument, options);
}
export type DeleteProposedCustomerKeywordMutationHookResult = ReturnType<
  typeof useDeleteProposedCustomerKeywordMutation
>;
export type DeleteProposedCustomerKeywordMutationResult =
  ApolloReactCommon.MutationResult<DeleteProposedCustomerKeywordMutation>;
export type DeleteProposedCustomerKeywordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteProposedCustomerKeywordMutation,
  DeleteProposedCustomerKeywordMutationVariables
>;
export const UpdateCustomerKeywordProposalDocument = gql`
  mutation UpdateCustomerKeywordProposal($input: UpdateCustomerKeywordProposalInput!) {
    updateCustomerKeywordProposal(input: $input) {
      customerKeywordProposal {
        id
        summary
      }
    }
  }
`;
export type UpdateCustomerKeywordProposalMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCustomerKeywordProposalMutation,
  UpdateCustomerKeywordProposalMutationVariables
>;

/**
 * __useUpdateCustomerKeywordProposalMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerKeywordProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerKeywordProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerKeywordProposalMutation, { data, loading, error }] = useUpdateCustomerKeywordProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerKeywordProposalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCustomerKeywordProposalMutation,
    UpdateCustomerKeywordProposalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCustomerKeywordProposalMutation,
    UpdateCustomerKeywordProposalMutationVariables
  >(UpdateCustomerKeywordProposalDocument, options);
}
export type UpdateCustomerKeywordProposalMutationHookResult = ReturnType<
  typeof useUpdateCustomerKeywordProposalMutation
>;
export type UpdateCustomerKeywordProposalMutationResult =
  ApolloReactCommon.MutationResult<UpdateCustomerKeywordProposalMutation>;
export type UpdateCustomerKeywordProposalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCustomerKeywordProposalMutation,
  UpdateCustomerKeywordProposalMutationVariables
>;
export const UpdateProposedCustomerKeywordDocument = gql`
  mutation UpdateProposedCustomerKeyword($input: UpdateProposedCustomerKeywordInput!) {
    updateProposedCustomerKeyword(input: $input) {
      proposedCustomerKeyword {
        id
        name
      }
    }
  }
`;
export type UpdateProposedCustomerKeywordMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProposedCustomerKeywordMutation,
  UpdateProposedCustomerKeywordMutationVariables
>;

/**
 * __useUpdateProposedCustomerKeywordMutation__
 *
 * To run a mutation, you first call `useUpdateProposedCustomerKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProposedCustomerKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProposedCustomerKeywordMutation, { data, loading, error }] = useUpdateProposedCustomerKeywordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProposedCustomerKeywordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProposedCustomerKeywordMutation,
    UpdateProposedCustomerKeywordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateProposedCustomerKeywordMutation,
    UpdateProposedCustomerKeywordMutationVariables
  >(UpdateProposedCustomerKeywordDocument, options);
}
export type UpdateProposedCustomerKeywordMutationHookResult = ReturnType<
  typeof useUpdateProposedCustomerKeywordMutation
>;
export type UpdateProposedCustomerKeywordMutationResult =
  ApolloReactCommon.MutationResult<UpdateProposedCustomerKeywordMutation>;
export type UpdateProposedCustomerKeywordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProposedCustomerKeywordMutation,
  UpdateProposedCustomerKeywordMutationVariables
>;
export const ClearProposedCustomerKeywordsRejectionDocument = gql`
  mutation ClearProposedCustomerKeywordsRejection($input: ClearProposedCustomerKeywordsRejectionInput!) {
    clearProposedCustomerKeywordsRejection(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type ClearProposedCustomerKeywordsRejectionMutationFn = ApolloReactCommon.MutationFunction<
  ClearProposedCustomerKeywordsRejectionMutation,
  ClearProposedCustomerKeywordsRejectionMutationVariables
>;

/**
 * __useClearProposedCustomerKeywordsRejectionMutation__
 *
 * To run a mutation, you first call `useClearProposedCustomerKeywordsRejectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearProposedCustomerKeywordsRejectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearProposedCustomerKeywordsRejectionMutation, { data, loading, error }] = useClearProposedCustomerKeywordsRejectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearProposedCustomerKeywordsRejectionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ClearProposedCustomerKeywordsRejectionMutation,
    ClearProposedCustomerKeywordsRejectionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ClearProposedCustomerKeywordsRejectionMutation,
    ClearProposedCustomerKeywordsRejectionMutationVariables
  >(ClearProposedCustomerKeywordsRejectionDocument, options);
}
export type ClearProposedCustomerKeywordsRejectionMutationHookResult = ReturnType<
  typeof useClearProposedCustomerKeywordsRejectionMutation
>;
export type ClearProposedCustomerKeywordsRejectionMutationResult =
  ApolloReactCommon.MutationResult<ClearProposedCustomerKeywordsRejectionMutation>;
export type ClearProposedCustomerKeywordsRejectionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ClearProposedCustomerKeywordsRejectionMutation,
  ClearProposedCustomerKeywordsRejectionMutationVariables
>;
export const KeywordResearchTaskWorkspaceDocument = gql`
  query KeywordResearchTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      ...KeywordResearchTaskWorkspace_Task
    }
  }
  ${KeywordResearchTaskWorkspace_TaskFragmentDoc}
`;

/**
 * __useKeywordResearchTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useKeywordResearchTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordResearchTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordResearchTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useKeywordResearchTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    KeywordResearchTaskWorkspaceQuery,
    KeywordResearchTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<KeywordResearchTaskWorkspaceQuery, KeywordResearchTaskWorkspaceQueryVariables>(
    KeywordResearchTaskWorkspaceDocument,
    options
  );
}
export function useKeywordResearchTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    KeywordResearchTaskWorkspaceQuery,
    KeywordResearchTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<KeywordResearchTaskWorkspaceQuery, KeywordResearchTaskWorkspaceQueryVariables>(
    KeywordResearchTaskWorkspaceDocument,
    options
  );
}
export type KeywordResearchTaskWorkspaceQueryHookResult = ReturnType<typeof useKeywordResearchTaskWorkspaceQuery>;
export type KeywordResearchTaskWorkspaceLazyQueryHookResult = ReturnType<
  typeof useKeywordResearchTaskWorkspaceLazyQuery
>;
export type KeywordResearchTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  KeywordResearchTaskWorkspaceQuery,
  KeywordResearchTaskWorkspaceQueryVariables
>;
export const CreateCustomerLinkBuildDocument = gql`
  mutation CreateCustomerLinkBuild($input: CreateCustomerLinkBuildInput!) {
    createCustomerLinkBuild(input: $input) {
      ok
      error {
        message
      }
      customerLinkBuild {
        id
      }
    }
  }
`;
export type CreateCustomerLinkBuildMutationFn = ApolloReactCommon.MutationFunction<
  CreateCustomerLinkBuildMutation,
  CreateCustomerLinkBuildMutationVariables
>;

/**
 * __useCreateCustomerLinkBuildMutation__
 *
 * To run a mutation, you first call `useCreateCustomerLinkBuildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerLinkBuildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerLinkBuildMutation, { data, loading, error }] = useCreateCustomerLinkBuildMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerLinkBuildMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCustomerLinkBuildMutation,
    CreateCustomerLinkBuildMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCustomerLinkBuildMutation, CreateCustomerLinkBuildMutationVariables>(
    CreateCustomerLinkBuildDocument,
    options
  );
}
export type CreateCustomerLinkBuildMutationHookResult = ReturnType<typeof useCreateCustomerLinkBuildMutation>;
export type CreateCustomerLinkBuildMutationResult = ApolloReactCommon.MutationResult<CreateCustomerLinkBuildMutation>;
export type CreateCustomerLinkBuildMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCustomerLinkBuildMutation,
  CreateCustomerLinkBuildMutationVariables
>;
export const LinkBuildTaskWorkspaceDocument = gql`
  query LinkBuildTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      ...LinkBuildTaskWorkspace_Task
    }
  }
  ${LinkBuildTaskWorkspace_TaskFragmentDoc}
`;

/**
 * __useLinkBuildTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useLinkBuildTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkBuildTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkBuildTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useLinkBuildTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LinkBuildTaskWorkspaceQuery, LinkBuildTaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LinkBuildTaskWorkspaceQuery, LinkBuildTaskWorkspaceQueryVariables>(
    LinkBuildTaskWorkspaceDocument,
    options
  );
}
export function useLinkBuildTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LinkBuildTaskWorkspaceQuery, LinkBuildTaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LinkBuildTaskWorkspaceQuery, LinkBuildTaskWorkspaceQueryVariables>(
    LinkBuildTaskWorkspaceDocument,
    options
  );
}
export type LinkBuildTaskWorkspaceQueryHookResult = ReturnType<typeof useLinkBuildTaskWorkspaceQuery>;
export type LinkBuildTaskWorkspaceLazyQueryHookResult = ReturnType<typeof useLinkBuildTaskWorkspaceLazyQuery>;
export type LinkBuildTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  LinkBuildTaskWorkspaceQuery,
  LinkBuildTaskWorkspaceQueryVariables
>;
export const UpdatePublishGbpDocument = gql`
  mutation UpdatePublishGbp($input: UpdateOrderGbpItemInput!) {
    updateOrderGbpItem(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type UpdatePublishGbpMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePublishGbpMutation,
  UpdatePublishGbpMutationVariables
>;

/**
 * __useUpdatePublishGbpMutation__
 *
 * To run a mutation, you first call `useUpdatePublishGbpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublishGbpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublishGbpMutation, { data, loading, error }] = useUpdatePublishGbpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublishGbpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePublishGbpMutation, UpdatePublishGbpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePublishGbpMutation, UpdatePublishGbpMutationVariables>(
    UpdatePublishGbpDocument,
    options
  );
}
export type UpdatePublishGbpMutationHookResult = ReturnType<typeof useUpdatePublishGbpMutation>;
export type UpdatePublishGbpMutationResult = ApolloReactCommon.MutationResult<UpdatePublishGbpMutation>;
export type UpdatePublishGbpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePublishGbpMutation,
  UpdatePublishGbpMutationVariables
>;
export const PublishGbpTaskWorkspaceDocument = gql`
  query PublishGbpTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      id
      customer {
        id
        name
        website
        gbpUrl
        partner {
          id
          name
        }
        customerKeywords(isPrimary: true) {
          nodes {
            id
            name
          }
        }
        customerQuestionnaire {
          id
          serviceArea
        }
      }
      order {
        id
        instructions
        partnerProduct {
          id
          product {
            id
            name
          }
        }
        orderGbpItem {
          id
          isProfilePublished
          gbpItem {
            id
            content
            thumbnailFile {
              id
              name
              url
            }
            readMoreUrl
          }
        }
      }
    }
  }
`;

/**
 * __usePublishGbpTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `usePublishGbpTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishGbpTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishGbpTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function usePublishGbpTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PublishGbpTaskWorkspaceQuery, PublishGbpTaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PublishGbpTaskWorkspaceQuery, PublishGbpTaskWorkspaceQueryVariables>(
    PublishGbpTaskWorkspaceDocument,
    options
  );
}
export function usePublishGbpTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PublishGbpTaskWorkspaceQuery,
    PublishGbpTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PublishGbpTaskWorkspaceQuery, PublishGbpTaskWorkspaceQueryVariables>(
    PublishGbpTaskWorkspaceDocument,
    options
  );
}
export type PublishGbpTaskWorkspaceQueryHookResult = ReturnType<typeof usePublishGbpTaskWorkspaceQuery>;
export type PublishGbpTaskWorkspaceLazyQueryHookResult = ReturnType<typeof usePublishGbpTaskWorkspaceLazyQuery>;
export type PublishGbpTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  PublishGbpTaskWorkspaceQuery,
  PublishGbpTaskWorkspaceQueryVariables
>;
export const UpdatePublishPostDocument = gql`
  mutation UpdatePublishPost($input: UpdateOrderContentItemInput!) {
    updateOrderContentItem(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type UpdatePublishPostMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePublishPostMutation,
  UpdatePublishPostMutationVariables
>;

/**
 * __useUpdatePublishPostMutation__
 *
 * To run a mutation, you first call `useUpdatePublishPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublishPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublishPostMutation, { data, loading, error }] = useUpdatePublishPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublishPostMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePublishPostMutation, UpdatePublishPostMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePublishPostMutation, UpdatePublishPostMutationVariables>(
    UpdatePublishPostDocument,
    options
  );
}
export type UpdatePublishPostMutationHookResult = ReturnType<typeof useUpdatePublishPostMutation>;
export type UpdatePublishPostMutationResult = ApolloReactCommon.MutationResult<UpdatePublishPostMutation>;
export type UpdatePublishPostMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePublishPostMutation,
  UpdatePublishPostMutationVariables
>;
export const PublishPostTaskWorkspaceDocument = gql`
  query PublishPostTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      id
      customer {
        id
        name
        customerSiteCredential {
          id
          adminUrl
          user
          password
          instructions
        }
      }
      order {
        id
        partnerProduct {
          id
          product {
            id
            name
          }
        }
      }
      publishPostOrders {
        id
        orderContentItems {
          id
          isPostPublished
          contentItemDelivery {
            publicUrl
          }
        }
      }
    }
  }
`;

/**
 * __usePublishPostTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `usePublishPostTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishPostTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishPostTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function usePublishPostTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PublishPostTaskWorkspaceQuery, PublishPostTaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PublishPostTaskWorkspaceQuery, PublishPostTaskWorkspaceQueryVariables>(
    PublishPostTaskWorkspaceDocument,
    options
  );
}
export function usePublishPostTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PublishPostTaskWorkspaceQuery,
    PublishPostTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PublishPostTaskWorkspaceQuery, PublishPostTaskWorkspaceQueryVariables>(
    PublishPostTaskWorkspaceDocument,
    options
  );
}
export type PublishPostTaskWorkspaceQueryHookResult = ReturnType<typeof usePublishPostTaskWorkspaceQuery>;
export type PublishPostTaskWorkspaceLazyQueryHookResult = ReturnType<typeof usePublishPostTaskWorkspaceLazyQuery>;
export type PublishPostTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  PublishPostTaskWorkspaceQuery,
  PublishPostTaskWorkspaceQueryVariables
>;
export const UpdatePublishTitleMetaDescriptionDocument = gql`
  mutation UpdatePublishTitleMetaDescription($input: UpdateOrderTitleMetaDescriptionItemInput!) {
    updateOrderTitleMetaDescriptionItem(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type UpdatePublishTitleMetaDescriptionMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePublishTitleMetaDescriptionMutation,
  UpdatePublishTitleMetaDescriptionMutationVariables
>;

/**
 * __useUpdatePublishTitleMetaDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdatePublishTitleMetaDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublishTitleMetaDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublishTitleMetaDescriptionMutation, { data, loading, error }] = useUpdatePublishTitleMetaDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublishTitleMetaDescriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePublishTitleMetaDescriptionMutation,
    UpdatePublishTitleMetaDescriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdatePublishTitleMetaDescriptionMutation,
    UpdatePublishTitleMetaDescriptionMutationVariables
  >(UpdatePublishTitleMetaDescriptionDocument, options);
}
export type UpdatePublishTitleMetaDescriptionMutationHookResult = ReturnType<
  typeof useUpdatePublishTitleMetaDescriptionMutation
>;
export type UpdatePublishTitleMetaDescriptionMutationResult =
  ApolloReactCommon.MutationResult<UpdatePublishTitleMetaDescriptionMutation>;
export type UpdatePublishTitleMetaDescriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePublishTitleMetaDescriptionMutation,
  UpdatePublishTitleMetaDescriptionMutationVariables
>;
export const PublishTitleMetaDescriptionTaskWorkspaceDocument = gql`
  query PublishTitleMetaDescriptionTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      id
      customer {
        id
        name
        website
      }
      order {
        id
        instructions
        partnerProduct {
          id
          product {
            id
            name
          }
        }
        orderTitleMetaDescriptionItem {
          id
          titleMetaDescriptionItem {
            id
            titleMetaDescriptions {
              id
              titleTag
              metaDescription
              url
            }
          }
        }
      }
    }
  }
`;

/**
 * __usePublishTitleMetaDescriptionTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `usePublishTitleMetaDescriptionTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishTitleMetaDescriptionTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishTitleMetaDescriptionTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function usePublishTitleMetaDescriptionTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PublishTitleMetaDescriptionTaskWorkspaceQuery,
    PublishTitleMetaDescriptionTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PublishTitleMetaDescriptionTaskWorkspaceQuery,
    PublishTitleMetaDescriptionTaskWorkspaceQueryVariables
  >(PublishTitleMetaDescriptionTaskWorkspaceDocument, options);
}
export function usePublishTitleMetaDescriptionTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PublishTitleMetaDescriptionTaskWorkspaceQuery,
    PublishTitleMetaDescriptionTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PublishTitleMetaDescriptionTaskWorkspaceQuery,
    PublishTitleMetaDescriptionTaskWorkspaceQueryVariables
  >(PublishTitleMetaDescriptionTaskWorkspaceDocument, options);
}
export type PublishTitleMetaDescriptionTaskWorkspaceQueryHookResult = ReturnType<
  typeof usePublishTitleMetaDescriptionTaskWorkspaceQuery
>;
export type PublishTitleMetaDescriptionTaskWorkspaceLazyQueryHookResult = ReturnType<
  typeof usePublishTitleMetaDescriptionTaskWorkspaceLazyQuery
>;
export type PublishTitleMetaDescriptionTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  PublishTitleMetaDescriptionTaskWorkspaceQuery,
  PublishTitleMetaDescriptionTaskWorkspaceQueryVariables
>;
export const UpdatePublishWebCopyPostDocument = gql`
  mutation UpdatePublishWebCopyPost($input: UpdateOrderContentItemInput!) {
    updateOrderContentItem(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type UpdatePublishWebCopyPostMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePublishWebCopyPostMutation,
  UpdatePublishWebCopyPostMutationVariables
>;

/**
 * __useUpdatePublishWebCopyPostMutation__
 *
 * To run a mutation, you first call `useUpdatePublishWebCopyPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublishWebCopyPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublishWebCopyPostMutation, { data, loading, error }] = useUpdatePublishWebCopyPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublishWebCopyPostMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePublishWebCopyPostMutation,
    UpdatePublishWebCopyPostMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePublishWebCopyPostMutation, UpdatePublishWebCopyPostMutationVariables>(
    UpdatePublishWebCopyPostDocument,
    options
  );
}
export type UpdatePublishWebCopyPostMutationHookResult = ReturnType<typeof useUpdatePublishWebCopyPostMutation>;
export type UpdatePublishWebCopyPostMutationResult = ApolloReactCommon.MutationResult<UpdatePublishWebCopyPostMutation>;
export type UpdatePublishWebCopyPostMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePublishWebCopyPostMutation,
  UpdatePublishWebCopyPostMutationVariables
>;
export const PublishWebPostTaskWorkspaceDocument = gql`
  query PublishWebPostTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      id
      customer {
        id
        name
      }
      order {
        id
        orderContentItems {
          id
          isPostPublished
          contentItemDelivery {
            publicUrl
          }
        }
      }
    }
  }
`;

/**
 * __usePublishWebPostTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `usePublishWebPostTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishWebPostTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishWebPostTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function usePublishWebPostTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PublishWebPostTaskWorkspaceQuery,
    PublishWebPostTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PublishWebPostTaskWorkspaceQuery, PublishWebPostTaskWorkspaceQueryVariables>(
    PublishWebPostTaskWorkspaceDocument,
    options
  );
}
export function usePublishWebPostTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PublishWebPostTaskWorkspaceQuery,
    PublishWebPostTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PublishWebPostTaskWorkspaceQuery, PublishWebPostTaskWorkspaceQueryVariables>(
    PublishWebPostTaskWorkspaceDocument,
    options
  );
}
export type PublishWebPostTaskWorkspaceQueryHookResult = ReturnType<typeof usePublishWebPostTaskWorkspaceQuery>;
export type PublishWebPostTaskWorkspaceLazyQueryHookResult = ReturnType<typeof usePublishWebPostTaskWorkspaceLazyQuery>;
export type PublishWebPostTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  PublishWebPostTaskWorkspaceQuery,
  PublishWebPostTaskWorkspaceQueryVariables
>;
export const ReviewTitleMetaDescriptionTaskWorkspaceDocument = gql`
  query ReviewTitleMetaDescriptionTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      ...ReviewTitleMetaDescriptionTaskWorkspace_Task
    }
  }
  ${ReviewTitleMetaDescriptionTaskWorkspace_TaskFragmentDoc}
`;

/**
 * __useReviewTitleMetaDescriptionTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useReviewTitleMetaDescriptionTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewTitleMetaDescriptionTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewTitleMetaDescriptionTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useReviewTitleMetaDescriptionTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ReviewTitleMetaDescriptionTaskWorkspaceQuery,
    ReviewTitleMetaDescriptionTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ReviewTitleMetaDescriptionTaskWorkspaceQuery,
    ReviewTitleMetaDescriptionTaskWorkspaceQueryVariables
  >(ReviewTitleMetaDescriptionTaskWorkspaceDocument, options);
}
export function useReviewTitleMetaDescriptionTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ReviewTitleMetaDescriptionTaskWorkspaceQuery,
    ReviewTitleMetaDescriptionTaskWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ReviewTitleMetaDescriptionTaskWorkspaceQuery,
    ReviewTitleMetaDescriptionTaskWorkspaceQueryVariables
  >(ReviewTitleMetaDescriptionTaskWorkspaceDocument, options);
}
export type ReviewTitleMetaDescriptionTaskWorkspaceQueryHookResult = ReturnType<
  typeof useReviewTitleMetaDescriptionTaskWorkspaceQuery
>;
export type ReviewTitleMetaDescriptionTaskWorkspaceLazyQueryHookResult = ReturnType<
  typeof useReviewTitleMetaDescriptionTaskWorkspaceLazyQuery
>;
export type ReviewTitleMetaDescriptionTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  ReviewTitleMetaDescriptionTaskWorkspaceQuery,
  ReviewTitleMetaDescriptionTaskWorkspaceQueryVariables
>;
export const SaveTaskTitleMetaDescriptionDocument = gql`
  mutation SaveTaskTitleMetaDescription($input: SaveTaskTitleMetaDescriptionInput!) {
    saveTaskTitleMetaDescriptions(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          message
          fieldName
        }
      }
    }
  }
`;
export type SaveTaskTitleMetaDescriptionMutationFn = ApolloReactCommon.MutationFunction<
  SaveTaskTitleMetaDescriptionMutation,
  SaveTaskTitleMetaDescriptionMutationVariables
>;

/**
 * __useSaveTaskTitleMetaDescriptionMutation__
 *
 * To run a mutation, you first call `useSaveTaskTitleMetaDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTaskTitleMetaDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTaskTitleMetaDescriptionMutation, { data, loading, error }] = useSaveTaskTitleMetaDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTaskTitleMetaDescriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveTaskTitleMetaDescriptionMutation,
    SaveTaskTitleMetaDescriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SaveTaskTitleMetaDescriptionMutation,
    SaveTaskTitleMetaDescriptionMutationVariables
  >(SaveTaskTitleMetaDescriptionDocument, options);
}
export type SaveTaskTitleMetaDescriptionMutationHookResult = ReturnType<typeof useSaveTaskTitleMetaDescriptionMutation>;
export type SaveTaskTitleMetaDescriptionMutationResult =
  ApolloReactCommon.MutationResult<SaveTaskTitleMetaDescriptionMutation>;
export type SaveTaskTitleMetaDescriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveTaskTitleMetaDescriptionMutation,
  SaveTaskTitleMetaDescriptionMutationVariables
>;
export const SiteAuditTaskWorkspace_CreateCustomerSiteAuditDocument = gql`
  mutation SiteAuditTaskWorkspace_CreateCustomerSiteAudit($input: CreateCustomerSiteAuditInput!) {
    createCustomerSiteAudit(input: $input) {
      ok
      error {
        message
      }
      customerSiteAudit {
        id
      }
    }
  }
`;
export type SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutationFn = ApolloReactCommon.MutationFunction<
  SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutation,
  SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutationVariables
>;

/**
 * __useSiteAuditTaskWorkspace_CreateCustomerSiteAuditMutation__
 *
 * To run a mutation, you first call `useSiteAuditTaskWorkspace_CreateCustomerSiteAuditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteAuditTaskWorkspace_CreateCustomerSiteAuditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteAuditTaskWorkspaceCreateCustomerSiteAuditMutation, { data, loading, error }] = useSiteAuditTaskWorkspace_CreateCustomerSiteAuditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSiteAuditTaskWorkspace_CreateCustomerSiteAuditMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutation,
    SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutation,
    SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutationVariables
  >(SiteAuditTaskWorkspace_CreateCustomerSiteAuditDocument, options);
}
export type SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutationHookResult = ReturnType<
  typeof useSiteAuditTaskWorkspace_CreateCustomerSiteAuditMutation
>;
export type SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutationResult =
  ApolloReactCommon.MutationResult<SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutation>;
export type SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutation,
  SiteAuditTaskWorkspace_CreateCustomerSiteAuditMutationVariables
>;
export const SiteAuditTaskWorkspace_GenerateUrlForFileUploadDocument = gql`
  mutation SiteAuditTaskWorkspace_GenerateUrlForFileUpload($input: GenerateUrlForFileUploadInput!) {
    generateUrlForFileUpload(input: $input) {
      fileId
      url
    }
  }
`;
export type SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutationFn = ApolloReactCommon.MutationFunction<
  SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutation,
  SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutationVariables
>;

/**
 * __useSiteAuditTaskWorkspace_GenerateUrlForFileUploadMutation__
 *
 * To run a mutation, you first call `useSiteAuditTaskWorkspace_GenerateUrlForFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSiteAuditTaskWorkspace_GenerateUrlForFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [siteAuditTaskWorkspaceGenerateUrlForFileUploadMutation, { data, loading, error }] = useSiteAuditTaskWorkspace_GenerateUrlForFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSiteAuditTaskWorkspace_GenerateUrlForFileUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutation,
    SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutation,
    SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutationVariables
  >(SiteAuditTaskWorkspace_GenerateUrlForFileUploadDocument, options);
}
export type SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutationHookResult = ReturnType<
  typeof useSiteAuditTaskWorkspace_GenerateUrlForFileUploadMutation
>;
export type SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutationResult =
  ApolloReactCommon.MutationResult<SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutation>;
export type SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutation,
  SiteAuditTaskWorkspace_GenerateUrlForFileUploadMutationVariables
>;
export const SiteAuditTaskWorkspaceDocument = gql`
  query SiteAuditTaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      ...SiteAuditTaskWorkspace_Task
    }
  }
  ${SiteAuditTaskWorkspace_TaskFragmentDoc}
`;

/**
 * __useSiteAuditTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useSiteAuditTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteAuditTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteAuditTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useSiteAuditTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SiteAuditTaskWorkspaceQuery, SiteAuditTaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SiteAuditTaskWorkspaceQuery, SiteAuditTaskWorkspaceQueryVariables>(
    SiteAuditTaskWorkspaceDocument,
    options
  );
}
export function useSiteAuditTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SiteAuditTaskWorkspaceQuery, SiteAuditTaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SiteAuditTaskWorkspaceQuery, SiteAuditTaskWorkspaceQueryVariables>(
    SiteAuditTaskWorkspaceDocument,
    options
  );
}
export type SiteAuditTaskWorkspaceQueryHookResult = ReturnType<typeof useSiteAuditTaskWorkspaceQuery>;
export type SiteAuditTaskWorkspaceLazyQueryHookResult = ReturnType<typeof useSiteAuditTaskWorkspaceLazyQuery>;
export type SiteAuditTaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<
  SiteAuditTaskWorkspaceQuery,
  SiteAuditTaskWorkspaceQueryVariables
>;
export const UberallCategorySelectorDocument = gql`
  query UberallCategorySelector(
    $categories: String
    $language: String!
    $max: Int
    $offset: Int
    $q: String
    $roots: Boolean
  ) {
    uberallCategories(categories: $categories, language: $language, max: $max, offset: $offset, q: $q, roots: $roots) {
      id
      fullName
      selectable
    }
  }
`;

/**
 * __useUberallCategorySelectorQuery__
 *
 * To run a query within a React component, call `useUberallCategorySelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useUberallCategorySelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUberallCategorySelectorQuery({
 *   variables: {
 *      categories: // value for 'categories'
 *      language: // value for 'language'
 *      max: // value for 'max'
 *      offset: // value for 'offset'
 *      q: // value for 'q'
 *      roots: // value for 'roots'
 *   },
 * });
 */
export function useUberallCategorySelectorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<UberallCategorySelectorQuery, UberallCategorySelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UberallCategorySelectorQuery, UberallCategorySelectorQueryVariables>(
    UberallCategorySelectorDocument,
    options
  );
}
export function useUberallCategorySelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UberallCategorySelectorQuery,
    UberallCategorySelectorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UberallCategorySelectorQuery, UberallCategorySelectorQueryVariables>(
    UberallCategorySelectorDocument,
    options
  );
}
export type UberallCategorySelectorQueryHookResult = ReturnType<typeof useUberallCategorySelectorQuery>;
export type UberallCategorySelectorLazyQueryHookResult = ReturnType<typeof useUberallCategorySelectorLazyQuery>;
export type UberallCategorySelectorQueryResult = ApolloReactCommon.QueryResult<
  UberallCategorySelectorQuery,
  UberallCategorySelectorQueryVariables
>;
export const UploadFileDialog_GenerateUrlForFileUploadDocument = gql`
  mutation UploadFileDialog_GenerateUrlForFileUpload($input: GenerateUrlForFileUploadInput!) {
    generateUrlForFileUpload(input: $input) {
      url
      fileId
      url
    }
  }
`;
export type UploadFileDialog_GenerateUrlForFileUploadMutationFn = ApolloReactCommon.MutationFunction<
  UploadFileDialog_GenerateUrlForFileUploadMutation,
  UploadFileDialog_GenerateUrlForFileUploadMutationVariables
>;

/**
 * __useUploadFileDialog_GenerateUrlForFileUploadMutation__
 *
 * To run a mutation, you first call `useUploadFileDialog_GenerateUrlForFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileDialog_GenerateUrlForFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileDialogGenerateUrlForFileUploadMutation, { data, loading, error }] = useUploadFileDialog_GenerateUrlForFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFileDialog_GenerateUrlForFileUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadFileDialog_GenerateUrlForFileUploadMutation,
    UploadFileDialog_GenerateUrlForFileUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UploadFileDialog_GenerateUrlForFileUploadMutation,
    UploadFileDialog_GenerateUrlForFileUploadMutationVariables
  >(UploadFileDialog_GenerateUrlForFileUploadDocument, options);
}
export type UploadFileDialog_GenerateUrlForFileUploadMutationHookResult = ReturnType<
  typeof useUploadFileDialog_GenerateUrlForFileUploadMutation
>;
export type UploadFileDialog_GenerateUrlForFileUploadMutationResult =
  ApolloReactCommon.MutationResult<UploadFileDialog_GenerateUrlForFileUploadMutation>;
export type UploadFileDialog_GenerateUrlForFileUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UploadFileDialog_GenerateUrlForFileUploadMutation,
  UploadFileDialog_GenerateUrlForFileUploadMutationVariables
>;
export const AccessiblePartnerCountDocument = gql`
  query AccessiblePartnerCount {
    partners {
      totalCount
    }
  }
`;

/**
 * __useAccessiblePartnerCountQuery__
 *
 * To run a query within a React component, call `useAccessiblePartnerCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccessiblePartnerCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccessiblePartnerCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccessiblePartnerCountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AccessiblePartnerCountQuery, AccessiblePartnerCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AccessiblePartnerCountQuery, AccessiblePartnerCountQueryVariables>(
    AccessiblePartnerCountDocument,
    options
  );
}
export function useAccessiblePartnerCountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccessiblePartnerCountQuery, AccessiblePartnerCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AccessiblePartnerCountQuery, AccessiblePartnerCountQueryVariables>(
    AccessiblePartnerCountDocument,
    options
  );
}
export type AccessiblePartnerCountQueryHookResult = ReturnType<typeof useAccessiblePartnerCountQuery>;
export type AccessiblePartnerCountLazyQueryHookResult = ReturnType<typeof useAccessiblePartnerCountLazyQuery>;
export type AccessiblePartnerCountQueryResult = ApolloReactCommon.QueryResult<
  AccessiblePartnerCountQuery,
  AccessiblePartnerCountQueryVariables
>;
export const TrackShowEventDocument = gql`
  mutation TrackShowEvent($input: CreateUserInteractionEventInput!) {
    createUserInteractionEvent(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type TrackShowEventMutationFn = ApolloReactCommon.MutationFunction<
  TrackShowEventMutation,
  TrackShowEventMutationVariables
>;

/**
 * __useTrackShowEventMutation__
 *
 * To run a mutation, you first call `useTrackShowEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackShowEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackShowEventMutation, { data, loading, error }] = useTrackShowEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackShowEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<TrackShowEventMutation, TrackShowEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<TrackShowEventMutation, TrackShowEventMutationVariables>(
    TrackShowEventDocument,
    options
  );
}
export type TrackShowEventMutationHookResult = ReturnType<typeof useTrackShowEventMutation>;
export type TrackShowEventMutationResult = ApolloReactCommon.MutationResult<TrackShowEventMutation>;
export type TrackShowEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TrackShowEventMutation,
  TrackShowEventMutationVariables
>;
export const TrackUserEventDocument = gql`
  mutation TrackUserEvent($input: CreateUserInteractionEventInput!) {
    createUserInteractionEvent(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type TrackUserEventMutationFn = ApolloReactCommon.MutationFunction<
  TrackUserEventMutation,
  TrackUserEventMutationVariables
>;

/**
 * __useTrackUserEventMutation__
 *
 * To run a mutation, you first call `useTrackUserEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackUserEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackUserEventMutation, { data, loading, error }] = useTrackUserEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTrackUserEventMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<TrackUserEventMutation, TrackUserEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<TrackUserEventMutation, TrackUserEventMutationVariables>(
    TrackUserEventDocument,
    options
  );
}
export type TrackUserEventMutationHookResult = ReturnType<typeof useTrackUserEventMutation>;
export type TrackUserEventMutationResult = ApolloReactCommon.MutationResult<TrackUserEventMutation>;
export type TrackUserEventMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TrackUserEventMutation,
  TrackUserEventMutationVariables
>;
export const UserAutoCompleteDocument = gql`
  query UserAutoComplete($offset: Int!, $limit: Int!, $search: String, $isAdminOrSuperAdmin: Boolean) {
    users(offset: $offset, limit: $limit, search: $search, isAdminOrSuperAdmin: $isAdminOrSuperAdmin) {
      nodes {
        id
        email
        fullName
      }
    }
  }
`;

/**
 * __useUserAutoCompleteQuery__
 *
 * To run a query within a React component, call `useUserAutoCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAutoCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAutoCompleteQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      isAdminOrSuperAdmin: // value for 'isAdminOrSuperAdmin'
 *   },
 * });
 */
export function useUserAutoCompleteQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<UserAutoCompleteQuery, UserAutoCompleteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserAutoCompleteQuery, UserAutoCompleteQueryVariables>(
    UserAutoCompleteDocument,
    options
  );
}
export function useUserAutoCompleteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserAutoCompleteQuery, UserAutoCompleteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserAutoCompleteQuery, UserAutoCompleteQueryVariables>(
    UserAutoCompleteDocument,
    options
  );
}
export type UserAutoCompleteQueryHookResult = ReturnType<typeof useUserAutoCompleteQuery>;
export type UserAutoCompleteLazyQueryHookResult = ReturnType<typeof useUserAutoCompleteLazyQuery>;
export type UserAutoCompleteQueryResult = ApolloReactCommon.QueryResult<
  UserAutoCompleteQuery,
  UserAutoCompleteQueryVariables
>;
export const UserSelectorV2Document = gql`
  query UserSelectorV2($isAdminOrSuperAdmin: Boolean) {
    users(isAdminOrSuperAdmin: $isAdminOrSuperAdmin) {
      nodes {
        id
        fullName
      }
    }
  }
`;

/**
 * __useUserSelectorV2Query__
 *
 * To run a query within a React component, call `useUserSelectorV2Query` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectorV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectorV2Query({
 *   variables: {
 *      isAdminOrSuperAdmin: // value for 'isAdminOrSuperAdmin'
 *   },
 * });
 */
export function useUserSelectorV2Query(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserSelectorV2Query, UserSelectorV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserSelectorV2Query, UserSelectorV2QueryVariables>(UserSelectorV2Document, options);
}
export function useUserSelectorV2LazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSelectorV2Query, UserSelectorV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserSelectorV2Query, UserSelectorV2QueryVariables>(
    UserSelectorV2Document,
    options
  );
}
export type UserSelectorV2QueryHookResult = ReturnType<typeof useUserSelectorV2Query>;
export type UserSelectorV2LazyQueryHookResult = ReturnType<typeof useUserSelectorV2LazyQuery>;
export type UserSelectorV2QueryResult = ApolloReactCommon.QueryResult<
  UserSelectorV2Query,
  UserSelectorV2QueryVariables
>;
export const UserSelectorDocument = gql`
  query UserSelector($isAdminOrSuperAdmin: Boolean) {
    users(isAdminOrSuperAdmin: $isAdminOrSuperAdmin) {
      nodes {
        id
        fullName
      }
    }
  }
`;

/**
 * __useUserSelectorQuery__
 *
 * To run a query within a React component, call `useUserSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectorQuery({
 *   variables: {
 *      isAdminOrSuperAdmin: // value for 'isAdminOrSuperAdmin'
 *   },
 * });
 */
export function useUserSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserSelectorQuery, UserSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserSelectorQuery, UserSelectorQueryVariables>(UserSelectorDocument, options);
}
export function useUserSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserSelectorQuery, UserSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserSelectorQuery, UserSelectorQueryVariables>(UserSelectorDocument, options);
}
export type UserSelectorQueryHookResult = ReturnType<typeof useUserSelectorQuery>;
export type UserSelectorLazyQueryHookResult = ReturnType<typeof useUserSelectorLazyQuery>;
export type UserSelectorQueryResult = ApolloReactCommon.QueryResult<UserSelectorQuery, UserSelectorQueryVariables>;
export const UserTaskPermissionsDrawer_CreatePermissionDocument = gql`
  mutation UserTaskPermissionsDrawer_CreatePermission($input: CreateUserTaskPermissionInput!) {
    createUserTaskPermission(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type UserTaskPermissionsDrawer_CreatePermissionMutationFn = ApolloReactCommon.MutationFunction<
  UserTaskPermissionsDrawer_CreatePermissionMutation,
  UserTaskPermissionsDrawer_CreatePermissionMutationVariables
>;

/**
 * __useUserTaskPermissionsDrawer_CreatePermissionMutation__
 *
 * To run a mutation, you first call `useUserTaskPermissionsDrawer_CreatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserTaskPermissionsDrawer_CreatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userTaskPermissionsDrawerCreatePermissionMutation, { data, loading, error }] = useUserTaskPermissionsDrawer_CreatePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserTaskPermissionsDrawer_CreatePermissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserTaskPermissionsDrawer_CreatePermissionMutation,
    UserTaskPermissionsDrawer_CreatePermissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UserTaskPermissionsDrawer_CreatePermissionMutation,
    UserTaskPermissionsDrawer_CreatePermissionMutationVariables
  >(UserTaskPermissionsDrawer_CreatePermissionDocument, options);
}
export type UserTaskPermissionsDrawer_CreatePermissionMutationHookResult = ReturnType<
  typeof useUserTaskPermissionsDrawer_CreatePermissionMutation
>;
export type UserTaskPermissionsDrawer_CreatePermissionMutationResult =
  ApolloReactCommon.MutationResult<UserTaskPermissionsDrawer_CreatePermissionMutation>;
export type UserTaskPermissionsDrawer_CreatePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserTaskPermissionsDrawer_CreatePermissionMutation,
  UserTaskPermissionsDrawer_CreatePermissionMutationVariables
>;
export const UserTaskPermissionsDrawer_DeletePermissionDocument = gql`
  mutation UserTaskPermissionsDrawer_DeletePermission($input: DeleteUserTaskPermissionInput!) {
    deleteUserTaskPermission(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type UserTaskPermissionsDrawer_DeletePermissionMutationFn = ApolloReactCommon.MutationFunction<
  UserTaskPermissionsDrawer_DeletePermissionMutation,
  UserTaskPermissionsDrawer_DeletePermissionMutationVariables
>;

/**
 * __useUserTaskPermissionsDrawer_DeletePermissionMutation__
 *
 * To run a mutation, you first call `useUserTaskPermissionsDrawer_DeletePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserTaskPermissionsDrawer_DeletePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userTaskPermissionsDrawerDeletePermissionMutation, { data, loading, error }] = useUserTaskPermissionsDrawer_DeletePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserTaskPermissionsDrawer_DeletePermissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserTaskPermissionsDrawer_DeletePermissionMutation,
    UserTaskPermissionsDrawer_DeletePermissionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UserTaskPermissionsDrawer_DeletePermissionMutation,
    UserTaskPermissionsDrawer_DeletePermissionMutationVariables
  >(UserTaskPermissionsDrawer_DeletePermissionDocument, options);
}
export type UserTaskPermissionsDrawer_DeletePermissionMutationHookResult = ReturnType<
  typeof useUserTaskPermissionsDrawer_DeletePermissionMutation
>;
export type UserTaskPermissionsDrawer_DeletePermissionMutationResult =
  ApolloReactCommon.MutationResult<UserTaskPermissionsDrawer_DeletePermissionMutation>;
export type UserTaskPermissionsDrawer_DeletePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UserTaskPermissionsDrawer_DeletePermissionMutation,
  UserTaskPermissionsDrawer_DeletePermissionMutationVariables
>;
export const UserTaskPermissionsDrawer_ProductTaskTypesDocument = gql`
  query UserTaskPermissionsDrawer_ProductTaskTypes {
    productTaskTypes {
      totalCount
      nodes {
        ...UserTaskPermissionDrawer_ProductTaskType
      }
    }
  }
  ${UserTaskPermissionDrawer_ProductTaskTypeFragmentDoc}
`;

/**
 * __useUserTaskPermissionsDrawer_ProductTaskTypesQuery__
 *
 * To run a query within a React component, call `useUserTaskPermissionsDrawer_ProductTaskTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTaskPermissionsDrawer_ProductTaskTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTaskPermissionsDrawer_ProductTaskTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTaskPermissionsDrawer_ProductTaskTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserTaskPermissionsDrawer_ProductTaskTypesQuery,
    UserTaskPermissionsDrawer_ProductTaskTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserTaskPermissionsDrawer_ProductTaskTypesQuery,
    UserTaskPermissionsDrawer_ProductTaskTypesQueryVariables
  >(UserTaskPermissionsDrawer_ProductTaskTypesDocument, options);
}
export function useUserTaskPermissionsDrawer_ProductTaskTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserTaskPermissionsDrawer_ProductTaskTypesQuery,
    UserTaskPermissionsDrawer_ProductTaskTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserTaskPermissionsDrawer_ProductTaskTypesQuery,
    UserTaskPermissionsDrawer_ProductTaskTypesQueryVariables
  >(UserTaskPermissionsDrawer_ProductTaskTypesDocument, options);
}
export type UserTaskPermissionsDrawer_ProductTaskTypesQueryHookResult = ReturnType<
  typeof useUserTaskPermissionsDrawer_ProductTaskTypesQuery
>;
export type UserTaskPermissionsDrawer_ProductTaskTypesLazyQueryHookResult = ReturnType<
  typeof useUserTaskPermissionsDrawer_ProductTaskTypesLazyQuery
>;
export type UserTaskPermissionsDrawer_ProductTaskTypesQueryResult = ApolloReactCommon.QueryResult<
  UserTaskPermissionsDrawer_ProductTaskTypesQuery,
  UserTaskPermissionsDrawer_ProductTaskTypesQueryVariables
>;
export const UserTaskPermissionsDrawerDocument = gql`
  query UserTaskPermissionsDrawer($userId: Int!) {
    user(id: $userId) {
      id
      fullName
      email
      userTaskPermissions {
        id
        vendorId
        product {
          id
          name
        }
        taskType {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useUserTaskPermissionsDrawerQuery__
 *
 * To run a query within a React component, call `useUserTaskPermissionsDrawerQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTaskPermissionsDrawerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTaskPermissionsDrawerQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserTaskPermissionsDrawerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    UserTaskPermissionsDrawerQuery,
    UserTaskPermissionsDrawerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserTaskPermissionsDrawerQuery, UserTaskPermissionsDrawerQueryVariables>(
    UserTaskPermissionsDrawerDocument,
    options
  );
}
export function useUserTaskPermissionsDrawerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserTaskPermissionsDrawerQuery,
    UserTaskPermissionsDrawerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserTaskPermissionsDrawerQuery, UserTaskPermissionsDrawerQueryVariables>(
    UserTaskPermissionsDrawerDocument,
    options
  );
}
export type UserTaskPermissionsDrawerQueryHookResult = ReturnType<typeof useUserTaskPermissionsDrawerQuery>;
export type UserTaskPermissionsDrawerLazyQueryHookResult = ReturnType<typeof useUserTaskPermissionsDrawerLazyQuery>;
export type UserTaskPermissionsDrawerQueryResult = ApolloReactCommon.QueryResult<
  UserTaskPermissionsDrawerQuery,
  UserTaskPermissionsDrawerQueryVariables
>;
export const VendorsForSelectorDocument = gql`
  query VendorsForSelector($search: String, $partnerId: Int, $ids: [Int!], $showExternalVendors: Boolean) {
    vendors(search: $search, partnerId: $partnerId, ids: $ids, showExternalVendors: $showExternalVendors) {
      nodes {
        id
        name
        partner {
          id
          name
        }
        isExternal
      }
    }
  }
`;

/**
 * __useVendorsForSelectorQuery__
 *
 * To run a query within a React component, call `useVendorsForSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsForSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsForSelectorQuery({
 *   variables: {
 *      search: // value for 'search'
 *      partnerId: // value for 'partnerId'
 *      ids: // value for 'ids'
 *      showExternalVendors: // value for 'showExternalVendors'
 *   },
 * });
 */
export function useVendorsForSelectorQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<VendorsForSelectorQuery, VendorsForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<VendorsForSelectorQuery, VendorsForSelectorQueryVariables>(
    VendorsForSelectorDocument,
    options
  );
}
export function useVendorsForSelectorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VendorsForSelectorQuery, VendorsForSelectorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<VendorsForSelectorQuery, VendorsForSelectorQueryVariables>(
    VendorsForSelectorDocument,
    options
  );
}
export type VendorsForSelectorQueryHookResult = ReturnType<typeof useVendorsForSelectorQuery>;
export type VendorsForSelectorLazyQueryHookResult = ReturnType<typeof useVendorsForSelectorLazyQuery>;
export type VendorsForSelectorQueryResult = ApolloReactCommon.QueryResult<
  VendorsForSelectorQuery,
  VendorsForSelectorQueryVariables
>;
export const CreateWorkflowNoteDocument = gql`
  mutation CreateWorkflowNote($input: CreateWorkflowNoteInput!) {
    createWorkflowNote(input: $input) {
      ok
      note {
        id
      }
      error {
        message
      }
    }
  }
`;
export type CreateWorkflowNoteMutationFn = ApolloReactCommon.MutationFunction<
  CreateWorkflowNoteMutation,
  CreateWorkflowNoteMutationVariables
>;

/**
 * __useCreateWorkflowNoteMutation__
 *
 * To run a mutation, you first call `useCreateWorkflowNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkflowNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkflowNoteMutation, { data, loading, error }] = useCreateWorkflowNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkflowNoteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateWorkflowNoteMutation, CreateWorkflowNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateWorkflowNoteMutation, CreateWorkflowNoteMutationVariables>(
    CreateWorkflowNoteDocument,
    options
  );
}
export type CreateWorkflowNoteMutationHookResult = ReturnType<typeof useCreateWorkflowNoteMutation>;
export type CreateWorkflowNoteMutationResult = ApolloReactCommon.MutationResult<CreateWorkflowNoteMutation>;
export type CreateWorkflowNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateWorkflowNoteMutation,
  CreateWorkflowNoteMutationVariables
>;
export const WorkflowNotesDocument = gql`
  query WorkflowNotes($taskId: Int!) {
    task(taskId: $taskId) {
      id
      workflow {
        id
        notes {
          totalCount
          nodes {
            id
            content
            createdAt
            user {
              id
              fullName
            }
          }
        }
      }
    }
  }
`;

/**
 * __useWorkflowNotesQuery__
 *
 * To run a query within a React component, call `useWorkflowNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowNotesQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useWorkflowNotesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<WorkflowNotesQuery, WorkflowNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<WorkflowNotesQuery, WorkflowNotesQueryVariables>(WorkflowNotesDocument, options);
}
export function useWorkflowNotesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkflowNotesQuery, WorkflowNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<WorkflowNotesQuery, WorkflowNotesQueryVariables>(WorkflowNotesDocument, options);
}
export type WorkflowNotesQueryHookResult = ReturnType<typeof useWorkflowNotesQuery>;
export type WorkflowNotesLazyQueryHookResult = ReturnType<typeof useWorkflowNotesLazyQuery>;
export type WorkflowNotesQueryResult = ApolloReactCommon.QueryResult<WorkflowNotesQuery, WorkflowNotesQueryVariables>;
export const AuthManagerDocument = gql`
  query AuthManager {
    viewer {
      ...AuthManager_Viewer
    }
  }
  ${AuthManager_ViewerFragmentDoc}
`;

/**
 * __useAuthManagerQuery__
 *
 * To run a query within a React component, call `useAuthManagerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthManagerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthManagerQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthManagerQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AuthManagerQuery, AuthManagerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AuthManagerQuery, AuthManagerQueryVariables>(AuthManagerDocument, options);
}
export function useAuthManagerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuthManagerQuery, AuthManagerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AuthManagerQuery, AuthManagerQueryVariables>(AuthManagerDocument, options);
}
export type AuthManagerQueryHookResult = ReturnType<typeof useAuthManagerQuery>;
export type AuthManagerLazyQueryHookResult = ReturnType<typeof useAuthManagerLazyQuery>;
export type AuthManagerQueryResult = ApolloReactCommon.QueryResult<AuthManagerQuery, AuthManagerQueryVariables>;
export const LogoutUserDocument = gql`
  mutation LogoutUser {
    logoutUser {
      isLoggedOut
    }
  }
`;
export type LogoutUserMutationFn = ApolloReactCommon.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, options);
}
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = ApolloReactCommon.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LogoutUserMutation,
  LogoutUserMutationVariables
>;
export const AdminBillingPartnerDocument = gql`
  query AdminBillingPartner($partnerId: Int!, $startDate: DateQuery) {
    partner(id: $partnerId) {
      id
      name
      billableOrders(startDate: $startDate) {
        nodes {
          id
          customer {
            id
            name
          }
          partnerProduct {
            id
            product {
              id
              name
            }
          }
          startDate
          totalWholesaleAmount
        }
      }
    }
  }
`;

/**
 * __useAdminBillingPartnerQuery__
 *
 * To run a query within a React component, call `useAdminBillingPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminBillingPartnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBillingPartnerQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useAdminBillingPartnerQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminBillingPartnerQuery, AdminBillingPartnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminBillingPartnerQuery, AdminBillingPartnerQueryVariables>(
    AdminBillingPartnerDocument,
    options
  );
}
export function useAdminBillingPartnerLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminBillingPartnerQuery, AdminBillingPartnerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminBillingPartnerQuery, AdminBillingPartnerQueryVariables>(
    AdminBillingPartnerDocument,
    options
  );
}
export type AdminBillingPartnerQueryHookResult = ReturnType<typeof useAdminBillingPartnerQuery>;
export type AdminBillingPartnerLazyQueryHookResult = ReturnType<typeof useAdminBillingPartnerLazyQuery>;
export type AdminBillingPartnerQueryResult = ApolloReactCommon.QueryResult<
  AdminBillingPartnerQuery,
  AdminBillingPartnerQueryVariables
>;
export const CreatePartnerInvoiceDocument = gql`
  mutation createPartnerInvoice($input: CreatePartnerInvoiceInput!) {
    createPartnerInvoice(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
      partnerInvoice {
        id
      }
    }
  }
`;
export type CreatePartnerInvoiceMutationFn = ApolloReactCommon.MutationFunction<
  CreatePartnerInvoiceMutation,
  CreatePartnerInvoiceMutationVariables
>;

/**
 * __useCreatePartnerInvoiceMutation__
 *
 * To run a mutation, you first call `useCreatePartnerInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerInvoiceMutation, { data, loading, error }] = useCreatePartnerInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartnerInvoiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePartnerInvoiceMutation,
    CreatePartnerInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreatePartnerInvoiceMutation, CreatePartnerInvoiceMutationVariables>(
    CreatePartnerInvoiceDocument,
    options
  );
}
export type CreatePartnerInvoiceMutationHookResult = ReturnType<typeof useCreatePartnerInvoiceMutation>;
export type CreatePartnerInvoiceMutationResult = ApolloReactCommon.MutationResult<CreatePartnerInvoiceMutation>;
export type CreatePartnerInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePartnerInvoiceMutation,
  CreatePartnerInvoiceMutationVariables
>;
export const AdminBillingCreateInvoicePartnersDocument = gql`
  query AdminBillingCreateInvoicePartners {
    partners {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useAdminBillingCreateInvoicePartnersQuery__
 *
 * To run a query within a React component, call `useAdminBillingCreateInvoicePartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminBillingCreateInvoicePartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBillingCreateInvoicePartnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminBillingCreateInvoicePartnersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminBillingCreateInvoicePartnersQuery,
    AdminBillingCreateInvoicePartnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AdminBillingCreateInvoicePartnersQuery,
    AdminBillingCreateInvoicePartnersQueryVariables
  >(AdminBillingCreateInvoicePartnersDocument, options);
}
export function useAdminBillingCreateInvoicePartnersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminBillingCreateInvoicePartnersQuery,
    AdminBillingCreateInvoicePartnersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminBillingCreateInvoicePartnersQuery,
    AdminBillingCreateInvoicePartnersQueryVariables
  >(AdminBillingCreateInvoicePartnersDocument, options);
}
export type AdminBillingCreateInvoicePartnersQueryHookResult = ReturnType<
  typeof useAdminBillingCreateInvoicePartnersQuery
>;
export type AdminBillingCreateInvoicePartnersLazyQueryHookResult = ReturnType<
  typeof useAdminBillingCreateInvoicePartnersLazyQuery
>;
export type AdminBillingCreateInvoicePartnersQueryResult = ApolloReactCommon.QueryResult<
  AdminBillingCreateInvoicePartnersQuery,
  AdminBillingCreateInvoicePartnersQueryVariables
>;
export const BillingOrdersReviewDocument = gql`
  query BillingOrdersReview($statusIds: [String!], $partnerId: Int, $startDate: DateQuery, $limit: Int) {
    orders(statusIds: $statusIds, partnerId: $partnerId, startDate: $startDate, limit: $limit) {
      totalCount
      nodes {
        ...BillingOrdersReview_Order
      }
    }
  }
  ${BillingOrdersReview_OrderFragmentDoc}
`;

/**
 * __useBillingOrdersReviewQuery__
 *
 * To run a query within a React component, call `useBillingOrdersReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingOrdersReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingOrdersReviewQuery({
 *   variables: {
 *      statusIds: // value for 'statusIds'
 *      partnerId: // value for 'partnerId'
 *      startDate: // value for 'startDate'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBillingOrdersReviewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<BillingOrdersReviewQuery, BillingOrdersReviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BillingOrdersReviewQuery, BillingOrdersReviewQueryVariables>(
    BillingOrdersReviewDocument,
    options
  );
}
export function useBillingOrdersReviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BillingOrdersReviewQuery, BillingOrdersReviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BillingOrdersReviewQuery, BillingOrdersReviewQueryVariables>(
    BillingOrdersReviewDocument,
    options
  );
}
export type BillingOrdersReviewQueryHookResult = ReturnType<typeof useBillingOrdersReviewQuery>;
export type BillingOrdersReviewLazyQueryHookResult = ReturnType<typeof useBillingOrdersReviewLazyQuery>;
export type BillingOrdersReviewQueryResult = ApolloReactCommon.QueryResult<
  BillingOrdersReviewQuery,
  BillingOrdersReviewQueryVariables
>;
export const AdminBillingDocument = gql`
  query AdminBilling($startDate: DateQuery) {
    partners(isBillingEnforced: true) {
      nodes {
        id
        name
        billableOrders(startDate: $startDate) {
          nodes {
            id
            partnerProduct {
              id
              product {
                id
                name
              }
            }
            startDate
            totalWholesaleAmount
          }
        }
      }
    }
  }
`;

/**
 * __useAdminBillingQuery__
 *
 * To run a query within a React component, call `useAdminBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminBillingQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useAdminBillingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AdminBillingQuery, AdminBillingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminBillingQuery, AdminBillingQueryVariables>(AdminBillingDocument, options);
}
export function useAdminBillingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminBillingQuery, AdminBillingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminBillingQuery, AdminBillingQueryVariables>(AdminBillingDocument, options);
}
export type AdminBillingQueryHookResult = ReturnType<typeof useAdminBillingQuery>;
export type AdminBillingLazyQueryHookResult = ReturnType<typeof useAdminBillingLazyQuery>;
export type AdminBillingQueryResult = ApolloReactCommon.QueryResult<AdminBillingQuery, AdminBillingQueryVariables>;
export const BlockDomainsDocument = gql`
  query BlockDomains($limit: Int, $offset: Int, $customerId: Int!) {
    blockDomains(limit: $limit, offset: $offset, customerId: $customerId) {
      totalCount
      nodes {
        id
        domain
      }
    }
  }
`;

/**
 * __useBlockDomainsQuery__
 *
 * To run a query within a React component, call `useBlockDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockDomainsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useBlockDomainsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<BlockDomainsQuery, BlockDomainsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BlockDomainsQuery, BlockDomainsQueryVariables>(BlockDomainsDocument, options);
}
export function useBlockDomainsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BlockDomainsQuery, BlockDomainsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BlockDomainsQuery, BlockDomainsQueryVariables>(BlockDomainsDocument, options);
}
export type BlockDomainsQueryHookResult = ReturnType<typeof useBlockDomainsQuery>;
export type BlockDomainsLazyQueryHookResult = ReturnType<typeof useBlockDomainsLazyQuery>;
export type BlockDomainsQueryResult = ApolloReactCommon.QueryResult<BlockDomainsQuery, BlockDomainsQueryVariables>;
export const CreateBlockDomainCustomersDocument = gql`
  mutation CreateBlockDomainCustomers($input: CreateBlockDomainCustomersInput!) {
    createBlockDomainCustomers(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type CreateBlockDomainCustomersMutationFn = ApolloReactCommon.MutationFunction<
  CreateBlockDomainCustomersMutation,
  CreateBlockDomainCustomersMutationVariables
>;

/**
 * __useCreateBlockDomainCustomersMutation__
 *
 * To run a mutation, you first call `useCreateBlockDomainCustomersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlockDomainCustomersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockDomainCustomersMutation, { data, loading, error }] = useCreateBlockDomainCustomersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBlockDomainCustomersMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateBlockDomainCustomersMutation,
    CreateBlockDomainCustomersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateBlockDomainCustomersMutation, CreateBlockDomainCustomersMutationVariables>(
    CreateBlockDomainCustomersDocument,
    options
  );
}
export type CreateBlockDomainCustomersMutationHookResult = ReturnType<typeof useCreateBlockDomainCustomersMutation>;
export type CreateBlockDomainCustomersMutationResult =
  ApolloReactCommon.MutationResult<CreateBlockDomainCustomersMutation>;
export type CreateBlockDomainCustomersMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateBlockDomainCustomersMutation,
  CreateBlockDomainCustomersMutationVariables
>;
export const CategoriesDocument = gql`
  query Categories($limit: Int!, $offset: Int!, $search: String, $includeInactive: Boolean) {
    categories(limit: $limit, offset: $offset, search: $search, includeInactive: $includeInactive) {
      totalCount
      nodes {
        id
        name
        isActive
      }
    }
  }
`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      includeInactive: // value for 'includeInactive'
 *   },
 * });
 */
export function useCategoriesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
}
export function useCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
}
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = ApolloReactCommon.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const AdminKeywordsDocument = gql`
  query AdminKeywords($limit: Int, $offset: Int, $search: String) {
    customerKeywords(limit: $limit, offset: $offset, search: $search, locationNotNull: true) {
      totalCount
      nodes {
        id
        name
        location
        country
        instructions
        customerId
      }
    }
  }
`;

/**
 * __useAdminKeywordsQuery__
 *
 * To run a query within a React component, call `useAdminKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminKeywordsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAdminKeywordsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AdminKeywordsQuery, AdminKeywordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminKeywordsQuery, AdminKeywordsQueryVariables>(AdminKeywordsDocument, options);
}
export function useAdminKeywordsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminKeywordsQuery, AdminKeywordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminKeywordsQuery, AdminKeywordsQueryVariables>(AdminKeywordsDocument, options);
}
export type AdminKeywordsQueryHookResult = ReturnType<typeof useAdminKeywordsQuery>;
export type AdminKeywordsLazyQueryHookResult = ReturnType<typeof useAdminKeywordsLazyQuery>;
export type AdminKeywordsQueryResult = ApolloReactCommon.QueryResult<AdminKeywordsQuery, AdminKeywordsQueryVariables>;
export const AdminUpdateCustomerKeywordDocument = gql`
  mutation AdminUpdateCustomerKeyword($input: UpdateCustomerKeywordInput!) {
    updateCustomerKeyword(input: $input) {
      customerKeyword {
        id
      }
    }
  }
`;
export type AdminUpdateCustomerKeywordMutationFn = ApolloReactCommon.MutationFunction<
  AdminUpdateCustomerKeywordMutation,
  AdminUpdateCustomerKeywordMutationVariables
>;

/**
 * __useAdminUpdateCustomerKeywordMutation__
 *
 * To run a mutation, you first call `useAdminUpdateCustomerKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateCustomerKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateCustomerKeywordMutation, { data, loading, error }] = useAdminUpdateCustomerKeywordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateCustomerKeywordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminUpdateCustomerKeywordMutation,
    AdminUpdateCustomerKeywordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AdminUpdateCustomerKeywordMutation, AdminUpdateCustomerKeywordMutationVariables>(
    AdminUpdateCustomerKeywordDocument,
    options
  );
}
export type AdminUpdateCustomerKeywordMutationHookResult = ReturnType<typeof useAdminUpdateCustomerKeywordMutation>;
export type AdminUpdateCustomerKeywordMutationResult =
  ApolloReactCommon.MutationResult<AdminUpdateCustomerKeywordMutation>;
export type AdminUpdateCustomerKeywordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminUpdateCustomerKeywordMutation,
  AdminUpdateCustomerKeywordMutationVariables
>;
export const CreateDomainCategoryDocument = gql`
  mutation CreateDomainCategory($input: CreateDomainCategoryInput!) {
    createDomainCategory(input: $input) {
      domainCategory {
        id
      }
    }
  }
`;
export type CreateDomainCategoryMutationFn = ApolloReactCommon.MutationFunction<
  CreateDomainCategoryMutation,
  CreateDomainCategoryMutationVariables
>;

/**
 * __useCreateDomainCategoryMutation__
 *
 * To run a mutation, you first call `useCreateDomainCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDomainCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDomainCategoryMutation, { data, loading, error }] = useCreateDomainCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDomainCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateDomainCategoryMutation,
    CreateDomainCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateDomainCategoryMutation, CreateDomainCategoryMutationVariables>(
    CreateDomainCategoryDocument,
    options
  );
}
export type CreateDomainCategoryMutationHookResult = ReturnType<typeof useCreateDomainCategoryMutation>;
export type CreateDomainCategoryMutationResult = ApolloReactCommon.MutationResult<CreateDomainCategoryMutation>;
export type CreateDomainCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDomainCategoryMutation,
  CreateDomainCategoryMutationVariables
>;
export const DeleteDomainCategoryDocument = gql`
  mutation DeleteDomainCategory($input: DeleteDomainCategoryInput!) {
    deleteDomainCategory(input: $input) {
      isDeleted
    }
  }
`;
export type DeleteDomainCategoryMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDomainCategoryMutation,
  DeleteDomainCategoryMutationVariables
>;

/**
 * __useDeleteDomainCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteDomainCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDomainCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDomainCategoryMutation, { data, loading, error }] = useDeleteDomainCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDomainCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDomainCategoryMutation,
    DeleteDomainCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteDomainCategoryMutation, DeleteDomainCategoryMutationVariables>(
    DeleteDomainCategoryDocument,
    options
  );
}
export type DeleteDomainCategoryMutationHookResult = ReturnType<typeof useDeleteDomainCategoryMutation>;
export type DeleteDomainCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteDomainCategoryMutation>;
export type DeleteDomainCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDomainCategoryMutation,
  DeleteDomainCategoryMutationVariables
>;
export const DomainDetailsDocument = gql`
  query DomainDetails($id: String!) {
    domain(id: $id) {
      id
      createdAt
      status {
        id
        name
      }
      availabilityLastCheckedAt
      expiresAt
      purchasedAt
      registrar
      registrarId
      purchaseRequestedByUser {
        id
        fullName
      }
      domainCategories {
        totalCount
        nodes {
          id
          category {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useDomainDetailsQuery__
 *
 * To run a query within a React component, call `useDomainDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDomainDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<DomainDetailsQuery, DomainDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DomainDetailsQuery, DomainDetailsQueryVariables>(DomainDetailsDocument, options);
}
export function useDomainDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DomainDetailsQuery, DomainDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DomainDetailsQuery, DomainDetailsQueryVariables>(DomainDetailsDocument, options);
}
export type DomainDetailsQueryHookResult = ReturnType<typeof useDomainDetailsQuery>;
export type DomainDetailsLazyQueryHookResult = ReturnType<typeof useDomainDetailsLazyQuery>;
export type DomainDetailsQueryResult = ApolloReactCommon.QueryResult<DomainDetailsQuery, DomainDetailsQueryVariables>;
export const CreateSiteDocument = gql`
  mutation CreateSite($input: CreateSiteInput!) {
    createSite(input: $input) {
      site {
        id
        domainId
      }
    }
  }
`;
export type CreateSiteMutationFn = ApolloReactCommon.MutationFunction<CreateSiteMutation, CreateSiteMutationVariables>;

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteMutation, CreateSiteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, options);
}
export type CreateSiteMutationHookResult = ReturnType<typeof useCreateSiteMutation>;
export type CreateSiteMutationResult = ApolloReactCommon.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSiteMutation,
  CreateSiteMutationVariables
>;
export const DomainsDocument = gql`
  query Domains($limit: Int!, $offset: Int!, $search: String, $statusIds: [String!], $categoryIds: [Int!]) {
    domains(limit: $limit, offset: $offset, search: $search, statusIds: $statusIds, categoryIds: $categoryIds) {
      totalCount
      nodes {
        ...DomainTable
      }
    }
  }
  ${DomainTableFragmentDoc}
`;

/**
 * __useDomainsQuery__
 *
 * To run a query within a React component, call `useDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      statusIds: // value for 'statusIds'
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useDomainsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<DomainsQuery, DomainsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DomainsQuery, DomainsQueryVariables>(DomainsDocument, options);
}
export function useDomainsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DomainsQuery, DomainsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DomainsQuery, DomainsQueryVariables>(DomainsDocument, options);
}
export type DomainsQueryHookResult = ReturnType<typeof useDomainsQuery>;
export type DomainsLazyQueryHookResult = ReturnType<typeof useDomainsLazyQuery>;
export type DomainsQueryResult = ApolloReactCommon.QueryResult<DomainsQuery, DomainsQueryVariables>;
export const PurchaseDomainDocument = gql`
  mutation PurchaseDomain($input: PurchaseDomainInput!) {
    purchaseDomain(input: $input) {
      domain {
        id
      }
    }
  }
`;
export type PurchaseDomainMutationFn = ApolloReactCommon.MutationFunction<
  PurchaseDomainMutation,
  PurchaseDomainMutationVariables
>;

/**
 * __usePurchaseDomainMutation__
 *
 * To run a mutation, you first call `usePurchaseDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseDomainMutation, { data, loading, error }] = usePurchaseDomainMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseDomainMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<PurchaseDomainMutation, PurchaseDomainMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<PurchaseDomainMutation, PurchaseDomainMutationVariables>(
    PurchaseDomainDocument,
    options
  );
}
export type PurchaseDomainMutationHookResult = ReturnType<typeof usePurchaseDomainMutation>;
export type PurchaseDomainMutationResult = ApolloReactCommon.MutationResult<PurchaseDomainMutation>;
export type PurchaseDomainMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PurchaseDomainMutation,
  PurchaseDomainMutationVariables
>;
export const RefreshDomainAvailabilityDocument = gql`
  mutation RefreshDomainAvailability($input: RefreshDomainAvailabilityInput!) {
    refreshDomainAvailability(input: $input) {
      ok
    }
  }
`;
export type RefreshDomainAvailabilityMutationFn = ApolloReactCommon.MutationFunction<
  RefreshDomainAvailabilityMutation,
  RefreshDomainAvailabilityMutationVariables
>;

/**
 * __useRefreshDomainAvailabilityMutation__
 *
 * To run a mutation, you first call `useRefreshDomainAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshDomainAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshDomainAvailabilityMutation, { data, loading, error }] = useRefreshDomainAvailabilityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshDomainAvailabilityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RefreshDomainAvailabilityMutation,
    RefreshDomainAvailabilityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RefreshDomainAvailabilityMutation, RefreshDomainAvailabilityMutationVariables>(
    RefreshDomainAvailabilityDocument,
    options
  );
}
export type RefreshDomainAvailabilityMutationHookResult = ReturnType<typeof useRefreshDomainAvailabilityMutation>;
export type RefreshDomainAvailabilityMutationResult =
  ApolloReactCommon.MutationResult<RefreshDomainAvailabilityMutation>;
export type RefreshDomainAvailabilityMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RefreshDomainAvailabilityMutation,
  RefreshDomainAvailabilityMutationVariables
>;
export const GetGoogleAccountsDocument = gql`
  query GetGoogleAccounts {
    getGoogleAccounts {
      accounts {
        name
        accountName
      }
    }
  }
`;

/**
 * __useGetGoogleAccountsQuery__
 *
 * To run a query within a React component, call `useGetGoogleAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGoogleAccountsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetGoogleAccountsQuery, GetGoogleAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetGoogleAccountsQuery, GetGoogleAccountsQueryVariables>(
    GetGoogleAccountsDocument,
    options
  );
}
export function useGetGoogleAccountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoogleAccountsQuery, GetGoogleAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetGoogleAccountsQuery, GetGoogleAccountsQueryVariables>(
    GetGoogleAccountsDocument,
    options
  );
}
export type GetGoogleAccountsQueryHookResult = ReturnType<typeof useGetGoogleAccountsQuery>;
export type GetGoogleAccountsLazyQueryHookResult = ReturnType<typeof useGetGoogleAccountsLazyQuery>;
export type GetGoogleAccountsQueryResult = ApolloReactCommon.QueryResult<
  GetGoogleAccountsQuery,
  GetGoogleAccountsQueryVariables
>;
export const GetGoogleLocationsDocument = gql`
  query GetGoogleLocations($account: String!) {
    getGoogleLocations(account: $account) {
      locations {
        name
        title
        storefrontAddress {
          addressLines
          locality
        }
      }
    }
  }
`;

/**
 * __useGetGoogleLocationsQuery__
 *
 * To run a query within a React component, call `useGetGoogleLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleLocationsQuery({
 *   variables: {
 *      account: // value for 'account'
 *   },
 * });
 */
export function useGetGoogleLocationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetGoogleLocationsQuery, GetGoogleLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetGoogleLocationsQuery, GetGoogleLocationsQueryVariables>(
    GetGoogleLocationsDocument,
    options
  );
}
export function useGetGoogleLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoogleLocationsQuery, GetGoogleLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetGoogleLocationsQuery, GetGoogleLocationsQueryVariables>(
    GetGoogleLocationsDocument,
    options
  );
}
export type GetGoogleLocationsQueryHookResult = ReturnType<typeof useGetGoogleLocationsQuery>;
export type GetGoogleLocationsLazyQueryHookResult = ReturnType<typeof useGetGoogleLocationsLazyQuery>;
export type GetGoogleLocationsQueryResult = ApolloReactCommon.QueryResult<
  GetGoogleLocationsQuery,
  GetGoogleLocationsQueryVariables
>;
export const GetGoogleReviewsDocument = gql`
  query GetGoogleReviews($account: String!, $location: String!, $pageToken: String) {
    getGoogleReviews(account: $account, location: $location, pageToken: $pageToken) {
      reviews {
        ...AdminLocationReview
      }
      nextPageToken
      totalReviewCount
      averageRating
    }
  }
  ${AdminLocationReviewFragmentDoc}
`;

/**
 * __useGetGoogleReviewsQuery__
 *
 * To run a query within a React component, call `useGetGoogleReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoogleReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoogleReviewsQuery({
 *   variables: {
 *      account: // value for 'account'
 *      location: // value for 'location'
 *      pageToken: // value for 'pageToken'
 *   },
 * });
 */
export function useGetGoogleReviewsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetGoogleReviewsQuery, GetGoogleReviewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetGoogleReviewsQuery, GetGoogleReviewsQueryVariables>(
    GetGoogleReviewsDocument,
    options
  );
}
export function useGetGoogleReviewsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoogleReviewsQuery, GetGoogleReviewsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetGoogleReviewsQuery, GetGoogleReviewsQueryVariables>(
    GetGoogleReviewsDocument,
    options
  );
}
export type GetGoogleReviewsQueryHookResult = ReturnType<typeof useGetGoogleReviewsQuery>;
export type GetGoogleReviewsLazyQueryHookResult = ReturnType<typeof useGetGoogleReviewsLazyQuery>;
export type GetGoogleReviewsQueryResult = ApolloReactCommon.QueryResult<
  GetGoogleReviewsQuery,
  GetGoogleReviewsQueryVariables
>;
export const GeminiReplyCommentDocument = gql`
  query GeminiReplyComment($comment: String!, $keywords: String!) {
    geminiReplyComment(comment: $comment, keywords: $keywords) {
      reply
    }
  }
`;

/**
 * __useGeminiReplyCommentQuery__
 *
 * To run a query within a React component, call `useGeminiReplyCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeminiReplyCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeminiReplyCommentQuery({
 *   variables: {
 *      comment: // value for 'comment'
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useGeminiReplyCommentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GeminiReplyCommentQuery, GeminiReplyCommentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GeminiReplyCommentQuery, GeminiReplyCommentQueryVariables>(
    GeminiReplyCommentDocument,
    options
  );
}
export function useGeminiReplyCommentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GeminiReplyCommentQuery, GeminiReplyCommentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GeminiReplyCommentQuery, GeminiReplyCommentQueryVariables>(
    GeminiReplyCommentDocument,
    options
  );
}
export type GeminiReplyCommentQueryHookResult = ReturnType<typeof useGeminiReplyCommentQuery>;
export type GeminiReplyCommentLazyQueryHookResult = ReturnType<typeof useGeminiReplyCommentLazyQuery>;
export type GeminiReplyCommentQueryResult = ApolloReactCommon.QueryResult<
  GeminiReplyCommentQuery,
  GeminiReplyCommentQueryVariables
>;
export const LoginGoogleDocument = gql`
  mutation LoginGoogle {
    loginGoogleService {
      ok
      url
    }
  }
`;
export type LoginGoogleMutationFn = ApolloReactCommon.MutationFunction<
  LoginGoogleMutation,
  LoginGoogleMutationVariables
>;

/**
 * __useLoginGoogleMutation__
 *
 * To run a mutation, you first call `useLoginGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginGoogleMutation, { data, loading, error }] = useLoginGoogleMutation({
 *   variables: {
 *   },
 * });
 */
export function useLoginGoogleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginGoogleMutation, LoginGoogleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginGoogleMutation, LoginGoogleMutationVariables>(LoginGoogleDocument, options);
}
export type LoginGoogleMutationHookResult = ReturnType<typeof useLoginGoogleMutation>;
export type LoginGoogleMutationResult = ApolloReactCommon.MutationResult<LoginGoogleMutation>;
export type LoginGoogleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginGoogleMutation,
  LoginGoogleMutationVariables
>;
export const LoginGoogleCallbackDocument = gql`
  mutation LoginGoogleCallback($input: LoginGoogleCallbackInput) {
    loginGoogleCallback(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type LoginGoogleCallbackMutationFn = ApolloReactCommon.MutationFunction<
  LoginGoogleCallbackMutation,
  LoginGoogleCallbackMutationVariables
>;

/**
 * __useLoginGoogleCallbackMutation__
 *
 * To run a mutation, you first call `useLoginGoogleCallbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginGoogleCallbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginGoogleCallbackMutation, { data, loading, error }] = useLoginGoogleCallbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginGoogleCallbackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginGoogleCallbackMutation, LoginGoogleCallbackMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginGoogleCallbackMutation, LoginGoogleCallbackMutationVariables>(
    LoginGoogleCallbackDocument,
    options
  );
}
export type LoginGoogleCallbackMutationHookResult = ReturnType<typeof useLoginGoogleCallbackMutation>;
export type LoginGoogleCallbackMutationResult = ApolloReactCommon.MutationResult<LoginGoogleCallbackMutation>;
export type LoginGoogleCallbackMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginGoogleCallbackMutation,
  LoginGoogleCallbackMutationVariables
>;
export const GoogleReviewReplyDocument = gql`
  mutation GoogleReviewReply($input: GoogleReviewReplyInput!) {
    googleReviewReply(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type GoogleReviewReplyMutationFn = ApolloReactCommon.MutationFunction<
  GoogleReviewReplyMutation,
  GoogleReviewReplyMutationVariables
>;

/**
 * __useGoogleReviewReplyMutation__
 *
 * To run a mutation, you first call `useGoogleReviewReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleReviewReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleReviewReplyMutation, { data, loading, error }] = useGoogleReviewReplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGoogleReviewReplyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<GoogleReviewReplyMutation, GoogleReviewReplyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<GoogleReviewReplyMutation, GoogleReviewReplyMutationVariables>(
    GoogleReviewReplyDocument,
    options
  );
}
export type GoogleReviewReplyMutationHookResult = ReturnType<typeof useGoogleReviewReplyMutation>;
export type GoogleReviewReplyMutationResult = ApolloReactCommon.MutationResult<GoogleReviewReplyMutation>;
export type GoogleReviewReplyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GoogleReviewReplyMutation,
  GoogleReviewReplyMutationVariables
>;
export const GuestArticleOrdersDocument = gql`
  query GuestArticleOrders(
    $productIds: [String!]
    $statusIds: [String!]
    $limit: Int!
    $offset: Int!
    $startDate: DateQuery
    $sort: OrdersSort
    $taskTypeIds: [String!]
    $tasksSort: TasksSort
  ) {
    orders(
      productIds: $productIds
      statusIds: $statusIds
      limit: $limit
      offset: $offset
      startDate: $startDate
      sort: $sort
    ) {
      totalCount
      nodes {
        id
        resultUrl
        startDate
        completedAt
        partnerProduct {
          id
          product {
            id
            name
          }
        }
        customer {
          id
          name
        }
        workflow {
          id
          tasks(taskTypeIds: $taskTypeIds, sort: $tasksSort) {
            nodes {
              id
              assignedUser {
                id
                fullName
              }
            }
          }
        }
        linkLocation {
          id
          domain
          domainAuthority
          authorityScore
          spam
          internalQuality
          monthlyTraffic
          cost
          publisherLinkLocation {
            id
            publisher {
              id
              name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGuestArticleOrdersQuery__
 *
 * To run a query within a React component, call `useGuestArticleOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuestArticleOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuestArticleOrdersQuery({
 *   variables: {
 *      productIds: // value for 'productIds'
 *      statusIds: // value for 'statusIds'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      startDate: // value for 'startDate'
 *      sort: // value for 'sort'
 *      taskTypeIds: // value for 'taskTypeIds'
 *      tasksSort: // value for 'tasksSort'
 *   },
 * });
 */
export function useGuestArticleOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GuestArticleOrdersQuery, GuestArticleOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GuestArticleOrdersQuery, GuestArticleOrdersQueryVariables>(
    GuestArticleOrdersDocument,
    options
  );
}
export function useGuestArticleOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GuestArticleOrdersQuery, GuestArticleOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GuestArticleOrdersQuery, GuestArticleOrdersQueryVariables>(
    GuestArticleOrdersDocument,
    options
  );
}
export type GuestArticleOrdersQueryHookResult = ReturnType<typeof useGuestArticleOrdersQuery>;
export type GuestArticleOrdersLazyQueryHookResult = ReturnType<typeof useGuestArticleOrdersLazyQuery>;
export type GuestArticleOrdersQueryResult = ApolloReactCommon.QueryResult<
  GuestArticleOrdersQuery,
  GuestArticleOrdersQueryVariables
>;
export const AdminPublishedContentDocument = gql`
  query AdminPublishedContent(
    $limit: Int
    $offset: Int
    $completedAt: DateQuery
    $includeDeleted: Boolean
    $productIds: [String!]
    $isPostPublished: Boolean
  ) {
    orders(
      limit: $limit
      offset: $offset
      completedAt: $completedAt
      productIds: $productIds
      isPostPublished: $isPostPublished
    ) {
      totalCount
      nodes {
        id
        resultUrl
        linkLocation {
          id
          publisherLinkLocation {
            id
            publisher {
              id
              name
            }
          }
        }
        orderContentItems(includeDeleted: $includeDeleted) {
          id
          isPostPublished
          lastVerifiedPostAt
        }
      }
    }
  }
`;

/**
 * __useAdminPublishedContentQuery__
 *
 * To run a query within a React component, call `useAdminPublishedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPublishedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPublishedContentQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      completedAt: // value for 'completedAt'
 *      includeDeleted: // value for 'includeDeleted'
 *      productIds: // value for 'productIds'
 *      isPostPublished: // value for 'isPostPublished'
 *   },
 * });
 */
export function useAdminPublishedContentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AdminPublishedContentQuery, AdminPublishedContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminPublishedContentQuery, AdminPublishedContentQueryVariables>(
    AdminPublishedContentDocument,
    options
  );
}
export function useAdminPublishedContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminPublishedContentQuery, AdminPublishedContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminPublishedContentQuery, AdminPublishedContentQueryVariables>(
    AdminPublishedContentDocument,
    options
  );
}
export type AdminPublishedContentQueryHookResult = ReturnType<typeof useAdminPublishedContentQuery>;
export type AdminPublishedContentLazyQueryHookResult = ReturnType<typeof useAdminPublishedContentLazyQuery>;
export type AdminPublishedContentQueryResult = ApolloReactCommon.QueryResult<
  AdminPublishedContentQuery,
  AdminPublishedContentQueryVariables
>;
export const VerifyGuestPostDocument = gql`
  mutation VerifyGuestPost($input: VerifyGuestPostInput!, $includeDeleted: Boolean!) {
    verifyGuestPost(input: $input) {
      ok
      error {
        message
      }
      orders {
        id
        resultUrl
        linkLocation {
          id
          publisherLinkLocation {
            id
            publisher {
              id
              name
            }
          }
        }
        orderContentItems(includeDeleted: $includeDeleted) {
          id
          isPostPublished
          lastVerifiedPostAt
        }
      }
    }
  }
`;
export type VerifyGuestPostMutationFn = ApolloReactCommon.MutationFunction<
  VerifyGuestPostMutation,
  VerifyGuestPostMutationVariables
>;

/**
 * __useVerifyGuestPostMutation__
 *
 * To run a mutation, you first call `useVerifyGuestPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyGuestPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyGuestPostMutation, { data, loading, error }] = useVerifyGuestPostMutation({
 *   variables: {
 *      input: // value for 'input'
 *      includeDeleted: // value for 'includeDeleted'
 *   },
 * });
 */
export function useVerifyGuestPostMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyGuestPostMutation, VerifyGuestPostMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<VerifyGuestPostMutation, VerifyGuestPostMutationVariables>(
    VerifyGuestPostDocument,
    options
  );
}
export type VerifyGuestPostMutationHookResult = ReturnType<typeof useVerifyGuestPostMutation>;
export type VerifyGuestPostMutationResult = ApolloReactCommon.MutationResult<VerifyGuestPostMutation>;
export type VerifyGuestPostMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VerifyGuestPostMutation,
  VerifyGuestPostMutationVariables
>;
export const AdminGuestArticleOutreachTasksDocument = gql`
  query AdminGuestArticleOutreachTasks(
    $limit: Int
    $offset: Int
    $sort: TasksSort
    $customerIds: [Int!]
    $taskTypeIds: [String!]
    $statusIds: [String!]
    $assignedUserIds: [Int!]
    $productIds: [String!]
    $search: String
    $subTasksSort: TasksSort
    $proposalTaskTypeIds: [String!]
    $publisherId: Int
  ) {
    tasks(
      limit: $limit
      offset: $offset
      sort: $sort
      customerIds: $customerIds
      taskTypeIds: $taskTypeIds
      statusIds: $statusIds
      assignedUserIds: $assignedUserIds
      productIds: $productIds
      search: $search
      publisherId: $publisherId
    ) {
      totalCount
      nodes {
        ...AdminGuestArticleOutreachTasks_Task
      }
    }
  }
  ${AdminGuestArticleOutreachTasks_TaskFragmentDoc}
`;

/**
 * __useAdminGuestArticleOutreachTasksQuery__
 *
 * To run a query within a React component, call `useAdminGuestArticleOutreachTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminGuestArticleOutreachTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminGuestArticleOutreachTasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      customerIds: // value for 'customerIds'
 *      taskTypeIds: // value for 'taskTypeIds'
 *      statusIds: // value for 'statusIds'
 *      assignedUserIds: // value for 'assignedUserIds'
 *      productIds: // value for 'productIds'
 *      search: // value for 'search'
 *      subTasksSort: // value for 'subTasksSort'
 *      proposalTaskTypeIds: // value for 'proposalTaskTypeIds'
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useAdminGuestArticleOutreachTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminGuestArticleOutreachTasksQuery,
    AdminGuestArticleOutreachTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminGuestArticleOutreachTasksQuery, AdminGuestArticleOutreachTasksQueryVariables>(
    AdminGuestArticleOutreachTasksDocument,
    options
  );
}
export function useAdminGuestArticleOutreachTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminGuestArticleOutreachTasksQuery,
    AdminGuestArticleOutreachTasksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminGuestArticleOutreachTasksQuery,
    AdminGuestArticleOutreachTasksQueryVariables
  >(AdminGuestArticleOutreachTasksDocument, options);
}
export type AdminGuestArticleOutreachTasksQueryHookResult = ReturnType<typeof useAdminGuestArticleOutreachTasksQuery>;
export type AdminGuestArticleOutreachTasksLazyQueryHookResult = ReturnType<
  typeof useAdminGuestArticleOutreachTasksLazyQuery
>;
export type AdminGuestArticleOutreachTasksQueryResult = ApolloReactCommon.QueryResult<
  AdminGuestArticleOutreachTasksQuery,
  AdminGuestArticleOutreachTasksQueryVariables
>;
export const GuestArticleTasksTable_UpdateTaskDocument = gql`
  mutation GuestArticleTasksTable_UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        id
        status {
          id
        }
        assignedUserId
      }
    }
  }
`;
export type GuestArticleTasksTable_UpdateTaskMutationFn = ApolloReactCommon.MutationFunction<
  GuestArticleTasksTable_UpdateTaskMutation,
  GuestArticleTasksTable_UpdateTaskMutationVariables
>;

/**
 * __useGuestArticleTasksTable_UpdateTaskMutation__
 *
 * To run a mutation, you first call `useGuestArticleTasksTable_UpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGuestArticleTasksTable_UpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [guestArticleTasksTableUpdateTaskMutation, { data, loading, error }] = useGuestArticleTasksTable_UpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGuestArticleTasksTable_UpdateTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GuestArticleTasksTable_UpdateTaskMutation,
    GuestArticleTasksTable_UpdateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GuestArticleTasksTable_UpdateTaskMutation,
    GuestArticleTasksTable_UpdateTaskMutationVariables
  >(GuestArticleTasksTable_UpdateTaskDocument, options);
}
export type GuestArticleTasksTable_UpdateTaskMutationHookResult = ReturnType<
  typeof useGuestArticleTasksTable_UpdateTaskMutation
>;
export type GuestArticleTasksTable_UpdateTaskMutationResult =
  ApolloReactCommon.MutationResult<GuestArticleTasksTable_UpdateTaskMutation>;
export type GuestArticleTasksTable_UpdateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GuestArticleTasksTable_UpdateTaskMutation,
  GuestArticleTasksTable_UpdateTaskMutationVariables
>;
export const Admin_ImportInvoicesDocument = gql`
  mutation Admin_ImportInvoices($input: ImportInvoicesInput!) {
    importInvoices(input: $input) {
      ok
      error {
        message
      }
      results {
        ok
        orderId
        message
      }
    }
  }
`;
export type Admin_ImportInvoicesMutationFn = ApolloReactCommon.MutationFunction<
  Admin_ImportInvoicesMutation,
  Admin_ImportInvoicesMutationVariables
>;

/**
 * __useAdmin_ImportInvoicesMutation__
 *
 * To run a mutation, you first call `useAdmin_ImportInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmin_ImportInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminImportInvoicesMutation, { data, loading, error }] = useAdmin_ImportInvoicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmin_ImportInvoicesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Admin_ImportInvoicesMutation,
    Admin_ImportInvoicesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<Admin_ImportInvoicesMutation, Admin_ImportInvoicesMutationVariables>(
    Admin_ImportInvoicesDocument,
    options
  );
}
export type Admin_ImportInvoicesMutationHookResult = ReturnType<typeof useAdmin_ImportInvoicesMutation>;
export type Admin_ImportInvoicesMutationResult = ApolloReactCommon.MutationResult<Admin_ImportInvoicesMutation>;
export type Admin_ImportInvoicesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Admin_ImportInvoicesMutation,
  Admin_ImportInvoicesMutationVariables
>;
export const AdminInvoicesDocument = gql`
  query AdminInvoices(
    $limit: Int
    $offset: Int
    $sort: PartnerInvoiceSort
    $partnerIds: [Int!]
    $statusIds: [String!]
    $search: String
  ) {
    partnerInvoices(
      limit: $limit
      offset: $offset
      sort: $sort
      partnerIds: $partnerIds
      statusIds: $statusIds
      search: $search
    ) {
      totalCount
      nodes {
        id
        status {
          id
          name
          description
        }
        totalDueAmount
        totalChargesAmount
        invoiceDate
        partner {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useAdminInvoicesQuery__
 *
 * To run a query within a React component, call `useAdminInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminInvoicesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      partnerIds: // value for 'partnerIds'
 *      statusIds: // value for 'statusIds'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAdminInvoicesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AdminInvoicesQuery, AdminInvoicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminInvoicesQuery, AdminInvoicesQueryVariables>(AdminInvoicesDocument, options);
}
export function useAdminInvoicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminInvoicesQuery, AdminInvoicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminInvoicesQuery, AdminInvoicesQueryVariables>(AdminInvoicesDocument, options);
}
export type AdminInvoicesQueryHookResult = ReturnType<typeof useAdminInvoicesQuery>;
export type AdminInvoicesLazyQueryHookResult = ReturnType<typeof useAdminInvoicesLazyQuery>;
export type AdminInvoicesQueryResult = ApolloReactCommon.QueryResult<AdminInvoicesQuery, AdminInvoicesQueryVariables>;
export const UpdateLinkLocationDetailsDocument = gql`
  mutation UpdateLinkLocationDetails(
    $input: UpdateLinkLocationInput!
    $categoryInput: UpdateLinkLocationCategoriesInput!
  ) {
    updateLinkLocation(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
    updateLinkLocationCategories(input: $categoryInput) {
      ok
      error {
        message
      }
    }
  }
`;
export type UpdateLinkLocationDetailsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLinkLocationDetailsMutation,
  UpdateLinkLocationDetailsMutationVariables
>;

/**
 * __useUpdateLinkLocationDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateLinkLocationDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkLocationDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkLocationDetailsMutation, { data, loading, error }] = useUpdateLinkLocationDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      categoryInput: // value for 'categoryInput'
 *   },
 * });
 */
export function useUpdateLinkLocationDetailsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLinkLocationDetailsMutation,
    UpdateLinkLocationDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateLinkLocationDetailsMutation, UpdateLinkLocationDetailsMutationVariables>(
    UpdateLinkLocationDetailsDocument,
    options
  );
}
export type UpdateLinkLocationDetailsMutationHookResult = ReturnType<typeof useUpdateLinkLocationDetailsMutation>;
export type UpdateLinkLocationDetailsMutationResult =
  ApolloReactCommon.MutationResult<UpdateLinkLocationDetailsMutation>;
export type UpdateLinkLocationDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLinkLocationDetailsMutation,
  UpdateLinkLocationDetailsMutationVariables
>;
export const UpdatePublisherLinkLocationDocument = gql`
  mutation UpdatePublisherLinkLocation($input: UpdatePublisherLinkLocationInput!) {
    updatePublisherLinkLocation(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type UpdatePublisherLinkLocationMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePublisherLinkLocationMutation,
  UpdatePublisherLinkLocationMutationVariables
>;

/**
 * __useUpdatePublisherLinkLocationMutation__
 *
 * To run a mutation, you first call `useUpdatePublisherLinkLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublisherLinkLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublisherLinkLocationMutation, { data, loading, error }] = useUpdatePublisherLinkLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublisherLinkLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePublisherLinkLocationMutation,
    UpdatePublisherLinkLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdatePublisherLinkLocationMutation,
    UpdatePublisherLinkLocationMutationVariables
  >(UpdatePublisherLinkLocationDocument, options);
}
export type UpdatePublisherLinkLocationMutationHookResult = ReturnType<typeof useUpdatePublisherLinkLocationMutation>;
export type UpdatePublisherLinkLocationMutationResult =
  ApolloReactCommon.MutationResult<UpdatePublisherLinkLocationMutation>;
export type UpdatePublisherLinkLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePublisherLinkLocationMutation,
  UpdatePublisherLinkLocationMutationVariables
>;
export const AdminUpdateLinkLocationMetricsDocument = gql`
  mutation AdminUpdateLinkLocationMetrics($input: UpdateLinkLocationMetricsInput!) {
    updateLinkLocationMetrics(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type AdminUpdateLinkLocationMetricsMutationFn = ApolloReactCommon.MutationFunction<
  AdminUpdateLinkLocationMetricsMutation,
  AdminUpdateLinkLocationMetricsMutationVariables
>;

/**
 * __useAdminUpdateLinkLocationMetricsMutation__
 *
 * To run a mutation, you first call `useAdminUpdateLinkLocationMetricsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdateLinkLocationMetricsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdateLinkLocationMetricsMutation, { data, loading, error }] = useAdminUpdateLinkLocationMetricsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdateLinkLocationMetricsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminUpdateLinkLocationMetricsMutation,
    AdminUpdateLinkLocationMetricsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminUpdateLinkLocationMetricsMutation,
    AdminUpdateLinkLocationMetricsMutationVariables
  >(AdminUpdateLinkLocationMetricsDocument, options);
}
export type AdminUpdateLinkLocationMetricsMutationHookResult = ReturnType<
  typeof useAdminUpdateLinkLocationMetricsMutation
>;
export type AdminUpdateLinkLocationMetricsMutationResult =
  ApolloReactCommon.MutationResult<AdminUpdateLinkLocationMetricsMutation>;
export type AdminUpdateLinkLocationMetricsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminUpdateLinkLocationMetricsMutation,
  AdminUpdateLinkLocationMetricsMutationVariables
>;
export const LinkLocationDetailsDocument = gql`
  query LinkLocationDetails($id: Int!) {
    linkLocation(id: $id) {
      id
      domain
      domainAuthority
      notes
      minimumWordCount
      spam
      authorityScore
      internalQuality
      monthlyTraffic
      cost
      contactName
      contactEmail
      allowsOnlyOneLinkInContent
      allowsCbdContent
      allowsWeapons
      allowsAIContent
      autoAssign
      statusId
      typeId
      createdAt
      linkLocationCategories {
        id
        categoryId
      }
      publisherLinkLocation {
        id
        isRCMPublishing
        publisher {
          id
          name
        }
      }
      linkLocationCredential {
        id
        adminUrl
        userName
        password
      }
      lastMetricsUpdatedAt
    }
  }
`;

/**
 * __useLinkLocationDetailsQuery__
 *
 * To run a query within a React component, call `useLinkLocationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkLocationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkLocationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLinkLocationDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<LinkLocationDetailsQuery, LinkLocationDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LinkLocationDetailsQuery, LinkLocationDetailsQueryVariables>(
    LinkLocationDetailsDocument,
    options
  );
}
export function useLinkLocationDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LinkLocationDetailsQuery, LinkLocationDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LinkLocationDetailsQuery, LinkLocationDetailsQueryVariables>(
    LinkLocationDetailsDocument,
    options
  );
}
export type LinkLocationDetailsQueryHookResult = ReturnType<typeof useLinkLocationDetailsQuery>;
export type LinkLocationDetailsLazyQueryHookResult = ReturnType<typeof useLinkLocationDetailsLazyQuery>;
export type LinkLocationDetailsQueryResult = ApolloReactCommon.QueryResult<
  LinkLocationDetailsQuery,
  LinkLocationDetailsQueryVariables
>;
export const UpdateLinkLocationCategoriesDocument = gql`
  mutation UpdateLinkLocationCategories($input: UpdateLinkLocationCategoriesInput!) {
    updateLinkLocationCategories(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type UpdateLinkLocationCategoriesMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLinkLocationCategoriesMutation,
  UpdateLinkLocationCategoriesMutationVariables
>;

/**
 * __useUpdateLinkLocationCategoriesMutation__
 *
 * To run a mutation, you first call `useUpdateLinkLocationCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLinkLocationCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLinkLocationCategoriesMutation, { data, loading, error }] = useUpdateLinkLocationCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLinkLocationCategoriesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLinkLocationCategoriesMutation,
    UpdateLinkLocationCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateLinkLocationCategoriesMutation,
    UpdateLinkLocationCategoriesMutationVariables
  >(UpdateLinkLocationCategoriesDocument, options);
}
export type UpdateLinkLocationCategoriesMutationHookResult = ReturnType<typeof useUpdateLinkLocationCategoriesMutation>;
export type UpdateLinkLocationCategoriesMutationResult =
  ApolloReactCommon.MutationResult<UpdateLinkLocationCategoriesMutation>;
export type UpdateLinkLocationCategoriesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLinkLocationCategoriesMutation,
  UpdateLinkLocationCategoriesMutationVariables
>;
export const Locations_UpdateLinkLocationDocument = gql`
  mutation Locations_UpdateLinkLocation($input: UpdateLinkLocationInput!) {
    updateLinkLocation(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type Locations_UpdateLinkLocationMutationFn = ApolloReactCommon.MutationFunction<
  Locations_UpdateLinkLocationMutation,
  Locations_UpdateLinkLocationMutationVariables
>;

/**
 * __useLocations_UpdateLinkLocationMutation__
 *
 * To run a mutation, you first call `useLocations_UpdateLinkLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocations_UpdateLinkLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationsUpdateLinkLocationMutation, { data, loading, error }] = useLocations_UpdateLinkLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLocations_UpdateLinkLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Locations_UpdateLinkLocationMutation,
    Locations_UpdateLinkLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Locations_UpdateLinkLocationMutation,
    Locations_UpdateLinkLocationMutationVariables
  >(Locations_UpdateLinkLocationDocument, options);
}
export type Locations_UpdateLinkLocationMutationHookResult = ReturnType<typeof useLocations_UpdateLinkLocationMutation>;
export type Locations_UpdateLinkLocationMutationResult =
  ApolloReactCommon.MutationResult<Locations_UpdateLinkLocationMutation>;
export type Locations_UpdateLinkLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Locations_UpdateLinkLocationMutation,
  Locations_UpdateLinkLocationMutationVariables
>;
export const LinkLocationsDocument = gql`
  query LinkLocations(
    $limit: Int
    $offset: Int
    $search: String
    $statusIds: [String!]
    $typeIds: [String!]
    $categoryIds: [Int!]
  ) {
    linkLocations(
      limit: $limit
      offset: $offset
      search: $search
      statusIds: $statusIds
      typeIds: $typeIds
      categoryIds: $categoryIds
    ) {
      totalCount
      nodes {
        id
        domain
        domainAuthority
        authorityScore
        internalQuality
        monthlyTraffic
        autoAssign
        createdAt
        linkLocationCategories {
          categoryId
        }
        status {
          name
        }
        type {
          name
        }
        publisherLinkLocation {
          id
          publisher {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useLinkLocationsQuery__
 *
 * To run a query within a React component, call `useLinkLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkLocationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      statusIds: // value for 'statusIds'
 *      typeIds: // value for 'typeIds'
 *      categoryIds: // value for 'categoryIds'
 *   },
 * });
 */
export function useLinkLocationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LinkLocationsQuery, LinkLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LinkLocationsQuery, LinkLocationsQueryVariables>(LinkLocationsDocument, options);
}
export function useLinkLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LinkLocationsQuery, LinkLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LinkLocationsQuery, LinkLocationsQueryVariables>(LinkLocationsDocument, options);
}
export type LinkLocationsQueryHookResult = ReturnType<typeof useLinkLocationsQuery>;
export type LinkLocationsLazyQueryHookResult = ReturnType<typeof useLinkLocationsLazyQuery>;
export type LinkLocationsQueryResult = ApolloReactCommon.QueryResult<LinkLocationsQuery, LinkLocationsQueryVariables>;
export const PartnerDetailsSecondaryNavDocument = gql`
  query PartnerDetailsSecondaryNav($partnerId: Int!) {
    partner(id: $partnerId) {
      id
      name
    }
  }
`;

/**
 * __usePartnerDetailsSecondaryNavQuery__
 *
 * To run a query within a React component, call `usePartnerDetailsSecondaryNavQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerDetailsSecondaryNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerDetailsSecondaryNavQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function usePartnerDetailsSecondaryNavQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PartnerDetailsSecondaryNavQuery,
    PartnerDetailsSecondaryNavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerDetailsSecondaryNavQuery, PartnerDetailsSecondaryNavQueryVariables>(
    PartnerDetailsSecondaryNavDocument,
    options
  );
}
export function usePartnerDetailsSecondaryNavLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PartnerDetailsSecondaryNavQuery,
    PartnerDetailsSecondaryNavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerDetailsSecondaryNavQuery, PartnerDetailsSecondaryNavQueryVariables>(
    PartnerDetailsSecondaryNavDocument,
    options
  );
}
export type PartnerDetailsSecondaryNavQueryHookResult = ReturnType<typeof usePartnerDetailsSecondaryNavQuery>;
export type PartnerDetailsSecondaryNavLazyQueryHookResult = ReturnType<typeof usePartnerDetailsSecondaryNavLazyQuery>;
export type PartnerDetailsSecondaryNavQueryResult = ApolloReactCommon.QueryResult<
  PartnerDetailsSecondaryNavQuery,
  PartnerDetailsSecondaryNavQueryVariables
>;
export const AdminPartnerOverviewDocument = gql`
  query AdminPartnerOverview($id: Int!) {
    partner(id: $id) {
      id
      name
      billingEmail
      isUpfrontPaymentRequired
      isBillingEnforced
      stripeAccountId
      portal
      reportingLogoFile {
        id
        name
        url
      }
      emailLogoFile {
        id
        name
        url
      }
      partnerAnalyticCredential {
        id
        userName
        password
      }
    }
  }
`;

/**
 * __useAdminPartnerOverviewQuery__
 *
 * To run a query within a React component, call `useAdminPartnerOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPartnerOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPartnerOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminPartnerOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminPartnerOverviewQuery, AdminPartnerOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminPartnerOverviewQuery, AdminPartnerOverviewQueryVariables>(
    AdminPartnerOverviewDocument,
    options
  );
}
export function useAdminPartnerOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminPartnerOverviewQuery, AdminPartnerOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminPartnerOverviewQuery, AdminPartnerOverviewQueryVariables>(
    AdminPartnerOverviewDocument,
    options
  );
}
export type AdminPartnerOverviewQueryHookResult = ReturnType<typeof useAdminPartnerOverviewQuery>;
export type AdminPartnerOverviewLazyQueryHookResult = ReturnType<typeof useAdminPartnerOverviewLazyQuery>;
export type AdminPartnerOverviewQueryResult = ApolloReactCommon.QueryResult<
  AdminPartnerOverviewQuery,
  AdminPartnerOverviewQueryVariables
>;
export const SavePartnerAnalyticCredentialDocument = gql`
  mutation SavePartnerAnalyticCredential($input: SavePartnerAnalyticCredentialInput!) {
    savePartnerAnalyticCredential(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type SavePartnerAnalyticCredentialMutationFn = ApolloReactCommon.MutationFunction<
  SavePartnerAnalyticCredentialMutation,
  SavePartnerAnalyticCredentialMutationVariables
>;

/**
 * __useSavePartnerAnalyticCredentialMutation__
 *
 * To run a mutation, you first call `useSavePartnerAnalyticCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePartnerAnalyticCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePartnerAnalyticCredentialMutation, { data, loading, error }] = useSavePartnerAnalyticCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSavePartnerAnalyticCredentialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SavePartnerAnalyticCredentialMutation,
    SavePartnerAnalyticCredentialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SavePartnerAnalyticCredentialMutation,
    SavePartnerAnalyticCredentialMutationVariables
  >(SavePartnerAnalyticCredentialDocument, options);
}
export type SavePartnerAnalyticCredentialMutationHookResult = ReturnType<
  typeof useSavePartnerAnalyticCredentialMutation
>;
export type SavePartnerAnalyticCredentialMutationResult =
  ApolloReactCommon.MutationResult<SavePartnerAnalyticCredentialMutation>;
export type SavePartnerAnalyticCredentialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SavePartnerAnalyticCredentialMutation,
  SavePartnerAnalyticCredentialMutationVariables
>;
export const AdminPartnerOverview_UpdatePartnerDocument = gql`
  mutation AdminPartnerOverview_UpdatePartner($input: UpdatePartnerInput!) {
    updatePartner(input: $input) {
      partner {
        id
        name
        billingEmail
        isUpfrontPaymentRequired
        isBillingEnforced
        stripeAccountId
        portal
        reportingLogoFile {
          id
          name
          url
        }
        emailLogoFile {
          id
          name
          url
        }
      }
    }
  }
`;
export type AdminPartnerOverview_UpdatePartnerMutationFn = ApolloReactCommon.MutationFunction<
  AdminPartnerOverview_UpdatePartnerMutation,
  AdminPartnerOverview_UpdatePartnerMutationVariables
>;

/**
 * __useAdminPartnerOverview_UpdatePartnerMutation__
 *
 * To run a mutation, you first call `useAdminPartnerOverview_UpdatePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPartnerOverview_UpdatePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPartnerOverviewUpdatePartnerMutation, { data, loading, error }] = useAdminPartnerOverview_UpdatePartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminPartnerOverview_UpdatePartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminPartnerOverview_UpdatePartnerMutation,
    AdminPartnerOverview_UpdatePartnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminPartnerOverview_UpdatePartnerMutation,
    AdminPartnerOverview_UpdatePartnerMutationVariables
  >(AdminPartnerOverview_UpdatePartnerDocument, options);
}
export type AdminPartnerOverview_UpdatePartnerMutationHookResult = ReturnType<
  typeof useAdminPartnerOverview_UpdatePartnerMutation
>;
export type AdminPartnerOverview_UpdatePartnerMutationResult =
  ApolloReactCommon.MutationResult<AdminPartnerOverview_UpdatePartnerMutation>;
export type AdminPartnerOverview_UpdatePartnerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminPartnerOverview_UpdatePartnerMutation,
  AdminPartnerOverview_UpdatePartnerMutationVariables
>;
export const PartnersDocument = gql`
  query Partners($limit: Int!, $offset: Int!, $sort: PartnersSort, $search: String, $portal: String) {
    partners(limit: $limit, offset: $offset, sort: $sort, search: $search, portal: $portal) {
      totalCount
      nodes {
        ...AdminPartners_Partner
      }
    }
  }
  ${AdminPartners_PartnerFragmentDoc}
`;

/**
 * __usePartnersQuery__
 *
 * To run a query within a React component, call `usePartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      portal: // value for 'portal'
 *   },
 * });
 */
export function usePartnersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PartnersQuery, PartnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnersQuery, PartnersQueryVariables>(PartnersDocument, options);
}
export function usePartnersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnersQuery, PartnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnersQuery, PartnersQueryVariables>(PartnersDocument, options);
}
export type PartnersQueryHookResult = ReturnType<typeof usePartnersQuery>;
export type PartnersLazyQueryHookResult = ReturnType<typeof usePartnersLazyQuery>;
export type PartnersQueryResult = ApolloReactCommon.QueryResult<PartnersQuery, PartnersQueryVariables>;
export const PaymentBatchReviewApproveDocument = gql`
  mutation PaymentBatchReviewApprove($input: ApprovePaymentBatchFundingInput!) {
    approvePaymentBatchFunding(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type PaymentBatchReviewApproveMutationFn = ApolloReactCommon.MutationFunction<
  PaymentBatchReviewApproveMutation,
  PaymentBatchReviewApproveMutationVariables
>;

/**
 * __usePaymentBatchReviewApproveMutation__
 *
 * To run a mutation, you first call `usePaymentBatchReviewApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentBatchReviewApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentBatchReviewApproveMutation, { data, loading, error }] = usePaymentBatchReviewApproveMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentBatchReviewApproveMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PaymentBatchReviewApproveMutation,
    PaymentBatchReviewApproveMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<PaymentBatchReviewApproveMutation, PaymentBatchReviewApproveMutationVariables>(
    PaymentBatchReviewApproveDocument,
    options
  );
}
export type PaymentBatchReviewApproveMutationHookResult = ReturnType<typeof usePaymentBatchReviewApproveMutation>;
export type PaymentBatchReviewApproveMutationResult =
  ApolloReactCommon.MutationResult<PaymentBatchReviewApproveMutation>;
export type PaymentBatchReviewApproveMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PaymentBatchReviewApproveMutation,
  PaymentBatchReviewApproveMutationVariables
>;
export const PaymentBatchReviewDocument = gql`
  query PaymentBatchReview($paymentBatchId: Int!) {
    paymentBatch(id: $paymentBatchId) {
      id
      createdAt
      paymentTotalAmount
      feesTotalAmount
      totalAmount
      fundingApprovedAt
      creativesScaleUserPayments {
        id
        createdAt
        amount
        taskId
        creativesScaleUserId
        creativesScaleUser {
          id
          user {
            id
            fullName
            email
          }
        }
      }
    }
  }
`;

/**
 * __usePaymentBatchReviewQuery__
 *
 * To run a query within a React component, call `usePaymentBatchReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentBatchReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentBatchReviewQuery({
 *   variables: {
 *      paymentBatchId: // value for 'paymentBatchId'
 *   },
 * });
 */
export function usePaymentBatchReviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PaymentBatchReviewQuery, PaymentBatchReviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PaymentBatchReviewQuery, PaymentBatchReviewQueryVariables>(
    PaymentBatchReviewDocument,
    options
  );
}
export function usePaymentBatchReviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaymentBatchReviewQuery, PaymentBatchReviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PaymentBatchReviewQuery, PaymentBatchReviewQueryVariables>(
    PaymentBatchReviewDocument,
    options
  );
}
export type PaymentBatchReviewQueryHookResult = ReturnType<typeof usePaymentBatchReviewQuery>;
export type PaymentBatchReviewLazyQueryHookResult = ReturnType<typeof usePaymentBatchReviewLazyQuery>;
export type PaymentBatchReviewQueryResult = ApolloReactCommon.QueryResult<
  PaymentBatchReviewQuery,
  PaymentBatchReviewQueryVariables
>;
export const PublisherDetailsSecondaryNavDocument = gql`
  query PublisherDetailsSecondaryNav($publisherId: Int!) {
    publisher(id: $publisherId) {
      id
      name
    }
  }
`;

/**
 * __usePublisherDetailsSecondaryNavQuery__
 *
 * To run a query within a React component, call `usePublisherDetailsSecondaryNavQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherDetailsSecondaryNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherDetailsSecondaryNavQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function usePublisherDetailsSecondaryNavQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PublisherDetailsSecondaryNavQuery,
    PublisherDetailsSecondaryNavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PublisherDetailsSecondaryNavQuery, PublisherDetailsSecondaryNavQueryVariables>(
    PublisherDetailsSecondaryNavDocument,
    options
  );
}
export function usePublisherDetailsSecondaryNavLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PublisherDetailsSecondaryNavQuery,
    PublisherDetailsSecondaryNavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PublisherDetailsSecondaryNavQuery, PublisherDetailsSecondaryNavQueryVariables>(
    PublisherDetailsSecondaryNavDocument,
    options
  );
}
export type PublisherDetailsSecondaryNavQueryHookResult = ReturnType<typeof usePublisherDetailsSecondaryNavQuery>;
export type PublisherDetailsSecondaryNavLazyQueryHookResult = ReturnType<
  typeof usePublisherDetailsSecondaryNavLazyQuery
>;
export type PublisherDetailsSecondaryNavQueryResult = ApolloReactCommon.QueryResult<
  PublisherDetailsSecondaryNavQuery,
  PublisherDetailsSecondaryNavQueryVariables
>;
export const AdminPublisherEmailsDocument = gql`
  query AdminPublisherEmails($publisherId: Int!) {
    publisher(id: $publisherId) {
      id
      emailMessages {
        totalCount
        nodes {
          id
          createdAt
          sentAt
          toName
          toAddress
          fromName
          fromAddress
          subject
        }
      }
    }
  }
`;

/**
 * __useAdminPublisherEmailsQuery__
 *
 * To run a query within a React component, call `useAdminPublisherEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPublisherEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPublisherEmailsQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useAdminPublisherEmailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminPublisherEmailsQuery, AdminPublisherEmailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminPublisherEmailsQuery, AdminPublisherEmailsQueryVariables>(
    AdminPublisherEmailsDocument,
    options
  );
}
export function useAdminPublisherEmailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminPublisherEmailsQuery, AdminPublisherEmailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminPublisherEmailsQuery, AdminPublisherEmailsQueryVariables>(
    AdminPublisherEmailsDocument,
    options
  );
}
export type AdminPublisherEmailsQueryHookResult = ReturnType<typeof useAdminPublisherEmailsQuery>;
export type AdminPublisherEmailsLazyQueryHookResult = ReturnType<typeof useAdminPublisherEmailsLazyQuery>;
export type AdminPublisherEmailsQueryResult = ApolloReactCommon.QueryResult<
  AdminPublisherEmailsQuery,
  AdminPublisherEmailsQueryVariables
>;
export const UpdatePublisherLinkLocationCategoriesDocument = gql`
  mutation UpdatePublisherLinkLocationCategories($input: UpdateLinkLocationCategoriesInput!) {
    updateLinkLocationCategories(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type UpdatePublisherLinkLocationCategoriesMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePublisherLinkLocationCategoriesMutation,
  UpdatePublisherLinkLocationCategoriesMutationVariables
>;

/**
 * __useUpdatePublisherLinkLocationCategoriesMutation__
 *
 * To run a mutation, you first call `useUpdatePublisherLinkLocationCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublisherLinkLocationCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublisherLinkLocationCategoriesMutation, { data, loading, error }] = useUpdatePublisherLinkLocationCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublisherLinkLocationCategoriesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePublisherLinkLocationCategoriesMutation,
    UpdatePublisherLinkLocationCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdatePublisherLinkLocationCategoriesMutation,
    UpdatePublisherLinkLocationCategoriesMutationVariables
  >(UpdatePublisherLinkLocationCategoriesDocument, options);
}
export type UpdatePublisherLinkLocationCategoriesMutationHookResult = ReturnType<
  typeof useUpdatePublisherLinkLocationCategoriesMutation
>;
export type UpdatePublisherLinkLocationCategoriesMutationResult =
  ApolloReactCommon.MutationResult<UpdatePublisherLinkLocationCategoriesMutation>;
export type UpdatePublisherLinkLocationCategoriesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePublisherLinkLocationCategoriesMutation,
  UpdatePublisherLinkLocationCategoriesMutationVariables
>;
export const AdminPublisherLinkLocationsDocument = gql`
  query AdminPublisherLinkLocations(
    $publisherId: Int!
    $limit: Int
    $offset: Int
    $statusIds: [String!]
    $search: String
    $sort: PublisherLinkLocationSort
  ) {
    publisherLinkLocations(
      publisherId: $publisherId
      limit: $limit
      offset: $offset
      statusIds: $statusIds
      search: $search
      sort: $sort
    ) {
      totalCount
      nodes {
        id
        linkLocation {
          id
          domain
          domainAuthority
          authorityScore
          monthlyTraffic
          internalQuality
          cost
          status {
            id
            name
          }
          linkLocationCategories {
            id
            categoryId
          }
        }
      }
    }
  }
`;

/**
 * __useAdminPublisherLinkLocationsQuery__
 *
 * To run a query within a React component, call `useAdminPublisherLinkLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPublisherLinkLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPublisherLinkLocationsQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      statusIds: // value for 'statusIds'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAdminPublisherLinkLocationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminPublisherLinkLocationsQuery,
    AdminPublisherLinkLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminPublisherLinkLocationsQuery, AdminPublisherLinkLocationsQueryVariables>(
    AdminPublisherLinkLocationsDocument,
    options
  );
}
export function useAdminPublisherLinkLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminPublisherLinkLocationsQuery,
    AdminPublisherLinkLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminPublisherLinkLocationsQuery, AdminPublisherLinkLocationsQueryVariables>(
    AdminPublisherLinkLocationsDocument,
    options
  );
}
export type AdminPublisherLinkLocationsQueryHookResult = ReturnType<typeof useAdminPublisherLinkLocationsQuery>;
export type AdminPublisherLinkLocationsLazyQueryHookResult = ReturnType<typeof useAdminPublisherLinkLocationsLazyQuery>;
export type AdminPublisherLinkLocationsQueryResult = ApolloReactCommon.QueryResult<
  AdminPublisherLinkLocationsQuery,
  AdminPublisherLinkLocationsQueryVariables
>;
export const AdminPublisherOverviewDocument = gql`
  query AdminPublisherOverview($id: Int!) {
    publisher(id: $id) {
      id
      name
      contactEmail
      paymentMethod
      paymentMethodId
      isSlowPartner
      instructions
      isAutoPayEnabled
      isInvoiceRequired
      createdByUser {
        id
        fullName
      }
    }
  }
`;

/**
 * __useAdminPublisherOverviewQuery__
 *
 * To run a query within a React component, call `useAdminPublisherOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPublisherOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPublisherOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminPublisherOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminPublisherOverviewQuery, AdminPublisherOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminPublisherOverviewQuery, AdminPublisherOverviewQueryVariables>(
    AdminPublisherOverviewDocument,
    options
  );
}
export function useAdminPublisherOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminPublisherOverviewQuery, AdminPublisherOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminPublisherOverviewQuery, AdminPublisherOverviewQueryVariables>(
    AdminPublisherOverviewDocument,
    options
  );
}
export type AdminPublisherOverviewQueryHookResult = ReturnType<typeof useAdminPublisherOverviewQuery>;
export type AdminPublisherOverviewLazyQueryHookResult = ReturnType<typeof useAdminPublisherOverviewLazyQuery>;
export type AdminPublisherOverviewQueryResult = ApolloReactCommon.QueryResult<
  AdminPublisherOverviewQuery,
  AdminPublisherOverviewQueryVariables
>;
export const AdminPublisherOverview_UpdatePublisherDocument = gql`
  mutation AdminPublisherOverview_UpdatePublisher($input: UpdatePublisherInput!) {
    updatePublisher(input: $input) {
      ok
      publisher {
        id
        name
        contactEmail
        paymentMethod
        paymentMethodId
        isSlowPartner
        instructions
      }
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type AdminPublisherOverview_UpdatePublisherMutationFn = ApolloReactCommon.MutationFunction<
  AdminPublisherOverview_UpdatePublisherMutation,
  AdminPublisherOverview_UpdatePublisherMutationVariables
>;

/**
 * __useAdminPublisherOverview_UpdatePublisherMutation__
 *
 * To run a mutation, you first call `useAdminPublisherOverview_UpdatePublisherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminPublisherOverview_UpdatePublisherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminPublisherOverviewUpdatePublisherMutation, { data, loading, error }] = useAdminPublisherOverview_UpdatePublisherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminPublisherOverview_UpdatePublisherMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminPublisherOverview_UpdatePublisherMutation,
    AdminPublisherOverview_UpdatePublisherMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminPublisherOverview_UpdatePublisherMutation,
    AdminPublisherOverview_UpdatePublisherMutationVariables
  >(AdminPublisherOverview_UpdatePublisherDocument, options);
}
export type AdminPublisherOverview_UpdatePublisherMutationHookResult = ReturnType<
  typeof useAdminPublisherOverview_UpdatePublisherMutation
>;
export type AdminPublisherOverview_UpdatePublisherMutationResult =
  ApolloReactCommon.MutationResult<AdminPublisherOverview_UpdatePublisherMutation>;
export type AdminPublisherOverview_UpdatePublisherMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminPublisherOverview_UpdatePublisherMutation,
  AdminPublisherOverview_UpdatePublisherMutationVariables
>;
export const PaymentBatchReviewApprovePayPalDocument = gql`
  mutation PaymentBatchReviewApprovePayPal($input: ApprovePaymentBatchFundingPaypalInput!) {
    approvePaymentBatchFundingPaypal(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type PaymentBatchReviewApprovePayPalMutationFn = ApolloReactCommon.MutationFunction<
  PaymentBatchReviewApprovePayPalMutation,
  PaymentBatchReviewApprovePayPalMutationVariables
>;

/**
 * __usePaymentBatchReviewApprovePayPalMutation__
 *
 * To run a mutation, you first call `usePaymentBatchReviewApprovePayPalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaymentBatchReviewApprovePayPalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paymentBatchReviewApprovePayPalMutation, { data, loading, error }] = usePaymentBatchReviewApprovePayPalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePaymentBatchReviewApprovePayPalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PaymentBatchReviewApprovePayPalMutation,
    PaymentBatchReviewApprovePayPalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PaymentBatchReviewApprovePayPalMutation,
    PaymentBatchReviewApprovePayPalMutationVariables
  >(PaymentBatchReviewApprovePayPalDocument, options);
}
export type PaymentBatchReviewApprovePayPalMutationHookResult = ReturnType<
  typeof usePaymentBatchReviewApprovePayPalMutation
>;
export type PaymentBatchReviewApprovePayPalMutationResult =
  ApolloReactCommon.MutationResult<PaymentBatchReviewApprovePayPalMutation>;
export type PaymentBatchReviewApprovePayPalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PaymentBatchReviewApprovePayPalMutation,
  PaymentBatchReviewApprovePayPalMutationVariables
>;
export const PublisherPaymentBatchReviewDocument = gql`
  query PublisherPaymentBatchReview($paymentBatchId: Int!) {
    paymentBatch(id: $paymentBatchId) {
      id
      createdAt
      paymentTotalAmount
      feesTotalAmount
      totalAmount
      fundingApprovedAt
      publisherPayments {
        id
        amount
        orderId
        processor
        processorReferenceId
        publisher {
          id
          name
        }
        status {
          id
        }
      }
    }
  }
`;

/**
 * __usePublisherPaymentBatchReviewQuery__
 *
 * To run a query within a React component, call `usePublisherPaymentBatchReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherPaymentBatchReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherPaymentBatchReviewQuery({
 *   variables: {
 *      paymentBatchId: // value for 'paymentBatchId'
 *   },
 * });
 */
export function usePublisherPaymentBatchReviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PublisherPaymentBatchReviewQuery,
    PublisherPaymentBatchReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PublisherPaymentBatchReviewQuery, PublisherPaymentBatchReviewQueryVariables>(
    PublisherPaymentBatchReviewDocument,
    options
  );
}
export function usePublisherPaymentBatchReviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PublisherPaymentBatchReviewQuery,
    PublisherPaymentBatchReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PublisherPaymentBatchReviewQuery, PublisherPaymentBatchReviewQueryVariables>(
    PublisherPaymentBatchReviewDocument,
    options
  );
}
export type PublisherPaymentBatchReviewQueryHookResult = ReturnType<typeof usePublisherPaymentBatchReviewQuery>;
export type PublisherPaymentBatchReviewLazyQueryHookResult = ReturnType<typeof usePublisherPaymentBatchReviewLazyQuery>;
export type PublisherPaymentBatchReviewQueryResult = ApolloReactCommon.QueryResult<
  PublisherPaymentBatchReviewQuery,
  PublisherPaymentBatchReviewQueryVariables
>;
export const AutoPublisherPaymentsDocument = gql`
  query AutoPublisherPayments(
    $processorReferenceId: String
    $statusIds: [String!]
    $checkPublisherAutoPayEnabled: Boolean
  ) {
    publisherPayments(
      processorReferenceId: $processorReferenceId
      statusIds: $statusIds
      checkPublisherAutoPayEnabled: $checkPublisherAutoPayEnabled
    ) {
      nodes {
        id
        amount
        orderId
        publisherId
        processor
        processorReferenceId
        publisher {
          id
          name
          paymentMethod
          paymentMethodId
        }
        status {
          id
        }
      }
    }
  }
`;

/**
 * __useAutoPublisherPaymentsQuery__
 *
 * To run a query within a React component, call `useAutoPublisherPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutoPublisherPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutoPublisherPaymentsQuery({
 *   variables: {
 *      processorReferenceId: // value for 'processorReferenceId'
 *      statusIds: // value for 'statusIds'
 *      checkPublisherAutoPayEnabled: // value for 'checkPublisherAutoPayEnabled'
 *   },
 * });
 */
export function useAutoPublisherPaymentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AutoPublisherPaymentsQuery, AutoPublisherPaymentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AutoPublisherPaymentsQuery, AutoPublisherPaymentsQueryVariables>(
    AutoPublisherPaymentsDocument,
    options
  );
}
export function useAutoPublisherPaymentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AutoPublisherPaymentsQuery, AutoPublisherPaymentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AutoPublisherPaymentsQuery, AutoPublisherPaymentsQueryVariables>(
    AutoPublisherPaymentsDocument,
    options
  );
}
export type AutoPublisherPaymentsQueryHookResult = ReturnType<typeof useAutoPublisherPaymentsQuery>;
export type AutoPublisherPaymentsLazyQueryHookResult = ReturnType<typeof useAutoPublisherPaymentsLazyQuery>;
export type AutoPublisherPaymentsQueryResult = ApolloReactCommon.QueryResult<
  AutoPublisherPaymentsQuery,
  AutoPublisherPaymentsQueryVariables
>;
export const UpdatePublisherPaymentDocument = gql`
  mutation UpdatePublisherPayment($input: UpdatePublisherPaymentInput!) {
    updatePublisherPayment(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type UpdatePublisherPaymentMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePublisherPaymentMutation,
  UpdatePublisherPaymentMutationVariables
>;

/**
 * __useUpdatePublisherPaymentMutation__
 *
 * To run a mutation, you first call `useUpdatePublisherPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublisherPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublisherPaymentMutation, { data, loading, error }] = useUpdatePublisherPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePublisherPaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePublisherPaymentMutation,
    UpdatePublisherPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePublisherPaymentMutation, UpdatePublisherPaymentMutationVariables>(
    UpdatePublisherPaymentDocument,
    options
  );
}
export type UpdatePublisherPaymentMutationHookResult = ReturnType<typeof useUpdatePublisherPaymentMutation>;
export type UpdatePublisherPaymentMutationResult = ApolloReactCommon.MutationResult<UpdatePublisherPaymentMutation>;
export type UpdatePublisherPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePublisherPaymentMutation,
  UpdatePublisherPaymentMutationVariables
>;
export const AdminPublisherMetricDocument = gql`
  query AdminPublisherMetric($publisherId: Int!) {
    publisher(id: $publisherId) {
      id
      publishedArticles7days: publishedOrders(daysAgo: 7) {
        totalCount
      }
      publishedArticles30days: publishedOrders(daysAgo: 30) {
        totalCount
      }
      publishedArticlesTotal: publishedOrders {
        totalCount
      }
      paidAmount30days: paidAmount(daysAgo: 30)
      paidAmountTotal: paidAmount
    }
  }
`;

/**
 * __useAdminPublisherMetricQuery__
 *
 * To run a query within a React component, call `useAdminPublisherMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPublisherMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPublisherMetricQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *   },
 * });
 */
export function useAdminPublisherMetricQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminPublisherMetricQuery, AdminPublisherMetricQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminPublisherMetricQuery, AdminPublisherMetricQueryVariables>(
    AdminPublisherMetricDocument,
    options
  );
}
export function useAdminPublisherMetricLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminPublisherMetricQuery, AdminPublisherMetricQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminPublisherMetricQuery, AdminPublisherMetricQueryVariables>(
    AdminPublisherMetricDocument,
    options
  );
}
export type AdminPublisherMetricQueryHookResult = ReturnType<typeof useAdminPublisherMetricQuery>;
export type AdminPublisherMetricLazyQueryHookResult = ReturnType<typeof useAdminPublisherMetricLazyQuery>;
export type AdminPublisherMetricQueryResult = ApolloReactCommon.QueryResult<
  AdminPublisherMetricQuery,
  AdminPublisherMetricQueryVariables
>;
export const AdminPublishedOrdersDocument = gql`
  query AdminPublishedOrders(
    $publisherId: Int!
    $requestLimit: Int
    $requestOffset: Int
    $requestSort: OrdersSort
    $limit: Int
    $offset: Int
    $sort: OrdersSort
  ) {
    publisher(id: $publisherId) {
      requestedOrders(limit: $requestLimit, offset: $requestOffset, sort: $requestSort) {
        totalCount
        nodes {
          ...RequestedOrder
        }
      }
      publishedOrders(limit: $limit, offset: $offset, sort: $sort) {
        totalCount
        nodes {
          ...PublishedOrder
        }
      }
    }
  }
  ${RequestedOrderFragmentDoc}
  ${PublishedOrderFragmentDoc}
`;

/**
 * __useAdminPublishedOrdersQuery__
 *
 * To run a query within a React component, call `useAdminPublishedOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPublishedOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPublishedOrdersQuery({
 *   variables: {
 *      publisherId: // value for 'publisherId'
 *      requestLimit: // value for 'requestLimit'
 *      requestOffset: // value for 'requestOffset'
 *      requestSort: // value for 'requestSort'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAdminPublishedOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminPublishedOrdersQuery, AdminPublishedOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminPublishedOrdersQuery, AdminPublishedOrdersQueryVariables>(
    AdminPublishedOrdersDocument,
    options
  );
}
export function useAdminPublishedOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminPublishedOrdersQuery, AdminPublishedOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminPublishedOrdersQuery, AdminPublishedOrdersQueryVariables>(
    AdminPublishedOrdersDocument,
    options
  );
}
export type AdminPublishedOrdersQueryHookResult = ReturnType<typeof useAdminPublishedOrdersQuery>;
export type AdminPublishedOrdersLazyQueryHookResult = ReturnType<typeof useAdminPublishedOrdersLazyQuery>;
export type AdminPublishedOrdersQueryResult = ApolloReactCommon.QueryResult<
  AdminPublishedOrdersQuery,
  AdminPublishedOrdersQueryVariables
>;
export const PublisherUsersDocument = gql`
  query PublisherUsers($id: Int!, $limit: Int!, $offset: Int!, $search: String, $includeInactive: Boolean) {
    publisher(id: $id) {
      id
      publisherUsers(limit: $limit, offset: $offset, search: $search, includeInactive: $includeInactive) {
        totalCount
        nodes {
          createdAt
          user {
            id
            fullName
            email
          }
        }
      }
    }
  }
`;

/**
 * __usePublisherUsersQuery__
 *
 * To run a query within a React component, call `usePublisherUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublisherUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublisherUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      includeInactive: // value for 'includeInactive'
 *   },
 * });
 */
export function usePublisherUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PublisherUsersQuery, PublisherUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PublisherUsersQuery, PublisherUsersQueryVariables>(PublisherUsersDocument, options);
}
export function usePublisherUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublisherUsersQuery, PublisherUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PublisherUsersQuery, PublisherUsersQueryVariables>(
    PublisherUsersDocument,
    options
  );
}
export type PublisherUsersQueryHookResult = ReturnType<typeof usePublisherUsersQuery>;
export type PublisherUsersLazyQueryHookResult = ReturnType<typeof usePublisherUsersLazyQuery>;
export type PublisherUsersQueryResult = ApolloReactCommon.QueryResult<
  PublisherUsersQuery,
  PublisherUsersQueryVariables
>;
export const PublishersDocument = gql`
  query Publishers($limit: Int!, $offset: Int!, $search: String) {
    publishers(limit: $limit, offset: $offset, search: $search) {
      totalCount
      nodes {
        ...AdminPublishers_Publisher
      }
    }
  }
  ${AdminPublishers_PublisherFragmentDoc}
`;

/**
 * __usePublishersQuery__
 *
 * To run a query within a React component, call `usePublishersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePublishersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PublishersQuery, PublishersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PublishersQuery, PublishersQueryVariables>(PublishersDocument, options);
}
export function usePublishersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublishersQuery, PublishersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PublishersQuery, PublishersQueryVariables>(PublishersDocument, options);
}
export type PublishersQueryHookResult = ReturnType<typeof usePublishersQuery>;
export type PublishersLazyQueryHookResult = ReturnType<typeof usePublishersLazyQuery>;
export type PublishersQueryResult = ApolloReactCommon.QueryResult<PublishersQuery, PublishersQueryVariables>;
export const AdminReports_PublicationManagementDocument = gql`
  query AdminReports_PublicationManagement($limit: Int, $offset: Int, $treatReportingSmartsResultUrlAsNull: Boolean) {
    adminReports {
      publicationManagement {
        ordersNeedingResultUrl(
          limit: $limit
          offset: $offset
          sort: COMPLETED_AT_ASC
          treatReportingSmartsResultUrlAsNull: $treatReportingSmartsResultUrlAsNull
        ) {
          totalCount
          nodes {
            id
            startDate
            completedAt
            topic
            partnerProduct {
              id
              product {
                id
                name
              }
            }
            customer {
              id
              name
              partner {
                id
                name
              }
            }
            orderContentItems {
              id
              contentItemDelivery {
                publicUrl
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAdminReports_PublicationManagementQuery__
 *
 * To run a query within a React component, call `useAdminReports_PublicationManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminReports_PublicationManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminReports_PublicationManagementQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      treatReportingSmartsResultUrlAsNull: // value for 'treatReportingSmartsResultUrlAsNull'
 *   },
 * });
 */
export function useAdminReports_PublicationManagementQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminReports_PublicationManagementQuery,
    AdminReports_PublicationManagementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AdminReports_PublicationManagementQuery,
    AdminReports_PublicationManagementQueryVariables
  >(AdminReports_PublicationManagementDocument, options);
}
export function useAdminReports_PublicationManagementLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminReports_PublicationManagementQuery,
    AdminReports_PublicationManagementQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminReports_PublicationManagementQuery,
    AdminReports_PublicationManagementQueryVariables
  >(AdminReports_PublicationManagementDocument, options);
}
export type AdminReports_PublicationManagementQueryHookResult = ReturnType<
  typeof useAdminReports_PublicationManagementQuery
>;
export type AdminReports_PublicationManagementLazyQueryHookResult = ReturnType<
  typeof useAdminReports_PublicationManagementLazyQuery
>;
export type AdminReports_PublicationManagementQueryResult = ApolloReactCommon.QueryResult<
  AdminReports_PublicationManagementQuery,
  AdminReports_PublicationManagementQueryVariables
>;
export const SitePostDetailDocument = gql`
  query SitePostDetail($id: Int!) {
    sitePost(id: $id) {
      id
      title
      content
      createdAt
      siteId
      category {
        id
      }
      addedByUser {
        id
        fullName
      }
      status {
        id
      }
    }
  }
`;

/**
 * __useSitePostDetailQuery__
 *
 * To run a query within a React component, call `useSitePostDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitePostDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitePostDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSitePostDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SitePostDetailQuery, SitePostDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SitePostDetailQuery, SitePostDetailQueryVariables>(SitePostDetailDocument, options);
}
export function useSitePostDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SitePostDetailQuery, SitePostDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SitePostDetailQuery, SitePostDetailQueryVariables>(
    SitePostDetailDocument,
    options
  );
}
export type SitePostDetailQueryHookResult = ReturnType<typeof useSitePostDetailQuery>;
export type SitePostDetailLazyQueryHookResult = ReturnType<typeof useSitePostDetailLazyQuery>;
export type SitePostDetailQueryResult = ApolloReactCommon.QueryResult<
  SitePostDetailQuery,
  SitePostDetailQueryVariables
>;
export const UpdateSitePostDocument = gql`
  mutation UpdateSitePost($input: UpdateSitePostInput!) {
    updateSitePost(input: $input) {
      sitePost {
        id
      }
    }
  }
`;
export type UpdateSitePostMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSitePostMutation,
  UpdateSitePostMutationVariables
>;

/**
 * __useUpdateSitePostMutation__
 *
 * To run a mutation, you first call `useUpdateSitePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSitePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSitePostMutation, { data, loading, error }] = useUpdateSitePostMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSitePostMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSitePostMutation, UpdateSitePostMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateSitePostMutation, UpdateSitePostMutationVariables>(
    UpdateSitePostDocument,
    options
  );
}
export type UpdateSitePostMutationHookResult = ReturnType<typeof useUpdateSitePostMutation>;
export type UpdateSitePostMutationResult = ApolloReactCommon.MutationResult<UpdateSitePostMutation>;
export type UpdateSitePostMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSitePostMutation,
  UpdateSitePostMutationVariables
>;
export const SitePostsDocument = gql`
  query SitePosts(
    $limit: Int
    $offset: Int
    $sort: SitePostsSort
    $search: String
    $siteIds: [String!]
    $statusIds: [String!]
  ) {
    sitePosts(limit: $limit, offset: $offset, sort: $sort, search: $search, siteIds: $siteIds, statusIds: $statusIds) {
      totalCount
      nodes {
        id
        siteId
        title
        createdAt
        taskId
        status {
          id
          name
        }
        site {
          id
        }
      }
    }
  }
`;

/**
 * __useSitePostsQuery__
 *
 * To run a query within a React component, call `useSitePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitePostsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      siteIds: // value for 'siteIds'
 *      statusIds: // value for 'statusIds'
 *   },
 * });
 */
export function useSitePostsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SitePostsQuery, SitePostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SitePostsQuery, SitePostsQueryVariables>(SitePostsDocument, options);
}
export function useSitePostsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SitePostsQuery, SitePostsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SitePostsQuery, SitePostsQueryVariables>(SitePostsDocument, options);
}
export type SitePostsQueryHookResult = ReturnType<typeof useSitePostsQuery>;
export type SitePostsLazyQueryHookResult = ReturnType<typeof useSitePostsLazyQuery>;
export type SitePostsQueryResult = ApolloReactCommon.QueryResult<SitePostsQuery, SitePostsQueryVariables>;
export const SitesDocument = gql`
  query Sites($limit: Int!, $offset: Int!, $search: String) {
    sites(limit: $limit, offset: $offset, search: $search) {
      totalCount
      nodes {
        id
        category {
          id
          name
        }
        deployStatus {
          id
          name
        }
        lastPublishedAt
        siteTemplate {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useSitesQuery__
 *
 * To run a query within a React component, call `useSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSitesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSitesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<SitesQuery, SitesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SitesQuery, SitesQueryVariables>(SitesDocument, options);
}
export function useSitesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SitesQuery, SitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SitesQuery, SitesQueryVariables>(SitesDocument, options);
}
export type SitesQueryHookResult = ReturnType<typeof useSitesQuery>;
export type SitesLazyQueryHookResult = ReturnType<typeof useSitesLazyQuery>;
export type SitesQueryResult = ApolloReactCommon.QueryResult<SitesQuery, SitesQueryVariables>;
export const UpdateTasksEditDocument = gql`
  mutation UpdateTasksEdit($input: UpdateRcmWriterTasksInput!) {
    updateRcmWriterTasks(input: $input) {
      ok
    }
  }
`;
export type UpdateTasksEditMutationFn = ApolloReactCommon.MutationFunction<
  UpdateTasksEditMutation,
  UpdateTasksEditMutationVariables
>;

/**
 * __useUpdateTasksEditMutation__
 *
 * To run a mutation, you first call `useUpdateTasksEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTasksEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTasksEditMutation, { data, loading, error }] = useUpdateTasksEditMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTasksEditMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTasksEditMutation, UpdateTasksEditMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateTasksEditMutation, UpdateTasksEditMutationVariables>(
    UpdateTasksEditDocument,
    options
  );
}
export type UpdateTasksEditMutationHookResult = ReturnType<typeof useUpdateTasksEditMutation>;
export type UpdateTasksEditMutationResult = ApolloReactCommon.MutationResult<UpdateTasksEditMutation>;
export type UpdateTasksEditMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateTasksEditMutation,
  UpdateTasksEditMutationVariables
>;
export const AdminTasksDocument = gql`
  query AdminTasks(
    $limit: Int
    $offset: Int
    $customerIds: [Int!]
    $vendorIds: [Int!]
    $taskTypeIds: [String!]
    $statusIds: [String!]
    $assignedUserIds: [Int!]
    $assignedAt: DateQuery
    $search: String
  ) {
    tasks(
      limit: $limit
      offset: $offset
      customerIds: $customerIds
      vendorIds: $vendorIds
      taskTypeIds: $taskTypeIds
      statusIds: $statusIds
      assignedUserIds: $assignedUserIds
      assignedAt: $assignedAt
      search: $search
    ) {
      totalCount
      nodes {
        ...AdminTasks_Task
      }
    }
  }
  ${AdminTasks_TaskFragmentDoc}
`;

/**
 * __useAdminTasksQuery__
 *
 * To run a query within a React component, call `useAdminTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      customerIds: // value for 'customerIds'
 *      vendorIds: // value for 'vendorIds'
 *      taskTypeIds: // value for 'taskTypeIds'
 *      statusIds: // value for 'statusIds'
 *      assignedUserIds: // value for 'assignedUserIds'
 *      assignedAt: // value for 'assignedAt'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAdminTasksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AdminTasksQuery, AdminTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminTasksQuery, AdminTasksQueryVariables>(AdminTasksDocument, options);
}
export function useAdminTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminTasksQuery, AdminTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminTasksQuery, AdminTasksQueryVariables>(AdminTasksDocument, options);
}
export type AdminTasksQueryHookResult = ReturnType<typeof useAdminTasksQuery>;
export type AdminTasksLazyQueryHookResult = ReturnType<typeof useAdminTasksLazyQuery>;
export type AdminTasksQueryResult = ApolloReactCommon.QueryResult<AdminTasksQuery, AdminTasksQueryVariables>;
export const AdminTasks_UpdateTaskDocument = gql`
  mutation AdminTasks_UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        id
      }
    }
  }
`;
export type AdminTasks_UpdateTaskMutationFn = ApolloReactCommon.MutationFunction<
  AdminTasks_UpdateTaskMutation,
  AdminTasks_UpdateTaskMutationVariables
>;

/**
 * __useAdminTasks_UpdateTaskMutation__
 *
 * To run a mutation, you first call `useAdminTasks_UpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminTasks_UpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminTasksUpdateTaskMutation, { data, loading, error }] = useAdminTasks_UpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminTasks_UpdateTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminTasks_UpdateTaskMutation,
    AdminTasks_UpdateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AdminTasks_UpdateTaskMutation, AdminTasks_UpdateTaskMutationVariables>(
    AdminTasks_UpdateTaskDocument,
    options
  );
}
export type AdminTasks_UpdateTaskMutationHookResult = ReturnType<typeof useAdminTasks_UpdateTaskMutation>;
export type AdminTasks_UpdateTaskMutationResult = ApolloReactCommon.MutationResult<AdminTasks_UpdateTaskMutation>;
export type AdminTasks_UpdateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminTasks_UpdateTaskMutation,
  AdminTasks_UpdateTaskMutationVariables
>;
export const AdminUsers_UpdateUserDocument = gql`
  mutation AdminUsers_UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export type AdminUsers_UpdateUserMutationFn = ApolloReactCommon.MutationFunction<
  AdminUsers_UpdateUserMutation,
  AdminUsers_UpdateUserMutationVariables
>;

/**
 * __useAdminUsers_UpdateUserMutation__
 *
 * To run a mutation, you first call `useAdminUsers_UpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUsers_UpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUsersUpdateUserMutation, { data, loading, error }] = useAdminUsers_UpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUsers_UpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminUsers_UpdateUserMutation,
    AdminUsers_UpdateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<AdminUsers_UpdateUserMutation, AdminUsers_UpdateUserMutationVariables>(
    AdminUsers_UpdateUserDocument,
    options
  );
}
export type AdminUsers_UpdateUserMutationHookResult = ReturnType<typeof useAdminUsers_UpdateUserMutation>;
export type AdminUsers_UpdateUserMutationResult = ApolloReactCommon.MutationResult<AdminUsers_UpdateUserMutation>;
export type AdminUsers_UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AdminUsers_UpdateUserMutation,
  AdminUsers_UpdateUserMutationVariables
>;
export const AdminUsersDocument = gql`
  query AdminUsers($limit: Int!, $offset: Int!, $search: String, $includeInactive: Boolean) {
    users(limit: $limit, offset: $offset, search: $search, includeInactive: $includeInactive) {
      totalCount
      nodes {
        ...AdminUsers_User
      }
    }
  }
  ${AdminUsers_UserFragmentDoc}
`;

/**
 * __useAdminUsersQuery__
 *
 * To run a query within a React component, call `useAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      includeInactive: // value for 'includeInactive'
 *   },
 * });
 */
export function useAdminUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AdminUsersQuery, AdminUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument, options);
}
export function useAdminUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AdminUsersQuery, AdminUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument, options);
}
export type AdminUsersQueryHookResult = ReturnType<typeof useAdminUsersQuery>;
export type AdminUsersLazyQueryHookResult = ReturnType<typeof useAdminUsersLazyQuery>;
export type AdminUsersQueryResult = ApolloReactCommon.QueryResult<AdminUsersQuery, AdminUsersQueryVariables>;
export const AdminVendorDetailsSecondaryNavDocument = gql`
  query AdminVendorDetailsSecondaryNav($vendorId: Int!) {
    vendor(id: $vendorId) {
      id
      name
    }
  }
`;

/**
 * __useAdminVendorDetailsSecondaryNavQuery__
 *
 * To run a query within a React component, call `useAdminVendorDetailsSecondaryNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminVendorDetailsSecondaryNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminVendorDetailsSecondaryNavQuery({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useAdminVendorDetailsSecondaryNavQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminVendorDetailsSecondaryNavQuery,
    AdminVendorDetailsSecondaryNavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AdminVendorDetailsSecondaryNavQuery, AdminVendorDetailsSecondaryNavQueryVariables>(
    AdminVendorDetailsSecondaryNavDocument,
    options
  );
}
export function useAdminVendorDetailsSecondaryNavLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminVendorDetailsSecondaryNavQuery,
    AdminVendorDetailsSecondaryNavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminVendorDetailsSecondaryNavQuery,
    AdminVendorDetailsSecondaryNavQueryVariables
  >(AdminVendorDetailsSecondaryNavDocument, options);
}
export type AdminVendorDetailsSecondaryNavQueryHookResult = ReturnType<typeof useAdminVendorDetailsSecondaryNavQuery>;
export type AdminVendorDetailsSecondaryNavLazyQueryHookResult = ReturnType<
  typeof useAdminVendorDetailsSecondaryNavLazyQuery
>;
export type AdminVendorDetailsSecondaryNavQueryResult = ApolloReactCommon.QueryResult<
  AdminVendorDetailsSecondaryNavQuery,
  AdminVendorDetailsSecondaryNavQueryVariables
>;
export const PartnerInvoiceDetailsDocument = gql`
  query PartnerInvoiceDetails($partnerInvoiceId: Int!) {
    partnerInvoice(id: $partnerInvoiceId) {
      id
      createdAt
      status {
        id
        name
        description
      }
      partner {
        id
        name
        billingEmail
      }
      totalChargesAmount
      totalPaymentsAmount
      totalRefundsAmount
      totalDueAmount
      partnerInvoicePayments {
        nodes {
          id
          createdAt
          amount
          errorMessage
          status {
            id
            name
            description
          }
          paymentMethodDescription
        }
      }
      partnerInvoiceCharges {
        nodes {
          id
          createdAt
          typeId
          amount
          customer {
            id
            name
          }
          status {
            id
            name
            description
          }
          order {
            id
            startDate
            activeOrderContentStrategy {
              id
              topic
            }
            partnerProduct {
              id
              product {
                id
                name
              }
            }
          }
        }
      }
      partnerInvoiceRefunds {
        nodes {
          id
          createdAt
          amount
          reason
          status {
            id
            name
            description
          }
        }
      }
    }
  }
`;

/**
 * __usePartnerInvoiceDetailsQuery__
 *
 * To run a query within a React component, call `usePartnerInvoiceDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerInvoiceDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerInvoiceDetailsQuery({
 *   variables: {
 *      partnerInvoiceId: // value for 'partnerInvoiceId'
 *   },
 * });
 */
export function usePartnerInvoiceDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PartnerInvoiceDetailsQuery, PartnerInvoiceDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerInvoiceDetailsQuery, PartnerInvoiceDetailsQueryVariables>(
    PartnerInvoiceDetailsDocument,
    options
  );
}
export function usePartnerInvoiceDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerInvoiceDetailsQuery, PartnerInvoiceDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerInvoiceDetailsQuery, PartnerInvoiceDetailsQueryVariables>(
    PartnerInvoiceDetailsDocument,
    options
  );
}
export type PartnerInvoiceDetailsQueryHookResult = ReturnType<typeof usePartnerInvoiceDetailsQuery>;
export type PartnerInvoiceDetailsLazyQueryHookResult = ReturnType<typeof usePartnerInvoiceDetailsLazyQuery>;
export type PartnerInvoiceDetailsQueryResult = ApolloReactCommon.QueryResult<
  PartnerInvoiceDetailsQuery,
  PartnerInvoiceDetailsQueryVariables
>;
export const PartnerInvoice_PayPartnerInvoiceDocument = gql`
  mutation PartnerInvoice_PayPartnerInvoice($input: PayPartnerInvoiceInput!) {
    payPartnerInvoice(input: $input) {
      ok
      error {
        message
      }
      wasPaymentSuccessful
    }
  }
`;
export type PartnerInvoice_PayPartnerInvoiceMutationFn = ApolloReactCommon.MutationFunction<
  PartnerInvoice_PayPartnerInvoiceMutation,
  PartnerInvoice_PayPartnerInvoiceMutationVariables
>;

/**
 * __usePartnerInvoice_PayPartnerInvoiceMutation__
 *
 * To run a mutation, you first call `usePartnerInvoice_PayPartnerInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePartnerInvoice_PayPartnerInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [partnerInvoicePayPartnerInvoiceMutation, { data, loading, error }] = usePartnerInvoice_PayPartnerInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePartnerInvoice_PayPartnerInvoiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PartnerInvoice_PayPartnerInvoiceMutation,
    PartnerInvoice_PayPartnerInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PartnerInvoice_PayPartnerInvoiceMutation,
    PartnerInvoice_PayPartnerInvoiceMutationVariables
  >(PartnerInvoice_PayPartnerInvoiceDocument, options);
}
export type PartnerInvoice_PayPartnerInvoiceMutationHookResult = ReturnType<
  typeof usePartnerInvoice_PayPartnerInvoiceMutation
>;
export type PartnerInvoice_PayPartnerInvoiceMutationResult =
  ApolloReactCommon.MutationResult<PartnerInvoice_PayPartnerInvoiceMutation>;
export type PartnerInvoice_PayPartnerInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  PartnerInvoice_PayPartnerInvoiceMutation,
  PartnerInvoice_PayPartnerInvoiceMutationVariables
>;
export const BillingOneTimePaymentReviewDocument = gql`
  query BillingOneTimePaymentReview($partnerId: Int!, $orderIds: [Int!]) {
    orders(ids: $orderIds, partnerId: $partnerId) {
      nodes {
        id
        startDate
        partnerProduct {
          id
          name
        }
        customer {
          id
          name
        }
        totalWholesaleAmount
        activeOrderContentStrategy {
          id
          topic
        }
      }
    }
  }
`;

/**
 * __useBillingOneTimePaymentReviewQuery__
 *
 * To run a query within a React component, call `useBillingOneTimePaymentReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingOneTimePaymentReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingOneTimePaymentReviewQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      orderIds: // value for 'orderIds'
 *   },
 * });
 */
export function useBillingOneTimePaymentReviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    BillingOneTimePaymentReviewQuery,
    BillingOneTimePaymentReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BillingOneTimePaymentReviewQuery, BillingOneTimePaymentReviewQueryVariables>(
    BillingOneTimePaymentReviewDocument,
    options
  );
}
export function useBillingOneTimePaymentReviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BillingOneTimePaymentReviewQuery,
    BillingOneTimePaymentReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BillingOneTimePaymentReviewQuery, BillingOneTimePaymentReviewQueryVariables>(
    BillingOneTimePaymentReviewDocument,
    options
  );
}
export type BillingOneTimePaymentReviewQueryHookResult = ReturnType<typeof useBillingOneTimePaymentReviewQuery>;
export type BillingOneTimePaymentReviewLazyQueryHookResult = ReturnType<typeof useBillingOneTimePaymentReviewLazyQuery>;
export type BillingOneTimePaymentReviewQueryResult = ApolloReactCommon.QueryResult<
  BillingOneTimePaymentReviewQuery,
  BillingOneTimePaymentReviewQueryVariables
>;
export const BillingOneTimePaymentDocument = gql`
  query BillingOneTimePayment($partnerId: Int!, $startDate: DateQuery) {
    partner(id: $partnerId) {
      id
      name
      billableOrders(startDate: $startDate) {
        nodes {
          id
          customer {
            id
            name
          }
          partnerProduct {
            id
            product {
              id
              name
            }
          }
          startDate
          totalWholesaleAmount
        }
      }
    }
  }
`;

/**
 * __useBillingOneTimePaymentQuery__
 *
 * To run a query within a React component, call `useBillingOneTimePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingOneTimePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingOneTimePaymentQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useBillingOneTimePaymentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<BillingOneTimePaymentQuery, BillingOneTimePaymentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BillingOneTimePaymentQuery, BillingOneTimePaymentQueryVariables>(
    BillingOneTimePaymentDocument,
    options
  );
}
export function useBillingOneTimePaymentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BillingOneTimePaymentQuery, BillingOneTimePaymentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BillingOneTimePaymentQuery, BillingOneTimePaymentQueryVariables>(
    BillingOneTimePaymentDocument,
    options
  );
}
export type BillingOneTimePaymentQueryHookResult = ReturnType<typeof useBillingOneTimePaymentQuery>;
export type BillingOneTimePaymentLazyQueryHookResult = ReturnType<typeof useBillingOneTimePaymentLazyQuery>;
export type BillingOneTimePaymentQueryResult = ApolloReactCommon.QueryResult<
  BillingOneTimePaymentQuery,
  BillingOneTimePaymentQueryVariables
>;
export const BillingOneTimePayment_CreatePartnerInvoiceDocument = gql`
  mutation BillingOneTimePayment_CreatePartnerInvoice($input: CreatePartnerInvoiceInput!) {
    createPartnerInvoice(input: $input) {
      ok
      error {
        message
      }
      partnerInvoice {
        id
      }
    }
  }
`;
export type BillingOneTimePayment_CreatePartnerInvoiceMutationFn = ApolloReactCommon.MutationFunction<
  BillingOneTimePayment_CreatePartnerInvoiceMutation,
  BillingOneTimePayment_CreatePartnerInvoiceMutationVariables
>;

/**
 * __useBillingOneTimePayment_CreatePartnerInvoiceMutation__
 *
 * To run a mutation, you first call `useBillingOneTimePayment_CreatePartnerInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingOneTimePayment_CreatePartnerInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingOneTimePaymentCreatePartnerInvoiceMutation, { data, loading, error }] = useBillingOneTimePayment_CreatePartnerInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillingOneTimePayment_CreatePartnerInvoiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BillingOneTimePayment_CreatePartnerInvoiceMutation,
    BillingOneTimePayment_CreatePartnerInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BillingOneTimePayment_CreatePartnerInvoiceMutation,
    BillingOneTimePayment_CreatePartnerInvoiceMutationVariables
  >(BillingOneTimePayment_CreatePartnerInvoiceDocument, options);
}
export type BillingOneTimePayment_CreatePartnerInvoiceMutationHookResult = ReturnType<
  typeof useBillingOneTimePayment_CreatePartnerInvoiceMutation
>;
export type BillingOneTimePayment_CreatePartnerInvoiceMutationResult =
  ApolloReactCommon.MutationResult<BillingOneTimePayment_CreatePartnerInvoiceMutation>;
export type BillingOneTimePayment_CreatePartnerInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BillingOneTimePayment_CreatePartnerInvoiceMutation,
  BillingOneTimePayment_CreatePartnerInvoiceMutationVariables
>;
export const BillingOneTimePayment_PayPartnerInvoiceDocument = gql`
  mutation BillingOneTimePayment_PayPartnerInvoice($input: PayPartnerInvoiceInput!) {
    payPartnerInvoice(input: $input) {
      ok
      error {
        message
      }
      wasPaymentSuccessful
    }
  }
`;
export type BillingOneTimePayment_PayPartnerInvoiceMutationFn = ApolloReactCommon.MutationFunction<
  BillingOneTimePayment_PayPartnerInvoiceMutation,
  BillingOneTimePayment_PayPartnerInvoiceMutationVariables
>;

/**
 * __useBillingOneTimePayment_PayPartnerInvoiceMutation__
 *
 * To run a mutation, you first call `useBillingOneTimePayment_PayPartnerInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBillingOneTimePayment_PayPartnerInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingOneTimePaymentPayPartnerInvoiceMutation, { data, loading, error }] = useBillingOneTimePayment_PayPartnerInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBillingOneTimePayment_PayPartnerInvoiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    BillingOneTimePayment_PayPartnerInvoiceMutation,
    BillingOneTimePayment_PayPartnerInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    BillingOneTimePayment_PayPartnerInvoiceMutation,
    BillingOneTimePayment_PayPartnerInvoiceMutationVariables
  >(BillingOneTimePayment_PayPartnerInvoiceDocument, options);
}
export type BillingOneTimePayment_PayPartnerInvoiceMutationHookResult = ReturnType<
  typeof useBillingOneTimePayment_PayPartnerInvoiceMutation
>;
export type BillingOneTimePayment_PayPartnerInvoiceMutationResult =
  ApolloReactCommon.MutationResult<BillingOneTimePayment_PayPartnerInvoiceMutation>;
export type BillingOneTimePayment_PayPartnerInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  BillingOneTimePayment_PayPartnerInvoiceMutation,
  BillingOneTimePayment_PayPartnerInvoiceMutationVariables
>;
export const BillingAutomaticPaymentsDocument = gql`
  query BillingAutomaticPayments($partnerId: Int!) {
    partner(id: $partnerId) {
      id
      name
      isAutopayEnabled
      autopayPartnerPaymentMethodId
      enableAutopayInformation {
        dueImmediatelyAmount
        nextBillingDate
        nextBillingAmount
      }
    }
  }
`;

/**
 * __useBillingAutomaticPaymentsQuery__
 *
 * To run a query within a React component, call `useBillingAutomaticPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingAutomaticPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingAutomaticPaymentsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useBillingAutomaticPaymentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<BillingAutomaticPaymentsQuery, BillingAutomaticPaymentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BillingAutomaticPaymentsQuery, BillingAutomaticPaymentsQueryVariables>(
    BillingAutomaticPaymentsDocument,
    options
  );
}
export function useBillingAutomaticPaymentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BillingAutomaticPaymentsQuery,
    BillingAutomaticPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BillingAutomaticPaymentsQuery, BillingAutomaticPaymentsQueryVariables>(
    BillingAutomaticPaymentsDocument,
    options
  );
}
export type BillingAutomaticPaymentsQueryHookResult = ReturnType<typeof useBillingAutomaticPaymentsQuery>;
export type BillingAutomaticPaymentsLazyQueryHookResult = ReturnType<typeof useBillingAutomaticPaymentsLazyQuery>;
export type BillingAutomaticPaymentsQueryResult = ApolloReactCommon.QueryResult<
  BillingAutomaticPaymentsQuery,
  BillingAutomaticPaymentsQueryVariables
>;
export const PartnerBillingContactsDocument = gql`
  query PartnerBillingContacts($partnerId: Int!) {
    partner(id: $partnerId) {
      id
      partnerBillingContacts {
        nodes {
          ...Billing_PartnerBillingContact
        }
      }
    }
  }
  ${Billing_PartnerBillingContactFragmentDoc}
`;

/**
 * __usePartnerBillingContactsQuery__
 *
 * To run a query within a React component, call `usePartnerBillingContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerBillingContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerBillingContactsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function usePartnerBillingContactsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PartnerBillingContactsQuery, PartnerBillingContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerBillingContactsQuery, PartnerBillingContactsQueryVariables>(
    PartnerBillingContactsDocument,
    options
  );
}
export function usePartnerBillingContactsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerBillingContactsQuery, PartnerBillingContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerBillingContactsQuery, PartnerBillingContactsQueryVariables>(
    PartnerBillingContactsDocument,
    options
  );
}
export type PartnerBillingContactsQueryHookResult = ReturnType<typeof usePartnerBillingContactsQuery>;
export type PartnerBillingContactsLazyQueryHookResult = ReturnType<typeof usePartnerBillingContactsLazyQuery>;
export type PartnerBillingContactsQueryResult = ApolloReactCommon.QueryResult<
  PartnerBillingContactsQuery,
  PartnerBillingContactsQueryVariables
>;
export const BillingOverviewDocument = gql`
  query BillingOverview($partnerId: Int!) {
    partner(id: $partnerId) {
      id
      name
      isUpfrontPaymentRequired
      isAutopayEnabled
      isBillingEnforced
      partnerInvoices {
        totalCount
        nodes {
          ...BillingOverview_PartnerInvoice
        }
      }
    }
  }
  ${BillingOverview_PartnerInvoiceFragmentDoc}
`;

/**
 * __useBillingOverviewQuery__
 *
 * To run a query within a React component, call `useBillingOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingOverviewQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useBillingOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<BillingOverviewQuery, BillingOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BillingOverviewQuery, BillingOverviewQueryVariables>(
    BillingOverviewDocument,
    options
  );
}
export function useBillingOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BillingOverviewQuery, BillingOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BillingOverviewQuery, BillingOverviewQueryVariables>(
    BillingOverviewDocument,
    options
  );
}
export type BillingOverviewQueryHookResult = ReturnType<typeof useBillingOverviewQuery>;
export type BillingOverviewLazyQueryHookResult = ReturnType<typeof useBillingOverviewLazyQuery>;
export type BillingOverviewQueryResult = ApolloReactCommon.QueryResult<
  BillingOverviewQuery,
  BillingOverviewQueryVariables
>;
export const DeletePartnerBillingContactDocument = gql`
  mutation DeletePartnerBillingContact($input: DeletePartnerBillingContactInput!) {
    deletePartnerBillingContact(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type DeletePartnerBillingContactMutationFn = ApolloReactCommon.MutationFunction<
  DeletePartnerBillingContactMutation,
  DeletePartnerBillingContactMutationVariables
>;

/**
 * __useDeletePartnerBillingContactMutation__
 *
 * To run a mutation, you first call `useDeletePartnerBillingContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnerBillingContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerBillingContactMutation, { data, loading, error }] = useDeletePartnerBillingContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePartnerBillingContactMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePartnerBillingContactMutation,
    DeletePartnerBillingContactMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeletePartnerBillingContactMutation,
    DeletePartnerBillingContactMutationVariables
  >(DeletePartnerBillingContactDocument, options);
}
export type DeletePartnerBillingContactMutationHookResult = ReturnType<typeof useDeletePartnerBillingContactMutation>;
export type DeletePartnerBillingContactMutationResult =
  ApolloReactCommon.MutationResult<DeletePartnerBillingContactMutation>;
export type DeletePartnerBillingContactMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePartnerBillingContactMutation,
  DeletePartnerBillingContactMutationVariables
>;
export const Billing_DeletePartnerPaymentMethodDocument = gql`
  mutation Billing_DeletePartnerPaymentMethod($input: DeletePartnerPaymentMethodInput!) {
    deletePartnerPaymentMethod(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type Billing_DeletePartnerPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<
  Billing_DeletePartnerPaymentMethodMutation,
  Billing_DeletePartnerPaymentMethodMutationVariables
>;

/**
 * __useBilling_DeletePartnerPaymentMethodMutation__
 *
 * To run a mutation, you first call `useBilling_DeletePartnerPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBilling_DeletePartnerPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingDeletePartnerPaymentMethodMutation, { data, loading, error }] = useBilling_DeletePartnerPaymentMethodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBilling_DeletePartnerPaymentMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Billing_DeletePartnerPaymentMethodMutation,
    Billing_DeletePartnerPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    Billing_DeletePartnerPaymentMethodMutation,
    Billing_DeletePartnerPaymentMethodMutationVariables
  >(Billing_DeletePartnerPaymentMethodDocument, options);
}
export type Billing_DeletePartnerPaymentMethodMutationHookResult = ReturnType<
  typeof useBilling_DeletePartnerPaymentMethodMutation
>;
export type Billing_DeletePartnerPaymentMethodMutationResult =
  ApolloReactCommon.MutationResult<Billing_DeletePartnerPaymentMethodMutation>;
export type Billing_DeletePartnerPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Billing_DeletePartnerPaymentMethodMutation,
  Billing_DeletePartnerPaymentMethodMutationVariables
>;
export const BillingPartnersDocument = gql`
  query BillingPartners {
    partners {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useBillingPartnersQuery__
 *
 * To run a query within a React component, call `useBillingPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingPartnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useBillingPartnersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<BillingPartnersQuery, BillingPartnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<BillingPartnersQuery, BillingPartnersQueryVariables>(
    BillingPartnersDocument,
    options
  );
}
export function useBillingPartnersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BillingPartnersQuery, BillingPartnersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<BillingPartnersQuery, BillingPartnersQueryVariables>(
    BillingPartnersDocument,
    options
  );
}
export type BillingPartnersQueryHookResult = ReturnType<typeof useBillingPartnersQuery>;
export type BillingPartnersLazyQueryHookResult = ReturnType<typeof useBillingPartnersLazyQuery>;
export type BillingPartnersQueryResult = ApolloReactCommon.QueryResult<
  BillingPartnersQuery,
  BillingPartnersQueryVariables
>;
export const PartnerPaymentMethodsDocument = gql`
  query PartnerPaymentMethods($partnerId: Int!) {
    partner(id: $partnerId) {
      id
      name
      partnerPaymentMethods {
        totalCount
        nodes {
          ...Billing_PartnerPaymentMethod
        }
      }
    }
  }
  ${Billing_PartnerPaymentMethodFragmentDoc}
`;

/**
 * __usePartnerPaymentMethodsQuery__
 *
 * To run a query within a React component, call `usePartnerPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerPaymentMethodsQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function usePartnerPaymentMethodsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PartnerPaymentMethodsQuery, PartnerPaymentMethodsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerPaymentMethodsQuery, PartnerPaymentMethodsQueryVariables>(
    PartnerPaymentMethodsDocument,
    options
  );
}
export function usePartnerPaymentMethodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerPaymentMethodsQuery, PartnerPaymentMethodsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerPaymentMethodsQuery, PartnerPaymentMethodsQueryVariables>(
    PartnerPaymentMethodsDocument,
    options
  );
}
export type PartnerPaymentMethodsQueryHookResult = ReturnType<typeof usePartnerPaymentMethodsQuery>;
export type PartnerPaymentMethodsLazyQueryHookResult = ReturnType<typeof usePartnerPaymentMethodsLazyQuery>;
export type PartnerPaymentMethodsQueryResult = ApolloReactCommon.QueryResult<
  PartnerPaymentMethodsQuery,
  PartnerPaymentMethodsQueryVariables
>;
export const RecordManualPaymentDocument = gql`
  mutation RecordManualPayment($input: RecordPartnerInvoicePaymentInput!) {
    recordPartnerInvoicePayment(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type RecordManualPaymentMutationFn = ApolloReactCommon.MutationFunction<
  RecordManualPaymentMutation,
  RecordManualPaymentMutationVariables
>;

/**
 * __useRecordManualPaymentMutation__
 *
 * To run a mutation, you first call `useRecordManualPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordManualPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordManualPaymentMutation, { data, loading, error }] = useRecordManualPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordManualPaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RecordManualPaymentMutation, RecordManualPaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RecordManualPaymentMutation, RecordManualPaymentMutationVariables>(
    RecordManualPaymentDocument,
    options
  );
}
export type RecordManualPaymentMutationHookResult = ReturnType<typeof useRecordManualPaymentMutation>;
export type RecordManualPaymentMutationResult = ApolloReactCommon.MutationResult<RecordManualPaymentMutation>;
export type RecordManualPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RecordManualPaymentMutation,
  RecordManualPaymentMutationVariables
>;
export const Billing_UpdatePartnerDocument = gql`
  mutation Billing_UpdatePartner($input: UpdatePartnerInput!) {
    updatePartner(input: $input) {
      partner {
        id
      }
    }
  }
`;
export type Billing_UpdatePartnerMutationFn = ApolloReactCommon.MutationFunction<
  Billing_UpdatePartnerMutation,
  Billing_UpdatePartnerMutationVariables
>;

/**
 * __useBilling_UpdatePartnerMutation__
 *
 * To run a mutation, you first call `useBilling_UpdatePartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBilling_UpdatePartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [billingUpdatePartnerMutation, { data, loading, error }] = useBilling_UpdatePartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBilling_UpdatePartnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    Billing_UpdatePartnerMutation,
    Billing_UpdatePartnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<Billing_UpdatePartnerMutation, Billing_UpdatePartnerMutationVariables>(
    Billing_UpdatePartnerDocument,
    options
  );
}
export type Billing_UpdatePartnerMutationHookResult = ReturnType<typeof useBilling_UpdatePartnerMutation>;
export type Billing_UpdatePartnerMutationResult = ApolloReactCommon.MutationResult<Billing_UpdatePartnerMutation>;
export type Billing_UpdatePartnerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  Billing_UpdatePartnerMutation,
  Billing_UpdatePartnerMutationVariables
>;
export const SaveClientBillingConfigDocument = gql`
  mutation SaveClientBillingConfig($input: SaveCustomerBillingConfigInput!) {
    saveCustomerBillingConfig(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type SaveClientBillingConfigMutationFn = ApolloReactCommon.MutationFunction<
  SaveClientBillingConfigMutation,
  SaveClientBillingConfigMutationVariables
>;

/**
 * __useSaveClientBillingConfigMutation__
 *
 * To run a mutation, you first call `useSaveClientBillingConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveClientBillingConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveClientBillingConfigMutation, { data, loading, error }] = useSaveClientBillingConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveClientBillingConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveClientBillingConfigMutation,
    SaveClientBillingConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveClientBillingConfigMutation, SaveClientBillingConfigMutationVariables>(
    SaveClientBillingConfigDocument,
    options
  );
}
export type SaveClientBillingConfigMutationHookResult = ReturnType<typeof useSaveClientBillingConfigMutation>;
export type SaveClientBillingConfigMutationResult = ApolloReactCommon.MutationResult<SaveClientBillingConfigMutation>;
export type SaveClientBillingConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveClientBillingConfigMutation,
  SaveClientBillingConfigMutationVariables
>;
export const ClientDetailsBillingConfigDocument = gql`
  query ClientDetailsBillingConfig($customerId: Int!) {
    customer(id: $customerId) {
      id
      customerBillingConfig {
        id
        isBillingEnforced
        isUpfrontPaymentRequired
      }
    }
  }
`;

/**
 * __useClientDetailsBillingConfigQuery__
 *
 * To run a query within a React component, call `useClientDetailsBillingConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsBillingConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsBillingConfigQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useClientDetailsBillingConfigQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ClientDetailsBillingConfigQuery,
    ClientDetailsBillingConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientDetailsBillingConfigQuery, ClientDetailsBillingConfigQueryVariables>(
    ClientDetailsBillingConfigDocument,
    options
  );
}
export function useClientDetailsBillingConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ClientDetailsBillingConfigQuery,
    ClientDetailsBillingConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClientDetailsBillingConfigQuery, ClientDetailsBillingConfigQueryVariables>(
    ClientDetailsBillingConfigDocument,
    options
  );
}
export type ClientDetailsBillingConfigQueryHookResult = ReturnType<typeof useClientDetailsBillingConfigQuery>;
export type ClientDetailsBillingConfigLazyQueryHookResult = ReturnType<typeof useClientDetailsBillingConfigLazyQuery>;
export type ClientDetailsBillingConfigQueryResult = ApolloReactCommon.QueryResult<
  ClientDetailsBillingConfigQuery,
  ClientDetailsBillingConfigQueryVariables
>;
export const CampaignInvoiceDocument = gql`
  query CampaignInvoice($invoiceId: Int!, $customerId: Int!) {
    customerCampaignInvoice(id: $invoiceId) {
      id
      invoiceDate
      invoiceData {
        totalChargesAmount
        totalDue
        totalPaymentsAmount
        invoiceCharges {
          id
          amount
          status {
            id
            name
            description
          }
          customerCampaign {
            id
            name
          }
          monthIndex
        }
        invoicePayments {
          id
          status {
            id
            name
            description
          }
          createdAt
          amount
          processor
          processorReferenceId
        }
      }
    }
    customer(id: $customerId) {
      id
      name
      status {
        id
      }
      partner {
        emailLogoFile {
          id
          url
        }
      }
    }
  }
`;

/**
 * __useCampaignInvoiceQuery__
 *
 * To run a query within a React component, call `useCampaignInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignInvoiceQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCampaignInvoiceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CampaignInvoiceQuery, CampaignInvoiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CampaignInvoiceQuery, CampaignInvoiceQueryVariables>(
    CampaignInvoiceDocument,
    options
  );
}
export function useCampaignInvoiceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CampaignInvoiceQuery, CampaignInvoiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CampaignInvoiceQuery, CampaignInvoiceQueryVariables>(
    CampaignInvoiceDocument,
    options
  );
}
export type CampaignInvoiceQueryHookResult = ReturnType<typeof useCampaignInvoiceQuery>;
export type CampaignInvoiceLazyQueryHookResult = ReturnType<typeof useCampaignInvoiceLazyQuery>;
export type CampaignInvoiceQueryResult = ApolloReactCommon.QueryResult<
  CampaignInvoiceQuery,
  CampaignInvoiceQueryVariables
>;
export const DeleteCustomerCampaignInvoiceDocument = gql`
  mutation DeleteCustomerCampaignInvoice($input: DeleteCustomerCampaignInvoiceInput!) {
    deleteCustomerCampaignInvoice(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type DeleteCustomerCampaignInvoiceMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCustomerCampaignInvoiceMutation,
  DeleteCustomerCampaignInvoiceMutationVariables
>;

/**
 * __useDeleteCustomerCampaignInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerCampaignInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerCampaignInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerCampaignInvoiceMutation, { data, loading, error }] = useDeleteCustomerCampaignInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerCampaignInvoiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCustomerCampaignInvoiceMutation,
    DeleteCustomerCampaignInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteCustomerCampaignInvoiceMutation,
    DeleteCustomerCampaignInvoiceMutationVariables
  >(DeleteCustomerCampaignInvoiceDocument, options);
}
export type DeleteCustomerCampaignInvoiceMutationHookResult = ReturnType<
  typeof useDeleteCustomerCampaignInvoiceMutation
>;
export type DeleteCustomerCampaignInvoiceMutationResult =
  ApolloReactCommon.MutationResult<DeleteCustomerCampaignInvoiceMutation>;
export type DeleteCustomerCampaignInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCustomerCampaignInvoiceMutation,
  DeleteCustomerCampaignInvoiceMutationVariables
>;
export const ClientDetailsClientCampaignsDocument = gql`
  query ClientDetailsClientCampaigns($clientId: Int!, $campaignCategoryId: String) {
    customer(id: $clientId) {
      id
      status {
        id
        name
      }
      customerCampaigns(campaignCategoryId: $campaignCategoryId) {
        nodes {
          ...ClientDetailsClientCampaign
        }
      }
    }
  }
  ${ClientDetailsClientCampaignFragmentDoc}
`;

/**
 * __useClientDetailsClientCampaignsQuery__
 *
 * To run a query within a React component, call `useClientDetailsClientCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsClientCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsClientCampaignsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      campaignCategoryId: // value for 'campaignCategoryId'
 *   },
 * });
 */
export function useClientDetailsClientCampaignsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ClientDetailsClientCampaignsQuery,
    ClientDetailsClientCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientDetailsClientCampaignsQuery, ClientDetailsClientCampaignsQueryVariables>(
    ClientDetailsClientCampaignsDocument,
    options
  );
}
export function useClientDetailsClientCampaignsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ClientDetailsClientCampaignsQuery,
    ClientDetailsClientCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClientDetailsClientCampaignsQuery, ClientDetailsClientCampaignsQueryVariables>(
    ClientDetailsClientCampaignsDocument,
    options
  );
}
export type ClientDetailsClientCampaignsQueryHookResult = ReturnType<typeof useClientDetailsClientCampaignsQuery>;
export type ClientDetailsClientCampaignsLazyQueryHookResult = ReturnType<
  typeof useClientDetailsClientCampaignsLazyQuery
>;
export type ClientDetailsClientCampaignsQueryResult = ApolloReactCommon.QueryResult<
  ClientDetailsClientCampaignsQuery,
  ClientDetailsClientCampaignsQueryVariables
>;
export const DeleteClientCampaignDocument = gql`
  mutation DeleteClientCampaign($input: DeleteCustomerCampaignInput!) {
    deleteCustomerCampaign(input: $input) {
      isDeleted
    }
  }
`;
export type DeleteClientCampaignMutationFn = ApolloReactCommon.MutationFunction<
  DeleteClientCampaignMutation,
  DeleteClientCampaignMutationVariables
>;

/**
 * __useDeleteClientCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteClientCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientCampaignMutation, { data, loading, error }] = useDeleteClientCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClientCampaignMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteClientCampaignMutation,
    DeleteClientCampaignMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeleteClientCampaignMutation, DeleteClientCampaignMutationVariables>(
    DeleteClientCampaignDocument,
    options
  );
}
export type DeleteClientCampaignMutationHookResult = ReturnType<typeof useDeleteClientCampaignMutation>;
export type DeleteClientCampaignMutationResult = ApolloReactCommon.MutationResult<DeleteClientCampaignMutation>;
export type DeleteClientCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteClientCampaignMutation,
  DeleteClientCampaignMutationVariables
>;
export const ClientDetailsEmailsDocument = gql`
  query ClientDetailsEmails($customerId: Int!) {
    customer(id: $customerId) {
      id
      emailMessages {
        totalCount
        nodes {
          id
          createdAt
          sentAt
          toName
          toAddress
          fromName
          fromAddress
          subject
        }
      }
    }
  }
`;

/**
 * __useClientDetailsEmailsQuery__
 *
 * To run a query within a React component, call `useClientDetailsEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsEmailsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useClientDetailsEmailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ClientDetailsEmailsQuery, ClientDetailsEmailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientDetailsEmailsQuery, ClientDetailsEmailsQueryVariables>(
    ClientDetailsEmailsDocument,
    options
  );
}
export function useClientDetailsEmailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientDetailsEmailsQuery, ClientDetailsEmailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClientDetailsEmailsQuery, ClientDetailsEmailsQueryVariables>(
    ClientDetailsEmailsDocument,
    options
  );
}
export type ClientDetailsEmailsQueryHookResult = ReturnType<typeof useClientDetailsEmailsQuery>;
export type ClientDetailsEmailsLazyQueryHookResult = ReturnType<typeof useClientDetailsEmailsLazyQuery>;
export type ClientDetailsEmailsQueryResult = ApolloReactCommon.QueryResult<
  ClientDetailsEmailsQuery,
  ClientDetailsEmailsQueryVariables
>;
export const ClientDetailsInvoiceCreateDocument = gql`
  query ClientDetailsInvoiceCreate($customerId: Int!, $startDate: DateQuery) {
    customer(id: $customerId) {
      id
      billableOrders(startDate: $startDate) {
        totalCount
        nodes {
          id
          partnerProduct {
            id
            name
          }
          startDate
          totalRetailAmount
        }
      }
    }
  }
`;

/**
 * __useClientDetailsInvoiceCreateQuery__
 *
 * To run a query within a React component, call `useClientDetailsInvoiceCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsInvoiceCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsInvoiceCreateQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useClientDetailsInvoiceCreateQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ClientDetailsInvoiceCreateQuery,
    ClientDetailsInvoiceCreateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientDetailsInvoiceCreateQuery, ClientDetailsInvoiceCreateQueryVariables>(
    ClientDetailsInvoiceCreateDocument,
    options
  );
}
export function useClientDetailsInvoiceCreateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ClientDetailsInvoiceCreateQuery,
    ClientDetailsInvoiceCreateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClientDetailsInvoiceCreateQuery, ClientDetailsInvoiceCreateQueryVariables>(
    ClientDetailsInvoiceCreateDocument,
    options
  );
}
export type ClientDetailsInvoiceCreateQueryHookResult = ReturnType<typeof useClientDetailsInvoiceCreateQuery>;
export type ClientDetailsInvoiceCreateLazyQueryHookResult = ReturnType<typeof useClientDetailsInvoiceCreateLazyQuery>;
export type ClientDetailsInvoiceCreateQueryResult = ApolloReactCommon.QueryResult<
  ClientDetailsInvoiceCreateQuery,
  ClientDetailsInvoiceCreateQueryVariables
>;
export const CreateCustomerInvoiceDocument = gql`
  mutation CreateCustomerInvoice($input: CreateCustomerInvoiceInput!) {
    createCustomerInvoice(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type CreateCustomerInvoiceMutationFn = ApolloReactCommon.MutationFunction<
  CreateCustomerInvoiceMutation,
  CreateCustomerInvoiceMutationVariables
>;

/**
 * __useCreateCustomerInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateCustomerInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerInvoiceMutation, { data, loading, error }] = useCreateCustomerInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerInvoiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCustomerInvoiceMutation,
    CreateCustomerInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCustomerInvoiceMutation, CreateCustomerInvoiceMutationVariables>(
    CreateCustomerInvoiceDocument,
    options
  );
}
export type CreateCustomerInvoiceMutationHookResult = ReturnType<typeof useCreateCustomerInvoiceMutation>;
export type CreateCustomerInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateCustomerInvoiceMutation>;
export type CreateCustomerInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCustomerInvoiceMutation,
  CreateCustomerInvoiceMutationVariables
>;
export const ClientDetailsInvoiceDocument = gql`
  query ClientDetailsInvoice($invoiceId: Int!) {
    customerInvoice(id: $invoiceId) {
      id
      invoiceDate
      invoiceData {
        totalChargesAmount
        totalDue
        totalPaymentsAmount
        invoiceCharges {
          id
          status {
            id
            name
            description
          }
          order {
            id
            startDate
            customerBillingStatus {
              id
              name
              description
            }
          }
          amount
        }
        invoicePayments {
          id
          status {
            id
            name
            description
          }
          createdAt
          amount
          processor
          processorReferenceId
        }
      }
      customer {
        id
        name
        customerBillingConfig {
          isBillingEnforced
        }
        partner {
          id
          name
          reportingLogoFile {
            id
            url
          }
        }
      }
    }
  }
`;

/**
 * __useClientDetailsInvoiceQuery__
 *
 * To run a query within a React component, call `useClientDetailsInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsInvoiceQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useClientDetailsInvoiceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ClientDetailsInvoiceQuery, ClientDetailsInvoiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientDetailsInvoiceQuery, ClientDetailsInvoiceQueryVariables>(
    ClientDetailsInvoiceDocument,
    options
  );
}
export function useClientDetailsInvoiceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientDetailsInvoiceQuery, ClientDetailsInvoiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClientDetailsInvoiceQuery, ClientDetailsInvoiceQueryVariables>(
    ClientDetailsInvoiceDocument,
    options
  );
}
export type ClientDetailsInvoiceQueryHookResult = ReturnType<typeof useClientDetailsInvoiceQuery>;
export type ClientDetailsInvoiceLazyQueryHookResult = ReturnType<typeof useClientDetailsInvoiceLazyQuery>;
export type ClientDetailsInvoiceQueryResult = ApolloReactCommon.QueryResult<
  ClientDetailsInvoiceQuery,
  ClientDetailsInvoiceQueryVariables
>;
export const ClientDetailsInvoicesDocument = gql`
  query ClientDetailsInvoices(
    $customerId: Int!
    $limit: Int
    $offset: Int
    $search: String
    $statusIds: [String]
    $sort: CustomerInvoiceSort
  ) {
    customer(id: $customerId) {
      id
      customerInvoices(limit: $limit, offset: $offset, search: $search, sort: $sort, statusIds: $statusIds) {
        totalCount
        nodes {
          ...ClientDetailsInvoice_CustomerInvoice
        }
      }
    }
  }
  ${ClientDetailsInvoice_CustomerInvoiceFragmentDoc}
`;

/**
 * __useClientDetailsInvoicesQuery__
 *
 * To run a query within a React component, call `useClientDetailsInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsInvoicesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      statusIds: // value for 'statusIds'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useClientDetailsInvoicesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ClientDetailsInvoicesQuery, ClientDetailsInvoicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientDetailsInvoicesQuery, ClientDetailsInvoicesQueryVariables>(
    ClientDetailsInvoicesDocument,
    options
  );
}
export function useClientDetailsInvoicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientDetailsInvoicesQuery, ClientDetailsInvoicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClientDetailsInvoicesQuery, ClientDetailsInvoicesQueryVariables>(
    ClientDetailsInvoicesDocument,
    options
  );
}
export type ClientDetailsInvoicesQueryHookResult = ReturnType<typeof useClientDetailsInvoicesQuery>;
export type ClientDetailsInvoicesLazyQueryHookResult = ReturnType<typeof useClientDetailsInvoicesLazyQuery>;
export type ClientDetailsInvoicesQueryResult = ApolloReactCommon.QueryResult<
  ClientDetailsInvoicesQuery,
  ClientDetailsInvoicesQueryVariables
>;
export const RecordCustomerInvoiceManualPaymentDocument = gql`
  mutation RecordCustomerInvoiceManualPayment($input: RecordCustomerInvoicePaymentInput!) {
    recordCustomerInvoicePayment(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type RecordCustomerInvoiceManualPaymentMutationFn = ApolloReactCommon.MutationFunction<
  RecordCustomerInvoiceManualPaymentMutation,
  RecordCustomerInvoiceManualPaymentMutationVariables
>;

/**
 * __useRecordCustomerInvoiceManualPaymentMutation__
 *
 * To run a mutation, you first call `useRecordCustomerInvoiceManualPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordCustomerInvoiceManualPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordCustomerInvoiceManualPaymentMutation, { data, loading, error }] = useRecordCustomerInvoiceManualPaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordCustomerInvoiceManualPaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RecordCustomerInvoiceManualPaymentMutation,
    RecordCustomerInvoiceManualPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RecordCustomerInvoiceManualPaymentMutation,
    RecordCustomerInvoiceManualPaymentMutationVariables
  >(RecordCustomerInvoiceManualPaymentDocument, options);
}
export type RecordCustomerInvoiceManualPaymentMutationHookResult = ReturnType<
  typeof useRecordCustomerInvoiceManualPaymentMutation
>;
export type RecordCustomerInvoiceManualPaymentMutationResult =
  ApolloReactCommon.MutationResult<RecordCustomerInvoiceManualPaymentMutation>;
export type RecordCustomerInvoiceManualPaymentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RecordCustomerInvoiceManualPaymentMutation,
  RecordCustomerInvoiceManualPaymentMutationVariables
>;
export const UpdateClientKeywordDetailsDocument = gql`
  mutation UpdateClientKeywordDetails($input: UpdateCustomerKeywordInput!) {
    updateCustomerKeyword(input: $input) {
      customerKeyword {
        id
      }
    }
  }
`;
export type UpdateClientKeywordDetailsMutationFn = ApolloReactCommon.MutationFunction<
  UpdateClientKeywordDetailsMutation,
  UpdateClientKeywordDetailsMutationVariables
>;

/**
 * __useUpdateClientKeywordDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateClientKeywordDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientKeywordDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientKeywordDetailsMutation, { data, loading, error }] = useUpdateClientKeywordDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientKeywordDetailsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateClientKeywordDetailsMutation,
    UpdateClientKeywordDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateClientKeywordDetailsMutation, UpdateClientKeywordDetailsMutationVariables>(
    UpdateClientKeywordDetailsDocument,
    options
  );
}
export type UpdateClientKeywordDetailsMutationHookResult = ReturnType<typeof useUpdateClientKeywordDetailsMutation>;
export type UpdateClientKeywordDetailsMutationResult =
  ApolloReactCommon.MutationResult<UpdateClientKeywordDetailsMutation>;
export type UpdateClientKeywordDetailsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateClientKeywordDetailsMutation,
  UpdateClientKeywordDetailsMutationVariables
>;
export const ClientDetailsKeywordDocument = gql`
  query ClientDetailsKeyword($id: Int!) {
    customerKeyword(id: $id) {
      id
      name
      location
      country
      landingPageUrl
      isActive
      isPrimary
    }
  }
`;

/**
 * __useClientDetailsKeywordQuery__
 *
 * To run a query within a React component, call `useClientDetailsKeywordQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsKeywordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsKeywordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientDetailsKeywordQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ClientDetailsKeywordQuery, ClientDetailsKeywordQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientDetailsKeywordQuery, ClientDetailsKeywordQueryVariables>(
    ClientDetailsKeywordDocument,
    options
  );
}
export function useClientDetailsKeywordLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientDetailsKeywordQuery, ClientDetailsKeywordQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClientDetailsKeywordQuery, ClientDetailsKeywordQueryVariables>(
    ClientDetailsKeywordDocument,
    options
  );
}
export type ClientDetailsKeywordQueryHookResult = ReturnType<typeof useClientDetailsKeywordQuery>;
export type ClientDetailsKeywordLazyQueryHookResult = ReturnType<typeof useClientDetailsKeywordLazyQuery>;
export type ClientDetailsKeywordQueryResult = ApolloReactCommon.QueryResult<
  ClientDetailsKeywordQuery,
  ClientDetailsKeywordQueryVariables
>;
export const CustomerKeywordsDocument = gql`
  query CustomerKeywords(
    $customerId: Int!
    $includeInactive: Boolean
    $searchEngine: String!
    $isTrackingMapRank: Boolean
  ) {
    customer(id: $customerId) {
      id
      hasMapRanking
      customerKeywords(includeInactive: $includeInactive, isTrackingMapRank: $isTrackingMapRank) {
        totalCount
        nodes {
          ...CustomerKeywordDetails
        }
      }
    }
  }
  ${CustomerKeywordDetailsFragmentDoc}
`;

/**
 * __useCustomerKeywordsQuery__
 *
 * To run a query within a React component, call `useCustomerKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerKeywordsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      includeInactive: // value for 'includeInactive'
 *      searchEngine: // value for 'searchEngine'
 *      isTrackingMapRank: // value for 'isTrackingMapRank'
 *   },
 * });
 */
export function useCustomerKeywordsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CustomerKeywordsQuery, CustomerKeywordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerKeywordsQuery, CustomerKeywordsQueryVariables>(
    CustomerKeywordsDocument,
    options
  );
}
export function useCustomerKeywordsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerKeywordsQuery, CustomerKeywordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerKeywordsQuery, CustomerKeywordsQueryVariables>(
    CustomerKeywordsDocument,
    options
  );
}
export type CustomerKeywordsQueryHookResult = ReturnType<typeof useCustomerKeywordsQuery>;
export type CustomerKeywordsLazyQueryHookResult = ReturnType<typeof useCustomerKeywordsLazyQuery>;
export type CustomerKeywordsQueryResult = ApolloReactCommon.QueryResult<
  CustomerKeywordsQuery,
  CustomerKeywordsQueryVariables
>;
export const KeywordRankingsDocument = gql`
  query KeywordRankings($customerKeywordId: Int!, $createdAt: DateQuery, $searchEngine: String!) {
    customerKeywordRankings(
      customerKeywordId: $customerKeywordId
      createdAt: $createdAt
      sort: DATE_ASC
      searchEngine: $searchEngine
    ) {
      nodes {
        id
        createdAt
        rank
      }
    }
  }
`;

/**
 * __useKeywordRankingsQuery__
 *
 * To run a query within a React component, call `useKeywordRankingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordRankingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordRankingsQuery({
 *   variables: {
 *      customerKeywordId: // value for 'customerKeywordId'
 *      createdAt: // value for 'createdAt'
 *      searchEngine: // value for 'searchEngine'
 *   },
 * });
 */
export function useKeywordRankingsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<KeywordRankingsQuery, KeywordRankingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<KeywordRankingsQuery, KeywordRankingsQueryVariables>(
    KeywordRankingsDocument,
    options
  );
}
export function useKeywordRankingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KeywordRankingsQuery, KeywordRankingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<KeywordRankingsQuery, KeywordRankingsQueryVariables>(
    KeywordRankingsDocument,
    options
  );
}
export type KeywordRankingsQueryHookResult = ReturnType<typeof useKeywordRankingsQuery>;
export type KeywordRankingsLazyQueryHookResult = ReturnType<typeof useKeywordRankingsLazyQuery>;
export type KeywordRankingsQueryResult = ApolloReactCommon.QueryResult<
  KeywordRankingsQuery,
  KeywordRankingsQueryVariables
>;
export const UpdateCustomerKeywordDocument = gql`
  mutation UpdateCustomerKeyword($input: UpdateCustomerKeywordInput!) {
    updateCustomerKeyword(input: $input) {
      customerKeyword {
        id
        name
        location
        landingPageUrl
        instructions
        customerId
        isActive
        isPrimary
        isTrackingRank
      }
    }
  }
`;
export type UpdateCustomerKeywordMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCustomerKeywordMutation,
  UpdateCustomerKeywordMutationVariables
>;

/**
 * __useUpdateCustomerKeywordMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerKeywordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerKeywordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerKeywordMutation, { data, loading, error }] = useUpdateCustomerKeywordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerKeywordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCustomerKeywordMutation,
    UpdateCustomerKeywordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCustomerKeywordMutation, UpdateCustomerKeywordMutationVariables>(
    UpdateCustomerKeywordDocument,
    options
  );
}
export type UpdateCustomerKeywordMutationHookResult = ReturnType<typeof useUpdateCustomerKeywordMutation>;
export type UpdateCustomerKeywordMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerKeywordMutation>;
export type UpdateCustomerKeywordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCustomerKeywordMutation,
  UpdateCustomerKeywordMutationVariables
>;
export const GetClientLocationGoogleReviewsDocument = gql`
  query GetClientLocationGoogleReviews($account: String!, $location: String!, $pageToken: String) {
    getGoogleReviews(account: $account, location: $location, pageToken: $pageToken) {
      reviews {
        ...LocationReview
      }
      nextPageToken
      totalReviewCount
      averageRating
    }
  }
  ${LocationReviewFragmentDoc}
`;

/**
 * __useGetClientLocationGoogleReviewsQuery__
 *
 * To run a query within a React component, call `useGetClientLocationGoogleReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientLocationGoogleReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientLocationGoogleReviewsQuery({
 *   variables: {
 *      account: // value for 'account'
 *      location: // value for 'location'
 *      pageToken: // value for 'pageToken'
 *   },
 * });
 */
export function useGetClientLocationGoogleReviewsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetClientLocationGoogleReviewsQuery,
    GetClientLocationGoogleReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetClientLocationGoogleReviewsQuery, GetClientLocationGoogleReviewsQueryVariables>(
    GetClientLocationGoogleReviewsDocument,
    options
  );
}
export function useGetClientLocationGoogleReviewsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetClientLocationGoogleReviewsQuery,
    GetClientLocationGoogleReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetClientLocationGoogleReviewsQuery,
    GetClientLocationGoogleReviewsQueryVariables
  >(GetClientLocationGoogleReviewsDocument, options);
}
export type GetClientLocationGoogleReviewsQueryHookResult = ReturnType<typeof useGetClientLocationGoogleReviewsQuery>;
export type GetClientLocationGoogleReviewsLazyQueryHookResult = ReturnType<
  typeof useGetClientLocationGoogleReviewsLazyQuery
>;
export type GetClientLocationGoogleReviewsQueryResult = ApolloReactCommon.QueryResult<
  GetClientLocationGoogleReviewsQuery,
  GetClientLocationGoogleReviewsQueryVariables
>;
export const GetClientGeminiReplyCommentDocument = gql`
  query GetClientGeminiReplyComment($comment: String!, $keywords: String!) {
    geminiReplyComment(comment: $comment, keywords: $keywords) {
      reply
    }
  }
`;

/**
 * __useGetClientGeminiReplyCommentQuery__
 *
 * To run a query within a React component, call `useGetClientGeminiReplyCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientGeminiReplyCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientGeminiReplyCommentQuery({
 *   variables: {
 *      comment: // value for 'comment'
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useGetClientGeminiReplyCommentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetClientGeminiReplyCommentQuery,
    GetClientGeminiReplyCommentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetClientGeminiReplyCommentQuery, GetClientGeminiReplyCommentQueryVariables>(
    GetClientGeminiReplyCommentDocument,
    options
  );
}
export function useGetClientGeminiReplyCommentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetClientGeminiReplyCommentQuery,
    GetClientGeminiReplyCommentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetClientGeminiReplyCommentQuery, GetClientGeminiReplyCommentQueryVariables>(
    GetClientGeminiReplyCommentDocument,
    options
  );
}
export type GetClientGeminiReplyCommentQueryHookResult = ReturnType<typeof useGetClientGeminiReplyCommentQuery>;
export type GetClientGeminiReplyCommentLazyQueryHookResult = ReturnType<typeof useGetClientGeminiReplyCommentLazyQuery>;
export type GetClientGeminiReplyCommentQueryResult = ApolloReactCommon.QueryResult<
  GetClientGeminiReplyCommentQuery,
  GetClientGeminiReplyCommentQueryVariables
>;
export const ClientDetailsLocationsInReviewDocument = gql`
  query ClientDetailsLocationsInReview($id: Int!) {
    customer(id: $id) {
      id
      googleAccountId
      customerBusinessListingSpecs {
        nodes {
          id
          address
          city
          googleLocationId
        }
      }
    }
  }
`;

/**
 * __useClientDetailsLocationsInReviewQuery__
 *
 * To run a query within a React component, call `useClientDetailsLocationsInReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsLocationsInReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsLocationsInReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientDetailsLocationsInReviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ClientDetailsLocationsInReviewQuery,
    ClientDetailsLocationsInReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientDetailsLocationsInReviewQuery, ClientDetailsLocationsInReviewQueryVariables>(
    ClientDetailsLocationsInReviewDocument,
    options
  );
}
export function useClientDetailsLocationsInReviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ClientDetailsLocationsInReviewQuery,
    ClientDetailsLocationsInReviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ClientDetailsLocationsInReviewQuery,
    ClientDetailsLocationsInReviewQueryVariables
  >(ClientDetailsLocationsInReviewDocument, options);
}
export type ClientDetailsLocationsInReviewQueryHookResult = ReturnType<typeof useClientDetailsLocationsInReviewQuery>;
export type ClientDetailsLocationsInReviewLazyQueryHookResult = ReturnType<
  typeof useClientDetailsLocationsInReviewLazyQuery
>;
export type ClientDetailsLocationsInReviewQueryResult = ApolloReactCommon.QueryResult<
  ClientDetailsLocationsInReviewQuery,
  ClientDetailsLocationsInReviewQueryVariables
>;
export const LoginGoogleClientReviewDocument = gql`
  mutation LoginGoogleClientReview {
    loginGoogleService {
      ok
      url
    }
  }
`;
export type LoginGoogleClientReviewMutationFn = ApolloReactCommon.MutationFunction<
  LoginGoogleClientReviewMutation,
  LoginGoogleClientReviewMutationVariables
>;

/**
 * __useLoginGoogleClientReviewMutation__
 *
 * To run a mutation, you first call `useLoginGoogleClientReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginGoogleClientReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginGoogleClientReviewMutation, { data, loading, error }] = useLoginGoogleClientReviewMutation({
 *   variables: {
 *   },
 * });
 */
export function useLoginGoogleClientReviewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginGoogleClientReviewMutation,
    LoginGoogleClientReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginGoogleClientReviewMutation, LoginGoogleClientReviewMutationVariables>(
    LoginGoogleClientReviewDocument,
    options
  );
}
export type LoginGoogleClientReviewMutationHookResult = ReturnType<typeof useLoginGoogleClientReviewMutation>;
export type LoginGoogleClientReviewMutationResult = ApolloReactCommon.MutationResult<LoginGoogleClientReviewMutation>;
export type LoginGoogleClientReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginGoogleClientReviewMutation,
  LoginGoogleClientReviewMutationVariables
>;
export const LoginGoogleCallbackClientReviewDocument = gql`
  mutation LoginGoogleCallbackClientReview($input: LoginGoogleCallbackInput) {
    loginGoogleCallback(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type LoginGoogleCallbackClientReviewMutationFn = ApolloReactCommon.MutationFunction<
  LoginGoogleCallbackClientReviewMutation,
  LoginGoogleCallbackClientReviewMutationVariables
>;

/**
 * __useLoginGoogleCallbackClientReviewMutation__
 *
 * To run a mutation, you first call `useLoginGoogleCallbackClientReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginGoogleCallbackClientReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginGoogleCallbackClientReviewMutation, { data, loading, error }] = useLoginGoogleCallbackClientReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginGoogleCallbackClientReviewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginGoogleCallbackClientReviewMutation,
    LoginGoogleCallbackClientReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    LoginGoogleCallbackClientReviewMutation,
    LoginGoogleCallbackClientReviewMutationVariables
  >(LoginGoogleCallbackClientReviewDocument, options);
}
export type LoginGoogleCallbackClientReviewMutationHookResult = ReturnType<
  typeof useLoginGoogleCallbackClientReviewMutation
>;
export type LoginGoogleCallbackClientReviewMutationResult =
  ApolloReactCommon.MutationResult<LoginGoogleCallbackClientReviewMutation>;
export type LoginGoogleCallbackClientReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginGoogleCallbackClientReviewMutation,
  LoginGoogleCallbackClientReviewMutationVariables
>;
export const GoogleReviewReplyClientReviewDocument = gql`
  mutation GoogleReviewReplyClientReview($input: GoogleReviewReplyInput!) {
    googleReviewReply(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type GoogleReviewReplyClientReviewMutationFn = ApolloReactCommon.MutationFunction<
  GoogleReviewReplyClientReviewMutation,
  GoogleReviewReplyClientReviewMutationVariables
>;

/**
 * __useGoogleReviewReplyClientReviewMutation__
 *
 * To run a mutation, you first call `useGoogleReviewReplyClientReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleReviewReplyClientReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleReviewReplyClientReviewMutation, { data, loading, error }] = useGoogleReviewReplyClientReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGoogleReviewReplyClientReviewMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GoogleReviewReplyClientReviewMutation,
    GoogleReviewReplyClientReviewMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GoogleReviewReplyClientReviewMutation,
    GoogleReviewReplyClientReviewMutationVariables
  >(GoogleReviewReplyClientReviewDocument, options);
}
export type GoogleReviewReplyClientReviewMutationHookResult = ReturnType<
  typeof useGoogleReviewReplyClientReviewMutation
>;
export type GoogleReviewReplyClientReviewMutationResult =
  ApolloReactCommon.MutationResult<GoogleReviewReplyClientReviewMutation>;
export type GoogleReviewReplyClientReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GoogleReviewReplyClientReviewMutation,
  GoogleReviewReplyClientReviewMutationVariables
>;
export const UpdateCustomerBusinessListingSpecDocument = gql`
  mutation UpdateCustomerBusinessListingSpec($input: UpdateCustomerBusinessListingSpecInput!) {
    updateCustomerBusinessListingSpec(input: $input) {
      ok
      error {
        message
      }
      customerBusinessListingSpec {
        id
        isListed
      }
    }
  }
`;
export type UpdateCustomerBusinessListingSpecMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCustomerBusinessListingSpecMutation,
  UpdateCustomerBusinessListingSpecMutationVariables
>;

/**
 * __useUpdateCustomerBusinessListingSpecMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerBusinessListingSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerBusinessListingSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerBusinessListingSpecMutation, { data, loading, error }] = useUpdateCustomerBusinessListingSpecMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerBusinessListingSpecMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCustomerBusinessListingSpecMutation,
    UpdateCustomerBusinessListingSpecMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCustomerBusinessListingSpecMutation,
    UpdateCustomerBusinessListingSpecMutationVariables
  >(UpdateCustomerBusinessListingSpecDocument, options);
}
export type UpdateCustomerBusinessListingSpecMutationHookResult = ReturnType<
  typeof useUpdateCustomerBusinessListingSpecMutation
>;
export type UpdateCustomerBusinessListingSpecMutationResult =
  ApolloReactCommon.MutationResult<UpdateCustomerBusinessListingSpecMutation>;
export type UpdateCustomerBusinessListingSpecMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCustomerBusinessListingSpecMutation,
  UpdateCustomerBusinessListingSpecMutationVariables
>;
export const CreateCustomerBusinessListingSpecDocument = gql`
  mutation CreateCustomerBusinessListingSpec($input: CreateCustomerBusinessListingSpecInput!) {
    createCustomerBusinessListingSpec(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type CreateCustomerBusinessListingSpecMutationFn = ApolloReactCommon.MutationFunction<
  CreateCustomerBusinessListingSpecMutation,
  CreateCustomerBusinessListingSpecMutationVariables
>;

/**
 * __useCreateCustomerBusinessListingSpecMutation__
 *
 * To run a mutation, you first call `useCreateCustomerBusinessListingSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerBusinessListingSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerBusinessListingSpecMutation, { data, loading, error }] = useCreateCustomerBusinessListingSpecMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerBusinessListingSpecMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCustomerBusinessListingSpecMutation,
    CreateCustomerBusinessListingSpecMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateCustomerBusinessListingSpecMutation,
    CreateCustomerBusinessListingSpecMutationVariables
  >(CreateCustomerBusinessListingSpecDocument, options);
}
export type CreateCustomerBusinessListingSpecMutationHookResult = ReturnType<
  typeof useCreateCustomerBusinessListingSpecMutation
>;
export type CreateCustomerBusinessListingSpecMutationResult =
  ApolloReactCommon.MutationResult<CreateCustomerBusinessListingSpecMutation>;
export type CreateCustomerBusinessListingSpecMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCustomerBusinessListingSpecMutation,
  CreateCustomerBusinessListingSpecMutationVariables
>;
export const SyncUberallLocationDocument = gql`
  mutation SyncUberallLocation($input: SyncUberallLocationInput!) {
    syncUberallLocation(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type SyncUberallLocationMutationFn = ApolloReactCommon.MutationFunction<
  SyncUberallLocationMutation,
  SyncUberallLocationMutationVariables
>;

/**
 * __useSyncUberallLocationMutation__
 *
 * To run a mutation, you first call `useSyncUberallLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncUberallLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncUberallLocationMutation, { data, loading, error }] = useSyncUberallLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncUberallLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SyncUberallLocationMutation, SyncUberallLocationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SyncUberallLocationMutation, SyncUberallLocationMutationVariables>(
    SyncUberallLocationDocument,
    options
  );
}
export type SyncUberallLocationMutationHookResult = ReturnType<typeof useSyncUberallLocationMutation>;
export type SyncUberallLocationMutationResult = ApolloReactCommon.MutationResult<SyncUberallLocationMutation>;
export type SyncUberallLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SyncUberallLocationMutation,
  SyncUberallLocationMutationVariables
>;
export const ClientDetailsLocationsDocument = gql`
  query ClientDetailsLocations($id: Int!, $campaignCategoryId: String!) {
    customer(id: $id) {
      id
      currentCustomerCampaign(campaignCategoryId: $campaignCategoryId) {
        id
      }
      customerBusinessListingSpecs {
        nodes {
          id
          address
          city
          isListed
        }
      }
    }
  }
`;

/**
 * __useClientDetailsLocationsQuery__
 *
 * To run a query within a React component, call `useClientDetailsLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      campaignCategoryId: // value for 'campaignCategoryId'
 *   },
 * });
 */
export function useClientDetailsLocationsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ClientDetailsLocationsQuery, ClientDetailsLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientDetailsLocationsQuery, ClientDetailsLocationsQueryVariables>(
    ClientDetailsLocationsDocument,
    options
  );
}
export function useClientDetailsLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientDetailsLocationsQuery, ClientDetailsLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClientDetailsLocationsQuery, ClientDetailsLocationsQueryVariables>(
    ClientDetailsLocationsDocument,
    options
  );
}
export type ClientDetailsLocationsQueryHookResult = ReturnType<typeof useClientDetailsLocationsQuery>;
export type ClientDetailsLocationsLazyQueryHookResult = ReturnType<typeof useClientDetailsLocationsLazyQuery>;
export type ClientDetailsLocationsQueryResult = ApolloReactCommon.QueryResult<
  ClientDetailsLocationsQuery,
  ClientDetailsLocationsQueryVariables
>;
export const ClientDetailsLocationDocument = gql`
  query ClientDetailsLocation($id: Int!, $customerId: Int!) {
    customer(id: $customerId) {
      id
      uberallBusinessId
    }
    customerBusinessListingSpec(id: $id) {
      id
      hasPhysicalStorefront
      name
      email
      address
      city
      stateOrProvince
      zipPostalCode
      country
      phoneNumber
      contactName
      isListed
      isReviewEnabled
      addressDisplay
      uberallCategoryId
      errorMessage
      uberallLocationId
      uberallSyncNeeded
    }
  }
`;

/**
 * __useClientDetailsLocationQuery__
 *
 * To run a query within a React component, call `useClientDetailsLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useClientDetailsLocationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ClientDetailsLocationQuery, ClientDetailsLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientDetailsLocationQuery, ClientDetailsLocationQueryVariables>(
    ClientDetailsLocationDocument,
    options
  );
}
export function useClientDetailsLocationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientDetailsLocationQuery, ClientDetailsLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClientDetailsLocationQuery, ClientDetailsLocationQueryVariables>(
    ClientDetailsLocationDocument,
    options
  );
}
export type ClientDetailsLocationQueryHookResult = ReturnType<typeof useClientDetailsLocationQuery>;
export type ClientDetailsLocationLazyQueryHookResult = ReturnType<typeof useClientDetailsLocationLazyQuery>;
export type ClientDetailsLocationQueryResult = ApolloReactCommon.QueryResult<
  ClientDetailsLocationQuery,
  ClientDetailsLocationQueryVariables
>;
export const CreateCustomerConversationDocument = gql`
  mutation CreateCustomerConversation($input: CreateCustomerConversationInput!) {
    createCustomerConversation(input: $input) {
      customerConversation {
        id
      }
    }
  }
`;
export type CreateCustomerConversationMutationFn = ApolloReactCommon.MutationFunction<
  CreateCustomerConversationMutation,
  CreateCustomerConversationMutationVariables
>;

/**
 * __useCreateCustomerConversationMutation__
 *
 * To run a mutation, you first call `useCreateCustomerConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerConversationMutation, { data, loading, error }] = useCreateCustomerConversationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerConversationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCustomerConversationMutation,
    CreateCustomerConversationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreateCustomerConversationMutation, CreateCustomerConversationMutationVariables>(
    CreateCustomerConversationDocument,
    options
  );
}
export type CreateCustomerConversationMutationHookResult = ReturnType<typeof useCreateCustomerConversationMutation>;
export type CreateCustomerConversationMutationResult =
  ApolloReactCommon.MutationResult<CreateCustomerConversationMutation>;
export type CreateCustomerConversationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCustomerConversationMutation,
  CreateCustomerConversationMutationVariables
>;
export const CustomerDetailConversationsDocument = gql`
  query CustomerDetailConversations(
    $customerId: Int
    $limit: Int
    $offset: Int
    $messageLimit: Int
    $messageOffset: Int
  ) {
    customerConversations(customerId: $customerId, limit: $limit, offset: $offset, sort: CREATED_AT_DESC) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        subject
        createdAt
        helpRequestedAt
        customerMessages(limit: $messageLimit, offset: $messageOffset, sort: CREATED_AT_DESC) {
          nodes {
            id
            createdAt
            isFromCustomer
          }
        }
      }
    }
  }
`;

/**
 * __useCustomerDetailConversationsQuery__
 *
 * To run a query within a React component, call `useCustomerDetailConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailConversationsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      messageLimit: // value for 'messageLimit'
 *      messageOffset: // value for 'messageOffset'
 *   },
 * });
 */
export function useCustomerDetailConversationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CustomerDetailConversationsQuery,
    CustomerDetailConversationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerDetailConversationsQuery, CustomerDetailConversationsQueryVariables>(
    CustomerDetailConversationsDocument,
    options
  );
}
export function useCustomerDetailConversationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerDetailConversationsQuery,
    CustomerDetailConversationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerDetailConversationsQuery, CustomerDetailConversationsQueryVariables>(
    CustomerDetailConversationsDocument,
    options
  );
}
export type CustomerDetailConversationsQueryHookResult = ReturnType<typeof useCustomerDetailConversationsQuery>;
export type CustomerDetailConversationsLazyQueryHookResult = ReturnType<typeof useCustomerDetailConversationsLazyQuery>;
export type CustomerDetailConversationsQueryResult = ApolloReactCommon.QueryResult<
  CustomerDetailConversationsQuery,
  CustomerDetailConversationsQueryVariables
>;
export const CustomerNameDocument = gql`
  query CustomerName($id: Int!) {
    customer(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useCustomerNameQuery__
 *
 * To run a query within a React component, call `useCustomerNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerNameQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CustomerNameQuery, CustomerNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerNameQuery, CustomerNameQueryVariables>(CustomerNameDocument, options);
}
export function useCustomerNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerNameQuery, CustomerNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerNameQuery, CustomerNameQueryVariables>(CustomerNameDocument, options);
}
export type CustomerNameQueryHookResult = ReturnType<typeof useCustomerNameQuery>;
export type CustomerNameLazyQueryHookResult = ReturnType<typeof useCustomerNameLazyQuery>;
export type CustomerNameQueryResult = ApolloReactCommon.QueryResult<CustomerNameQuery, CustomerNameQueryVariables>;
export const SaveCustomerBusinessListingSpecDocument = gql`
  mutation SaveCustomerBusinessListingSpec($input: SaveCustomerBusinessListingSpecInput!) {
    saveCustomerBusinessListingSpec(input: $input) {
      customerBusinessListingSpec {
        id
      }
    }
  }
`;
export type SaveCustomerBusinessListingSpecMutationFn = ApolloReactCommon.MutationFunction<
  SaveCustomerBusinessListingSpecMutation,
  SaveCustomerBusinessListingSpecMutationVariables
>;

/**
 * __useSaveCustomerBusinessListingSpecMutation__
 *
 * To run a mutation, you first call `useSaveCustomerBusinessListingSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomerBusinessListingSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomerBusinessListingSpecMutation, { data, loading, error }] = useSaveCustomerBusinessListingSpecMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCustomerBusinessListingSpecMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveCustomerBusinessListingSpecMutation,
    SaveCustomerBusinessListingSpecMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SaveCustomerBusinessListingSpecMutation,
    SaveCustomerBusinessListingSpecMutationVariables
  >(SaveCustomerBusinessListingSpecDocument, options);
}
export type SaveCustomerBusinessListingSpecMutationHookResult = ReturnType<
  typeof useSaveCustomerBusinessListingSpecMutation
>;
export type SaveCustomerBusinessListingSpecMutationResult =
  ApolloReactCommon.MutationResult<SaveCustomerBusinessListingSpecMutation>;
export type SaveCustomerBusinessListingSpecMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveCustomerBusinessListingSpecMutation,
  SaveCustomerBusinessListingSpecMutationVariables
>;
export const CreateCustomerPremiumBusinessListingSpecImageDocument = gql`
  mutation CreateCustomerPremiumBusinessListingSpecImage($input: CreateCustomerPremiumBusinessListingSpecImageInput!) {
    createCustomerPremiumBusinessListingSpecImage(input: $input) {
      ok
      error {
        message
      }
      customerPremiumBusinessListingSpecImage {
        id
        customerId
        imageTypeId
        file {
          id
          name
          contentType
          dataUrl: url
          size: sizeInBytes
        }
      }
    }
  }
`;
export type CreateCustomerPremiumBusinessListingSpecImageMutationFn = ApolloReactCommon.MutationFunction<
  CreateCustomerPremiumBusinessListingSpecImageMutation,
  CreateCustomerPremiumBusinessListingSpecImageMutationVariables
>;

/**
 * __useCreateCustomerPremiumBusinessListingSpecImageMutation__
 *
 * To run a mutation, you first call `useCreateCustomerPremiumBusinessListingSpecImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerPremiumBusinessListingSpecImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerPremiumBusinessListingSpecImageMutation, { data, loading, error }] = useCreateCustomerPremiumBusinessListingSpecImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerPremiumBusinessListingSpecImageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCustomerPremiumBusinessListingSpecImageMutation,
    CreateCustomerPremiumBusinessListingSpecImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateCustomerPremiumBusinessListingSpecImageMutation,
    CreateCustomerPremiumBusinessListingSpecImageMutationVariables
  >(CreateCustomerPremiumBusinessListingSpecImageDocument, options);
}
export type CreateCustomerPremiumBusinessListingSpecImageMutationHookResult = ReturnType<
  typeof useCreateCustomerPremiumBusinessListingSpecImageMutation
>;
export type CreateCustomerPremiumBusinessListingSpecImageMutationResult =
  ApolloReactCommon.MutationResult<CreateCustomerPremiumBusinessListingSpecImageMutation>;
export type CreateCustomerPremiumBusinessListingSpecImageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCustomerPremiumBusinessListingSpecImageMutation,
  CreateCustomerPremiumBusinessListingSpecImageMutationVariables
>;
export const DeleteCustomerPremiumBusinessListingSpecImageDocument = gql`
  mutation DeleteCustomerPremiumBusinessListingSpecImage($input: DeleteCustomerPremiumBusinessListingSpecImageInput!) {
    deleteCustomerPremiumBusinessListingSpecImage(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type DeleteCustomerPremiumBusinessListingSpecImageMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCustomerPremiumBusinessListingSpecImageMutation,
  DeleteCustomerPremiumBusinessListingSpecImageMutationVariables
>;

/**
 * __useDeleteCustomerPremiumBusinessListingSpecImageMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerPremiumBusinessListingSpecImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerPremiumBusinessListingSpecImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerPremiumBusinessListingSpecImageMutation, { data, loading, error }] = useDeleteCustomerPremiumBusinessListingSpecImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerPremiumBusinessListingSpecImageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCustomerPremiumBusinessListingSpecImageMutation,
    DeleteCustomerPremiumBusinessListingSpecImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteCustomerPremiumBusinessListingSpecImageMutation,
    DeleteCustomerPremiumBusinessListingSpecImageMutationVariables
  >(DeleteCustomerPremiumBusinessListingSpecImageDocument, options);
}
export type DeleteCustomerPremiumBusinessListingSpecImageMutationHookResult = ReturnType<
  typeof useDeleteCustomerPremiumBusinessListingSpecImageMutation
>;
export type DeleteCustomerPremiumBusinessListingSpecImageMutationResult =
  ApolloReactCommon.MutationResult<DeleteCustomerPremiumBusinessListingSpecImageMutation>;
export type DeleteCustomerPremiumBusinessListingSpecImageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCustomerPremiumBusinessListingSpecImageMutation,
  DeleteCustomerPremiumBusinessListingSpecImageMutationVariables
>;
export const CustomerDetailsBusinessListingDocument = gql`
  query CustomerDetailsBusinessListing($id: Int!) {
    customer(id: $id) {
      id
      customerBusinessListingSpec {
        id
        hasPhysicalStorefront
        email
        address
        city
        stateOrProvince
        zipPostalCode
        country
        phoneNumber
        contactName
      }
    }
  }
`;

/**
 * __useCustomerDetailsBusinessListingQuery__
 *
 * To run a query within a React component, call `useCustomerDetailsBusinessListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailsBusinessListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailsBusinessListingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerDetailsBusinessListingQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerDetailsBusinessListingQuery,
    CustomerDetailsBusinessListingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerDetailsBusinessListingQuery, CustomerDetailsBusinessListingQueryVariables>(
    CustomerDetailsBusinessListingDocument,
    options
  );
}
export function useCustomerDetailsBusinessListingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerDetailsBusinessListingQuery,
    CustomerDetailsBusinessListingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerDetailsBusinessListingQuery,
    CustomerDetailsBusinessListingQueryVariables
  >(CustomerDetailsBusinessListingDocument, options);
}
export type CustomerDetailsBusinessListingQueryHookResult = ReturnType<typeof useCustomerDetailsBusinessListingQuery>;
export type CustomerDetailsBusinessListingLazyQueryHookResult = ReturnType<
  typeof useCustomerDetailsBusinessListingLazyQuery
>;
export type CustomerDetailsBusinessListingQueryResult = ApolloReactCommon.QueryResult<
  CustomerDetailsBusinessListingQuery,
  CustomerDetailsBusinessListingQueryVariables
>;
export const CustomerDetailsCompanyImagesDocument = gql`
  query CustomerDetailsCompanyImages($id: Int!) {
    customer(id: $id) {
      id
      hasPremiumListing
      logoImage: customerPremiumBusinessListingSpecImages(imageTypeId: "logo") {
        nodes {
          id
          customerId
          imageTypeId
          file {
            id
            name
            contentType
            dataUrl: url
            size: sizeInBytes
          }
        }
      }
      storefrontImages: customerPremiumBusinessListingSpecImages(imageTypeId: "storefront") {
        nodes {
          id
          customerId
          imageTypeId
          file {
            id
            name
            contentType
            dataUrl: url
            size: sizeInBytes
          }
        }
      }
      productImages: customerPremiumBusinessListingSpecImages(imageTypeId: "product-or-service") {
        nodes {
          id
          customerId
          imageTypeId
          file {
            id
            name
            contentType
            dataUrl: url
            size: sizeInBytes
          }
        }
      }
    }
  }
`;

/**
 * __useCustomerDetailsCompanyImagesQuery__
 *
 * To run a query within a React component, call `useCustomerDetailsCompanyImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailsCompanyImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailsCompanyImagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerDetailsCompanyImagesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerDetailsCompanyImagesQuery,
    CustomerDetailsCompanyImagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerDetailsCompanyImagesQuery, CustomerDetailsCompanyImagesQueryVariables>(
    CustomerDetailsCompanyImagesDocument,
    options
  );
}
export function useCustomerDetailsCompanyImagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerDetailsCompanyImagesQuery,
    CustomerDetailsCompanyImagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerDetailsCompanyImagesQuery, CustomerDetailsCompanyImagesQueryVariables>(
    CustomerDetailsCompanyImagesDocument,
    options
  );
}
export type CustomerDetailsCompanyImagesQueryHookResult = ReturnType<typeof useCustomerDetailsCompanyImagesQuery>;
export type CustomerDetailsCompanyImagesLazyQueryHookResult = ReturnType<
  typeof useCustomerDetailsCompanyImagesLazyQuery
>;
export type CustomerDetailsCompanyImagesQueryResult = ApolloReactCommon.QueryResult<
  CustomerDetailsCompanyImagesQuery,
  CustomerDetailsCompanyImagesQueryVariables
>;
export const UpdateCustomerDocument = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        id
      }
    }
  }
`;
export type UpdateCustomerMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(
    UpdateCustomerDocument,
    options
  );
}
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;
export const CustomerDetailsHoursOfOperationItemsDocument = gql`
  query CustomerDetailsHoursOfOperationItems($id: Int!) {
    customer(id: $id) {
      id
      customerHoursOfOperationItems {
        id
        dayIndex
        isOpen
        openAt
        closeAt
      }
    }
  }
`;

/**
 * __useCustomerDetailsHoursOfOperationItemsQuery__
 *
 * To run a query within a React component, call `useCustomerDetailsHoursOfOperationItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailsHoursOfOperationItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailsHoursOfOperationItemsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerDetailsHoursOfOperationItemsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerDetailsHoursOfOperationItemsQuery,
    CustomerDetailsHoursOfOperationItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomerDetailsHoursOfOperationItemsQuery,
    CustomerDetailsHoursOfOperationItemsQueryVariables
  >(CustomerDetailsHoursOfOperationItemsDocument, options);
}
export function useCustomerDetailsHoursOfOperationItemsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerDetailsHoursOfOperationItemsQuery,
    CustomerDetailsHoursOfOperationItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerDetailsHoursOfOperationItemsQuery,
    CustomerDetailsHoursOfOperationItemsQueryVariables
  >(CustomerDetailsHoursOfOperationItemsDocument, options);
}
export type CustomerDetailsHoursOfOperationItemsQueryHookResult = ReturnType<
  typeof useCustomerDetailsHoursOfOperationItemsQuery
>;
export type CustomerDetailsHoursOfOperationItemsLazyQueryHookResult = ReturnType<
  typeof useCustomerDetailsHoursOfOperationItemsLazyQuery
>;
export type CustomerDetailsHoursOfOperationItemsQueryResult = ApolloReactCommon.QueryResult<
  CustomerDetailsHoursOfOperationItemsQuery,
  CustomerDetailsHoursOfOperationItemsQueryVariables
>;
export const CustomerDetailsOverviewDocument = gql`
  query CustomerDetailsOverview($id: Int!, $limit: Int, $offset: Int, $processedAt: DateQuery) {
    customer(id: $id) {
      id
      name
      website
      partnerId
      analyticsReportingInlineFrameUrl
      gbpUrl
      instructions
      partner {
        id
        name
        portal
      }
      accountManagerUser {
        id
        fullName
      }
      rcmAccountManagerUser {
        id
        fullName
      }
      status {
        id
        name
      }
      category {
        id
        name
      }
      customerStatusChanges(limit: $limit, offset: $offset, sort: EFFECTIVE_AT_DESC, processedAt: $processedAt) {
        nodes {
          ...Overview_CustomerStatusChange
        }
      }
    }
  }
  ${Overview_CustomerStatusChangeFragmentDoc}
`;

/**
 * __useCustomerDetailsOverviewQuery__
 *
 * To run a query within a React component, call `useCustomerDetailsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailsOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      processedAt: // value for 'processedAt'
 *   },
 * });
 */
export function useCustomerDetailsOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CustomerDetailsOverviewQuery, CustomerDetailsOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerDetailsOverviewQuery, CustomerDetailsOverviewQueryVariables>(
    CustomerDetailsOverviewDocument,
    options
  );
}
export function useCustomerDetailsOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerDetailsOverviewQuery,
    CustomerDetailsOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerDetailsOverviewQuery, CustomerDetailsOverviewQueryVariables>(
    CustomerDetailsOverviewDocument,
    options
  );
}
export type CustomerDetailsOverviewQueryHookResult = ReturnType<typeof useCustomerDetailsOverviewQuery>;
export type CustomerDetailsOverviewLazyQueryHookResult = ReturnType<typeof useCustomerDetailsOverviewLazyQuery>;
export type CustomerDetailsOverviewQueryResult = ApolloReactCommon.QueryResult<
  CustomerDetailsOverviewQuery,
  CustomerDetailsOverviewQueryVariables
>;
export const SaveCustomerPremiumBusinessListingSpecDocument = gql`
  mutation SaveCustomerPremiumBusinessListingSpec($input: SaveCustomerPremiumBusinessListingSpecInput!) {
    saveCustomerPremiumBusinessListingSpec(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type SaveCustomerPremiumBusinessListingSpecMutationFn = ApolloReactCommon.MutationFunction<
  SaveCustomerPremiumBusinessListingSpecMutation,
  SaveCustomerPremiumBusinessListingSpecMutationVariables
>;

/**
 * __useSaveCustomerPremiumBusinessListingSpecMutation__
 *
 * To run a mutation, you first call `useSaveCustomerPremiumBusinessListingSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomerPremiumBusinessListingSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomerPremiumBusinessListingSpecMutation, { data, loading, error }] = useSaveCustomerPremiumBusinessListingSpecMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCustomerPremiumBusinessListingSpecMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveCustomerPremiumBusinessListingSpecMutation,
    SaveCustomerPremiumBusinessListingSpecMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SaveCustomerPremiumBusinessListingSpecMutation,
    SaveCustomerPremiumBusinessListingSpecMutationVariables
  >(SaveCustomerPremiumBusinessListingSpecDocument, options);
}
export type SaveCustomerPremiumBusinessListingSpecMutationHookResult = ReturnType<
  typeof useSaveCustomerPremiumBusinessListingSpecMutation
>;
export type SaveCustomerPremiumBusinessListingSpecMutationResult =
  ApolloReactCommon.MutationResult<SaveCustomerPremiumBusinessListingSpecMutation>;
export type SaveCustomerPremiumBusinessListingSpecMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveCustomerPremiumBusinessListingSpecMutation,
  SaveCustomerPremiumBusinessListingSpecMutationVariables
>;
export const CustomerDetailsPremiumBusinessListingDocument = gql`
  query CustomerDetailsPremiumBusinessListing($id: Int!) {
    customer(id: $id) {
      id
      customerPremiumBusinessListingSpec {
        id
        yearsInBusiness
        specialHoursOfOperation
        isAlwaysOpen
        priceRangeLevel
        shouldReceiveLeadMessages
        isWomenOwned
        isBlackOwned
      }
    }
  }
`;

/**
 * __useCustomerDetailsPremiumBusinessListingQuery__
 *
 * To run a query within a React component, call `useCustomerDetailsPremiumBusinessListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailsPremiumBusinessListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailsPremiumBusinessListingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerDetailsPremiumBusinessListingQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerDetailsPremiumBusinessListingQuery,
    CustomerDetailsPremiumBusinessListingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CustomerDetailsPremiumBusinessListingQuery,
    CustomerDetailsPremiumBusinessListingQueryVariables
  >(CustomerDetailsPremiumBusinessListingDocument, options);
}
export function useCustomerDetailsPremiumBusinessListingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerDetailsPremiumBusinessListingQuery,
    CustomerDetailsPremiumBusinessListingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CustomerDetailsPremiumBusinessListingQuery,
    CustomerDetailsPremiumBusinessListingQueryVariables
  >(CustomerDetailsPremiumBusinessListingDocument, options);
}
export type CustomerDetailsPremiumBusinessListingQueryHookResult = ReturnType<
  typeof useCustomerDetailsPremiumBusinessListingQuery
>;
export type CustomerDetailsPremiumBusinessListingLazyQueryHookResult = ReturnType<
  typeof useCustomerDetailsPremiumBusinessListingLazyQuery
>;
export type CustomerDetailsPremiumBusinessListingQueryResult = ApolloReactCommon.QueryResult<
  CustomerDetailsPremiumBusinessListingQuery,
  CustomerDetailsPremiumBusinessListingQueryVariables
>;
export const SaveCustomerQuestionnaireDocument = gql`
  mutation SaveCustomerQuestionnaire($input: SaveCustomerQuestionnaireInput!) {
    saveCustomerQuestionnaire(input: $input) {
      customerQuestionnaire {
        id
      }
    }
  }
`;
export type SaveCustomerQuestionnaireMutationFn = ApolloReactCommon.MutationFunction<
  SaveCustomerQuestionnaireMutation,
  SaveCustomerQuestionnaireMutationVariables
>;

/**
 * __useSaveCustomerQuestionnaireMutation__
 *
 * To run a mutation, you first call `useSaveCustomerQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomerQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomerQuestionnaireMutation, { data, loading, error }] = useSaveCustomerQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCustomerQuestionnaireMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveCustomerQuestionnaireMutation,
    SaveCustomerQuestionnaireMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveCustomerQuestionnaireMutation, SaveCustomerQuestionnaireMutationVariables>(
    SaveCustomerQuestionnaireDocument,
    options
  );
}
export type SaveCustomerQuestionnaireMutationHookResult = ReturnType<typeof useSaveCustomerQuestionnaireMutation>;
export type SaveCustomerQuestionnaireMutationResult =
  ApolloReactCommon.MutationResult<SaveCustomerQuestionnaireMutation>;
export type SaveCustomerQuestionnaireMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveCustomerQuestionnaireMutation,
  SaveCustomerQuestionnaireMutationVariables
>;
export const CustomerDetailsQuestionnaireDocument = gql`
  query CustomerDetailsQuestionnaire($id: Int!) {
    customer(id: $id) {
      id
      name
      isBlogFulfilledByRCMWriter
      isGuestArticleFulfilledByRCMWriter
      customerQuestionnaire {
        id
        websiteHosting
        websiteCms
        whoCanChangeWebsite
        customerMarketSize
        businessDescription
        productsOffered
        targetKeywords
        preferredVoice
        targetAudience
        competitorWebsites
        contentPreference
        targetInternalLinks
        purposeOfContent
        callToActionUrls
        serviceArea
        contentPublishingPermissions
        isRCMPublishing
        isAddImage
        completedAt
      }
    }
  }
`;

/**
 * __useCustomerDetailsQuestionnaireQuery__
 *
 * To run a query within a React component, call `useCustomerDetailsQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerDetailsQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerDetailsQuestionnaireQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerDetailsQuestionnaireQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    CustomerDetailsQuestionnaireQuery,
    CustomerDetailsQuestionnaireQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerDetailsQuestionnaireQuery, CustomerDetailsQuestionnaireQueryVariables>(
    CustomerDetailsQuestionnaireDocument,
    options
  );
}
export function useCustomerDetailsQuestionnaireLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerDetailsQuestionnaireQuery,
    CustomerDetailsQuestionnaireQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerDetailsQuestionnaireQuery, CustomerDetailsQuestionnaireQueryVariables>(
    CustomerDetailsQuestionnaireDocument,
    options
  );
}
export type CustomerDetailsQuestionnaireQueryHookResult = ReturnType<typeof useCustomerDetailsQuestionnaireQuery>;
export type CustomerDetailsQuestionnaireLazyQueryHookResult = ReturnType<
  typeof useCustomerDetailsQuestionnaireLazyQuery
>;
export type CustomerDetailsQuestionnaireQueryResult = ApolloReactCommon.QueryResult<
  CustomerDetailsQuestionnaireQuery,
  CustomerDetailsQuestionnaireQueryVariables
>;
export const SaveCustomerHoursOfOperationItemsDocument = gql`
  mutation SaveCustomerHoursOfOperationItems($input: SaveCustomerHoursOfOperationItemsInput!) {
    saveCustomerHoursOfOperationItems(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type SaveCustomerHoursOfOperationItemsMutationFn = ApolloReactCommon.MutationFunction<
  SaveCustomerHoursOfOperationItemsMutation,
  SaveCustomerHoursOfOperationItemsMutationVariables
>;

/**
 * __useSaveCustomerHoursOfOperationItemsMutation__
 *
 * To run a mutation, you first call `useSaveCustomerHoursOfOperationItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomerHoursOfOperationItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomerHoursOfOperationItemsMutation, { data, loading, error }] = useSaveCustomerHoursOfOperationItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCustomerHoursOfOperationItemsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveCustomerHoursOfOperationItemsMutation,
    SaveCustomerHoursOfOperationItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SaveCustomerHoursOfOperationItemsMutation,
    SaveCustomerHoursOfOperationItemsMutationVariables
  >(SaveCustomerHoursOfOperationItemsDocument, options);
}
export type SaveCustomerHoursOfOperationItemsMutationHookResult = ReturnType<
  typeof useSaveCustomerHoursOfOperationItemsMutation
>;
export type SaveCustomerHoursOfOperationItemsMutationResult =
  ApolloReactCommon.MutationResult<SaveCustomerHoursOfOperationItemsMutation>;
export type SaveCustomerHoursOfOperationItemsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveCustomerHoursOfOperationItemsMutation,
  SaveCustomerHoursOfOperationItemsMutationVariables
>;
export const SaveCustomerSiteCredentialDocument = gql`
  mutation SaveCustomerSiteCredential($input: SaveCustomerSiteCredentialInput!) {
    saveCustomerSiteCredential(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type SaveCustomerSiteCredentialMutationFn = ApolloReactCommon.MutationFunction<
  SaveCustomerSiteCredentialMutation,
  SaveCustomerSiteCredentialMutationVariables
>;

/**
 * __useSaveCustomerSiteCredentialMutation__
 *
 * To run a mutation, you first call `useSaveCustomerSiteCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomerSiteCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomerSiteCredentialMutation, { data, loading, error }] = useSaveCustomerSiteCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCustomerSiteCredentialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveCustomerSiteCredentialMutation,
    SaveCustomerSiteCredentialMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SaveCustomerSiteCredentialMutation, SaveCustomerSiteCredentialMutationVariables>(
    SaveCustomerSiteCredentialDocument,
    options
  );
}
export type SaveCustomerSiteCredentialMutationHookResult = ReturnType<typeof useSaveCustomerSiteCredentialMutation>;
export type SaveCustomerSiteCredentialMutationResult =
  ApolloReactCommon.MutationResult<SaveCustomerSiteCredentialMutation>;
export type SaveCustomerSiteCredentialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveCustomerSiteCredentialMutation,
  SaveCustomerSiteCredentialMutationVariables
>;
export const CustomerSiteCredentialDocument = gql`
  query CustomerSiteCredential($id: Int!) {
    customer(id: $id) {
      id
      customerSiteCredential {
        id
        adminUrl
        user
        password
        instructions
      }
    }
  }
`;

/**
 * __useCustomerSiteCredentialQuery__
 *
 * To run a query within a React component, call `useCustomerSiteCredentialQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSiteCredentialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSiteCredentialQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerSiteCredentialQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CustomerSiteCredentialQuery, CustomerSiteCredentialQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerSiteCredentialQuery, CustomerSiteCredentialQueryVariables>(
    CustomerSiteCredentialDocument,
    options
  );
}
export function useCustomerSiteCredentialLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerSiteCredentialQuery, CustomerSiteCredentialQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerSiteCredentialQuery, CustomerSiteCredentialQueryVariables>(
    CustomerSiteCredentialDocument,
    options
  );
}
export type CustomerSiteCredentialQueryHookResult = ReturnType<typeof useCustomerSiteCredentialQuery>;
export type CustomerSiteCredentialLazyQueryHookResult = ReturnType<typeof useCustomerSiteCredentialLazyQuery>;
export type CustomerSiteCredentialQueryResult = ApolloReactCommon.QueryResult<
  CustomerSiteCredentialQuery,
  CustomerSiteCredentialQueryVariables
>;
export const PremiumBusinessListingSpecImage_GenerateUrlForFileUploadDocument = gql`
  mutation PremiumBusinessListingSpecImage_GenerateUrlForFileUpload($input: GenerateUrlForFileUploadInput!) {
    generateUrlForFileUpload(input: $input) {
      url
      fileId
    }
  }
`;
export type PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationFn = ApolloReactCommon.MutationFunction<
  PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation,
  PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationVariables
>;

/**
 * __usePremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation__
 *
 * To run a mutation, you first call `usePremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [premiumBusinessListingSpecImageGenerateUrlForFileUploadMutation, { data, loading, error }] = usePremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation,
    PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation,
    PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationVariables
  >(PremiumBusinessListingSpecImage_GenerateUrlForFileUploadDocument, options);
}
export type PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationHookResult = ReturnType<
  typeof usePremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation
>;
export type PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationResult =
  ApolloReactCommon.MutationResult<PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation>;
export type PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutation,
    PremiumBusinessListingSpecImage_GenerateUrlForFileUploadMutationVariables
  >;
export const RoadmapDeleteAmTaskDocument = gql`
  mutation RoadmapDeleteAmTask($input: DeleteAmTaskInput!) {
    deleteAmTask(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type RoadmapDeleteAmTaskMutationFn = ApolloReactCommon.MutationFunction<
  RoadmapDeleteAmTaskMutation,
  RoadmapDeleteAmTaskMutationVariables
>;

/**
 * __useRoadmapDeleteAmTaskMutation__
 *
 * To run a mutation, you first call `useRoadmapDeleteAmTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadmapDeleteAmTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadmapDeleteAmTaskMutation, { data, loading, error }] = useRoadmapDeleteAmTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRoadmapDeleteAmTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RoadmapDeleteAmTaskMutation, RoadmapDeleteAmTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RoadmapDeleteAmTaskMutation, RoadmapDeleteAmTaskMutationVariables>(
    RoadmapDeleteAmTaskDocument,
    options
  );
}
export type RoadmapDeleteAmTaskMutationHookResult = ReturnType<typeof useRoadmapDeleteAmTaskMutation>;
export type RoadmapDeleteAmTaskMutationResult = ApolloReactCommon.MutationResult<RoadmapDeleteAmTaskMutation>;
export type RoadmapDeleteAmTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RoadmapDeleteAmTaskMutation,
  RoadmapDeleteAmTaskMutationVariables
>;
export const RoadmapDeleteOrderDocument = gql`
  mutation RoadmapDeleteOrder($input: DeleteOrderInput!) {
    deleteOrder(input: $input) {
      isDeleted
    }
  }
`;
export type RoadmapDeleteOrderMutationFn = ApolloReactCommon.MutationFunction<
  RoadmapDeleteOrderMutation,
  RoadmapDeleteOrderMutationVariables
>;

/**
 * __useRoadmapDeleteOrderMutation__
 *
 * To run a mutation, you first call `useRoadmapDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRoadmapDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [roadmapDeleteOrderMutation, { data, loading, error }] = useRoadmapDeleteOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRoadmapDeleteOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RoadmapDeleteOrderMutation, RoadmapDeleteOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RoadmapDeleteOrderMutation, RoadmapDeleteOrderMutationVariables>(
    RoadmapDeleteOrderDocument,
    options
  );
}
export type RoadmapDeleteOrderMutationHookResult = ReturnType<typeof useRoadmapDeleteOrderMutation>;
export type RoadmapDeleteOrderMutationResult = ApolloReactCommon.MutationResult<RoadmapDeleteOrderMutation>;
export type RoadmapDeleteOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RoadmapDeleteOrderMutation,
  RoadmapDeleteOrderMutationVariables
>;
export const CustomerRoadmapCampaignsDocument = gql`
  query CustomerRoadmapCampaigns($customerId: Int!, $campaignCategoryId: String) {
    customer(id: $customerId) {
      ...RoadmapCustomer
    }
    orders(customerIds: [$customerId]) {
      nodes {
        ...RoadmapOrder
      }
    }
    amTasks(customerId: $customerId) {
      nodes {
        ...RoadmapAmTask
      }
    }
  }
  ${RoadmapCustomerFragmentDoc}
  ${RoadmapOrderFragmentDoc}
  ${RoadmapAmTaskFragmentDoc}
`;

/**
 * __useCustomerRoadmapCampaignsQuery__
 *
 * To run a query within a React component, call `useCustomerRoadmapCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerRoadmapCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerRoadmapCampaignsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      campaignCategoryId: // value for 'campaignCategoryId'
 *   },
 * });
 */
export function useCustomerRoadmapCampaignsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CustomerRoadmapCampaignsQuery, CustomerRoadmapCampaignsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerRoadmapCampaignsQuery, CustomerRoadmapCampaignsQueryVariables>(
    CustomerRoadmapCampaignsDocument,
    options
  );
}
export function useCustomerRoadmapCampaignsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CustomerRoadmapCampaignsQuery,
    CustomerRoadmapCampaignsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerRoadmapCampaignsQuery, CustomerRoadmapCampaignsQueryVariables>(
    CustomerRoadmapCampaignsDocument,
    options
  );
}
export type CustomerRoadmapCampaignsQueryHookResult = ReturnType<typeof useCustomerRoadmapCampaignsQuery>;
export type CustomerRoadmapCampaignsLazyQueryHookResult = ReturnType<typeof useCustomerRoadmapCampaignsLazyQuery>;
export type CustomerRoadmapCampaignsQueryResult = ApolloReactCommon.QueryResult<
  CustomerRoadmapCampaignsQuery,
  CustomerRoadmapCampaignsQueryVariables
>;
export const UpdateRoadmapOrderDocument = gql`
  mutation UpdateRoadmapOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      order {
        ...RoadmapOrder
      }
    }
  }
  ${RoadmapOrderFragmentDoc}
`;
export type UpdateRoadmapOrderMutationFn = ApolloReactCommon.MutationFunction<
  UpdateRoadmapOrderMutation,
  UpdateRoadmapOrderMutationVariables
>;

/**
 * __useUpdateRoadmapOrderMutation__
 *
 * To run a mutation, you first call `useUpdateRoadmapOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoadmapOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoadmapOrderMutation, { data, loading, error }] = useUpdateRoadmapOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoadmapOrderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRoadmapOrderMutation, UpdateRoadmapOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateRoadmapOrderMutation, UpdateRoadmapOrderMutationVariables>(
    UpdateRoadmapOrderDocument,
    options
  );
}
export type UpdateRoadmapOrderMutationHookResult = ReturnType<typeof useUpdateRoadmapOrderMutation>;
export type UpdateRoadmapOrderMutationResult = ApolloReactCommon.MutationResult<UpdateRoadmapOrderMutation>;
export type UpdateRoadmapOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateRoadmapOrderMutation,
  UpdateRoadmapOrderMutationVariables
>;
export const UpdateClientUserDocument = gql`
  mutation UpdateClientUser($input: UpdateCustomerUserInput!) {
    updateCustomerUser(input: $input) {
      customerUser {
        id
      }
    }
  }
`;
export type UpdateClientUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdateClientUserMutation,
  UpdateClientUserMutationVariables
>;

/**
 * __useUpdateClientUserMutation__
 *
 * To run a mutation, you first call `useUpdateClientUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientUserMutation, { data, loading, error }] = useUpdateClientUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateClientUserMutation, UpdateClientUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateClientUserMutation, UpdateClientUserMutationVariables>(
    UpdateClientUserDocument,
    options
  );
}
export type UpdateClientUserMutationHookResult = ReturnType<typeof useUpdateClientUserMutation>;
export type UpdateClientUserMutationResult = ApolloReactCommon.MutationResult<UpdateClientUserMutation>;
export type UpdateClientUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateClientUserMutation,
  UpdateClientUserMutationVariables
>;
export const ClientUsersDocument = gql`
  query ClientUsers(
    $id: Int!
    $search: String
    $limit: Int
    $offset: Int
    $sort: CustomerUsersSort
    $includeInactive: Boolean
  ) {
    customer(id: $id) {
      id
      customerUsers(search: $search, limit: $limit, offset: $offset, sort: $sort, includeInactive: $includeInactive) {
        totalCount
        nodes {
          id
          customerId
          isActive
          createdAt
          user {
            id
            email
            fullName
          }
        }
      }
    }
  }
`;

/**
 * __useClientUsersQuery__
 *
 * To run a query within a React component, call `useClientUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      includeInactive: // value for 'includeInactive'
 *   },
 * });
 */
export function useClientUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ClientUsersQuery, ClientUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientUsersQuery, ClientUsersQueryVariables>(ClientUsersDocument, options);
}
export function useClientUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientUsersQuery, ClientUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClientUsersQuery, ClientUsersQueryVariables>(ClientUsersDocument, options);
}
export type ClientUsersQueryHookResult = ReturnType<typeof useClientUsersQuery>;
export type ClientUsersLazyQueryHookResult = ReturnType<typeof useClientUsersLazyQuery>;
export type ClientUsersQueryResult = ApolloReactCommon.QueryResult<ClientUsersQuery, ClientUsersQueryVariables>;
export const ClientDetailsDocument = gql`
  query ClientDetails($customerId: Int!) {
    customer(id: $customerId) {
      id
      name
      isBillingVisible
    }
  }
`;

/**
 * __useClientDetailsQuery__
 *
 * To run a query within a React component, call `useClientDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useClientDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<ClientDetailsQuery, ClientDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ClientDetailsQuery, ClientDetailsQueryVariables>(ClientDetailsDocument, options);
}
export function useClientDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClientDetailsQuery, ClientDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ClientDetailsQuery, ClientDetailsQueryVariables>(ClientDetailsDocument, options);
}
export type ClientDetailsQueryHookResult = ReturnType<typeof useClientDetailsQuery>;
export type ClientDetailsLazyQueryHookResult = ReturnType<typeof useClientDetailsLazyQuery>;
export type ClientDetailsQueryResult = ApolloReactCommon.QueryResult<ClientDetailsQuery, ClientDetailsQueryVariables>;
export const CustomersDocument = gql`
  query Customers(
    $limit: Int!
    $offset: Int!
    $search: String
    $sort: CustomersSort
    $statusIds: [String!]
    $partnerIds: [Int!]
    $campaignCategoryId: String
    $rcmAccountManagerUserId: Int
    $partnerPortal: String
  ) {
    customers(
      limit: $limit
      offset: $offset
      search: $search
      sort: $sort
      partnerIds: $partnerIds
      statusIds: $statusIds
      campaignCategoryId: $campaignCategoryId
      rcmAccountManagerUserId: $rcmAccountManagerUserId
      partnerPortal: $partnerPortal
    ) {
      totalCount
      nodes {
        id
        name
        website
        createdAt
        accountManagerUser {
          id
          fullName
        }
        partner {
          id
          name
        }
        status {
          id
          name
        }
        customerQuestionnaire {
          id
          createdAt
          completedAt
        }
        customerCampaigns(campaignCategoryId: $campaignCategoryId) {
          nodes {
            endsAt
          }
        }
      }
    }
  }
`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      sort: // value for 'sort'
 *      statusIds: // value for 'statusIds'
 *      partnerIds: // value for 'partnerIds'
 *      campaignCategoryId: // value for 'campaignCategoryId'
 *      rcmAccountManagerUserId: // value for 'rcmAccountManagerUserId'
 *      partnerPortal: // value for 'partnerPortal'
 *   },
 * });
 */
export function useCustomersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<CustomersQuery, CustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
}
export function useCustomersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
}
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = ApolloReactCommon.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const PartnerAvailableProductsDocument = gql`
  query PartnerAvailableProducts($id: Int!) {
    products {
      nodes {
        ...PartnerAvailableProducts_Product
      }
    }
    partner(id: $id) {
      ...PartnerAvailableProducts_Partner
    }
  }
  ${PartnerAvailableProducts_ProductFragmentDoc}
  ${PartnerAvailableProducts_PartnerFragmentDoc}
`;

/**
 * __usePartnerAvailableProductsQuery__
 *
 * To run a query within a React component, call `usePartnerAvailableProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerAvailableProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerAvailableProductsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePartnerAvailableProductsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PartnerAvailableProductsQuery, PartnerAvailableProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerAvailableProductsQuery, PartnerAvailableProductsQueryVariables>(
    PartnerAvailableProductsDocument,
    options
  );
}
export function usePartnerAvailableProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PartnerAvailableProductsQuery,
    PartnerAvailableProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerAvailableProductsQuery, PartnerAvailableProductsQueryVariables>(
    PartnerAvailableProductsDocument,
    options
  );
}
export type PartnerAvailableProductsQueryHookResult = ReturnType<typeof usePartnerAvailableProductsQuery>;
export type PartnerAvailableProductsLazyQueryHookResult = ReturnType<typeof usePartnerAvailableProductsLazyQuery>;
export type PartnerAvailableProductsQueryResult = ApolloReactCommon.QueryResult<
  PartnerAvailableProductsQuery,
  PartnerAvailableProductsQueryVariables
>;
export const AddPackageItemDataDocument = gql`
  query AddPackageItemData($partnerId: Int!) {
    partner(id: $partnerId) {
      id
      partnerProducts {
        nodes {
          ...AddPackageItem_PartnerProduct
        }
      }
    }
  }
  ${AddPackageItem_PartnerProductFragmentDoc}
`;

/**
 * __useAddPackageItemDataQuery__
 *
 * To run a query within a React component, call `useAddPackageItemDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddPackageItemDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddPackageItemDataQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useAddPackageItemDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<AddPackageItemDataQuery, AddPackageItemDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AddPackageItemDataQuery, AddPackageItemDataQueryVariables>(
    AddPackageItemDataDocument,
    options
  );
}
export function useAddPackageItemDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AddPackageItemDataQuery, AddPackageItemDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<AddPackageItemDataQuery, AddPackageItemDataQueryVariables>(
    AddPackageItemDataDocument,
    options
  );
}
export type AddPackageItemDataQueryHookResult = ReturnType<typeof useAddPackageItemDataQuery>;
export type AddPackageItemDataLazyQueryHookResult = ReturnType<typeof useAddPackageItemDataLazyQuery>;
export type AddPackageItemDataQueryResult = ApolloReactCommon.QueryResult<
  AddPackageItemDataQuery,
  AddPackageItemDataQueryVariables
>;
export const CreatePartnerPackageItemDocument = gql`
  mutation CreatePartnerPackageItem($input: CreatePartnerPackageItemInput!) {
    createPartnerPackageItem(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
    }
  }
`;
export type CreatePartnerPackageItemMutationFn = ApolloReactCommon.MutationFunction<
  CreatePartnerPackageItemMutation,
  CreatePartnerPackageItemMutationVariables
>;

/**
 * __useCreatePartnerPackageItemMutation__
 *
 * To run a mutation, you first call `useCreatePartnerPackageItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePartnerPackageItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPartnerPackageItemMutation, { data, loading, error }] = useCreatePartnerPackageItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePartnerPackageItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePartnerPackageItemMutation,
    CreatePartnerPackageItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CreatePartnerPackageItemMutation, CreatePartnerPackageItemMutationVariables>(
    CreatePartnerPackageItemDocument,
    options
  );
}
export type CreatePartnerPackageItemMutationHookResult = ReturnType<typeof useCreatePartnerPackageItemMutation>;
export type CreatePartnerPackageItemMutationResult = ApolloReactCommon.MutationResult<CreatePartnerPackageItemMutation>;
export type CreatePartnerPackageItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatePartnerPackageItemMutation,
  CreatePartnerPackageItemMutationVariables
>;
export const PartnerPackageDetailsDocument = gql`
  query PartnerPackageDetails($partnerPackageId: Int!, $partnerId: Int!) {
    partner(id: $partnerId) {
      id
      name
    }
    partnerPackage(id: $partnerPackageId) {
      ...PartnerPackageDetails_Package
    }
  }
  ${PartnerPackageDetails_PackageFragmentDoc}
`;

/**
 * __usePartnerPackageDetailsQuery__
 *
 * To run a query within a React component, call `usePartnerPackageDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerPackageDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerPackageDetailsQuery({
 *   variables: {
 *      partnerPackageId: // value for 'partnerPackageId'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function usePartnerPackageDetailsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PartnerPackageDetailsQuery, PartnerPackageDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerPackageDetailsQuery, PartnerPackageDetailsQueryVariables>(
    PartnerPackageDetailsDocument,
    options
  );
}
export function usePartnerPackageDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerPackageDetailsQuery, PartnerPackageDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerPackageDetailsQuery, PartnerPackageDetailsQueryVariables>(
    PartnerPackageDetailsDocument,
    options
  );
}
export type PartnerPackageDetailsQueryHookResult = ReturnType<typeof usePartnerPackageDetailsQuery>;
export type PartnerPackageDetailsLazyQueryHookResult = ReturnType<typeof usePartnerPackageDetailsLazyQuery>;
export type PartnerPackageDetailsQueryResult = ApolloReactCommon.QueryResult<
  PartnerPackageDetailsQuery,
  PartnerPackageDetailsQueryVariables
>;
export const UpdatePartnerPackageItemDocument = gql`
  mutation UpdatePartnerPackageItem($input: UpdatePartnerPackageItemInput!) {
    updatePartnerPackageItem(input: $input) {
      ok
      error {
        message
      }
      partnerPackageItem {
        id
        quantity
        unitPrice
        totalPrice
      }
    }
  }
`;
export type UpdatePartnerPackageItemMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePartnerPackageItemMutation,
  UpdatePartnerPackageItemMutationVariables
>;

/**
 * __useUpdatePartnerPackageItemMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerPackageItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerPackageItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerPackageItemMutation, { data, loading, error }] = useUpdatePartnerPackageItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartnerPackageItemMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePartnerPackageItemMutation,
    UpdatePartnerPackageItemMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePartnerPackageItemMutation, UpdatePartnerPackageItemMutationVariables>(
    UpdatePartnerPackageItemDocument,
    options
  );
}
export type UpdatePartnerPackageItemMutationHookResult = ReturnType<typeof useUpdatePartnerPackageItemMutation>;
export type UpdatePartnerPackageItemMutationResult = ApolloReactCommon.MutationResult<UpdatePartnerPackageItemMutation>;
export type UpdatePartnerPackageItemMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePartnerPackageItemMutation,
  UpdatePartnerPackageItemMutationVariables
>;
export const UpdatePartnerPackageDocument = gql`
  mutation UpdatePartnerPackage($input: UpdatePartnerPackageInput!) {
    updatePartnerPackage(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type UpdatePartnerPackageMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePartnerPackageMutation,
  UpdatePartnerPackageMutationVariables
>;

/**
 * __useUpdatePartnerPackageMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerPackageMutation, { data, loading, error }] = useUpdatePartnerPackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartnerPackageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePartnerPackageMutation,
    UpdatePartnerPackageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePartnerPackageMutation, UpdatePartnerPackageMutationVariables>(
    UpdatePartnerPackageDocument,
    options
  );
}
export type UpdatePartnerPackageMutationHookResult = ReturnType<typeof useUpdatePartnerPackageMutation>;
export type UpdatePartnerPackageMutationResult = ApolloReactCommon.MutationResult<UpdatePartnerPackageMutation>;
export type UpdatePartnerPackageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePartnerPackageMutation,
  UpdatePartnerPackageMutationVariables
>;
export const PartnerPackagesDocument = gql`
  query PartnerPackages($partnerId: Int!) {
    partner(id: $partnerId) {
      id
      name
    }
    partnerPackages(partnerId: $partnerId, includeInactive: true) {
      totalCount
      nodes {
        ...PartnerPackages_Package
      }
    }
  }
  ${PartnerPackages_PackageFragmentDoc}
`;

/**
 * __usePartnerPackagesQuery__
 *
 * To run a query within a React component, call `usePartnerPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerPackagesQuery({
 *   variables: {
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function usePartnerPackagesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PartnerPackagesQuery, PartnerPackagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerPackagesQuery, PartnerPackagesQueryVariables>(
    PartnerPackagesDocument,
    options
  );
}
export function usePartnerPackagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerPackagesQuery, PartnerPackagesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerPackagesQuery, PartnerPackagesQueryVariables>(
    PartnerPackagesDocument,
    options
  );
}
export type PartnerPackagesQueryHookResult = ReturnType<typeof usePartnerPackagesQuery>;
export type PartnerPackagesLazyQueryHookResult = ReturnType<typeof usePartnerPackagesLazyQuery>;
export type PartnerPackagesQueryResult = ApolloReactCommon.QueryResult<
  PartnerPackagesQuery,
  PartnerPackagesQueryVariables
>;
export const DeletePartnerProductDocument = gql`
  mutation DeletePartnerProduct($input: DeletePartnerProductInput!) {
    deletePartnerProduct(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type DeletePartnerProductMutationFn = ApolloReactCommon.MutationFunction<
  DeletePartnerProductMutation,
  DeletePartnerProductMutationVariables
>;

/**
 * __useDeletePartnerProductMutation__
 *
 * To run a mutation, you first call `useDeletePartnerProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnerProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartnerProductMutation, { data, loading, error }] = useDeletePartnerProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePartnerProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeletePartnerProductMutation,
    DeletePartnerProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<DeletePartnerProductMutation, DeletePartnerProductMutationVariables>(
    DeletePartnerProductDocument,
    options
  );
}
export type DeletePartnerProductMutationHookResult = ReturnType<typeof useDeletePartnerProductMutation>;
export type DeletePartnerProductMutationResult = ApolloReactCommon.MutationResult<DeletePartnerProductMutation>;
export type DeletePartnerProductMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeletePartnerProductMutation,
  DeletePartnerProductMutationVariables
>;
export const Configuration_PartnerProductsDocument = gql`
  query Configuration_PartnerProducts($search: String, $limit: Int, $offset: Int, $partnerId: Int!) {
    partner(id: $partnerId) {
      id
      name
    }
    partnerProducts(search: $search, limit: $limit, offset: $offset, partnerId: $partnerId) {
      totalCount
      nodes {
        id
        name
        createdAt
        activeAt
        inactiveAt
        vendorId
        productId
        checklistDefinitionId
        wholesalePrice
        currentPartnerProductPrice {
          price
        }
        canDelete
        product {
          id
          name
          isKeywordRequired
          isChecklistRequired
          isVendorRequired
          description
          isSystemManaged
        }
        checklistDefinition {
          id
          name
        }
        vendor {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useConfiguration_PartnerProductsQuery__
 *
 * To run a query within a React component, call `useConfiguration_PartnerProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfiguration_PartnerProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfiguration_PartnerProductsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function useConfiguration_PartnerProductsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    Configuration_PartnerProductsQuery,
    Configuration_PartnerProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<Configuration_PartnerProductsQuery, Configuration_PartnerProductsQueryVariables>(
    Configuration_PartnerProductsDocument,
    options
  );
}
export function useConfiguration_PartnerProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    Configuration_PartnerProductsQuery,
    Configuration_PartnerProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<Configuration_PartnerProductsQuery, Configuration_PartnerProductsQueryVariables>(
    Configuration_PartnerProductsDocument,
    options
  );
}
export type Configuration_PartnerProductsQueryHookResult = ReturnType<typeof useConfiguration_PartnerProductsQuery>;
export type Configuration_PartnerProductsLazyQueryHookResult = ReturnType<
  typeof useConfiguration_PartnerProductsLazyQuery
>;
export type Configuration_PartnerProductsQueryResult = ApolloReactCommon.QueryResult<
  Configuration_PartnerProductsQuery,
  Configuration_PartnerProductsQueryVariables
>;
export const UpdatePartnerUserDocument = gql`
  mutation UpdatePartnerUser($input: UpdatePartnerUserInput!) {
    updatePartnerUser(input: $input) {
      partnerUser {
        id
      }
    }
  }
`;
export type UpdatePartnerUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePartnerUserMutation,
  UpdatePartnerUserMutationVariables
>;

/**
 * __useUpdatePartnerUserMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartnerUserMutation, { data, loading, error }] = useUpdatePartnerUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePartnerUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePartnerUserMutation, UpdatePartnerUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdatePartnerUserMutation, UpdatePartnerUserMutationVariables>(
    UpdatePartnerUserDocument,
    options
  );
}
export type UpdatePartnerUserMutationHookResult = ReturnType<typeof useUpdatePartnerUserMutation>;
export type UpdatePartnerUserMutationResult = ApolloReactCommon.MutationResult<UpdatePartnerUserMutation>;
export type UpdatePartnerUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePartnerUserMutation,
  UpdatePartnerUserMutationVariables
>;
export const PartnerUsersDocument = gql`
  query PartnerUsers($limit: Int!, $offset: Int!, $search: String, $includeInactive: Boolean, $partnerId: Int!) {
    partner(id: $partnerId) {
      id
      name
      partnerUsers(limit: $limit, offset: $offset, search: $search, includeInactive: $includeInactive) {
        totalCount
        nodes {
          ...PartnerUsers_PartnerUser
        }
      }
    }
  }
  ${PartnerUsers_PartnerUserFragmentDoc}
`;

/**
 * __usePartnerUsersQuery__
 *
 * To run a query within a React component, call `usePartnerUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      includeInactive: // value for 'includeInactive'
 *      partnerId: // value for 'partnerId'
 *   },
 * });
 */
export function usePartnerUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<PartnerUsersQuery, PartnerUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerUsersQuery, PartnerUsersQueryVariables>(PartnerUsersDocument, options);
}
export function usePartnerUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerUsersQuery, PartnerUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerUsersQuery, PartnerUsersQueryVariables>(PartnerUsersDocument, options);
}
export type PartnerUsersQueryHookResult = ReturnType<typeof usePartnerUsersQuery>;
export type PartnerUsersLazyQueryHookResult = ReturnType<typeof usePartnerUsersLazyQuery>;
export type PartnerUsersQueryResult = ApolloReactCommon.QueryResult<PartnerUsersQuery, PartnerUsersQueryVariables>;
export const PartnerConfigurationDocument = gql`
  query PartnerConfiguration($search: String, $offset: Int, $limit: Int) {
    partners(search: $search, limit: $limit, offset: $offset) {
      nodes {
        id
        name
        partnerProducts {
          totalCount
        }
        partnerUsers {
          totalCount
        }
      }
    }
  }
`;

/**
 * __usePartnerConfigurationQuery__
 *
 * To run a query within a React component, call `usePartnerConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerConfigurationQuery({
 *   variables: {
 *      search: // value for 'search'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePartnerConfigurationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PartnerConfigurationQuery, PartnerConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<PartnerConfigurationQuery, PartnerConfigurationQueryVariables>(
    PartnerConfigurationDocument,
    options
  );
}
export function usePartnerConfigurationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PartnerConfigurationQuery, PartnerConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<PartnerConfigurationQuery, PartnerConfigurationQueryVariables>(
    PartnerConfigurationDocument,
    options
  );
}
export type PartnerConfigurationQueryHookResult = ReturnType<typeof usePartnerConfigurationQuery>;
export type PartnerConfigurationLazyQueryHookResult = ReturnType<typeof usePartnerConfigurationLazyQuery>;
export type PartnerConfigurationQueryResult = ApolloReactCommon.QueryResult<
  PartnerConfigurationQuery,
  PartnerConfigurationQueryVariables
>;
export const ConfirmEmailDocument = gql`
  mutation ConfirmEmail($input: ConfirmEmailInput!) {
    confirmEmail(input: $input) {
      ok
    }
  }
`;
export type ConfirmEmailMutationFn = ApolloReactCommon.MutationFunction<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;

/**
 * __useConfirmEmailMutation__
 *
 * To run a mutation, you first call `useConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailMutation, { data, loading, error }] = useConfirmEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(
    ConfirmEmailDocument,
    options
  );
}
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = ApolloReactCommon.MutationResult<ConfirmEmailMutation>;
export type ConfirmEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ConfirmEmailMutation,
  ConfirmEmailMutationVariables
>;
export const KeywordResearchKeywordsDocument = gql`
  query KeywordResearchKeywords($keywords: [String!]!) {
    getKeywordsKeywords(keywords: $keywords) {
      keywordSuggestions {
        keyword
        avgMonthlySearches
        competitionIndex
      }
    }
  }
`;

/**
 * __useKeywordResearchKeywordsQuery__
 *
 * To run a query within a React component, call `useKeywordResearchKeywordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordResearchKeywordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordResearchKeywordsQuery({
 *   variables: {
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useKeywordResearchKeywordsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<KeywordResearchKeywordsQuery, KeywordResearchKeywordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<KeywordResearchKeywordsQuery, KeywordResearchKeywordsQueryVariables>(
    KeywordResearchKeywordsDocument,
    options
  );
}
export function useKeywordResearchKeywordsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    KeywordResearchKeywordsQuery,
    KeywordResearchKeywordsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<KeywordResearchKeywordsQuery, KeywordResearchKeywordsQueryVariables>(
    KeywordResearchKeywordsDocument,
    options
  );
}
export type KeywordResearchKeywordsQueryHookResult = ReturnType<typeof useKeywordResearchKeywordsQuery>;
export type KeywordResearchKeywordsLazyQueryHookResult = ReturnType<typeof useKeywordResearchKeywordsLazyQuery>;
export type KeywordResearchKeywordsQueryResult = ApolloReactCommon.QueryResult<
  KeywordResearchKeywordsQuery,
  KeywordResearchKeywordsQueryVariables
>;
export const KeywordResearchSiteDocument = gql`
  query KeywordResearchSite($domain: String!) {
    getKeywordsSite(domain: $domain) {
      keywordSuggestions {
        keyword
        avgMonthlySearches
        competitionIndex
      }
    }
  }
`;

/**
 * __useKeywordResearchSiteQuery__
 *
 * To run a query within a React component, call `useKeywordResearchSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeywordResearchSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeywordResearchSiteQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useKeywordResearchSiteQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<KeywordResearchSiteQuery, KeywordResearchSiteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<KeywordResearchSiteQuery, KeywordResearchSiteQueryVariables>(
    KeywordResearchSiteDocument,
    options
  );
}
export function useKeywordResearchSiteLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KeywordResearchSiteQuery, KeywordResearchSiteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<KeywordResearchSiteQuery, KeywordResearchSiteQueryVariables>(
    KeywordResearchSiteDocument,
    options
  );
}
export type KeywordResearchSiteQueryHookResult = ReturnType<typeof useKeywordResearchSiteQuery>;
export type KeywordResearchSiteLazyQueryHookResult = ReturnType<typeof useKeywordResearchSiteLazyQuery>;
export type KeywordResearchSiteQueryResult = ApolloReactCommon.QueryResult<
  KeywordResearchSiteQuery,
  KeywordResearchSiteQueryVariables
>;
export const LoginUserDocument = gql`
  mutation loginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      isLoggedIn
    }
  }
`;
export type LoginUserMutationFn = ApolloReactCommon.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
}
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = ApolloReactCommon.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginUserMutation,
  LoginUserMutationVariables
>;
export const CustomerConversationsDocument = gql`
  query CustomerConversations(
    $customerId: Int
    $limit: Int
    $offset: Int
    $sort: CustomerConversationsSort
    $messageLimit: Int
    $messageOffset: Int
    $messageSort: CustomerMessagesSort
    $search: String
  ) {
    customerConversations(customerId: $customerId, limit: $limit, offset: $offset, sort: $sort, search: $search) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        subject
        createdAt
        helpRequestedAt
        customer {
          id
          name
          partner {
            id
            name
          }
        }
        customerMessages(limit: $messageLimit, offset: $messageOffset, sort: $messageSort) {
          nodes {
            id
            createdAt
            isFromCustomer
          }
        }
      }
    }
  }
`;

/**
 * __useCustomerConversationsQuery__
 *
 * To run a query within a React component, call `useCustomerConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerConversationsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      messageLimit: // value for 'messageLimit'
 *      messageOffset: // value for 'messageOffset'
 *      messageSort: // value for 'messageSort'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCustomerConversationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerConversationsQuery, CustomerConversationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<CustomerConversationsQuery, CustomerConversationsQueryVariables>(
    CustomerConversationsDocument,
    options
  );
}
export function useCustomerConversationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerConversationsQuery, CustomerConversationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<CustomerConversationsQuery, CustomerConversationsQueryVariables>(
    CustomerConversationsDocument,
    options
  );
}
export type CustomerConversationsQueryHookResult = ReturnType<typeof useCustomerConversationsQuery>;
export type CustomerConversationsLazyQueryHookResult = ReturnType<typeof useCustomerConversationsLazyQuery>;
export type CustomerConversationsQueryResult = ApolloReactCommon.QueryResult<
  CustomerConversationsQuery,
  CustomerConversationsQueryVariables
>;
export const OrderDetailsContentItemDocument = gql`
  query OrderDetailsContentItem($orderId: Int!) {
    order(id: $orderId) {
      id
      orderContentItems {
        ...OrderDetails_OrderContentItem
      }
    }
  }
  ${OrderDetails_OrderContentItemFragmentDoc}
`;

/**
 * __useOrderDetailsContentItemQuery__
 *
 * To run a query within a React component, call `useOrderDetailsContentItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsContentItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsContentItemQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderDetailsContentItemQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<OrderDetailsContentItemQuery, OrderDetailsContentItemQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrderDetailsContentItemQuery, OrderDetailsContentItemQueryVariables>(
    OrderDetailsContentItemDocument,
    options
  );
}
export function useOrderDetailsContentItemLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrderDetailsContentItemQuery,
    OrderDetailsContentItemQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrderDetailsContentItemQuery, OrderDetailsContentItemQueryVariables>(
    OrderDetailsContentItemDocument,
    options
  );
}
export type OrderDetailsContentItemQueryHookResult = ReturnType<typeof useOrderDetailsContentItemQuery>;
export type OrderDetailsContentItemLazyQueryHookResult = ReturnType<typeof useOrderDetailsContentItemLazyQuery>;
export type OrderDetailsContentItemQueryResult = ApolloReactCommon.QueryResult<
  OrderDetailsContentItemQuery,
  OrderDetailsContentItemQueryVariables
>;
export const OrderDetails_ContentStrategyOrdersDocument = gql`
  query OrderDetails_ContentStrategyOrders($orderId: Int!) {
    order(id: $orderId) {
      id
      partnerProduct {
        id
        product {
          id
        }
      }
      customerProposal {
        id
        orderContentStrategies {
          id
          order {
            id
            startDate
            status {
              id
              name
            }
            partnerProduct {
              id
              name
              product {
                id
                name
              }
            }
          }
          topic
        }
      }
    }
  }
`;

/**
 * __useOrderDetails_ContentStrategyOrdersQuery__
 *
 * To run a query within a React component, call `useOrderDetails_ContentStrategyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetails_ContentStrategyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetails_ContentStrategyOrdersQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderDetails_ContentStrategyOrdersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrderDetails_ContentStrategyOrdersQuery,
    OrderDetails_ContentStrategyOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OrderDetails_ContentStrategyOrdersQuery,
    OrderDetails_ContentStrategyOrdersQueryVariables
  >(OrderDetails_ContentStrategyOrdersDocument, options);
}
export function useOrderDetails_ContentStrategyOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrderDetails_ContentStrategyOrdersQuery,
    OrderDetails_ContentStrategyOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OrderDetails_ContentStrategyOrdersQuery,
    OrderDetails_ContentStrategyOrdersQueryVariables
  >(OrderDetails_ContentStrategyOrdersDocument, options);
}
export type OrderDetails_ContentStrategyOrdersQueryHookResult = ReturnType<
  typeof useOrderDetails_ContentStrategyOrdersQuery
>;
export type OrderDetails_ContentStrategyOrdersLazyQueryHookResult = ReturnType<
  typeof useOrderDetails_ContentStrategyOrdersLazyQuery
>;
export type OrderDetails_ContentStrategyOrdersQueryResult = ApolloReactCommon.QueryResult<
  OrderDetails_ContentStrategyOrdersQuery,
  OrderDetails_ContentStrategyOrdersQueryVariables
>;
export const OrderDetails_DeleteOrderContentStrategyDocument = gql`
  mutation OrderDetails_DeleteOrderContentStrategy($input: DeleteOrderContentStrategyInput!) {
    deleteOrderContentStrategy(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type OrderDetails_DeleteOrderContentStrategyMutationFn = ApolloReactCommon.MutationFunction<
  OrderDetails_DeleteOrderContentStrategyMutation,
  OrderDetails_DeleteOrderContentStrategyMutationVariables
>;

/**
 * __useOrderDetails_DeleteOrderContentStrategyMutation__
 *
 * To run a mutation, you first call `useOrderDetails_DeleteOrderContentStrategyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderDetails_DeleteOrderContentStrategyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderDetailsDeleteOrderContentStrategyMutation, { data, loading, error }] = useOrderDetails_DeleteOrderContentStrategyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderDetails_DeleteOrderContentStrategyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OrderDetails_DeleteOrderContentStrategyMutation,
    OrderDetails_DeleteOrderContentStrategyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OrderDetails_DeleteOrderContentStrategyMutation,
    OrderDetails_DeleteOrderContentStrategyMutationVariables
  >(OrderDetails_DeleteOrderContentStrategyDocument, options);
}
export type OrderDetails_DeleteOrderContentStrategyMutationHookResult = ReturnType<
  typeof useOrderDetails_DeleteOrderContentStrategyMutation
>;
export type OrderDetails_DeleteOrderContentStrategyMutationResult =
  ApolloReactCommon.MutationResult<OrderDetails_DeleteOrderContentStrategyMutation>;
export type OrderDetails_DeleteOrderContentStrategyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OrderDetails_DeleteOrderContentStrategyMutation,
  OrderDetails_DeleteOrderContentStrategyMutationVariables
>;
export const OrderDetailsContentStrategyDocument = gql`
  query OrderDetailsContentStrategy($orderId: Int!) {
    order(id: $orderId) {
      id
      customerId
      partnerProduct {
        id
        product {
          id
          name
        }
      }
      activeOrderContentStrategy {
        ...OrderDetails_OrderContentStrategy
      }
    }
  }
  ${OrderDetails_OrderContentStrategyFragmentDoc}
`;

/**
 * __useOrderDetailsContentStrategyQuery__
 *
 * To run a query within a React component, call `useOrderDetailsContentStrategyQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsContentStrategyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsContentStrategyQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderDetailsContentStrategyQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OrderDetailsContentStrategyQuery,
    OrderDetailsContentStrategyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrderDetailsContentStrategyQuery, OrderDetailsContentStrategyQueryVariables>(
    OrderDetailsContentStrategyDocument,
    options
  );
}
export function useOrderDetailsContentStrategyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OrderDetailsContentStrategyQuery,
    OrderDetailsContentStrategyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrderDetailsContentStrategyQuery, OrderDetailsContentStrategyQueryVariables>(
    OrderDetailsContentStrategyDocument,
    options
  );
}
export type OrderDetailsContentStrategyQueryHookResult = ReturnType<typeof useOrderDetailsContentStrategyQuery>;
export type OrderDetailsContentStrategyLazyQueryHookResult = ReturnType<typeof useOrderDetailsContentStrategyLazyQuery>;
export type OrderDetailsContentStrategyQueryResult = ApolloReactCommon.QueryResult<
  OrderDetailsContentStrategyQuery,
  OrderDetailsContentStrategyQueryVariables
>;
export const OrderDetails_UpdateOrderContentStrategyDocument = gql`
  mutation OrderDetails_UpdateOrderContentStrategy($input: UpdateOrderContentStrategyInput!) {
    updateOrderContentStrategy(input: $input) {
      ok
      error {
        message
        inputFieldErrors {
          fieldName
          message
        }
      }
      orderContentStrategy {
        ...OrderDetails_OrderContentStrategy
      }
    }
  }
  ${OrderDetails_OrderContentStrategyFragmentDoc}
`;
export type OrderDetails_UpdateOrderContentStrategyMutationFn = ApolloReactCommon.MutationFunction<
  OrderDetails_UpdateOrderContentStrategyMutation,
  OrderDetails_UpdateOrderContentStrategyMutationVariables
>;

/**
 * __useOrderDetails_UpdateOrderContentStrategyMutation__
 *
 * To run a mutation, you first call `useOrderDetails_UpdateOrderContentStrategyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderDetails_UpdateOrderContentStrategyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderDetailsUpdateOrderContentStrategyMutation, { data, loading, error }] = useOrderDetails_UpdateOrderContentStrategyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderDetails_UpdateOrderContentStrategyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OrderDetails_UpdateOrderContentStrategyMutation,
    OrderDetails_UpdateOrderContentStrategyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    OrderDetails_UpdateOrderContentStrategyMutation,
    OrderDetails_UpdateOrderContentStrategyMutationVariables
  >(OrderDetails_UpdateOrderContentStrategyDocument, options);
}
export type OrderDetails_UpdateOrderContentStrategyMutationHookResult = ReturnType<
  typeof useOrderDetails_UpdateOrderContentStrategyMutation
>;
export type OrderDetails_UpdateOrderContentStrategyMutationResult =
  ApolloReactCommon.MutationResult<OrderDetails_UpdateOrderContentStrategyMutation>;
export type OrderDetails_UpdateOrderContentStrategyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OrderDetails_UpdateOrderContentStrategyMutation,
  OrderDetails_UpdateOrderContentStrategyMutationVariables
>;
export const OrderDetailsInvoiceDocument = gql`
  query OrderDetailsInvoice($orderId: Int!) {
    order(id: $orderId) {
      id
      billingStatus {
        id
        name
        description
      }
      customer {
        id
        partner {
          id
          isBillingEnforced
        }
      }
      startDate
      partnerInvoiceCharges {
        id
        partnerId
        partnerInvoiceId
        status {
          id
          name
          description
        }
        amount
        createdAt
      }
    }
  }
`;

/**
 * __useOrderDetailsInvoiceQuery__
 *
 * To run a query within a React component, call `useOrderDetailsInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsInvoiceQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderDetailsInvoiceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<OrderDetailsInvoiceQuery, OrderDetailsInvoiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrderDetailsInvoiceQuery, OrderDetailsInvoiceQueryVariables>(
    OrderDetailsInvoiceDocument,
    options
  );
}
export function useOrderDetailsInvoiceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderDetailsInvoiceQuery, OrderDetailsInvoiceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrderDetailsInvoiceQuery, OrderDetailsInvoiceQueryVariables>(
    OrderDetailsInvoiceDocument,
    options
  );
}
export type OrderDetailsInvoiceQueryHookResult = ReturnType<typeof useOrderDetailsInvoiceQuery>;
export type OrderDetailsInvoiceLazyQueryHookResult = ReturnType<typeof useOrderDetailsInvoiceLazyQuery>;
export type OrderDetailsInvoiceQueryResult = ApolloReactCommon.QueryResult<
  OrderDetailsInvoiceQuery,
  OrderDetailsInvoiceQueryVariables
>;
export const OrderDetailsOverviewDocument = gql`
  query OrderDetailsOverview($orderId: Int!) {
    order(id: $orderId) {
      id
      createdAt
      customerId
      userId
      startDate
      topic
      resultUrl
      resultUrlDomainAuthority
      instructions
      status {
        id
        name
      }
      customer {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
      partnerProduct {
        id
        name
        product {
          id
          name
        }
      }
      orderIssues {
        title
        message
      }
      targetResultUrl
      orderExternalContentSpec {
        id
        externalOrderId
        vendor {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useOrderDetailsOverviewQuery__
 *
 * To run a query within a React component, call `useOrderDetailsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsOverviewQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderDetailsOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<OrderDetailsOverviewQuery, OrderDetailsOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrderDetailsOverviewQuery, OrderDetailsOverviewQueryVariables>(
    OrderDetailsOverviewDocument,
    options
  );
}
export function useOrderDetailsOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderDetailsOverviewQuery, OrderDetailsOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrderDetailsOverviewQuery, OrderDetailsOverviewQueryVariables>(
    OrderDetailsOverviewDocument,
    options
  );
}
export type OrderDetailsOverviewQueryHookResult = ReturnType<typeof useOrderDetailsOverviewQuery>;
export type OrderDetailsOverviewLazyQueryHookResult = ReturnType<typeof useOrderDetailsOverviewLazyQuery>;
export type OrderDetailsOverviewQueryResult = ApolloReactCommon.QueryResult<
  OrderDetailsOverviewQuery,
  OrderDetailsOverviewQueryVariables
>;
export const ReCreateGuestOutreachDocument = gql`
  mutation ReCreateGuestOutreach($input: ReCreateGuestOutreachTaskInput!) {
    reCreateGuestOutreachTask(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type ReCreateGuestOutreachMutationFn = ApolloReactCommon.MutationFunction<
  ReCreateGuestOutreachMutation,
  ReCreateGuestOutreachMutationVariables
>;

/**
 * __useReCreateGuestOutreachMutation__
 *
 * To run a mutation, you first call `useReCreateGuestOutreachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReCreateGuestOutreachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reCreateGuestOutreachMutation, { data, loading, error }] = useReCreateGuestOutreachMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReCreateGuestOutreachMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReCreateGuestOutreachMutation,
    ReCreateGuestOutreachMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ReCreateGuestOutreachMutation, ReCreateGuestOutreachMutationVariables>(
    ReCreateGuestOutreachDocument,
    options
  );
}
export type ReCreateGuestOutreachMutationHookResult = ReturnType<typeof useReCreateGuestOutreachMutation>;
export type ReCreateGuestOutreachMutationResult = ApolloReactCommon.MutationResult<ReCreateGuestOutreachMutation>;
export type ReCreateGuestOutreachMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ReCreateGuestOutreachMutation,
  ReCreateGuestOutreachMutationVariables
>;
export const OrderDetailsWorkflowDocument = gql`
  query OrderDetailsWorkflow($orderId: Int!, $taskIncludeDeleted: Boolean) {
    order(id: $orderId) {
      id
      workflow {
        ...OrderDetails_Workflow
      }
    }
  }
  ${OrderDetails_WorkflowFragmentDoc}
`;

/**
 * __useOrderDetailsWorkflowQuery__
 *
 * To run a query within a React component, call `useOrderDetailsWorkflowQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsWorkflowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsWorkflowQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      taskIncludeDeleted: // value for 'taskIncludeDeleted'
 *   },
 * });
 */
export function useOrderDetailsWorkflowQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<OrderDetailsWorkflowQuery, OrderDetailsWorkflowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrderDetailsWorkflowQuery, OrderDetailsWorkflowQueryVariables>(
    OrderDetailsWorkflowDocument,
    options
  );
}
export function useOrderDetailsWorkflowLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderDetailsWorkflowQuery, OrderDetailsWorkflowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrderDetailsWorkflowQuery, OrderDetailsWorkflowQueryVariables>(
    OrderDetailsWorkflowDocument,
    options
  );
}
export type OrderDetailsWorkflowQueryHookResult = ReturnType<typeof useOrderDetailsWorkflowQuery>;
export type OrderDetailsWorkflowLazyQueryHookResult = ReturnType<typeof useOrderDetailsWorkflowLazyQuery>;
export type OrderDetailsWorkflowQueryResult = ApolloReactCommon.QueryResult<
  OrderDetailsWorkflowQuery,
  OrderDetailsWorkflowQueryVariables
>;
export const OrdersDocument = gql`
  query Orders(
    $limit: Int
    $offset: Int
    $sort: OrdersSort
    $search: String
    $customerIds: [Int!]
    $statusIds: [String!]
    $productIds: [String!]
  ) {
    orders(
      limit: $limit
      offset: $offset
      sort: $sort
      search: $search
      customerIds: $customerIds
      statusIds: $statusIds
      productIds: $productIds
    ) {
      totalCount
      nodes {
        id
        topic
        startDate
        instructions
        billingStatus {
          id
          name
          description
        }
        status {
          id
          name
        }
        customer {
          id
          name
          partner {
            id
            name
            isBillingEnforced
          }
        }
        user {
          id
          firstName
          lastName
        }
        partnerProduct {
          id
          name
        }
        orderIssues {
          title
          message
        }
      }
    }
  }
`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      customerIds: // value for 'customerIds'
 *      statusIds: // value for 'statusIds'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
}
export function useOrdersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
}
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = ApolloReactCommon.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      ok
      error {
        message
      }
    }
  }
`;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    ChangePasswordDocument,
    options
  );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UserProfileDocument = gql`
  query UserProfile($id: Int!) {
    user(id: $id) {
      id
      calendarUrl
      title
      emailPreference
      profilePictureFile {
        id
        name
        url
      }
    }
  }
`;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserProfileQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<UserProfileQuery, UserProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
}
export function useUserProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserProfileQuery, UserProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserProfileQuery, UserProfileQueryVariables>(UserProfileDocument, options);
}
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<typeof useUserProfileLazyQuery>;
export type UserProfileQueryResult = ApolloReactCommon.QueryResult<UserProfileQuery, UserProfileQueryVariables>;
export const RequestPasswordResetDocument = gql`
  mutation RequestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input) {
      wasRequested
    }
  }
`;
export type RequestPasswordResetMutationFn = ApolloReactCommon.MutationFunction<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(
    RequestPasswordResetDocument,
    options
  );
}
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = ApolloReactCommon.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      wasReset
    }
  }
`;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options
  );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const LoginUserSsoDocument = gql`
  mutation loginUserSSO($input: LoginUserInput!) {
    loginUser(input: $input) {
      isLoggedIn
    }
  }
`;
export type LoginUserSsoMutationFn = ApolloReactCommon.MutationFunction<
  LoginUserSsoMutation,
  LoginUserSsoMutationVariables
>;

/**
 * __useLoginUserSsoMutation__
 *
 * To run a mutation, you first call `useLoginUserSsoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserSsoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserSsoMutation, { data, loading, error }] = useLoginUserSsoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginUserSsoMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<LoginUserSsoMutation, LoginUserSsoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginUserSsoMutation, LoginUserSsoMutationVariables>(
    LoginUserSsoDocument,
    options
  );
}
export type LoginUserSsoMutationHookResult = ReturnType<typeof useLoginUserSsoMutation>;
export type LoginUserSsoMutationResult = ApolloReactCommon.MutationResult<LoginUserSsoMutation>;
export type LoginUserSsoMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LoginUserSsoMutation,
  LoginUserSsoMutationVariables
>;
export const TaskWorkspaceDocument = gql`
  query TaskWorkspace($taskId: Int!) {
    task(taskId: $taskId) {
      ...TaskWorkspace_Task
    }
  }
  ${TaskWorkspace_TaskFragmentDoc}
`;

/**
 * __useTaskWorkspaceQuery__
 *
 * To run a query within a React component, call `useTaskWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskWorkspaceQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskWorkspaceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>(TaskWorkspaceDocument, options);
}
export function useTaskWorkspaceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>(TaskWorkspaceDocument, options);
}
export type TaskWorkspaceQueryHookResult = ReturnType<typeof useTaskWorkspaceQuery>;
export type TaskWorkspaceLazyQueryHookResult = ReturnType<typeof useTaskWorkspaceLazyQuery>;
export type TaskWorkspaceQueryResult = ApolloReactCommon.QueryResult<TaskWorkspaceQuery, TaskWorkspaceQueryVariables>;
export const TaskWorkspace_UpdateTaskDocument = gql`
  mutation TaskWorkspace_UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        ...TaskWorkspace_Task
      }
    }
  }
  ${TaskWorkspace_TaskFragmentDoc}
`;
export type TaskWorkspace_UpdateTaskMutationFn = ApolloReactCommon.MutationFunction<
  TaskWorkspace_UpdateTaskMutation,
  TaskWorkspace_UpdateTaskMutationVariables
>;

/**
 * __useTaskWorkspace_UpdateTaskMutation__
 *
 * To run a mutation, you first call `useTaskWorkspace_UpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskWorkspace_UpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskWorkspaceUpdateTaskMutation, { data, loading, error }] = useTaskWorkspace_UpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskWorkspace_UpdateTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TaskWorkspace_UpdateTaskMutation,
    TaskWorkspace_UpdateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<TaskWorkspace_UpdateTaskMutation, TaskWorkspace_UpdateTaskMutationVariables>(
    TaskWorkspace_UpdateTaskDocument,
    options
  );
}
export type TaskWorkspace_UpdateTaskMutationHookResult = ReturnType<typeof useTaskWorkspace_UpdateTaskMutation>;
export type TaskWorkspace_UpdateTaskMutationResult = ApolloReactCommon.MutationResult<TaskWorkspace_UpdateTaskMutation>;
export type TaskWorkspace_UpdateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TaskWorkspace_UpdateTaskMutation,
  TaskWorkspace_UpdateTaskMutationVariables
>;
export const TasksDocument = gql`
  query Tasks(
    $limit: Int
    $offset: Int
    $customerIds: [Int!]
    $vendorIds: [Int!]
    $excludedVendorIds: [Int!]
    $taskTypeIds: [String!]
    $notTaskTypeIds: [String!]
    $statusIds: [String!]
    $assignedUserIds: [Int!]
    $productIds: [String!]
  ) {
    tasks(
      limit: $limit
      offset: $offset
      customerIds: $customerIds
      vendorIds: $vendorIds
      excludedVendorIds: $excludedVendorIds
      taskTypeIds: $taskTypeIds
      notTaskTypeIds: $notTaskTypeIds
      statusIds: $statusIds
      assignedUserIds: $assignedUserIds
      productIds: $productIds
    ) {
      totalCount
      nodes {
        ...TasksTable_Task
      }
    }
  }
  ${TasksTable_TaskFragmentDoc}
`;

/**
 * __useTasksQuery__
 *
 * To run a query within a React component, call `useTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      customerIds: // value for 'customerIds'
 *      vendorIds: // value for 'vendorIds'
 *      excludedVendorIds: // value for 'excludedVendorIds'
 *      taskTypeIds: // value for 'taskTypeIds'
 *      notTaskTypeIds: // value for 'notTaskTypeIds'
 *      statusIds: // value for 'statusIds'
 *      assignedUserIds: // value for 'assignedUserIds'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useTasksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
}
export function useTasksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
}
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = ApolloReactCommon.QueryResult<TasksQuery, TasksQueryVariables>;
export const TasksTable_UpdateTaskDocument = gql`
  mutation TasksTable_UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        ...TasksTable_Task
      }
    }
  }
  ${TasksTable_TaskFragmentDoc}
`;
export type TasksTable_UpdateTaskMutationFn = ApolloReactCommon.MutationFunction<
  TasksTable_UpdateTaskMutation,
  TasksTable_UpdateTaskMutationVariables
>;

/**
 * __useTasksTable_UpdateTaskMutation__
 *
 * To run a mutation, you first call `useTasksTable_UpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTasksTable_UpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tasksTableUpdateTaskMutation, { data, loading, error }] = useTasksTable_UpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTasksTable_UpdateTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TasksTable_UpdateTaskMutation,
    TasksTable_UpdateTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<TasksTable_UpdateTaskMutation, TasksTable_UpdateTaskMutationVariables>(
    TasksTable_UpdateTaskDocument,
    options
  );
}
export type TasksTable_UpdateTaskMutationHookResult = ReturnType<typeof useTasksTable_UpdateTaskMutation>;
export type TasksTable_UpdateTaskMutationResult = ApolloReactCommon.MutationResult<TasksTable_UpdateTaskMutation>;
export type TasksTable_UpdateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TasksTable_UpdateTaskMutation,
  TasksTable_UpdateTaskMutationVariables
>;
export const DomainMetricsDocument = gql`
  query DomainMetrics($domain: String!) {
    getDomainMetrics(domain: $domain) {
      domainMetrics {
        authorityScore
        organitcTraffic
        spamScore
        domainAuthority
      }
    }
  }
`;

/**
 * __useDomainMetricsQuery__
 *
 * To run a query within a React component, call `useDomainMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainMetricsQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useDomainMetricsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<DomainMetricsQuery, DomainMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<DomainMetricsQuery, DomainMetricsQueryVariables>(DomainMetricsDocument, options);
}
export function useDomainMetricsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DomainMetricsQuery, DomainMetricsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<DomainMetricsQuery, DomainMetricsQueryVariables>(DomainMetricsDocument, options);
}
export type DomainMetricsQueryHookResult = ReturnType<typeof useDomainMetricsQuery>;
export type DomainMetricsLazyQueryHookResult = ReturnType<typeof useDomainMetricsLazyQuery>;
export type DomainMetricsQueryResult = ApolloReactCommon.QueryResult<DomainMetricsQuery, DomainMetricsQueryVariables>;
export const TopicGeneratorDocument = gql`
  query TopicGenerator($keyword: String!) {
    getTopicKeyword(keyword: $keyword) {
      topicSuggestions
    }
  }
`;

/**
 * __useTopicGeneratorQuery__
 *
 * To run a query within a React component, call `useTopicGeneratorQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicGeneratorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicGeneratorQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useTopicGeneratorQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<TopicGeneratorQuery, TopicGeneratorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TopicGeneratorQuery, TopicGeneratorQueryVariables>(TopicGeneratorDocument, options);
}
export function useTopicGeneratorLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TopicGeneratorQuery, TopicGeneratorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<TopicGeneratorQuery, TopicGeneratorQueryVariables>(
    TopicGeneratorDocument,
    options
  );
}
export type TopicGeneratorQueryHookResult = ReturnType<typeof useTopicGeneratorQuery>;
export type TopicGeneratorLazyQueryHookResult = ReturnType<typeof useTopicGeneratorLazyQuery>;
export type TopicGeneratorQueryResult = ApolloReactCommon.QueryResult<
  TopicGeneratorQuery,
  TopicGeneratorQueryVariables
>;
export const VendorDetailsOverviewDocument = gql`
  query VendorDetailsOverview($id: Int!) {
    vendor(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useVendorDetailsOverviewQuery__
 *
 * To run a query within a React component, call `useVendorDetailsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorDetailsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorDetailsOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVendorDetailsOverviewQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<VendorDetailsOverviewQuery, VendorDetailsOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<VendorDetailsOverviewQuery, VendorDetailsOverviewQueryVariables>(
    VendorDetailsOverviewDocument,
    options
  );
}
export function useVendorDetailsOverviewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VendorDetailsOverviewQuery, VendorDetailsOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<VendorDetailsOverviewQuery, VendorDetailsOverviewQueryVariables>(
    VendorDetailsOverviewDocument,
    options
  );
}
export type VendorDetailsOverviewQueryHookResult = ReturnType<typeof useVendorDetailsOverviewQuery>;
export type VendorDetailsOverviewLazyQueryHookResult = ReturnType<typeof useVendorDetailsOverviewLazyQuery>;
export type VendorDetailsOverviewQueryResult = ApolloReactCommon.QueryResult<
  VendorDetailsOverviewQuery,
  VendorDetailsOverviewQueryVariables
>;
export const UpdateVendorUserDocument = gql`
  mutation UpdateVendorUser($input: UpdateVendorUserInput!) {
    updateVendorUser(input: $input) {
      vendorUser {
        id
        userId
        isActive
      }
    }
  }
`;
export type UpdateVendorUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdateVendorUserMutation,
  UpdateVendorUserMutationVariables
>;

/**
 * __useUpdateVendorUserMutation__
 *
 * To run a mutation, you first call `useUpdateVendorUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorUserMutation, { data, loading, error }] = useUpdateVendorUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVendorUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVendorUserMutation, UpdateVendorUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<UpdateVendorUserMutation, UpdateVendorUserMutationVariables>(
    UpdateVendorUserDocument,
    options
  );
}
export type UpdateVendorUserMutationHookResult = ReturnType<typeof useUpdateVendorUserMutation>;
export type UpdateVendorUserMutationResult = ApolloReactCommon.MutationResult<UpdateVendorUserMutation>;
export type UpdateVendorUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateVendorUserMutation,
  UpdateVendorUserMutationVariables
>;
export const VendorUsersDocument = gql`
  query VendorUsers($id: Int!, $search: String, $limit: Int, $offset: Int, $includeInactive: Boolean) {
    vendor(id: $id) {
      id
      name
      partnerId
      vendorUsers(search: $search, limit: $limit, offset: $offset, includeInactive: $includeInactive) {
        totalCount
        nodes {
          id
          vendorId
          isActive
          userId
          user {
            id
            firstName
            lastName
            email
          }
          userTaskPermissions {
            id
            productId
            taskTypeId
          }
        }
      }
    }
  }
`;

/**
 * __useVendorUsersQuery__
 *
 * To run a query within a React component, call `useVendorUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      includeInactive: // value for 'includeInactive'
 *   },
 * });
 */
export function useVendorUsersQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<VendorUsersQuery, VendorUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<VendorUsersQuery, VendorUsersQueryVariables>(VendorUsersDocument, options);
}
export function useVendorUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VendorUsersQuery, VendorUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<VendorUsersQuery, VendorUsersQueryVariables>(VendorUsersDocument, options);
}
export type VendorUsersQueryHookResult = ReturnType<typeof useVendorUsersQuery>;
export type VendorUsersLazyQueryHookResult = ReturnType<typeof useVendorUsersLazyQuery>;
export type VendorUsersQueryResult = ApolloReactCommon.QueryResult<VendorUsersQuery, VendorUsersQueryVariables>;
export const VendorDetailsSecondaryNavDocument = gql`
  query VendorDetailsSecondaryNav($vendorId: Int!) {
    vendor(id: $vendorId) {
      id
      name
    }
  }
`;

/**
 * __useVendorDetailsSecondaryNavQuery__
 *
 * To run a query within a React component, call `useVendorDetailsSecondaryNavQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorDetailsSecondaryNavQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorDetailsSecondaryNavQuery({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useVendorDetailsSecondaryNavQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    VendorDetailsSecondaryNavQuery,
    VendorDetailsSecondaryNavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<VendorDetailsSecondaryNavQuery, VendorDetailsSecondaryNavQueryVariables>(
    VendorDetailsSecondaryNavDocument,
    options
  );
}
export function useVendorDetailsSecondaryNavLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VendorDetailsSecondaryNavQuery,
    VendorDetailsSecondaryNavQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<VendorDetailsSecondaryNavQuery, VendorDetailsSecondaryNavQueryVariables>(
    VendorDetailsSecondaryNavDocument,
    options
  );
}
export type VendorDetailsSecondaryNavQueryHookResult = ReturnType<typeof useVendorDetailsSecondaryNavQuery>;
export type VendorDetailsSecondaryNavLazyQueryHookResult = ReturnType<typeof useVendorDetailsSecondaryNavLazyQuery>;
export type VendorDetailsSecondaryNavQueryResult = ApolloReactCommon.QueryResult<
  VendorDetailsSecondaryNavQuery,
  VendorDetailsSecondaryNavQueryVariables
>;
export const VendorsDocument = gql`
  query Vendors($limit: Int!, $offset: Int!, $search: String) {
    vendors(limit: $limit, offset: $offset, search: $search) {
      totalCount
      nodes {
        id
        name
        isExternal
        partner {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useVendorsQuery__
 *
 * To run a query within a React component, call `useVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useVendorsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<VendorsQuery, VendorsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, options);
}
export function useVendorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VendorsQuery, VendorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, options);
}
export type VendorsQueryHookResult = ReturnType<typeof useVendorsQuery>;
export type VendorsLazyQueryHookResult = ReturnType<typeof useVendorsLazyQuery>;
export type VendorsQueryResult = ApolloReactCommon.QueryResult<VendorsQuery, VendorsQueryVariables>;
